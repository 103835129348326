import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import { useNavigate } from "react-router";



export default function TermsCond() {
  //Navigation
  const naviGation = useNavigate();
  let url = window.location.href;
  let route = url.split("/");


  return (
    <div>
      <Navbar />
      <div className="term-cond mt-5">
        <div className="container">
          <div className="row justify-content-center pt-3">
            <div className="col-lg-9 py-5">
              <h5 className="font-weight-bold text-green">
                Terms and Conditions
              </h5>
              <h6>PURPOSE:</h6>
              <p>
                EAD Jobs is intended only to serve as a preliminary medium of
                contact and exchange of information for its users / members /
                visitors who have a bona fide intention to contact and/or be
                contacted for the purposes related to genuine existing job
                vacancies and for other career enhancement services.
              </p>
              <h6 className="font-weight-bold">
                USE TO BE IN CONFORMITY WITH THE PURPOSE
              </h6>
              <p>
                EAD Jobs (and related products) or service or product that is
                subscribe to or used (whether the same is paid for by you or
                not) is meant for the Purpose and only the exclusive use of the
                subscriber/registered user. Copying or downloading or recreating
                or sharing passwords or sublicensing or sharing in any manner
                which is not in accordance with these terms, is a misuse of the
                platform or service or product and IEIL reserves its rights to
                act in such manner as to protect its loss of revenue or
                reputation or claim damages including stopping your service or
                access and reporting to relevant authorities. In the event you
                are found to be copying or misusing or transmitting or crawling
                any data or photographs or graphics or any information available
                on Naukri.com for any purpose other than that being a bonafide
                Purpose, we reserve the right to take such action that we deem
                fit including stopping access and claiming damages
              </p>
              <p>
                The site is a public site with free access and Info Edge assumes
                no liability for the quality and genuineness of responses. Info
                Edge (India) Ltd. cannot monitor the responses that a person may
                receive in response to information he/she has displayed on the
                site. The individual/company would have to conduct its own
                background checks on the bonafide nature of all response(s).
              </p>
              <p>
                You give us permission to use the information about actions that
                you have taken on Naukri.com in connection with ads, offers and
                other content (whether sponsored or not) that we display across
                our services, without any compensation to you. We use data and
                information about you to make relevant suggestions and
                recommendation to you and others.
              </p>
              <p>
                The platform may contain links to third party websites, these
                links are provided solely as convenience to You and the presence
                of these links should not under any circumstances be considered
                as an endorsement of the contents of the same, if You chose to
                access these websites you do so at your own risk.
              </p>
              <p></p>
              <button
                className="btn btn-light my-2 my-sm-0 login-btn"
                onClick={() => naviGation(`/${route[5]}`)}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
