import React, { useState, useCallback } from "react";
import NavbarDashboard from "../NavbarDashboard";
import { Link } from "react-router-dom";
import Select from "react-select";
import "react-phone-input-2/lib/material.css";
// import SampleResume from "../../../images/sample-resume.png";
import CustomModal from "../../CustomModal";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  ActiveLogPost,
  CreateGreeting,
  CreateSendMessage,
  GetActivityLog,
  GetAllAppliedJos,
  GetAllGreeting,
  GetMasterDatasByType,
  GetParticularAppliedJobs,
  GetParticularGreeting,
  UpdateAppliedJobStatus,
} from "../../Api/Api";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Roboimg from "../../../images/roboimg.png";
import { GetInitials } from "../../Store/Constants";
import { NonEmptyValidation } from "../../Store/validate";

export default function CandidateProfile() {
  //state
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const userProfileId = state?.auth?.auth?.userProfileId;
  const companyId = state?.auth?.auth?.userDetails?.companyId

  const [RejectionModal, setRejectionModal] = useState(false);
  const [UpdateStatusModal, setUpdateStatusModal] = useState(false);
  const [SendMessageModal, setSendMessageModal] = useState(false);
  const [GreetingMessageModal, setGreetingMessageModal] = useState(false);
  const [getActivityMsg, setGetActivtyMsg] = useState([]);

  let host = window.location.href;
  let appledid = host?.split("/");

  const [particularAppliedJobs, setParticularAppliedJobs] = useState([]);

  //get all applied jobs
  const [allAplliedJobs, setAllAppliedJobs] = useState([]);

  const fetchAllAppliedJobs = useCallback(async (status, jobid) => {
    const jobId = jobid;
    if (!authKey || !jobId) return;

    const getJobPost = await GetAllAppliedJos(authKey, jobId, status);
    setAllAppliedJobs(getJobPost.data);
  }, [authKey]);

  const [currentApplicant, setCurrentApplicant] = useState(null);

  const nextApplicant = (value) => {
    let appliedIdNo = allAplliedJobs?.appliedJobDetails?.map(
      (e) => e.appliedId
    );
    let index = appliedIdNo.indexOf(currentApplicant);
    setParticularAppliedJobs(allAplliedJobs?.appliedJobDetails[index + value]);
    setCurrentApplicant(
      allAplliedJobs?.appliedJobDetails[index + value].appliedId
    );
  };

  let appliedIdNo = allAplliedJobs?.appliedJobDetails?.map((e) => e.appliedId);

  //get all applied jobs

  const fetchActivityLog = useCallback(async (value, value1) => {
    const jobId = value;
    const jobSeekerId = value1;

    if (!authKey || !jobId || !jobSeekerId) return;

    const getJobPost = await GetActivityLog(authKey, jobId, jobSeekerId);
    setGetActivtyMsg(getJobPost.data);
  }, [authKey]);

  const fetchParticularAppliedJobs = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const appliedId = appledid[5];
    const getJobPost = await GetParticularAppliedJobs(
      authKey,
      appliedId,
      userProfileId
    );
    let data1 = getJobPost.data;
    setParticularAppliedJobs(data1);

    setTimeout(() => {
      fetchAllAppliedJobs(data1.status, data1.jobId);
      fetchActivityLog(data1.jobId, data1.userProfileId);
    }, 500);
  }, [authKey, appledid, userProfileId, fetchActivityLog, fetchAllAppliedJobs]);

  useEffect(() => {
    fetchParticularAppliedJobs();
    setCurrentApplicant(appledid[5]);
  }, [fetchParticularAppliedJobs, appledid]);

  // create job post api integration

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };
  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };
  const [messageLog, setMessageLog] = useState({});
  const setMessageLogValue = (key, value) => {
    setMessageLog({ ...messageLog, [key]: value });
    if (updateStatusValidation[key]) delete updateStatusValidation[key];
  };
  const [updateStatusValidation, setUpdateStatusValidation] = useState({});
  const setUpdateStatusValidationValue = (key, value) => {
    setUpdateStatusValidation({ ...updateStatusValidation, [key]: value });
  };

  const [sendMessageValidation, setSendMessageValidation] = useState({});

  const setValidationValue = (key, value) => {
    setSendMessageValidation({ ...sendMessageValidation, [key]: value });
  };

  const handleActivityLog = async (value) => {
    let Payload;

    if (messageLog.message === "") {
      Payload = {
        jobSeekerId: particularAppliedJobs?.userProfileId,
        employerId: state?.auth?.auth?.userProfileId,
        jobId: particularAppliedJobs?.jobId,
        jobStatus: value,
      };
    } else {
      Payload = {
        jobSeekerId: particularAppliedJobs?.userProfileId,
        employerId: state?.auth?.auth?.userProfileId,
        jobId: particularAppliedJobs?.jobId,
        jobStatus: value,
        message: messageLog.message,
      };
    }

    const token = state?.auth?.auth?.authKey;
    const response = await ActiveLogPost(Payload, token);

    if (response && response.statusCode === 200) {
      fetchActivityLog(
        particularAppliedJobs.jobId,
        particularAppliedJobs.userProfileId
      );
      fetchParticularAppliedJobs();
      setUpdateStatusModal(false);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const handleUpdateStatus = async (value) => {
    let Payload;
    let validate = {};
    validate.message = NonEmptyValidation(messageLog.message);
    setUpdateStatusValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      Payload = {
        jobSeekerId: particularAppliedJobs?.userProfileId,
        employerId: state?.auth?.auth?.userProfileId,
        jobId: particularAppliedJobs?.jobId,
        jobStatus: value,
        message: messageLog.message,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await ActiveLogPost(Payload, token);

      if (response && response.statusCode === 200) {
        fetchActivityLog(
          particularAppliedJobs.jobId,
          particularAppliedJobs.userProfileId
        );
        setMessageLog("");
        fetchParticularAppliedJobs();
        setUpdateStatusModal(false);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      console.log("Error while update status");
    }
  };

  const [jobStatus, setJobStatus] = useState({});

  const [reasonModalValidation, setReasonModalValidation] = useState({});

  const setJobStatusValue = (key, value) => {
    setJobStatus({ ...jobStatus, [key]: value });
    if (reasonModalValidation[key]) delete reasonModalValidation[key];
  };

  const setReasonModalValidationValue = (key, value) => {
    setReasonModalValidation({ ...reasonModalValidation, [key]: value });
  };

  const handleUpdateRejectJobStatus = async (value) => {
    let validate = {};
    validate.reason = NonEmptyValidation(jobStatus.reason);
    validate.reasonDetails = NonEmptyValidation(jobStatus.reasonDetails);
    setReasonModalValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const token = state?.auth?.auth?.authKey;
      let Payload = {
        appliedId: currentApplicant,
        status: value,
        reason: jobStatus.reason.dataId,
        reasonDetails: jobStatus.reasonDetails,
      };
      const response = await UpdateAppliedJobStatus(Payload, token);

      if (response && response.statusCode === 200) {
        handleActivityLog(value);
        fetchActivityLog(
          particularAppliedJobs.jobId,
          particularAppliedJobs.userProfileId
        );
        setJobStatus({});
        fetchParticularAppliedJobs();
        setRejectionModal(false);
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  const handleUpdateAppliedJobStatus = async (value) => {
    const token = state?.auth?.auth?.authKey;
    let Payload = {
      appliedId: currentApplicant,
      status: value,
    };
    const response = await UpdateAppliedJobStatus(Payload, token);

    if (response && response.statusCode === 200) {
      handleActivityLog(value);
      fetchParticularAppliedJobs();
      showToastSuccessMsg(response.message);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const [reason, setReason] = useState([]);

  const fetchReason = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=reason&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setReason(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchReason();
  }, [fetchReason]);

  const [greetingMsg, setGreetingMsg] = useState([]);

  const setGreetingMsgValue = (key, value) => {
    setGreetingMsg({ ...greetingMsg, [key]: value });
    if (sendMessageValidation[key]) delete sendMessageValidation[key];
  };

  // const [greetingCheckBox, setGreetingCheckBox] = useState();

  //get all greeting

  const [allGreeting, setAllGreeting] = useState([]);

  async function fetchAllGreeting() {
    const token = state?.auth?.auth?.authKey;
    const userProfileId = state?.auth?.auth?.userProfileId;
    const getJobPost = await GetAllGreeting(token, userProfileId);
    setAllGreeting(getJobPost.data);
  }

  //get particular greeting

  async function fetchParticularGreeting(value) {
    const token = state?.auth?.auth?.authKey;
    const greetingId = value;
    const getJobPost = await GetParticularGreeting(token, greetingId);
    // setParticularGreeting(getJobPost.data);
    setGreetingMsgValue("message", getJobPost.data.message);
  }

  const handleGeetings = async () => {
    let validate = {};
    validate.message = NonEmptyValidation(greetingMsg.message);
    validate.status = NonEmptyValidation(greetingMsg.status);
    setSendMessageValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const token = state?.auth?.auth?.authKey;
      let Payload = {
        jobId: particularAppliedJobs?.jobId,
        userProfileId: state?.auth?.auth?.userProfileId,
        message: greetingMsg.message,
        status: greetingMsg.status ? greetingMsg.status : "InActive",
      };
      const response = await CreateGreeting(Payload, token);

      if (response && response.statusCode === 200) {
        setGreetingMsg({});
        setSendMessageModal(false);
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      console.log("Error while adding greeting message in waiting state");
    }
  };

  const handleSendMessage = async () => {
    let validate = {};
    validate.message = NonEmptyValidation(greetingMsg.message);
    setSendMessageValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const token = state?.auth?.auth?.authKey;
      let Payload = {
        userProfileId: particularAppliedJobs.userProfileId,
        jobId: particularAppliedJobs.jobId,
        message: greetingMsg.message,
      };
      const response = await CreateSendMessage(Payload, token);

      if (response && response.statusCode === 200) {
        setSendMessageModal(false);
        handleGeetings();
        setGreetingMsg({});
        fetchParticularAppliedJobs();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      console.log("Error while sending message in waiting state");
    }
  };

  return (
    <div className="dashboard-bg">
      <NavbarDashboard />
      <div className="main">
        <div className="candidate-details-section scroll-content">
          <div className="candidate-profile-section ">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="Jobs-details-skills d-flex align-items-center my-2 p-3">
                    <div className="container">
                      <div className="row mx-1">
                        <h6 className="font-weight-bold mr-5">
                          {particularAppliedJobs?.userDetails?.fullName}
                        </h6>
                        <div className="match d-flex align-items-center mx-2">
                          <small className="color-green">90% Match</small>
                        </div>
                      </div>
                      <p className="mb-0">Backend Developer</p>
                      <p className="mb-0 email-color">
                        {particularAppliedJobs?.userDetails?.emailId}
                      </p>
                      <small className="my-1">
                        Applied date :{" "}
                        {moment(particularAppliedJobs?.createdAt).format(
                          "DD/MM/YYYY"
                        )}
                      </small>
                    </div>
                    <div className="profile-name mx-2 profile-name d-flex align-items-center justify-content-center mx-2">
                      <div className="banner-profile-dashboard2">
                        {particularAppliedJobs?.userDetails?.profileImage ? (
                          <img
                            className="PP-upload-img"
                            alt=""
                            src={
                              particularAppliedJobs?.userDetails?.profileImage
                            }
                            hidden={
                              !particularAppliedJobs?.userDetails?.profileImage
                            }
                          />
                        ) : (
                          <h1>
                            {GetInitials(
                              particularAppliedJobs?.userDetails?.displayName
                            )}
                          </h1>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="Jobs-details-skills  my-2 p-3">
                    <div className="container">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td className="w-25">Primary Location</td>
                            <th>:</th>
                            <th>
                              {
                                particularAppliedJobs?.userDetails
                                  ?.primaryLocation?.name
                              }
                            </th>
                          </tr>
                          <tr>
                            <td className="w-25">Secondary Location</td>
                            <th>:</th>
                            {particularAppliedJobs?.userDetails?.secondaryLocation?.map(
                              (e) => (
                                <th className="mb-0"> {e.name}</th>
                              )
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="Jobs-details-skills  my-2 p-3">
                    <div className="container">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td className="w-25">Languages</td>
                            <th>:</th>
                            <th>
                              {particularAppliedJobs?.userDetails?.language?.map(
                                (e) => (
                                  <p className="mb-0">
                                    {" "}
                                    {e.languageName} -{" "}
                                    {e.read === true ? "Read," : ""} {" "}
                                    {e.write === true ? "Write," : ""} {" "}
                                    {e.speak === true ? "Speak" : ""} -
                                    <span className="email-color">
                                      {" "}
                                      {e.proficiency}
                                    </span>
                                  </p>
                                )
                              )}
                            </th>

                            <th>
                              {/* {" "}
                              English - Read, Speak, and Write -
                              <span className="email-color">
                                {" "}
                                Native / Bilingual Proficiency
                              </span>{" "} */}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="Jobs-details-skills  my-2 p-3">
                    <div className="container">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <th className="w-25">Primary skills </th>
                            <th>:</th>
                            {particularAppliedJobs?.skills?.map((e) =>
                              e.skillType === "Primary" ? (
                                <td>{e.skillName.name}</td>
                              ) : (
                                ""
                              )
                            )}
                          </tr>
                          <tr>
                            <th className="w-25">Secondary skills </th>
                            <th>:</th>
                            {particularAppliedJobs?.skills?.map((e) =>
                              e.skillType === "Secondary" ? (
                                <td>{e.skillName.name}</td>
                              ) : (
                                ""
                              )
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="Jobs-details-skills  my-2 p-3">
                    <div className="container">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <th className="w-25">Cover Letter</th>
                            <td>:</td>
                            <td>
                              {" "}
                              {
                                particularAppliedJobs?.userCoverLetter
                                  ?.description
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="Jobs-details-skills  my-2 p-3">
                    <div className="container">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <th className="w-25">Resume</th>
                            <td></td>
                            <td className="text-right">
                              {/* <a
                                className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}
                                href={particularAppliedJobs?.resume?.fileUrl}
                                target="_blank"
                              >
                                Download Resume
                              </a>{" "} */}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="resume-section">
                        <iframe
                          style={{ width: "100%", height: "100vh" }}
                          src={particularAppliedJobs?.resume?.fileUrl}
                          title="title"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <h5 className="font-weight-bold">Activity Logs</h5>

                  <div className="candiate-progressbar ">
                    <div
                      className="navigation_menu py-5 px-4"
                      id="navigation_menu"
                    >
                      <ul className="navigation_tabs" id="navigation_tabs">
                        <li
                          className={
                            getActivityMsg?.some(
                              (e) => e.jobStatus === "Applied"
                            )
                              ? "tab_inactive selected-resume-css"
                              : "tab_disabled selected-resume-css"
                          }
                        >
                          <a href="/#">Applied</a>
                          <small className="my-auto ml-2">
                            {moment(getActivityMsg?.createdAt).format(
                              "DD/MM/YYYY"
                            )}
                          </small>
                        </li>
                        <li
                          className={
                            getActivityMsg?.some(
                              (e) => e.jobStatus === "Waiting"
                            )
                              ? "tab_inactive"
                              : "tab_disabled"
                          }
                        >
                          <div className="selected-resume-css">
                          <a href="/#">Wait List</a>
                          <small  className="my-auto ml-2">
                            {moment(getActivityMsg?.createdAt).format(
                              "DD/MM/YYYY"
                            )}
                          </small>
                          </div>
                          
                          {getActivityMsg
                            ?.filter((e) => e.jobStatus === "Waiting")
                            .map((e) => (
                              <p>{e.message}</p>
                            ))}
                        </li>
                        <li
                          className={
                            getActivityMsg?.some(
                              (e) => e.jobStatus === "Inprogress"
                            )
                              ? "tab_inactive"
                              : "tab_disabled"
                          }
                        >
                          <div className="selected-resume-css">
                          <a href="/#">Inprogress</a>
                          <small className="my-auto ml-2">
                            {moment(getActivityMsg?.createdAt).format(
                              "DD/MM/YYYY"
                            )}
                          </small>
                          </div>
                          
                          {getActivityMsg
                            ?.filter((e) => e.jobStatus === "Inprogress")
                            .map((e) => (
                              <p>{e.message}</p>
                            ))}
                        </li>
                        <li
                          className={
                            getActivityMsg?.some(
                              (e) => e.jobStatus === "Selected"
                            )
                              ? "tab_inactive selected-resume-css"
                              : getActivityMsg?.some(
                                  (e) => e.jobStatus === "Rejected"
                                )
                              ? "tab_inactive_danger selected-resume-css"
                              : "tab_disabled selected-resume-css"
                          }
                        >
                          <a href="/#">
                            {getActivityMsg?.some(
                              (e) => e.jobStatus === "Rejected"
                            )
                              ? "Rejected"
                              : getActivityMsg?.some(
                                  (e) => e.jobStatus === "Selected"
                                )
                              ? "Hired"
                              : "Hired/Rejected"}
                          </a>
                          <small className="my-auto ml-2">
                            {moment(getActivityMsg?.createdAt).format(
                              "DD/MM/YYYY"
                            )}
                          </small>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="candidate-profile-section1 d-flex align-items-center mt-2 py-1">
            <div className="container-fluid">
              <div className="row d-flex align-items-center justify-content-between ">
                <div className="col-lg-2 py-2 btn-bg-color row my-auto">
                  {/* <Link to={`/JobsDetailsPage/${particularAppliedJobs?.jobId}`}> */}
                  {appliedIdNo?.indexOf(currentApplicant) === 0 ? (
                    <button
                      className="btn btn-light my-2 my-sm-0 mx-auto postjob-btn"
                      disabled
                    >
                      Previous
                    </button>
                  ) : (
                    <button
                      className="btn btn-light my-2 my-sm-0 mx-auto postjob-btn"
                      // onClick={()=>navigate(`/JobDetailsPage/${particularAppliedJobs?.jobId}`)}
                      onClick={() => nextApplicant(-1)}
                    >
                      Previous
                    </button>
                  )}
                  {/* </Link> */}
                </div>
                <div className="col-lg-8 py-2 btn-bg-color text-center d-flex align-items-center justify-content-between">
                  <div className="">
                    <button
                      className="btn btn-light text-danger my-2 my-sm-0 mr-2 postjob-btn rejected-btn"
                      onClick={() => {
                        setRejectionModal(true);
                      }}
                      disabled={particularAppliedJobs?.status === "Rejected"}
                    >
                      Reject
                    </button>
                  </div>
                  <div className="">
                    {particularAppliedJobs?.status === "Applied" && (
                      <button
                        className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn"
                        onClick={() => {
                          handleUpdateAppliedJobStatus("Waiting");
                        }}
                      >
                        Wait list
                      </button>
                    )}
                    {particularAppliedJobs?.status === "Applied" && (
                      <button
                        className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                        onClick={() => {
                          handleUpdateAppliedJobStatus("Inprogress");
                        }}
                      >
                        Like
                      </button>
                    )}
                    {/* <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn">Like</button> */}
                    {particularAppliedJobs?.status === "Waiting" && (
                      <button
                        className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn"
                        onClick={() => {
                          setUpdateStatusModal(true);
                        }}
                      >
                        Update status
                      </button>
                    )}
                    {particularAppliedJobs?.status === "Waiting" && (
                      <button
                        className="btn btn-light my-2 my-sm-0 mr-2 sendmessage-btn"
                        onClick={() => {
                          setSendMessageModal(true);
                        }}
                      >
                        Send message
                      </button>
                    )}
                    {particularAppliedJobs?.status === "Waiting" && (
                      <button
                        className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                        onClick={() => {
                          handleUpdateAppliedJobStatus("Inprogress");
                        }}
                      >
                        Like
                      </button>
                    )}

                    {particularAppliedJobs?.status === "Inprogress" && (
                      <button
                        className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn"
                        onClick={() => {
                          setUpdateStatusModal(true);
                        }}
                      >
                        Update status
                      </button>
                    )}
                    {particularAppliedJobs?.status === "Inprogress" && (
                      <Link to={"/Chat"}>
                        <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn">
                          Chat
                        </button>
                      </Link>
                    )}
                    {particularAppliedJobs?.status === "Inprogress" && (
                      <button
                        className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                        onClick={() => {
                          handleUpdateAppliedJobStatus("Selected");
                        }}
                      >
                        Hire
                      </button>
                    )}
                    {particularAppliedJobs?.status === "Selected" && (
                      <Link to={"/Chat"}>
                        <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn">
                          Chat
                        </button>
                      </Link>
                    )}
                    {particularAppliedJobs?.status === "Selected" && (
                      <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn">
                        Hired
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-lg-2 py-2 btn-bg-color row my-auto">
                  {appliedIdNo?.length ===
                  appliedIdNo?.indexOf(currentApplicant) + 1 ? (
                    <button
                      className="btn btn-light mx-auto my-2 my-sm-0  postjob-btn"
                      disabled
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      className="btn btn-light mx-auto my-2 my-sm-0  postjob-btn"
                      onClick={() => nextApplicant(1)}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={RejectionModal}
        onClickOutside={() => {
          setRejectionModal(false);
          setReasonModalValidation({});
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Reason of Rejection
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-5">
                  <div className="google-input">
                    <label htmlFor="template-input" className="z-index-value">
                      Reason
                    </label>
                    <div className="dropdown-container">
                      <Select
                        options={reason}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "6px",
                          zIndex: 3,
                          colors: {
                            ...theme.colors,
                            primary25: "#DFF1F1",
                            neutral20:
                              reasonModalValidation?.reason?.status === false
                                ? "red"
                                : "#00ABAD", // border color
                            primary:
                              reasonModalValidation?.reason?.status === false
                                ? "red"
                                : "#00ABAD", // border color when focus
                          },
                        })}
                        styles={{
                          menu: (provided, state) => ({
                            ...provided,
                            zIndex: "5 !important",
                          }),
                        }}
                        placeholder="Choose your reason"
                        value={jobStatus.reason}
                        onChange={(e) => {
                          setJobStatusValue("reason", e);
                        }}
                      />
                    </div>
                  </div>
                  <small className="text-danger">
                    {reasonModalValidation?.reason?.message
                      ? `Reason ${reasonModalValidation?.reason?.message}`
                      : ""}
                  </small>
                  <div className="modalAnswer mt-2">
                    <label for="exampleFormControlTextarea2">
                      Reason Details
                    </label>
                    <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button
                        type="button"
                        className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                      >
                        Rich Text Editor
                        <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                          lock
                        </span>
                      </button>
                    </div>

                    <div className="form-group">
                      <textarea
                        className="form-control rounded-0 modalDisapprove-textarea"
                        id="exampleFormControlTextarea2"
                        rows="7"
                        aria-invalid={Boolean(
                          reasonModalValidation?.reasonDetails?.status === false
                        )}
                        value={jobStatus.reasonDetails}
                        onChange={(e) => {
                          setJobStatusValue("reasonDetails", e.target.value);
                        }}
                        onBlur={(e) => {
                          setReasonModalValidationValue(
                            "reasonDetails",
                            NonEmptyValidation(e.target.value)
                          );
                        }}
                      ></textarea>
                    </div>
                    <small className="text-danger">
                      {reasonModalValidation?.reasonDetails?.message
                        ? `Reason details ${reasonModalValidation?.reasonDetails?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setRejectionModal(false);
                    setJobStatus({});
                    setReasonModalValidation({});
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn"
                  onClick={() => {
                    handleUpdateRejectJobStatus("Rejected");
                  }}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={UpdateStatusModal}
        onClickOutside={() => {
          setUpdateStatusModal(false);
          setUpdateStatusValidation({});
          setMessageLog([]);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Update Status
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-5">
                  <div className="google-input"></div>
                  <div className="modalAnswer mt-2">
                    <label for="exampleFormControlTextarea2">
                      To : <b>{particularAppliedJobs?.userDetails?.fullName}</b>
                    </label>
                    <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button
                        type="button"
                        className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                      >
                        Rich Text Editor
                        <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                          lock
                        </span>
                      </button>
                    </div>

                    <div className="form-group">
                      <textarea
                        className="form-control rounded-0 modalDisapprove-textarea"
                        id="exampleFormControlTextarea2"
                        aria-invalid={Boolean(
                          updateStatusValidation?.message?.status === false
                        )}
                        rows="7"
                        value={messageLog?.message}
                        onChange={(e) => {
                          setMessageLogValue("message", e.target.value);
                        }}
                        onBlur={(e) => {
                          setUpdateStatusValidationValue(
                            "message",
                            NonEmptyValidation(e.target.value)
                          );
                        }}
                      ></textarea>
                    </div>
                    <small className="text-danger">
                      {updateStatusValidation?.message?.message
                        ? `Message ${updateStatusValidation?.message?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setUpdateStatusModal(false);
                    setUpdateStatusValidation({});
                    setMessageLog([]);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn"
                  onClick={() => {
                    handleUpdateStatus(particularAppliedJobs?.status);
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={SendMessageModal}
        onClickOutside={() => {
          setSendMessageModal(false);
          setSendMessageValidation({});
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Send Message
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-5">
                  <div className="google-input"></div>
                  <div className="modalAnswer mt-2">
                    <label for="exampleFormControlTextarea2">
                      To : <b>{particularAppliedJobs?.userDetails?.fullName}</b>
                    </label>
                    <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button
                        type="button"
                        className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                        onClick={() => {
                          fetchAllGreeting();
                          setGreetingMsg([]);
                          setSendMessageModal(false);
                          setGreetingMessageModal(true);
                        }}
                      >
                        Greeting
                        <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                          message
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                      >
                        Rich Text Editor
                        <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                          lock
                        </span>
                      </button>
                    </div>

                    <div className="form-group">
                      <textarea
                        className="form-control rounded-0 modalDisapprove-textarea"
                        id="exampleFormControlTextarea2"
                        rows="7"
                        aria-invalid={Boolean(
                          sendMessageValidation?.message?.status === false
                        )}
                        value={greetingMsg?.message}
                        onChange={(e) => {
                          setGreetingMsgValue("message", e.target.value);
                        }}
                        onBlur={(e) => {
                          setValidationValue(
                            "message",
                            NonEmptyValidation(e.target.value)
                          );
                        }}
                      ></textarea>
                    </div>
                    <small className="text-danger">
                      {sendMessageValidation?.message?.message
                        ? `Message ${sendMessageValidation?.message?.message}`
                        : ""}
                    </small>
                    <div className="form-check mt-1">
                      <input
                        autoComplete="off"
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                        value={greetingMsg?.status}
                        onChange={() => {
                          setGreetingMsgValue("status", "Active");
                        }}
                      />
                      <label className="form-check-label" htmlFor="gridCheck1">
                        Set as default greeting
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setSendMessageModal(false);
                    setSendMessageValidation({});
                    setGreetingMsg([]);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn"
                  onClick={handleSendMessage}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={GreetingMessageModal}
        onClickOutside={() => {
          setGreetingMessageModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">Greetings</h5>
            </div>
            <div className="container">
              <div className="row d-flex  justify-content-center modal-scroll-content mt-3">
                {allGreeting ? (
                  allGreeting?.map((e) => (
                    <div className="col-lg-6">
                      <div className="Greeting-section border rounded p-4">
                        <div class="form-check form-check-inline">
                          <label
                            class="form-check-label"
                            for="inlineRadio1"
                            onClick={() => {
                              fetchParticularGreeting(e.greetingId);
                              setGreetingMessageModal(false);
                              setSendMessageModal(true);
                            }}
                          >
                            {e.message}
                          </label>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-12 mt-4">
                    <h6 className="text-center font-weight-bold my-2">
                      <img
                        style={{
                          width: "85px",
                          textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                        }}
                        src={Roboimg}
                        alt=""
                      />
                    </h6>
                    <br />
                    <div>
                      <h6 className="text-center font-weight-bold my-2">
                        No greeting message available
                      </h6>
                    </div>
                  </div>
                )}
                <div className="col-lg-6"></div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setGreetingMessageModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
