import React from "react";
import Navbar from "./navbar";
import { Link } from "react-router-dom";
import { useState } from "react";
import Roboimg from "../images/roboimg.png";
import Google from "../images/google.png";
import Linkedin from "../images/linkedin.png";
import Apple from "../images/apple-logo.png";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { loginSuccess } from "./Store/auth/action";
import { UserLogin } from "./Api/Api";
import CustomModal from "./CustomModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BgButterfly from "../images/bg-butterfly.png";
export default function LoginEmployer() {
  const [loginFailureModal, setloginFailureModal] = useState(false);
  const [loginRegisterModal, setloginRegisterModal] = useState(false);
  // Navigation
  const navigation = useNavigate();

  const {
    register,
    handleSubmit,
    // reset,
    // control,
    formState: { errors },
  } = useForm();

  const [errorMsg, setErrorMsg] = useState();

  const dispatch = useDispatch();

  // handle submit
  const onSubmit = async (data) => {
    const Payload = {
      emailId: data.email,
      password: data.password,
    };

    const response = await UserLogin(Payload);
    if (response && response.statusCode === 200) {
      dispatch(loginSuccess(response.data, response?.data?.token));
      const ApiData = response.data;
      if (ApiData.actorType === "Employee") {
        navigation("/ProfileEmployee");
      } else if (ApiData.actorType === "Admin") {
        navigation("/Admin/JobSeekers");
      } else {
        navigation({ pathname: "/Profile", search: `tab=Basic Details` });
      }
    } else {
      showToastErrorMsg(
        response.message
      );
      setErrorMsg();
      // setErrorMsg(response.message || response.error.response.data.message);
      // setloginFailureModal(true);
      //  setSuccessMsg("")
    }
  };
  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };
  //success
  // const showToastSuccessMsg = (successMsg) => {
  //   toast.success(successMsg, {
  //     position: toast.POSITION.TOP_RIGHT,
  //     className: "toast-message",
  //     autoClose: 5000,
  //   });
  // };

  return (
    <div>
      <Navbar />
      <div className="Banner d-flex align-items-center">
        {/* <img src={BannerImg} style={{width:'100%',height:'auto',position:'absolute'}} /> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-5">
              <div className="section1 section1-regEmp my-5 p-4">
                <img className="mt-3" src={Roboimg} alt="" />
                <h1>
                  Hi There! Lets login
                  <br /> to build your dreams!
                </h1>
                <p>
                  Do you know that, EADJobs.us is the only platform <br />
                  to find pre-qualified EAD candidates who can join immediately{" "}
                  <br />
                  matched to roles that are right for you.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-7 m-auto">
              <div className="section2 my-4 ">
                <h5 className="text-left font-weight-bold  mx-5">Welcome!</h5>
                <h6 className="text-left  mx-5">
                  New to EADJobs.us ?
                  {/* <span className="reg-text-clr pl-1" style={{ cursor: "pointer" }} onClick={() => navigation("/RegisterEmployer")}>
                    Register Here
                  </span> */}
                  <span
                    className="reg-text-clr pl-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setloginRegisterModal(true);
                    }}
                  >
                    Register Here
                  </span>
                </h6>
                <form className="my-4 mx-5" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-container">
                    <div className="google-input">
                      <input
                        autoComplete="off"
                        type="text"
                        id="emailId"
                        placeholder="Enter your email ID"
                        {...register("email", {
                          required: "Email is Required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please enter a valid email",
                          },
                        })}
                      />
                      <label htmlFor="emailId">Registered Email ID</label>
                    </div>
                    <small className="text-danger">
                      {errors.email?.message}
                    </small>
                    <div className="google-input">
                      <input
                        autoComplete="off"
                        type="password"
                        id="password"
                        placeholder="Password"
                        {...register("password", {
                          required: "Password is Required",

                          // validate: (value) => {
                          //   return [/[a-z]/, /[A-Z]/, /[0-9]/].every((pattern) => pattern.test(value)) || "cannot special chars, only lower, upper, number";
                          // },
                        })}
                      />
                      <label htmlFor="password">Enter your password</label>
                    </div>
                    <small className="text-danger">
                      {errors.password?.message}
                    </small>
                    <div className="google-input-pswd">
                      <span
                        className="fgtpwd"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigation("/ForgotPassword")}
                      >
                        Forgot Password?
                      </span>
                    </div>
                    <div className="google-input">
                      <button
                        type="submit"
                        className="btn btn-light my-2 my-sm-0 login-btn"
                        // onClick={() => naviGation("/RegisterEmployer")}
                      >
                        Login
                      </button>
                      <ToastContainer />
                    </div>
                  </div>
                </form>
                <h4 className="lined-text my-2 mx-5">Or</h4>
                <div className="row login-btm-padd justify-content-center">
                  <div className="col-md-8 text-center">
                    <li className="img-icon">
                      <img src={Google} alt="" />
                    </li>
                    <li className="img-icon">
                      <img src={Apple} alt="" />
                    </li>
                    <li className="img-icon">
                      <img src={Linkedin} alt="" />
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={loginFailureModal}
        onClickOutside={() => {
          setloginFailureModal(false);
        }}
      >
        <div className="modal-content w-50  m-auto">
          <div className="modal-body p-0 ">
            <div className="modalHeader succ-failure">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                onClick={() => {
                  setloginFailureModal(false);
                }}
              >
                close
              </span>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  src={Roboimg}
                  alt=""
                />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 py-4">
                  <h3 className="font-weight-bold text-center">Sorry!</h3>
                  <p className="font-weight-bold text-center">{errorMsg}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={loginRegisterModal}
        onClickOutside={() => {
          setloginRegisterModal(false);
        }}
      >
        <div className="modal-content w-100  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                onClick={() => {
                  setloginRegisterModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-6 col-md-6 modal-register-bg m-auto">
                  <div className="section1 ">
                    <img
                      className="mt-3"
                      style={{ width: "100px", height: "100px" }}
                      src={Roboimg}
                      alt=""
                    />
                    <h1>
                      Howdy! I’m Quantum, <br /> your personal recruiter who
                      never sleeps!
                    </h1>
                    <p>
                      With my AI/ML capabilities <br /> we can achieve your
                      goals with minimal effort!{" "}
                    </p>
                    <img
                      src={BgButterfly}
                      className="modal-register-bg-image"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6  m-auto">
                  <div
                    className="section2"
                    style={{ boxShadow: "0px 3px 6px #00000017" }}
                  >
                    <h5 className="text-center pt-0 font-weight-bold mx-4">
                      How can I help?
                    </h5>
                    <div className="mx-4 pb-pt-form">
                      <Link to="/RegisterEmployee">
                        <button className="btn btn-light home-rdt-btn">
                          I’m exploring jobs
                        </button>
                      </Link>
                      <Link to="/RegisterEmployer">
                        <button className="btn btn-light home-rdt-btn">
                          I’m recruiting
                        </button>
                      </Link>
                      <Link to="">
                        <button className="btn btn-light home-rdt-btn">
                          I'm conducting interviews
                        </button>
                      </Link>
                      <Link to="">
                        <button className="btn btn-light home-rdt-btn">
                          I’m building resumes
                        </button>
                      </Link>
                      <Link to="">
                        <button className="btn btn-light home-rdt-btn">
                          I’m mentoring job seekers
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
