/* eslint-disable array-callback-return */
import React, { useEffect, useCallback, useState } from "react";
import NavbarEmployee from "./NavbarEmployee";
import CompLogo from "../../images/google.png";
import { useNavigate } from "react-router-dom";
import {
  CompanyDetailsEmployee,
  IndustryCompanyEmployee,
  ParticularCompanyDetailsEmployee,
  SearchCompany,
} from "../Api/Api";
import { useSelector } from "react-redux";
import Roboimg2 from "../../images/roboimg-outerborder.png";
import StarRatings from "react-star-ratings";
import { GetInitials } from "../Store/Constants";
export default function CompaniesEmployee() {
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const [listingData, setListingData] = useState([]);
  //  const [searchInput,setSearchInput] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [clickResult, setClickResult] = useState(false);
  const [companySort, setCompanySort] = useState("newest");
  const Navigate = useNavigate();

  // Find all
  const handleAllCompany = useCallback(async () => {
    if (!authKey) return;

    const response = await CompanyDetailsEmployee(authKey, companySort);
    if (response && response.statusCode === 200) {
      let newData = response?.data?.companyListing;
      setListingData(newData);
    } else {
      setListingData([]);
    }
  }, [authKey, companySort]);

  useEffect(() => {
    handleAllCompany();
    return () => {};
  }, [companySort, handleAllCompany]);

  const [particularIndustry, setParticularIndustry] = useState([])

  const handleParticularCompany = async (value) => {
    const token = state?.auth?.auth?.authKey;
    const industryId = value
    const response = await ParticularCompanyDetailsEmployee(token, companySort, industryId);
    if (response && response.statusCode === 200) {
      let newData = response?.data?.companyListing;
      setParticularIndustry(newData);
    } else {
      setParticularIndustry([]);
    }
  };

  //Find all
  const handleAllIndustry = useCallback(async () => {
    if (!authKey) return;

    const response = await IndustryCompanyEmployee(authKey);
    if (response && response.statusCode === 200) {
      setIndustryData(response.data);
    } else {
      setIndustryData([]);
    }
  }, [authKey]);

  useEffect(() => {
    handleAllIndustry();
    return () => {};
  }, [handleAllIndustry]);

  //Find all
  const searchHandle = async (e) => {
    e.preventDefault();
    const token = state?.auth?.auth?.authKey;
    const response = await SearchCompany(token);
    if (response && response.statusCode === 200) {
      // console.log(response.data,"response");
      // setListingData(response.data);
    } else {
      //setListingData([])
    }
  };



  return (
    <div className="dashboard-bg">
      <NavbarEmployee />
      <div className="main ce-section">
        <div className="faq-section  scroll-content  py-4">
          <div className="container">
            <h2 className="font-weight-bold text-center">
              Find great places to work
            </h2>
            <h6 className=" text-center">
              Get access to millions of company reviews
            </h6>
            <div className="container-fluid">
              <div className="row my-3">
                <div className="col-lg-6 mx-auto col-md-9 col-12">
                  <div className="d-flex flex-sm-row flex-column  justify-content-between">
                    <div className="input-group flex-nowrap mr-4">
                      <div className="input-group-prepend">
                        <span className="input-group-text material-symbols-rounded">
                          search
                        </span>
                      </div>
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="addon-wrapping"
                      />
                    </div>
                    <button className="btn btn-light my-2 my-sm-0  login-btn">
                      Discover Companies
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {clickResult === false && (
              <div className="container-fluid">
                <h5 className="font-weight-bold py-3">
                  Top Companies by Industry
                </h5>
                <div className="row">
                  {industryData.map((data, index) => {
                    return (
                      <div
                        className="col-lg-4 ce-card-head"
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setClickResult(true);
                          handleParticularCompany(data.masterDataId);
                        }}
                      >
                        <div className="ce-card text-center my-3 p-3">
                          <span className="material-symbols-rounded  ce-font text-green">
                            assured_workload
                          </span>
                          <h6 className="font-weight-bold  text-center cut-text2">
                            {data.name}
                          </h6>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="text-center mt-4 mb-2">
                  {/* <Link to="/CompaniesIndividualPage"> */}
                  <button className="btn btn-light my-2 my-sm-0  login-btn">
                    See all Industries
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            )}
          </div>
          {clickResult === false && (
            <div className="container">
              <div className="d-flex flex-sm-row flex-column align-items-center justify-content-between">
                <h5 className="font-weight-bold py-3">
                  Top Companies by Industry
                </h5>
                <div className="d-flex align-items-center">
                  <p className="mb-0 mr-2">Sort by </p>
                  <div className="mb-0  form-group">
                    <select
                      className="form-control px-2 font-weight-bold text-green"
                      onChange={(e) => setCompanySort(e.target.value)}
                    >
                      <option value="newest">NEWEST</option>
                      <option value="oldest">OLDEST</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                {listingData?.map((data, index) => {
                  let ratingCount = 0;
                  let ratingCountLength = 0;
                  data?.overRating?.map((rating, index) => {
                    ratingCount += parseInt(rating?.overRating);
                    ratingCountLength = index;
                   
                  });
                  return (
                    <div className="col-lg-4 my-3" key={index}>
                      <div
                        className="ce-card2 p-3"
                        onClick={() => {
                          Navigate(
                            `/CompaniesIndividualPage/${data.companyId}`
                          );
                        }}
                      >
                        <div className="d-flex">
                          <div className="all-comp-section cursor">
                            {data?.companyLogo === "" ? (
                              <h2 className="compworked-img cursor ci-bannerLogo font-weight-bold">
                                {GetInitials(data?.companyName)}
                              </h2>
                            ) : (
                              <img
                                className="ci-bannerLogo"
                                src={data?.companyLogo}
                                alt=""
                                hidden={data?.companyLogo === ""}
                              />
                            )}
                          </div>
                          <div className="pl-2">
                            <h5 className="font-weight-bold my-0 cursor" z>
                              {data?.companyName}
                            </h5>
                            <div className="d-flex align-items-center">
                              <h5 className="my-0 font-weight-bold ">
                                {Math.round(ratingCount / (ratingCountLength + 1))}
                              </h5>
                              &nbsp;
                              <div className="rate-area">
                                <StarRatings
                                  rating={ratingCount / (ratingCountLength + 1)}
                                  starDimension="20px"
                                  starSpacing="1px"
                                  starRatedColor="#e75f6b"
                                />
                              </div>
                            </div>
                            <small className="my-0 ">
                              {data?.companyIndustryName}
                            </small>
                            <div className="d-flex justify-content-between">
                              <h6
                                className="text-green my-0 mr-1 chck-outline-hover "
                                onClick={() => {
                                  Navigate(
                                    `/CompaniesIndividualPage/${data.companyId}?tab=Jobs`
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <u>Active Jobs {data?.activeJobCount}</u>
                              </h6>
                              {/* <h6 className="text-green my-0 ml-1 chck-outline-hover ">
                                <u>Location {data?.headQuarterslocation}</u>
                              </h6> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {clickResult === true && (
            <>
              <div className="container">
                <div className="d-flex flex-sm-row flex-column   align-items-center justify-content-between">
                  <h5 className="font-weight-bold py-3">
                    Top Companies by Industry
                  </h5>
                  <div className="d-flex align-items-center">
                    <p className="mb-0 mr-2">Sort by </p>
                    <div className="mb-0  form-group">
                      <select
                        className="form-control px-2 font-weight-bold text-green"
                        onChange={(e) => setCompanySort(e.target.value)}
                      >
                        <option value="newest">NEWEST</option>
                        <option value="oldest">OLDEST</option>
                      </select>
                    </div>
                  </div>
                </div>
                {particularIndustry?.length > 0
                ?
                (
                  <div className="row">
                    {particularIndustry?.map((data, index) => {
                      let ratingCount = 0;
                      let ratingCountLength = 0;
                      data?.overRating?.map((rating, index) => {
                        ratingCount += parseInt(rating.overRating);
                        ratingCountLength = index;
                      });
                      return (
                        <div
                          className="col-lg-4 col-md-6 my-3"
                          key={index}
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="ce-card2 p-3"
                            onClick={() => {
                              Navigate(
                                `/CompaniesIndividualPage/${data.companyId}`
                              );
                            }}
                          >
                            <div className="d-flex">
                              <div className="all-comp-section">
                                {data?.companyLogo === "" ? (
                                  <h2 className="compworked-img ci-bannerLogo font-weight-bold">
                                    {GetInitials(data?.companyName)}
                                  </h2>
                                ) : (
                                  <img
                                    className="ci-bannerLogo"
                                    src={data?.companyLogo}
                                    alt=""
                                    hidden={data?.companyLogo === ""}
                                  />
                                )}
                              </div>
                              <div className="pl-2">
                                <h5 className="font-weight-bold my-0">
                                  {data?.companyName}
                                </h5>
                                <div className="d-flex align-items-center">
                                  <h5 className="my-0 font-weight-bold ">
                                  {Math.round(ratingCount / (ratingCountLength + 1))}
                                  </h5>
                                  <div className="rate-area ">
                                    <StarRatings
                                      rating={
                                       ratingCount / (ratingCountLength + 1)
                                      }
                                      starDimension="20px"
                                      starSpacing="1px"
                                      starRatedColor="#e75f6b"
                                    />
                                  </div>
                                </div>
                                <small className="my-0 ">
                                  {data?.companyIndustryName}
                                </small>
                                <div className="d-flex justify-content-between">
                                  <h6 className="text-green my-0 mr-1 chck-outline-hover ">
                                    <u>Active Jobs {data?.activeJobCount}</u>
                                  </h6>
                                  {/* <h6 className="text-green my-0 ml-1 chck-outline-hover ">
                                    <u>Locations 3</u>
                                  </h6> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )
                : 
                (
                  <div className="row justify-content-center">
                    <div className="col-lg-12 mt-4">
                      <h6 className="text-center font-weight-bold my-2">
                        <img
                          style={{
                            width: "85px",
                            textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px;",
                          }}
                          src={Roboimg2}
                          alt=""
                        />
                      </h6>
                      <br />
                      <div>
                        <h6 className="text-center font-weight-bold my-2">
                          No Companies Found
                        </h6>
                      </div>
                    </div>
                  </div>
                ) 
                }
              </div>
              <div className="text-center mt-4 mb-2">
                {/* <Link to="/CompaniesIndividualPage"> */}
                <button
                  className="btn btn-light my-2 my-sm-0  login-btn"
                  onClick={() => {
                    setParticularIndustry([])
                    setClickResult(false);
                    
                  }}
                >
                  Back
                </button>
                {/* </Link> */}
              </div>
            </>
          )}
          {clickResult === false && (
            <div className="container">
              <div className="row my-3">
                <div className="col-lg-6 col-md-9 col-12">
                  <h5 className="font-weight-bold py-3">Company name</h5>
                  <form onSubmit={(e) => searchHandle(e)}>
                    <div className="d-flex flex-sm-row flex-column justify-content-between">
                      <div className="input-group flex-nowrap mr-4">
                        <div className="input-group-prepend">
                          <span className="input-group-text material-symbols-rounded">
                            search
                          </span>
                        </div>
                        {/* <input autoComplete="off" type="text" className="form-control" placeholder="Search" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e)=>setSearchInput(e.target.value)}/> */}
                      </div>
                      <button
                        type="submit"
                        className="btn btn-light my-2 my-sm-0  login-btn"
                      >
                        Discover Companies
                      </button>
                    </div>
                  </form>
                  <h6 className="font-weight-bold mt-4">
                    Popular companies for Google
                  </h6>
                  <h6 className="">
                    Based on reviews and recent job openings on indeed
                  </h6>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-6 my-3">
                  <div className="ce-card2 p-3">
                    <div className="d-flex">
                      <div className="all-comp-section">
                        <img className="ci-bannerLogo" src={CompLogo} alt="" />
                      </div>
                      <div className="pl-2">
                        <h5 className="font-weight-bold my-0">Google</h5>
                        <div className="d-flex align-items-center">
                          <h5 className="my-0 font-weight-bold ">4.0</h5>
                          <div className="rate-area ">
                            <input
                              autoComplete="off"
                              type="radio"
                              id="5-star"
                              name="rating"
                              value="5"
                            />
                            <label for="5-star" title="Amazing">
                              5 stars
                            </label>
                            <input
                              autoComplete="off"
                              type="radio"
                              id="4-star"
                              name="rating"
                              value="4"
                            />
                            <label for="4-star" title="Good">
                              4 stars
                            </label>
                            <input
                              autoComplete="off"
                              type="radio"
                              id="3-star"
                              name="rating"
                              value="3"
                            />
                            <label for="3-star" title="Average">
                              3 stars
                            </label>
                            <input
                              autoComplete="off"
                              type="radio"
                              id="2-star"
                              name="rating"
                              value="2"
                            />
                            <label for="2-star" title="Not Good">
                              2 stars
                            </label>
                            <input
                              autoComplete="off"
                              type="radio"
                              id="1-star"
                              name="rating"
                              value="1"
                            />
                            <label for="1-star" title="Bad">
                              1 star
                            </label>
                          </div>
                        </div>
                        <small className="my-0 ">Frontend Developer</small>
                        <div className="d-flex justify-content-between">
                          <h6 className="text-green my-0 mr-1 chck-outline-hover ">
                            <u>Active Jobs 10</u>
                          </h6>
                          {/* <h6 className="text-green my-0 ml-1 chck-outline-hover ">
                            <u>Locations 3</u>
                          </h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 my-3">
                  <div className="ce-card2 p-3">
                    <div className="d-flex">
                      <div className="all-comp-section">
                        <img className="ci-bannerLogo" src={CompLogo} alt="" />
                      </div>
                      <div className="pl-2">
                        <h5 className="font-weight-bold my-0">Google</h5>
                        <div className="d-flex align-items-center">
                          <h5 className="my-0 font-weight-bold ">4.0</h5>
                          <div className="rate-area ">
                            <input
                              autoComplete="off"
                              type="radio"
                              id="5-star"
                              name="rating"
                              value="5"
                            />
                            <label for="5-star" title="Amazing">
                              5 stars
                            </label>
                            <input
                              autoComplete="off"
                              type="radio"
                              id="4-star"
                              name="rating"
                              value="4"
                            />
                            <label for="4-star" title="Good">
                              4 stars
                            </label>
                            <input
                              autoComplete="off"
                              type="radio"
                              id="3-star"
                              name="rating"
                              value="3"
                            />
                            <label for="3-star" title="Average">
                              3 stars
                            </label>
                            <input
                              autoComplete="off"
                              type="radio"
                              id="2-star"
                              name="rating"
                              value="2"
                            />
                            <label for="2-star" title="Not Good">
                              2 stars
                            </label>
                            <input
                              autoComplete="off"
                              type="radio"
                              id="1-star"
                              name="rating"
                              value="1"
                            />
                            <label for="1-star" title="Bad">
                              1 star
                            </label>
                          </div>
                        </div>
                        <small className="my-0 ">Frontend Developer</small>
                        <div className="d-flex justify-content-between">
                          <h6 className="text-green my-0 mr-1 chck-outline-hover ">
                            <u>Active Jobs 10</u>
                          </h6>
                          {/* <h6 className="text-green my-0 ml-1 chck-outline-hover ">
                            <u>Locations 3</u>
                          </h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
