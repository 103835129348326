import React, { useState, useCallback, useEffect } from "react";
import CustomModal from "../../CustomModal";
import { useSelector } from "react-redux";
import {
  CityValidation,
  CompanyNameValidation,
  NonEmptyObjectValidation,
  NonEmptyValidation,
  NonEmptyValidationForDate,
  NonEmptyValidationForEndDate,
  StringValidation,
} from "../../Store/validate";
import {
  CreateCompanyName,
  CreateEmployeeSkills,
  CreateEmploymentDetailsPost,
  DeleteEmploymentDetails,
  GetCompanyName,
  GetEmploymentDetailsPost,
  GetMasterDatasByType,
  GetParticularEmploymentDetails,
  UpdateEmploymentDetails,
} from "../../Api/Api";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import Roboimg2 from "../../../images/roboimg-outerborder.png";

const EmploymentDetails = (props) => {
  const { showToastError, showToastSuccess, skills } = props;
  //state value
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const userProfileId = state?.auth?.auth?.userProfileId;

  // currently working
  const [companyName, setCompanyName] = useState([]);
  const [deleteEmployModal, setDeleteEmployModal] = useState(false);
  const [employId, setEmployId] = useState("");
  const [jobTitle, setjobTitle] = useState([]);
  const [getEmploymentDetails, setGetEmploymentDetails] = useState([]);
  const [EmploymentDetailsData, setEmploymentDetailsData] = useState({
    currentlyWorking: false,
  });
  const [AddEmploymentModal, setAddEmploymentModal] = useState(false);
  const [EditEmploymentModal, setEditEmploymentModal] = useState(false);
  const [EmploymentDetailsValidationData, setEmploymentDetailsValidationData] =
    useState({});
  const [getParticularEmploymentDetails, setParticularGetEmploymentDetails] =
    useState({});
  const [
    particularEmploymentDetailsValidation,
    setParticularEmploymentDetailsValidation,
  ] = useState({});
  const [skillDeleteModalStatus, setSkillDeleteModalStatus] = useState(false);
  const [deleteSkillData, setDeleteSkillData] = useState("");
  const onChangeEmploymentDetailsData = (key, value) => {
    setEmploymentDetailsData({ ...EmploymentDetailsData, [key]: value });
    if (EmploymentDetailsValidationData[key])
      delete EmploymentDetailsValidationData[key];
  };

  const onChangeEmploymentDetailsValidationData = (key, value) => {
    setEmploymentDetailsValidationData({
      ...EmploymentDetailsValidationData,
      [key]: value,
    });
  };
  
  const handleCreateSkills = async (e, type) => {
    const valid = NonEmptyObjectValidation(e);
    onChangeEmploymentDetailsValidationData("skill", valid);
    if (valid.status) {
      const response = await props.handleCreateSkills(e);
      if (response && response.statusCode === 200) {
        if (type === "create") {
          if (EmploymentDetailsData?.skill?.length) {
            let data = EmploymentDetailsData?.skill;
            data.push(response.data);
            onChangeEmploymentDetailsData("skill", data);
          } else {
            onChangeEmploymentDetailsData("skill", [response.data]);
          }
        } else {
          if (getParticularEmploymentDetails?.skill?.length) {
            let data = getParticularEmploymentDetails?.skill;
            data.push(response.data);
            onChangeParticularEmploymentDetails("skill", data);
          } else {
            onChangeParticularEmploymentDetails("skill", [response.data]);
          }
        }
      }
    }
  };
  //get job title dropdown list

  const fetchData1 = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const Query = `authKey=${authKey}&masterDataType=job_title&status=Active&restrictionLevel=0&userId=${userProfileId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          masterDataId: e.masterDataId,
        };
      });
      setjobTitle(mappingData);
    }
  }, [authKey, userProfileId]);

  const handleJobTitle = async (string, type) => {
    const valid = StringValidation(string);
    onChangeEmploymentDetailsValidationData("jobTitle", valid);
    if (valid.status) {
      const Payload = {
        masterDataType: "job_title",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 0,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchData1();
        if (type === "create") {
          onChangeEmploymentDetailsData("jobTitle", {
            value: response.data.name,
            label: response.data.name,
            masterDataId: response.data.masterDataId,
          });
        } else {
          onChangeParticularEmploymentDetails("jobTitle", {
            value: response.data.name,
            label: response.data.name,
            masterDataId: response.data.masterDataId,
          });
        }
      }
    }
  };

  const fetchAllEmploymentDetailsData = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const getEmployeeSkills = await GetEmploymentDetailsPost(authKey, userProfileId);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      const sortedValue = getEmployeeSkills.data.sort(
        (a, b) => new Date(b.workingSince) - new Date(a.workingSince)
      );
      setGetEmploymentDetails(sortedValue);
    } else {
      setGetEmploymentDetails([]);
    }
  }, [authKey, userProfileId]);

  //get particular employment details

  async function fetchParticularEmpDetails(value) {
    const token = state?.auth?.auth?.authKey;
    const userEmploymentDetailId = value;
    const getEmployeeSkills = await GetParticularEmploymentDetails(
      token,
      userEmploymentDetailId
    );
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      setParticularGetEmploymentDetails(getEmployeeSkills.data);
    }
  }
  const onChangeParticularEmploymentDetails = (key, value) => {
    setParticularGetEmploymentDetails({
      ...getParticularEmploymentDetails,
      [key]: value,
    });
    if (particularEmploymentDetailsValidation[key])
      delete particularEmploymentDetailsValidation[key];
  };

  const onChangeParticularEmploymentDetailsValidation = (key, value) => {
    setParticularEmploymentDetailsValidation({
      ...particularEmploymentDetailsValidation,
      [key]: value,
    });
  };

  //delete employment details
  const handleDeleteEmpDetails = async (value) => {
    const Payload = {
      userEmploymentDetailId: value,
    };
    const token = state?.auth?.auth?.authKey;
    const response = await DeleteEmploymentDetails(Payload, token);
    if (response && response.statusCode === 200) {
      setDeleteEmployModal(false);
      showToastSuccess(response.message);
      fetchAllEmploymentDetailsData();
    } else {
      showToastError(response.message || response.error.response.data.message);
    }
  };

  const handleCreateCity = async (e, type) => {
    const valid = CityValidation(e);
    onChangeEmploymentDetailsValidationData("city", valid);
    if (valid.status) {
      const response = await props.handleCreateCity(e);
      if (response && response.statusCode === 200) {
        if (type === "create") {
          onChangeEmploymentDetailsData("city", {
            masterDataId: response.data.masterDataId,
            name: response.data.name,
          });
        } else {
          onChangeParticularEmploymentDetails("city", {
            masterDataId: response.data.masterDataId,
            name: response.data.name,
          });
        }
      }
    } else {
      onChangeEmploymentDetailsValidationData("city", valid);
    }
  };
  const handleEmploymentDetails = async () => {
    let validate = {};
    validate.companyName = NonEmptyObjectValidation(
      EmploymentDetailsData.companyName
    );
    validate.city = NonEmptyObjectValidation(EmploymentDetailsData.city);
    validate.jobTitle = NonEmptyObjectValidation(
      EmploymentDetailsData.jobTitle
    );
    validate.workingSince = NonEmptyValidationForDate(
      EmploymentDetailsData.workingSince
    );
    validate.skill = NonEmptyObjectValidation(EmploymentDetailsData.skill);
    if (EmploymentDetailsData.currentlyWorking === false) {
      validate.workingTo = NonEmptyValidationForEndDate(
        EmploymentDetailsData.workingTo
      );
    }

    validate.description = NonEmptyValidation(
      EmploymentDetailsData.description
    );
    setEmploymentDetailsValidationData(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        userProfileId: state.auth.auth.userDetails?.userProfileId,
        companyId:
          EmploymentDetailsData?.companyName?.companyId ||
          EmploymentDetailsData?.companyName?.dataId,
        referenceJobId: EmploymentDetailsData?.jobTitle?.masterDataId,
        cityId:
          EmploymentDetailsData.city?.masterDataId ||
          EmploymentDetailsData.city?.dataId,
        currentlyWorking: EmploymentDetailsData.currentlyWorking,
        workingSince: EmploymentDetailsData?.workingSince,
        workingTo: EmploymentDetailsData?.workingTo,
        description: EmploymentDetailsData?.description,
        skill: EmploymentDetailsData?.skill?.map((e) =>
          e.masterDataId.toString()
        ),
      };

      const token = state?.auth?.auth?.authKey;

      const response = await CreateEmploymentDetailsPost(Payload, token);

      if (response && response.statusCode === 200) {
        fetchAllEmploymentDetailsData();
        setAddEmploymentModal(false);
        showToastSuccess(response.message);
        setEmploymentDetailsData({ currentlyStudying: false });
      } else {
        showToastError(
          response.message || response.error.response.data.message
        );
      }
    }
  };
  const handleUpdateEmploymentDetails = async () => {
    let validate = {};
    validate.companyName = NonEmptyObjectValidation(
      getParticularEmploymentDetails.companyName
    );
    validate.city = NonEmptyObjectValidation(
      getParticularEmploymentDetails.city
    );
    validate.jobTitle = NonEmptyObjectValidation(
      getParticularEmploymentDetails.jobTitle
    );
    validate.workingSince = NonEmptyValidationForDate(
      getParticularEmploymentDetails.workingSince
    );
    validate.skill = NonEmptyObjectValidation(
      getParticularEmploymentDetails.skill
    );
    if (getParticularEmploymentDetails.currentlyWorking === false) {
      validate.workingTo = NonEmptyValidationForEndDate(
        getParticularEmploymentDetails.workingTo
      );
    }

    validate.description = NonEmptyValidation(
      getParticularEmploymentDetails.description
    );
    setParticularEmploymentDetailsValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let Payload = {
        userProfileId: state.auth.auth.userDetails?.userProfileId,
        companyId: getParticularEmploymentDetails.companyName.companyId,
        referenceJobId: getParticularEmploymentDetails?.jobTitle?.masterDataId,
        cityId: getParticularEmploymentDetails.city?.masterDataId,
        currentlyWorking: getParticularEmploymentDetails.currentlyWorking,
        workingSince: getParticularEmploymentDetails?.workingSince,
        workingTo: getParticularEmploymentDetails?.workingTo
          ? getParticularEmploymentDetails?.workingTo
          : undefined,
        description: getParticularEmploymentDetails?.description,
        skill: getParticularEmploymentDetails?.skill?.map((e) =>
          e.masterDataId.toString()
        ),
        userEmploymentDetailId:
          getParticularEmploymentDetails?.userEmploymentDetailId,
      };

      const token = state?.auth?.auth?.authKey;

      const response = await UpdateEmploymentDetails(Payload, token);

      if (response && response.statusCode === 200) {
        fetchAllEmploymentDetailsData();
        setEditEmploymentModal(false);
        showToastSuccess(response.message);
        setEmploymentDetailsData({ currentlyStudying: false });
      } else {
        showToastError(
          response.message || response.error.response.data.message
        );
      }
    }
  };
  const handleDeleteEmploymentDetailsSkill = async (data) => {
    let Payload = {
      userProfileId: state.auth.auth.userDetails?.userProfileId,
      skill: data?.skill?.map((e) => e.masterDataId.toString()),
      userEmploymentDetailId: data?.userEmploymentDetailId,
    };
    const token = state?.auth?.auth?.authKey;
    const response = await UpdateEmploymentDetails(Payload, token);
    if (response && response.statusCode === 200) {
      fetchAllEmploymentDetailsData();
      setSkillDeleteModalStatus(false);
      showToastSuccess(response.message);
    } else {
      showToastError(response.message || response.error.response.data.message);
    }
  };
  async function fetchCompanyName() {
    const response = await GetCompanyName();
    if (response && response.statusCode === 200) {
      setCompanyName(response.data);
    } else {
      setCompanyName([]);
    }
  }
  const handleCreateCompanyName = async (string, type) => {
    const valid = CompanyNameValidation(string);
    onChangeEmploymentDetailsValidationData("companyName", valid);
    if (valid.status) {
      const Payload = {
        companyName: string,
      };
      const response = await CreateCompanyName(Payload);
      if (response && response.statusCode === 200) {
        fetchCompanyName();
        const data = {
          value: response.data.companyName,
          label: response.data.companyName,
          dataId: response.data.companyId,
        };
        if (type === "create") {
          onChangeEmploymentDetailsData("companyName", data);
        } else {
          onChangeParticularEmploymentDetails("companyName", data);
        }
      }
    }
  };
  const handleDeleteSkill = async () => {
    try {
      let data = deleteSkillData;
      data.data.skill = data.data.skill.filter(
        (item) => item?.masterDataId !== data.masterDataId
      );
      handleDeleteEmploymentDetailsSkill(data.data);
    } catch (e) {
      console.log(e, "Error while deleting the skill");
    }
  };
  useEffect(() => {
    fetchData1();
    fetchAllEmploymentDetailsData();
    fetchCompanyName();
  }, [fetchData1, fetchAllEmploymentDetailsData]);

  return (
    <div>
      <div className="container py-4">
        <div className="row justify-content-center d-flex align-items-center ">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-2 col-md-3 col-4 my-2 text-center">
                <div className="upload-btn-wrapper">
                  <button
                    className="btn upload-gallery"
                    onClick={() => {
                      setAddEmploymentModal(true);
                    }}
                  >
                    <h1>
                      <span className="material-symbols-rounded">add</span>
                    </h1>
                    <span className="content">Add Position</span>
                  </button>
                </div>
              </div>
              <div className="col-lg-10 scroll-content-design">
                <div className="edudetails-section">
                  <div className="row  ">
                    {getEmploymentDetails?.map((e, index) => (
                      <div className="col-lg-6 col-md-6 px-1 my-2" key={index}>
                        <div className="edudetails-card">
                          <div className="d-flex  align-items-center">
                            <span className="material-symbols-rounded comp-icon">
                              apartment
                            </span>
                            <div className="px-3 w-100">
                              <div className="d-flex justify-content-between">
                                <h6 className="font-weight-bold">
                                  {e?.jobTitle?.name}
                                </h6>
                                <div className="icon">
                                  <span
                                    className="material-icons icon-middle edit  m-1"
                                    onClick={() => {
                                      setEditEmploymentModal(true);
                                      fetchParticularEmpDetails(
                                        e?.userEmploymentDetailId
                                      );
                                    }}
                                  >
                                    edit
                                  </span>
                                  <span
                                    className="material-symbols-rounded del icon-middle rounded m-1"
                                    onClick={() => {
                                      setDeleteEmployModal(true);
                                      setEmployId(e.userEmploymentDetailId);
                                    }}
                                  >
                                    delete
                                  </span>
                                </div>
                              </div>
                              <div>
                                <p className="cut-text mb-1  font-weight-bold">
                                  {e?.companyName?.companyName}
                                  <br />
                                  {e?.city?.name}
                                </p>
                                <p className="font-weight-bold ">
                                  {moment(e.workingSince).format("MMMM YYYY")}
                                  &nbsp;to&nbsp;
                                  {e.currentlyWorking === false
                                    ? moment(e.workingTo).format("MMMM YYYY")
                                    : "Till now"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <p className="mb-0 text-justify">{e.description}</p>
                          <div className="my-2">
                            {e?.skill?.map((v, i) => (
                              <small
                                key={i}
                                className="bg-color-green px-3 py-1 mx-1 my-1 "
                              >
                                {v.name}
                                <span
                                  className="material-symbols-rounded icon-middle"
                                  onClick={() => {
                                    setDeleteSkillData({
                                      masterDataId: v.masterDataId,
                                      data: e,
                                    });
                                    setSkillDeleteModalStatus(true);
                                  }}
                                >
                                  cancel
                                </span>
                              </small>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card my-1 d-lg-none d-block ">
        <div className="card-header" id="headingTwo">
          <h5 className="mb-0">
            <button
              className="btn btn-link text-left collapsed"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Employment Details
              <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                expand_more
              </span>
            </button>
          </h5>
        </div>
        <div
          id="collapseTwo"
          className="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordion"
        >
          <div className="card-body p-0">
            <div>
              <div className="container ">
                <div className="row justify-content-center d-flex align-items-center ">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-2 col-md-4 col-12 my-2 text-center">
                        <div className="upload-btn-wrapper">
                          <button
                            className="btn upload-gallery"
                            onClick={() => {
                              setAddEmploymentModal(true);
                            }}
                          >
                            <h5>
                              <span className="content">Add Position</span>
                            </h5>
                            <h4>
                              <span className="material-symbols-rounded">
                                add
                              </span>
                            </h4>
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-10 scroll-content-design">
                        <div className="edudetails-section">
                          <div className="row  ">
                            {getEmploymentDetails.map((e, i) => (
                              <div
                                className="col-lg-6 col-md-6 px-1 my-2"
                                key={i}
                              >
                                <div className="edudetails-card">
                                  <div className="d-flex  align-items-center">
                                    <span className="material-symbols-rounded comp-icon ">
                                      apartment
                                    </span>
                                    <div className="px-3">
                                      <div className="d-flex justify-content-between">
                                        <h6 className="font-weight-bold">
                                          {e?.jobTitle?.name}
                                        </h6>
                                        <div className="icon">
                                          <span
                                            className="material-icons icon-middle edit  m-1"
                                            onClick={() => {
                                              setEditEmploymentModal(true);
                                              fetchParticularEmpDetails(
                                                e?.userEmploymentDetailId
                                              );
                                            }}
                                          >
                                            edit
                                          </span>
                                          <span
                                            className="material-symbols-rounded del icon-middle rounded m-1"
                                            onClick={() => {
                                              setDeleteEmployModal(true);
                                              setEmployId(
                                                e.userEmploymentDetailId
                                              );
                                            }}
                                          >
                                            delete
                                          </span>
                                        </div>
                                      </div>
                                      <div>
                                        <p className="cut-text mb-1 font-weight-bold">
                                          {e.companyName?.companyName}
                                          <br />
                                          {e?.city?.name}
                                        </p>
                                        <p className="font-weight-bold">
                                          {moment(e.workingSince).format(
                                            "MMM YYYY"
                                          )}
                                          &nbsp;to&nbsp;
                                          {moment(e.to).format("MMM YYYY")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <p className="mb-0 text-justify">
                                    {e.description}
                                  </p>
                                  <div className="my-2">
                                    {e?.skill?.map((v, i) => (
                                      <small
                                        key={i}
                                        className="bg-color-green px-3 py-1 mx-1 my-1 "
                                      >
                                        {v.name}
                                        <span className="material-symbols-rounded icon-middle">
                                          cancel
                                        </span>
                                      </small>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={AddEmploymentModal}
        onClickOutside={() => {
          setEmploymentDetailsValidationData({});
          setAddEmploymentModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader py-3">
              <h5 className="text-center font-weight-bold my-0">
                Add Employment
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-11">
                  <form>
                    <div className="form-container pt-4 py-1">
                      <div className="row ">
                        <div className="col-lg-6 px-4 pb-4 ">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Company Name
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={companyName}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      EmploymentDetailsValidationData
                                        ?.companyName?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      EmploymentDetailsValidationData
                                        ?.companyName?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                onInputChange={() => {
                                  if (
                                    EmploymentDetailsValidationData[
                                      "companyName"
                                    ]
                                  )
                                    delete EmploymentDetailsValidationData[
                                      "companyName"
                                    ];
                                }}
                                getOptionLabel={(option) =>
                                  option.companyName || option.label
                                }
                                getOptionValue={(option) => option.companyId}
                                placeholder="Enter The Company name"
                                isClearable
                                value={EmploymentDetailsData.companyName}
                                onChange={(value) =>
                                  onChangeEmploymentDetailsData(
                                    "companyName",
                                    value
                                  )
                                }
                                onCreateOption={(e) =>
                                  handleCreateCompanyName(e, "create")
                                }
                              />
                              {EmploymentDetailsValidationData?.companyName
                                ?.status === false ? (
                                <small className="text-danger">
                                  Company Name&nbsp;
                                  {
                                    EmploymentDetailsValidationData?.companyName
                                      ?.message
                                  }
                                </small>
                              ) : null}
                            </div>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              City
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={props.cities}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      EmploymentDetailsValidationData?.city
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      EmploymentDetailsValidationData?.city
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                onInputChange={() => {
                                  if (EmploymentDetailsValidationData["city"])
                                    delete EmploymentDetailsValidationData[
                                      "city"
                                    ];
                                }}
                                placeholder="Enter The City"
                                isClearable
                                getOptionLabel={(option) =>
                                  option.name || option.label
                                }
                                getOptionValue={(option) => option.masterDataId}
                                value={EmploymentDetailsData.city}
                                onChange={(value) =>
                                  onChangeEmploymentDetailsData("city", value)
                                }
                                onCreateOption={(e) =>
                                  handleCreateCity(e, "create")
                                }
                              />
                            </div>
                            {EmploymentDetailsValidationData?.city?.status ===
                            false ? (
                              <small className="text-danger">
                                City&nbsp;
                                {EmploymentDetailsValidationData?.city?.message}
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 px-4 pb-4">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Job Title
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={jobTitle}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      EmploymentDetailsValidationData?.jobTitle
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      EmploymentDetailsValidationData?.jobTitle
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Enter Job Title"
                                value={EmploymentDetailsData.jobTitle}
                                onChange={(newValue) =>
                                  onChangeEmploymentDetailsData(
                                    "jobTitle",
                                    newValue
                                  )
                                }
                                onInputChange={() => {
                                  if (
                                    EmploymentDetailsValidationData["jobTitle"]
                                  )
                                    delete EmploymentDetailsValidationData[
                                      "jobTitle"
                                    ];
                                }}
                                onCreateOption={(string) =>
                                  handleJobTitle(string, "create")
                                }
                                isClearable
                              />
                            </div>
                            {EmploymentDetailsValidationData?.jobTitle
                              ?.status === false ? (
                              <small className="text-danger">
                                Job Title&nbsp;
                                {
                                  EmploymentDetailsValidationData?.jobTitle
                                    ?.message
                                }
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12 px-4 pb-4">
                          <div className="form-check mt-1">
                            <input
                              autoComplete="off"
                              className="form-check-input"
                              type="checkbox"
                              id="gridCheck1"
                              checked={
                                EmploymentDetailsData?.currentlyWorking === true
                              }
                              value={EmploymentDetailsData.currentlyWorking}
                              onChange={() => {
                                if (EmploymentDetailsData.currentlyWorking)
                                  onChangeEmploymentDetailsData(
                                    "currentlyWorking",
                                    false
                                  );
                                else
                                  onChangeEmploymentDetailsData(
                                    "currentlyWorking",
                                    true
                                  );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridCheck1"
                            >
                              I currently working here
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 px-4 pb-4 ">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Working Since
                            </label>
                            <input
                              autoComplete="off"
                              type="date"
                              id="template-input"
                              name="workingSince"
                              aria-invalid={
                                EmploymentDetailsValidationData?.workingSince
                                  ?.status === false
                              }
                              onBlur={(e) => {
                                onChangeEmploymentDetailsValidationData(
                                  "workingSince",
                                  NonEmptyValidationForDate(e.target.value)
                                );
                              }}
                              max={moment().format("YYYY-MM-DD")}
                              onChange={(e) => {
                                onChangeEmploymentDetailsData(
                                  "workingSince",
                                  e.target.value
                                );
                              }}
                            />
                            {EmploymentDetailsValidationData?.workingSince
                              ?.status === false ? (
                              <small className="text-danger">
                                From Date&nbsp;
                                {
                                  EmploymentDetailsValidationData?.workingSince
                                    ?.message
                                }
                              </small>
                            ) : null}
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Skill*
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={skills}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      EmploymentDetailsValidationData?.skill
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      EmploymentDetailsValidationData?.skill
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose your skill"
                                isClearable
                                isMulti
                                onInputChange={() => {
                                  if (EmploymentDetailsValidationData["skill"])
                                    delete EmploymentDetailsValidationData[
                                      "skill"
                                    ];
                                }}
                                value={EmploymentDetailsData.skill}
                                getOptionLabel={(option) =>
                                  option.name || option.label
                                }
                                getOptionValue={(option) => option.masterDataId}
                                onChange={(newValue) =>
                                  onChangeEmploymentDetailsData(
                                    "skill",
                                    newValue
                                  )
                                }
                                onCreateOption={(e) =>
                                  handleCreateSkills(e, "create")
                                }
                              />
                            </div>
                            {EmploymentDetailsValidationData?.skill?.status ===
                            false ? (
                              <small className="text-danger">
                                Skills&nbsp;
                                {
                                  EmploymentDetailsValidationData?.skill
                                    ?.message
                                }
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 px-4 pb-4">
                          {EmploymentDetailsData.currentlyWorking === false ? (
                            <div className="google-input">
                              <label
                                htmlFor="template-input"
                                className="z-index-value"
                              >
                                To
                              </label>
                              <input
                                autoComplete="off"
                                type="date"
                                id="template-input"
                                name="to"
                                onBlur={(e) => {
                                  if (
                                    EmploymentDetailsData.currentlyWorking ===
                                    false
                                  ) {
                                    onChangeEmploymentDetailsValidationData(
                                      "workingTo",
                                      NonEmptyValidationForEndDate(
                                        e.target.value
                                      )
                                    );
                                  }
                                }}
                                aria-invalid={
                                  EmploymentDetailsValidationData?.workingTo
                                    ?.status === false
                                }
                                onChange={(e) => {
                                  if (
                                    EmploymentDetailsData.currentlyWorking ===
                                    false
                                  ) {
                                    onChangeEmploymentDetailsData(
                                      "workingTo",
                                      e.target.value
                                    );
                                  }
                                }}
                              />
                              {EmploymentDetailsValidationData?.workingTo
                                ?.status === false ? (
                                <small className="text-danger">
                                  To Date&nbsp;
                                  {
                                    EmploymentDetailsValidationData?.workingTo
                                      ?.message
                                  }
                                </small>
                              ) : null}
                            </div>
                          ) : (
                            <div className="google-input">
                              <p className="text-center text-light w-100 bg-secondary py-2 mb-0 rounded">
                                Currently Working
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modalAnswer col-lg-11 mt-2 mb-4  pt-2 px-4 ">
                  <label htmlFor="template-input" className="z-index-value">
                    Job Description
                  </label>
                  <div className="modalDisapprove-details d-flex  justify-content-end ">
                    <button
                      type="button"
                      className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                    >
                      Rich Text Editor
                      <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                        lock
                      </span>
                    </button>
                  </div>

                  <div className="form-group">
                    <textarea
                      className="form-control modalDisapprove-textarea"
                      id="exampleFormControlTextarea2"
                      rows="7"
                      value={EmploymentDetailsData.description}
                      onBlur={(e) => {
                        onChangeEmploymentDetailsValidationData(
                          "description",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                      aria-invalid={
                        EmploymentDetailsValidationData?.description?.status ===
                        false
                      }
                      onChange={(e) => {
                        onChangeEmploymentDetailsData(
                          "description",
                          e.target.value
                        );
                      }}
                    ></textarea>
                  </div>
                  {EmploymentDetailsValidationData?.description?.status ===
                  false ? (
                    <small className="text-danger">
                      Description&nbsp;
                      {EmploymentDetailsValidationData?.description?.message}
                    </small>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setAddEmploymentModal(false);
                    setEmploymentDetailsValidationData({});
                    setEmploymentDetailsData({ currentlyWorking: false });
                  }}
                >
                  Back
                </button>
              </div>
              <div>
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                  onClick={handleEmploymentDetails}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={EditEmploymentModal}
        onClickOutside={() => {
          setEditEmploymentModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader py-3">
              <h5 className="text-center font-weight-bold my-0">
                Edit Employment
              </h5>
            </div>

            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-11">
                  <form>
                    <div className="form-container pt-4 py-1">
                      <div className="row ">
                        <div className="col-lg-6 px-5 pb-4 ">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Company name
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={companyName}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      particularEmploymentDetailsValidation
                                        ?.companyName?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      particularEmploymentDetailsValidation
                                        ?.companyName?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                getOptionLabel={(option) =>
                                  option.companyName || option.label
                                }
                                getOptionValue={(option) => option.companyId}
                                placeholder="Enter The Company name"
                                isClearable
                                value={
                                  getParticularEmploymentDetails?.companyName
                                }
                                onInputChange={() => {
                                  if (
                                    particularEmploymentDetailsValidation[
                                      "companyName"
                                    ]
                                  )
                                    delete particularEmploymentDetailsValidation[
                                      "companyName"
                                    ];
                                }}
                                onChange={(value) =>
                                  onChangeParticularEmploymentDetails(
                                    "companyName",
                                    value
                                  )
                                }
                                onCreateOption={(e) =>
                                  handleCreateCompanyName(e, "update")
                                }
                              />
                              {particularEmploymentDetailsValidation
                                ?.companyName?.status === false ? (
                                <small className="text-danger">
                                  Company Name
                                  {
                                    particularEmploymentDetailsValidation
                                      ?.companyName?.message
                                  }
                                </small>
                              ) : null}
                            </div>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              City
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={props.cities}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      particularEmploymentDetailsValidation
                                        ?.city?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      particularEmploymentDetailsValidation
                                        ?.city?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Enter your city"
                                isClearable
                                value={{
                                  value:
                                    getParticularEmploymentDetails?.city?.name,
                                  label:
                                    getParticularEmploymentDetails?.city
                                      ?.name ||
                                    getParticularEmploymentDetails?.city?.label,
                                }}
                                getOptionLabel={(option) =>
                                  option.name || option.label
                                }
                                getOptionValue={(option) => option.masterDataId}
                                onChange={(newValue) =>
                                  onChangeParticularEmploymentDetails(
                                    "city",
                                    newValue
                                  )
                                }
                                onInputChange={() => {
                                  if (
                                    particularEmploymentDetailsValidation[
                                      "city"
                                    ]
                                  )
                                    delete particularEmploymentDetailsValidation[
                                      "city"
                                    ];
                                }}
                                onCreateOption={(e) =>
                                  handleCreateCity(e, "update")
                                }
                              />
                              {particularEmploymentDetailsValidation?.city
                                ?.status === false ? (
                                <small className="text-danger">
                                  City
                                  {
                                    particularEmploymentDetailsValidation?.city
                                      ?.message
                                  }
                                </small>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 px-5 pb-4">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Job Title
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={jobTitle}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      particularEmploymentDetailsValidation
                                        ?.jobTitle?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      particularEmploymentDetailsValidation
                                        ?.jobTitle?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Enter Job Title"
                                value={{
                                  value:
                                    getParticularEmploymentDetails?.jobTitle
                                      ?.masterDataId,
                                  label:
                                    getParticularEmploymentDetails?.jobTitle
                                      ?.name ||
                                    getParticularEmploymentDetails?.jobTitle
                                      ?.label,
                                }}
                                onChange={(newValue) =>
                                  onChangeParticularEmploymentDetails(
                                    "jobTitle",
                                    newValue
                                  )
                                }
                                onInputChange={() => {
                                  if (
                                    particularEmploymentDetailsValidation[
                                      "jobTitle"
                                    ]
                                  )
                                    delete particularEmploymentDetailsValidation[
                                      "jobTitle"
                                    ];
                                }}
                                onCreateOption={(string) =>
                                  handleJobTitle(string, "update")
                                }
                                isClearable
                              />
                              {particularEmploymentDetailsValidation?.jobTitle
                                ?.status === false ? (
                                <small className="text-danger">
                                  Job title
                                  {
                                    particularEmploymentDetailsValidation
                                      ?.jobTitle?.message
                                  }
                                </small>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 px-5 pb-4">
                          <div className="form-check mt-1">
                            <input
                              autoComplete="off"
                              className="form-check-input"
                              type="checkbox"
                              id="gridCheck1"
                              checked={
                                getParticularEmploymentDetails?.currentlyWorking ===
                                true
                              }
                              onChange={() => {
                                if (
                                  getParticularEmploymentDetails.currentlyWorking
                                )
                                  onChangeParticularEmploymentDetails(
                                    "currentlyWorking",
                                    false
                                  );
                                else
                                  onChangeParticularEmploymentDetails(
                                    "currentlyWorking",
                                    true
                                  );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridCheck1"
                            >
                              Currently Working
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 px-5 pb-4 ">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Working Since
                            </label>
                            <input
                              autoComplete="off"
                              type="date"
                              aria-invalid={
                                particularEmploymentDetailsValidation
                                  ?.workingSince?.status === false
                              }
                              id="template-input"
                              onBlur={(e) => {
                                onChangeParticularEmploymentDetailsValidation(
                                  "workingSince",
                                  NonEmptyValidationForDate(e.target.value)
                                );
                              }}
                              max={moment().format("YYYY-MM-DD")}
                              name="workingSince"
                              value={moment(
                                getParticularEmploymentDetails?.workingSince
                              ).format("YYYY-MM-DD")}
                            />
                            {particularEmploymentDetailsValidation?.workingSince
                              ?.status === false ? (
                              <small className="text-danger">
                                Working since
                                {
                                  particularEmploymentDetailsValidation
                                    ?.workingSince?.message
                                }
                              </small>
                            ) : null}
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Skill*
                            </label>

                            <div className="dropdown-container">
                              <CreatableSelect
                                options={skills}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      particularEmploymentDetailsValidation
                                        ?.skill?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      particularEmploymentDetailsValidation
                                        ?.skill?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose your skill"
                                isClearable
                                isMulti
                                getOptionLabel={(option) =>
                                  option.name || option.label
                                }
                                getOptionValue={(option) => option.masterDataId}
                                onInputChange={() => {
                                  if (
                                    particularEmploymentDetailsValidation[
                                      "skill"
                                    ]
                                  )
                                    delete particularEmploymentDetailsValidation[
                                      "skill"
                                    ];
                                }}
                                value={getParticularEmploymentDetails.skill}
                                onChange={(newValue) =>
                                  onChangeParticularEmploymentDetails(
                                    "skill",
                                    newValue
                                  )
                                }
                                onCreateOption={(e) =>
                                  handleCreateSkills(e, "update")
                                }
                              />
                            </div>
                            {particularEmploymentDetailsValidation?.skill
                              ?.status === false ? (
                              <small className="text-danger">
                                Skills
                                {
                                  particularEmploymentDetailsValidation?.skill
                                    ?.message
                                }
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 px-5 pb-4">
                          {getParticularEmploymentDetails?.currentlyWorking ===
                          false ? (
                            <div className="google-input">
                              <label
                                htmlFor="template-input"
                                className="z-index-value"
                              >
                                To
                              </label>

                              <input
                                autoComplete="off"
                                onBlur={(e) => {
                                  onChangeParticularEmploymentDetailsValidation(
                                    "workingTo",
                                    NonEmptyValidationForEndDate(e.target.value)
                                  );
                                }}
                                aria-invalid={
                                  particularEmploymentDetailsValidation
                                    ?.workingTo?.status === false
                                }
                                type="date"
                                onChange={(e) =>
                                  onChangeParticularEmploymentDetails(
                                    "workingTo",
                                    e.target.value
                                  )
                                }
                                id="template-input"
                                name="to"
                                value={moment(
                                  getParticularEmploymentDetails?.workingTo
                                ).format("YYYY-MM-DD")}
                              />
                              {particularEmploymentDetailsValidation?.workingTo
                                ?.status === false ? (
                                <small className="text-danger">
                                  Working to
                                  {
                                    particularEmploymentDetailsValidation
                                      ?.workingTo?.message
                                  }
                                </small>
                              ) : null}
                            </div>
                          ) : (
                            <p className="text-center text-light w-100 bg-secondary py-2 mt-4 mb-0 rounded">
                              Currently Working
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modalAnswer col-lg-11 mt-2 mb-4 pt-2 px-5">
                  <label htmlFor="template-input" className="z-index-value">
                    Job Description
                  </label>
                  <div className="modalDisapprove-details d-flex  justify-content-end ">
                    <button
                      type="button"
                      className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                    >
                      Rich Text Editor
                      <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                        lock
                      </span>
                    </button>
                  </div>

                  <div className="form-group">
                    <textarea
                      className="form-control modalDisapprove-textarea"
                      id="exampleFormControlTextarea2"
                      rows="7"
                      onBlur={(e) => {
                        onChangeParticularEmploymentDetailsValidation(
                          "description",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                      onChange={(e) =>
                        onChangeParticularEmploymentDetails(
                          "description",
                          e.target.value
                        )
                      }
                      aria-invalid={
                        particularEmploymentDetailsValidation?.description
                          ?.status === false
                      }
                      name="description"
                      value={getParticularEmploymentDetails?.description}
                    ></textarea>
                  </div>
                  {particularEmploymentDetailsValidation?.description
                    ?.status === false ? (
                    <small className="text-danger">
                      Description
                      {
                        particularEmploymentDetailsValidation?.description
                          ?.message
                      }
                    </small>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setEditEmploymentModal(false);
                    setParticularEmploymentDetailsValidation({});
                    setParticularGetEmploymentDetails({
                      currentlyWorking: false,
                    });
                  }}
                >
                  Back
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    handleUpdateEmploymentDetails();
                  }}
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={deleteEmployModal}
        onClickOutside={() => {
          setDeleteEmployModal(false);
        }}
      >
        <div className="modal-content w-75  m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <h3 className="font-weight-bold text-center">
                    Are You Sure?
                  </h3>
                  <p className=" text-center">
                    Do you really want to delete this Record? this process
                    cannot be undone.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center border-0 mb-3">
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                  onClick={() => {
                    setDeleteEmployModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ padding: "8px 24px" }}
                  className="btn btn-danger my-2 my-sm-0 mr-2 "
                  onClick={() => handleDeleteEmpDetails(employId)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={skillDeleteModalStatus}
        onClickOutside={() => {
          setSkillDeleteModalStatus(false);
        }}
      >
        <div className="modal-content w-75 m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <h3 className="font-weight-bold text-center">
                    Are You Sure?
                  </h3>
                  <p className=" text-center">
                    Do you really want to delete this skill? this process cannot
                    be undone.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center border-0 mb-3">
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                  onClick={() => {
                    setSkillDeleteModalStatus(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ padding: "8px 24px" }}
                  className="btn btn-danger my-2 my-sm-0 mr-2 "
                  onClick={() => handleDeleteSkill()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default EmploymentDetails;
