import React, { useState, useCallback, useEffect } from "react";
import { GetBasicDetails, GetFAQDetails } from "../../components/Api/Api";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function FAQ() {
    const params = useParams();
    const state = useSelector((state) => state);
    const authKey = state?.auth?.auth?.authKey;
    const [setFaqDetails] = useState([]);

    const [setBasicDetails] = useState([])

    const fetchData = useCallback(async () => {
      if (!authKey) return;

      const companyProfileId = params.id;
      const getBasicDetails = await GetBasicDetails(authKey, companyProfileId);
      setBasicDetails(getBasicDetails.data);
    }, [authKey, params.id, setBasicDetails]);

    const handleallFAQ = useCallback(async () => {
      if (!authKey) return;

      const companyId = params.id;
      const response = await GetFAQDetails(companyId, authKey);
      if (response && response.statusCode === 200) {
        setFaqDetails(response.data);
      } else {
        setFaqDetails([]);
      }
    }, [authKey, params.id, setFaqDetails]);

    useEffect(() => {
      handleallFAQ();
      fetchData()
      return () => {};
    }, [fetchData, handleallFAQ]);

    return (
        <div className="dashboard-bg">
            <div className="main">
                <div className="scroll-content-admin">
                    {/* <MUIDataTable title={"FAQ"} data={faqDetails} columns={columns} options={options} /> */}
                </div>
            </div>
        </div>
    )
}
