import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import Roboimg from "../images/roboimg.png";
import Roboopen from "../images/robo-open.png";
import Homesection1 from "../images/homesection1.png";
import Homesection2 from "../images/homesection2.png";
import Dummy1 from "../images/dummy1.png";
import Dummy2 from "../images/dummy2.png";
import Dummy3 from "../images/dummy3.png";
import Dummy4 from "../images/dummy4.png";
import Dummy5 from "../images/dummy5.png";
import section4 from "../images/section4.png";
import section1 from "../images/section1.png";
import Trainer1 from "../images/trainer1.png";
import Trainer2 from "../images/trainer2.png";
// import { useSelector } from 'react-redux/es/exports';

import { Map as MapContainer, TileLayer } from "react-leaflet";

import "leaflet/dist/leaflet.css";

// import L from "leaflet";

// import { renderToStaticMarkup } from "react-dom/server";
import { Link, useNavigate } from "react-router-dom";
import useFeatureFlags from "../hooks/useFeatureFlags";

const HELP_LINK_ITEMS = [{
  id: 'seeker-link',
  url: '/RegisterEmployee',
  label: 'I’m exploring jobs',
  featureFlag: 'enable_job_seeker_persona'
}, {
  id: 'employer-link',
  url: '/RegisterEmployer',
  label: 'I’m recruiting',
  featureFlag: 'enable_employer_persona'
}, {
  id: 'resume-builder-link',
  url: '',
  label: 'I’m building resumes',
  featureFlag: 'enable_resume_builder_persona'
}, {
  id: 'mentor-link',
  url: '',
  label: 'I’m mentoring job seekers',
  featureFlag: 'enable_mentor_persona'
}]

export default function Home() {
  //state
  // const state = useSelector((state) => state)

  //navigation
  const navigate = useNavigate();
  const feature = useFeatureFlags();

  return (
    <div>
      <Navbar />
      <div className="Banner d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="section1 my-3 p-4">
                {/* <img className="mt-3" src={Roboimg} alt="" /> */}
                <div id="cf3">
                  <img alt="" className="bottom" src={Roboimg} />
                  <img alt="" className="top" src={Roboopen} />
                </div>
                <h1>
                  Howdy! I’m Quantum, <br /> your personal recruiter who never
                  sleeps!
                </h1>
                <p>
                  With my AI/ML capabilities <br /> we can achieve your goals
                  with minimal effort!{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 m-auto">
              <div className="section2 my-3 ">
                <h5 className="text-center pt-4 font-weight-bold mx-4">
                  How can I help?
                </h5>
                <div className="mx-4 pb-pt-form">
                  {HELP_LINK_ITEMS.map(linkItem => {
                    const { id, url, label, featureFlag = 'none' } = linkItem;
                    console.log('featureFlag: ', featureFlag);
                    return feature[featureFlag] && (
                      <Link to={url} key={id}>
                        <button className="btn btn-light home-rdt-btn">
                          {label}
                        </button>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="homesection1">
        <h3 className="text-center my-5 font-weight-bold px-3">
          With an individual enrollment specialist, you'll get <br /> matched to
          jobs that are ideal for you.
        </h3>
        <div className="container">
          <div className="row my-2">
            <div className="col-lg-6 col-md-6 pr-0 my-2">
              <div className="section-content1">
                <h4 className="font-weight-bold px-5">
                  Discover New Opportunities
                </h4>
                <p className="px-5">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                </p>
                <button
                  className="btn btn-light login-btn mx-5 my-4 px-4 py-2"
                  onClick={() => navigate("/Login")}
                >
                  Explore
                </button>
                <div className="text-right">
                  <img src={Homesection1} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 pl-0 my-3">
              <div className="section-content2">
                <img src={Homesection2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="homesection2">
        <h3 className="text-center my-5 font-weight-bold px-3">EAD Facts</h3>
        <div className="container">
          <div className="row my-2 justify-content-center">
            <div className="col-lg-2 col-md-4  my-2">
              <div className="card-section">
                <img src={Dummy3} alt="" />
                <div className="card-content p-2 my-2">
                  <p className="font-weight-bold">Profile</p>
                  <h5 className="font-weight-bold">1,000+</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4  my-2">
              <div className="card-section">
                <img src={Dummy2} alt="" />
                <div className="card-content p-2 my-2">
                  <p className="font-weight-bold">Companies / Employers</p>
                  <h5 className="font-weight-bold">500+</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4  my-2">
              <div className="card-section">
                <img src={Dummy4} alt="" />
                <div className="card-content p-2 my-2">
                  <p className="font-weight-bold">Job posting</p>
                  <h5 className="font-weight-bold">2,000+</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 my-2">
              <div className="card-section">
                <img src={Dummy5} alt="" />
                <div className="card-content p-2 my-2">
                  <p className="font-weight-bold">Mentors</p>
                  <h5 className="font-weight-bold">500+</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4  my-2">
              <div className="card-section">
                <img src={Dummy1} alt="" />
                <div className="card-content p-2 my-2">
                  <p className="font-weight-bold">Trainers</p>
                  <h5 className="font-weight-bold">500+</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="homesection3">
        <h3 className="text-center my-5 font-weight-bold px-3">
          What people are saying about EADJobs
        </h3>
        <div className="container">
          <div className="row my-2">
            <div className="col-lg-6 col-md-6">
              <div className="section1content">
                <div className="text-right pt-4">
                  <img className="sectionimg" src={section4} alt="" />
                </div>
                <div className="sectioncontent">
                  <h4 className="font-weight-bold py-2">Adam Chill</h4>
                  <h5 className="py-2">Mentor</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur.
                  </p>
                </div>
              </div>
              <div className="section3content mt-4">
                <div className="row">
                  <div className="col-5"></div>
                  <div className="col-7">
                    <div className="innercontent mb-2">
                      <h4 className="font-weight-bold ">Angelina</h4>
                      <h5>Employer</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="trainersection mt-4">
                <div className="row">
                  <div className="col-lg-12 col-xs-12 col-sm-12">
                    <div className="innercontent mb-2">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                      </p>
                      <div className="row trainerimg">
                        <div className="col-3">
                          <img src={Trainer1} alt="" />
                        </div>
                        <div className="col-5 my-auto ">
                          <div className="right-text">
                            <h4 className="font-weight-bold ">Lundon</h4>
                            <h5>Trainer</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12 mt-5">
              <div className="section2content">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="innercontent">
                      <h4 className="font-weight-bold ">Rhaenyra Targaryen</h4>
                      <h5>Ceo</h5>
                      <p className="pr-5">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section4content mt-4">
                <div className="text-left pt-4">
                  <img className="sectionimg" src={section1} alt="" />
                </div>
                <div className="sectioncontent">
                  <h4 className="font-weight-bold py-2">Luci</h4>
                  <h5 className="py-2">Job Seeker</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur.
                  </p>
                </div>
              </div>
              <div className="trainersection mt-4">
                <div className="row">
                  <div className="col-lg-12 col-xs-12">
                    <div className="innercontent mb-2">
                      <div className="row trainerimg">
                        <div className="col-3">
                          <img src={Trainer2} alt="" />
                        </div>
                        <div className="col-5  my-auto">
                          <div className="right-text">
                            <h4 className="font-weight-bold ">Warner</h4>
                            <h5>Trainer</h5>
                          </div>
                        </div>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="homesection4">
        <div className="container">
          <div className="search-area py-3 my-4">
            <h3 className="text-center  my-4 font-weight-bold px-3">
              Create a profile for the best experience or search for jobs.
            </h3>
            <div className="search-box-section">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-9">
                    <div className="d-flex justify-content-center">
                      <form className="form-inline  my-lg-0">
                        <div className="">
                          <div className="form-group has-search mr-2">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              placeholder="Job Title or Keyword"
                            />
                          </div>
                        </div>
                        <div className="">
                          <div className="form-group has-search mr-2">
                            <span className="fa-solid fa-location-dot form-control-feedback"></span>
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              placeholder="Search city, State"
                            />
                          </div>
                        </div>
                        <div className="">
                          <button className="btn btn-light font-weight-bold search-jobs-btn  px-4 py-2">
                            {" "}
                            Search Jobs
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="homesection5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 map-section">
              <div className="d-flex justify-content-end map-section-btn">
                <button class="btn btn-light rounded-left px-4 my-2 my-sm-0 login-btn">
                  Job Seekers
                </button>
                <button class="btn btn-light rounded-right px-5 my-2 my-sm-0 postjob-btn">
                  Jobs
                </button>
              </div>
              <MapContainer
                style={{
                  height: "80vh",

                  width: "100%",

                  padding: "5px",
                }}
                scrollWheelZoom={false}
                className="mb-5"
                center={[36.084621, -96.921387]}
                zoom={4}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </MapContainer>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
