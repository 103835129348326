import React, { useState, useCallback, useEffect } from "react";
import NavbarAdmin from "../NavbarAdmin";
import MUIDataTable from "mui-datatables";
import {
  CompanyDetailsEmployee,
} from "../../components/Api/Api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Companies = () => {
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const [companiesList, setCompaniesList] = useState([]);

  //Find all
  const handleAllCompany = useCallback(async () => {
    if (!authKey) return;

    const response = await CompanyDetailsEmployee(authKey, "newest");
    if (response && response.statusCode === 200) {
      let newData = response?.data?.companyListing;
      setCompaniesList(newData);
    } else {
      setCompaniesList([]);
    }
  }, [authKey]);

  useEffect(() => {
    handleAllCompany();
    return () => {};
  }, [handleAllCompany]);

  console.log(companiesList);

  const columns = [
    {
      name: "companyId",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "companyName",
      label: "Company Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <div
                className="btn"
                onClick={() =>
                  navigate(
                    `/Admin/Companies/EditCompanies/${tableMeta.rowData[0]}`
                  )
                }
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "companyLogo",
      label: "Company Logo",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div >
              <img
              style={{ height: "50px", width: "50px" }}
                className="ci-bannerLogo"
                src={value}
                alt=""
                hidden={value === ""}
              />
            </div>
          );
        },
      },
    },
    { name: "status", label: "Status" },
  ];
  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
  };
  return (
    <div className="dashboard-bg">
      <NavbarAdmin />
      <div className="main">
        <div className="chat-section scroll-content">
          <MUIDataTable
            title={"Companies List"}
            data={companiesList}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};
export default Companies;
