import React, { useCallback, useEffect, useMemo, useState } from "react";
import NavbarDashboard from "./NavbarDashboard";
import Select from "react-select";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import CompBanner from "../../images/compbanner.png";
import { GetInitials, PasswordRegex } from "../Store/Constants";
import { useDispatch, useSelector } from "react-redux";
import Roboimg2 from "../../images/roboimg-outerborder.png";
import CreatableSelect from "react-select/creatable";
import Roboimg from "../../images/roboimg.png";
import {
  ChangePassword,
  CreateEmployeeSkills,
  CreateGallery,
  CreateTicket,
  DeleteGallery,
  GetBasicDetails,
  GetCompanyDetail,
  GetGallery,
  GetMasterDatasByType,
  ReSendOtp,
  UpdateBasicDetail,
  UpdateCompanyDetail,
  VerifyOtp,
} from "../Api/Api";
import { useForm } from "react-hook-form";
import CustomModal from "../CustomModal";
import { useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Approval from "./Profile/Approval";
import { updateSpecificUserDetailsKeyinState } from "../Store/auth/action";
import { useNavigate, useLocation } from "react-router-dom";

//progress bar
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  StringValidation,
  PhoneNumberValidation,
  DateValidation,
  URLValidation,
  NumericRangeValidation,
  CompanyNameValidation,
  NonEmptyObjectValidation,
  EmailCompanyValidation,
  NonEmptyValidation,
  CompanyNameChangeValidation,
} from "../Store/validate";
import CropEasy from "../Crop/CropEasy";

export default function Profile() {
  const [approvalSubTab, setApprovalSubTab] = useState("Pending");
  const approvalTabs = ["Pending", "Approved", "Rejected"];

  //progress bar
  // const percentageProgress = 80;
  
  const NeededKeysForPercentage = useMemo(() => [
    "companyName",
    "officalEmail",
    "genderId",
    "fullName",
    "displayName",
    "phoneNumber",
    "profileImage",
  ], []);

  const [selectedTab, setSelectedTab] = React.useState("Basic Details");
  const [deleteGalleryModal, setDeleteGalleryModal] = useState(false);
  const [galleryId, setGalleryId] = useState("");
  // const [successMsg, setSuccessMsg] = useState();
  // const [errorMsg, setErrorMsg] = useState();
  const [ceoPic, setCeoPic] = useState("");
  const [setCAPic] = useState("");
  const [setBannerPic] = useState(CompBanner);
  const CeoRef = useRef();
  const CARef = useRef();
  const FullNameLabelRef = useRef();
  const CompanyUrlLabelRef = useRef();
  const [CD_ComapnyUrl, setCD_ComapnyUrl] = useState(true);
  const [BD_FullName, setBD_FullName] = useState(true);
  const navigation = useNavigate();
  const [editInputFieldModal, setEditInputFieldModal] = useState(false);
  const [emailVerifyModal, setEmailVerifyModal] = useState(false);
  // state values
  const state = useSelector((state) => state);
  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };
  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };
  const {
    // register,
    // handleSubmit,
    // control,
    reset,
    // watch,
    // formState: { errors },
  } = useForm();

  const Alltabs =
    state?.auth?.auth?.userDetails?.role === "Admin"
      ? [
          "Basic Details",
          "Company Details",
          "Gallery",
          "Change Password",
          "Approval",
        ]
      : ["Basic Details", "Company Details", "Gallery", "Change Password"];

  const [code, setOtp] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  const [validation, setValidation] = useState({});

  //Otp Submit
  const handleChange = (code) => {
    setOtp(code);
    setValidation({});
  };

  const Resent = async () => {
    setOtp(null);
    const Payload = {
      emailId: state.auth.auth.emailId,
      verificationType: "Account Creation",
    };

    const response = await ReSendOtp(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setMinutes(0);
      setSeconds(59);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  // final submit
  const Submit = async (data) => {
    data.preventDefault();
    let validate = {};
    validate.code = NonEmptyValidation(code);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        emailId: state.auth.auth.emailId,
        verificationType: "Account Creation",
        otp: parseInt(code),
      };
      const response = await VerifyOtp(Payload);
      if (response && response.statusCode === 200) {
        fetchData();
        navigation("/Profile?tab=Basic%20Details");
        setEmailVerifyModal(false);
        dispatch(updateSpecificUserDetailsKeyinState(response.data));
        showToastSuccessMsg(
          response.message || response.error.response.data.message
        );
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        // setFailureModal(true);
      }
    } else {
      console.log("Error while verify otp");
    }
  };

  //Update Compaby Basic details state
  const dispatch = useDispatch();
  const [basicDetails, setBasicDetails] = useState([]);

  const [CompanyDetailsValidation, setCompanyDetailsValidation] = useState({});

  const setValue = (key, value) => {
    setBasicDetails({ ...basicDetails, [key]: value });
    if (basicDetailsValidation[key]) delete basicDetailsValidation[key];
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //COMPANY BASIC DETAILS

  //get gender
  const [gender, setGender] = useState([]);
  const authKey = state?.auth?.auth?.authKey;
  const companyId = state?.auth?.auth?.userDetails?.companyId;
  const companyProfileId = state?.auth?.auth?.userDetails?.companyProfileId;

  const fetchGenderData = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=gender&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getGenderDetails = await GetMasterDatasByType(Query);
    let data1 = getGenderDetails.data;
    if (getGenderDetails && getGenderDetails.statusCode === 200) {
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      // if (!state?.auth?.auth?.userDetails?.gender) {
      //   setValue("gender", getGenderDetails[getGenderDetails.length - 1]);
      // }
      setGender(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchGenderData();
  }, [fetchGenderData]);

  const [basicDetailsValidation, setBasicDetailsValidation] = useState({});
  const setBasicDetailsValidationValue = (key, value) => {
    setBasicDetailsValidation({ ...basicDetailsValidation, [key]: value });
  };

  const setValidationValue = (key, value) => {
    setCompanyDetailsValidation({ ...CompanyDetailsValidation, [key]: value });
  };

  const fetchData = useCallback(async () => {
    if (!authKey || !companyProfileId) return;
    const getBasicDetails = await GetBasicDetails(authKey, companyProfileId);
    setBasicDetails(getBasicDetails.data);
    setCAPic(getBasicDetails?.data?.profileImage);
    dispatch(updateSpecificUserDetailsKeyinState(getBasicDetails.data));
  }, [authKey, companyProfileId, dispatch, setCAPic]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  //update basic details api integration

  const handleUpdate = async (value) => {
    let Payload;
    if (value === "UpdateBasicDetails") {
      let validate = {};
      validate.fullName = StringValidation(basicDetails.fullName);
      validate.displayName = StringValidation(basicDetails.displayName);
      validate.phoneNumber = PhoneNumberValidation(basicDetails.phoneNumber);
      setBasicDetailsValidation(validate);
      if (Object.values(validate).every((v) => v.status === true)) {
        Payload = {
          genderId: basicDetails.genderId.dataId,
          fullName: basicDetails.fullName,
          displayName: basicDetails.displayName,
          phoneNumber: basicDetails.phoneNumber.toString(),
          companyName: basicDetails?.companyName?.dataId,
          officalEmail: basicDetails.officalEmail,
          companyProfileId: state?.auth?.auth?.userDetails?.companyProfileId,
        };
        const token = state?.auth?.auth?.authKey;
        const response = await UpdateBasicDetail(Payload, token);
        if (response && response.statusCode === 200) {
          showToastSuccessMsg(response.message);
          dispatch(updateSpecificUserDetailsKeyinState(response.data));
          if (value === "UpdateFullName") {
            setBD_FullName(!BD_FullName);
          }
        } else {
          showToastErrorMsg(
            response.message || response.error.response.data.message
          );
        }
      }
    } else if (value === "UpdateFullName") {
      if (basicDetails.fullName !== state.auth.auth.userDetails.fullName) {
        let validate = {};
        validate.fullName = StringValidation(basicDetails.fullName);

        if (Object.values(validate).every((v) => v.status === true)) {
          Payload = {
            fullName: basicDetails.fullName,
            companyProfileId: state?.auth?.auth?.userDetails?.companyProfileId,
          };
          const token = state?.auth?.auth?.authKey;
          const response = await UpdateBasicDetail(Payload, token);
          if (response && response.statusCode === 200) {
            showToastSuccessMsg(response.message);
            dispatch(updateSpecificUserDetailsKeyinState(response.data));
            if (value === "UpdateFullName") {
              setBD_FullName(!BD_FullName);
            }
          } else {
            showToastErrorMsg(
              response.message || response.error.response.data.message
            );
          }
        }
      } else {
        if (value === "UpdateFullName") {
          setBD_FullName(!BD_FullName);
        }
      }
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  //COMPANY DETAILS

  //get industry

  const [companyIndustryData, setCompanyIndustryData] = useState([]);
  const fetchIndustry = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=industry&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setCompanyIndustryData(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchIndustry();
  }, [fetchIndustry]);

  const handleCreateCompanyIndustry = async (string) => {
    const valid = CompanyNameValidation(string);
    setValidationValue("companyIndustryId", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "industry",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);

      if (response && response.statusCode === 200) {
        fetchIndustry();
        setCompanyDetailsValue("companyIndustryId", {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        });
      }
    }
  };

  //get revenue

  const [revenueData, setRevenueData] = useState([]);
  const fetchDataRevenue = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=company_revenue&status=Active&restrictionLevel=1&companyId=${companyId}`;
    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setRevenueData(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchDataRevenue();
  }, [fetchDataRevenue]);

  const handleCreateRevenue = async (string) => {
    const valid = NumericRangeValidation(string);
    setValidationValue("revenueId", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "company_revenue",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchDataRevenue();
        setCompanyDetailsValue("revenueId", {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        });
      }
    }
  };

  //get company size

  const [companySize, setCompanySize] = useState([]);
  const fetchData1 = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=company_size&status=Active&restrictionLevel=1&companyId=${companyId}`;
    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setCompanySize(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData1();
  }, [fetchData1]);

  const handleCreateCompanySize = async (string) => {
    const valid = NumericRangeValidation(string);
    setValidationValue("companySizeId", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "company_size",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchData1();
        setCompanyDetailsValue("companySizeId", {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        });
      }
    }
  };

  //get headquaters location

  // const [location, setLocation] = useState([]);
  // async function fetchData2() {
  //   const Query = `authKey=${state?.auth?.auth?.authKey}&masterDataType=gender&status=Active&restrictionLevel=1&companyId=${state?.auth?.auth?.userDetails?.companyId}`;

  //   const getEmployeeSkills = await GetMasterDatasByType(Query);
  //   if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
  //     let data1 = getEmployeeSkills.data;
  //     let mappingData = data1.map((e) => {
  //       return {
  //         value: e.name,
  //         label: e.name,
  //         dataId: e.masterDataId,
  //       };
  //     });
  //     setLocation(mappingData);
  //   }
  // }
  // useEffect(() => {
  //   fetchData2();
  // }, []);

  const locationSearch = useLocation();

  useEffect(() => {
    const search = locationSearch.search;
    const tab = new URLSearchParams(search).get("tab");
    const subtab = new URLSearchParams(search).get("subtab");

    setChangePasswordValidationData({});

    if (tab && !subtab) {
      setSelectedTab(tab);
    } else if (tab) {
      setSelectedTab(tab);
      setApprovalSubTab(subtab);
    }
    return () => {};
  }, [locationSearch]);

  // const [locationValue, setLocationValue] = useState();

  // const handleCreateLocation = async (string) => {
  //   const token = state?.auth?.auth?.authKey;
  //   const Payload = {
  //     masterDataType: "headquartersLocation",
  //     name: string,
  //     userId: state?.auth?.auth?.userProfileId,
  //     companyId: state?.auth?.auth?.userDetails?.companyId,
  //     restrictionLevel: 1,
  //   };
  //   const response = await CreateEmployeeSkills(Payload, token);
  //   if (response && response.statusCode === 200) {
  //     fetchData2();
  //     setLocationValue({
  //       value: response.data.name,
  //       label: response.data.name,
  //       dataId: response.data.masterDataId,
  //     });
  //   }
  // };
  const [createdCompanyDetails, setCreatedCompanyDetails] = useState({});
  // get and update company details api integration

  const setCompanyDetailsValue = (key, value) => {
    setCreatedCompanyDetails({ ...createdCompanyDetails, [key]: value });
    if (CompanyDetailsValidation[key]) delete CompanyDetailsValidation[key];
  };

  const [currectCompanyURL, setCurrentCompanyURL] = useState();

  const fetchCompanyDetails = useCallback(async () => {
    if (!authKey || !companyId) return;

    const getCompanyDetails = await GetCompanyDetail(authKey, companyId);
    if (getCompanyDetails && getCompanyDetails.statusCode === 200) {
      setCreatedCompanyDetails(getCompanyDetails.data);
      setCeoPic(getCompanyDetails?.data?.companyLogo);
      setBannerPic(getCompanyDetails?.data?.bannerImage);
      setCurrentCompanyURL(getCompanyDetails?.data?.companyUrl);
    }
  }, [authKey, companyId, setBannerPic]);

  useEffect(() => {
    fetchCompanyDetails();
  }, [fetchCompanyDetails]);

  const handleCompanyUpdate = async (value) => {
    const token = state?.auth?.auth?.authKey;
    const getCompanyDetails = await GetCompanyDetail(
      token,
      state.auth.auth.userDetails?.companyId
    );
    let Payload;
    let validate = {};
    validate.ceoName = StringValidation(createdCompanyDetails.ceoName);
    validate.founded = DateValidation(createdCompanyDetails.founded);
    validate.companyUrl = URLValidation(createdCompanyDetails.companyUrl);
    validate.companyIndustryId = NonEmptyObjectValidation(
      createdCompanyDetails.companyIndustryId
    );
    validate.revenueId = NonEmptyObjectValidation(
      createdCompanyDetails.revenueId
    );
    validate.companySizeId = NonEmptyObjectValidation(
      createdCompanyDetails.companySizeId
    );
    validate.headQuarterslocation = StringValidation(
      createdCompanyDetails.headQuarterslocation
    );

    setCompanyDetailsValidation(validate);
    if (value === "UpdateCompanyDetails") {
      if (Object.values(validate).every((v) => v.status === true)) {
        Payload = {
          ceoName: createdCompanyDetails.ceoName,
          companyLogo: createdCompanyDetails.companyLogo
            ? createdCompanyDetails.companyLogo
            : "",
          bannerImage: createdCompanyDetails.bannerImage
            ? createdCompanyDetails.bannerImage
            : "",
          founded: createdCompanyDetails.founded,
          revenueId:
            createdCompanyDetails?.revenueId?.dataId ||
            createdCompanyDetails?.revenueId,
          companyUrl: createdCompanyDetails.companyUrl,
          companyId: state.auth.auth.userDetails?.companyId,
          companySizeId:
            createdCompanyDetails?.companySizeId?.dataId ||
            createdCompanyDetails?.companySizeId,
          headQuarterslocation: createdCompanyDetails.headQuarterslocation,
          companyIndustryId:
            createdCompanyDetails?.companyIndustryId?.dataId ||
            createdCompanyDetails?.companyIndustryId,
        };
        if (getCompanyDetails && getCompanyDetails.statusCode === 200) {
          const response = await UpdateCompanyDetail(Payload, token);

          if (response && response.statusCode === 200) {
            showToastSuccessMsg(response.message);
            fetchCompanyDetails();
            // setSuccessModal(true);
            if (value === "UpdateCompanyURL") {
              setCD_ComapnyUrl(!CD_ComapnyUrl);
            }
          } else {
            showToastErrorMsg(
              response.message || response.error.response.data.message
            );
            //setFailureModal(true);
          }
        } else {
          const response = await UpdateCompanyDetail(Payload, token);

          if (response && response.statusCode === 200) {
            showToastSuccessMsg(response.message);
            fetchCompanyDetails();
            if (value === "UpdateCompanyURL") {
              setCD_ComapnyUrl(!CD_ComapnyUrl);
            }
          } else {
            showToastErrorMsg(
              response.message || response.error.response.data.message
            );
            //setFailureModal(true);
          }
        }
      }
    } 
    else if (value === "UpdateCompanyURL") {
      if (createdCompanyDetails.companyUrl !== currectCompanyURL) {
        let companyUrlValidation = URLValidation(
          createdCompanyDetails.companyUrl
        );

        if (!companyUrlValidation.status) {
          setValidationValue("companyUrl", companyUrlValidation);
        }
        if (companyUrlValidation.status) {
          Payload = {
            companyUrl: createdCompanyDetails.companyUrl,
            companyId: state.auth.auth.userDetails?.companyId,
          };
          if (getCompanyDetails && getCompanyDetails.statusCode === 200) {
            const response = await UpdateCompanyDetail(Payload, token);

            if (response && response.statusCode === 200) {
              showToastSuccessMsg(response.message);
              fetchCompanyDetails();
              // setSuccessModal(true);
              if (value === "UpdateCompanyURL") {
                setCD_ComapnyUrl(!CD_ComapnyUrl);
              }
            } else {
              showToastErrorMsg(
                response.message || response.error.response.data.message
              );
              //setFailureModal(true);
            }
          } else {
            const response = await UpdateCompanyDetail(Payload, token);

            if (response && response.statusCode === 200) {
              showToastSuccessMsg(response.message);
              fetchCompanyDetails();
              if (value === "UpdateCompanyURL") {
                setCD_ComapnyUrl(!CD_ComapnyUrl);
              }
            } else {
              showToastErrorMsg(
                response.message || response.error.response.data.message
              );
              //setFailureModal(true);
            }
          }
        }
      } else {
        if (value === "UpdateFullName") {
          setBD_FullName(!BD_FullName);
        }
      }
    }
   
  };

  const uploadCompanyUrl = async (url,value) => {
    const token = state?.auth?.auth?.authKey;
    let Payload;

    if(value==="companyLogo"){
      
      const getCompanyDetails = await GetCompanyDetail(
            token,
            state.auth.auth.userDetails?.companyId
          );
         
          if (getCompanyDetails && getCompanyDetails.statusCode === 200) {
            Payload = {
              companyId: state?.auth?.auth?.userDetails?.companyId,
              companyLogo: url,
            };
      
            const response = await UpdateCompanyDetail(Payload, token);
            if (response && response.statusCode === 200) {
            fetchCompanyDetails()
            } else {
              showToastErrorMsg(
                response.message || response.error.response.data.message
              );
            }
          } else {
            Payload = {
              companyId: state?.auth?.auth?.userDetails?.companyId,
              bannerImage: "",
              ceoName: "",
              founded: "",
              revenueId: "",
              companyUrl: "",
              companySizeId: "",
              headQuarterslocation: "",
              companyIndustryId: "",
              companyLogo: url,
            };
            const response = await UpdateCompanyDetail(Payload, token);
            if (response && response.statusCode === 200) {
              fetchCompanyDetails()
              //setFileUrl(response.data?.imageUrl)
            } else {
              showToastErrorMsg(
                response.message || response.error.response.data.message
              );
              //setFailureModal(true);
            }
          }
    }
    else if(value==="bannerImage"){
     
      const getCompanyDetails = await GetCompanyDetail(
        token,
        state.auth.auth.userDetails.companyId
      );
     
      if (getCompanyDetails && getCompanyDetails.statusCode === 200) {
        Payload = {
          companyId: state.auth.auth.userDetails.companyId,
          bannerImage: url,
        };
  
        const response = await UpdateCompanyDetail(Payload, token);
        if (response && response.statusCode === 200) {
          fetchCompanyDetails()
          //setFileUrl(response.data?.imageUrl)
          //setCAPic(response?.data?.profileImage)
        } else {
          showToastErrorMsg(
            response.message || response.error.response.data.message
          );
          //setFailureModal(true);
        }
      } else {
        Payload = {
          companyId: state.auth.auth.userDetails.companyId,
          bannerImage: url,
          ceoName: "",
          founded: "",
          revenueId: "",
          companyUrl: "",
          companySizeId: "",
          companyIndustryId: "",
          headQuarterslocation: "",
          companyLogo: "",
        };
       
        const response = await UpdateCompanyDetail(Payload, token);
        if (response && response.statusCode === 200) {
          fetchCompanyDetails()
          //setFileUrl(response.data?.imageUrl)
          //setCAPic(response?.data?.profileImage)
        } else {
          showToastErrorMsg(
            response.message || response.error.response.data.message
          );
          //setFailureModal(true);
        }
      }
    }
    else if(value==="profileImage"){
      const Payload = {
        companyProfileId: state?.auth?.auth?.userDetails?.companyProfileId,
        profileImage: url,
      };
  
      const token = state?.auth?.auth?.authKey;
      const response = await UpdateBasicDetail(Payload, token);
      //   setSuccessModal(true);
  
      if (response && response.statusCode === 200) {
        setValue("profileImage", url);
        dispatch(updateSpecificUserDetailsKeyinState(response.data));
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setFailureModal(true);
      }
    }
    else if(value==="gallery"){
      Payload = {
        companyId: state?.auth?.auth?.userDetails?.companyId,
        imageUrl: url,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await CreateGallery(Payload, token);
  
      if (response && response.statusCode === 200) {
        
        fetchImage();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////

  //COMPANY GALLERY

  //get all gallery images

  const [createdGallery, setCreatedGallery] = useState();

  const fetchImage = useCallback(async () => {
    if (!authKey || !companyId) return;

    const response = await GetGallery(authKey, companyId);
    if (response && response.statusCode === 200) {
      setCreatedGallery(response.data);
    } else {
      setCreatedGallery([]);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchImage();
  }, [fetchImage]);

  // create gallery api integration
  // const handleGallery = async (name) => {
  //   const Payload = {
  //     companyId: state?.auth?.auth?.userDetails?.companyId,
  //     imageUrl: name,
  //   };
  //   const token = state?.auth?.auth?.authKey;
  //   const response = await CreateGallery(Payload, token);

  //   if (response && response.statusCode === 200) {
  //     showToastSuccessMsg(response.message);
  //     fetchImage();
  //   } else {
  //     showToastErrorMsg(
  //       response.message || response.error.response.data.message
  //     );
  //   }
  // };

  const handleDeleteGallery = async (value) => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      galleryId: value,
    };

    const response = await DeleteGallery(Payload, token);
    if (response && response.statusCode === 200) {
      setDeleteGalleryModal(false);
      showToastSuccessMsg(response.message);
      //setSuccessModal(true);
      fetchImage();
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setFailureModal(true);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //CHANGE PASSWORD

  const onSubmitChangePassword = async (e) => {
    if (CheckChangePasswordValidation()) {
      const Payload = {
        emailId: state.auth.auth.emailId,
        password: changePasswordData.password,
        oldPassword: changePasswordData.oldPassword,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await ChangePassword(Payload, token);
      if (response && response.statusCode === 200) {
        e.target.reset();
        setChangePasswordData({});
        showToastSuccessMsg(response.message);
        reset({});
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setFailureModal(true);
      }
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  // File upload
  // const uploadFile = async (e) => {
  //   let formData = new FormData();
  //   formData.append("file", e.target.files[0]);
  //   formData.append("fileName", e.target.files[0].name);
  //   formData.append("path", "resume/");
  //   let response = await UploadDocument(formData);
  //   if (response && response.statusCode === 200) {
  //     handleGallery(response.data);
  //   } else {
  //     showToastErrorMsg(
  //       response.message || response.error.response.data.message
  //     );
  //   }
  // };
  // const multiUploadFile = async (e) => {
  // let formData = new FormData();
  // formData.append("file", e.target.files[0]);
  // Array.from(e.target.files).forEach((image)=>{
  //   formData.append("file", image);
  // })
  // formData.append("path", "resume/");
  // //console.log("formData",formData);
  // let response = await MultipleFileUpload(formData);

  // if (response && response.statusCode === 200) {
  //   // showToastSuccessMsg(response.message);
  //   //setSuccessModal(true);
  //   handleGallery(response.data);
  // } else {
  //   showToastErrorMsg(response.message || response.error.response.data.message);
  //   //setFailureModal(true);
  // }
  // let formData = new FormData();
  // formData.append("file", e.target.files[0]);
  // formData.append("fileName", e.target.files[0].name);
  // formData.append("path", "resume/");
  // let response = await MultipleFileUpload(formData);
  //console.log("formData",formData);
  // if (response && response.statusCode === 200) {
  // showToastSuccessMsg(response.message);
  //setSuccessModal(true);
  //   handleGallery(response.data);
  // } else {
  //   showToastErrorMsg(
  //     response.message || response.error.response.data.message
  //   );
  //setFailureModal(true);
  //   }
  // };

  const [fileName, setFileName] = useState("");
  const [field, setField] = useState("");

  const uploadCeoPic = async (e,value) => {
    const file = e.target.files[0];
    if (file) {
      setField(value)
      setFile(file);
      setPhotoURL(URL.createObjectURL(file));
      setFileName(e.target.files[0].name);
      setOpenCrop(true);
    }
  };

  
  // let formData = new FormData();
  // formData.append("file", e.target.files[0]);
  // formData.append("fileName", e.target.files[0].name);
  // formData.append("path", "resume/");

  // let response = await UploadDocument(formData);
  // if (response && response.statusCode === 200) {

  //   setPhotoURL(response.data);
  //   setCeoPic(photoURL);
  //   handleCompanyPic(photoURL);
  //   showToastSuccessMsg(response.message);
  // } else {
  //   showToastErrorMsg(
  //     response.message || response.error.response.data.message
  //   );
  // }
  // };
  // const handleCompanyPic = async (name) => {
  //   const token = state?.auth?.auth?.authKey;
  //   const getCompanyDetails = await GetCompanyDetail(
  //     token,
  //     state.auth.auth.userDetails?.companyId
  //   );
  //   let Payload;
  //   if (getCompanyDetails && getCompanyDetails.statusCode === 200) {
  //     Payload = {
  //       companyId: state?.auth?.auth?.userDetails?.companyId,
  //       companyLogo: name,
  //     };

  //     const response = await UpdateCompanyDetail(Payload, token);
  //     if (response && response.statusCode === 200) {
  //     } else {
  //       showToastErrorMsg(
  //         response.message || response.error.response.data.message
  //       );
  //     }
  //   } else {
  //     Payload = {
  //       companyId: state?.auth?.auth?.userDetails?.companyId,
  //       bannerImage: "",
  //       ceoName: "",
  //       founded: "",
  //       revenueId: "",
  //       companyUrl: "",
  //       companySizeId: "",
  //       headQuarterslocation: "",
  //       companyIndustryId: "",
  //       companyLogo: name,
  //     };
  //     const response = await UpdateCompanyDetail(Payload, token);
  //     if (response && response.statusCode === 200) {
  //       //setFileUrl(response.data?.imageUrl)
  //     } else {
  //       showToastErrorMsg(
  //         response.message || response.error.response.data.message
  //       );
  //       //setFailureModal(true);
  //     }
  //   }
  // };

  const handleRemoveCompanyPic = async () => {
    const token = state?.auth?.auth?.authKey;

    let Payload = {
      companyId: state?.auth?.auth?.userDetails?.companyId,
      companyLogo: "",
    };

    const response = await UpdateCompanyDetail(Payload, token);
    if (response && response.statusCode === 200) {
      //setFileUrl(response.data?.imageUrl)
      fetchCompanyDetails();
      // setCreatedCompanyDetails(response?.data?.companyLogo);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setFailureModal(true);
    }
  };

  // const uploadCAPic = async (e) => {
  //   let formData = new FormData();
  //   formData.append("file", e.target.files[0]);
  //   formData.append("fileName", e.target.files[0].name);
  //   formData.append("path", "resume/");
  //   let response = await UploadDocument(formData);
  //   if (response && response.statusCode === 200) {
  //     setCAPic(response.data);
  //     showToastSuccessMsg(response.message);
  //     handleProfilePhoto(response.data);
  //     //setSuccessModal(true);
  //   } else {
  //     showToastErrorMsg(
  //       response.message || response.error.response.data.message
  //     );
  //     //setFailureModal(true);
  //   }
  // };
  // update the profile pic  api integration
  // const handleProfilePhoto = async (name) => {
  //   const Payload = {
  //     companyProfileId: state?.auth?.auth?.userDetails?.companyProfileId,
  //     profileImage: name,
  //   };

  //   const token = state?.auth?.auth?.authKey;
  //   const response = await UpdateBasicDetail(Payload, token);
  //   //   setSuccessModal(true);

  //   if (response && response.statusCode === 200) {
  //     setValue("profileImage", name);
  //     dispatch(updateSpecificUserDetailsKeyinState(response.data));
  //   } else {
  //     showToastErrorMsg(
  //       response.message || response.error.response.data.message
  //     );
  //     //setFailureModal(true);
  //   }
  // };

  const handleRemoveProfilePhoto = async () => {
    const Payload = {
      companyProfileId: state?.auth?.auth?.userDetails?.companyProfileId,
      profileImage: "",
    };
    const token = state?.auth?.auth?.authKey;
    const response = await UpdateBasicDetail(Payload, token);
    // setSuccessModal(true);

    if (response && response.statusCode === 200) {
      dispatch(updateSpecificUserDetailsKeyinState(response.data));
      setCAPic(response?.data?.profileImage);
      setValue("profileImage", "");
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setFailureModal(true);
    }
  };

  // const uploadBannerPic = async (e) => {
  //   let formData = new FormData();
  //   formData.append("file", e.target.files[0]);
  //   formData.append("fileName", e.target.files[0].name);
  //   formData.append("path", "resume/");
  //   let response = await UploadDocument(formData);
  //   if (response && response.statusCode === 200) {
  //     setBannerPic(response.data);
  //     showToastSuccessMsg(response.message);
  //     handleBannerPhoto(response.data);
  //   } else {
  //     showToastErrorMsg(
  //       response.message || response.error.response.data.message
  //     );
  //   }
  // };

  // const handleBannerPhoto = async (name) => {
  //   const token = state?.auth?.auth?.authKey;
  //   const getCompanyDetails = await GetCompanyDetail(
  //     token,
  //     state.auth.auth.userDetails.companyId
  //   );
  //   let Payload;
  //   if (getCompanyDetails && getCompanyDetails.statusCode === 200) {
  //     Payload = {
  //       companyId: state.auth.auth.userDetails.companyId,
  //       bannerImage: name,
  //     };

  //     const response = await UpdateCompanyDetail(Payload, token);
  //     if (response && response.statusCode === 200) {
  //       //setFileUrl(response.data?.imageUrl)
  //       //setCAPic(response?.data?.profileImage)
  //     } else {
  //       showToastErrorMsg(
  //         response.message || response.error.response.data.message
  //       );
  //       //setFailureModal(true);
  //     }
  //   } else {
  //     Payload = {
  //       companyId: state.auth.auth.userDetails.companyId,
  //       bannerImage: name,
  //       ceoName: "",
  //       founded: "",
  //       revenueId: "",
  //       companyUrl: "",
  //       companySizeId: "",
  //       companyIndustryId: "",
  //       headQuarterslocation: "",
  //       companyLogo: "",
  //     };
  //     const response = await UpdateCompanyDetail(Payload, token);
  //     if (response && response.statusCode === 200) {
  //       //setFileUrl(response.data?.imageUrl)
  //       //setCAPic(response?.data?.profileImage)
  //     } else {
  //       showToastErrorMsg(
  //         response.message || response.error.response.data.message
  //       );
  //       //setFailureModal(true);
  //     }
  //   }
  // };

  const handleBannerRemove = async () => {
    const Payload = {
      companyId: state?.auth?.auth?.userDetails?.companyId,
      bannerImage: "",
    };
    const token = state?.auth?.auth?.authKey;
    const response = await UpdateCompanyDetail(Payload, token);
    //   setSuccessModal(true);

    if (response && response.statusCode === 200) {
      fetchCompanyDetails()
      // setBannerPic(CompBanner);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (state.auth.auth.userDetails) {
      const percent = NeededKeysForPercentage.filter(
        (key) => !!state.auth.auth.userDetails[key]
      );
      setPercentage(
        Math.round((percent.length / NeededKeysForPercentage.length) * 100)
      );
    }

    return () => {};
  }, [NeededKeysForPercentage, state?.auth?.auth?.userDetails]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleUpdate("UpdateFullName");
    }
  };

  const handleKeyDownUrl = (event) => {
    if (event.key === "Enter") {
      handleCompanyUpdate("UpdateCompanyURL");
    }
  };
  const [ticketRaiseData, setTicketRaiseData] = useState({});
  const [ticketRaiseDataValidation, setTicketRaiseDataValidation] = useState(
    {}
  );

  const ticketRaiseDataValidationValue = (key, value) => {
    setTicketRaiseDataValidation({
      ...ticketRaiseDataValidation,
      [key]: value,
    });
  };

  const onChangeTicketRaiseData = (key, value) => {
    setTicketRaiseData({ ...ticketRaiseData, [key]: value });
    if (ticketRaiseDataValidation[key]) delete ticketRaiseDataValidation[key];
  };

  const handleTicketRaise = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.newValue =
      ticketRaiseData.type === "Official Email"
        ? EmailCompanyValidation(
            ticketRaiseData.newValue,
            basicDetails?.officalEmail
          )
        : CompanyNameChangeValidation(
            ticketRaiseData.newValue,
            basicDetails?.companyName
          );
    validate.description = CompanyNameValidation(ticketRaiseData.description);

    setTicketRaiseDataValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        userProfileId: state?.auth?.auth?.userProfileId,
        companyProfileId: state?.auth?.auth?.userDetails?.companyProfileId,
        changeRequest: `${ticketRaiseData.type} change request`,
        type: ticketRaiseData.type,
        newValue: ticketRaiseData.newValue,
        oldValue:
          ticketRaiseData.type === "Company name"
            ? basicDetails?.companyName
            : basicDetails?.officalEmail,
        description: ticketRaiseData.description,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await CreateTicket(Payload, token);

      if (response && response.statusCode === 200) {
        setEditInputFieldModal(false);
        setTicketRaiseData({});
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };
  const [changePasswordData, setChangePasswordData] = useState({});

  const [changePasswordValidationData, setChangePasswordValidationData] = useState({});
  const CheckChangePasswordValidation = () => {
    let status = true,
      validation = {};

    if (
      !changePasswordData.oldPassword ||
      !changePasswordData?.oldPassword?.trim()?.length
    ) {
      status = false;
      validation.oldPassword = "Current Password is required";
    }

    if (
      !changePasswordData.password ||
      !changePasswordData?.password?.trim()?.length
    ) {
      status = false;
      validation.password = "New Password is required";
    } else if (
      changePasswordData.password &&
      changePasswordData?.oldPassword &&
      changePasswordData?.oldPassword === changePasswordData.password
    ) {
      status = false;
      validation.password = "New password can't be same as the Old password";
    } else if (!PasswordRegex.test(changePasswordData.password)) {
      status = false;
      validation.password = "Enter your Password with correct combinations";
    }
    if (
      !changePasswordData.confirmPassword ||
      !changePasswordData?.confirmPassword?.trim()?.length
    ) {
      status = false;
      validation.confirmPassword = "Confirm Password is required";
    } else if (
      changePasswordData.confirmPassword &&
      changePasswordData?.password &&
      changePasswordData.confirmPassword !== changePasswordData.password
    ) {
      status = false;
      validation.confirmPassword =
        "Confirm password is mismatch with New password";
    }
    setChangePasswordValidationData(validation);
    return status;
  };
  const onChangeChangePassword = (key, value) => {
    setChangePasswordData({ ...changePasswordData, [key]: value });
    if (changePasswordValidationData[key])
      delete changePasswordValidationData[key];
  };
  const [photoURL, setPhotoURL] = useState(basicDetails?.profileImage);
  const [openCrop, setOpenCrop] = useState(false);
  const [setFile] = useState(null);

  return (
    <div className="dashboard-bg">
      <NavbarDashboard
        title={"Hello! " + state?.auth?.auth?.userDetails?.displayName}
      />

      {!openCrop ? (
        <div className="main">
          <div className="profilesection1 d-flex align-items-center py-1">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4 col-md-12 d-flex employer-profile-name">
                  <div className="profilesection1-content">
                    <div className="profile-name2 m-auto d-flex align-items-center justify-content-center">
                      <h1 className="  font-weight-bold">
                        {!createdCompanyDetails?.companyLogo
                          ? GetInitials(basicDetails?.companyName)
                          : null}
                      </h1>

                      <div className="banner-profile-dashboard2">
                        <img
                          className="PP-upload-img"
                          src={createdCompanyDetails?.companyLogo}
                          alt=""
                          hidden={!createdCompanyDetails?.companyLogo}
                        />
                        <div className="banner-profile-dashboard-text">
                          <div className="text">
                            <input
                              autoComplete="off"
                              type="file"
                              id="actual-btn"
                              hidden
                              onChange={(e) => {
                                uploadCeoPic(e,"companyLogo");
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {basicDetails?.role === "Admin" ? (
                        <>
                          {!createdCompanyDetails?.companyLogo ? (
                            <span
                              className="material-symbols-rounded photo-icon-css2 "
                              onClick={() => CeoRef.current.click()}
                            >
                              add_a_photo
                              <input
                                autoComplete="off"
                                type="file"
                                name="myfile"
                                ref={CeoRef}
                                hidden
                                onChange={(e) => {
                                  uploadCeoPic(e,"companyLogo");
                                }}
                              />
                            </span>
                          ) : (
                            <span
                              className="material-symbols-rounded text-danger photo-icon-css2 "
                              onClick={handleRemoveCompanyPic}
                            >
                              cancel
                            </span>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div className="progress-add-icon">
                    {!CAPic ? (
                      <span className="material-symbols-rounded photo-icon-css" onClick={() => CARef.current.click()}>
                        add_a_photo
                        <input autoComplete="off" type="file" name="myfile" ref={CARef} hidden onChange={(e) => uploadCAPic(e)} />
                      </span>
                    ) : (
                      <span className="material-symbols-rounded text-danger photo-icon-css" onClick={handleRemoveProfilePhoto}>
                        cancel
                      </span>
                    )}
                    <div className="progress-profile ">
                      <CircularProgressbarWithChildren
                        value={percentage}
                        styles={{
                          position: "absolute",
                          width: "50%",
                          height: "100%",
                          marginTop: "-50%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span className="title timer" data-from="0" data-to={percentage} data-speed="1800">
                          {!CAPic ? GetInitials(basicDetails?.fullName) : null}
                          <img className="PP-upload-img" src={CAPic} alt="" />
                        </span>
                      </CircularProgressbarWithChildren>
                    </div>
                  </div> */}
                    <div className="profile-name-content mx-2 my-auto ">
                      <h6 className="font-weight-bold mx-2">
                        {basicDetails?.companyName?.label ||
                          basicDetails?.companyName}
                      </h6>
                      {basicDetails?.status === "Active" ||
                      basicDetails?.approved === true ? (
                        <>
                          <p className="mb-0">
                            <span className="material-symbols-rounded icon-middle mr-2">
                              language
                            </span>
                            <input
                              value={createdCompanyDetails?.companyUrl}
                              onChange={(e) => {
                                if (!CD_ComapnyUrl) {
                                  setCompanyDetailsValue(
                                    "companyUrl",
                                    e.target.value
                                  );
                                }
                              }}
                              onBlur={(e) => {
                                setValidationValue(
                                  "companyUrl",
                                  URLValidation(e.target.value)
                                );
                              }}
                              ref={CompanyUrlLabelRef}
                              onKeyDown={handleKeyDownUrl}
                              className={`EditableTextBox ${
                                !CD_ComapnyUrl ? "active" : ""
                              }`}
                              readOnly={CD_ComapnyUrl}
                            />
                            <span
                              onClick={() => {
                                setSelectedTab("Company Details");
                                setCD_ComapnyUrl(!CD_ComapnyUrl);
                                CompanyUrlLabelRef.current.focus();
                              }}
                              className="ml-2 material-icons edit-icon"
                            >
                              edit
                            </span>
                          </p>
                          <small className="text-danger">
                            {CompanyDetailsValidation?.companyUrl?.message
                              ? `Company url ${CompanyDetailsValidation?.companyUrl?.message}`
                              : ""}
                          </small>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="res-percentage-content profile-name-content">
                    <h3
                      className="font-weight-bold progress-color"
                      style={{
                        color:
                          percentage <= 60
                            ? "#e75f6b"
                            : percentage <= 80
                            ? "orange"
                            : "green",
                      }}
                    >
                      {percentage}%
                    </h3>
                    <p className="mb-0">Profile Completed</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6  my-auto">
                  <div className="profilesection1-content res-psc custom-border ">
                    <div className="progress-add-icon">
                      {!basicDetails?.profileImage ? (
                        <span
                          className="material-symbols-rounded photo-icon-css"
                          onClick={() => CARef.current.click()}
                        >
                          add_a_photo
                          <input
                            autoComplete="off"
                            type="file"
                            name="myfile"
                            ref={CARef}
                            hidden
                            onChange={(e) => uploadCeoPic(e,"profileImage")}
                          />
                        </span>
                      ) : (
                        <span
                          className="material-symbols-rounded text-danger photo-icon-css"
                          onClick={handleRemoveProfilePhoto}
                        >
                          cancel
                        </span>
                      )}
                      <div className="progress-profile ">
                        <CircularProgressbarWithChildren
                          value={percentage}
                          styles={{
                            position: "absolute",
                            width: "50%",
                            height: "100%",
                            marginTop: "-50%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="title timer"
                            data-from="0"
                            data-to={percentage}
                            data-speed="1800"
                          >
                            {!basicDetails?.profileImage
                              ? GetInitials(basicDetails?.fullName)
                              : null}
                            <img className="PP-upload-img" src={basicDetails?.profileImage} alt="" />
                          </span>
                        </CircularProgressbarWithChildren>
                      </div>
                    </div>
                    <div className="profile-name-content mx-2 my-auto">
                      <ul className="mb-0 pl-0">
                        <li className="font-weight-bold mb-2">
                          <span className="material-symbols-rounded icon-middle mr-2">
                            account_circle
                          </span>

                          <input
                            value={basicDetails?.fullName}
                            onChange={(e) => {
                              if (!BD_FullName) {
                                setValue("fullName", e.target.value);
                              }
                            }}
                            onBlur={(e) => {
                              setBasicDetailsValidationValue(
                                "fullName",
                                StringValidation(e.target.value)
                              );
                              // setBD_FullName(!BD_FullName);
                            }}
                            ref={FullNameLabelRef}
                            onKeyDown={handleKeyDown}
                            className={`EditableTextBox ${
                              !BD_FullName ? "active" : ""
                            }`}
                            readOnly={BD_FullName}
                          />
                          <span
                            className="material-icons edit-icon fullName"
                            onClick={() => {
                              setSelectedTab("Basic Details");
                              setBD_FullName(!BD_FullName);

                              FullNameLabelRef.current.focus();
                            }}
                          >
                            edit
                          </span>
                        </li>
                        <small className="text-danger">
                          {basicDetailsValidation?.fullName?.message
                            ? `Full name ${basicDetailsValidation?.fullName?.message}`
                            : ""}
                        </small>
                        <li className="font-weight-bold">
                          <span className="material-symbols-rounded icon-middle mr-2">
                            mail
                          </span>
                          {basicDetails?.officalEmail} <span></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 my-auto">
                  <div className="profilesection-content res-psc mx-5">
                    <div className="d-flex  justify-content-between">
                      <h6
                        className="font-weight-bold"
                        style={{ paddingRight: "10px" }}
                      >
                        Profile Completion Status
                      </h6>
                      <h5
                        className="progress-color font-weight-bold"
                        style={{
                          color:
                            percentage <= 60
                              ? "#e75f6b"
                              : percentage <= 80
                              ? "orange"
                              : "green",
                        }}
                      >
                        {percentage}%
                      </h5>
                    </div>

                    <div className="progress">
                      {/* <div className="progress-bar" role="progressbar" style={{ width: "25%", borderRadius: "20px" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div> */}
                      <div
                        className="progress-bar"
                        style={{
                          width: `${percentage}%`,
                          backgroundColor:
                            percentage <= 60
                              ? "#e75f6b"
                              : percentage <= 80
                              ? "orange"
                              : "green",
                          borderRadius: "20px",
                        }}
                        aria-valuenow={percentage}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    {percentage !== 100 ? (
                      <div>
                        Fill{" "}
                        <span className="text-capitalize">
                          {NeededKeysForPercentage.filter(
                            (key) => !basicDetails[key]
                          ).toLocaleString()}
                        </span>{" "}
                        to make 100%
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profilesection2 py-1">
            <div className="">
              <>
                <nav className="flex border-b tab-section border-gray-300">
                  {Alltabs.map((tab, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          selectedTab === tab ? "tab active" : "tab inactive"
                        }
                        onClick={() => {
                          if (tab === "Approval") {
                            navigation({
                              pathname: "/Profile",
                              search: `tab=Approval&subtab=Pending`,
                            });
                          } else {
                            navigation({
                              pathname: "/Profile",
                              search: `tab=${tab}`,
                            });
                          }
                        }}
                      >
                        <li className="tab-header">{tab}</li>
                      </div>
                    );
                  })}
                </nav>
                <div className="py-1">
                  {/*///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                  {selectedTab === "Basic Details" && (
                    <div className="scroll-content">
                      <div className="container">
                        <div className="row d-flex align-items-center justify-content-center ">
                          <div className="col-lg-10">
                            <form>
                              <div className="form-container py-1">
                                <div className="row ">
                                  <div className="col-lg-6 px-5 pb-4 border-right">
                                    <div className="google-input">
                                      <label
                                        htmlFor="template-input"
                                        className="z-index-value"
                                      >
                                        Company name
                                      </label>
                                      <div className="dropdown-container2">
                                        <input
                                          autoComplete="off"
                                          type="text"
                                          id="template-input"
                                          placeholder="Enter your email"
                                          defaultValue={
                                            basicDetails?.companyName
                                          }
                                          disabled
                                        />
                                        <span
                                          className="material-symbols-rounded  text-white rounded edit-icon-input"
                                          onClick={() => {
                                            onChangeTicketRaiseData(
                                              "type",
                                              "Company Name"
                                            );

                                            setEditInputFieldModal(true);
                                          }}
                                        >
                                          edit
                                        </span>
                                      </div>
                                    </div>
                                    <div className="google-input">
                                      <input
                                        autoComplete="off"
                                        type="text"
                                        id="fullName"
                                        aria-invalid={Boolean(
                                          basicDetails?.fullName?.status ===
                                            false
                                        )}
                                        placeholder="Enter your full name"
                                        defaultValue={basicDetails?.fullName}
                                        onChange={(e) => {
                                          setValue("fullName", e.target.value);
                                        }}
                                        onBlur={(e) => {
                                          setBasicDetailsValidationValue(
                                            "fullName",
                                            StringValidation(e.target.value)
                                          );
                                        }}
                                        onKeyUp={(e) => {
                                          setValue("fullName", e.target.value);
                                        }}
                                      />
                                      <label htmlFor="template-input">
                                        Full name
                                      </label>
                                      <small className="text-danger">
                                        {basicDetailsValidation?.fullName
                                          ?.message
                                          ? `Full name ${basicDetailsValidation?.fullName?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="google-input">
                                      <input
                                        autoComplete="off"
                                        type="text"
                                        id="template-input"
                                        aria-invalid={Boolean(
                                          basicDetails?.displayName?.status ===
                                            false
                                        )}
                                        placeholder="Enter your display name"
                                        onBlur={(e) => {
                                          setBasicDetailsValidationValue(
                                            "displayName",
                                            StringValidation(e.target.value)
                                          );
                                        }}
                                        defaultValue={basicDetails?.displayName}
                                        onChange={(e) => {
                                          setValue(
                                            "displayName",
                                            e.target.value
                                          );
                                        }}
                                        onKeyUp={(e) => {
                                          setValue(
                                            "displayName",
                                            e.target.value
                                          );
                                        }}
                                      />
                                      <label htmlFor="template-input">
                                        Display name
                                      </label>
                                      <small className="text-danger">
                                        {basicDetailsValidation?.displayName
                                          ?.message
                                          ? `Display name ${basicDetailsValidation?.displayName?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 px-5 pb-4">
                                    <div className="google-input">
                                      <input
                                        autoComplete="off"
                                        type="text"
                                        id="template-input"
                                        placeholder="Enter your email"
                                        disabled
                                        defaultValue={
                                          basicDetails?.officalEmail
                                        }
                                      />
                                      {basicDetails?.emailIdVerified ===
                                      true ? (
                                        <span
                                          className="material-symbols-rounded  text-white rounded edit-icon-input"
                                          onClick={() => {
                                            onChangeTicketRaiseData(
                                              "type",
                                              "Official Email"
                                            );
                                            setEditInputFieldModal(true);
                                          }}
                                        >
                                          edit
                                        </span>
                                      ) : (
                                        <span
                                          className="text-white bg-danger rounded edit-icon-input"
                                          onClick={() => {
                                            setEmailVerifyModal(true);
                                            Resent();
                                          }}
                                        >
                                          Verify
                                        </span>
                                      )}

                                      <label htmlFor="template-input">
                                        Official Email
                                      </label>
                                    </div>
                                    <PhoneInput
                                      inputStyle={{
                                        width: "100%",
                                        height: "45px",
                                        onFocus: "red",
                                      }}
                                      onlyCountries={["us"]}
                                      country={"us"}
                                      value={basicDetails?.phoneNumber?.toString()}
                                      onChange={(e) => {
                                        setValue("phoneNumber", e);
                                      }}
                                      onBlur={(e) => {
                                        setBasicDetailsValidationValue(
                                          "phoneNumber",
                                          PhoneNumberValidation(e.target.value)
                                        );
                                      }}
                                    />
                                    <small className="text-danger">
                                      {basicDetailsValidation?.phoneNumber
                                        ?.message
                                        ? `Phone number ${basicDetailsValidation?.phoneNumber?.message}`
                                        : ""}
                                    </small>
                                    <div className="google-input">
                                      <label
                                        htmlFor="template-input"
                                        className="z-index-value"
                                      >
                                        Gender
                                      </label>

                                      <div className="dropdown-container">
                                        <Select
                                          options={gender}
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: "6px",

                                            zIndex: 3,
                                            colors: {
                                              ...theme.colors,
                                              primary25: "#DFF1F1",
                                              neutral20:
                                                basicDetailsValidation?.genderId
                                                  ?.status === false
                                                  ? "red"
                                                  : "#00ABAD", // border color
                                              primary:
                                                basicDetailsValidation?.genderId
                                                  ?.status === false
                                                  ? "red"
                                                  : "#00ABAD", // border color when focus
                                            },
                                          })}
                                          placeholder="Select Gender"
                                          value={{
                                            value:
                                              basicDetails?.genderId?.value ||
                                              basicDetails?.genderName ||
                                              basicDetails?.genderId,
                                            label:
                                              basicDetails?.genderId?.label ||
                                              basicDetails?.genderName ||
                                              basicDetails?.genderId,
                                          }}
                                          onChange={(e) => {
                                            setValue("genderId", e);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <small className="text-danger">
                                      {basicDetailsValidation?.genderId?.message
                                        ? `Gender ${basicDetailsValidation?.genderId?.message}`
                                        : ""}
                                    </small>
                                  </div>
                                  <div className="col-lg-12 text-center">
                                    <div className="google-input">
                                      <button
                                        type="button"
                                        className="btn btn-light my-2 my-sm-0 login-btn"
                                        onClick={() => {
                                          handleUpdate("UpdateBasicDetails");
                                        }}
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* <ToastContainer /> */}
                    </div>
                  )}
                  {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                  {selectedTab === "Company Details" && (
                    <>
                      {basicDetails?.status === "Active" ||
                      basicDetails?.approved === true ? (
                        <div className="scroll-content">
                          <div className="banner-profile-dashboard">
                            <img
                              className="bannerImage"
                              src={createdCompanyDetails?.bannerImage === "" ? CompBanner : createdCompanyDetails?.bannerImage}
                              alt=""
                            />
                            <div className="banner-profile-dashboard-text">
                              <h5>Replace Banner Image</h5>
                              <p className="">
                                Optimal dimensions 3200 x 410px{" "}
                              </p>
                              <div className="text">
                                <input
                                  autoComplete="off"
                                  type="file"
                                  id="actual-btn1"
                                  hidden
                                  onChange={(e) => {
                                    uploadCeoPic(e,"bannerImage");
                                  }}
                                />
                                <label
                                  className="login-btn"
                                  htmlFor="actual-btn1"
                                >
                                  Replace Image
                                </label>
                                <span
                                  className="ml-4 chck-outline-hover"
                                  onClick={handleBannerRemove}
                                >
                                  Remove
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="container">
                            <div className="row d-flex  justify-content-center scroll-content">
                              <div className="col-lg-2 mt-5 mb-auto d-flex flex-column profilesection1-content">
                                <div className="profile-name2 m-auto d-flex align-items-center justify-content-center">
                                  <h1 className="  font-weight-bold">
                                    {!createdCompanyDetails?.companyLogo
                                      ? GetInitials(basicDetails?.companyName)
                                      : null}
                                  </h1>

                                  <div className="banner-profile-dashboard2">
                                    <img
                                      className="PP-upload-img"
                                      src={createdCompanyDetails?.companyLogo}
                                      alt=""
                                      hidden={!createdCompanyDetails?.companyLogo}
                                    />
                                    <div className="banner-profile-dashboard-text">
                                      <div className="text">
                                        <input
                                          autoComplete="off"
                                          type="file"
                                          id="actual-btn"
                                          hidden
                                          onChange={(e) => {
                                            uploadCeoPic(e,"companyLogo");
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {!createdCompanyDetails?.companyLogo ? (
                                    <span
                                      className="material-symbols-rounded photo-icon-css2 "
                                      onClick={() => CeoRef.current.click()}
                                    >
                                      add_a_photo
                                      <input
                                        autoComplete="off"
                                        type="file"
                                        name="myfile"
                                        ref={CeoRef}
                                        hidden
                                        onChange={(e) => {
                                          uploadCeoPic(e,"companyLogo");
                                        }}
                                      />
                                    </span>
                                  ) : (
                                    <span
                                      className="material-symbols-rounded text-danger photo-icon-css2 "
                                      onClick={handleRemoveCompanyPic}
                                    >
                                      cancel
                                    </span>
                                  )}
                                </div>

                                <small className="font-weight-bold text-center d-flex justify-content-center">
                                  Company Logo
                                </small>
                              </div>
                              <div className="col-lg-10">
                                <form>
                                  <div className="form-container py-1">
                                    <div className="row ">
                                      <div className="col-lg-6 px-5 pb-4 border-right">
                                        <div className="google-input">
                                          <input
                                            autoComplete="off"
                                            type="text"
                                            id="ceoName"
                                            aria-invalid={
                                              CompanyDetailsValidation?.ceoName
                                                ?.status === false
                                            }
                                            placeholder="Enter your CEO name"
                                            onBlur={(e) => {
                                              setValidationValue(
                                                "ceoName",
                                                StringValidation(e.target.value)
                                              );
                                            }}
                                            value={
                                              createdCompanyDetails?.ceoName ||
                                              ""
                                            }
                                            onChange={(e) => {
                                              setCompanyDetailsValue(
                                                "ceoName",
                                                e.target.value
                                              );
                                            }}
                                          />
                                          <label htmlFor="template-input">
                                            CEO Name
                                          </label>
                                        </div>

                                        <small className="text-danger">
                                          {CompanyDetailsValidation?.ceoName
                                            ?.message
                                            ? `Ceo name ${CompanyDetailsValidation?.ceoName?.message}`
                                            : ""}
                                        </small>
                                        <div className="google-input">
                                          <input
                                            autoComplete="off"
                                            type="text"
                                            id="founded"
                                            maxLength={4}
                                            aria-invalid={Boolean(
                                              CompanyDetailsValidation?.founded
                                                ?.status === false
                                            )}
                                            placeholder="Enter your Founded Year"
                                            value={
                                              createdCompanyDetails?.founded ||
                                              ""
                                            }
                                            onChange={(e) => {
                                              setCompanyDetailsValue(
                                                "founded",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setValidationValue(
                                                "founded",
                                                DateValidation(e.target.value)
                                              );
                                            }}
                                          />
                                          <label htmlFor="template-input">
                                            Founded
                                          </label>
                                        </div>
                                        <small className="text-danger">
                                          {CompanyDetailsValidation?.founded
                                            ?.message
                                            ? `Founded year ${CompanyDetailsValidation?.founded?.message}`
                                            : ""}
                                        </small>
                                        <div className="google-input">
                                          <input
                                            autoComplete="off"
                                            type="text"
                                            id="companyUrl"
                                            placeholder="Enter your website link"
                                            aria-invalid={Boolean(
                                              CompanyDetailsValidation
                                                ?.companyUrl?.status === false
                                            )}
                                            value={
                                              createdCompanyDetails?.companyUrl ||
                                              ""
                                            }
                                            onChange={(e) => {
                                              setCompanyDetailsValue(
                                                "companyUrl",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setValidationValue(
                                                "companyUrl",
                                                URLValidation(e.target.value)
                                              );
                                            }}
                                          />
                                          <label htmlFor="template-input">
                                            Company URL
                                          </label>
                                        </div>
                                        <small className="text-danger">
                                          {CompanyDetailsValidation?.companyUrl
                                            ?.message
                                            ? `Company url ${CompanyDetailsValidation?.companyUrl?.message}`
                                            : ""}
                                        </small>
                                        <div className="google-input">
                                          <label
                                            htmlFor="companyIndustryId"
                                            className="z-index-value"
                                          >
                                            Industry
                                          </label>

                                          <div className="dropdown-container">
                                            <CreatableSelect
                                              options={companyIndustryData}
                                              id="companyIndustryId"
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: "6px",
                                                zIndex: 3,
                                                colors: {
                                                  ...theme.colors,
                                                  primary25: "#DFF1F1",
                                                  neutral20:
                                                    CompanyDetailsValidation
                                                      ?.companyIndustryId
                                                      ?.status === false
                                                      ? "red"
                                                      : "#00ABAD", // border color
                                                  primary:
                                                    CompanyDetailsValidation
                                                      ?.companyIndustryId
                                                      ?.status === false
                                                      ? "red"
                                                      : "#00ABAD", // border color when focus
                                                },
                                              })}
                                              styles={{
                                                menu: (provided, state) => ({
                                                  ...provided,
                                                  zIndex: "5 !important",
                                                }),
                                              }}
                                              onInputChange={() => {
                                                if (
                                                  CompanyDetailsValidation[
                                                    "companyIndustryId"
                                                  ]
                                                )
                                                  delete CompanyDetailsValidation[
                                                    "companyIndustryId"
                                                  ];
                                              }}
                                              placeholder="Select your company's revenue"
                                              value={{
                                                value:
                                                  createdCompanyDetails
                                                    ?.companyIndustryId
                                                    ?.value ||
                                                  createdCompanyDetails?.companyIndustryId ||
                                                  createdCompanyDetails?.companyIndustryId,
                                                label:
                                                  createdCompanyDetails
                                                    ?.companyIndustryId
                                                    ?.label ||
                                                  createdCompanyDetails?.companyIndustryName ||
                                                  createdCompanyDetails?.companyIndustryId,
                                              }}
                                              onChange={(newValue) => {
                                                setCompanyDetailsValue(
                                                  "companyIndustryId",
                                                  newValue
                                                );
                                              }}
                                              onCreateOption={(e) =>
                                                handleCreateCompanyIndustry(e)
                                              }
                                              isClearable
                                            />
                                            <small className="text-danger">
                                              {CompanyDetailsValidation
                                                ?.companyIndustryId?.message
                                                ? `Industry ${CompanyDetailsValidation?.companyIndustryId?.message}`
                                                : ""}
                                            </small>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-lg-6 px-5 pb-4">
                                        <div className="google-input">
                                          <label
                                            htmlFor="revenue"
                                            className="z-index-value"
                                          >
                                            Revenue
                                          </label>

                                          <div className="dropdown-container">
                                            <CreatableSelect
                                              options={revenueData}
                                              id="revenueId"
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: "6px",
                                                zIndex: 3,
                                                colors: {
                                                  ...theme.colors,
                                                  primary25: "#DFF1F1",
                                                  neutral20:
                                                    CompanyDetailsValidation
                                                      ?.revenueId?.status ===
                                                    false
                                                      ? "red"
                                                      : "#00ABAD", // border color
                                                  primary:
                                                    CompanyDetailsValidation
                                                      ?.revenueId?.status ===
                                                    false
                                                      ? "red"
                                                      : "#00ABAD", // border color when focus
                                                },
                                              })}
                                              styles={{
                                                menu: (provided, state) => ({
                                                  ...provided,
                                                  zIndex: "5 !important",
                                                }),
                                              }}
                                              onInputChange={() => {
                                                if (
                                                  CompanyDetailsValidation[
                                                    "revenueId"
                                                  ]
                                                )
                                                  delete CompanyDetailsValidation[
                                                    "revenueId"
                                                  ];
                                              }}
                                              placeholder="Select your company's revenue"
                                              value={{
                                                value:
                                                  createdCompanyDetails
                                                    ?.revenueId?.value ||
                                                  createdCompanyDetails?.revenueId ||
                                                  createdCompanyDetails?.revenueId,
                                                label:
                                                  createdCompanyDetails
                                                    ?.revenueId?.label ||
                                                  createdCompanyDetails?.revenueName ||
                                                  createdCompanyDetails?.revenueId,
                                              }}
                                              onChange={(newValue) => {
                                                setCompanyDetailsValue(
                                                  "revenueId",
                                                  newValue
                                                );
                                                // setRevenueDataValue(newValue);
                                              }}
                                              onCreateOption={
                                                handleCreateRevenue
                                              }
                                              isClearable
                                            />
                                            <small className="text-danger">
                                              {CompanyDetailsValidation
                                                ?.revenueId?.message
                                                ? `Revenue ${CompanyDetailsValidation?.revenueId?.message}`
                                                : ""}
                                            </small>
                                          </div>
                                        </div>

                                        <div className="google-input">
                                          <label
                                            htmlFor="companySize"
                                            className="z-index-value"
                                          >
                                            Company Size
                                          </label>
                                          <div className="dropdown-container">
                                            <CreatableSelect
                                              options={companySize}
                                              id="companySizeId"
                                              theme={(theme) => ({
                                                ...theme,
                                                borderRadius: "6px",
                                                zIndex: 3,
                                                colors: {
                                                  ...theme.colors,
                                                  primary25: "#DFF1F1", // selected option background color
                                                  neutral20:
                                                    CompanyDetailsValidation
                                                      ?.companySizeId
                                                      ?.status === false
                                                      ? "red"
                                                      : "#00ABAD", // border color
                                                  primary:
                                                    CompanyDetailsValidation
                                                      ?.companySizeId
                                                      ?.status === false
                                                      ? "red"
                                                      : "#00ABAD", // border color when focus
                                                },
                                              })}
                                              styles={{
                                                menu: (provided, state) => ({
                                                  ...provided,
                                                  zIndex: "5 !important",
                                                }),
                                              }}
                                              onInputChange={() => {
                                                if (
                                                  CompanyDetailsValidation[
                                                    "companySizeId"
                                                  ]
                                                )
                                                  delete CompanyDetailsValidation[
                                                    "companySizeId"
                                                  ];
                                              }}
                                              placeholder="Enter your Company Size"
                                              value={{
                                                value:
                                                  createdCompanyDetails
                                                    ?.companySizeId?.value ||
                                                  createdCompanyDetails?.companySizeId ||
                                                  createdCompanyDetails?.companySizeId,
                                                label:
                                                  createdCompanyDetails
                                                    ?.companySizeId?.label ||
                                                  createdCompanyDetails?.companySizeName ||
                                                  createdCompanyDetails?.companySizeId,
                                              }}
                                              onChange={(newValue) =>
                                                setCompanyDetailsValue(
                                                  "companySizeId",
                                                  newValue
                                                )
                                              }
                                              onCreateOption={
                                                handleCreateCompanySize
                                              }
                                              isClearable
                                            />
                                            <small className="text-danger">
                                              {CompanyDetailsValidation
                                                ?.companySizeId?.message
                                                ? `Company size ${CompanyDetailsValidation?.companySizeId?.message}`
                                                : ""}
                                            </small>
                                          </div>
                                        </div>
                                        <div className="google-input">
                                          <label
                                            htmlFor="headQuarterslocation"
                                            className="z-index-value"
                                          >
                                            Headquarters Location
                                          </label>
                                          <div className="google-input">
                                            <input
                                              autoComplete="off"
                                              type="text"
                                              aria-invalid={Boolean(
                                                CompanyDetailsValidation
                                                  ?.headQuarterslocation
                                                  ?.status === false
                                              )}
                                              id="headQuarterslocation"
                                              placeholder="Enter your Headquarters Location"
                                              value={
                                                createdCompanyDetails?.headQuarterslocation ||
                                                ""
                                              }
                                              onChange={(e) => {
                                                setCompanyDetailsValue(
                                                  "headQuarterslocation",
                                                  e.target.value
                                                );
                                              }}
                                              onBlur={(e) => {
                                                setValidationValue(
                                                  "headQuarterslocation",
                                                  StringValidation(
                                                    e.target.value
                                                  )
                                                );
                                              }}
                                            />
                                          </div>
                                          <small className="text-danger">
                                            {CompanyDetailsValidation
                                              ?.headQuarterslocation?.message
                                              ? `Headquarters location ${CompanyDetailsValidation?.headQuarterslocation?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 text-center">
                                        <div className="google-input">
                                          <button
                                            type="button"
                                            className="btn btn-light my-2 my-sm-0 login-btn"
                                            onClick={() =>
                                              handleCompanyUpdate(
                                                "UpdateCompanyDetails"
                                              )
                                            }
                                          >
                                            Update
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* <ToastContainer /> */}
                        </div>
                      ) : (
                        <div className="modal-body p-3 ">
                          <div
                            className="modalHeader"
                            style={{ backgroundColor: "#ffffff" }}
                          >
                            <h6 className="text-center font-weight-bold my-2">
                              <img
                                style={{
                                  width: "85px",
                                  textShadow:
                                    "rgb(171 167 167 / 44%) 0px 3px 6px;",
                                }}
                                src={Roboimg2}
                                alt=""
                              />
                            </h6>
                          </div>
                          <form className="container">
                            <div className="row d-flex align-items-center justify-content-center  mt-0">
                              <div className="col-lg-8 pb-3">
                                <p className=" text-center">
                                  Your account is still pending for approval.
                                </p>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </>
                  )}
                  {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////              */}

                  {selectedTab === "Gallery" && (
                    <>
                      {basicDetails.status === "Active" ||
                      basicDetails.approved === true ? (
                        <div>
                          <div className="container py-4">
                            <div className="row justify-content-center d-flex align-items-center ">
                              <div className="col-lg-10">
                                <div className="row">
                                  <div className="col-lg-3 text-center">
                                    <div className="upload-btn-wrapper mx-3">
                                      <button className="btn upload-gallery">
                                        <h1>
                                          <span className="material-symbols-rounded">
                                            add
                                          </span>
                                        </h1>
                                        Upload Images
                                      </button>
                                      <input
                                        autoComplete="off"
                                        type="file"
                                        name="myfile"
                                        onChange={(e) => uploadCeoPic(e,"gallery")}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-9 scroll-content-design">
                                    <div className="gallery-section">
                                      <div className="row">
                                        {createdGallery?.map((e, i) => (
                                          <div
                                            key={i}
                                            className="col-lg-3 px-2 mb-3"
                                          >
                                            <img src={e.imageUrl} alt="" />

                                            <span
                                              className="material-symbols-rounded  text-white rounded del-icon"
                                              onClick={() => {
                                                setGalleryId(e.galleryId);
                                                setDeleteGalleryModal(true);
                                              }}
                                            >
                                              delete
                                            </span>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <ToastContainer /> */}
                        </div>
                      ) : (
                        <div className="modal-body p-3 ">
                          <div
                            className="modalHeader"
                            style={{ backgroundColor: "#ffffff" }}
                          >
                            <h6 className="text-center font-weight-bold my-2">
                              <img
                                style={{
                                  width: "85px",
                                  textShadow:
                                    "rgb(171 167 167 / 44%) 0px 3px 6px;",
                                }}
                                src={Roboimg2}
                                alt=""
                              />
                            </h6>
                          </div>
                          <form className="container">
                            <div className="row d-flex align-items-center justify-content-center  mt-0">
                              <div className="col-lg-8 pb-3">
                                <p className=" text-center">
                                  Your account is still pending for approval.
                                </p>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </>
                  )}
                  {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////                */}
                  {selectedTab === "Change Password" && (
                    <div>
                      <div className="container">
                        <div className="row d-flex  justify-content-center scroll-content">
                          <div className="col-lg-10">
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                onSubmitChangePassword(e);
                              }}
                            >
                              <div className="form-container">
                                <div className="row justify-content-center">
                                  <div className="col-lg-6 px-5 pb-4">
                                    <div className="google-input">
                                      <input
                                        autoComplete="off"
                                        type="password"
                                        id="oldPassword"
                                        name="oldPassword"
                                        placeholder="Enter your Current Password"
                                        value={
                                          changePasswordData?.oldPassword || ""
                                        }
                                        onChange={(e) => {
                                          onChangeChangePassword(
                                            "oldPassword",
                                            e.target.value
                                          );
                                        }}
                                      />
                                      <label htmlFor="oldPassword">
                                        Current Password
                                      </label>
                                    </div>
                                    <p className="text-danger">
                                      {
                                        changePasswordValidationData?.oldPassword
                                      }
                                    </p>
                                    <div className="google-input">
                                      <input
                                        autoComplete="off"
                                        type="password"
                                        id="password"
                                        name="password"
                                        placeholder="Enter your New Password"
                                        value={
                                          changePasswordData?.password || ""
                                        }
                                        onChange={(e) => {
                                          onChangeChangePassword(
                                            "password",
                                            e.target.value
                                          );
                                        }}
                                      />
                                      <label htmlFor="password">
                                        New Password
                                      </label>
                                    </div>
                                    <p className="text-danger">
                                      {changePasswordValidationData?.password}
                                    </p>
                                    <div>
                                      {changePasswordData?.password ? (
                                        <div className="mt-3">
                                          <b>
                                            Create a password with the following
                                          </b>
                                          <br />

                                          {
                                            <p
                                              className={`${
                                                changePasswordData?.password.trim()
                                                  .length < 8
                                                  ? "text-danger"
                                                  : "text-success"
                                              } d-flex`}
                                            >
                                              {changePasswordData?.password.trim()
                                                .length < 8 ? (
                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                  close
                                                </span>
                                              ) : (
                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                  check
                                                </span>
                                              )}
                                              At least 8 Characters
                                            </p>
                                          }

                                          {
                                            <p
                                              className={`${
                                                !/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                                  changePasswordData?.password
                                                )
                                                  ? "text-danger"
                                                  : "text-success"
                                              } d-flex`}
                                            >
                                              {!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                                changePasswordData?.password
                                              ) ? (
                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                  close
                                                </span>
                                              ) : (
                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                  check
                                                </span>
                                              )}
                                              An Uppercase &amp; Lowercase
                                              character
                                            </p>
                                          }

                                          {
                                            <p
                                              className={`${
                                                !/^(?=.*[.,:;'!@#$%^&*_+=|(){}[?\-\]/\\]).*$/.test(
                                                  changePasswordData?.password
                                                )
                                                  ? "text-danger"
                                                  : "text-success"
                                              } d-flex`}
                                            >
                                              {!/^(?=.*[.,:;'!@#$%^&*_+=|(){}[?\-\]/\\]).*$/.test(
                                                changePasswordData?.password
                                              ) ? (
                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                  close
                                                </span>
                                              ) : (
                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                  check
                                                </span>
                                              )}
                                              A special character
                                            </p>
                                          }

                                          {
                                            <p
                                              className={`${
                                                !/[0-9]/.test(
                                                  changePasswordData?.password
                                                )
                                                  ? "text-danger"
                                                  : "text-success"
                                              } d-flex`}
                                            >
                                              {!/[0-9]/.test(
                                                changePasswordData?.password
                                              ) ? (
                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                  close
                                                </span>
                                              ) : (
                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                  check
                                                </span>
                                              )}
                                              A number
                                            </p>
                                          }
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="google-input">
                                      <input
                                        autoComplete="off"
                                        type="password"
                                        id="template-input"
                                        placeholder="Re-Enter your New Password"
                                        value={
                                          changePasswordData?.confirmPassword ||
                                          ""
                                        }
                                        onChange={(e) => {
                                          onChangeChangePassword(
                                            "confirmPassword",
                                            e.target.value
                                          );
                                        }}
                                      />
                                      <label htmlFor="template-input">
                                        Re-Enter New Password
                                      </label>
                                    </div>
                                    <p className="text-danger">
                                      {
                                        changePasswordValidationData?.confirmPassword
                                      }
                                    </p>
                                  </div>
                                  <div className="col-lg-12 text-center">
                                    <div className="google-input">
                                      <button className="btn btn-light my-2 my-sm-0 login-btn">
                                        Change Password
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* <ToastContainer /> */}
                    </div>
                  )}
                  {selectedTab === "Approval" &&
                    state?.auth?.auth?.userDetails?.role === "Admin" && (
                      <>
                        <nav className="flex border-b tab-section border-gray-300">
                          {approvalTabs.map((tab, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  approvalSubTab === tab
                                    ? "tab active"
                                    : "tab inactive"
                                }
                                onClick={() => {
                                  navigation({
                                    pathname: "/Profile",
                                    search: `tab=Approval&subtab=${tab}`,
                                  });
                                }}
                              >
                                <li className="tab-header">{tab}</li>
                              </div>
                            );
                          })}
                        </nav>
                        <div className="py-1">
                          {approvalSubTab === "Pending" && (
                            <Approval
                              type="Pending"
                              showToastSuccess={showToastSuccessMsg}
                              showToastError={showToastErrorMsg}
                            />
                          )}
                        </div>
                        <div className="py-1">
                          {approvalSubTab === "Approved" && (
                            <Approval
                              type="Approved"
                              showToastSuccess={showToastSuccessMsg}
                              showToastError={showToastErrorMsg}
                            />
                          )}
                        </div>
                        <div className="py-1">
                          {approvalSubTab === "Rejected" && (
                            <Approval
                              type="Rejected"
                              showToastSuccess={showToastSuccessMsg}
                              showToastError={showToastErrorMsg}
                            />
                          )}
                        </div>
                      </>
                    )}
                </div>
              </>
            </div>
          </div>
          <div className="res-profilesection2">
            <div id="accordion">
              <div className="card my-1">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      Basic Details
                      <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                        expand_more
                      </span>
                    </button>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="card-body p-0">
                    <div className="scroll-content">
                      <div className="container">
                        <div className="row d-flex align-items-center justify-content-center ">
                          <div className="col-lg-10 col-md-12">
                            <form>
                              <div className="form-container py-1">
                                <div className="row ">
                                  <div className="col-lg-6 col-md-6  border-right">
                                    <div className="google-input">
                                      <label
                                        htmlFor="template-input"
                                        className="z-index-value"
                                      >
                                        Company name
                                      </label>
                                      <div className="dropdown-container2">
                                        <input
                                          autoComplete="off"
                                          type="text"
                                          id="template-input"
                                          placeholder="Enter your email"
                                          defaultValue={
                                            basicDetails?.companyName
                                          }
                                          disabled
                                        />
                                        <span
                                          className="material-symbols-rounded  text-white rounded edit-icon-input"
                                          onClick={() => {
                                            onChangeTicketRaiseData(
                                              "type",
                                              "Company Name"
                                            );
                                            setEditInputFieldModal(true);
                                          }}
                                        >
                                          edit
                                        </span>
                                      </div>
                                    </div>
                                    <div className="google-input">
                                      <input
                                        autoComplete="off"
                                        type="text"
                                        id="fullName"
                                        aria-invalid={Boolean(
                                          basicDetails?.fullName?.status ===
                                            false
                                        )}
                                        placeholder="Enter your full name"
                                        defaultValue={basicDetails?.fullName}
                                        onChange={(e) => {
                                          setValue("fullName", e.target.value);
                                        }}
                                        onBlur={(e) =>
                                          setBasicDetailsValidationValue(
                                            "fullName",
                                            StringValidation(e.target.value)
                                          )
                                        }
                                        onKeyUp={(e) => {
                                          setValue("fullName", e.target.value);
                                        }}
                                      />
                                      <label htmlFor="template-input">
                                        Full name
                                      </label>
                                      <small className="text-danger">
                                        {basicDetailsValidation?.fullName
                                          ?.message
                                          ? `Full name ${basicDetailsValidation?.fullName?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="google-input">
                                      <input
                                        autoComplete="off"
                                        type="text"
                                        id="template-input"
                                        aria-invalid={Boolean(
                                          basicDetails?.displayName?.status ===
                                            false
                                        )}
                                        placeholder="Enter your display name"
                                        defaultValue={basicDetails?.displayName}
                                        onChange={(e) => {
                                          setValue(
                                            "displayName",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={(e) =>
                                          setBasicDetailsValidationValue(
                                            "displayName",
                                            StringValidation(e.target.value)
                                          )
                                        }
                                      />
                                      <label htmlFor="template-input">
                                        Display name
                                      </label>
                                      <small className="text-danger">
                                        {basicDetailsValidation?.displayName
                                          ?.message
                                          ? `Display name ${basicDetailsValidation?.displayName?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 ">
                                    <div className="google-input">
                                      <input
                                        autoComplete="off"
                                        type="text"
                                        id="template-input"
                                        placeholder="Enter your email"
                                        defaultValue={
                                          basicDetails?.officalEmail
                                        }
                                      />
                                      {basicDetails?.emailIdVerified ===
                                      true ? (
                                        <span
                                          className="material-symbols-rounded  text-white rounded edit-icon-input"
                                          onClick={() => {
                                            onChangeTicketRaiseData(
                                              "type",
                                              "Official Email"
                                            );
                                            setEditInputFieldModal(true);
                                          }}
                                        >
                                          edit
                                        </span>
                                      ) : (
                                        <span
                                          className="text-white bg-danger rounded edit-icon-input"
                                          onClick={() => {
                                            setEmailVerifyModal(true);
                                            Resent();
                                          }}
                                        >
                                          Verify
                                        </span>
                                      )}
                                      <label htmlFor="template-input">
                                        Official Email
                                      </label>
                                    </div>
                                    <PhoneInput
                                      inputStyle={{
                                        width: "100%",
                                        height: "45px",
                                        onFocus: "red",
                                      }}
                                      onlyCountries={["us"]}
                                      country={"us"}
                                      value={basicDetails?.phoneNumber?.toString()}
                                      onChange={(e) => {
                                        setValue("phoneNumber", e);
                                      }}
                                      onBlur={(e) => {
                                        setBasicDetailsValidationValue(
                                          "phoneNumber",
                                          PhoneNumberValidation(e.target.value)
                                        );
                                      }}
                                    />
                                    <small className="text-danger">
                                      {basicDetailsValidation?.phoneNumber
                                        ?.message
                                        ? `Phone number ${basicDetailsValidation?.phoneNumber?.message}`
                                        : ""}
                                    </small>
                                    <div className="google-input">
                                      <label
                                        htmlFor="template-input"
                                        className="z-index-value"
                                      >
                                        Gender
                                      </label>
                                      <div className="dropdown-container">
                                        <Select
                                          options={gender}
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: "6px",
                                            zIndex: 3,
                                            colors: {
                                              ...theme.colors,
                                              primary25: "#DFF1F1",
                                              primary: "#00ABAD",
                                            },
                                          })}
                                          placeholder="Select Gender"
                                          value={{
                                            value:
                                              basicDetails?.genderId?.value ||
                                              basicDetails?.genderName ||
                                              basicDetails?.genderId,
                                            label:
                                              basicDetails?.genderId?.label ||
                                              basicDetails?.genderName ||
                                              basicDetails?.genderId,
                                          }}
                                          onChange={(e) => {
                                            setValue("genderId", e);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-12 pb-4 text-center">
                                    <div className="google-input">
                                      <button
                                        type="button"
                                        className="btn btn-light my-2 my-sm-0 login-btn"
                                        onClick={() => {
                                          handleUpdate("UpdateBasicDetails");
                                        }}
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* <ToastContainer /> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="card my-1">
                <div className="card-header" id="headingTwo">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link text-left collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Company Details
                      <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                        expand_more
                      </span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div className="card-body p-0">
                    <div className="scroll-content">
                      <div className="banner-profile-dashboard">
                        <img
                          className="banner-img-profile"
                          src={createdCompanyDetails?.bannerImage}
                          alt=""
                        />
                        <div className="banner-profile-dashboard-text">
                          <h5>Replace Banner Image</h5>
                          <p className="">Optimal dimensions 3200 x 410px </p>
                          <div className="text">
                            <input
                              autoComplete="off"
                              type="file"
                              id="actual-btn1"
                              hidden
                              onChange={(e) => {
                                uploadCeoPic(e,"bannerImage");
                              }}
                            />
                            <label className="login-btn" htmlFor="actual-btn1">
                              Replace Image
                            </label>
                            <span
                              className="ml-4 chck-outline-hover"
                              onClick={handleBannerRemove}
                            >
                              Remove
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div className="row d-flex  justify-content-center scroll-content">
                          <div className="col-lg-2 mt-4 mb-auto d-flex flex-column profilesection1-content">
                            <div className="profile-name2 m-auto d-flex align-items-center justify-content-center">
                              <h1 className="  font-weight-bold">
                                {!createdCompanyDetails?.companyLogo
                                  ? GetInitials(basicDetails?.companyName)
                                  : ""}
                              </h1>
                              <div className="banner-profile-dashboard">
                                <img
                                  className="PP-upload-img"
                                  src={createdCompanyDetails?.companyLogo}
                                  alt=""
                                  hidden={!createdCompanyDetails?.companyLogo}
                                />
                                <div className="banner-profile-dashboard-text">
                                  <div className="text">
                                    <input
                                      autoComplete="off"
                                      type="file"
                                      id="actual-btn"
                                      hidden
                                      onChange={(e) => {
                                        uploadCeoPic(e,"companyLogo");
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              {!ceoPic ? (
                                <span
                                  className="material-symbols-rounded photo-icon-css2 "
                                  onClick={() => CeoRef.current.click()}
                                >
                                  add_a_photo
                                  <input
                                    autoComplete="off"
                                    type="file"
                                    name="myfile"
                                    ref={CeoRef}
                                    hidden
                                    onChange={(e) => {
                                      uploadCeoPic(e,"companyLogo");
                                    }}
                                  />
                                </span>
                              ) : (
                                <span
                                  className="material-symbols-rounded text-danger photo-icon-css2 "
                                  onClick={handleRemoveCompanyPic}
                                >
                                  cancel
                                </span>
                              )}
                            </div>

                            <small className="font-weight-bold text-center d-flex justify-content-center">
                              Company Logo
                            </small>
                          </div>
                          <div className="col-lg-10 col-md-12">
                            <form>
                              <div className="form-container py-1">
                                <div className="row ">
                                  <div className="col-lg-6 col-md-6  border-right">
                                    <div className="google-input">
                                      <input
                                        autoComplete="off"
                                        type="text"
                                        id="ceoName"
                                        aria-invalid={Boolean(
                                          CompanyDetailsValidation?.ceoName
                                            ?.status === false
                                        )}
                                        placeholder="Enter your CEO name"
                                        value={
                                          createdCompanyDetails?.ceoName || ""
                                        }
                                        onChange={(e) => {
                                          setCompanyDetailsValue(
                                            "ceoName",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={(e) => {
                                          setValidationValue(
                                            "ceoName",
                                            StringValidation(e.target.value)
                                          );
                                        }}
                                      />
                                      <label htmlFor="template-input">
                                        CEO Name
                                      </label>
                                    </div>
                                    <small className="text-danger">
                                      {CompanyDetailsValidation?.ceoName
                                        ?.message
                                        ? `Ceo Name ${CompanyDetailsValidation?.ceoName?.message}`
                                        : ""}
                                    </small>
                                    <div className="google-input">
                                      <input
                                        autoComplete="off"
                                        type="text"
                                        id="founded"
                                        maxLength={4}
                                        aria-invalid={Boolean(
                                          CompanyDetailsValidation?.founded
                                            ?.status === false
                                        )}
                                        placeholder="Enter your Founded Year"
                                        value={
                                          createdCompanyDetails?.founded || ""
                                        }
                                        onChange={(e) => {
                                          setCompanyDetailsValue(
                                            "founded",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={(e) => {
                                          setValidationValue(
                                            "founded",
                                            DateValidation(e.target.value)
                                          );
                                        }}
                                      />
                                      <label htmlFor="template-input">
                                        Founded
                                      </label>
                                    </div>
                                    <small className="text-danger">
                                      {CompanyDetailsValidation?.founded
                                        ?.message
                                        ? `Founded year ${CompanyDetailsValidation?.founded?.message}`
                                        : ""}
                                    </small>
                                    <div className="google-input">
                                      <input
                                        autoComplete="off"
                                        type="text"
                                        id="companyUrl"
                                        placeholder="Enter your website link"
                                        aria-invalid={Boolean(
                                          CompanyDetailsValidation?.companyUrl
                                            ?.status === false
                                        )}
                                        value={
                                          createdCompanyDetails?.companyUrl ||
                                          ""
                                        }
                                        onChange={(e) => {
                                          setCompanyDetailsValue(
                                            "companyUrl",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={(e) => {
                                          setValidationValue(
                                            "companyUrl",
                                            URLValidation(e.target.value)
                                          );
                                        }}
                                      />
                                      <label htmlFor="template-input">
                                        Company URL
                                      </label>
                                    </div>
                                    <small className="text-danger">
                                      {CompanyDetailsValidation?.companyUrl
                                        ?.message
                                        ? `Company url ${CompanyDetailsValidation?.companyUrl?.message}`
                                        : ""}
                                    </small>
                                    <div className="google-input">
                                      <label
                                        htmlFor="companyIndustryId"
                                        className="z-index-value"
                                      >
                                        Industry
                                      </label>

                                      <div className="dropdown-container">
                                        <CreatableSelect
                                          options={companyIndustryData}
                                          id="companyIndustryId"
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: "6px",
                                            zIndex: 3,
                                            colors: {
                                              ...theme.colors,
                                              primary25: "#DFF1F1",
                                              neutral20:
                                                CompanyDetailsValidation
                                                  ?.companyIndustryId
                                                  ?.status === false
                                                  ? "red"
                                                  : "#00ABAD", // border color
                                              primary:
                                                CompanyDetailsValidation
                                                  ?.companyIndustryId
                                                  ?.status === false
                                                  ? "red"
                                                  : "#00ABAD", // border color when focus
                                            },
                                          })}
                                          styles={{
                                            menu: (provided, state) => ({
                                              ...provided,
                                              zIndex: "5 !important",
                                            }),
                                          }}
                                          placeholder="Select your company's revenue"
                                          value={{
                                            value:
                                              createdCompanyDetails
                                                ?.companyIndustryId?.value ||
                                              createdCompanyDetails?.companyIndustryId ||
                                              createdCompanyDetails?.companyIndustryId,
                                            label:
                                              createdCompanyDetails
                                                ?.companyIndustryId?.label ||
                                              createdCompanyDetails?.companyIndustryName ||
                                              createdCompanyDetails?.companyIndustryId,
                                          }}
                                          onInputChange={() => {
                                            if (
                                              CompanyDetailsValidation[
                                                "companyIndustryId"
                                              ]
                                            )
                                              delete CompanyDetailsValidation[
                                                "companyIndustryId"
                                              ];
                                          }}
                                          onChange={(newValue) => {
                                            setCompanyDetailsValue(
                                              "companyIndustryId",
                                              newValue
                                            );
                                            // setRevenueDataValue(newValue);
                                          }}
                                          onCreateOption={
                                            handleCreateCompanyIndustry
                                          }
                                          isClearable
                                        />
                                        <small className="text-danger">
                                          {CompanyDetailsValidation
                                            ?.companyIndustryId?.message
                                            ? `Industry ${CompanyDetailsValidation?.companyIndustryId?.message}`
                                            : ""}
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6 ">
                                    <div className="google-input">
                                      <label
                                        htmlFor="revenue"
                                        className="z-index-value"
                                      >
                                        Revenue
                                      </label>

                                      <div className="dropdown-container">
                                        <CreatableSelect
                                          options={revenueData}
                                          id="revenue"
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: "6px",
                                            zIndex: 3,
                                            colors: {
                                              ...theme.colors,
                                              primary25: "#DFF1F1",
                                              neutral20:
                                                CompanyDetailsValidation
                                                  ?.revenueId?.status === false
                                                  ? "red"
                                                  : "#00ABAD", // border color
                                              primary:
                                                CompanyDetailsValidation
                                                  ?.revenueId?.status === false
                                                  ? "red"
                                                  : "#00ABAD", // border color when focus
                                            },
                                          })}
                                          styles={{
                                            menu: (provided, state) => ({
                                              ...provided,
                                              zIndex: "5 !important",
                                            }),
                                          }}
                                          onInputChange={() => {
                                            if (
                                              CompanyDetailsValidation[
                                                "revenueId"
                                              ]
                                            )
                                              delete CompanyDetailsValidation[
                                                "revenueId"
                                              ];
                                          }}
                                          placeholder="Select your company's revenue"
                                          value={{
                                            value:
                                              createdCompanyDetails?.revenueId
                                                ?.value ||
                                              createdCompanyDetails?.revenueId ||
                                              createdCompanyDetails?.revenueId,
                                            label:
                                              createdCompanyDetails?.revenueId
                                                ?.label ||
                                              createdCompanyDetails?.revenueName ||
                                              createdCompanyDetails?.revenueId,
                                          }}
                                          onChange={(newValue) => {
                                            setCompanyDetailsValue(
                                              "revenueId",
                                              newValue
                                            );
                                          }}
                                          onCreateOption={handleCreateRevenue}
                                          isClearable
                                        />
                                        <small className="text-danger">
                                          {CompanyDetailsValidation?.revenueId
                                            ?.message
                                            ? `Revenue ${CompanyDetailsValidation?.revenueId?.message}`
                                            : ""}
                                        </small>
                                      </div>
                                    </div>

                                    <div className="google-input">
                                      <label
                                        htmlFor="companySize"
                                        className="z-index-value"
                                      >
                                        Company Size
                                      </label>
                                      <div className="dropdown-container">
                                        <CreatableSelect
                                          options={companySize}
                                          id="companySize"
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: "6px",
                                            zIndex: 3,
                                            colors: {
                                              ...theme.colors,
                                              primary25: "#DFF1F1",
                                              neutral20:
                                                CompanyDetailsValidation
                                                  ?.companySizeId?.status ===
                                                false
                                                  ? "red"
                                                  : "#00ABAD", // border color
                                              primary:
                                                CompanyDetailsValidation
                                                  ?.companySizeId?.status ===
                                                false
                                                  ? "red"
                                                  : "#00ABAD", // border color when focus
                                            },
                                          })}
                                          styles={{
                                            menu: (provided, state) => ({
                                              ...provided,
                                              zIndex: "5 !important",
                                            }),
                                          }}
                                          onInputChange={() => {
                                            if (
                                              CompanyDetailsValidation[
                                                "companySizeId"
                                              ]
                                            )
                                              delete CompanyDetailsValidation[
                                                "companySizeId"
                                              ];
                                          }}
                                          placeholder="Enter your Company Size"
                                          value={{
                                            value:
                                              createdCompanyDetails
                                                ?.companySizeId?.value ||
                                              createdCompanyDetails?.companySizeId ||
                                              createdCompanyDetails?.companySizeId,
                                            label:
                                              createdCompanyDetails
                                                ?.companySizeId?.label ||
                                              createdCompanyDetails?.companySizeName ||
                                              createdCompanyDetails?.companySizeId,
                                          }}
                                          onChange={(newValue) => {
                                            setCompanyDetailsValue(
                                              "companySizeId",
                                              newValue
                                            );
                                          }}
                                          onCreateOption={
                                            handleCreateCompanySize
                                          }
                                          isClearable
                                        />
                                        <small className="text-danger">
                                          {CompanyDetailsValidation
                                            ?.companySizeId?.message
                                            ? `Company size ${CompanyDetailsValidation?.companySizeId?.message}`
                                            : ""}
                                        </small>
                                      </div>
                                    </div>
                                    <div className="google-input">
                                      <label
                                        htmlFor="headQuarterslocation"
                                        className="z-index-value"
                                      >
                                        Headquarters Location
                                      </label>
                                      <div className="google-input">
                                        <input
                                          autoComplete="off"
                                          type="text"
                                          aria-invalid={Boolean(
                                            CompanyDetailsValidation
                                              ?.headQuarterslocation?.status ===
                                              false
                                          )}
                                          id="headQuarterslocation"
                                          placeholder="Enter your Headquarters Location"
                                          value={
                                            createdCompanyDetails?.headQuarterslocation ||
                                            ""
                                          }
                                          onChange={(e) => {
                                            setCompanyDetailsValue(
                                              "headQuarterslocation",
                                              e.target.value
                                            );
                                          }}
                                          onBlur={(e) => {
                                            setValidationValue(
                                              "headQuarterslocation",
                                              StringValidation(e.target.value)
                                            );
                                          }}
                                        />
                                      </div>
                                      <small className="text-danger">
                                        {CompanyDetailsValidation
                                          ?.headQuarterslocation?.message
                                          ? `Location ${CompanyDetailsValidation?.headQuarterslocation?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                  </div>
                                  <div className="col-lg-12 pb-4 text-center">
                                    <div className="google-input">
                                      <button
                                        type="button"
                                        className="btn btn-light my-2 my-sm-0 login-btn"
                                        onClick={() =>
                                          handleCompanyUpdate(
                                            "UpdateCompanyDetails"
                                          )
                                        }
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* <ToastContainer /> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="card my-1">
                <div className="card-header" id="headingThree">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Gallery
                      <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                        expand_more
                      </span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordion"
                >
                  <div className="card-body p-0">
                    <div>
                      <div className="container">
                        <div className="row justify-content-center d-flex align-items-center ">
                          <div className="col-lg-10">
                            <div className="row">
                              <div className="col-lg-3 pb-4 col-md-4 col-12 text-center">
                                <div className="upload-btn-wrapper mx-3">
                                  <button className="btn upload-gallery">
                                    <h5>
                                      <span className="content">
                                        Upload Images
                                      </span>
                                    </h5>
                                    <h4>
                                      <span className="material-symbols-rounded">
                                        add
                                      </span>
                                    </h4>
                                  </button>
                                  <input
                                    autoComplete="off"
                                    type="file"
                                    name="myfile"
                                    onChange={(e) => uploadCeoPic(e,"gallery")}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-9 scroll-content-design">
                                <div className="gallery-section">
                                  <div className="row">
                                    {createdGallery?.map((e, i) => (
                                      <div
                                        key={i}
                                        className="col-lg-3 px-2 mb-3"
                                      >
                                        <img src={e.imageUrl} alt="" />

                                        <span
                                          className="material-symbols-rounded  text-white rounded del-icon"
                                          onClick={() => {
                                            setGalleryId(e.galleryId);
                                            setDeleteGalleryModal(true);
                                          }}
                                        >
                                          delete
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <ToastContainer /> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="card my-1">
                <div className="card-header" id="headingFour">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Change Password
                      <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                        expand_more
                      </span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordion"
                >
                  <div className="card-body p-0">
                    <div>
                      <div className="container">
                        <div className="row d-flex  justify-content-center scroll-content">
                          <div className="col-lg-10">
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                onSubmitChangePassword(e);
                              }}
                            >
                              <div className="form-container">
                                <div className="row justify-content-center">
                                  <div className="col-lg-6 px-5 pb-4">
                                    <div className="google-input">
                                      <input
                                        autoComplete="off"
                                        type="password"
                                        id="oldPassword"
                                        name="oldPassword"
                                        placeholder="Enter your Current Password"
                                        value={
                                          changePasswordData?.oldPassword || ""
                                        }
                                        onChange={(e) => {
                                          onChangeChangePassword(
                                            "oldPassword",
                                            e.target.value
                                          );
                                        }}
                                      />
                                      <label htmlFor="oldPassword">
                                        Current Password
                                      </label>
                                    </div>
                                    <p className="text-danger">
                                      {
                                        changePasswordValidationData?.oldPassword
                                      }
                                    </p>
                                    <div className="google-input">
                                      <input
                                        autoComplete="off"
                                        type="password"
                                        id="password"
                                        name="password"
                                        placeholder="Enter your New Password"
                                        value={
                                          changePasswordData?.password || ""
                                        }
                                        onChange={(e) => {
                                          onChangeChangePassword(
                                            "password",
                                            e.target.value
                                          );
                                        }}
                                      />
                                      <label htmlFor="password">
                                        New Password
                                      </label>
                                    </div>
                                    <p className="text-danger">
                                      {changePasswordValidationData?.password}
                                    </p>
                                    <div>
                                      {changePasswordData?.password ? (
                                        <div className="mt-3">
                                          <b>
                                            Create a password with the following
                                          </b>
                                          <br />

                                          {
                                            <p
                                              className={`${
                                                changePasswordData?.password.trim()
                                                  .length < 8
                                                  ? "text-danger"
                                                  : "text-success"
                                              } d-flex`}
                                            >
                                              {changePasswordData?.password.trim()
                                                .length < 8 ? (
                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                  close
                                                </span>
                                              ) : (
                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                  check
                                                </span>
                                              )}
                                              At least 8 Characters
                                            </p>
                                          }

                                          {
                                            <p
                                              className={`${
                                                !/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                                  changePasswordData?.password
                                                )
                                                  ? "text-danger"
                                                  : "text-success"
                                              } d-flex`}
                                            >
                                              {!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                                changePasswordData?.password
                                              ) ? (
                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                  close
                                                </span>
                                              ) : (
                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                  check
                                                </span>
                                              )}
                                              An Uppercase &amp; Lowercase
                                              character
                                            </p>
                                          }

                                          {
                                            <p
                                              className={`${
                                                !/^(?=.*[.,:;'!@#$%^&*_+=|(){}[?\-\]/\\]).*$/.test(
                                                  changePasswordData?.password
                                                )
                                                  ? "text-danger"
                                                  : "text-success"
                                              } d-flex`}
                                            >
                                              {!/^(?=.*[.,:;'!@#$%^&*_+=|(){}[?\-\]/\\]).*$/.test(
                                                changePasswordData?.password
                                              ) ? (
                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                  close
                                                </span>
                                              ) : (
                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                  check
                                                </span>
                                              )}
                                              A special character
                                            </p>
                                          }

                                          {
                                            <p
                                              className={`${
                                                !/[0-9]/.test(
                                                  changePasswordData?.password
                                                )
                                                  ? "text-danger"
                                                  : "text-success"
                                              } d-flex`}
                                            >
                                              {!/[0-9]/.test(
                                                changePasswordData?.password
                                              ) ? (
                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                  close
                                                </span>
                                              ) : (
                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                  check
                                                </span>
                                              )}
                                              A number
                                            </p>
                                          }
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="google-input">
                                      <input
                                        autoComplete="off"
                                        type="password"
                                        id="template-input"
                                        placeholder="Re-Enter your New Password"
                                        value={
                                          changePasswordData?.confirmPassword ||
                                          ""
                                        }
                                        onChange={(e) => {
                                          onChangeChangePassword(
                                            "confirmPassword",
                                            e.target.value
                                          );
                                        }}
                                      />
                                      <label htmlFor="template-input">
                                        Re-Enter New Password
                                      </label>
                                    </div>
                                    <p className="text-danger">
                                      {
                                        changePasswordValidationData?.confirmPassword
                                      }
                                    </p>
                                  </div>
                                  <div className="col-lg-12 text-center">
                                    <div className="google-input">
                                      <button className="btn btn-light my-2 my-sm-0 login-btn">
                                        Change Password
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CropEasy
          {...{ photoURL, uploadCompanyUrl,setOpenCrop, setPhotoURL, setFile, fileName, field }}
        />
      )}

      <CustomModal
        open={deleteGalleryModal}
        onClickOutside={() => {
          setDeleteGalleryModal(false);
        }}
      >
        <div className="modal-content w-75  m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px;",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <h3 className="font-weight-bold text-center">
                    Are You Sure?
                  </h3>
                  <p className=" text-center">
                    Do you really want to delete this Image? this process cannot
                    be undone.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center border-0 mb-3">
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                  onClick={() => {
                    setDeleteGalleryModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ padding: "8px 24px" }}
                  className="btn btn-danger my-2 my-sm-0 mr-2 "
                  onClick={() => handleDeleteGallery(galleryId)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={editInputFieldModal}
        onClickOutside={() => {
          setEditInputFieldModal(false);
          setTicketRaiseData({});
          setTicketRaiseDataValidation({});
        }}
      >
        <div className="modal-content w-75  m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px;",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <form className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <h3 className="font-weight-bold text-center">
                    Are You Sure?
                  </h3>
                  <p className=" text-center">
                    Do you really want to Edit this {ticketRaiseData.type}?
                    Raise the service request
                  </p>
                  <div className="google-input">
                    <input
                      autoComplete="off"
                      type="text"
                      id="template-input"
                      placeholder={`Enter current ${ticketRaiseData.type}`}
                      defaultValue={
                        ticketRaiseData.type === "Company Name"
                          ? basicDetails?.companyName
                          : basicDetails?.officalEmail
                      }
                      readOnly
                    />
                    <label htmlFor="template-input">
                      Current {ticketRaiseData.type}
                    </label>
                  </div>
                  <div className="google-input">
                    {ticketRaiseData.type === "Official Email" ? (
                      <>
                        {/* <div className="google-input"> */}
                        <input
                          autoComplete="off"
                          type="text"
                          id="template-input"
                          aria-invalid={Boolean(
                            ticketRaiseDataValidation?.newValue?.status ===
                              false
                          )}
                          placeholder="Enter new official email"
                          // placeholder={`Enter new ${ticketRaiseData.type}`}
                          defaultValue={ticketRaiseData?.newValue}
                          onChange={(e) => {
                            onChangeTicketRaiseData("newValue", e.target.value);
                          }}
                          onBlur={(e) => {
                            ticketRaiseDataValidationValue(
                              "newValue",
                              EmailCompanyValidation(
                                e.target.value,
                                basicDetails?.officalEmail
                              )
                            );
                          }}
                        />
                        {/* <label htmlFor="template-input">
                      New Official Email
                    </label>
                     <small className="text-danger">
                     {
                     ticketRaiseDataValidation?.newValue?.message
                     ? `New Official email ${ticketRaiseDataValidation?.newValue?.message}`
                     : "" }
                   </small>
                   </div> */}
                      </>
                    ) : (
                      <>
                        {/* <div className="google-input"> */}
                        <input
                          autoComplete="off"
                          type="text"
                          id="template-input"
                          placeholder="Enter new company name"
                          aria-invalid={Boolean(
                            ticketRaiseDataValidation?.newValue?.status ===
                              false
                          )}
                          // placeholder={`Enter new ${ticketRaiseData.type}`}
                          defaultValue={ticketRaiseData?.newValue}
                          onChange={(e) => {
                            onChangeTicketRaiseData("newValue", e.target.value);
                          }}
                          onBlur={(e) => {
                            ticketRaiseDataValidationValue(
                              "newValue",
                              CompanyNameChangeValidation(
                                e.target.value,
                                basicDetails?.companyName
                              )
                            );
                          }}
                        />
                        {/* <label htmlFor="template-input">
                      New Company Name
                    </label>
                    <small className="text-danger">
              {
              ticketRaiseDataValidation?.newValue?.message
              ? `New Company name ${ticketRaiseDataValidation?.newValue?.message}`
              : ""}
            </small>
            </div> */}
                      </>
                    )}
                    <label htmlFor="template-input">
                      New {ticketRaiseData.type}
                    </label>
                  </div>
                  {ticketRaiseData.type === "Official Email" ? (
                    <>
                      <small className="text-danger">
                        {ticketRaiseDataValidation?.newValue?.message
                          ? `New Official email ${ticketRaiseDataValidation?.newValue?.message}`
                          : ""}
                      </small>
                    </>
                  ) : (
                    <small className="text-danger">
                      {ticketRaiseDataValidation?.newValue?.message
                        ? `New Company name ${ticketRaiseDataValidation?.newValue?.message}`
                        : ""}
                    </small>
                  )}
                  <div className="google-input">
                    <textarea
                      autoComplete="off"
                      type="text"
                      id="template-input"
                      className="w-100 p-3"
                      aria-invalid={Boolean(
                        ticketRaiseDataValidation?.description?.status === false
                      )}
                      placeholder={`Enter Description`}
                      defaultValue={ticketRaiseData?.description}
                      onChange={(e) => {
                        onChangeTicketRaiseData("description", e.target.value);
                      }}
                      onBlur={(e) => {
                        ticketRaiseDataValidationValue(
                          "description",
                          CompanyNameValidation(e.target.value)
                        );
                      }}
                    />
                    <label htmlFor="template-input">Description</label>
                  </div>
                  {ticketRaiseData.type === "Official Email" ? (
                    <small className="text-danger">
                      {ticketRaiseDataValidation?.description?.message
                        ? `Description ${ticketRaiseDataValidation?.description?.message}`
                        : ""}
                    </small>
                  ) : (
                    <small className="text-danger">
                      {ticketRaiseDataValidation?.description?.message
                        ? `Description ${ticketRaiseDataValidation?.description?.message}`
                        : ""}
                    </small>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-center border-0 mb-3">
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                  onClick={() => {
                    setEditInputFieldModal(false);
                    setTicketRaiseData({});
                    setTicketRaiseDataValidation({});
                  }}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  style={{ padding: "8px 24px" }}
                  className="btn login-btn my-2 my-sm-0 mr-2 "
                  onClick={handleTicketRaise}
                >
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={emailVerifyModal}
        onClickOutside={() => {
          setEmailVerifyModal(false);
          setValidation({});
        }}
      >
        <div className="modal-content w-100  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                onClick={() => {
                  setEmailVerifyModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-6 col-md-6 modal-register-bg m-auto">
                  <div className="section1 ">
                    <img
                      className="mt-3"
                      style={{ width: "100px", height: "100px" }}
                      src={Roboimg}
                      alt=""
                    />
                    <h1>
                      Howdy! I’m Quantum, <br /> your personal recruiter who
                      never sleeps!
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6  m-auto">
                  <div
                    className="section2"
                    style={{ boxShadow: "0px 3px 6px #00000017" }}
                  >
                    <h5 className="text-center pt-0 font-weight-bold mx-4">
                      Verify your email ID
                    </h5>
                    <div className="mx-4 pb-pt-form">
                      <form className="my-4 mx-5" onSubmit={(e) => Submit(e)}>
                        <div className="form-container">
                          <div className="verification-code--inputs">
                            <OtpInput
                              className="otpInput"
                              value={code}
                              onChange={handleChange}
                              numInputs={6}
                              separator={<span style={{ width: "8px" }}></span>}
                              isInputNum={true}
                              shouldAutoFocus={true}
                              inputStyle={{
                                border: "1px solid #CFD3DB",
                                borderRadius: "8px",
                                width: "40px",
                                height: "40px",
                                fontSize: "12px",
                                color: "#000",
                                fontWeight: "400",
                                caretColor: "blue",
                              }}
                              focusStyle={{
                                border: "1px solid black ",
                                outline: "none",
                              }}
                            />
                          </div>
                          <small className="text-danger">
                            {validation?.code?.message
                              ? `OTP ${validation?.code?.message}`
                              : ""}
                          </small>
                          {/* <p className="text-danger">{errors.otp?.message}</p> */}
                          {seconds > 0 || minutes > 0 ? (
                            <div className="top-12">
                              Resend OTP in&nbsp;
                              <span className="text-green">
                                {minutes < 10 ? `0${minutes}` : minutes}:
                                {seconds < 10 ? `0${seconds}` : seconds}
                              </span>
                            </div>
                          ) : (
                            <div className="top-12">
                              Didn't receive code?&nbsp;
                              <span
                                className="fgtpwd"
                                style={{ cursor: "pointer" }}
                                onClick={() => Resent()}
                              >
                                Resend
                              </span>
                            </div>
                          )}

                          <div className="google-input-btm">
                            <button
                              type="submit"
                              className="btn btn-light my-2 my-sm-0 login-btn"
                              // onClick={() => naviGation("/Profile")}
                            >
                              Verify
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
