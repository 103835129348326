import React, { useEffect, useCallback, useState } from "react";
import {
  CreateEducationDetails,
  CreateMasterDataByType,
  DeleteEducationalDetails,
  GetAllEducationDetails,
  GetMasterDatasByType,
  EditEducationDetails,
} from "../../Api/Api";
import CustomModal from "../../CustomModal";
import { useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import {
  CityValidation,
  NonEmptyKeyValidation,
  NonEmptyObjectValidation,
  NonEmptyValidation,
  NonEmptyValidationForDate,
  NonEmptyValidationForEndDate,
  StringValidation,
} from "../../Store/validate";
import moment from "moment";
import Roboimg2 from "../../../images/roboimg-outerborder.png";
const EducationalDetails = (props) => {
 
  const { showToastError, showToastSuccess } = props;
  //state value
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const userProfileId = state?.auth?.auth?.userProfileId;

  const [AddEducationModal, setAddEducationModal] = useState(false);
  const [EditEducationModal, setEditEducationModal] = useState(false);
  const [educationDetailsList, setEducationDetailsList] = useState([]);
  const [educationDetailsData, setEducationDetailsData] = useState({
    currentlyStudying: false,
  });
  const [updateEducationDetailsData, setUpdateEducationDetailsData] = useState({
    currentlyStudying: false,
  });
  const [allFieldOfStudyData, setAllFieldOfStudyData] = useState([]);
  const [allAcademicsData, setAllAcademicsData] = useState([]);
  const [educationalQualification, setEducationalQualification] = useState([]);
  const [educationDetailsValidationData, setEducationDetailsValidationData] =
    useState({});
  const [
    updateEducationDetailsValidationData,
    setUpdateEducationDetailsValidationData,
  ] = useState({});
  const [deleteEducationModal, setDeleteEducationModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [skillDeleteModalStatus, setSkillDeleteModalStatus] = useState(false);
  const [deleteSkillData, setDeleteSkillData] = useState("");
  const onChangeEducationDetailsData = (key, value) => {
    setEducationDetailsData({ ...educationDetailsData, [key]: value });
    if (educationDetailsValidationData[key])
      delete educationDetailsValidationData[key];
  };
  const onChangeEducationDetailsValidationData = (key, value) => {
    setEducationDetailsValidationData({
      ...educationDetailsValidationData,
      [key]: value,
    });
  };
  const onChangeUpdateEducationDetailsData = (key, value) => {
    setUpdateEducationDetailsData({
      ...updateEducationDetailsData,
      [key]: value,
    });
    if (updateEducationDetailsValidationData[key])
      delete updateEducationDetailsValidationData[key];
  };
  const onChangeUpdateEducationDetailsValidationData = (key, value) => {
    setUpdateEducationDetailsValidationData({
      ...updateEducationDetailsValidationData,
      [key]: value,
    });
  };

  const fetchAllEducationDetails = useCallback(async () => {
    if (!authKey) return;

    const Query = `userProfileId=${userProfileId}&authKey=${authKey}`;
    const response = await GetAllEducationDetails(Query);
    if (response && response.statusCode === 200) {
      setEducationDetailsList(response.data);
    } else {
      setEducationDetailsList([]);
    }
  }, [authKey, userProfileId]);

  //get education qualification
  const fetchAllEducationalQualification = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const Query = `authKey=${authKey}&masterDataType=educational_qualification&status=Active&restrictionLevel=0&userId=${userProfileId}`;
    const response = await GetMasterDatasByType(Query);
    if (response && response.statusCode === 200) {
      setEducationalQualification(response.data);
    }
  }, [authKey, userProfileId]);

  const handleCreateEducationalQualification = async (string, type) => {
    const valid = NonEmptyValidation(string);
    onChangeEducationDetailsValidationData("qualificationId", valid);
    
    if (valid.status) {
      const Payload = {
        masterDataType: "educational_qualification",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        restrictionLevel: 0,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await CreateMasterDataByType(Payload, token);
      if (response && response.statusCode === 200) {
        fetchAllEducationalQualification();
        if (type === "create") {
          onChangeEducationDetailsData("qualificationId", response.data);
        } else {
          onChangeUpdateEducationDetailsData(
            "qualification",
            response.data.name
          );
        }
      }
    } else {
      if (type === "create") {
        onChangeEducationDetailsValidationData("qualificationId", valid);
      } else {
        onChangeUpdateEducationDetailsValidationData("qualification", valid);
      }
    }
  };

  const handleCreateSkills = async (e, type) => {
    const valid = NonEmptyObjectValidation(e);
    onChangeEducationDetailsValidationData("skill", valid);
    if (valid.status) {
    const response = await props.handleCreateSkills(e);
    if (response && response.statusCode === 200) {
      onChangeEducationDetailsData("skill", {
        masterDataId: response.data.masterDataId,
        name: response.data.name,
      });
      if (type === "create") {
        if (educationDetailsData?.skill?.length) {
          let data = educationDetailsData?.skill;
          data.push(response.data);
          onChangeEducationDetailsData("skill", data);
        } else {
          onChangeEducationDetailsData("skill", [response.data]);
        }
      } else {
        if (updateEducationDetailsData?.skill?.length) {
          let data = updateEducationDetailsData?.skill;
          data.push(response.data);
          onChangeUpdateEducationDetailsData("skill", data);
        } else {
          onChangeUpdateEducationDetailsData("skill", [response.data]);
        }
      }
    }
  }
  };

  const handleCreateFieldOfStudy = async (string, type) => {
    const valid = StringValidation(string);
    onChangeEducationDetailsValidationData("fieldOfStudyId", valid);
    if (valid.status) {
      const Payload = {
        masterDataType: "field_of_study",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        restrictionLevel: 0,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await CreateMasterDataByType(Payload, token);
      if (response && response.statusCode === 200) {
        fetchAllFieldOfStudy();
        if (type === "create") {
          onChangeEducationDetailsData("fieldOfStudyId", response.data);
        } else {
          onChangeUpdateEducationDetailsData("fieldOfStudy", response.data);
        }
      }
    } else {
      if (type === "create") {
        onChangeEducationDetailsValidationData("fieldOfStudyId", valid);
      } else {
        onChangeUpdateEducationDetailsValidationData("fieldOfStudy", valid);
      }
    }
  };

  const handleCreateCity = async (e, type) => {
    const valid = CityValidation(e);
    onChangeEducationDetailsValidationData("city", valid);
    if (valid.status) {
      const response = await props.handleCreateCity(e);
      if (response && response.statusCode === 200) {
        if (type === "create") {
          onChangeEducationDetailsData("city", {
            masterDataId: response.data.masterDataId,
            name: response.data.name,
          });
        } else {
          onChangeUpdateEducationDetailsData("city", {
            masterDataId: response.data.masterDataId,
            name: response.data.name,
          });
        }
      }
    } else {
      if (type === "create") {
        onChangeEducationDetailsValidationData("city", valid);
      }
    }
  };

  const fetchAllFieldOfStudy = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const Query = `authKey=${authKey}&masterDataType=field_of_study&status=Active&restrictionLevel=0&userId=${userProfileId}`;
    const response = await GetMasterDatasByType(Query);
    if (response && response.statusCode === 200) {
      setAllFieldOfStudyData(response.data);
    }
    return response;
  }, [authKey, userProfileId]);

  const fetchAllAcademics = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const Query = `authKey=${authKey}&masterDataType=academy&status=Active&restrictionLevel=0&userId=${userProfileId}`;
    const response = await GetMasterDatasByType(Query);
    if (response && response.statusCode === 200) {
      setAllAcademicsData(response.data);
    }
    return response;
  }, [authKey, userProfileId]);

  useEffect(() => {
    fetchAllEducationDetails();
    fetchAllEducationalQualification();
    fetchAllFieldOfStudy();
    fetchAllAcademics();
    setEducationDetailsValidationData({});
  }, [fetchAllAcademics, fetchAllEducationDetails, fetchAllEducationalQualification, fetchAllFieldOfStudy]);

  const handleCreateAcademics = async (string, type) => {
    const valid = StringValidation(string);
    onChangeEducationDetailsValidationData("collegeOrUniversity", valid);
    if (valid.status) {
      const Payload = {
        masterDataType: "academy",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        restrictionLevel: 0,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await CreateMasterDataByType(Payload, token);
      if (response && response.statusCode === 200) {
        fetchAllAcademics();
        if (type === "create") {
          onChangeEducationDetailsData("collegeOrUniversity", response.data);
        } else {
          onChangeUpdateEducationDetailsData(
            "collegeOrUniversity",
            response.data
          );
        }
      }
    } else {
      if (type === "create") {
        onChangeEducationDetailsValidationData("collegeOrUniversity", valid);
      } else {
        onChangeUpdateEducationDetailsValidationData(
          "collegeOrUniversity",
          valid
        );
      }
    }
  };

  const handleCreateEducationDetails = async () => {
    let validate = {};
    validate.qualificationId = NonEmptyObjectValidation(educationDetailsData.qualificationId);
    validate.collegeOrUniversity = NonEmptyObjectValidation(educationDetailsData.collegeOrUniversity);
    validate.fieldOfStudyId = NonEmptyObjectValidation(educationDetailsData.fieldOfStudyId);
    validate.city = NonEmptyObjectValidation(educationDetailsData.city);
    validate.educationType = NonEmptyKeyValidation(educationDetailsData.educationType);
    validate.workingSince = NonEmptyValidationForDate(educationDetailsData.workingSince);
    if (
      educationDetailsData.currentlyStudying ===
      false
    ) {
      validate.workingTo = NonEmptyValidationForEndDate(educationDetailsData.workingTo);
    }
    validate.description = NonEmptyValidation(educationDetailsData.description);
    validate.skill = NonEmptyObjectValidation(educationDetailsData.skill);
    setEducationDetailsValidationData(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
    
   const AuthKey = state?.auth?.auth?.authKey;
      const Payload = {
        userProfileId: state?.auth?.auth?.userProfileId,
        qualificationId: educationDetailsData.qualificationId.masterDataId,
        fieldOfStudyId: educationDetailsData.fieldOfStudyId.masterDataId,
        collegeOrUniversityId:
          educationDetailsData.collegeOrUniversity.masterDataId,
        cityId:
          educationDetailsData.city.masterDataId ||
          educationDetailsData.city.dataId,
        educationType: educationDetailsData.educationType,
        currentlyStudying: educationDetailsData.currentlyStudying,
        workingSince: educationDetailsData.workingSince,
        workingTo: educationDetailsData.currentlyStudying
          ? undefined
          : educationDetailsData.workingTo,
        skill: educationDetailsData.skill.map(function (item) {
          return item.masterDataId;
        }),
        description: educationDetailsData.description,
      };
      const response = await CreateEducationDetails(AuthKey, Payload);
      if (response && response.statusCode === 200) {
        setAddEducationModal(false);
        setEducationDetailsData({ currentlyStudying: false });
        showToastSuccess(response.message);
        fetchAllEducationDetails();
      } else {
        showToastError(
          response.message || response.error.response.data.message
        );
      }
    }
  };
  const handleUpdateEducationDetails = async () => {
    let validate = {};
    validate.qualificationId = NonEmptyObjectValidation(updateEducationDetailsData.qualification);
    validate.collegeOrUniversity = NonEmptyObjectValidation(updateEducationDetailsData.collegeOrUniversity);
    validate.fieldOfStudyId = NonEmptyObjectValidation(updateEducationDetailsData.fieldOfStudy);
    validate.city = NonEmptyObjectValidation(updateEducationDetailsData.city);
    validate.educationType = NonEmptyKeyValidation(updateEducationDetailsData.educationType);
    validate.workingSince = NonEmptyValidationForDate(updateEducationDetailsData.workingSince);
    if (
      updateEducationDetailsData.currentlyStudying ===
      false
    ) {
      validate.workingTo = NonEmptyValidationForEndDate(updateEducationDetailsData.workingTo);
    }
    validate.description = NonEmptyValidation(updateEducationDetailsData.description);
    validate.skill = NonEmptyObjectValidation(updateEducationDetailsData.skill);
    setUpdateEducationDetailsValidationData(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
   const AuthKey = state?.auth?.auth?.authKey;
      const Payload = {
        qualificationId:
          updateEducationDetailsData.qualification.masterDataId,
        fieldOfStudyId: updateEducationDetailsData.fieldOfStudy.masterDataId,
        collegeOrUniversityId:
          updateEducationDetailsData.collegeOrUniversity.masterDataId,
        cityId: updateEducationDetailsData.city.masterDataId,
        educationType: updateEducationDetailsData.educationType,
        currentlyStudying: updateEducationDetailsData.currentlyStudying,
        workingSince: updateEducationDetailsData.workingSince,
        workingTo: updateEducationDetailsData.currentlyStudying
          ? undefined
          : updateEducationDetailsData.workingTo,
        skill: updateEducationDetailsData.skill.map(function (item) {
          return item.masterDataId;
        }),
        description: updateEducationDetailsData.description,
        userEducationDetailId: updateEducationDetailsData.userEducationDetailId,
      };
      const response = await EditEducationDetails(AuthKey, Payload);
      if (response && response.statusCode === 200) {
        setEditEducationModal(false);
        setUpdateEducationDetailsData({ currentlyStudying: false });
        showToastSuccess(response.message);
        fetchAllEducationDetails();
      } else {
        showToastError(
          response.message || response.error.response.data.message
        );
      }
    }
  };
  const handleDeleteEducationalDetails = async (key) => {
    const Payload = { userEducationDetailId: key };
    const AuthKey = state?.auth?.auth?.authKey;
    const response = await DeleteEducationalDetails(AuthKey, Payload);
    if (response && response.statusCode === 200) {
      setDeleteEducationModal(false);
      showToastSuccess(response.message);
      fetchAllEducationDetails();
    } else {
      showToastError(response.message || response.error.response.data.message);
    }
  };
  const handleDeleteEmploymentDetailsSkill = async (data) => {
    let Payload = {
      skill: data?.skill?.map((e) => e.masterDataId.toString()),
      userEducationDetailId: data?.userEducationDetailId,
    };
    const token = state?.auth?.auth?.authKey;
    const response = await EditEducationDetails(token, Payload);
    if (response && response.statusCode === 200) {
      fetchAllEducationDetails();
      setSkillDeleteModalStatus(false);
      showToastSuccess(response.message);
    } else {
      showToastError(response.message || response.error.response.data.message);
    }
  };
  const handleDeleteSkill = async () => {
    try {
      let data = deleteSkillData;
      data.data.skill = data.data.skill.filter(
        (item) => item?.masterDataId !== data.masterDataId
      );
      handleDeleteEmploymentDetailsSkill(data.data);
    } catch (e) {
      console.log(e, "Error while deleting the skill");
    }
  };

  return (
    <div>
      <div className="container py-4">
        <div className="row justify-content-center d-flex align-items-center ">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-2 col-md-3 col-4 text-center my-2">
                <div className="upload-btn-wrapper">
                  <button
                    className="btn upload-gallery"
                    onClick={() => {
                      setAddEducationModal(true);
                    }}
                  >
                    <h1>
                      <span className="material-symbols-rounded">add</span>
                    </h1>
                    <span className="content">Add Education</span>
                  </button>
                </div>
              </div>
              <div className="col-lg-10 scroll-content-design">
                <div className="edudetails-section">
                  <div className="row  ">
                    {educationDetailsList.map((data, index) => {
                      return (
                        <div
                          className="col-lg-6 col-md-6 px-1 my-2"
                          key={index}
                        >
                          <div className="edudetails-card">
                            <div className="d-flex justify-content-between">
                              <h6 className="font-weight-bold" style={{fontSize:"18.5px"}}>
                                {data?.fieldOfStudy?.name}
                              </h6>
                              <div className="icon">
                                <span
                                  className="material-icons icon-middle edit  m-1"
                                  onClick={() => {
                                    setUpdateEducationDetailsData(data);
                                    setEditEducationModal(true);
                                  }}
                                >
                                  edit
                                </span>
                                <span
                                  onClick={() => {
                                    setDeleteId(data.userEducationDetailId);
                                    setDeleteEducationModal(true);
                                  }}
                                  className="material-symbols-rounded del icon-middle rounded m-1"
                                >
                                  delete
                                </span>
                              </div>
                            </div>
                            <p className="cut-text   font-weight-bold mb-1">
                             <i>{data?.collegeOrUniversity?.name}</i>
                            </p>
                            <p className=" font-weight-bold">
                              {moment(data?.workingSince).format("MMMM YYYY")}
                              &nbsp;to&nbsp;
                              {data.currentlyStudying === false
                                ? moment(data.workingTo).format("MMMM YYYY")
                                : "Till now"}
                            </p>
                            <p className="mb-0">{data.description}</p>
                            <div className="my-2">
                              {data?.skill?.map((skilldata, ind) => {
                                return (
                                  <small
                                    className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1 "
                                    key={ind}
                                  >
                                    {skilldata.name}
                                    <span
                                      className="material-symbols-rounded pl-2 icon-middle"
                                      onClick={() => {
                                        setDeleteSkillData({
                                          masterDataId: skilldata.masterDataId,
                                          data,
                                        });
                                        setSkillDeleteModalStatus(true);
                                      }}
                                    >
                                      cancel
                                    </span>
                                  </small>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card my-1  d-lg-none d-block">
        <div className="card-header" id="headingThree">
          <h5 className="mb-0">
            <button
              className="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Education Details
              <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                expand_more
              </span>
            </button>
          </h5>
        </div>
        <div
          id="collapseThree"
          className="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordion"
        >
          <div className="card-body p-0">
            <div>
              <div className="container">
                <div className="row justify-content-center d-flex align-items-center ">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-2 col-md-4 col-12 text-center my-2">
                        <div className="upload-btn-wrapper">
                          <button
                            className="btn upload-gallery"
                            onClick={() => {
                              setAddEducationModal(true);
                            }}
                          >
                            <h5>
                              <span className="content">Add Education</span>
                            </h5>
                            <h4>
                              <span className="material-symbols-rounded">
                                add
                              </span>
                            </h4>
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-10 scroll-content-design">
                        <div className="edudetails-section">
                          <div className="row  ">
                            {educationDetailsList.map((data, index) => {
                              return (
                                <div
                                  className="col-lg-6 col-md-6 px-1 my-2"
                                  key={index}
                                >
                                  <div className="edudetails-card">
                                    <div className="d-flex justify-content-between">
                                      <h6 className="font-weight-bold" style={{fontSize:"18.5px"}}>
                                        {data?.fieldOfStudyId?.name}
                                      </h6>
                                      <div className="icon">
                                        <span
                                          className="material-icons icon-middle edit  m-1"
                                          onClick={() => {
                                            setEditEducationModal(true);
                                          }}
                                        >
                                          edit
                                        </span>
                                        <span
                                          onClick={() => {
                                            setDeleteId(
                                              data.userEducationDetailId
                                            );
                                            setDeleteEducationModal(true);
                                          }}
                                          className="material-symbols-rounded del icon-middle rounded m-1"
                                        >
                                          delete
                                        </span>
                                      </div>
                                    </div>
                                    <p className="cut-text   font-weight-bold mb-1">
                                      <i>{data?.collegeOrUniversity?.name}</i>
                                    </p>
                                    <p className=" font-weight-bold">
                                      {moment(data?.workingSince).format(
                                        "MMMM YYYY"
                                      )}
                                      &nbsp;to&nbsp;
                                      {data.currentlyStudying === false
                                        ? moment(data.workingTo).format(
                                            "MMMM YYYY"
                                          )
                                        : "Till now"}
                                    </p>
                                    <p className="mb-0">{data.description}</p>
                                    <div className="my-2">
                                      {data?.skills?.map((skill, ind) => {
                                        return (
                                          <small
                                            className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1 "
                                            key={ind}
                                          >
                                            {skill.name}
                                            <span className="material-symbols-rounded pl-2 icon-middle">
                                              cancel
                                            </span>
                                          </small>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={AddEducationModal}
        onClickOutside={() => setAddEducationModal(false)}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader py-3">
              <h5 className="text-center font-weight-bold my-0">
                Add Education
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-11">
                  <form>
                    <div className="form-container pt-4 py-1">
                      <div className="row ">
                        <div className="col-lg-6 px-4 pb-4 ">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Highest Qualification
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={educationalQualification}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      educationDetailsValidationData
                                        ?.qualificationId?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      educationDetailsValidationData
                                        ?.qualificationId?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                               
                                getOptionLabel={(option) =>
                                  option.name || option.label
                                }
                                getOptionValue={(option) => option.masterDataId}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose your highest qualification"
                                value={educationDetailsData.qualificationId}
                                onCreateOption={(e) =>
                                  handleCreateEducationalQualification(
                                    e,
                                    "create"
                                  )
                                }
                                onInputChange={()=>{
                                  if (educationDetailsValidationData["qualificationId"]) delete educationDetailsValidationData["qualificationId"]
                                }
                              }  
                                onChange={(value) =>
                                  onChangeEducationDetailsData(
                                    "qualificationId",
                                    value
                                  )
                                }
                              />
                            </div>
                            {educationDetailsValidationData?.qualificationId
                              ?.status === false ? (
                              <small className="text-danger">
                                Highest Qualification&nbsp;
                                {
                                  educationDetailsValidationData
                                    ?.qualificationId?.message
                                }
                              </small>
                            ) : null}
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              College or University
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={allAcademicsData}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      educationDetailsValidationData
                                        ?.collegeOrUniversity?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      educationDetailsValidationData
                                        ?.collegeOrUniversity?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                getOptionLabel={(option) =>
                                  option.name || option.label
                                }
                                getOptionValue={(option) => option.masterDataId}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                onInputChange={()=>{
                                  if (educationDetailsValidationData["collegeOrUniversity"]) delete educationDetailsValidationData["collegeOrUniversity"]
                                }
                              } 
                                onCreateOption={(e) =>
                                  handleCreateAcademics(e, "create")
                                }
                                placeholder="Select your College or University"
                                value={educationDetailsData.collegeOrUniversity}
                                onChange={(value) =>
                                  onChangeEducationDetailsData(
                                    "collegeOrUniversity",
                                    value
                                  )
                                }
                              />
                            </div>
                            {educationDetailsValidationData?.collegeOrUniversity
                              ?.status === false ? (
                              <small className="text-danger">
                                Academy&nbsp;
                                {
                                  educationDetailsValidationData
                                    ?.collegeOrUniversity?.message
                                }
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 px-4 pb-4">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Field of Study&nbsp;
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={allFieldOfStudyData}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      educationDetailsValidationData
                                        ?.fieldOfStudyId?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      educationDetailsValidationData
                                        ?.fieldOfStudyId?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                onInputChange={()=>{
                                  if (educationDetailsValidationData["fieldOfStudyId"]) delete educationDetailsValidationData["fieldOfStudyId"]
                                }
                              } 
                                getOptionLabel={(option) =>
                                  option.name || option.label
                                }
                                getOptionValue={(option) => option.masterDataId}
                                placeholder="Choose your field of study"
                                value={educationDetailsData.fieldOfStudyId}
                                onChange={(newValue) =>
                                  onChangeEducationDetailsData(
                                    "fieldOfStudyId",
                                    newValue
                                  )
                                }
                                onCreateOption={(e) =>
                                  handleCreateFieldOfStudy(e, "create")
                                }
                              />
                            </div>
                            {educationDetailsValidationData?.fieldOfStudyId
                              ?.status === false ? (
                              <small className="text-danger">
                                Field of Study&nbsp;
                                {
                                  educationDetailsValidationData?.fieldOfStudyId
                                    ?.message
                                }
                              </small>
                            ) : null}
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              City
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={props.cities}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      educationDetailsValidationData?.city
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      educationDetailsValidationData?.city
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                onInputChange={()=>{
                                  if (educationDetailsValidationData["city"]) delete educationDetailsValidationData["city"]
                                }
                              } 
                                placeholder="Enter The City"
                                isClearable
                                getOptionLabel={(option) =>
                                  option.name || option.label
                                }
                                getOptionValue={(option) => option.masterDataId}
                                value={educationDetailsData.city}
                                onChange={(newValue) =>
                                  onChangeEducationDetailsData("city", newValue)
                                }
                                onCreateOption={(e) =>
                                  handleCreateCity(e, "create")
                                }
                              />
                            </div>
                            {educationDetailsValidationData?.city?.status ===
                            false ? (
                              <small className="text-danger">
                                City&nbsp;
                                {educationDetailsValidationData?.city?.message}
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12 px-4 pb-4">
                          <div className="modalAnswer">
                            <label
                              htmlFor="template-input"
                              className="z-index-value employeeNavbar-labels"
                            >
                              Education Type
                            </label>
                            <div
                              className="btn-group-toggle"
                              data-toggle="buttons"
                            >
                              <label
                                className={`btn addLanguage-skills mr-2 ${
                                  educationDetailsData.educationType ===
                                  "Full Time"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  onChangeEducationDetailsData(
                                    "educationType",
                                    "Full Time"
                                  )
                                }
                              >
                                <input
                                  autoComplete="off"
                                  id="Part_Time"
                                  type="radio"
                                  value={
                                    educationDetailsData.educationType ===
                                    "Part Time"
                                  }
                                />
                                Full Time
                              </label>
                              <label
                                className={`btn addLanguage-skills mr-2 ${
                                  educationDetailsData.educationType ===
                                  "Part Time"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  onChangeEducationDetailsData(
                                    "educationType",
                                    "Part Time"
                                  )
                                }
                              >
                                <input
                                  autoComplete="off"
                                  id="Full_Time"
                                  type="radio"
                                  value={
                                    educationDetailsData.educationType ===
                                    "Full Time"
                                  }
                                />
                                Part Time
                              </label>
                            </div>
                            {educationDetailsValidationData?.educationType
                              ?.status === false ? (
                              <small className="text-danger">
                                Education Type&nbsp;
                                {
                                  educationDetailsValidationData?.educationType
                                    ?.message
                                }
                              </small>
                            ) : null}
                          </div>
                          <div className="form-check mt-4">
                            <input
                              onChange={() => {
                                if (educationDetailsData.currentlyStudying)
                                  onChangeEducationDetailsData(
                                    "currentlyStudying",
                                    false
                                  );
                                else
                                  onChangeEducationDetailsData(
                                    "currentlyStudying",
                                    true
                                  );
                              }}
                              value={educationDetailsData.currentlyStudying}
                              checked={
                                educationDetailsData.currentlyStudying === true
                              }
                              autoComplete="off"
                              className="form-check-input"
                              type="checkbox"
                              id="gridCheck1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridCheck1"
                            >
                              I currently go here
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 px-4 pb-4 ">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Start Date
                            </label>
                            <input
                              autoComplete="off"
                              type="date"
                              id="startdate"
                              name="startdate"
                              max={moment().format("YYYY-MM-DD")}
                              aria-invalid={
                                educationDetailsValidationData?.workingSince
                                  ?.status === false
                              }
                              onBlur={(e) => {
                                onChangeEducationDetailsValidationData(
                                  "workingSince",
                                  NonEmptyObjectValidation(
                                    e.target.value
                                  )
                                );
                              }}
                              onChange={(e) => {
                                onChangeEducationDetailsData(
                                  "workingSince",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          {educationDetailsValidationData?.workingSince
                            ?.status === false ? (
                            <small className="text-danger">
                              Start Date
                              {
                                educationDetailsValidationData?.workingSince
                                  ?.message
                              }
                            </small>
                          ) : null}

                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Skill*
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={props.skills}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      educationDetailsValidationData?.skill
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      educationDetailsValidationData?.skill
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose your skill"
                                isClearable
                                isMulti
                                
                                value={educationDetailsData.skill}
                                getOptionLabel={(option) =>
                                  option.label || option.name
                                }
                                getOptionValue={(option) =>
                                  option.dataId || option.masterDataId
                                }
                                onChange={(newValue) =>
                                  onChangeEducationDetailsData(
                                    "skill",
                                    newValue
                                  )
                                }
                                onInputChange={()=>{
                                  if (educationDetailsValidationData["skill"]) delete educationDetailsValidationData["skill"]
                                }
                              } 
                                onCreateOption={(e) =>
                                  handleCreateSkills(e, "create")
                                }
                              />
                            </div>
                            {educationDetailsValidationData?.skill?.status ===
                            false ? (
                              <small className="text-danger">
                                Skills&nbsp;
                                {educationDetailsValidationData?.skill?.message}
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 px-4 pb-4">
                          {educationDetailsData.currentlyStudying === false ? (
                            <div className="google-input">
                              <label
                                htmlFor="template-input"
                                className="z-index-value"
                              >
                                End Date
                              </label>
                              <input
                                autoComplete="off"
                                type="date"
                                id="enddate"
                                name="enddate"
                                onBlur={(e) => {
                                  if (
                                    educationDetailsData.currentlyStudying ===
                                    false
                                  ) {
                                    onChangeEducationDetailsValidationData(
                                      "workingTo",
                                      NonEmptyValidationForEndDate(
                                        e.target.value
                                      )
                                    );
                                  }
                                }}
                                aria-invalid={
                                  educationDetailsValidationData?.workingTo
                                    ?.status === false
                                }
                                onChange={(e) => {
                                  if (
                                    educationDetailsData.currentlyStudying ===
                                    false
                                  ) {
                                    onChangeEducationDetailsData(
                                      "workingTo",
                                      e.target.value
                                    );
                                  }
                                }}
                                readOnly={
                                  educationDetailsData.currentlyStudying
                                }
                              />
                              {educationDetailsValidationData?.workingTo
                                ?.status === false ? (
                                <small className="text-danger">
                                  End Date
                                  {
                                    educationDetailsValidationData?.workingTo
                                      ?.message
                                  }
                                </small>
                              ) : null}
                            </div>
                          ) : (
                            <div className="google-input">
                              <p className="text-center text-light w-100 bg-secondary py-2 mb-0 rounded">
                                Currently Pursuing
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modalAnswer col-lg-11 mt-2 mb-4 pt-2 px-4 ">
                  <label htmlFor="template-input" className="z-index-value">
                    Description
                  </label>
                  <div className="modalDisapprove-details d-flex  justify-content-end ">
                    <button
                      type="button"
                      className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                    >
                      Rich Text Editor
                      <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                        lock
                      </span>
                    </button>
                  </div>

                  <div className="form-group">
                    <textarea
                      onBlur={(e) => {
                        onChangeEducationDetailsValidationData(
                          "description",
                          NonEmptyValidation(
                            e.target.value
                          )
                        );
                      }}
                      onChange={(e) => {
                        onChangeEducationDetailsData(
                          "description",
                          e.target.value
                        );
                      }}
                      aria-invalid={
                        educationDetailsValidationData?.description?.status ===
                        false
                      }
                      className="form-control modalDisapprove-textarea"
                      id="exampleFormControlTextarea2"
                      rows="7"
                    ></textarea>
                  </div>
                  {educationDetailsValidationData?.description?.status ===
                  false ? (
                    <small className="text-danger">
                      Description&nbsp;
                      {educationDetailsValidationData?.description?.message}
                    </small>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setAddEducationModal(false);
                    setEducationDetailsValidationData({});
                  }}
                >
                  Back
                </button>
              </div>
              <div>
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                  onClick={() => {
                    handleCreateEducationDetails();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={EditEducationModal}
        onClickOutside={() => {
          setUpdateEducationDetailsValidationData({});
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader py-3">
              <h5 className="text-center font-weight-bold my-0">
                Edit Education
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-11">
                  <form>
                    <div className="form-container pt-4 py-1">
                      <div className="row ">
                        <div className="col-lg-6 px-4 pb-4 ">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Highest Qualification
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={educationalQualification}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                    updateEducationDetailsValidationData
                                        ?.qualification?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                    updateEducationDetailsValidationData
                                        ?.qualification?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                getOptionLabel={(option) =>
                                  option.name || option.label
                                }
                                getOptionValue={(option) => option.masterDataId}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose your highest qualification"
                                value={updateEducationDetailsData.qualification}
                                onCreateOption={(e) =>
                                  handleCreateEducationalQualification(
                                    e,
                                    "update"
                                  )
                                }
                                onInputChange={()=>{
                                  if (updateEducationDetailsValidationData["qualification"]) delete updateEducationDetailsValidationData["qualification"]
                                }
                              } 
                                onChange={(value) =>
                                  onChangeUpdateEducationDetailsData(
                                    "qualification",
                                    value
                                  )
                                }
                              />
                            </div>
                            {updateEducationDetailsValidationData?.qualification
                              ?.status === false ? (
                              <small className="text-danger">
                                Highest Qualification
                                {
                                  updateEducationDetailsValidationData
                                    ?.qualification?.message
                                }
                              </small>
                            ) : null}
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              College or University
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={allAcademicsData}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                    updateEducationDetailsValidationData
                                        ?.collegeOrUniversity?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                    updateEducationDetailsValidationData
                                        ?.collegeOrUniversity?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  
                                  },
                                })}
                                getOptionLabel={(option) =>
                                  option.name || option.label
                                }
                                getOptionValue={(option) => option.masterDataId}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                onInputChange={()=>{
                                  if (updateEducationDetailsValidationData["collegeOrUniversity"]) delete updateEducationDetailsValidationData["collegeOrUniversity"]
                                }
                              } 
                                onCreateOption={(e) =>
                                  handleCreateAcademics(e, "create")
                                }
                                placeholder="Select your College or University"
                                value={
                                  updateEducationDetailsData.collegeOrUniversity
                                }
                                onChange={(value) =>
                                  onChangeUpdateEducationDetailsData(
                                    "collegeOrUniversity",
                                    value
                                  )
                                }
                              />
                            </div>
                            {updateEducationDetailsValidationData
                              ?.collegeOrUniversity?.status === false ? (
                              <small className="text-danger">
                                Academy
                                {
                                  updateEducationDetailsValidationData
                                    ?.collegeOrUniversity?.message
                                }
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 px-4 pb-4">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Field of Study
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={allFieldOfStudyData}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                    updateEducationDetailsValidationData
                                        ?.fieldOfStudy?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                    updateEducationDetailsValidationData
                                        ?.fieldOfStudy?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                
                                getOptionLabel={(option) =>
                                  option.name || option.label
                                }
                                getOptionValue={(option) => option.masterDataId}
                                placeholder="Choose your field of study"
                                value={updateEducationDetailsData.fieldOfStudy}
                                onChange={(newValue) =>
                                  onChangeUpdateEducationDetailsData(
                                    "fieldOfStudy",
                                    newValue
                                  )
                                }
                                onCreateOption={(e) =>
                                  handleCreateFieldOfStudy(e, "update")
                                }
                                onInputChange={()=>{
                                  if (updateEducationDetailsValidationData["fieldOfStudy"]) delete updateEducationDetailsValidationData["fieldOfStudy"]
                                }
                              } 
                              />
                            </div>
                            {updateEducationDetailsValidationData?.fieldOfStudy
                              ?.status === false ? (
                              <small className="text-danger">
                                Field of Study
                                {
                                  updateEducationDetailsValidationData
                                    ?.fieldOfStudy?.message
                                }
                              </small>
                            ) : null}
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              City
                            </label>

                            <div className="dropdown-container">
                              <CreatableSelect
                                options={props.cities}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                    updateEducationDetailsValidationData
                                        ?.city?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                    updateEducationDetailsValidationData
                                        ?.city?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  
                                  
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                getOptionLabel={(option) =>
                                  option.name || option.label
                                }
                                getOptionValue={(option) =>
                                  option.masterDataId || option.dataId
                                }
                                onInputChange={()=>{
                                  if (updateEducationDetailsValidationData["city"]) delete updateEducationDetailsValidationData["city"]
                                }
                              } 
                                placeholder="Las Vegas, United States."
                                value={updateEducationDetailsData.city}
                                onChange={(newValue) =>
                                  onChangeUpdateEducationDetailsData(
                                    "city",
                                    newValue
                                  )
                                }
                                onCreateOption={(e) =>
                                  handleCreateCity(e, "update")
                                }
                              />
                            </div>
                            {updateEducationDetailsValidationData?.city
                              ?.status === false ? (
                              <small className="text-danger">
                                City
                                {
                                  updateEducationDetailsValidationData?.city
                                    ?.message
                                }
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12 px-4 pb-4">
                          <div className="modalAnswer">
                            <label
                              htmlFor="template-input"
                              className="z-index-value employeeNavbar-labels"
                            >
                              Education Type
                            </label>
                            <div
                              className="btn-group-toggle"
                              data-toggle="buttons"
                            >
                              <label
                                className={`btn addLanguage-skills mr-2 ${
                                  updateEducationDetailsData.educationType ===
                                  "Full Time"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  onChangeUpdateEducationDetailsData(
                                    "educationType",
                                    "Full Time"
                                  )
                                }
                              >
                                <input
                                  autoComplete="off"
                                  id="Part_Time"
                                  type="radio"
                                  value={
                                    updateEducationDetailsData.educationType ===
                                    "Part Time"
                                  }
                                />
                                Full Time
                              </label>
                              <label
                                className={`btn addLanguage-skills mr-2 ${
                                  updateEducationDetailsData.educationType ===
                                  "Part Time"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  onChangeUpdateEducationDetailsData(
                                    "educationType",
                                    "Part Time"
                                  )
                                }
                              >
                                <input
                                  autoComplete="off"
                                  id="Full_Time"
                                  type="radio"
                                  value={
                                    updateEducationDetailsData.educationType ===
                                    "Full Time"
                                  }
                                />
                                Part Time
                              </label>
                            </div>
                            {updateEducationDetailsValidationData?.educationType
                              ?.status === false ? (
                              <small className="text-danger">
                                Education Type
                                {
                                  updateEducationDetailsValidationData
                                    ?.educationType?.message
                                }
                              </small>
                            ) : null}
                          </div>
                          <div className="form-check mt-4">
                            <input
                              onChange={() => {
                                if (
                                  updateEducationDetailsData.currentlyStudying
                                )
                                  onChangeUpdateEducationDetailsData(
                                    "currentlyStudying",
                                    false
                                  );
                                else
                                  onChangeUpdateEducationDetailsData(
                                    "currentlyStudying",
                                    true
                                  );
                              }}
                              value={
                                updateEducationDetailsData.currentlyStudying
                              }
                              autoComplete="off"
                              checked={
                                updateEducationDetailsData?.currentlyStudying ===
                                true
                              }
                              className="form-check-input"
                              type="checkbox"
                              id="gridCheck1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="gridCheck1"
                            >
                              I currently go here
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 px-4 pb-4 ">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Start Date
                            </label>
                            <input
                              autoComplete="off"
                              type="date"
                              id="startdate"
                              name="startdate"
                              onBlur={(e) => {
                                onChangeUpdateEducationDetailsValidationData(
                                  "workingSince",
                                  NonEmptyValidationForDate(
                                    e.target.value
                                  )
                                );
                              }}
                              max={moment().format("YYYY-MM-DD")}
                              aria-invalid={
                                updateEducationDetailsValidationData
                                  ?.workingSince?.status === false
                              }
                              value={moment(
                                updateEducationDetailsData?.workingSince
                              ).format("YYYY-MM-DD")}
                              onChange={(e) => {
                                onChangeUpdateEducationDetailsData(
                                  "workingSince",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                          {updateEducationDetailsValidationData?.workingSince
                            ?.status === false ? (
                            <small className="text-danger">
                              Start Date
                              {
                                updateEducationDetailsValidationData
                                  ?.workingSince?.message
                              }
                            </small>
                          ) : null}

                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Skill*
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={props.skills}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                    updateEducationDetailsValidationData
                                        ?.skill?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                    updateEducationDetailsValidationData
                                        ?.skill?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose your skill"
                                isClearable
                                isMulti
                                onInputChange={()=>{
                                  if (updateEducationDetailsValidationData["skill"]) delete updateEducationDetailsValidationData["skill"]
                                }
                              } 
                                getOptionLabel={(option) =>
                                  option.label || option.name
                                }
                                getOptionValue={(option) =>
                                  option.dataId || option.masterDataId
                                }
                                value={updateEducationDetailsData.skill}
                                onChange={(newValue) =>
                                  onChangeUpdateEducationDetailsData(
                                    "skill",
                                    newValue
                                  )
                                }
                                onCreateOption={(e) =>
                                  handleCreateSkills(e, "update")
                                }
                              />
                            </div>
                            {updateEducationDetailsValidationData?.skill
                              ?.status === false ? (
                              <small className="text-danger">
                                Skills
                                {
                                  updateEducationDetailsValidationData?.skill
                                    ?.message
                                }
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 px-4 pb-4">
                          {updateEducationDetailsData.currentlyStudying ===
                          false ? (
                            <div className="google-input">
                              <label
                                htmlFor="template-input"
                                className="z-index-value"
                              >
                                End Date
                              </label>
                              <input
                                autoComplete="off"
                                type="date"
                                id="enddate"
                                name="enddate"
                                value={moment(
                                  updateEducationDetailsData?.workingTo
                                ).format("YYYY-MM-DD")}
                                onBlur={(e) => {
                                  if (
                                    updateEducationDetailsData.currentlyStudying ===
                                    false
                                  ) {
                                    onChangeUpdateEducationDetailsValidationData(
                                      "workingTo",
                                      NonEmptyValidationForEndDate(
                                        e.target.value
                                      )
                                    );
                                  }
                                }}
                                aria-invalid={
                                  updateEducationDetailsValidationData
                                    ?.workingTo?.status === false
                                }
                                onChange={(e) => {
                                  if (
                                    updateEducationDetailsData.currentlyStudying ===
                                    false
                                  ) {
                                    onChangeUpdateEducationDetailsData(
                                      "workingTo",
                                      e.target.value
                                    );
                                  }
                                }}
                                readOnly={
                                  updateEducationDetailsData.currentlyStudying
                                }
                              />
                              {updateEducationDetailsValidationData?.workingTo
                                ?.status === false ? (
                                <small className="text-danger">
                                  End Date
                                  {
                                    updateEducationDetailsValidationData
                                      ?.workingTo?.message
                                  }
                                </small>
                              ) : null}
                            </div>
                          ) : (
                            <div className="google-input">
                              <p className="text-center text-light w-100 bg-secondary py-2 mb-0 rounded">
                                Currently Pursuing
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modalAnswer col-lg-11 mt-2 mb-4 pt-2 px-4 ">
                  <label htmlFor="template-input" className="z-index-value">
                    Description
                  </label>
                  <div className="modalDisapprove-details d-flex  justify-content-end ">
                    <button
                      type="button"
                      className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                    >
                      Rich Text Editor
                      <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                        lock
                      </span>
                    </button>
                  </div>

                  <div className="form-group">
                    <textarea
                      onBlur={(e) => {
                        onChangeUpdateEducationDetailsValidationData(
                          "description",
                          NonEmptyValidation(
                            e.target.value
                          )
                        );
                      }}
                      onChange={(e) => {
                        onChangeUpdateEducationDetailsData(
                          "description",
                          e.target.value
                        );
                      }}
                      aria-invalid={
                        updateEducationDetailsValidationData?.description
                          ?.status === false
                      }
                      value={updateEducationDetailsData.description}
                      className="form-control modalDisapprove-textarea"
                      id="exampleFormControlTextarea2"
                      rows="7"
                    ></textarea>
                  </div>
                  {updateEducationDetailsValidationData?.description?.status ===
                  false ? (
                    <small className="text-danger">
                      Description
                      {
                        updateEducationDetailsValidationData?.description
                          ?.message
                      }
                    </small>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setEditEducationModal(false);
                    setUpdateEducationDetailsValidationData({});
                  }}
                >
                  Back
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                  onClick={() => handleUpdateEducationDetails()}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={deleteEducationModal}
        onClickOutside={() => {
          setDeleteEducationModal(false);
        }}
      >
        <div className="modal-content w-75  m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <h3 className="font-weight-bold text-center">
                    Are You Sure?
                  </h3>
                  <p className=" text-center">
                    Do you really want to delete this Record? this process
                    cannot be undone.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center border-0 mb-3">
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                  onClick={() => {
                    setDeleteEducationModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ padding: "8px 24px" }}
                  className="btn btn-danger my-2 my-sm-0 mr-2 "
                  onClick={() => handleDeleteEducationalDetails(deleteId)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={skillDeleteModalStatus}
        onClickOutside={() => {
          setSkillDeleteModalStatus(false);
        }}
      >
        <div className="modal-content w-75 m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <h3 className="font-weight-bold text-center">
                    Are You Sure?
                  </h3>
                  <p className=" text-center">
                    Do you really want to delete this skill? this process cannot
                    be undone.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center border-0 mb-3">
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                  onClick={() => {
                    setSkillDeleteModalStatus(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ padding: "8px 24px" }}
                  className="btn btn-danger my-2 my-sm-0 mr-2 "
                  onClick={() => handleDeleteSkill()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );

};

export default EducationalDetails;
