import React, { useState, useCallback, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { GetAllJobPost} from "../../components/Api/Api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Jobs() {
    const navigate = useNavigate();
    const state = useSelector((state) => state);
    const authKey = state?.auth?.auth?.authKey;
    const userProfileId = state?.auth?.auth?.userProfileId;
    const [listingJobs, setListingJobs] = useState([]);

    const handleListingJobPost = useCallback(async (value) => {
      if (!authKey || !userProfileId) return;

      const status = value;
      const response = await GetAllJobPost(authKey, userProfileId, status);
      if (response && response.statusCode === 200) {
        setListingJobs(response.data);
      } else {
      }
    }, [authKey, userProfileId]);
  
    useEffect(() => {
      handleListingJobPost("All");
    }, [handleListingJobPost]);

    console.log(listingJobs)

    const columns = [
      {
        name: "id",
        label: "#",
        options: {
          filter: false,
          customBodyRender: (value, meta) => {
            return meta.rowIndex + 1;
          },
        },
      },
      { name: "jobName", label: "Job Title" },
      { name: "companyName", label: "Company Name" },
      { name: "status", label: "status" },
      {
        name: "companyId",
        label: "Action",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <button className="btn" onClick={() => navigate('/Admin/Companies/EditJobs')}>Edit</button>
              </div>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "dropdown",
      selectableRows: "none",
      responsive: "standard",
    };
    return (
        <div className="dashboard-bg">
            <div className="main">
                <div className="scroll-content-admin">
                    <MUIDataTable title={"Jobs"} data={listingJobs?.jobDetails} columns={columns} options={options} />
                </div>
            </div>
        </div>
    )
}
