import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import { useNavigate } from "react-router";

export default function PrivacyPolicy() {
  //Navigation
  const naviGation = useNavigate();
  let url = window.location.href;
  let route = url.split("/");

  return (
    <div>
      <Navbar />
      <div className="privacy-policy mt-5">
        <div className="container">
          <div className="row justify-content-center pt-3">
            <div className="col-lg-9 py-5">
              <h5 className="font-weight-bold text-green">Privacy Policy</h5>
              <h6>simply dummy text</h6>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              <h6 className="font-weight-bold">Lorem ipsum dolor sit amet,</h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                mattis velit eu tellus sollicitudin, nec pretium ligula
                ullamcorper. Cras eu luctus dui. Nulla facilisi. Nullam
                porttitor, odio sed aliquam auctor, orci est iaculis ipsum, nec
                aliquet libero nibh eu dui. Pellentesque quis est nulla. In
                venenatis augue nec aliquet aliquet. Pellentesque vel lectus
                ligula. Duis iaculis diam ac nibh dapibus blandit. Maecenas
                porta orci quam, non ultricies nibh porta at. Nulla nec tempus
                erat. Phasellus nec semper odio.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                mattis velit eu tellus sollicitudin, nec pretium ligula
                ullamcorper. Cras eu luctus dui. Nulla facilisi. Nullam
                porttitor, odio sed aliquam auctor, orci est iaculis ipsum, nec
                aliquet libero nibh eu dui. Pellentesque quis est nulla. In
                venenatis augue nec aliquet aliquet. Pellentesque vel lectus
                ligula. Duis iaculis diam ac nibh dapibus blandit. Maecenas
                porta orci quam, non ultricies nibh porta at. Nulla nec tempus
                erat. Phasellus nec semper odio.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                mattis velit eu tellus sollicitudin, nec pretium ligula
                ullamcorper. Cras eu luctus dui. Nulla facilisi. Nullam
                porttitor, odio sed aliquam auctor, orci est iaculis ipsum, nec
                aliquet libero nibh eu dui. Pellentesque quis est nulla. In
                venenatis augue nec aliquet aliquet. Pellentesque vel lectus
                ligula. Duis iaculis diam ac nibh dapibus blandit. Maecenas
                porta orci quam, non ultricies nibh porta at. Nulla nec tempus
                erat. Phasellus nec semper odio.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                mattis velit eu tellus sollicitudin, nec pretium ligula
                ullamcorper. Cras eu luctus dui. Nulla facilisi. Nullam
                porttitor, odio sed aliquam auctor, orci est iaculis ipsum, nec
                aliquet libero nibh eu dui. Pellentesque quis est nulla. In
                venenatis augue nec aliquet aliquet. Pellentesque vel lectus
                ligula. Duis iaculis diam ac nibh dapibus blandit. Maecenas
                porta orci quam, non ultricies nibh porta at. Nulla nec tempus
                erat. Phasellus nec semper odio.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                mattis velit eu tellus sollicitudin, nec pretium ligula
                ullamcorper. Cras eu luctus dui. Nulla facilisi. Nullam
                porttitor, odio sed aliquam auctor, orci est iaculis ipsum, nec
                aliquet libero nibh eu dui. Pellentesque quis est nulla. In
                venenatis augue nec aliquet aliquet. Pellentesque vel lectus
                ligula. Duis iaculis diam ac nibh dapibus blandit. Maecenas
                porta orci quam, non ultricies nibh porta at. Nulla nec tempus
                erat. Phasellus nec semper odio.
              </p> 
              <button
                className="btn btn-light my-2 my-sm-0 login-btn"
                onClick={() => naviGation(`/${route[5]}`)}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
