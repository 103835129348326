import NavbarDashboard from "../NavbarDashboard";
import "react-phone-input-2/lib/material.css";
import Roboimg2 from "../../../images/roboimg-outerborder.png";
import { useSelector } from "react-redux";

export default function Chat() {
  //state value
  const state = useSelector((state) => state);
  const chatData = [
    {
      jobType: "Junior PHP Developer - Madipakkam, …...",
      name: "Manokaran K",
      message: "No Sir, I am not working in WordPress.",
    },
    {
      jobType: "Junior PHP Developer - Madipakkam, …...",
      name: "Manokaran K",
      message: "No Sir, I am not working in WordPress.",
    },
    {
      jobType: "Junior PHP Developer - Madipakkam, …...",
      name: "Manokaran K",
      message: "No Sir, I am not working in WordPress.",
    },
    {
      jobType: "Junior PHP Developer - Madipakkam, …...",
      name: "Manokaran K",
      message: "No Sir, I am not working in WordPress.",
    },
    {
      jobType: "Junior PHP Developer - Madipakkam, …...",
      name: "Manokaran K",
      message: "No Sir, I am not working in WordPress.",
    },
    {
      jobType: "Junior PHP Developer - Madipakkam, …...",
      name: "Manokaran K",
      message: "No Sir, I am not working in WordPress.",
    },
    {
      jobType: "Junior PHP Developer - Madipakkam, …...",
      name: "Manokaran K",
      message: "No Sir, I am not working in WordPress.",
    },
    {
      jobType: "Junior PHP Developer - Madipakkam, …...",
      name: "Manokaran K",
      message: "No Sir, I am not working in WordPress.",
    },
  ];
  const chatMessage = [
    {
      leftText:
        "No Sir, I am not working in WordPress. Current CTC 1.3L Expected CTC 2L ",
      rightText:
        "Thank you for applying to the Junior PHP Developer position at Quantzi Infotech Private Limited. ",
    },
    {
      leftText:
        "No Sir, I am not working in WordPress. Current CTC 1.3L Expected CTC 2L ",
      rightText:
        "Thank you for applying to the Junior PHP Developer position at Quantzi Infotech Private Limited. ",
    },
    {
      leftText:
        "No Sir, I am not working in WordPress. Current CTC 1.3L Expected CTC 2L ",
      rightText:
        "Thank you for applying to the Junior PHP Developer position at Quantzi Infotech Private Limited. ",
    },
    {
      leftText:
        "No Sir, I am not working in WordPress. Current CTC 1.3L Expected CTC 2L ",
      rightText:
        "Thank you for applying to the Junior PHP Developer position at Quantzi Infotech Private Limited. ",
    },
  ];
  return (
    <div className="dashboard-bg">
      <NavbarDashboard title={"Chat"} />
      <div className="main">
        <div className="chat-section ">
          {state?.auth?.auth?.userDetails?.status === "Active" ? (
            <div className="candidate-profile-section ">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4 px-0">
                    <div className="chat-text">
                      <div className="chat-search d-flex justify-content-between px-4 py-1">
                        <h6 className="chat font-weight-bold">CHAT</h6>
                        <div className="icon my-auto">
                          <span className="material-icons icon-middle">
                            search
                          </span>
                        </div>
                      </div>
                      <div className="all-chat-section scroll-content">
                        {chatData.map((data, index) => {
                          return (
                            <div
                              className="all-chat d-flex justify-content-between  mb-1 px-4 py-2"
                              key={index}
                            >
                              <div className="individual-chat">
                                <p className="mb-0 cut-text">{data.jobType}</p>
                                <p className="font-weight-bold mb-0 cut-text">
                                  {data.name}
                                </p>
                                <p className="mb-0 cut-text">{data.message}</p>
                              </div>
                              <div className="chat-date">
                                <p>
                                  <small>Aug 03</small>
                                </p>
                                <div className="icon my-auto">
                                  <span className="material-symbols-rounded icon-middle">
                                    star
                                  </span>
                                  {/* <span className="material-icons icon-middle">star</span> */}
                                  <span className="material-icons icon-middle">
                                    more_vert
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 pr-0 pl-2">
                    {/* <div className='chat-open text-center m-auto'>
									<div className='my-4'>
										<img src={ChatImg} alt='' /> 
										<h6 className='font-weight-bold text-center'>Select the conversationon</h6>
									</div>
								</div> */}
                    <div className="chat-open">
                      <div className="chat-search d-flex justify-content-between px-4 py-1">
                        <div className="chat-open-text">
                          <h6 className="chat">Junior PHP Developer</h6>
                          <h6 className="font-weight-bold">Manokaran K</h6>
                        </div>
                        <div className="chat-open-text my-auto">
                          <h6>APPLIED:</h6>
                          <h6 className="font-weight-bold">AUG 03, 2022</h6>
                        </div>
                      </div>
                      <div className="chat-open-section scroll-content pb-5">
                        {chatMessage.map((data, index) => {
                          if (index % 2 === 0) {
                            return (
                              <div className="" key={index}>
                                <h6 className="text-center  my-4">
                                  <span className="py-2 px-4">
                                    AUG 03, 2022
                                  </span>
                                </h6>
                                <div className="message-right px-4 my-2">
                                  <div className="text-left chat-right">
                                    <p className="mb-0">{data.rightText}</p>
                                  </div>
                                  <small>5:34 PM</small>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="" key={index}>
                                <h6 className="text-center  my-4">
                                  <span className="py-2 px-4">
                                    AUG 03, 2022
                                  </span>
                                </h6>

                                <div className="message-left px-4 my-2">
                                  <div className="text-left chat-left">
                                    <p className="mb-0">{data.leftText}</p>
                                  </div>
                                  <small className="d-flex justify-content-end">
                                    5:34 PM
                                  </small>
                                </div>
                              </div>
                            );
                          }
                        })}
                        <div className="chat-send px-4">
                          <input
                            autoComplete="off"
                            className="form-control"
                            placeholder="Write your message"
                          />
                          <button className="send-btn">
                            <span className="material-icons icon-middle">
                              send
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal-body p-3 ">
              <div className="modalHeader">
                <h6 className="text-center font-weight-bold my-2">
                  <img
                    style={{
                      width: "85px",
                      textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px;",
                    }}
                    src={Roboimg2}
                    alt=""
                  />
                </h6>
              </div>
              <form className="container">
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="col-lg-8 pb-3">
                    <p className=" text-center">
                      Your account is still pending for approval.
                    </p>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
