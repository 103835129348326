import React from 'react'
import NavbarAdmin from "../../Admin/NavbarAdmin";
export default function EditEmployee() {
    return (
        <div className="dashboard-bg">
            <NavbarAdmin />
            <div className="main">
                <div className="chat-section bg-white py-1" style={{ boxShadow: "0px 3px 6px #00000017" }}>
                    <div className="">
                        <>
                            <nav className="flex border-b tab-section border-gray-300">
                                <div className="tab active">
                                    <li className="tab-header">Edit Employee</li>
                                </div>
                            </nav>
                        </>
                        <div className="">
                            <div className="container">
                                <div className="row d-flex align-items-center justify-content-center ">
                                    <div className="col-lg-10">
                                        <form>
                                            <div className="form-container py-4">
                                                <div className="row justify-content-center ">
                                                    <div className="col-lg-6 border-right  pb-4">
                                                        <div className="google-input">
                                                            <input autoComplete="off" type="text" id="template-input" placeholder="Enter your full name" />
                                                            <label htmlFor="template-input">Name</label>
                                                        </div>
                                                        <div className="google-input">
                                                            <input autoComplete="off" type="text" id="template-input" placeholder="Enter your email" />
                                                            <label htmlFor="template-input">Email Id</label>
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-6  pb-4">
                                                        <div className="google-input">
                                                            <input autoComplete="off" type="text" id="template-input" placeholder="Enter your Mobile Number" />
                                                            <label htmlFor="template-input">Mobile Number</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 text-center">
                                                        <div className="google-input">
                                                            <button type="button" className="btn btn-light my-2 my-sm-0 login-btn">
                                                                Update
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
