import React, { useEffect, useCallback, useState } from "react";
import NavbarDashboard from "../NavbarDashboard";
import { Link } from "react-router-dom";
import Roboimg from "../../../images/roboimg.png";
import Select from "react-select";
import "react-phone-input-2/lib/material.css";
import Roboimg2 from "../../../images/roboimg-outerborder.png";
import CustomModal from "../../CustomModal";
import { useSelector } from "react-redux";
import {
  CloseJob,
  CreateEmployeeSkills,
  GetAllAppliedJos,
  GetMasterDatasByType,
  GetParticularJob,
  ListingJobPost,
  UpdateJobPost,
} from "../../Api/Api";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import {
  NonEmptyObjectValidation,
  NonEmptyValidation,
  NoOfPositionNumberValidation,
  NumberMaxValidation,
  NumberValidation,
  SalaryRangeValidation,
  StringValidation,
} from "../../Store/validate";
import { GetInitials } from "../../Store/Constants";

export default function JobsDetailsPage() {
  // React state to manage selected options
  const [selectedOptions, setSelectedOptions] = useState();

  // Array of all options
  const optionList = [
    { value: "Remote", label: "Remote" },
    { value: "Anywhere in US", label: "Anywhere in US" },
    { value: "Atlanta", label: "Atlanta" },
    { value: "Texas", label: "Texas" },
    { value: "California", label: "California" },
  ];

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }
  const [editJobModal, seteditJobModal] = useState(false);
  const [editJob2Modal, seteditJob2Modal] = useState(false);
  const [ClosingModal, setClosingModal] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState("");
  //state value
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const companyId = state?.auth?.auth?.userDetails?.companyId;
  const companyProfileId = state?.auth?.auth?.userProfileId;

  //////////////////////////////////////////////////////////////////////////////////////////////////

  let host = window.location.href;
  let jobid = host?.split("/");

  const [JobPostValidation, setJobPostValidation] = useState({});
  const setValidationValue = (key, value) => {
    setJobPostValidation({ ...JobPostValidation, [key]: value });
  };

  const [getParticularJob, setGetParticularJob] = useState([]);
  const setGetParticularJobValue = (key, value) => {
    setGetParticularJob({ ...getParticularJob, [key]: value });
    if (JobPostValidation[key]) delete JobPostValidation[key];
  };

  const fetchParticularJob = useCallback(async () => {
    const jobId = jobid[5];

    if (!authKey || !jobId) return;

    const getJobPost = await GetParticularJob(authKey, jobId);
    let data1 = getJobPost.data;
    setGetParticularJob(data1);
  }, [authKey, jobid]);

  useEffect(() => {
    fetchParticularJob();
  }, [fetchParticularJob]);

  const [closeJobPost, setCloseJobPost] = useState(false);

  // const [listingJobs, setListingJobs] = useState([]);

  const handleListingJobPost = useCallback(async (value) => {
    if (!authKey || !companyProfileId) return;

    const Payload = {
      companyProfileId,
      status: value,
    };

    const response = await ListingJobPost(Payload, authKey);
    if (response && response.statusCode === 200) {
      // setListingJobs(response.data);
    } else {
    }
  }, [authKey, companyProfileId]);

  useEffect(() => {
    handleListingJobPost("Opened");
  }, [handleListingJobPost]);

  const handleChangeJobStatus = async (jobValue) => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      jobId: getParticularJob.jobId,
      status: jobValue,
    };

    const response = await UpdateJobPost(Payload, token);
    if (response && response.statusCode === 200) {
      handleListingJobPost("Opened");
      setGetParticularJob(response.data);
      fetchParticularJob();
      setCloseJobPost(false);
      showToastSuccessMsg(response.message);
    }
  };

  //get job title
  const [jobTitle, setJobTitle] = useState([]);

  const fetchJobTitle = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=job_title&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setJobTitle(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchJobTitle();
  }, [fetchJobTitle]);

  const handleCreateJobTitle = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("jobTitleId", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "job_title",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchJobTitle();
        const data = {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        };

        setGetParticularJobValue("jobTitleId", data);
      }
    }
  };

  //get salary range

  const [salaryRange, setsalaryRage] = useState([]);
  
  const fetchSalaryRange = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=salary_range_by&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setsalaryRage(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchSalaryRange();
  }, [fetchSalaryRange]);

  //get employment type

  const [employmentType, setEmploymentType] = useState([]);

  const fetchData = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=employment&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setEmploymentType(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCreateEmploymentType = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("employmentType", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "employment",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };

      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchData();

        const data = {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        };

        if (getParticularJob?.employmentType?.length) {
          let employmentTypeData = getParticularJob?.employmentType;

          employmentTypeData.push(data);
          setGetParticularJobValue("employmentType", employmentTypeData);
        } else {
          setGetParticularJobValue("employmentType", [data]);
        }
      }
    } else {
      console.log("Error while creating employmentType");
    }
  };

  //get work place

  const [workPlace, setworkPlace] = useState([]);

  const fetchData1 = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=work_place&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setworkPlace(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData1();
  }, [fetchData1]);

  const handleCreateWorkPlace = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("workPlaceType", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "work_place",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchData1();

        const data = {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        };
        if (getParticularJob?.workPlaceType?.length) {
          let workPlaceTypeData = getParticularJob?.workPlaceType;

          workPlaceTypeData.push(data);
          setGetParticularJobValue("workPlaceType", workPlaceTypeData);
        } else {
          setGetParticularJobValue("workPlaceType", [data]);
        }
      }
    } else {
      console.log("Error while creating workPlaceType");
    }
  };

  //get schedule
  const [schedule, setSchedule] = useState([]);

  const fetchData2 = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=schedule&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setSchedule(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData2();
  }, [fetchData2]);

  const handleCreateSchedule = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("schedule", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "schedule",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchData2();

        const data = {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        };
        if (getParticularJob?.schedule?.length) {
          let scheduleData = getParticularJob?.schedule;

          scheduleData.push(data);
          setGetParticularJobValue("schedule", scheduleData);
        } else {
          setGetParticularJobValue("schedule", [data]);
        }
      }
    } else {
      console.log("Error while creating schedule");
    }
  };

  //get experience level
  const [experienceLevel, setExperienceLevel] = useState([]);

  const fetchData3 = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=experience_level&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setExperienceLevel(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData3();
  }, [fetchData3]);

  const handleCreateExperienceLevel = async (string) => {
    const valid = NumberValidation(string);
    setValidationValue("experienceLevel", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "experience_level",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchData3();

        const data = {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        };
        if (getParticularJob?.experienceLevel?.length) {
          let experienceLevelData = getParticularJob?.experienceLevel;

          experienceLevelData.push(data);
          setGetParticularJobValue("experienceLevel", experienceLevelData);
        } else {
          setGetParticularJobValue("experienceLevel", [data]);
        }
      }
    } else {
      console.log("Error while creating experienceLevel");
    }
  };

  //get must skill
  const [mustSkill, setMustSkill] = useState([]);

  const fetchData4 = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=must_have_skill&status=Active&restrictionLevel=1&companyId=${companyId}`;
    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setMustSkill(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData4();
  }, [fetchData4]);

  const handleCreateMustSkill = async (string) => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      masterDataType: "must_have_skill",
      name: string,
      userId: state?.auth?.auth?.userProfileId,
      companyId: state?.auth?.auth?.userDetails?.companyId,
      restrictionLevel: 1,
    };
    const response = await CreateEmployeeSkills(Payload, token);
    if (response && response.statusCode === 200) {
      fetchData4();

      const data = {
        value: response.data.name,
        label: response.data.name,
        dataId: response.data.masterDataId,
      };
      if (getParticularJob?.mustHaveSkill?.length) {
        let mustSkillData = getParticularJob?.mustHaveSkill;

        mustSkillData.push(data);
        setGetParticularJobValue("mustHaveSkill", mustSkillData);
      } else {
        setGetParticularJobValue("mustHaveSkill", [data]);
      }
    }
  };

  //get need skill
  const [needSkill, setNeedSkill] = useState([]);

  const fetchData5 = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=nice_have_skill&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setNeedSkill(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData5();
  }, [fetchData5]);

  const handleCreateNeedSkill = async (string) => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      masterDataType: "nice_have_skill",
      name: string,
      userId: state?.auth?.auth?.userProfileId,
      companyId: state?.auth?.auth?.userDetails?.companyId,
      restrictionLevel: 1,
    };
    const response = await CreateEmployeeSkills(Payload, token);
    if (response && response.statusCode === 200) {
      fetchData5();

      const data = {
        value: response.data.name,
        label: response.data.name,
        dataId: response.data.masterDataId,
      };
      if (getParticularJob?.niceTohaveSkill?.length) {
        let niceTohaveSkillData = getParticularJob?.niceTohaveSkill;

        niceTohaveSkillData.push(data);
        setGetParticularJobValue("niceTohaveSkill", niceTohaveSkillData);
      } else {
        setGetParticularJobValue("niceTohaveSkill", [data]);
      }
    }
  };

  //get education qualification
  const [educationalQualification, setEducationalQualification] = useState([]);

  const fetchData6 = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=educational&status=Active&restrictionLevel=1&companyId=${companyId}`;
    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setEducationalQualification(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData6();
  }, [fetchData6]);

  const handleCreateEducationalQualification = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("highestQualication", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "educational",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchData5();

        const data = {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        };
        if (getParticularJob?.highestQualication?.length) {
          let highestQualicationData = getParticularJob?.highestQualication;

          highestQualicationData.push(data);
          setGetParticularJobValue(
            "highestQualication",
            highestQualicationData
          );
        } else {
          setGetParticularJobValue("highestQualication", [data]);
        }
      }
    } else {
      console.log("Error while creating educationalQualification");
    }
  };

  //get employer benefit
  const [employerBenefits, setEmployerBenefits] = useState([]);

  const fetchData7 = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=employer_benefits&status=Active&restrictionLevel=1&companyId=${companyId}`;
    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setEmployerBenefits(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData7();
  }, [fetchData7]);

  const handleCreateEmployerBenefits = async (string) => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      masterDataType: "employer_benefits",
      name: string,
      userId: state?.auth?.auth?.userProfileId,
      companyId: state?.auth?.auth?.userDetails?.companyId,
      restrictionLevel: 1,
    };
    const response = await CreateEmployeeSkills(Payload, token);
    if (response && response.statusCode === 200) {
      fetchData5();

      const data = {
        value: response.data.name,
        label: response.data.name,
        dataId: response.data.masterDataId,
      };
      if (getParticularJob?.employerBenefits?.length) {
        let employerBenefitsData = getParticularJob?.employerBenefits;

        employerBenefitsData.push(data);
        setGetParticularJobValue("employerBenefits", employerBenefitsData);
      } else {
        setGetParticularJobValue("employerBenefits", [data]);
      }
    }
  };

  // const [jobDetails, setJobDetails] = useState({});
  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  // create job post api integration

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };
  const handleUpdateJobPost = async (value) => {
    const Payload = {
      jobTitleId: getParticularJob?.jobTitleId?.dataId,
      employmentTypeId: getParticularJob?.employmentType?.map(
        (e) => e.dataId || e.masterDataId
      ),
      workPlaceTypeId: getParticularJob?.workPlaceType?.map(
        (e) => e.masterDataId || e.dataId
      ),
      locationId: [getParticularJob?.locationId],
      scheduleId: getParticularJob?.schedule?.map(
        (e) => e.masterDataId || e.dataId
      ),
      salaryRangeById: getParticularJob?.salaryRangeByValue,
      experienceLevelId: getParticularJob?.experienceLevel?.map(
        (e) => e.masterDataId || e.dataId
      ),
      mustHaveSkillId: getParticularJob?.mustHaveSkill?.map(
        (e) => e.masterDataId || e.dataId
      ),
      niceTohaveSkillId: getParticularJob?.niceTohaveSkill?.map(
        (e) => e.masterDataId || e.dataId
      ),
      highestQualicationId:
        getParticularJob?.highestQualication?.masterDataId ||
        getParticularJob?.highestQualication?.dataId,
      noOfPosition: getParticularJob?.noOfPosition,
      companyId: state.auth.auth.userDetails?.companyId,
      employerBenefitsId: getParticularJob?.employerBenefits?.map(
        (e) => e.masterDataId || e.dataId
      ),
      jobDescription: getParticularJob?.jobDescription,
      jobId: value,
      status: getParticularJob?.status,
    };

    const token = state?.auth?.auth?.authKey;
    const response = await UpdateJobPost(Payload, token);
    if (response && response.statusCode === 200) {
      seteditJobModal(false);
      seteditJob2Modal(false);
      showToastSuccessMsg(response.message);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };
  //clear edit feild

  const ClearFields = () => {
    setJobPostValidation({});
  };

  const ContinueValidation = () => {
    let validate = {};
    validate.jobTitleId = NonEmptyObjectValidation(getParticularJob.jobTitleId);
    validate.employmentType = NonEmptyObjectValidation(
      getParticularJob?.employmentType
    );
    validate.workPlaceType = NonEmptyObjectValidation(
      getParticularJob?.workPlaceType
    );
    validate.locationId = NonEmptyValidation(getParticularJob?.locationId);
    validate.schedule = NonEmptyObjectValidation(getParticularJob?.schedule);
    validate.experienceLevel = NonEmptyObjectValidation(
      getParticularJob?.experienceLevel
    );
    validate.mustHaveSkill = NonEmptyObjectValidation(
      getParticularJob?.mustHaveSkill
    );
    validate.niceTohaveSkill = NonEmptyObjectValidation(
      getParticularJob?.niceTohaveSkill
    );
    validate.salaryMin = NumberValidation(
      getParticularJob?.salaryMin.toString()
    );
    validate.salaryMax = NumberMaxValidation(
      getParticularJob?.salaryMax.toString(),
      getParticularJob?.salaryMin.toString()
    );
    validate.salaryRangeByValue = SalaryRangeValidation(
      getParticularJob?.salaryRangeByValue,
      "Select Range Type"
    );
    setJobPostValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      seteditJob2Modal(true);
      seteditJobModal(false);
    } else {
      console.log("Error");
    }
  };

  const BackValidation = () => {
    let validate = {};
    validate.highestQualication = NonEmptyObjectValidation(
      getParticularJob?.highestQualication
    );
    validate.noOfPosition = NoOfPositionNumberValidation(getParticularJob?.noOfPosition);
    validate.employerBenefits = NonEmptyObjectValidation(
      getParticularJob?.employerBenefits
    );
    validate.jobDescription = NonEmptyValidation(
      getParticularJob?.jobDescription
    );
    setJobPostValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      seteditJob2Modal(false);
      seteditJobModal(true);
    } else {
      console.log("Error");
    }
  };

  const PublishValidation = (value) => {
    let validate = {};
    validate.jobTitleId = NonEmptyObjectValidation(getParticularJob.jobTitleId);
    validate.employmentType = NonEmptyObjectValidation(
      getParticularJob?.employmentType
    );
    validate.workPlaceType = NonEmptyObjectValidation(
      getParticularJob?.workPlaceType
    );
    validate.locationId = NonEmptyValidation(getParticularJob?.locationId);
    validate.schedule = NonEmptyObjectValidation(getParticularJob?.schedule);
    validate.experienceLevel = NonEmptyObjectValidation(
      getParticularJob?.experienceLevel
    );
    validate.mustHaveSkill = NonEmptyObjectValidation(
      getParticularJob?.mustHaveSkill
    );
    validate.niceTohaveSkill = NonEmptyObjectValidation(
      getParticularJob?.niceTohaveSkill
    );
    validate.salaryMin = NumberValidation(
      getParticularJob?.salaryMin.toString()
    );
    validate.salaryMax = NumberMaxValidation(
      getParticularJob?.salaryMax.toString(),
      getParticularJob?.salaryMin.toString()
    );
    validate.salaryRangeByValue = SalaryRangeValidation(
      getParticularJob?.salaryRangeByValue,
      "Select Range Type"
    );
    setJobPostValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      let validate = {};
      validate.highestQualication = NonEmptyObjectValidation(
        getParticularJob?.highestQualication
      );
      validate.noOfPosition = NoOfPositionNumberValidation(
        getParticularJob?.noOfPosition
      );
      validate.employerBenefits = NonEmptyObjectValidation(
        getParticularJob?.employerBenefits
      );
      validate.jobDescription = NonEmptyValidation(
        getParticularJob?.jobDescription
      );
      setJobPostValidation(validate);

      if (Object.values(validate).every((v) => v.status === true)) {
        handleUpdateJobPost(value);
      }
    } else {
      console.log("Error while clicking publish in edit job modal");
    }
  };

  const FinalPublishValidation = (value) => {
    let validate = {};
    validate.highestQualication = NonEmptyObjectValidation(
      getParticularJob?.highestQualication
    );
    validate.noOfPosition = NoOfPositionNumberValidation(getParticularJob?.noOfPosition);
    validate.employerBenefits = NonEmptyObjectValidation(
      getParticularJob?.employerBenefits
    );
    validate.jobDescription = NonEmptyValidation(
      getParticularJob?.jobDescription
    );
    setJobPostValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      handleUpdateJobPost(value);
    } else {
      console.log("Error while clicking final publish in edit job modal");
    }
  };

  //get all applied jobs
  const [allAplliedJobs, setAllAppliedJobs] = useState([]);

  const fetchAllAppliedJobs = useCallback(async (status) => {
    const jobId = jobid[5];
    if (!authKey || !jobId) return;

    const getJobPost = await GetAllAppliedJos(authKey, jobId, status);
    let data1 = getJobPost.data;
    setAllAppliedJobs(data1);
  }, [authKey, jobid]);

  useEffect(() => {
    fetchAllAppliedJobs("Applied");
  }, [fetchAllAppliedJobs]);

  const [closeReason, setCloseReason] = useState([]);
  const setCloseReasonValue = (key, value) => {
    setCloseReason({ ...closeReason, [key]: value });
    if (reasonModalValidation[key]) delete reasonModalValidation[key];
  };

  const [reasonModalValidation, setReasonModalValidation] = useState({});

  const handleCloseJobPost = async (value) => {
    let validate = {};
    validate.reason = NonEmptyObjectValidation(closeReason.reason);
    setReasonModalValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        reason: closeReason.reason.dataId,
        jobId: value,
        closedBy: state?.auth?.auth?.userProfileId,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await CloseJob(Payload, token);
      if (response && response.statusCode === 200) {
        setCloseJobPost(false);
        handleListingJobPost("Opened");
        showToastSuccessMsg(response.message);
      } else {
        setCloseJobPost(false);
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      console.log("Error while closing job");
    }
  };
  const [reason, setReason] = useState([]);
  const fetchReason = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=reason&status=Active&restrictionLevel=1&companyId=${companyId}`;
    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setReason(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchReason();
  }, [fetchReason]);

  // const [currentPage, setCurrentPage] = useState(1);
  // const [clicksPerPage, setClicksPerPage] = useState(8);
 
  // const IndexOfLastPage = currentPage * clicksPerPage;
  // const IndexOfFirstPage = IndexOfLastPage - clicksPerPage;
  // const current = listingJobs?.jobDetails?.slice(IndexOfFirstPage, IndexOfLastPage)
 
  // const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className="dashboard-bg">
      <NavbarDashboard />
      <div className="main">
        <div className="jobs-details-section scroll-content">
          <div className="Jobs-details-job-name d-flex align-items-center py-1">
            <div className="container-fluid">
              <div className=" d-flex justify-content-between align-items-center px-3 py-2">
                <div className="left-text">
                  <h5 className="font-weight-bold">
                    {getParticularJob?.jobName}
                  </h5>
                  <small className="mb-0">
                    <span className="material-symbols-rounded filled-icon icon-middle">
                      location_on
                    </span>
                    {getParticularJob?.locationId}
                  </small>
                </div>
                <div className="right-text">
                  <button
                    className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn"
                    onClick={() => {
                      seteditJobModal(true);
                    }}
                  >
                    Edit Job
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="Jobs-details-skills d-flex align-items-center mt-2 py-1">
            <div className="container-fluid">
              <div className="row  justify-content-between align-items-center px-3 py-2">
                <div className="col-lg-10">
                  <div className="row">
                    <div className="col-lg-2 col-md-6">
                      <p className="mb-1 font-weight-bold">Must have skills</p>
                      {getParticularJob?.mustHaveSkill?.map((e) => (
                        <li>{e.name}</li>
                      ))}
                    </div>
                    <div className="col-lg-2 col-md-6">
                      <p className="mb-1 font-weight-bold">
                        Nice to have skills
                      </p>

                      {getParticularJob?.niceTohaveSkill?.map((e) => (
                        <li>{e.name}</li>
                      ))}
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <p className="mb-1 font-weight-bold">Job info</p>
                      <table>
                        <tbody>
                          <tr>
                            <td>Experience</td>
                            <th>:</th>
                            <th>
                              {getParticularJob?.experienceLevel?.map((e) => (
                                <p className="mb-0">{e.name}</p>
                              ))}
                            </th>
                          </tr>
                          <tr>
                            <td>Employment Type</td>
                            <th>:</th>
                            <th>
                              {getParticularJob?.workPlaceType?.map((e) => (
                                <p className="mb-0">{e.name}</p>
                              ))}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-lg-5 col-md-6">
                      <p className="mb-1 font-weight-bold">Job info</p>
                      <table>
                        <tbody>
                          <tr>
                            <td>Job Type</td>
                            <th>:</th>
                            <th>
                              {getParticularJob?.employmentType?.map((e) => (
                                <p className="mb-0">{e.name}</p>
                              ))}
                            </th>
                          </tr>
                          <tr>
                            <td>Salary</td>
                            <th>:</th>
                            <th>
                              ${getParticularJob?.salaryMin} -
                              {getParticularJob?.salaryMax}{" "}
                              {getParticularJob?.salaryRangeByValue}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <table>
                    <tbody>
                      <td className="p-1 align-middle">
                        <div className="Toggle-Switch align-items-center d-flex">
                          <label className="switch icon-middle mb-0">
                            <input
                              autoComplete="off"
                              type="checkbox"
                              onChange={() => {
                                if (getParticularJob?.status === "Active") {
                                  handleChangeJobStatus("InActive");
                                } else {
                                  handleChangeJobStatus("Active");
                                }
                              }}
                              checked={getParticularJob?.status === "Active"}
                            />
                            <span className="slider round"></span>
                          </label>
                          <span>{getParticularJob?.status}</span>
                        </div>
                      </td>
                      <td className="">
                        <button
                          className="btn btn-light postjob-btn"
                          onClick={() => {
                            setSelectedJobId(getParticularJob?.jobId);
                            setCloseJobPost(true);
                          }}
                        >
                          Close
                        </button>
                        {/* <button
                                    className="btn btn-light postjob-btn"
                                    onClick={() => {
                                      setSelectedJobId(getParticularJob?.jobId);
                                      setCloseJobPost(true);
                                    }}
                                  >
                                    Close
                                  </button> */}
                      </td>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="Jobs-details-section1 d-flex align-items-center mt-2 py-1">
            <div className="container-fluid">
              <div className="align-items-center responsive-jobshomemployeee ">
                <div className="has-search2">
                  <span className="material-symbols-rounded form-control-feedback2 align-middle pr-2">
                    tune
                  </span>
                </div>
                <div className="d-flex text-center">
                  <button
                    className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn"
                    onClick={() => {
                      fetchAllAppliedJobs("Applied");
                    }}
                  >
                    New ({allAplliedJobs?.Applied})
                  </button>
                  <button
                    className={`btn btn-light my-2 my-sm-0 mr-2 postjob-btn  `}
                    onClick={() => {
                      fetchAllAppliedJobs("Waiting");
                    }}
                  >
                    Waiting for review ({allAplliedJobs?.Waiting})
                  </button>
                  <button
                    className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn"
                    onClick={() => {
                      fetchAllAppliedJobs("Inprogress");
                    }}
                  >
                    In Progress ({allAplliedJobs?.Inprogress})
                  </button>
                  <button
                    className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn"
                    onClick={() => {
                      fetchAllAppliedJobs("Selected");
                    }}
                  >
                    Hired ({allAplliedJobs?.Selected})
                  </button>
                </div>
                <div className=" justify-content-center my-auto">
                  <button
                    className="btn btn-light text-danger my-2 my-sm-0 mr-2 rejected-btn postjob-btn"
                    onClick={() => {
                      fetchAllAppliedJobs("Rejected");
                    }}
                  >
                    Rejected ({allAplliedJobs?.Rejected})
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="Jobs-details-skills2 mt-2">
            <div className="container-fluid">
              <div className="row">
                {allAplliedJobs?.appliedJobDetails?.length === 0 ? (
                  <div className="col-12 mt-4">
                    <h6 className="text-center font-weight-bold my-2">
                      <img
                        style={{
                          width: "85px",
                          textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                        }}
                        src={Roboimg}
                        alt=""
                      />
                    </h6>
                    <br />
                    <div>
                      <h6 className="text-center font-weight-bold my-2">
                        No applicants found
                      </h6>
                    </div>
                  </div>
                ) : (
                  allAplliedJobs?.appliedJobDetails?.map((data, index) => {
                    return (
                      <div
                        className="col-lg-3 jobs-padding col-md-6"
                        key={index}
                      >
                        <Link
                          className="redirect-link"
                          to={`/CandidateProfile/${data?.appliedId}`}
                        >
                          <div className="jobs-content">
                            <div className="resume-details">
                              <div className="resume-details-content row">
                                <div className="profile-name mx-2 profile-name d-flex align-items-center justify-content-center mx-2">
                                  <div className="banner-profile-dashboard2">
                                    {data?.userDetails?.profileImage ? (
                                      <img
                                        className="PP-upload-img"
                                        alt=""
                                        src={data?.userDetails?.profileImage}
                                        hidden={
                                          !data?.userDetails?.profileImage
                                        }
                                      />
                                    ) : (
                                      <h1>
                                        {GetInitials(
                                          data?.userDetails?.displayName
                                        )}
                                      </h1>
                                    )}
                                  </div>
                                </div>
                                <div className="profile-name-content mx-2 my-auto ">
                                  <h6 className="font-weight-bold">
                                    {data?.userDetails?.fullName}
                                  </h6>
                                  <small className="mb-0">
                                    UX/UI Designer{" "}
                                  </small>
                                </div>
                                <div className="match d-flex align-items-center mx-2">
                                  <small className="color-green">
                                    90% Match
                                  </small>
                                </div>
                              </div>

                              <div className="my-2">
                                <p className=" font-weight-bold">
                                  Primary skills
                                </p>
                                {data?.skills ? (
                                  data?.skills?.map((e) =>
                                    e.skillType === "Primary" ? (
                                      <li>{e.skillName.name}</li>
                                    ) : (
                                      ""
                                    )
                                  )
                                ) : (
                                  <li>N/A</li>
                                )}
                              </div>

                              <div className="my-2">
                                <p className=" font-weight-bold">
                                  Secondary skills
                                </p>
                                {data?.skills ? (
                                  data?.skills?.map((e) =>
                                    e.skillType === "Secondary" ? (
                                      <li>{e.skillName.name}</li>
                                    ) : (
                                      ""
                                    )
                                  )
                                ) : (
                                  <li>N/A</li>
                                )}
                              </div>
                              <small>
                                <span>Applied on Aug 03</span>
                              </small>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={editJobModal}
        onClickOutside={() => {
          seteditJobModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">Edit Job</h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content">
                <div className="col-lg-12">
                  <form>
                    <div className="form-container py-1">
                      <div className="row ">
                        <div className="col-lg-6 px-5 pb-4 border-right">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Job Title
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={jobTitle}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      JobPostValidation?.jobTitleId?.status ===
                                      false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.jobTitleId?.status ===
                                      false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose Job Title"
                                value={{
                                  value:
                                    getParticularJob?.jobTitleId?.label ||
                                    getParticularJob?.jobTitleId?.name ||
                                    getParticularJob?.jobName,
                                  label:
                                    getParticularJob?.jobTitleId?.label ||
                                    getParticularJob?.jobTitleId?.name ||
                                    getParticularJob?.jobName,
                                  dataId:
                                    getParticularJob?.jobTitleId
                                      ?.masterDataId ||
                                    getParticularJob?.jobTitleId?.dataId ||
                                    getParticularJob?.jobTitleId,
                                }}
                                onChange={(newValue) => {
                                  setGetParticularJobValue(
                                    "jobTitleId",
                                    newValue
                                  );
                                }}
                                onInputChange={() => {
                                  if (JobPostValidation["jobTitleId"])
                                    delete JobPostValidation["jobTitleId"];
                                }}
                                onCreateOption={handleCreateJobTitle}
                                isClearable
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.jobTitleId?.message
                                ? `Job title ${getParticularJob?.jobTitleId?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Employment Type
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={employmentType}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      JobPostValidation?.employmentType
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.employmentType
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose employment type"
                                value={
                                  getParticularJob?.employmentType
                                    ? getParticularJob?.employmentType?.map(
                                        (v) => {
                                          return {
                                            value: v.name ? v.name : v.label,
                                            label: v.name ? v.name : v.label,
                                            dataId: v.masterDataId
                                              ? v.masterDataId
                                              : v.dataId,
                                          };
                                        }
                                      )
                                    : []
                                }
                                onChange={(newValue) => {
                                  setGetParticularJobValue(
                                    "employmentType",
                                    newValue
                                  );
                                }}
                                onInputChange={() => {
                                  if (JobPostValidation["employmentType"])
                                    delete JobPostValidation["employmentType"];
                                }}
                                onCreateOption={handleCreateEmploymentType}
                                isClearable
                                isMulti
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.employmentType?.message
                                ? `Employment type ${JobPostValidation?.employmentType?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Work Place Type
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={workPlace}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      JobPostValidation?.workPlaceType
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.workPlaceType
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose job type"
                                isClearable
                                isMulti
                                value={
                                  getParticularJob?.workPlaceType
                                    ? getParticularJob?.workPlaceType?.map(
                                        (v) => {
                                          return {
                                            value: v.name ? v.name : v.label,
                                            label: v.name ? v.name : v.label,
                                            dataId: v.masterDataId
                                              ? v.masterDataId
                                              : v.dataId,
                                          };
                                        }
                                      )
                                    : []
                                }
                                onChange={(newValue) => {
                                  setGetParticularJobValue(
                                    "workPlaceType",
                                    newValue
                                  );
                                }}
                                onInputChange={() => {
                                  if (JobPostValidation["workPlaceType"])
                                    delete JobPostValidation["workPlaceType"];
                                }}
                                onCreateOption={handleCreateWorkPlace}
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.workPlaceType?.message
                                ? `Work type ${JobPostValidation?.workPlaceType?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Location
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      JobPostValidation?.locationId?.status ===
                                      false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.locationId?.status ===
                                      false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose your job location"
                                value={{
                                  value: getParticularJob?.locationId,
                                  label: getParticularJob?.locationId,
                                }}
                                onChange={(e) =>
                                  setGetParticularJobValue("locationId", "USA")
                                }
                                isMulti
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.locationId?.message
                                ? `Location ${JobPostValidation?.locationId?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Schedule
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={schedule}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      JobPostValidation?.schedule?.status ===
                                      false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.schedule?.status ===
                                      false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose Schedule"
                                isClearable
                                isMulti
                                value={
                                  getParticularJob?.schedule
                                    ? getParticularJob?.schedule?.map((v) => {
                                        return {
                                          value: v.name ? v.name : v.label,
                                          label: v.name ? v.name : v.label,
                                          dataId: v.masterDataId
                                            ? v.masterDataId
                                            : v.dataId,
                                        };
                                      })
                                    : []
                                }
                                onChange={(newValue) => {
                                  setGetParticularJobValue(
                                    "schedule",
                                    newValue
                                  );
                                }}
                                onInputChange={() => {
                                  if (JobPostValidation["schedule"])
                                    delete JobPostValidation["schedule"];
                                }}
                                onCreateOption={handleCreateSchedule}
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.schedule?.message
                                ? `Schedule ${JobPostValidation?.schedule?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-6 px-5 pb-4">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Experience level
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={experienceLevel}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      JobPostValidation?.experienceLevel
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.experienceLevel
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose experience level"
                                value={
                                  getParticularJob?.experienceLevel
                                    ? getParticularJob?.experienceLevel?.map(
                                        (v) => {
                                          return {
                                            value: v.name ? v.name : v.label,
                                            label: v.name ? v.name : v.label,
                                            dataId: v.masterDataId
                                              ? v.masterDataId
                                              : v.dataId,
                                          };
                                        }
                                      )
                                    : []
                                }
                                onChange={(newValue) => {
                                  setGetParticularJobValue(
                                    "experienceLevel",
                                    newValue
                                  );
                                }}
                                onInputChange={() => {
                                  if (JobPostValidation["experienceLevel"])
                                    delete JobPostValidation["experienceLevel"];
                                }}
                                onCreateOption={handleCreateExperienceLevel}
                                isClearable
                                isMulti
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation.experienceLevel?.message
                                ? `Experience level ${JobPostValidation.experienceLevel?.message}`
                                : ""}
                            </small>
                          </div>

                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Must Have Skills
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={mustSkill}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      JobPostValidation?.mustHaveSkill
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.mustHaveSkill
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose must have skills"
                                isClearable
                                isMulti
                                value={
                                  getParticularJob?.mustHaveSkill
                                    ? getParticularJob?.mustHaveSkill?.map(
                                        (v) => {
                                          return {
                                            value: v.name ? v.name : v.label,
                                            label: v.name ? v.name : v.label,
                                            dataId: v.masterDataId
                                              ? v.masterDataId
                                              : v.dataId,
                                          };
                                        }
                                      )
                                    : []
                                }
                                onInputChange={() => {
                                  if (JobPostValidation["mustHaveSkill"])
                                    delete JobPostValidation["mustHaveSkill"];
                                }}
                                onChange={(newValue) => {
                                  setGetParticularJobValue(
                                    "mustHaveSkill",
                                    newValue
                                  );
                                }}
                                onCreateOption={handleCreateMustSkill}
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.mustHaveSkill?.message
                                ? `Must skill ${JobPostValidation?.mustHaveSkill?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Nice To Have Skills
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={needSkill}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      JobPostValidation?.niceTohaveSkill
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.niceTohaveSkill
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose nice to have skills"
                                isClearable
                                isMulti
                                value={
                                  getParticularJob?.niceTohaveSkill
                                    ? getParticularJob?.niceTohaveSkill?.map(
                                        (v) => {
                                          return {
                                            value: v.name ? v.name : v.label,
                                            label: v.name ? v.name : v.label,
                                            dataId: v.masterDataId
                                              ? v.masterDataId
                                              : v.dataId,
                                          };
                                        }
                                      )
                                    : []
                                }
                                onChange={(newValue) => {
                                  setGetParticularJobValue(
                                    "niceTohaveSkill",
                                    newValue
                                  );
                                }}
                                onInputChange={() => {
                                  if (JobPostValidation["niceTohaveSkill"])
                                    delete JobPostValidation["niceTohaveSkill"];
                                }}
                                onCreateOption={handleCreateNeedSkill}
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.niceTohaveSkill?.message
                                ? `Nice skill ${JobPostValidation?.niceTohaveSkill?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              style={{ zIndex: "1" }}
                            >
                              Salary Range
                            </label>
                            <div className="input-group">
                              <div className="row">
                                <div className="col-4">
                                  <input
                                    autoComplete="off"
                                    type="number"
                                    className="form-control"
                                    aria-label="Text input with segmented dropdown button"
                                    value={getParticularJob?.salaryMin}
                                    aria-invalid={Boolean(
                                      JobPostValidation?.salaryMin?.status ===
                                        false
                                    )}
                                    name="salaryMin"
                                    onChange={(e) =>
                                      setGetParticularJobValue(
                                        "salaryMin",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      setValidationValue(
                                        "salaryMin",
                                        NumberValidation(e.target.value)
                                      );
                                    }}
                                  />
                                  <small className="text-danger">
                                    {JobPostValidation?.salaryMin?.message
                                      ? `Minimum salary ${JobPostValidation?.salaryMin?.message}`
                                      : ""}
                                  </small>
                                </div>
                                <div className="col-4">
                                  <input
                                    autoComplete="off"
                                    type="number"
                                    className="form-control"
                                    aria-label="Text input with segmented dropdown button"
                                    name="salaryMax"
                                    aria-invalid={Boolean(
                                      JobPostValidation?.salaryMax?.status ===
                                        false
                                    )}
                                    value={getParticularJob?.salaryMax}
                                    onChange={(e) =>
                                      setGetParticularJobValue(
                                        "salaryMax",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      setValidationValue(
                                        "salaryMax",
                                        NumberValidation(e.target.value)
                                      );
                                    }}
                                  />
                                  <small className="text-danger">
                                    {JobPostValidation?.salaryMax?.message
                                      ? `Maximum salary ${JobPostValidation?.salaryMax?.message}`
                                      : ""}
                                  </small>
                                </div>
                                <div className="col-4">
                                  <div className="input-group-append">
                                    <select
                                      className="custom-select"
                                      style={{
                                        height: "45px",
                                        borderRadius: "0px 0.25rem 0.25rem 0px",
                                        border: `${
                                          JobPostValidation?.salaryRangeByValue
                                            ?.status === false
                                            ? "1.5px solid red"
                                            : ""
                                        }`,
                                      }}
                                      aria-invalid={Boolean(
                                        JobPostValidation?.salaryRangeByValue
                                          ?.status === false
                                      )}
                                      value={
                                        getParticularJob?.salaryRangeByValue
                                      }
                                      onChange={(e) => {
                                        setGetParticularJobValue(
                                          "salaryRangeByValue",
                                          e.target.value
                                        );
                                      }}
                                      id="inputGroupSelect01"
                                    >
                                      <option>Select Range Type</option>
                                      {salaryRange?.map((e, index) => (
                                        <option
                                          key={index}
                                          value={e?.masterDataId}
                                        >
                                          {e.value}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <small className="text-danger">
                                    {JobPostValidation?.salaryRangeByValue
                                      ?.message
                                      ? `Salary range ${JobPostValidation?.salaryRangeBy?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    seteditJobModal(false);
                    ClearFields();
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn"
                  onClick={() => {
                    ContinueValidation();
                  }}
                >
                  Continue
                </button>
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                  onClick={() => {
                    PublishValidation(getParticularJob?.jobId);
                  }}
                >
                  Publish
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={editJob2Modal}
        onClickOutside={() => {
          seteditJob2Modal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">Post Job</h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-10">
                  <form>
                    <div className="form-container pt-4 py-1">
                      <div className="row ">
                        <div className="col-lg-6 px-5 pb-4 border-right">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Highest Qualification
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={educationalQualification}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      JobPostValidation?.highestQualication
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.highestQualication
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose your qualification"
                                isClearable
                                value={
                                  getParticularJob?.highestQualication
                                    ? {
                                        value: getParticularJob
                                          ?.highestQualication?.name
                                          ? getParticularJob?.highestQualication
                                              ?.name
                                          : getParticularJob?.highestQualication
                                              ?.label,
                                        label: getParticularJob
                                          ?.highestQualication?.name
                                          ? getParticularJob?.highestQualication
                                              ?.name
                                          : getParticularJob?.highestQualication
                                              ?.label,
                                        dataId:
                                          getParticularJob?.highestQualication
                                            ?.masterDataId,
                                      }
                                    : {}
                                }
                                onChange={(newValue) => {
                                  setGetParticularJobValue(
                                    "highestQualication",
                                    newValue
                                  );
                                }}
                                onInputChange={() => {
                                  if (JobPostValidation["highestQualication"])
                                    delete JobPostValidation[
                                      "highestQualication"
                                    ];
                                }}
                                onCreateOption={
                                  handleCreateEducationalQualification
                                }
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.highestQualication?.message
                                ? `Education ${JobPostValidation?.highestQualication?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="google-input mt-5">
                            <input
                              autoComplete="off"
                              type="text"
                              id="template-input"
                              maxLength={3}
                              placeholder="Enter no of position"
                              aria-invalid={Boolean(
                                JobPostValidation?.noOfPosition?.status ===
                                  false
                              )}
                              value={getParticularJob?.noOfPosition}
                              onChange={(e) =>
                                setGetParticularJobValue(
                                  "noOfPosition",
                                  e.target.value
                                )
                              }
                              onBlur={(e) => {
                                setValidationValue(
                                  "noOfPosition",
                                  NumberValidation(e.target.value)
                                );
                              }}
                            />
                            <label htmlFor="template-input">
                              No of position
                            </label>
                            <small className="text-danger">
                              {JobPostValidation?.noOfPosition?.message
                                ? `No of Position ${JobPostValidation?.noOfPosition?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-6 px-5 pb-4">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Employer Benefits
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={employerBenefits}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      JobPostValidation?.employerBenefits
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.employerBenefits
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose employer benefits"
                                isClearable
                                isMulti
                                value={
                                  getParticularJob?.employerBenefits
                                    ? getParticularJob?.employerBenefits?.map(
                                        (v) => {
                                          return {
                                            value: v.name ? v.name : v.label,
                                            label: v.name ? v.name : v.label,
                                            dataId: v.masterDataId
                                              ? v.masterDataId
                                              : v.dataId,
                                          };
                                        }
                                      )
                                    : []
                                }
                                onChange={(newValue) => {
                                  setGetParticularJobValue(
                                    "employerBenefits",
                                    newValue
                                  );
                                }}
                                onInputChange={() => {
                                  if (JobPostValidation["employerBenefits"])
                                    delete JobPostValidation[
                                      "employerBenefits"
                                    ];
                                }}
                                onCreateOption={handleCreateEmployerBenefits}
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.employerBenefits?.message
                                ? `Employer benifit ${JobPostValidation?.employerBenefits?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modalAnswer col-lg-10 mt-2 pt-5 px-5 ml-2">
                  <label htmlFor="template-input" className="z-index-value">
                    Job Description
                  </label>
                  <div className="modalDisapprove-details d-flex  justify-content-end ">
                    <button
                      type="button"
                      className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                    >
                      Rich Text Editor
                      <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                        lock
                      </span>
                    </button>
                  </div>

                  <div className="form-group">
                    <textarea
                      aria-invalid={Boolean(
                        JobPostValidation?.jobDescription?.status === false
                      )}
                      className="form-control rounded-0 modalDisapprove-textarea"
                      id="exampleFormControlTextarea2"
                      rows="7"
                      value={getParticularJob?.jobDescription}
                      onChange={(e) =>
                        setGetParticularJobValue(
                          "jobDescription",
                          e.target.value
                        )
                      }
                      onBlur={(e) => {
                        setValidationValue(
                          "jobDescription",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    ></textarea>
                  </div>
                  <small className="text-danger">
                    {JobPostValidation?.jobDescription?.message
                      ? `Description ${JobPostValidation?.jobDescription?.message}`
                      : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    seteditJob2Modal(false);
                    ClearFields();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-light otp-btn postjob-btn mx-4"
                  onClick={() => {
                    BackValidation();
                  }}
                >
                  Back
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                  onClick={() => {
                    FinalPublishValidation(getParticularJob?.jobId);
                  }}
                >
                  Publish
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        open={ClosingModal}
        onClickOutside={() => {
          setClosingModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Reason of Closing
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-5">
                  <div className="google-input">
                    <label htmlFor="template-input" className="z-index-value">
                      Reason
                    </label>
                    <div className="dropdown-container">
                      <Select
                        options={optionList}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "6px",
                          zIndex: 3,
                          colors: {
                            ...theme.colors,
                            primary25: "#DFF1F1",
                            primary: "#00ABAD",
                          },
                        })}
                        styles={{
                          menu: (provided, state) => ({
                            ...provided,
                            zIndex: "5 !important",
                          }),
                        }}
                        placeholder="Choose your reason"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isMulti
                      />
                    </div>
                  </div>
                  <div className="modalAnswer mt-2">
                    <label for="exampleFormControlTextarea2">
                      Reason Details
                    </label>
                    <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button
                        type="button"
                        className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                      >
                        Rich Text Editor
                        <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                          lock
                        </span>
                      </button>
                    </div>

                    <div className="form-group">
                      <textarea
                        className="form-control rounded-0 modalDisapprove-textarea"
                        id="exampleFormControlTextarea2"
                        rows="7"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setClosingModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={closeJobPost}
        onClickOutside={() => {
          setCloseJobPost(false);
          setReasonModalValidation({});
        }}
      >
        <div className="modal-content w-75  m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px;",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <div className="google-input">
                    <label htmlFor="template-input" className="z-index-value">
                      Reason
                    </label>
                    <div className="dropdown-container">
                      <Select
                        options={reason}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "6px",
                          zIndex: 3,
                          colors: {
                            ...theme.colors,
                            primary25: "#DFF1F1",
                            neutral20:
                              reasonModalValidation?.reason?.status === false
                                ? "red"
                                : "#00ABAD", // border color
                            primary:
                              reasonModalValidation?.reason?.status === false
                                ? "red"
                                : "#00ABAD", // border color when focus
                          },
                        })}
                        styles={{
                          menu: (provided, state) => ({
                            ...provided,
                            zIndex: "5 !important",
                          }),
                        }}
                        placeholder="Choose your reason"
                        value={closeReason.reason}
                        onChange={(e) => {
                          
                          setCloseReasonValue("reason", e);
                        }}
                      />
                    </div>
                  </div>
                  <small className="text-danger">
                    {reasonModalValidation?.reason?.message
                      ? `Reason ${reasonModalValidation?.reason?.message}`
                      : ""}
                  </small>
                </div>
              </div>
              <div className="d-flex justify-content-center border-0 mb-3">
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                  onClick={() => {
                    setCloseJobPost(false);
                    setReasonModalValidation({});
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  style={{ padding: "8px 24px" }}
                  className="btn btn-danger my-2 my-sm-0 mr-2 "
                  onClick={() => {
                    handleCloseJobPost(selectedJobId);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
