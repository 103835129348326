import { Cancel } from "@mui/icons-material";
import CropIcon from "@mui/icons-material/Crop";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Slider,
  Typography,
} from "@mui/material";
import React, { useEffect, useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import { useSelector } from "react-redux";
import {
  GetCompanyDetail,
  UploadDocument,
} from "../Api/Api";
import getCroppedImg from "./cropImage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CropEasy = ({
  photoURL,
  setOpenCrop,
  field,
  uploadCompanyUrl,
  setFile,
  fileName,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  // state values
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const companyId = state?.auth?.auth?.userDetails?.companyId;

  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };
  
  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const fetchCompanyDetails = useCallback(async () => {
    if (!authKey || !companyId) return;

    const getCompanyDetails = await GetCompanyDetail(authKey, companyId);
    if (getCompanyDetails && getCompanyDetails.statusCode === 200) {
      console.log("Successfully fetched company details");
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchCompanyDetails();
  }, [fetchCompanyDetails]);

  const cropImage = async () => {
    if(field === "companyLogo"){
      try {
        const { file } = await getCroppedImg(
          photoURL,
          croppedAreaPixels,
          rotation
        );
  
        setFile(file);
        let formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", fileName);
        formData.append("path", "resume/");
        let response = await UploadDocument(formData);
        if (response && response.statusCode === 200) {
        
          uploadCompanyUrl(response.data,"companyLogo");
          showToastSuccessMsg(response.message);
        
        } else {
          showToastErrorMsg(
            response.message || response.error.response.data.message
          );
        }
        setOpenCrop(false);
      } catch (error) {
        console.log(error);
      }
    }
    else if(field === "bannerImage"){
      try {
        const { file } = await getCroppedImg(
          photoURL,
          croppedAreaPixels,
          rotation
        );
  
        setFile(file);
        let formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", fileName);
        formData.append("path", "resume/");
        let response = await UploadDocument(formData);
        if (response && response.statusCode === 200) {
        
          uploadCompanyUrl(response.data,"bannerImage");
          showToastSuccessMsg(response.message);
        
        } else {
          showToastErrorMsg(
            response.message || response.error.response.data.message
          );
        }
        setOpenCrop(false);
      } catch (error) {
        console.log(error);
      }
    }
   
      else if(field === "profileImage"){
        try {
          const { file } = await getCroppedImg(
            photoURL,
            croppedAreaPixels,
            rotation
          );
    
          setFile(file);
          let formData = new FormData();
          formData.append("file", file);
          formData.append("fileName", fileName);
          formData.append("path", "resume/");
          let response = await UploadDocument(formData);
          if (response && response.statusCode === 200) {
          
            uploadCompanyUrl(response.data,"profileImage");
            showToastSuccessMsg(response.message);
          
          } else {
            showToastErrorMsg(
              response.message || response.error.response.data.message
            );
          }
          setOpenCrop(false);
        } catch (error) {
          console.log(error);
        }
      }
      else if(field === "gallery"){
        try {
          const { file } = await getCroppedImg(
            photoURL,
            croppedAreaPixels,
            rotation
          );
    
          setFile(file);
          let formData = new FormData();
          formData.append("file", file);
          formData.append("fileName", fileName);
          formData.append("path", "resume/");
          let response = await UploadDocument(formData);
          if (response && response.statusCode === 200) {
          
            uploadCompanyUrl(response.data,"gallery");
            showToastSuccessMsg(response.message);
          
          } else {
            showToastErrorMsg(
              response.message || response.error.response.data.message
            );
          }
          setOpenCrop(false);
        } catch (error) {
          console.log(error);
        }
      }
      else if(field === "profileImage"){
        try {
          const { file } = await getCroppedImg(
            photoURL,
            croppedAreaPixels,
            rotation
          );
    
          setFile(file);
          let formData = new FormData();
          formData.append("file", file);
          formData.append("fileName", fileName);
          formData.append("path", "resume/");
          let response = await UploadDocument(formData);
          if (response && response.statusCode === 200) {
          
            uploadCompanyUrl(response.data,"gallery");
            showToastSuccessMsg(response.message);
          
          } else {
            showToastErrorMsg(
              response.message || response.error.response.data.message
            );
          }
          setOpenCrop(false);
        } catch (error) {
          console.log(error);
        }
      }
  };


  return (
    <div className="d-flex align-items-center flex-column" 
    // style={{backgroundClor:"black",border:"1px solid black",width:"700px",height:"600px"}}
    >
      <DialogContent
        dividers
        sx={{
          background: "#333",
          position: "relative",
          height: 400,
          width: "auto",
          minWidth: { sm: 500 },
        }}
      >
        <Cropper
          image={photoURL}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
        />
      </DialogContent>
      <DialogActions sx={{ flexDirection: "column", mx: 3, my: 2 }}>
        <Box sx={{ width: "100%", mb: 1 }}>
          <Box>
            <Typography>Zoom: {zoomPercent(zoom)}</Typography>
            <Slider
              valueLabelDisplay="auto"
              valueLabelFormat={zoomPercent}
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </Box>
          <Box>
            <Typography>Rotation: {rotation + "°"}</Typography>
            <Slider
              valueLabelDisplay="auto"
              min={0}
              max={360}
              value={rotation}
              onChange={(e, rotation) => setRotation(rotation)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="outlined"
            startIcon={<Cancel />}
            onClick={() => setOpenCrop(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            startIcon={<CropIcon />}
            onClick={cropImage}
          >
            Crop
          </Button>
        </Box>
      </DialogActions>
      </div>
  );
};

export default CropEasy;

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};
