import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchAllEmployersList, UpdateBasicDetail } from "../../Api/Api";
import CustomModal from "../../CustomModal";
import { GetInitials } from "../../Store/Constants";
import Roboimg2 from "../../../images/roboimg-outerborder.png";
import { NonEmptyValidation } from "../../Store/validate";
const Approval = (props) => {
  const { showToastError, showToastSuccess, type } = props;
  const state = useSelector((state) => state);
  const [usersList, setUsersList] = useState([]);
  const [rejectionModalStatus, setRejectionModalStatus] = useState(false);
  const [rejectionData, setRejectionData] = useState({});
  const [rejectionDataValidation, setRejectionDataValidation] = useState({});
  const authKey = state?.auth?.auth?.authKey;
  const companyId = state?.auth?.auth?.userDetails?.companyId;

  const fetchDatas = useCallback(async () => {
    if (!companyId || !companyId) return;

    const response = await fetchAllEmployersList(
      authKey,
      companyId
    );
    if (response && response.statusCode === 200) {
      let filteredDatas = [];
      if (type === "Pending") {
        filteredDatas = response.data.filter(
          (value) => value.status === "Inactive" && value.approved === false
        );
      } else if (type === "Approved") {
        filteredDatas = response.data.filter(
          (value) => value.status === "Active" && value.approved === true
        );
      } else if (type === "Rejected") {
        filteredDatas = response.data.filter(
          (value) => value.status === "Active" && value.approved === false
        );
      }
      setUsersList(filteredDatas);
    } else {
      setUsersList([]);
    }
  }, [type, authKey, companyId]);
  
  const onChangeRejectionData = (key, value) => {
    setRejectionData({ ...rejectionData, [key]: value });
    if (rejectionDataValidation[key]) delete rejectionDataValidation[key];
  };
  const onChangeRejectionDataValidation = (key, value) => {
    setRejectionDataValidation({ ...rejectionDataValidation, [key]: value });
  };

  useEffect(() => {
    fetchDatas();
    return () => { };
  }, [type, fetchDatas]);
  const updateUserStatus = async (companyProfileId, status, approved) => {
    const Payload = {
      companyProfileId,
      status,
      approved,
      employerApproveOrReject: true,
    };
    const token = state?.auth?.auth?.authKey;
    const response = await UpdateBasicDetail(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccess("User Approved");
      fetchDatas();
    } else {
      showToastError(response.message || response.error.response.data.message);
    }
  };
  const handleReject = async (e) => {
    e.preventDefault();
    const valid = NonEmptyValidation(rejectionData.rejectedReason);
    onChangeRejectionDataValidation("rejectedReason", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const response = await UpdateBasicDetail(rejectionData, token);
      if (response && response.statusCode === 200) {
        showToastSuccess("User Rejected");
        fetchDatas();
        setRejectionData({});
        setRejectionModalStatus(false);
        e.target.reset();
      } else {
        showToastError(
          response.message || response.error.response.data.message
        );
      }
    }
  };
  return (
    <div>
      <div className="row m-1">
        {usersList && usersList.length
          ? usersList.map((user, index) => {
            return (
              <div key={index} className="col-md-3">
                <div className="jobs-content">
                  <div className="resume-details">
                    <div className="resume-details-content row">
                      <div className="profile-name mx-2 profile-name d-flex align-items-center justify-content-center mx-2">
                        <div className="banner-profile-dashboard2">
                          {user.profileImage ? (
                            <img
                              className="PP-upload-img"
                              alt=""
                              src={user.profileImage}
                              hidden={!user.profileImage}
                            />
                          ) : (
                            <h4>{GetInitials(user.fullName)}</h4>
                          )}
                        </div>
                      </div>
                      <div className="profile-name-content mx-2 my-auto ">
                        <h6 className="font-weight-bold">{user.fullName}</h6>
                        <small className="mb-0">{user.officalEmail}</small>
                      </div>
                    </div>
                    <div className="mt-3">
                      {user.genderName && (
                        <div className="mt-1">
                          <p className="mb-1 font-weight-bold">Gender</p>
                          {user.genderName}
                        </div>
                      )}
                      {user.phoneNumber && (
                        <div className="mt-1">
                          <p className="mb-1 font-weight-bold">Mobile</p>
                          {user.phoneNumber}
                        </div>
                      )}
                    </div>


                    {type === "Pending" && (
                      <div className="row mt-3">
                        <div className="col-6">
                          <button
                            className="btn btn-success text-success postjob-btn"
                            onClick={() => {
                              updateUserStatus(
                                user.companyProfileId,
                                "Active",
                                true
                              );
                            }}
                          >
                            Approve
                          </button>
                        </div>

                        <div className="col-6">
                          <button
                            className="btn btn-danger text-danger postjob-btn"
                            onClick={() => {
                              setRejectionModalStatus(true);
                              setRejectionData({
                                ...rejectionData,
                                ...{
                                  companyProfileId: user.companyProfileId,
                                  status: "Active",
                                  approved: false,
                                  employerApproveOrReject: false,
                                },
                              });
                            }}
                          >
                            Reject
                          </button>
                        </div>
                      </div>
                    )}
                    {type === "Approved" && user?.role !== "Admin" && (
                      <div className="col-6 mt-3">
                        <button
                          className="btn  btn-light rejected-btn"
                          onClick={() => {
                            setRejectionModalStatus(true);
                            setRejectionData({
                              ...rejectionData,
                              ...{
                                companyProfileId: user.companyProfileId,
                                status: "Active",
                                approved: false,
                                employerApproveOrReject: false,
                              },
                            });
                          }}
                        >
                          Reject
                        </button>
                      </div>
                    )}
                    {type === "Rejected" && (
                      <div className="col-6 mt-3">
                        <button
                          className="btn btn-success text-success postjob-btn"
                          onClick={() => {
                            updateUserStatus(
                              user.companyProfileId,
                              "Active",
                              true
                            );
                          }}
                        >
                          Approve
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })
          : null}
      </div>
      <CustomModal
        open={rejectionModalStatus}
        onClickOutside={() => {
          setRejectionModalStatus(false);
        }}
      >
        <div className="modal-content w-75  m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px;",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <form
              className="container"
              onSubmit={(e) => {
                handleReject(e);
              }}
            >
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <h3 className="font-weight-bold text-center">
                    Are You Sure?
                  </h3>
                  <p className=" text-center">
                    Do you really want to Reject this Employer?
                  </p>
                  <div className="google-input">
                    <textarea
                      autoComplete="off"
                      type="text"
                      id="template-input"
                      className="w-100 p-3"
                      aria-invalid={Boolean(
                        rejectionDataValidation?.rejectedReason?.status ===
                        false
                      )}
                      placeholder={`Enter Description`}
                      defaultValue={rejectionData?.rejectedReason}
                      onChange={(e) => {
                        onChangeRejectionData("rejectedReason", e.target.value);
                      }}
                      onBlur={(e) => {
                        onChangeRejectionDataValidation(
                          "rejectedReason",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    />
                    <label htmlFor="template-input">Description</label>
                  </div>
                  <small className="text-danger">
                    {rejectionDataValidation?.rejectedReason?.message
                      ? `Description ${rejectionDataValidation?.rejectedReason?.message}`
                      : ""}
                  </small>
                </div>
              </div>
              <div className="d-flex justify-content-center border-0 mb-3">
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                  onClick={() => {
                    setRejectionModalStatus(false);
                    setRejectionData({});
                    setRejectionDataValidation({});
                  }}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  style={{ padding: "8px 24px" }}
                  className="btn login-btn my-2 my-sm-0 mr-2 "
                >
                  Reject
                </button>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default Approval;
