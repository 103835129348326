import axios from 'axios';
import {
  url,
} from '../BaseUrl';

export const getFeatureFlags = async () => {
  const response = await axios
    .get(`${url}/api/v1/feature_flags`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
  return response;
};