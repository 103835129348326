import React from "react";
import Navbar from "./navbar";
import Roboimg from "../images/roboimg.png";

export default function ComingSoon() {
  return (
    <div>
      <Navbar />
      <div className="Banner d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="my-3 p-4 text-center">
                <img className="" src={Roboimg} alt="" />
                <h1 className="font-weight-bold my-4 text-light">
                  Coming Soon :)
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
