import axios from "axios";
import {
  url,
  url_company_details,
  url_job_details,
  url_master_details,
  url_document_upload,
} from "./BaseUrl";

const APIBaseUrl = url + "/api/v1/userinfo";
const APIBaseUrlCompanyDetails = url_company_details + "/api/v1/companyinfo";
const APIBaseUrlJobDetails = url_job_details + "/api/v1/jobinfo";
const APIBaseUrlMasterDetails = url_master_details + "/api/v1/masterdata";

// Send Otp
export const SendOtp = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/sendOtp`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Verify Otp
export const VerifyOtp = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/verifyOtp`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//RESEND
export const ReSendOtp = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/resendOtp`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
// create User Account
export const CreateUserAccount = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/createUserAccount`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//CHANGE PASSWORD
export const ChangePassword = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/changePassword?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UserLogin = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/userLogin`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const Logout = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/logout?authKey=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//get gender

//get all must skill
export const GetMasterDatasByType = async (Query) => {
  const response = await axios
    .get(`${APIBaseUrlMasterDetails}/findAllMasterData?${Query}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Basic Details

export const UpdateBasicDetail = async (Payload, token) => {
  const response = await axios
    .put(
      `${APIBaseUrlCompanyDetails}/updateCompanyBasicDetails?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Get Basic Details

export const GetBasicDetails = async (token, companyProfileId) => {
  const response = await axios
    .get(
      `${APIBaseUrlCompanyDetails}/findParticularDetails?companyProfileId=${companyProfileId}&authKey=${token}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Find Company Details

export const GetCompanyDetail = async (token, companyId) => {
  const response = await axios
    .get(
      `${APIBaseUrlCompanyDetails}/findCompanyDetails?companyId=${companyId}&authKey=${token}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Company Details Update

export const UpdateCompanyDetail = async (Payload, token) => {
  const response = await axios
    .put(
      `${APIBaseUrlCompanyDetails}/updateCompanyDetails?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Gallery

export const CreateGallery = async (Payload, token) => {
  const response = await axios
    .post(
      `${APIBaseUrlCompanyDetails}/createGallery?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Get Gallery Image

export const GetGallery = async (token, companyId, sort) => {
  const response = await axios
    .get(
      `${APIBaseUrlCompanyDetails}/findAllGallery?companyId=${companyId}&sortBy=${sort}&authKey=${token}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Delete Gallery image

export const DeleteGallery = async (Payload, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrlCompanyDetails}/deleteParticularImage?authKey=${token}`,
      { data: Payload },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// employee flow
//RESEND
export const ReSendotp = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/resendOtp`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
// password update
export const UpdatePassword = async (Payload) => {
  const response = await axios
    .put(`${APIBaseUrl}/forgotPasswordUpdatePassword`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create Job Post

export const CreateJobPost = async (Payload, token) => {
  const response = await axios
    .post(
      `${APIBaseUrlJobDetails}/createJobPostDetails?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Get Job Post

export const GetJobPost = async (token, companyId) => {
  const response = await axios
    .get(
      `${APIBaseUrlJobDetails}/findAllJobDetails?companyProfileId=${companyId}&authKey=${token}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Get Single Job Post

export const GetParticularJob = async (token, jobId, userProfileId) => {
  const response = await axios
    .get(
      `${APIBaseUrlJobDetails}/findParticularJobDetails?jobId=${jobId}&authKey=${token}&userProfileId=${userProfileId}`,

      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Update Job Post

export const UpdateJobPost = async (Payload, token) => {
  const response = await axios
    .put(
      `${APIBaseUrlJobDetails}/updateParticularJobDetails?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Job Listing Post

export const ListingJobPost = async (Payload, token) => {
  const response = await axios
    .post(
      `${APIBaseUrlJobDetails}/employerSideJobListing?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Skill Api Integration
//create skill

export const CreateEmployeeSkills = async (Payload, token) => {
  const response = await axios
    .post(
      `${APIBaseUrlMasterDetails}/createMasterData?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//create skill post

export const CreateEmployeeSkillsPost = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/addSkill?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get skill post

export const GetEmployeeSkillsPost = async (token, profileId) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findAllSkill?userProfileId=${profileId}&authKey=${token}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Employee flow employment details api

//post employment details
export const CreateEmploymentDetailsPost = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createEmploymentDetails?&authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get all employment details
export const GetEmploymentDetailsPost = async (token, userId) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findAllEmploymentDetails?userProfileId=${userId}&authKey=${token}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get particular employment details
export const GetParticularEmploymentDetails = async (
  token,
  userEmploymentDetailId
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findParticularEmploymentDetails?userEmploymentDetailId=${userEmploymentDetailId}&authKey=${token}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//delete employment details
export const DeleteEmploymentDetails = async (Payload, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/deletEmploymentDetails?&authKey=${token}`,
      { data: Payload },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//post cover letter
export const CreateCoverLetter = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createCoverLetter?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get all cover letter
export const GetAllCoverLetter = async (token, userId) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findAllCoverLetter?userProfileId=${userId}&authKey=${token}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//delete cover letter
export const DeleteCoverLetter = async (Payload, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/deleteCoverLetter?&authKey=${token}`,
      { data: Payload },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//update cover letter

export const UpdateCoverLetter = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateCoverLetter?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

///////////////////////////////////////////////////////////////////////////////////////////////////////

//post certification

export const CreateCertificationPost = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createCertification?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get all certification

export const GetAllCertification = async (userId, token) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findAllCertifications?userProfileId=${userId}&authKey=${token}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get particular certification

export const GetParticularCertification = async (
  userCertificationId,
  authKey
) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findParticularCertification?userCertificationId=${userCertificationId}&authKey=${authKey}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
export const UpdateCertificationPost = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateCertification?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//delete certification
export const DeleteCertification = async (Payload, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/deleteCertification?&authKey=${token}`,
      { data: Payload },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// GET ALL LIST OF CHAT ROOMS
export const GetListOfChatRooms = async (Query) => {
  const response = await axios
    .get(`${url}/api/v1/userinfo/findAllChatRoom?${Query}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// GET LIST OF MESSAGES IN CHAT
export const GetListOfMessages = async (Query) => {
  const response = await axios
    .get(`${url}/api/v1/userinfo/findAllMessage?roomId=${Query}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Document/Image Upload
export const UploadDocument = async (Payload) => {
  const response = await axios
    .post(
      `${url_document_upload}/api/v1/documentmanagement/fileUpload`,
      Payload,
      {
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// ADMIN

export const GetAllJobUsersListByType = async (Query) => {
  const response = await axios
    .get(`${url}/api/v1/userinfo/findAllUserByType?${Query}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//create company name

export const CreateCompanyName = async (Payload) => {
  const response = await axios
    .post(`${APIBaseUrlCompanyDetails}/createCompany`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get company name

export const GetCompanyName = async () => {
  const response = await axios
    .get(`${APIBaseUrlCompanyDetails}/findAllCompany`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const UpdatePersonDetails = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updatePersonDetails?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//FAQS
//get all
export const GetFAQDetails = async (id, token) => {
  const response = await axios
    .get(
      `${APIBaseUrlCompanyDetails}/findAllfAq?companyId=${id}&authKey=${token}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//create
export const CreateFAQDetails = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrlCompanyDetails}/createFaq?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//delete
export const DeleteFAQDetails = async (Payload, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrlCompanyDetails}/deleteFaq?authKey=${token}`,
      { data: Payload },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//find
export const GetFAQ = async (id, token) => {
  const response = await axios
    .get(`${APIBaseUrlCompanyDetails}/findFaq?faqId=${id}&authKey=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//update
export const UpdateFAQDetails = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrlCompanyDetails}/updateFaq?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//get label for faq

export const GetLabel = async (token, restrict, id) => {
  const response = await axios
    .get(
      `${APIBaseUrlMasterDetails}/findAllMasterData?authKey=${token}&masterDataType=label&status=Active&restrictionLevel=${restrict}&companyId=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Reviews
//create
export const CreateReviewDetails = async (Payload, token) => {
  const response = await axios
    .post(
      `${APIBaseUrlCompanyDetails}/createReview?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//get all
export const GetReviewDetails = async (companyId, token, sort) => {
  // &sortBy=
  const response = await axios
    .get(
      `${APIBaseUrlCompanyDetails}/findAllreview?companyId=${companyId}&sortBy=${sort}&authKey=${token}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//update
export const UpdateReviewDetails = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrlCompanyDetails}/updateReview?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get reason

export const GetReason = async (token, restrict, id) => {
  const response = await axios
    .get(
      `${APIBaseUrlMasterDetails}/findAllMasterData?authKey=${token}&masterDataType=reviewReason&status=Active&restrictionLevel=${restrict}&companyId=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//delete
export const DeleteReviewDetails = async (Payload, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrlCompanyDetails}/deleteReview?authKey=${token}`,
      { data: Payload },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//company name change ticket raise

export const CreateTicket = async (Payload, token) => {
  const response = await axios
    .post(
      `${APIBaseUrlCompanyDetails}/createTicketDetails?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//multipleFileUpload

//Document/Image Upload
export const MultipleFileUpload = async (Payload) => {
  const response = await axios
    .post(
      `${url_document_upload}/api/v1/documentmanagement/multipleFileUpload`,
      Payload,
      {
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get all
export const GetAllJobPost = async (token, userProfileId, status) => {
  const response = await axios
    .get(
      `${APIBaseUrlJobDetails}/employeeJobListing?authKey=${token}&userProfileId=${userProfileId}&status=${status}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//employee flow apply job

export const ApplyJobEmployee = async (Payload, token) => {
  const response = await axios
    .post(
      `${APIBaseUrlJobDetails}/createAppliedJob?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//employee flow resume post

export const ResumePost = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createResume?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get all resume
export const GetAllResume = async (token, userProfileId) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findAllResume?authKey=${token}&userProfileId=${userProfileId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get all applied jobs
export const GetAllAppliedJos = async (token, jobId, status) => {
  const response = await axios
    .get(
      `${APIBaseUrlJobDetails}/findAllAppliedJobByStatus?authKey=${token}&jobId=${jobId}&status=${status}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//get all applied jobs
export const GetWaitingAppliedJobs = async (token, jobId) => {
  const response = await axios
    .get(
      `${APIBaseUrlJobDetails}/findAllAppliedJobByStatus?authKey=${token}&jobId=${jobId}&status=Waiting`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get particular applied jobs
export const GetParticularAppliedJobs = async (
  token,
  appliedId,
  userprofileId
) => {
  const response = await axios
    .get(
      `${APIBaseUrlJobDetails}/findAppliedJob?authKey=${token}&appliedId=${appliedId}&userprofileId=${userprofileId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Delete Resume

export const DeleteResume = async (Payload, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/deleteResume?authKey=${token}`,
      { data: Payload },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//update particular personal details
export const UpdatePesonalDetails = async (token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updatePersonDetails?authKey=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get particular personal details
export const GetPersonalDetails = async (token, userProfileId) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findParticularPersonDetails?authKey=${token}&userProfileId=${userProfileId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//employer flow active log post

export const ActiveLogPost = async (Payload, token) => {
  const response = await axios
    .post(
      `${APIBaseUrlJobDetails}/createJobActiveLogDetails?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Q&A
//get all
export const GetQADetails = async (companyId, token, sort, answerSort) => {
  // &sortBy=
  const response = await axios
    .get(
      `${APIBaseUrlCompanyDetails}/findAllQuestion?companyId=${companyId}&sortBy=${sort}&authKey=${token}&start=0&end=25&answersortBy=${answerSort}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//get
//find question
export const GetParticularQA = async (id, token) => {
  const response = await axios
    .get(
      `${APIBaseUrlCompanyDetails}/findQuestion?questionId=${id}&authKey=${token}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//create
export const CreateAnswer = async (Payload, token) => {
  const response = await axios
    .post(
      `${APIBaseUrlCompanyDetails}/createAnswer?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//find answer
export const GetParticularAnswer = async (id, token) => {
  const response = await axios
    .get(
      `${APIBaseUrlCompanyDetails}/findAnswer?answerId=${id}&authKey=${token}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//update answer
export const UpdateAnswer = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrlCompanyDetails}/updateAnswer?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//employer Update Applied Job

export const UpdateAppliedJobStatus = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrlJobDetails}/UpdateAppliedJob?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
// Delete Existing Skill

export const DeleteSkill = async (Payload, token) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/deleteSkill?authKey=${token}`,
      { data: Payload },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
//create like & dislike
export const CreateLikeDislike = async (Payload, token) => {
  const response = await axios
    .post(
      `${APIBaseUrlCompanyDetails}/createQADetails?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//create greeting msg
export const CreateGreeting = async (Payload, token) => {
  const response = await axios
    .post(
      `${APIBaseUrlJobDetails}/createGreetingDetails?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get all greetings
export const GetAllGreeting = async (token, userProfileId) => {
  const response = await axios
    .get(
      `${APIBaseUrlJobDetails}/findAllGreetingDetails?authKey=${token}&userProfileId=${userProfileId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get particular greetings
export const GetParticularGreeting = async (token, greetingId) => {
  const response = await axios
    .get(
      `${APIBaseUrlJobDetails}/findGreetingDetails?authKey=${token}&greetingId=${greetingId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
// update Employment Details

export const UpdateEmploymentDetails = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateEmploymentDetails?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//create chat room
export const CreateChatroom = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createChatRoom?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get activity log
export const GetActivityLog = async (token, jobId, jobSeekerId) => {
  const response = await axios
    .get(
      `${APIBaseUrlJobDetails}/findAllJobActiveLogDetails?authKey=${token}&jobId=${jobId}&jobSeekerId=${jobSeekerId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//create send message
export const CreateSendMessage = async (Payload, token) => {
  const response = await axios
    .post(
      `${APIBaseUrlJobDetails}/createSendMessageDetails?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//  label filter in faq
//find
export const GetLabelFaqFilter = async (id, token, start, end, companyId) => {
  const response = await axios
    .get(
      `${APIBaseUrlCompanyDetails}/fAQ_findlabeldetails?labelId=${id}&authKey=${token}&start=${start}&end=${end}&companyId=${companyId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//create saved job

export const CreateSavedJob = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrlJobDetails}/createSavedJob?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//  label filter in faq
//find
export const GetLabelQuestionFilter = async (
  id,
  token,
  start,
  end,
  companyId
) => {
  const response = await axios
    .get(
      `${APIBaseUrlCompanyDetails}/Question_findlabeldetails?labelId=${id}&authKey=${token}&start=${start}&end=${end}&companyId=${companyId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//find all EducationDetails
export const GetAllEducationDetails = async (Query) => {
  const response = await axios
    .get(`${APIBaseUrl}/findAllEducationDetails?${Query}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Create Education Details
export const CreateEducationDetails = async (AuthKey, Payload) => {
  const response = await axios
    .post(`${APIBaseUrl}/createEducationDetails?authKey=${AuthKey}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//Edit Education Details
export const EditEducationDetails = async (AuthKey, Payload) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateEducationDetails?authKey=${AuthKey}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const CreateMasterDataByType = async (Payload, token) => {
  const response = await axios
    .post(
      `${APIBaseUrlMasterDetails}/createMasterData?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//visibility Post

export const VisibilityPost = async (Payload, token) => {
  const response = await axios
    .post(
      `${APIBaseUrlJobDetails}/createJobHideDetails?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Delete Education details

export const DeleteEducationalDetails = async (AuthKey, Payload) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/deleteEducationDetails?authKey=${AuthKey}`,
      { data: Payload },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//find all company details on employee
export const CompanyDetailsEmployee = async (token, sort) => {
  const response = await axios
    .get(`${APIBaseUrl}/listingTheCompany?authKey=${token}&sortBy=${sort}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//find particular company details on employee
export const ParticularCompanyDetailsEmployee = async (token, sort, industryId) => {
  const response = await axios
    .get(`${APIBaseUrl}/listingTheCompany?authKey=${token}&sortBy=${sort}&industryId=${industryId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

export const IndustryCompanyEmployee = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/industrylisting?authKey=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// find overview data on employee

export const GetOverview = async (id, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/companyBasic?companyId=${id}&authKey=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// find search company on employee

export const SearchCompany = async (token) => {
  const response = await axios
    .get(`${APIBaseUrl}/searchCompany?authKey=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Create Question
//create
export const CreateQuestion = async (Payload, token) => {
  const response = await axios
    .post(
      `${APIBaseUrlCompanyDetails}/createQuestion?authKey=${token}`,
      Payload,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get insights on employer
export const GetNavbarCount = async (token, id) => {
  const response = await axios
    .get(
      `${APIBaseUrlCompanyDetails}/companyCount?authKey=${token}&companyProfileId=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//job list in company details page
export const JobPostList = async (token, companyId, sort) => {
  const response = await axios
    .get(
      `${APIBaseUrlJobDetails}/findAllJobDetails?companyId=${companyId}&authKey=${token}&sortBy=${sort}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//get job title for review

// export const GetReviewJobTitle = async (token, restrict, id) => {
//   const response = await axios
//     .get(`${APIBaseUrlMasterDetails}/findAllMasterData?authKey=${token}&masterDataType=jobTitle&status=Active&restrictionLevel=${restrict}&userId=${id}`, {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => ({ error: error }));
//   return response;
// };

//get job location for review

// export const GetReviewJobLocation = async (token, restrict, id) => {
//   const response = await axios
//     .get(`${APIBaseUrlMasterDetails}/findAllMasterData?authKey=${token}&masterDataType=jobLocation&status=Active&restrictionLevel=${restrict}&userId=${id}`, {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => ({ error: error }));
//   return response;
// };

//get job location for review
// export const GetQuestionLabel = async (token, restrict, id) => {
//   const response = await axios
//     .get(`${APIBaseUrlMasterDetails}/findAllMasterData?authKey=${token}&masterDataType=QuestionLabel&status=Active&restrictionLevel=${restrict}&userId=${id}`, {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => ({ error: error }));
//   return response;
// };

//create

export const CreateKnownLanguage = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrl}/createKnownLanguage?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// delete Known language

export const DeleteKnownLanguage = async (Payload, Authkey) => {
  const response = await axios
    .delete(
      `${APIBaseUrl}/deleteKnownLanguages?authKey=${Authkey}`,
      { data: Payload },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// Update Skill

export const UpdateSkillDetails = async (Payload, AuthKey) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateSkill?authKey=${AuthKey}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//find all blogs
export const BlogList = async () => {
  const response = await axios
    .get(`${APIBaseUrl}/findAllBlogsDetails`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// find blog
export const BlogDetail = async (id, token) => {
  const response = await axios
    .get(`${APIBaseUrl}/findBlogsDetails?blogId=${id}&authKey=${token}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

//close job

export const CloseJob = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrlJobDetails}/jobClosed?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};
// find all Employers list
export const fetchAllEmployersList = async (token, companyId) => {
  const response = await axios
    .get(
      `${APIBaseUrlCompanyDetails}/findAllEmployer?authKey=${token}&companyId=${companyId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};

// repost job

export const RepostJob = async (Payload, token) => {
  const response = await axios
    .post(`${APIBaseUrlJobDetails}/jobRepost?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};


//language update
export const UpdateLanguage = async (Payload, token) => {
  const response = await axios
    .put(`${APIBaseUrl}/updateKnownLanguage?authKey=${token}`, Payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};


// find particular language
export const getParticularLanguage = async (token, knownLanguageId) => {
  const response = await axios
    .get(
      `${APIBaseUrl}/findParticularKnownLanguage?authKey=${token}&knownLanguageId=${knownLanguageId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => ({ error: error }));
  return response;
};