import React, { useEffect, useCallback, useState } from "react";
import CustomModal from "../../CustomModal";
import CreatableSelect from "react-select/creatable";
import {
  CreateCertificationPost,
  CreateEmployeeSkills,
  DeleteCertification,
  GetAllCertification,
  GetMasterDatasByType,
  UpdateCertificationPost,
} from "../../Api/Api";
import moment from "moment";
import Roboimg2 from "../../../images/roboimg-outerborder.png";
import {
  NonEmptyObjectValidation,
  NonEmptyValidation,
  NonEmptyValidationForDate,
  NonEmptyValidationForEndDate,
  StringValidation,
  URLValidation,
} from "../../Store/validate";
const Certification = (props) => {
  
  const { state, showToastSuccess, showToastError, skills } = props;
  const authKey = state?.auth?.auth?.authKey;
  const userProfileId = state?.auth?.auth?.userProfileId;

  const [allCertifications, setAllCertifications] = useState([]);
  const [AddCertificationModal, setAddCertificationModal] = useState(false);
  const [addCertificationData, setAddCertificationData] = useState({
    expire: false,
  });
  const [addCertificationDataValidation, setAddCertificationDataValidation] =
    useState({});
  const [updateCertificationData, setUpdateCertificationData] = useState({
    expire: false,
  });
  const [
    updateCertificationDataValidation,
    setUpdateCertificationDataValidation,
  ] = useState({});
  const [EditCertificationModal, setEditCertificationModal] = useState(false);
  const [certificateId, setCertificateId] = useState("");
  const [deleteCertificateModal, setDeleteCertificateModal] = useState(false);
  const [skillDeleteModalStatus, setSkillDeleteModalStatus] = useState(false);
  const [deleteSkillData, setDeleteSkillData] = useState("");
  const [externalLink, setExternalLink] = useState([]);

  const handleOrganizationName = async (string, type) => {
    const valid = StringValidation(string);
    onChangeAddCertificationDataValidation("issuingOrganizationId", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "organization_name",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 0,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchOrganizationName();
        if (type === "create") {
          onChangeAddCertificationDatas("issuingOrganizationId", response.data);
        } else {
          onChangeUpdateCertificationDatas(
            "issuingOrganizationId",
            response.data
          );
        }
      }
    }
  };

  const onChangeAddCertificationDatas = (key, value) => {
    setAddCertificationData({ ...addCertificationData, [key]: value });
    if (addCertificationDataValidation[key])
      delete addCertificationDataValidation[key];
  };

  const onChangeAddCertificationDataValidation = (key, value) => {
    setAddCertificationDataValidation({
      ...addCertificationDataValidation,
      [key]: value,
    });
  };

  const onChangeUpdateCertificationDatas = (key, value) => {
    setUpdateCertificationData({ ...updateCertificationData, [key]: value });
    if (updateCertificationDataValidation[key])
      delete updateCertificationDataValidation[key];
  };

  const onChangeUpdateCertificationDataValidation = (key, value) => {
    setUpdateCertificationDataValidation({
      ...updateCertificationDataValidation,
      [key]: value,
    });
  };

  const handleCreateSkills = async (e, type) => {
    const valid = StringValidation(e);
    onChangeAddCertificationDataValidation("skill", valid);
    if (valid.status) {
      const response = await props.handleCreateSkills(e);
      if (response && response.statusCode === 200) {
        if (type === "create") {
          if (addCertificationData?.skill?.length) {
            let data = addCertificationData?.skill;
            data.push(response.data);
            onChangeAddCertificationDatas("skill", data);
          } else {
            onChangeAddCertificationDatas("skill", [response.data]);
          }
        } else {
          if (updateCertificationData?.skill?.length) {
            let data = updateCertificationData?.skill;
            data.push(response.data);
            onChangeUpdateCertificationDatas("skill", data);
          } else {
            onChangeUpdateCertificationDatas("skill", [response.data]);
          }
        }
      }
    }
  };

  //create organization name
  const [organizationName, setOrganizationName] = useState([]);

  const fetchOrganizationName = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const Query = `authKey=${authKey}&masterDataType=organization_name&status=Active&restrictionLevel=0&userId=${userProfileId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      setOrganizationName(getEmployeeSkills.data);
    } else {
      setOrganizationName([]);
    }
  }, [authKey, userProfileId]);
  
  //get all certifications
  const handleAddCertification = async () => {
    let validate = {};
    validate.certificationId = NonEmptyObjectValidation(addCertificationData.certificationId);
    validate.issuingOrganizationId = NonEmptyObjectValidation(addCertificationData.issuingOrganizationId);
    validate.credentialId = NonEmptyValidation(addCertificationData.credentialId);
    validate.credentialUrl = NonEmptyValidation(addCertificationData.credentialUrl);
    validate.issueDate = NonEmptyValidationForDate(addCertificationData.issueDate);
    
    if(addCertificationData.expire === false){
      validate.expirationDate = NonEmptyValidationForEndDate(addCertificationData.expirationDate);
    }
    validate.skill = NonEmptyValidationForDate(addCertificationData.skill);
    
    setAddCertificationDataValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
    
       let Payload = {
        userProfileId: state?.auth?.auth?.userProfileId,
        certificationId: addCertificationData?.certificationId?.masterDataId,
        credentialId: addCertificationData?.credentialId,
        credentialUrl: addCertificationData?.credentialUrl,
        issuingOrganizationId:
          addCertificationData?.issuingOrganizationId?.masterDataId,
        expire: addCertificationData?.expire,
        issueDate: moment(addCertificationData?.issueDate).format("YYYY-MM-DD"),
        expirationDate: addCertificationData.expire
          ? undefined
          : addCertificationData?.expirationDate,
        skill: addCertificationData?.skill?.map((e) =>
          e.masterDataId.toString()
        ),
      };
      const token = state?.auth?.auth?.authKey;
      const response = await CreateCertificationPost(Payload, token);

      if (response && response.statusCode === 200) {
        showToastSuccess(response.message);
        setAddCertificationModal(false);
        fetchCertificates();
        setAddCertificationData({ expire: false });
      } else {
        showToastError(
          response.message || response.error.response.data.message
        );
      }
    }
  };
 
  const handleUpdateCertification = async () => {
    let validate = {};
    validate.certificationId = NonEmptyObjectValidation(updateCertificationData.certificationId);
    validate.issuingOrganizationId = NonEmptyObjectValidation(updateCertificationData.issuingOrganizationId);
    validate.credentialId = NonEmptyValidation(updateCertificationData.credentialId);
    validate.credentialUrl = NonEmptyValidation(updateCertificationData.credentialUrl);
    validate.issueDate = NonEmptyValidationForDate(updateCertificationData.issueDate);
    if(updateCertificationData.expire === false){
      validate.expirationDate = NonEmptyValidationForEndDate(updateCertificationData.expirationDate);
    }
    validate.skill = NonEmptyObjectValidation(updateCertificationData.skill);
    setUpdateCertificationDataValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
    let Payload = {
        certificationId: updateCertificationData?.certificationId?.masterDataId,
        credentialId: updateCertificationData?.credentialId,
        credentialUrl: updateCertificationData?.credentialUrl,
        issuingOrganizationId:
          updateCertificationData?.issuingOrganizationId?.masterDataId,
        expire: updateCertificationData?.expire,
        issueDate: updateCertificationData?.issueDate,
        expirationDate: updateCertificationData.expire
          ? undefined
          : updateCertificationData?.expirationDate,
        skill: updateCertificationData?.skill?.map((e) =>
          e.masterDataId.toString()
        ),
        userCertificationId: updateCertificationData.userCertificationId,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await UpdateCertificationPost(Payload, token);

      if (response && response.statusCode === 200) {
        showToastSuccess(response.message);
        setEditCertificationModal(false);
        setUpdateCertificationData({ expire: false });
        fetchCertificates();
      } else {
        showToastError(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  const fetchCertificates = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const getEmployeeSkills = await GetAllCertification(userProfileId, authKey);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      setAllCertifications(getEmployeeSkills.data);
      let temp = [];
      for (let intz = 0; intz < getEmployeeSkills.data.length; intz++) {
        temp.push("https://" + getEmployeeSkills.data[intz].credentialUrl);
      }
      setExternalLink(temp);
    }
  }, [authKey, userProfileId]);

  const handleDeleteCertification = async (value) => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      userCertificationId: value,
    };
    const response = await DeleteCertification(Payload, token);
    if (response && response.statusCode === 200) {
      setDeleteCertificateModal(false);
      showToastSuccess(response.message);
      fetchCertificates();
    } else {
      showToastError(response.message || response.error.response.data.message);
      //setFailureModal(true);
    }
  };

  const [certificationId, setCertificationName] = useState([]);
  const fetchcertificationName = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const Query = `authKey=${authKey}&masterDataType=certificate_name&status=Active&restrictionLevel=0&userId=${userProfileId}`;
    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      setCertificationName(getEmployeeSkills.data);
    }
  }, [authKey, userProfileId]);

  const handlecertificationName = async (string, type) => {
    const valid = StringValidation(string);
    onChangeAddCertificationDataValidation("certificationId", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "certificate_name",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 0,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchcertificationName();
        if (type === "create") {
          onChangeAddCertificationDatas("certificationId", response.data);
        } else {
          onChangeUpdateCertificationDatas("certificationId", response.data);
        }
      }
    }
  };

  useEffect(() => {
    fetchCertificates();
    fetchcertificationName();
    fetchOrganizationName();
    setAddCertificationDataValidation({});
  }, [fetchCertificates, fetchOrganizationName, fetchcertificationName]);
  const handleDeleteEmploymentDetailsSkill = async (data) => {
    let Payload = {
      skill: data?.skill?.map((e) => e.masterDataId.toString()),
      userCertificationId: data?.userCertificationId,
    };
    const token = state?.auth?.auth?.authKey;
    const response = await UpdateCertificationPost(Payload, token);
    if (response && response.statusCode === 200) {
      fetchCertificates();
      setSkillDeleteModalStatus(false);
      showToastSuccess(response.message);
    } else {
      showToastError(response.message || response.error.response.data.message);
    }
  };
  const handleDeleteSkill = async () => {
    try {
      let data = deleteSkillData;
      data.data.skill = data.data.skill.filter(
        (item) => item?.masterDataId !== data.masterDataId
      );
      handleDeleteEmploymentDetailsSkill(data.data);
    } catch (e) {
      console.log(e, "Error while deleting the skill");
    }
  };
  return (
    <div>
      <div>
        <div className="container py-4">
          <div className="row justify-content-center d-flex align-items-center ">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-2 col-md-3 col-4 my-2 text-center">
                  <div className="upload-btn-wrapper">
                    <button
                      className="btn upload-gallery"
                      onClick={() => {
                        setAddCertificationModal(true);
                      }}
                    >
                      <h1>
                        <span className="material-symbols-rounded">add</span>
                      </h1>
                      <span className="content">Add Certifications</span>
                    </button>
                  </div>
                </div>
                <div className="col-lg-10 scroll-content-design">
                  <div className="edudetails-section">
                    <div className="row ">
                      {allCertifications?.map((e, i) => (
                        <div key={i} className="col-lg-6 col-md-6 px-1 my-2">
                          <div className="edudetails-card">
                            <div className="d-flex justify-content-between">
                              <h6 className="font-weight-bold">
                                {e?.certificationId?.name}
                              </h6>
                              <div className="icon d-flex align-items-start">
                                <span
                                  className="material-icons icon-middle edit  m-1"
                                  onClick={() => {
                                    setUpdateCertificationData(e);
                                    setEditCertificationModal(true);
                                  }}
                                >
                                  edit
                                </span>
                                <span
                                  className="material-symbols-rounded del icon-middle rounded m-1"
                                  onClick={() => {
                                    setCertificateId(e.userCertificationId);
                                    setDeleteCertificateModal(true);
                                  }}
                                >
                                  delete
                                </span>
                              </div>
                            </div>
                            <h6 className="cut-text ">
                              {e?.issuingOrganizationId?.name}
                            </h6>
                            <div className="d-flex justify-content-between my-3">
                              <div className="">
                                <p className="mb-0">
                                  Issued :&nbsp;
                                  <span className="mb-0">
                                    {moment(e.issueDate).format("MMMM YYYY")}
                                    &nbsp; -&nbsp;
                                    {e.expire === false
                                      ? moment(e.expirationDate).format(
                                          "MMMM YYYY"
                                        )
                                      : "Till now"}
                                  </span>
                                </p>
                                <p className="mb-0">
                                  Credential ID :
                                  <span className="mb-0 ">
                                    {e.credentialId}
                                  </span>
                                </p>
                              </div>
                              <a
                                href={externalLink[i]}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-light my-2 my-sm-0 mx-2 postjob-btn"
                              >
                                Show Certificate
                              </a>
                            </div>
                            <div className="my-2">
                              {e.skill?.map((v, i) => (
                                <small
                                  key={i}
                                  className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1 "
                                >
                                  {v.name}
                                  <span
                                    onClick={() => {
                                      setDeleteSkillData({
                                        masterDataId: v.masterDataId,
                                        data: e,
                                      });
                                      setSkillDeleteModalStatus(true);
                                    }}
                                    className="material-symbols-rounded pl-2 icon-middle"
                                  >
                                    cancel
                                  </span>
                                </small>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card my-1 d-lg-none d-block">
        <div className="card-header" id="headingFour">
          <h5 className="mb-0">
            <button
              className="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              Certifications
              <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                expand_more
              </span>
            </button>
          </h5>
        </div>
        <div
          id="collapseFour"
          className="collapse"
          aria-labelledby="headingFour"
          data-parent="#accordion"
        >
          <div className="card-body p-0">
            <div>
              <div className="container">
                <div className="row justify-content-center d-flex align-items-center ">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-2 col-md-3 col-4 my-2 text-center">
                        <div className="upload-btn-wrapper">
                          <button
                            className="btn upload-gallery"
                            onClick={() => {
                              setAddCertificationModal(true);
                            }}
                          >
                            <h1>
                              <span className="material-symbols-rounded">
                                add
                              </span>
                            </h1>
                            <span className="content">Add Certifications</span>
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-10 scroll-content-design">
                        <div className="edudetails-section">
                          <div className="row ">
                            {allCertifications?.map((e, i) => (
                              <div
                                key={i}
                                className="col-lg-6 col-md-6 px-1 my-2"
                              >
                                <div className="edudetails-card">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="font-weight-bold">
                                      {e?.certificationId?.name}
                                    </h6>
                                    <div className="icon d-flex align-items-start">
                                      <span
                                        className="material-icons icon-middle edit  m-1"
                                        onClick={() => {
                                          setUpdateCertificationData(e);
                                          setEditCertificationModal(true);
                                        }}
                                      >
                                        edit
                                      </span>
                                      <span
                                        className="material-symbols-rounded del icon-middle rounded m-1"
                                        onClick={() => {
                                          setCertificateId(
                                            e.userCertificationId
                                          );
                                          setDeleteCertificateModal(true);
                                        }}
                                      >
                                        delete
                                      </span>
                                    </div>
                                  </div>
                                  <h6 className="cut-text ">
                                    {e?.issuingOrganizationId?.name}
                                  </h6>
                                  <div className="d-flex justify-content-between my-3">
                                    <div className="">
                                      <p className="mb-0 ">
                                        {moment(e.issueDate).format("MMM YYYY")}
                                        -
                                        {moment(e.expirationDate).format(
                                          "MMM YYYY"
                                        )}
                                      </p>
                                      <p className="mb-0">
                                        Credential ID : {e.credentialId}
                                      </p>
                                    </div>
                                    <button className="btn btn-light my-2 my-sm-0 mx-2 postjob-btn">
                                      Show Credential
                                    </button>
                                  </div>
                                  <div className="my-2">
                                    {e.skill?.map((v, i) => (
                                      <small
                                        key={i}
                                        className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1 "
                                      >
                                        {v.name}
                                        <span className="material-symbols-rounded pl-2 icon-middle">
                                          cancel
                                        </span>
                                      </small>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <CustomModal
          open={AddCertificationModal}
          onClickOutside={() => {
            setAddCertificationModal(false);
          }}
        >
          <div className="modal-content">
            <div className="modal-body p-0 ">
              <div className="modalHeader py-3">
                <h5 className="text-center font-weight-bold my-0">
                  Add Certification
                </h5>
              </div>
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center modal-scroll-content">
                  <div className="col-lg-11">
                    <form>
                      <div className="form-container py-4">
                        <div className="row ">
                          <div className="col-lg-6 px-4 pb-4 ">
                            <div className="google-input">
                              <label
                                htmlFor="template-input"
                                className="z-index-value"
                              >
                                Certificate name
                              </label>
                              <div className="dropdown-container">
                                <CreatableSelect
                                  options={certificationId}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: "6px",
                                    zIndex: 3,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#DFF1F1",
                                      neutral20:
                                        addCertificationDataValidation
                                          ?.certificationId?.status === false
                                          ? "red"
                                          : "#00ABAD", // border color
                                      primary:
                                        addCertificationDataValidation
                                          ?.certificationId?.status === false
                                          ? "red"
                                          : "#00ABAD", // border color when focus
                                    },
                                  })}
                                  styles={{
                                    menu: (provided, state) => ({
                                      ...provided,
                                      zIndex: "5 !important",
                                    }),
                                  }}
                                  getOptionLabel={(option) =>
                                    option.name || option.label
                                  }
                                  getOptionValue={(option) =>
                                    option.masterDataId
                                  }
                                  onInputChange={()=>{
                                    if (addCertificationDataValidation["certificationId"]) delete addCertificationDataValidation["certificationId"]
                                  }
                                }  
                                  placeholder="Choose Certificate name"
                                  value={addCertificationData.certificationId}
                                  onChange={(newValue) =>
                                    onChangeAddCertificationDatas(
                                      "certificationId",
                                      newValue
                                    )
                                  }
                                  onCreateOption={(data) =>
                                    handlecertificationName(data, "create")
                                  }
                                  isClearable
                                />
                              </div>
                              <small className="text-danger">
                                {addCertificationDataValidation?.certificationId
                                  ?.message
                                  ? `Certificate name ${addCertificationDataValidation?.certificationId?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="google-input">
                              <label
                                htmlFor="template-input"
                                className="z-index-value"
                              >
                                Issuing organization name
                              </label>
                              <div className="dropdown-container">
                                <CreatableSelect
                                  options={organizationName}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: "6px",
                                    zIndex: 3,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#DFF1F1",
                                      neutral20:
                                        addCertificationDataValidation
                                          ?.issuingOrganizationId?.status ===
                                        false
                                          ? "red"
                                          : "#00ABAD", // border color
                                      primary:
                                        addCertificationDataValidation
                                          ?.issuingOrganizationId?.status ===
                                        false
                                          ? "red"
                                          : "#00ABAD", // border color when focus
                                    },
                                  })}
                                  styles={{
                                    menu: (provided, state) => ({
                                      ...provided,
                                      zIndex: "5 !important",
                                    }),
                                  }}
                                  getOptionLabel={(option) =>
                                    option.name || option.label
                                  }
                                  getOptionValue={(option) =>
                                    option.masterDataId
                                  }
                                  placeholder="Choose issuing organization name"
                                  value={
                                    addCertificationData?.issuingOrganizationId
                                  }
                                  onChange={(newValue) =>
                                    onChangeAddCertificationDatas(
                                      "issuingOrganizationId",
                                      newValue
                                    )
                                  }
                                  onCreateOption={(data) =>
                                    handleOrganizationName(data, "create")
                                  }
                                  onInputChange={()=>{
                                    if (addCertificationDataValidation["issuingOrganizationId"]) delete addCertificationDataValidation["issuingOrganizationId"]
                                  }
                                }  
                                  isClearable
                                />
                              </div>
                              <small className="text-danger">
                                {addCertificationDataValidation
                                  ?.issuingOrganizationId?.message
                                  ? `Organization name ${addCertificationDataValidation?.issuingOrganizationId?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 px-4 pb-4">
                            <div className="google-input">
                              <label
                                htmlFor="template-input"
                                className="z-index-value"
                              >
                                Credential ID
                              </label>
                              <input
                                onBlur={(e) => {
                                  onChangeAddCertificationDataValidation(
                                    "credentialId",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                aria-invalid={
                                  addCertificationDataValidation?.credentialId
                                    ?.status === false
                                }
                                value={addCertificationData.credentialId}
                                autoComplete="off"
                                type="text"
                                id="template-input"
                                name="credentialId"
                                onChange={(e) =>
                                  onChangeAddCertificationDatas(
                                    "credentialId",
                                    e.target.value
                                  )
                                }
                              />
                              <small className="text-danger">
                                {addCertificationDataValidation?.credentialId
                                  ?.message
                                  ? `Credential ID ${addCertificationDataValidation?.credentialId?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="google-input">
                              <label
                                htmlFor="template-input"
                                className="z-index-value"
                              >
                                Credential URL
                              </label>
                              <input
                                value={addCertificationData.credentialUrl}
                                autoComplete="off"
                                type="text"
                                id="template-input"
                                name="credentialUrl"
                                aria-invalid={
                                  addCertificationDataValidation?.credentialUrl
                                    ?.status === false
                                }
                                onBlur={(e) => {
                                  onChangeAddCertificationDataValidation(
                                    "credentialUrl",
                                    URLValidation(e.target.value)
                                  );
                                }}
                                onChange={(e) =>
                                  onChangeAddCertificationDatas(
                                    "credentialUrl",
                                    e.target.value
                                  )
                                }
                              />
                              <small className="text-danger">
                                {addCertificationDataValidation?.credentialUrl
                                  ?.message
                                  ? `Credential Url ${addCertificationDataValidation?.credentialUrl?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12 px-4 pb-4">
                            <div className="form-check mt-4">
                              <input
                                autoComplete="off"
                                className="form-check-input"
                                type="checkbox"
                                id="gridCheck1"
                                value={addCertificationData.expire}
                                onChange={() => {
                                  if (addCertificationData.expire)
                                    onChangeAddCertificationDatas(
                                      "expire",
                                      false
                                    );
                                  else
                                    onChangeAddCertificationDatas(
                                      "expire",
                                      true
                                    );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="gridCheck1"
                              >
                                This credential does not expire
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6 px-4 pb-4 ">
                            <div className="google-input">
                              <label
                                htmlFor="template-input"
                                className="z-index-value"
                              >
                                Issue date
                              </label>
                              <input
                                autoComplete="off"
                                type="date"
                                id="template-input"
                                name="issueDate"
                                max={moment().format("YYYY-MM-DD")}
                                aria-invalid={
                                  addCertificationDataValidation?.issueDate
                                    ?.status === false
                                }
                                onBlur={(e) => {
                                  onChangeAddCertificationDataValidation(
                                    "issueDate",
                                    NonEmptyValidationForDate(e.target.value)
                                  );
                                }}
                                onChange={(e) =>
                                  onChangeAddCertificationDatas(
                                    "issueDate",
                                    e.target.value
                                  )
                                }
                              />
                              <small className="text-danger">
                                {addCertificationDataValidation?.issueDate
                                  ?.message
                                  ? `Issue Date ${addCertificationDataValidation?.issueDate?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="google-input">
                              <label
                                htmlFor="template-input"
                                className="z-index-value"
                              >
                                Skill*
                              </label>
                              <div className="dropdown-container">
                                <CreatableSelect
                                  options={skills}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: "6px",
                                    zIndex: 3,
                                    colors: {
                                      ...theme.colors,
                                      neutral20:
                                        addCertificationDataValidation?.skill
                                          ?.status === false
                                          ? "red"
                                          : "#00ABAD", // border color
                                      primary:
                                        addCertificationDataValidation?.skill
                                          ?.status === false
                                          ? "red"
                                          : "#00ABAD", // border color when focus
                                    },
                                  })}
                                  styles={{
                                    menu: (provided, state) => ({
                                      ...provided,
                                      zIndex: "5 !important",
                                    }),
                                  }}
                                  getOptionLabel={(option) =>
                                    option.name || option.label
                                  }
                                  getOptionValue={(option) =>
                                    option.masterDataId
                                  }
                                  placeholder="Choose your skill"
                                  isClearable
                                  isMulti
                                  
                                  value={addCertificationData.skill}
                                  onChange={(newValue) =>
                                    onChangeAddCertificationDatas(
                                      "skill",
                                      newValue
                                    )
                                  }
                                  onInputChange={()=>{
                                    if (addCertificationDataValidation["skill"]) delete addCertificationDataValidation["skill"]
                                  }
                                }  
                                  onCreateOption={(e) =>
                                    handleCreateSkills(e, "create")
                                  }
                                />
                              </div>
                              <small className="text-danger">
                                {addCertificationDataValidation?.skill?.message
                                  ? `Skill ${addCertificationDataValidation?.skill?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 px-4 pb-4">
                            {addCertificationData.expire === false ? (
                              <div className="google-input">
                                <label
                                  htmlFor="template-input"
                                  className="z-index-value"
                                >
                                  Expiration date
                                </label>
                                <input
                                  autoComplete="off"
                                  type="date"
                                  id="template-input"
                                  name="expirationDate"
                                  aria-invalid={
                                    addCertificationDataValidation
                                      ?.expirationDate?.status === false
                                  }
                                  onBlur={(e) => {
                                    onChangeAddCertificationDataValidation(
                                      "expirationDate",
                                      NonEmptyValidationForDate(e.target.value)
                                    );
                                  }}
                                  onChange={(e) =>
                                    onChangeAddCertificationDatas(
                                      "expirationDate",
                                      e.target.value
                                    )
                                  }
                                />
                                <small className="text-danger">
                                  {addCertificationDataValidation
                                    ?.expirationDate?.message
                                    ? `Expiration Date ${addCertificationDataValidation?.expirationDate?.message}`
                                    : ""}
                                </small>
                              </div>
                            ) : (
                              <p className="text-center text-light w-100 bg-secondary py-2 mt-4 mb-0 rounded">
                                No Expiration
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0">
                <div>
                  <button
                    className="btn btn-light otp-btn postjob-btn"
                    onClick={() => {
                      setAddCertificationModal(false);
                      setAddCertificationDataValidation({});
                    }}
                  >
                    Back
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                    onClick={() => {
                      handleAddCertification();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
        <CustomModal
          open={EditCertificationModal}
          onClickOutside={() => {
            setEditCertificationModal(false);
          }}
        >
          <div className="modal-content">
            <div className="modal-body p-0 ">
              <div className="modalHeader py-3">
                <h5 className="text-center font-weight-bold my-0">
                  Edit Certification
                </h5>
              </div>
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center modal-scroll-content">
                  <div className="col-lg-11">
                    <form>
                      <div className="form-container py-4">
                        <div className="row ">
                          <div className="col-lg-6 px-4 pb-4 ">
                            <div className="google-input">
                              <label
                                htmlFor="template-input"
                                className="z-index-value"
                              >
                                Certificate name
                              </label>
                              <div className="dropdown-container">
                                <CreatableSelect
                                  options={certificationId}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: "6px",
                                    zIndex: 3,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#DFF1F1",
                                      neutral20:
                                      updateCertificationDataValidation
                                        ?.certificationId?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                    updateCertificationDataValidation
                                        ?.certificationId?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  
                                    },
                                  })}
                                  styles={{
                                    menu: (provided, state) => ({
                                      ...provided,
                                      zIndex: "5 !important",
                                    }),
                                  }}
                                  getOptionLabel={(option) =>
                                    option.name || option.label
                                  }
                                  getOptionValue={(option) =>
                                    option.masterDataId
                                  }
                                  placeholder="Certificate name"
                                  value={
                                    updateCertificationData.certificationId
                                  }
                                  onChange={(newValue) =>
                                    onChangeUpdateCertificationDatas(
                                      "certificationId",
                                      newValue
                                    )
                                  }
                                  onCreateOption={(data) =>
                                    handlecertificationName(data, "update")
                                  }
                                  onInputChange={()=>{
                                    if (updateCertificationDataValidation["certificationId"]) delete updateCertificationDataValidation["certificationId"]
                                  }
                                }  
                                  isClearable
                                />
                                <small className="text-danger">
                                  {updateCertificationDataValidation
                                    ?.certificationId?.message
                                    ? `Certificate name ${updateCertificationDataValidation?.certificationId?.message}`
                                    : ""}
                                </small>
                              </div>
                            </div>
                            <div className="google-input">
                              <label
                                htmlFor="template-input"
                                className="z-index-value"
                              >
                                Issuing organization name
                              </label>
                              <div className="dropdown-container">
                                <CreatableSelect
                                  options={organizationName}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: "6px",
                                    zIndex: 3,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#DFF1F1",
                                      neutral20:
                                      updateCertificationDataValidation
                                          ?.issuingOrganizationId?.status ===
                                        false
                                          ? "red"
                                          : "#00ABAD", // border color
                                      primary:
                                      updateCertificationDataValidation
                                          ?.issuingOrganizationId?.status ===
                                        false
                                          ? "red"
                                          : "#00ABAD", // border color when focus
                                    
                                    },
                                  })}
                                  styles={{
                                    menu: (provided, state) => ({
                                      ...provided,
                                      zIndex: "5 !important",
                                    }),
                                  }}
                                  getOptionLabel={(option) =>
                                    option.name || option.label
                                  }
                                  getOptionValue={(option) =>
                                    option.masterDataId
                                  }
                                  placeholder="Choose issuing organization name"
                                  value={
                                    updateCertificationData?.issuingOrganizationId
                                  }
                                  onChange={(newValue) =>
                                    onChangeUpdateCertificationDatas(
                                      "issuingOrganizationId",
                                      newValue
                                    )
                                  }
                                  onInputChange={()=>{
                                    if (updateCertificationDataValidation["issuingOrganizationId"]) delete updateCertificationDataValidation["issuingOrganizationId"]
                                  }
                                } 
                                  onCreateOption={(data) =>
                                    handleOrganizationName(data, "update")
                                  }
                                  isClearable
                                />
                                <small className="text-danger">
                                  {updateCertificationDataValidation
                                    ?.issuingOrganizationId?.message
                                    ? `Organization name ${updateCertificationDataValidation?.issuingOrganizationId?.message}`
                                    : ""}
                                </small>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 px-4 pb-4">
                            <div className="google-input">
                              <label
                                htmlFor="template-input"
                                className="z-index-value"
                              >
                                Credential ID
                              </label>
                              <input
                                value={updateCertificationData.credentialId}
                                autoComplete="off"
                                type="text"
                                id="template-input"
                                name="credentialId"
                                onChange={(e) =>
                                  onChangeUpdateCertificationDatas(
                                    "credentialId",
                                    e.target.value
                                  )
                                }
                                aria-invalid={
                                  updateCertificationDataValidation?.credentialId
                                    ?.status === false
                                }
                                onBlur={(e) => {
                                  onChangeUpdateCertificationDataValidation(
                                    "credentialId",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {updateCertificationDataValidation?.credentialId
                                  ?.message
                                  ? `Credential ID ${updateCertificationDataValidation?.credentialId?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="google-input">
                              <label
                                htmlFor="template-input"
                                className="z-index-value"
                              >
                                Credential URL
                              </label>
                              <input
                                value={updateCertificationData.credentialUrl}
                                autoComplete="off"
                                type="text"
                                id="template-input"
                                name="credentialUrl"
                                onChange={(e) =>
                                  onChangeUpdateCertificationDatas(
                                    "credentialUrl",
                                    e.target.value
                                  )
                                }
                                aria-invalid={
                                  updateCertificationDataValidation?.credentialUrl
                                    ?.status === false
                                }
                                onBlur={(e) => {
                                  onChangeUpdateCertificationDataValidation(
                                    "credentialUrl",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {updateCertificationDataValidation
                                  ?.credentialUrl?.message
                                  ? `Credential Url ${updateCertificationDataValidation?.credentialUrl?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12 px-4 pb-4">
                            <div className="form-check mt-4">
                              <input
                                autoComplete="off"
                                className="form-check-input"
                                type="checkbox"
                                id="gridCheck1"
                                checked={
                                  updateCertificationData?.expire === true
                                }
                                value={updateCertificationData.expire}
                                onChange={() => {
                                  if (updateCertificationData.expire)
                                    onChangeUpdateCertificationDatas(
                                      "expire",
                                      false
                                    );
                                  else
                                    onChangeUpdateCertificationDatas(
                                      "expire",
                                      true
                                    );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="gridCheck1"
                              >
                                This credential does not expire
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6 px-4 pb-4 ">
                            <div className="google-input">
                              <label
                                htmlFor="template-input"
                                className="z-index-value"
                              >
                                Issue date
                              </label>
                              <input
                                autoComplete="off"
                                max={moment().format("YYYY-MM-DD")}
                                value={moment(
                                  updateCertificationData.issueDate
                                ).format("YYYY-MM-DD")}
                                type="date"
                                id="template-input"
                                name="issueDate"
                                onBlur={(e) => {
                                  onChangeUpdateCertificationDataValidation(
                                    "issueDate",
                                    NonEmptyValidationForDate(e.target.value)
                                  );
                                }}
                                onChange={(e) =>
                                  onChangeUpdateCertificationDatas(
                                    "issueDate",
                                    e.target.value
                                  )
                                }
                                aria-invalid={
                                  updateCertificationDataValidation?.issueDate
                                    ?.status === false
                                }
                              />
                              <small className="text-danger">
                                {updateCertificationDataValidation?.issueDate
                                  ?.message
                                  ? `Issue Date ${updateCertificationDataValidation?.issueDate?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="google-input">
                              <label
                                htmlFor="template-input"
                                className="z-index-value"
                              >
                                Skill*
                              </label>
                              <div className="dropdown-container">
                                <CreatableSelect
                                  options={skills}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: "6px",
                                    zIndex: 3,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#DFF1F1",
                                      neutral20:
                                      updateCertificationDataValidation?.skill
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                    updateCertificationDataValidation?.skill
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  
                                    },
                                  })}
                                  styles={{
                                    menu: (provided, state) => ({
                                      ...provided,
                                      zIndex: "5 !important",
                                    }),
                                  }}
                                  getOptionLabel={(option) =>
                                    option.name || option.label
                                  }
                                  getOptionValue={(option) =>
                                    option.masterDataId
                                  }
                                  placeholder="Choose your skill"
                                  isClearable
                                  isMulti
                                  onInputChange={()=>{
                                    if (updateCertificationDataValidation["skill"]) delete updateCertificationDataValidation["skill"]
                                  }
                                } 
                                  value={updateCertificationData.skill}
                                  onChange={(newValue) =>
                                    onChangeUpdateCertificationDatas(
                                      "skill",
                                      newValue
                                    )
                                  }
                                  onCreateOption={(e) =>
                                    handleCreateSkills(e, "update")
                                  }
                                />
                              </div>
                              <small className="text-danger">
                                {updateCertificationDataValidation?.skill
                                  ?.message
                                  ? `Skill ${updateCertificationDataValidation?.skill?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 px-4 pb-4">
                            {updateCertificationData.expire === false ? (
                              <div className="google-input">
                                <label
                                  htmlFor="template-input"
                                  className="z-index-value"
                                >
                                  Expiration date
                                </label>
                                <input
                                  autoComplete="off"
                                  type="date"
                                  id="template-input"
                                  name="expirationDate"
                                  value={moment(
                                    updateCertificationData.expirationDate
                                  ).format("YYYY-MM-DD")}
                                  onChange={(e) =>
                                    onChangeUpdateCertificationDatas(
                                      "expirationDate",
                                      e.target.value
                                    )
                                  }
                                  aria-invalid={
                                    updateCertificationDataValidation
                                      ?.expirationDate?.status === false
                                  }
                                  onBlur={(e) => {
                                    onChangeUpdateCertificationDataValidation(
                                      "expirationDate",
                                      NonEmptyValidationForDate(e.target.value)
                                    );
                                  }}
                                  disabled={
                                    updateCertificationData.expire === true
                                  }
                                />
                                <small className="text-danger">
                                  {updateCertificationDataValidation
                                    ?.expirationDate?.message
                                    ? `Expiration Date ${updateCertificationDataValidation?.expirationDate?.message}`
                                    : ""}
                                </small>
                              </div>
                            ) : (
                              <p className="text-center text-light w-100 bg-secondary py-2 mt-4 mb-0 rounded">
                                No Expiration
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0">
                <div>
                  <button
                    className="btn btn-light otp-btn postjob-btn"
                    onClick={() => {
                      setEditCertificationModal(false);
                    }}
                  >
                    Back
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                    onClick={handleUpdateCertification}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
        <CustomModal
          open={deleteCertificateModal}
          onClickOutside={() => {
            setDeleteCertificateModal(false);
          }}
        >
          <div className="modal-content w-75  m-auto">
            <div className="modal-body p-3 ">
              <div
                className="modalHeader"
                style={{ backgroundColor: "#ffffff" }}
              >
                <h6 className="text-center font-weight-bold my-2">
                  <img
                    style={{
                      width: "85px",
                      textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                    }}
                    src={Roboimg2}
                    alt=""
                  />
                </h6>
              </div>
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="col-lg-8 pb-3">
                    <h3 className="font-weight-bold text-center">
                      Are You Sure?
                    </h3>
                    <p className=" text-center">
                      Do you really want to delete this Record? this process
                      cannot be undone.
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-center border-0 mb-3">
                  <button
                    className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                    onClick={() => {
                      setDeleteCertificateModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    style={{ padding: "8px 24px" }}
                    className="btn btn-danger my-2 my-sm-0 mr-2 "
                    onClick={() => handleDeleteCertification(certificateId)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
        <CustomModal
          open={skillDeleteModalStatus}
          onClickOutside={() => {
            setSkillDeleteModalStatus(false);
          }}
        >
          <div className="modal-content w-75 m-auto">
            <div className="modal-body p-3 ">
              <div
                className="modalHeader"
                style={{ backgroundColor: "#ffffff" }}
              >
                <h6 className="text-center font-weight-bold my-2">
                  <img
                    style={{
                      width: "85px",
                      textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                    }}
                    src={Roboimg2}
                    alt=""
                  />
                </h6>
              </div>
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="col-lg-8 pb-3">
                    <h3 className="font-weight-bold text-center">
                      Are You Sure?
                    </h3>
                    <p className=" text-center">
                      Do you really want to delete this skill? this process
                      cannot be undone.
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-center border-0 mb-3">
                  <button
                    className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                    onClick={() => {
                      setSkillDeleteModalStatus(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    style={{ padding: "8px 24px" }}
                    className="btn btn-danger my-2 my-sm-0 mr-2 "
                    onClick={() => handleDeleteSkill()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
};

export default Certification;
