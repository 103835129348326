import React, { useState, useCallback, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { GetAllJobUsersListByType } from "../../components/Api/Api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function QA() {
    const navigate = useNavigate();
    const state = useSelector((state) => state);
    const authKey = state?.auth?.auth?.authKey;
    const [companiesList, setCompaniesList] = useState([]);

    const FetchAllCompaniesList = useCallback(async () => {
      if (!authKey) return;
 
      const Query = `actorType=Employer&authKey=${authKey}`;
      const Response = await GetAllJobUsersListByType(Query);
      if (Response && Response.statusCode === 200) {
        setCompaniesList(Response.data);
      }
    }, [authKey]);

    useEffect(() => {
      FetchAllCompaniesList();
      return () => {};
    }, [FetchAllCompaniesList]);
  
    const columns = [
      {
        name: "id",
        label: "#",
        options: {
          filter: false,
          customBodyRender: (value, meta) => {
            return meta.rowIndex + 1;
          },
        },
      },
      { name: "fullName", label: "Name" },
      { name: "emailId", label: "Email Id" },
      { name: "phoneNumber", label: "Mobile Number" },
      {
        name: "userProfileId",
        label: "Action",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <button className="btn" onClick={() => navigate('/Admin/Companies/EditQA')}>Edit</button>
              </div>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "dropdown",
      selectableRows: "none",
      responsive: "standard",
    };
    return (
        <div className="dashboard-bg">
            <div className="main">
                <div className="scroll-content-admin">
                    <MUIDataTable title={"QA"} data={companiesList} columns={columns} options={options} />
                </div>
            </div>
        </div>
    )
}
