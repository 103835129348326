import React from "react";
import { Routes, Route, Navigate, HashRouter } from "react-router-dom";
import Home from "./components/home";
import HomeEmployee from "./components/Employee/home";
import LoginEmployer from "./components/LoginEmployer";
import RegisterEmployer from "./components/RegisterEmployer";
import VerifyEmployer from "./components/VerifyEmployer";
import Profile from "./components/Employer/Profile";
import JobsHome from "./components/Employer/Jobs/JobsHome";
import JobsDetailsPage from "./components/Employer/Jobs/JobsDetailsPage";
import CandidateProfile from "./components/Employer/Jobs/CandidateProfile";
import Chat from "./components/Employer/Chat/Chat";
import FAQ from "./components/Employer/FAQ";
import QA from "./components/Employer/QA";
import Reviews from "./components/Employer/Reviews";
import ChangePassword from "./components/ChangePassword";
import ForgotPassword from "./components/ForgotPassword";
import ProfileEmployee from "./components/Employee/ProfileEmployee";
import JobsHomeEmployee from "./components/Employee/Jobs/JobsHomeEmployee";
import CandidateDetailsEmployee from "./components/Employee/Jobs/CandidateDetailsEmployee";
import ChatEmployee from "./components/Employee/Chat/ChatEmployee";
import Verifyemployee from "./components/Verifyemployee";
import RegisterEmployee from "./components/Employee/RegisterEmployee";
import EmployeOtp from "./components/EmployeOtp";
import ComingSoon from "./components/ComingSoon";
import TermsCond from "./components/TermsCond";
import { useSelector } from "react-redux";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Dashboard from "./Admin/Dashboard";
import JobSeekers from "./Admin/JobSeekers";
import Companies from "./Admin/Companies";
import Admins from "./Admin/Admins";
import AdminProfile from "./Admin/AdminProfile";
import InsightsEmployer from "./components/Employer/InsightsEmployer";
import CompaniesEmployee from "./components/Employee/CompaniesEmployee";
import CompaniesIndividualPage from "./components/Employee/CompaniesIndividualPage";
import CompaniesWAReview from "./components/Employee/CompaniesWAReview";
import BlogsHome from "./components/Blogs/BlogsHome";
import BlogsIndividual from "./components/Blogs/BlogsIndividual";
import BlogsDashboard from "./components/Blogs/BlogsDashboard";
import MasterData from "./Admin/MasterData";
import EditJobseekers from "./Admin/JobSeekers/EditJobseekers";
import EditCompanies from "./Admin/Companies/EditCompanies";
import EditMasterData from "./Admin/MasterData/EditMasterData";
import EditAdmins from "./Admin/Admins/EditAdmins";
import EditJobs from "./Admin/Jobs/EditJobs";
import Jobs from "./Admin/Jobs";
import CreateAdmins from "./Admin/Admins/CreateAdmins";
import EditBlogs from "./Admin/Companies/EditBlogs";
import EditEmployee from "./Admin/Companies/EditEmployee";
import EditFAQ from "./Admin/Companies/EditFAQ";
import EditQA from "./Admin/Companies/EditQA";

export default function Routeslist() {
  const state = useSelector((state) => state);
  return (
    <HashRouter>
      <Routes>
        <Route
          path="HomeEmployee"
          element={
            state?.auth?.isLoggedIn ? (
              <Navigate replace to="/ProfileEmployee" />
            ) : (
              <HomeEmployee />
            )
          }
        />
        <Route
          path="Login"
          element={
            state?.auth?.isLoggedIn ? (
              <>
                {state?.auth?.auth.actorType === "Employee" ? (
                  <Navigate replace to="/ProfileEmployee" />
                ) : state?.auth?.auth.actorType === "Employer" ? (
                  <Navigate replace to="/Profile" />
                ) : (
                  <Navigate replace to={"/Admin/JobSeekers"} />
                )}
              </>
            ) : (
              <LoginEmployer />
            )
          }
        />
        <Route
          path="RegisterEmployer"
          element={
            state?.auth?.isLoggedIn ? (
              <Navigate replace to="/Profile" />
            ) : (
              <RegisterEmployer />
            )
          }
        />
        <Route
          path="VerifyEmployer"
          element={
            state?.auth?.isLoggedIn ? (
              <Navigate replace to="/Profile" />
            ) : (
              <VerifyEmployer />
            )
          }
        />

        <Route
          path="EmployeOtp"
          element={
            state?.auth?.isLoggedIn ? (
              <Navigate replace to="/ProfileEmployee" />
            ) : (
              <EmployeOtp />
            )
          }
        />
        <Route
          path="Verifyemployee"
          element={
            state?.auth?.isLoggedIn ? (
              <Navigate replace to="/ProfileEmployee" />
            ) : (
              <Verifyemployee />
            )
          }
        />
        <Route
          path="RegisterEmployee"
          element={
            state?.auth?.isLoggedIn ? (
              <Navigate replace to="/ProfileEmployee" />
            ) : (
              <RegisterEmployee />
            )
          }
        />

        <Route
          path="ChangePassword"
          element={
            state?.auth?.isLoggedIn ? (
              <>
                {state?.auth?.auth.actorType === "Employee" ? (
                  <Navigate replace to="/ProfileEmployee" />
                ) : (
                  <Navigate replace to="/Profile" />
                )}
              </>
            ) : (
              <ChangePassword />
            )
          }
        />
        <Route
          path="ForgotPassword"
          element={
            state?.auth?.isLoggedIn ? (
              <>
                {state?.auth?.auth.actorType === "Employee" ? (
                  <Navigate replace to="/ProfileEmployee" />
                ) : (
                  <Navigate replace to="/Profile" />
                )}
              </>
            ) : (
              <ForgotPassword />
            )
          }
        />
        <Route
          path="TermsAndConditions/:id"
          element={
            state?.auth?.isLoggedIn ? (
              <>
                {state?.auth?.auth.actorType === "Employee" ? (
                  <Navigate replace to="/ProfileEmployee" />
                ) : (
                  <Navigate replace to="/Profile" />
                )}
              </>
            ) : (
              <TermsCond />
            )
          }
        />
        <Route path="ComingSoon" element={<ComingSoon />} />
        <Route path="BlogsHome" element={<BlogsHome />} />
        <Route path="PrivacyPolicy/:id" element={<PrivacyPolicy />} />
        <Route path="BlogsIndividual/:id" element={<BlogsIndividual />} />
        <Route path="BlogsDashboard" element={<BlogsDashboard />} />

        <Route
          path="ProfileEmployee"
          element={
            state?.auth?.isLoggedIn ? (
              <ProfileEmployee />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="JobsListing"
          element={
            state?.auth?.isLoggedIn ? (
              <JobsHomeEmployee />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="JobsHome"
          element={
            state?.auth?.isLoggedIn ? <JobsHome /> : <Navigate replace to="/" />
          }
        />
        <Route
          path="JobsDetailsPage/:id"
          element={
            state?.auth?.isLoggedIn ? (
              <JobsDetailsPage />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="CandidateProfile/:id"
          element={
            state?.auth?.isLoggedIn ? (
              <CandidateProfile />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="Profile"
          element={
            state?.auth?.isLoggedIn ? <Profile /> : <Navigate replace to="/" />
          }
        />
        <Route
          path="Chat"
          element={
            state?.auth?.isLoggedIn ? <Chat /> : <Navigate replace to="/" />
          }
        />
        <Route
          path="FAQ"
          element={
            state?.auth?.isLoggedIn ? <FAQ /> : <Navigate replace to="/" />
          }
        />
        <Route
          path="QA"
          element={
            state?.auth?.isLoggedIn ? <QA /> : <Navigate replace to="/" />
          }
        />
        <Route
          path="Reviews"
          element={
            state?.auth?.isLoggedIn ? <Reviews /> : <Navigate replace to="/" />
          }
        />
        <Route
          path="JobInfo/:id"
          element={
            state?.auth?.isLoggedIn ? (
              <CandidateDetailsEmployee />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="ChatEmployee"
          element={
            state?.auth?.isLoggedIn ? (
              <ChatEmployee />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />

        <Route
          path="/"
          element={
            state?.auth?.isLoggedIn ? (
              <>
                {state?.auth?.auth.actorType === "Employee" ? (
                  <Navigate replace to="/ProfileEmployee" />
                ) : (
                  <Navigate replace to="/Profile" />
                )}
              </>
            ) : (
              <Home />
            )
          }
        />

        <Route
          path="/Admin/Dashboard"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? (
              <Dashboard />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="/Admin/JobSeekers"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? (
              <JobSeekers />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="/Admin/Companies"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? (
              <Companies />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="/Admin/Admins"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? <Admins /> : <Navigate replace to="/" />
          }
        />
        <Route
          path="/Admin/Profile"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? (
              <AdminProfile />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="/Admin/MasterData"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? (
              <MasterData />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="/Admin/Jobs"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? <Jobs /> : <Navigate replace to="/" />
          }
        />
        <Route
          path="/Admin/JobSeekers/EditJobseekers/:id"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? (
              <EditJobseekers />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="/Admin/Admins/EditAdmins/:id"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? (
              <EditAdmins />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="/Admin/Admins/CreateAdmins"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? (
              <CreateAdmins />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="/Admin/MasterData/EditMasterData"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? (
              <EditMasterData />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="/Admin/Companies/EditCompanies/:id"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? (
              <EditCompanies />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="/Admin/Companies/EditBlogs"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? (
              <EditBlogs />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="/Admin/Companies/EditEmployee"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? (
              <EditEmployee />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="/Admin/Companies/EditFAQ"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? <EditFAQ /> : <Navigate replace to="/" />
          }
        />
        <Route
          path="/Admin/Companies/EditJobs"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? <EditJobs /> : <Navigate replace to="/" />
          }
        />
        <Route
          path="/Admin/Companies/EditQA"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? <EditQA /> : <Navigate replace to="/" />
          }
        />
        <Route
          path="/Admin/Jobs/EditJobs"
          element={
            state?.auth?.isLoggedIn && state?.auth?.auth?.actorType==="Admin" ? <EditJobs /> : <Navigate replace to="/" />
          }
        />

        <Route
          path="InsightsEmployer"
          element={
            state?.auth?.isLoggedIn  ? (
              <InsightsEmployer />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="CompaniesEmployee"
          element={
            state?.auth?.isLoggedIn ? (
              <CompaniesEmployee />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="CompaniesIndividualPage/:id"
          element={
            state?.auth?.isLoggedIn ? (
              <CompaniesIndividualPage />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="CompaniesWAReview/:id"
          element={
            state?.auth?.isLoggedIn ? (
              <CompaniesWAReview />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
      </Routes>
    </HashRouter>
  );
}
