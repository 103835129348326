import React, { useState, useEffect, useCallback, useMemo } from "react";
import Select from "react-select";
import { NavLink, useNavigate } from "react-router-dom";
import CustomModal from "../CustomModal";
import DashboardLogo from "../../images/dashboard-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { GetEmployeeSkillsPost, Logout } from "../Api/Api";
import { logOutSuccess } from "../Store/auth/action";
import EADLogo from "../../images/eadlogo2.png";
import { GetInitials } from "../Store/Constants";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Badge } from "@mui/material";
export default function NavbarEmployee(props) {
  // React state to manage selected options
  const [selectedOptions, setSelectedOptions] = useState();

  //state value
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const userProfileId = state?.auth?.auth?.userProfileId;

  //nav toggle
  const [Show, setShow] = React.useState(false);

  //navigate

  const naviGation = useNavigate();
  //   const checkActiveUrl = (arr = ["JobsListing", "JobInfo"]) => {
  //     console.log(pathname);
  //     console.log(arr[1].toLowerCase().includes(pathname.toLowerCase()));
  //     const stat = arr.filter((e) =>
  //  {
  //   console.log(e, e.indexOf(pathname));
  //   return   e.indexOf(pathname) >=1}
  //     ).length;
  //     console.log(stat);
  //     return stat;
  //   };
  //logout

  const [allSkills, setAllSkills] = useState([]);

  const fetchSkillsData = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const getEmployeeSkillPost = await GetEmployeeSkillsPost(authKey, userProfileId);
    if (getEmployeeSkillPost && getEmployeeSkillPost.statusCode === 200) {
      let data1 = getEmployeeSkillPost.data
        ?.filter((e) => e.skillType === "unCategorized")
        .map((e) => {
          return e;
        });
      setAllSkills(data1);
    }
  }, [authKey, userProfileId]);


  const [notifyMsg, setNotifyMsg] = useState([])

  const notifyFunction = useCallback(async () => {
    if(allSkills.length){
      let msg = `You have ${allSkills.length} skills in uncategorized type`
     return setNotifyMsg([msg])
    }
  }, [allSkills.length]);

  const dispatch = useDispatch();
  const handleSubmit = async (data) => {
    const token = state?.auth?.auth?.authKey;
    const response = await Logout(token);
    if (response && response.statusCode === 200) {
      dispatch(logOutSuccess(data));
      naviGation("/");
    }
  };
  const NeededKeysForPercentage = useMemo(() => [
    { key: "emailId", label: "Email Id" },
    { key: "genderId", label: "Gender" },
    { key: "fullName", label: "Full Name" },
    { key: "displayName", label: "Display Name" },
    { key: "mobileNumber", label: "Mobile Number" },
    { key: "profileImage", label: "Profile Image" },
    { key: "language", label: "Language" },
    { key: "primaryLocation", label: "Primary Location" },
    { key: "secondaryLocation", label: "Secondary Location" },
  ], []);

  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    if (state?.auth?.auth?.userDetails) {
      const percent = NeededKeysForPercentage.filter((item) => {
        if (Array.isArray(state?.auth?.auth?.userDetails[item.key])) {
          return Boolean(state?.auth?.auth?.userDetails[item.key].length);
        } else {
          return !!state?.auth?.auth?.userDetails[item.key];
        }
      });

      setPercentage(
        Math.round((percent.length / NeededKeysForPercentage.length) * 100)
      );
    }

    return () => {};
  }, [state?.auth?.auth?.userDetails, NeededKeysForPercentage]);
  // Array of all options
  const optionList = [
    { value: "Sentinel Technologies", label: "Sentinel Technologies" },
    { value: "Quantzi", label: "Quantzi" },
    { value: "Infosys", label: "Infosys" },
    { value: "IBM", label: "IBM" },
    { value: "Zoho", label: "Zoho" },
  ];

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }

  useEffect(() => {
    fetchSkillsData();
    notifyFunction();
  }, [fetchSkillsData, notifyFunction]);

  const [AddLanguageModal, setAddLanguageModal] = useState(false);
  const [postJobModal, setpostJobModal] = useState(false);

  let host = window.location.href;
  let component = host?.split("/");

  return (
    <div>
      <div className="topbar">
        <div className="topbar-header">
          <div className="dashboard-logo d-flex align-items-center justify-content-center">
            <NavLink to={"/ProfileEmployee"}>
              {" "}
              <img className="logo-dashboard-img " src={DashboardLogo} alt="" />
            </NavLink>
          </div>
          <div className="dashboard-header d-flex align-items-center  ">
            <div className="d-flex mx-3 justify-content-between center-d-flex-content">
              <div className="dashboard-header-left my-auto ">
                <h3 className="font-weight-bold my-1">{props.title}</h3>
              </div>
              <div className="dashboard-header-center my-auto ">
                <form className="form-inline my-2 my-lg-0">
                  <div className="form-group has-search mr-2">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input
                      autoComplete="off"
                      type="text"
                      className="form-control dashboard-search"
                      placeholder="Search Candidates"
                    />
                    {/* <span className="fa-solid fa-sliders form-control-feedback2"></span> */}
                    <span className="material-symbols-rounded form-control-feedback2">
                      tune
                    </span>
                  </div>
                </form>
              </div>
              <div className="dashboard-header-right my-auto ">
              
                <div
                  className="form-inline my-2 my-lg-0"
                  type="button"
                  id="dropdownMenu1Button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ cursor: "pointer" }}
                >
                  {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn" onClick={() => {
                    setpostJobModal(true);
                  }} >Post a Job</button> */}
                 
                 <span
                    className="material-icons mx-4"
                    style={{ fontSize: "30px", cursor: "pointer" }}
                  >
                     <Badge badgeContent={notifyMsg?.length} color="primary">
                  
                    notifications_none
                    </Badge>
                  </span>
                  <div
                    class="dropdown-menu nav-logout-dropdown"
                    aria-labelledby="dropdownMenu1Button"
                  >
                 {notifyMsg?.map((e)=>(
                  <button class="dropdown-item">{e}</button>
                 ))}   
                </div>
                 
                 
                  <div class="dropdown ">
                    <div
                      className="progress-profile2"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{ cursor: "pointer" }}
                    >
                      <CircularProgressbarWithChildren
                        value={percentage}
                        styles={{
                          position: "absolute",
                          width: "50%",
                          height: "100%",
                          marginTop: "-50%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="title timer"
                          data-from="0"
                          data-to={percentage}
                        >
                          {state?.auth?.auth?.userDetails?.profileImage
                            ? ""
                            : GetInitials(
                                state?.auth?.auth?.userDetails?.fullName
                              )}
                          <img
                            className="PP-upload-img2"
                            src={state?.auth?.auth?.userDetails?.profileImage}
                            alt=""
                          />
                        </span>
                      </CircularProgressbarWithChildren>
                      {/* 
                      <img
                        className="PP-upload-img2"
                        src={state?.auth?.auth?.userDetails?.profileImage}
                        alt=""
                        hidden={!state?.auth?.auth?.userDetails?.profileImage}
                      /> */}
                      {/* <div className="overlay"></div>
                    <div className="left"></div>
                    <div className="right"></div> */}
                    </div>
                    <div
                      class="dropdown-menu nav-logout-dropdown"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <NavLink to="/ProfileEmployee">
                        <button class="dropdown-item">Profile</button>
                      </NavLink>
                      <button class="dropdown-item" onClick={handleSubmit}>
                        Logout
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="responsive-topbar">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container d-flex justify-content-between">
            <div className="dashboard-logo d-flex align-items-center justify-content-center">
              <NavLink to={"/ProfileEmployee"}>
                {" "}
                <img className="logo-dashboard-img " src={EADLogo} alt="" />
              </NavLink>
            </div>
            <div className="d-flex">
              <div className="btn btn-default top-btn-desk">
                <span className="material-symbols-rounded menu-icon">
                  search
                </span>
              </div>
              <button
                onClick={() => {
                  setShow(true);
                }}
                className="btn btn-default top-btn-desk"
                id="menu-toggle"
              >
                <span className="material-symbols-rounded menu-icon">menu</span>
              </button>
            </div>
          </div>
        </nav>
      </div>
      <div className={Show ? "sidebar active" : "sidebar"}>
        <div className="d-flex justify-content-between">
          <NavLink className="res-logo-img" to={"/ProfileEmployee"}>
            <img className="logo-dashboard-img " src={EADLogo} alt="" />
          </NavLink>
          <button
            onClick={() => {
              setShow(false);
            }}
            className="btn btn-default closemenu-toggle"
            id="menu-toggle"
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <ul>
          <NavLink
            to="/JobsListing"
            className={component[4] === "JobInfo" ? "active" : ""}
            // className={checkActiveUrl() ? "active" : ""}
          >
            <span className="material-symbols-rounded">plagiarism</span>
            <li>Jobs</li>
          </NavLink>
          <NavLink to="/ChatEmployee">
            <span className="material-symbols-rounded">sms</span>
            <li>Chat</li>
          </NavLink>
          <NavLink to="/ProfileEmployee">
            <span className="material-symbols-rounded">account_circle</span>
            <li>Profile</li>
          </NavLink>
          <NavLink
            to="/CompaniesEmployee"
            className={
              component[4] === "CompaniesIndividualPage" ||
              component[4] === "CompaniesWAReview"
                ? "active"
                : ""
            }
          >
            <span className="material-symbols-rounded">apartment</span>
            <li>Companies</li>
          </NavLink>
          {/* <NavLink to="/Reviews"><span className="material-symbols-rounded">3p</span><li>Reviews</li></NavLink>
          <NavLink to="/QA"><span className="material-symbols-rounded">quiz</span><li>Q&A</li></NavLink>
          <NavLink to="/FAQ"><span className="material-symbols-rounded">question_mark</span><li>FAQ</li></NavLink> */}
          <NavLink to="" id="logout-hover" onClick={handleSubmit}>
            <span className="material-symbols-rounded">logout</span>
            <li>Logout</li>
          </NavLink>
        </ul>
      </div>
      <CustomModal
        open={AddLanguageModal}
        onClickOutside={() => {
          setAddLanguageModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Add Language
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-0">
                <div className="col-lg-5">
                  <div className="google-input">
                    <label htmlFor="template-input" className="z-index-value">
                      Language*
                    </label>
                    <div className="dropdown-container">
                      <Select
                        options={optionList}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "6px",
                          zIndex: 3,
                          colors: {
                            ...theme.colors,
                            primary25: "#DFF1F1",
                            primary: "#00ABAD",
                          },
                        })}
                        styles={{
                          menu: (provided, state) => ({
                            ...provided,
                            zIndex: "5 !important",
                          }),
                        }}
                        placeholder="Choose your languages"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isMulti
                      />
                    </div>
                  </div>
                  <div className="modalAnswer mt-3">
                    <label
                      htmlFor="template-input"
                      className="z-index-value employeeNavbar-labels"
                    >
                      Communication skill
                    </label>
                    <div className="btn-group-toggle" data-toggle="buttons">
                      <label className="btn active addLanguage-skills mr-2">
                        <input autoComplete="off" type="checkbox" checked />{" "}
                        Speak
                      </label>
                      <label className="btn active addLanguage-skills mr-2">
                        <input autoComplete="off" type="checkbox" checked />{" "}
                        Read
                      </label>
                      <label className="btn active addLanguage-skills">
                        <input autoComplete="off" type="checkbox" checked />{" "}
                        Write
                      </label>
                    </div>
                  </div>
                  <div className="modalAnswer ml-1 mt-5">
                    <label
                      htmlFor="template-input"
                      className="z-index-value employeeNavbar-labels"
                    >
                      Proficiency
                    </label>
                    <div className="form-check">
                      <input
                        autoComplete="off"
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                      />
                      <label className="form-check-label" for="gridCheck1">
                        Basic
                      </label>
                    </div>
                    <div className="form-check mt-1">
                      <input
                        autoComplete="off"
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                      />
                      <label className="form-check-label" for="gridCheck1">
                        Intermediate
                      </label>
                    </div>
                    <div className="form-check mt-1">
                      <input
                        autoComplete="off"
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                      />
                      <label className="form-check-label" for="gridCheck1">
                        Expert
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setAddLanguageModal(false);
                  }}
                >
                  Close
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn">
                  ADD
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={postJobModal}
        onClickOutside={() => {
          setpostJobModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">Post Job</h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content">
                <div className="col-lg-12">
                  <form>
                    <div className="form-container py-1">
                      <div className="row ">
                        <div className="col-lg-6 px-5 pb-4 border-right">
                          <div className="google-input">
                            <div className="google-input">
                              <input
                                autoComplete="off"
                                type="text"
                                id="template-input"
                                placeholder="Choose job title"
                              />
                              <label htmlFor="template-input">Job Title</label>
                            </div>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Employment Type
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose employment type"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Work Place Type
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose job type"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Location
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose your job location"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Schedule
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Enter the salary range low to high"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 px-5 pb-4">
                          <div className="google-input">
                            {/* <input autoComplete="off" type="text" id="template-input" placeholder="Type your question" />
                            <label htmlFor="template-input">
                            Salary Range
                            </label> */}
                            <label
                              htmlFor="template-input"
                              style={{ zIndex: "1000" }}
                            >
                              Salary Range
                            </label>
                            <div className="input-group">
                              <input
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                aria-label="Text input with segmented dropdown button"
                              />
                              <div className="input-group-append">
                                <select
                                  className="custom-select"
                                  style={{
                                    height: "45px",
                                    borderRadius: "0px 0.25rem 0.25rem 0px",
                                  }}
                                  id="inputGroupSelect01"
                                >
                                  <option selected>Per year</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Experience level
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose experience level"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>

                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Must Have Skills
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose must have skills"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Nice To Have Skills
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose nice to have skills"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                          {/* <div className="google-input">
                            <label htmlFor="template-input" className="z-index-value">
                              Headquarters Location
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: '6px',
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#DFF1F1',
                                    primary: '#00ABAD',
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Enter your Headquarters Location"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setpostJobModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">
                  Continue
                </button>
                <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn">
                  Publish
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
