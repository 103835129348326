import React, { useEffect, useCallback, useState } from "react";
import NavbarDashboard from "./NavbarDashboard";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { GetNavbarCount } from "../Api/Api";
import { useSelector } from "react-redux";
import Roboimg2 from "../../images/roboimg-outerborder.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Posted Jobs",
      data: [677, 888, 888, 333],
      borderColor: "#00abad",
      backgroundColor: "#00abad",
    },
    {
      label: "Company Views",
      data: [550, 988, 3, 877],
      borderColor: "#5800ad",
      backgroundColor: "#5800ad",
    },
    {
      label: "Job Posting Views",
      data: [370, 777, 340, 707],
      borderColor: "#ad00ab",
      backgroundColor: "#ad00ab",
    },
    {
      label: "New Applicants",
      data: [0, 308, 990, 77],
      borderColor: "#ad0054",
      backgroundColor: "#ad0054",
    },
  ],
};
export default function InsightsEmployer() {
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const companyProfileId = state?.auth?.auth?.userDetails?.companyProfileId;
  const [navbarCount, setNavbarCount] = useState([]);

  //navbar
  const handleNavbarCount = useCallback(async () => {
    if (!authKey || !companyProfileId) return;

    const response = await GetNavbarCount(authKey, companyProfileId);
    if (response && response.statusCode === 200) {
      setNavbarCount(response.data);
    }
  }, [authKey, companyProfileId]);

  useEffect(() => {
    handleNavbarCount();
    return () => {};
  }, [handleNavbarCount]);

  return (
    <div className="dashboard-bg">
      <NavbarDashboard />
      <div className="main">
        <div className="insights-section scroll-content ">
          {state?.auth?.auth?.userDetails?.status === "Active" ||
          state?.auth?.auth?.userDetails?.approved === true ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-5 px-1 mt-1">
                  <div className="insights-overall-jobs py-2">
                    <h6 className="text-center font-weight-bold py-2">
                      Overall Jobs posted and their performance
                    </h6>
                    <div className="container-fluid px-1">
                      <div className="d-flex justify-content-center">
                        <div className=" mx-1 px-0 py-1 bg-color bg-pink">
                          <div className="pt-2 text-center">
                            <small className="font-weight-bold">Posted</small>
                            <h4 className="text">{navbarCount.jobPostCount}</h4>
                          </div>
                        </div>
                        <div className=" mx-1 px-0 py-1 bg-color bg-pink">
                          <div className="pt-2 text-center">
                            <small className="font-weight-bold">Applied</small>
                            <h4 className="text">{navbarCount.Applied}</h4>
                          </div>
                        </div>
                        <div className=" mx-1 px-0 py-1 bg-color bg-pink">
                          <div className="pt-2 text-center">
                            <small className="font-weight-bold">
                              InProgress
                            </small>
                            <h4 className="text">{navbarCount.Inprogress}</h4>
                          </div>
                        </div>
                        <div className=" mx-1 px-0 py-1 bg-color bg-pink">
                          <div className="pt-2 text-center">
                            <small className="font-weight-bold">Hired</small>
                            <h4 className="text">{navbarCount.Selected}</h4>
                          </div>
                        </div>
                        <div className=" mx-1 px-0 py-1 bg-color bg-pink">
                          <div className="pt-2 text-center">
                            <small className="font-weight-bold">Rejected</small>
                            <h4 className="text">{navbarCount.Rejected}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 px-1 mt-1">
                  <div className="insights-rtp py-2">
                    <h6 className="text-center font-weight-bold py-2">
                      Reviews and their performance{" "}
                    </h6>
                    <div className="container-fluid px-1">
                      <div className="d-flex justify-content-center">
                        <div className=" mx-1 px-0 py-1 bg-color bg-vio">
                          <div className="pt-2 text-center">
                            <small className="font-weight-bold">Approved</small>
                            <h4 className="text">{navbarCount.Approve}</h4>
                          </div>
                        </div>
                        <div className=" mx-1 px-0 py-1 bg-color bg-vio">
                          <div className="pt-2 text-center">
                            <small className="font-weight-bold">Rejeced</small>
                            <h4 className="text">{navbarCount.Disapprove}</h4>
                          </div>
                        </div>
                        <div className=" mx-1 px-0 py-1 bg-color bg-vio">
                          <div className="pt-2 text-center">
                            <small className="font-weight-bold">
                              Awaiting review
                            </small>
                            <h4 className="text">
                              {navbarCount.waitingforReview}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 px-1 mt-1">
                  <div className="insights-qtp py-2">
                    <h6 className="text-center font-weight-bold py-2">
                      Questions and their performance{" "}
                    </h6>
                    <div className="container-fluid px-1">
                      <div className="d-flex justify-content-center">
                        <div className=" mx-1 px-0 py-1 bg-color bg-vio">
                          <div className="pt-2 text-center">
                            <small className="font-weight-bold">Asked</small>
                            <h4 className="text2">
                              {navbarCount.QATotalCount}
                            </h4>
                          </div>
                        </div>
                        <div className=" mx-1 px-0 py-1 bg-color bg-vio">
                          <div className="pt-2 text-center">
                            <small className="font-weight-bold">Answered</small>
                            <h4 className="text2">{navbarCount.Answered}</h4>
                          </div>
                        </div>
                        <div className=" mx-1 px-0 py-1 bg-color bg-vio">
                          <div className="pt-2 text-center">
                            <small className="font-weight-bold">
                              Unanswered
                            </small>
                            <h4 className="text2">{navbarCount.UnAnswered}</h4>
                          </div>
                        </div>
                        {/* <div className=" mx-1 px-0 py-1 bg-color bg-vio">
                        <div className="pt-2 text-center">
                          <small className="font-weight-bold">New Answers</small>
                          <h4 className="text2">50</h4>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 px-1 my-2">
                  <div className="insights-map-section p-2">
                    <div className="d-flex  justify-content-between ">
                      <h5 className="mx-2 font-weight-bold">
                        Account Insights
                      </h5>
                      <select
                        className="form-select text-blue postjob-btn mx-2"
                        aria-label="Default select example"
                      >
                        <option>Last 30 Days</option>
                        <option value="1">Last 20 Days</option>
                        <option value="2">Last 10 Days</option>
                        <option value="3">Last 5 Days</option>
                      </select>
                      {/* <div className="d-flex align-items-center color-based-map">
                      <li>
                        <small className="color-green font-weight-bold">Posted Jobs</small>
                      </li>
                      <li>
                        <small className="color-blue font-weight-bold">Company Views</small>
                      </li>
                      <li>
                        <small className="color-violet font-weight-bold">Job Posting Views</small>
                      </li>
                      <li>
                        <small className="color-brown font-weight-bold">New Applicants</small>
                      </li>
                    </div> */}
                    </div>
                    <div className=" mx-2 mb-4">
                      <Line options={options} data={data} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 ">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-6 px-1 my-2 ">
                      <div className="insights-date-section1 p-2">
                        <h6 className="text-center font-weight-bold  px-2">
                          Average time to convert the candidate{" "}
                        </h6>
                        <div className="bg-color-blue text-center my-2">
                          <h1 className="font-weight-bold">14</h1>
                          <p>Days</p>
                        </div>
                        <small className="text-center">
                          New to hired status
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6 px-1 my-2">
                      <div className="insights-date-section2 p-2">
                        <h6 className="text-center font-weight-bold  px-2">
                          Average age of job posting{" "}
                        </h6>
                        <div className="bg-color-green-insights my-2 text-center">
                          <h1 className="font-weight-bold">30</h1>
                          <p>Days</p>
                        </div>
                        <small className="text-center">
                          Average age of job posting
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-12 px-1">
                      <div className="insights-rejected-reason ">
                        <div className="heading py-1">
                          <h6 className="text-center font-weight-bold">
                            Top 10 Reason of Rejections
                          </h6>
                        </div>
                        <div className="accordion" id="accordionExample">
                          <div className="card my-1">
                            <div className="card-header p-0" id="headingOne">
                              <h2 className="my-0">
                                <button
                                  className="btn py-2 d-flex  btn-block text-left"
                                  style={{ backgroundColor: "#fff" }}
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseOne"
                                  aria-expanded="false"
                                  aria-controls="collapseOne"
                                >
                                  01 Reason Type{" "}
                                  <span className="material-symbols-rounded align-middle ml-auto">
                                    expand_more
                                  </span>
                                </button>
                              </h2>
                            </div>

                            <div
                              id="collapseOne"
                              className="collapse"
                              aria-labelledby="headingOne"
                              data-parent="#accordionExample"
                            >
                              <div className="card-body text-left">
                                Some placeholder content for the first accordion
                                panel. This panel is shown by default, thanks to
                                the <code>.show</code> className.
                              </div>
                            </div>
                          </div>
                          <div className="card my-1">
                            <div className="card-header p-0" id="headingTwo">
                              <h2 className="my-0">
                                <button
                                  className="btn py-2 d-flex  btn-block text-left collapsed"
                                  style={{ backgroundColor: "#fff" }}
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  02 Reason Type{" "}
                                  <span className="material-symbols-rounded align-middle ml-auto">
                                    expand_more
                                  </span>
                                </button>
                              </h2>
                            </div>
                            <div
                              id="collapseTwo"
                              className="collapse"
                              aria-labelledby="headingTwo"
                              data-parent="#accordionExample"
                            >
                              <div className="card-body text-left">
                                Some placeholder content for the second
                                accordion panel. This panel is hidden by
                                default.
                              </div>
                            </div>
                          </div>
                          <div className="card my-1">
                            <div className="card-header p-0" id="headingThree">
                              <h2 className="my-0">
                                <button
                                  className="btn py-2 d-flex  btn-block text-left collapsed"
                                  style={{ backgroundColor: "#fff" }}
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseThree"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  03 Reason Type{" "}
                                  <span className="material-symbols-rounded align-middle ml-auto">
                                    expand_more
                                  </span>
                                </button>
                              </h2>
                            </div>
                            <div
                              id="collapseThree"
                              className="collapse"
                              aria-labelledby="headingThree"
                              data-parent="#accordionExample"
                            >
                              <div className="card-body text-left">
                                And lastly, the placeholder content for the
                                third and final accordion panel. This panel is
                                hidden by default.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal-body p-3 ">
              <div className="modalHeader">
                <h6 className="text-center font-weight-bold my-2">
                  <img
                    style={{
                      width: "85px",
                      textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px;",
                    }}
                    src={Roboimg2}
                    alt=""
                  />
                </h6>
              </div>
              <form className="container">
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="col-lg-8 pb-3">
                    <p className=" text-center">
                      Your account is still pending for approval.
                    </p>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
