import React, { useEffect, useCallback, useState } from "react";
import CustomModal from "../CustomModal";
import Navbar from "../navbar";
import BlogsImg from "../../images/blogs.png";
import BlogsImg2 from "../../images/blogs2.png";
import RoboImg from "../../images/roboimg.png";
import BlogIndi from "../../images/blog-indi.png";
import Footer from "../footer";
import { useParams } from "react-router-dom";
import { BlogDetail } from "../Api/Api";
import moment from "moment";
export default function BlogsIndividual() {
  const [writeBlogModal, setwriteBlogModal] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const { id } = useParams();
  //const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyUHJvZmlsZUlkIjoiNDQyMWVkYzMtNWIyZi00YWZhLTkwM2MtZjZmNzRiNzRkYTRjIiwiaWF0IjoxNjczMzM0MzI2fQ.EoetbLf6azY15EnWKOikJ1apLyBEqnmpDsQ44wDvJTE";

  const handleBlogDetail = useCallback(async () => {
    const response = await BlogDetail(id);
    if (response && response.statusCode === 200) {
      setBlogData(response.data);
    }
  }, [id]);

  useEffect(() => {
    handleBlogDetail();
    return () => {};
  }, [handleBlogDetail]);

  return (
    <div className="">
      <Navbar />
      <div class="main ce-section BlogsHome">
        <div className="py-1 ">
          <div className="container py-4">
            <div className="row">
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="faq-heading reviews-heading-comp">
                      <div className="container">
                        <div className=" d-flex justify-content-between  my-3 align-items-center">
                          <div className="d-flex align-items-center">
                            <img
                              className="blog-indi-img"
                              src={RoboImg}
                              alt=""
                            />
                            <div className="">
                              <h6 className="font-weight-bold">
                                {blogData.name}
                              </h6>
                              <h6 className="">
                                {moment(blogData.createdAt).format("ll")}
                              </h6>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="material-symbols-rounded icon-size align-middle">
                              {" "}
                              share
                            </span>
                            <span className="material-symbols-rounded icon-size align-middle px-3">
                              {" "}
                              bookmark
                            </span>
                            <span className="material-symbols-rounded icon-size align-middle">
                              {" "}
                              more_vert
                            </span>
                          </div>
                        </div>
                        <div className="">
                          <h5 className="font-weight-bold my-3">
                            Now you can have a Googler review your UX portfolio
                          </h5>
                          <h6 className="">{blogData.context}</h6>
                          <img
                            className="w-100 h-auto my-3"
                            src={BlogsImg}
                            alt=""
                          />
                          <div className="d-none">
                            <h6 className="">
                              Since Google launched its UX Design Certificate on
                              Coursera, we at the UX Collective have seen an
                              exciting influx of recently graduated students who
                              are looking to learn more about our field. Most
                              students are coming in through our group on
                              Linkedin, and they are generally looking to
                              network with other designers and get a bit more
                              guidance when it comes to the next steps in their
                              careers.
                            </h6>
                            <h6 className="">
                              It’s really inspiring to see a new generation of
                              designers who are looking to change the world with
                              their creativity and passion — and we know
                              sometimes technology lends a hand. Google also
                              wants to do its part to make sure technology
                              brings opportunity to everyone.
                            </h6>
                            <img
                              className="w-100 h-auto my-3"
                              src={BlogIndi}
                              alt=""
                            />
                            <h6>
                              The Google UX Design Certificate introduces
                              learners to the foundations of the design process,
                              including empathizing with users, building
                              wireframes and prototypes, and conducting research
                              to test designs. The certificate was developed and
                              is taught by Google employees working in the
                              field.{" "}
                            </h6>
                            <h5 className="font-weight-bold my-3">
                              The big news?
                            </h5>
                            <h6>
                              This is your chance to get coaching and insights
                              that can transform your portfolio and help you
                              launch an exciting career in UX. The first 50
                              entries to submit this form will have their
                              portfolio reviewed by a Googler. Feedback will be
                              provided between August 1st and September 30th,
                              2021.
                            </h6>
                            <div className="my-4">
                              <h6 className="font-weight-bold">
                                To qualify, you must:
                              </h6>
                              <h6>Be aged 18 years or over</h6>
                              <h6>
                                Complete all seven courses of the Google UX
                                Design Certificate, or planned to complete no
                                later
                              </h6>
                              <h6>than September 30,</h6>
                              <h6>
                                2021 Be a follower of the UX Collective
                                publication
                              </h6>
                            </div>
                            <div className="my-4">
                              <h6 className="font-weight-bold">
                                Link to participate:
                              </h6>
                              <h6>
                                <a href="#top">
                                  <u>
                                    UX Collective+ Google UX Design Certificate
                                    Portfolio Submission
                                  </u>
                                </a>
                              </h6>
                            </div>
                            <h6 className="my-4">
                              <span className="bg-gray py-1 px-2">
                                UX Design
                              </span>
                            </h6>
                            <div className="d-flex my-4 justify-content-between">
                              <div className="d-flex">
                                <p className="mb-0 mr-3 d-flex align-items-center">
                                  <span className="material-symbols-rounded  icon-size faq-thumbs-up align-middle">
                                    thumb_up
                                  </span>
                                </p>
                                <span
                                  className="badge  my-auto px-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  1000
                                </span>
                                <p className="mb-0 mx-3 d-flex align-items-center">
                                  <span className="material-symbols-rounded icon-size  chat-bubble align-middle">
                                    chat_bubble
                                  </span>
                                </p>
                                <span
                                  className="badge  my-auto px-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  500
                                </span>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <span className="material-symbols-rounded icon-size align-middle">
                                  {" "}
                                  share
                                </span>
                                <span className="material-symbols-rounded icon-size align-middle px-3">
                                  {" "}
                                  bookmark
                                </span>
                                <span className="material-symbols-rounded icon-size align-middle">
                                  {" "}
                                  more_vert
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="d-block py-5">
                            <div className="container">
                              <div className="row d-flex align-items-center justify-content-center  mt-0">
                                <div className="col-lg-8 pb-3">
                                  <h5 className="font-weight-bold text-center py-3">
                                    You must be an EADJobs.us member to view
                                    confidential blogs
                                  </h5>
                                  <div className="text-center">
                                    <button className="btn btn-light login-btn my-2  my-sm-0 mx-auto ">
                                      Sign Up for free
                                    </button>
                                  </div>
                                  <p className=" text-center pt-4">
                                    Already a member?{" "}
                                    <span>
                                      <a
                                        className="text-green"
                                        style={{ color: "#00abad" }}
                                        href="#top"
                                      >
                                        Sign In
                                      </a>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="faq-heading mt-4 reviews-heading-comp">
                      <div className="container">
                        <div className="">
                          <h5 className="font-weight-bold my-3">
                            Responses (164)
                          </h5>
                          <div className="my-3 blogs-profile  align-items-center">
                            <div className=" mt-2">
                              <div className="modalDisapprove-details py-2 border-0 d-flex  justify-content-end ">
                                <button
                                  type="button"
                                  className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                                >
                                  Rich Text Editor
                                  <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                                    lock
                                  </span>
                                </button>
                              </div>
                              <div className="form-group">
                                <textarea
                                  className="form-control bg-white modalDisapprove-textarea"
                                  id="exampleFormControlTextarea2"
                                  style={{ resize: "auto" }}
                                  rows="9"
                                ></textarea>
                              </div>
                            </div>
                            <div className="blogs-profile-img px-2 d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center">
                                <img
                                  className="blog-indi-img"
                                  src={RoboImg}
                                  alt=""
                                />
                                <h6 className="font-weight-bold">
                                  Kristopher Saber
                                </h6>
                              </div>
                              <button
                                type="button"
                                class="btn btn-light login-btn"
                              >
                                Comment
                              </button>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div class="form-group mb-0">
                              <select
                                class="form-control  bg-light font-weight-bold text-green"
                                id="exampleFormControlSelect1"
                              >
                                <option>Most Relevant</option>
                                <option>Relevant</option>
                              </select>
                            </div>
                          </div>
                          <hr />
                          <div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center mt-3">
                                <img
                                  className="w-25 h-auto"
                                  src={RoboImg}
                                  alt=""
                                />
                                <div className="">
                                  <h6 className="font-weight-bold">Saber</h6>
                                  <h6 className="">Jub 14, 2022</h6>
                                </div>
                              </div>
                              <span className="material-symbols-rounded icon-size align-middle">
                                {" "}
                                more_vert
                              </span>
                            </div>
                            <h6 className="my-3">
                              Emily, this is a brilliant concept. It's
                              fantastic! Very useful information! Thank you for
                              sharing!
                            </h6>
                            <div className="d-flex my-4 justify-content-between">
                              <div className="d-flex">
                                <p className="mb-0 mr-3 d-flex align-items-center">
                                  <span className="material-symbols-rounded  icon-size faq-thumbs-up align-middle">
                                    thumb_up
                                  </span>
                                </p>
                                <span
                                  className="badge  my-auto px-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  1000
                                </span>
                                <p className="mb-0 mx-3 d-flex align-items-center">
                                  <span className="material-symbols-rounded active icon-size  chat-bubble align-middle">
                                    chat_bubble
                                  </span>
                                </p>
                                <span
                                  className="badge  my-auto px-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  5 Reply
                                </span>
                              </div>
                            </div>
                            <div className="blogs-profile">
                              <div className=" mt-2">
                                <div className="modalDisapprove-details py-2 border-0 d-flex  justify-content-end ">
                                  <button
                                    type="button"
                                    className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                                  >
                                    Rich Text Editor
                                    <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                                      lock
                                    </span>
                                  </button>
                                </div>
                                <div className="form-group">
                                  <textarea
                                    className="form-control bg-white modalDisapprove-textarea"
                                    id="exampleFormControlTextarea2"
                                    style={{ resize: "auto" }}
                                    rows="9"
                                    placeholder="Replying to Krishtopher"
                                  ></textarea>
                                </div>
                              </div>
                              <div className="blogs-profile-img px-2 d-flex justify-content-end align-items-center">
                                <div className="d-flex align-items-center">
                                  <button
                                    type="button"
                                    class="btn btn-light mr-2 postjob-btn"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-light login-btn"
                                  >
                                    Reply
                                  </button>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                          <div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center mt-3">
                                <img
                                  className="w-25 h-auto"
                                  src={RoboImg}
                                  alt=""
                                />
                                <div className="">
                                  <h6 className="font-weight-bold">Saber</h6>
                                  <h6 className="">Jub 14, 2022</h6>
                                </div>
                              </div>
                              <span className="material-symbols-rounded icon-size align-middle">
                                {" "}
                                more_vert
                              </span>
                            </div>
                            <h6 className="my-3">
                              Emily, this is a brilliant concept. It's
                              fantastic! Very useful information! Thank you for
                              sharing!
                            </h6>
                            <div className="d-flex my-4 justify-content-between">
                              <div className="d-flex">
                                <p className="mb-0 mr-3 d-flex align-items-center">
                                  <span className="material-symbols-rounded  icon-size faq-thumbs-up align-middle">
                                    thumb_up
                                  </span>
                                </p>
                                <span
                                  className="badge  my-auto px-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  1000
                                </span>
                                <p className="mb-0 mx-3 d-flex align-items-center">
                                  <span className="material-symbols-rounded icon-size  chat-bubble align-middle">
                                    chat_bubble
                                  </span>
                                </p>
                                <span
                                  className="badge  my-auto px-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  5 Reply
                                </span>
                              </div>
                            </div>
                            <hr />
                          </div>
                          <div className="text-center my-4">
                            <button
                              type="button"
                              class="btn btn-light login-btn"
                            >
                              Read More Comments
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3  px-1">
                <div className="reviews-heading reviews-heading-comp mb-3 py-3">
                  <h6 className="font-weight-bold">
                    Lorem Ipsum is simply dummy text of the
                  </h6>
                  <p className="">
                    <small>
                      Ask a question about working or interviewing at Cognizant
                      Technology Solutions. Our community is ready to answer
                    </small>
                  </p>
                  <button
                    type="button"
                    className="btn btn-light mx-2  login-btn"
                    onClick={() => {
                      setwriteBlogModal(true);
                    }}
                  >
                    Write Blog
                  </button>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <h5 className="mx-auto font-weight-bold">
                      Relevant Stories
                    </h5>
                    <div
                      className="col-lg-12 jobs-padding col-md-6"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="jobs-content">
                        <div className="d-flex">
                          <img className="w-100 h-auto" src={BlogsImg} alt="" />
                        </div>
                        <div className="redirect-link">
                          <h5 className="font-weight-bold my-3">
                            Now you can have a Googler review your UX portfolio
                          </h5>
                          <h6 className="mb-1 ">
                            Readers of the UX Collective who are taking the
                            Google UX Design Certificate can now enter for a
                            chance to get portfolio feedback from a Googler.
                          </h6>
                          <div className="d-flex align-items-center mt-2">
                            <img className="w-25 h-auto" src={RoboImg} alt="" />
                            <div className="">
                              <h6 className="font-weight-bold">
                                Kristopher Saber
                              </h6>
                              <h6 className="">Jub 14, 2022</h6>
                            </div>
                          </div>
                          <div className="d-flex mt-3 justify-content-between">
                            <h6>
                              <span className="bg-gray py-1 px-2">
                                UX Design
                              </span>
                            </h6>
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="material-symbols-rounded icon-size align-middle">
                                {" "}
                                share
                              </span>
                              <span className="material-symbols-rounded icon-size align-middle px-3">
                                {" "}
                                bookmark
                              </span>
                              <span className="material-symbols-rounded icon-size align-middle">
                                {" "}
                                more_vert
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-12 jobs-padding col-md-6"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="jobs-content">
                        <div className="d-flex">
                          <img
                            className="w-100 h-auto"
                            src={BlogsImg2}
                            alt=""
                          />
                        </div>
                        <div className="redirect-link">
                          <h5 className="font-weight-bold my-3 ">
                            Now you can have a Googler review your UX portfolio
                          </h5>
                          <h6 className="mb-1 ">
                            Readers of the UX Collective who are taking the
                            Google UX Design Certificate can now enter for a
                            chance to get portfolio feedback from a Googler.
                          </h6>
                          <div className="d-flex align-items-center mt-2">
                            <img className="w-25 h-auto" src={RoboImg} alt="" />
                            <div className="">
                              <h6 className="font-weight-bold">
                                Kristopher Saber
                              </h6>
                              <h6 className="">Jub 14, 2022</h6>
                            </div>
                          </div>
                          <div className="d-flex mt-3 justify-content-between">
                            <h6>
                              <span className="bg-gray py-1 px-2">
                                UX Design
                              </span>
                            </h6>
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="material-symbols-rounded icon-size align-middle">
                                {" "}
                                share
                              </span>
                              <span className="material-symbols-rounded icon-size align-middle px-3">
                                {" "}
                                bookmark
                              </span>
                              <span className="material-symbols-rounded icon-size align-middle">
                                {" "}
                                more_vert
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-light mx-auto mt-3  login-btn"
                    >
                      Read More Stories
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <CustomModal
        open={writeBlogModal}
        onClickOutside={() => {
          setwriteBlogModal(false);
        }}
      >
        <div className="modal-content w-75  m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                onClick={() => {
                  setwriteBlogModal(false);
                }}
              >
                close
              </span>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <h5 className="font-weight-bold text-center py-3">
                    You must be an EADJobs.us member to write blogs
                  </h5>
                  <div className="text-center">
                    <button className="btn btn-light login-btn my-2  my-sm-0 mx-auto ">
                      Sign Up for free
                    </button>
                  </div>
                  <p className=" text-center pt-4">
                    Already a member?{" "}
                    <span>
                      <a className="text-green" href="#top">
                        Sign In
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
