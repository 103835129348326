import React, { useEffect, useCallback, useState } from "react";
import NavbarEmployee from "../NavbarEmployee";
import Select from "react-select";
import "react-phone-input-2/lib/material.css";
import CustomModal from "../../CustomModal";
import {
  ActiveLogPost,
  ApplyJobEmployee,
  GetActivityLog,
  GetAllCoverLetter,
  GetAllResume,
  GetParticularJob,
} from "../../Api/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import Roboimg2 from "../../../images/roboimg-outerborder.png";
import { toast, ToastContainer } from "react-toastify";
import { GetInitials } from "../../Store/Constants";
import { useNavigate } from "react-router-dom";

export default function CandidateDetailsEmployee() {
  // React state to manage selected options
  const [selectedOptions, setSelectedOptions] = useState();

  // Array of all options
  const optionList = [
    { value: "Sentinel Technologies", label: "Sentinel Technologies" },
    { value: "Quantzi", label: "Quantzi" },
    { value: "Infosys", label: "Infosys" },
    { value: "IBM", label: "IBM" },
    { value: "Zoho", label: "Zoho" },
  ];

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }
  const [RejectionModal, setRejectionModal] = useState(false);
  const [UpdateStatusModal, setUpdateStatusModal] = useState(false);
  const [SendMessageModal, setSendMessageModal] = useState(false);
  const [resumeListModal, setResumeListModal] = useState(false);
  const [coverLetterListModal, setCoverLetterListModal] = useState(false);
  //navigation

  const navigation = useNavigate();

  //state value
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const userProfileId = state?.auth?.auth?.userProfileId;

  let host = window.location.href;
  let jobid = host?.split("/");

  const [getParticularJob, setGetParticularJob] = useState([]);

  const fetchParticularJob = useCallback(async () => {
    if (!authKey || !userProfileId) return;
    const jobId = jobid[5];

    const getJobPost = await GetParticularJob(authKey, jobId, userProfileId);
    let data1 = getJobPost.data;
    setGetParticularJob(data1);
  }, [authKey, userProfileId, jobid]);

  useEffect(() => {
    fetchParticularJob();
  }, [fetchParticularJob]);

  //Apply Job
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  const [resumeData, setResumeData] = useState(null);
  const [coverLetterData, setCoverLetterData] = useState(null);
  const [getCoverLetter, setGetCoverLetter] = useState([]);
  const [getResume, setGetResume] = useState([]);

  const fetchAllCoverLetterData = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const getEmployeeSkills = await GetAllCoverLetter(authKey, userProfileId);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      setGetCoverLetter(getEmployeeSkills.data);
    }
  }, [authKey, userProfileId]);

  useEffect(() => {
    fetchAllCoverLetterData();
  }, [fetchAllCoverLetterData]);

  const fetchAllResumeData = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const getEmployeeSkills = await GetAllResume(authKey, userProfileId);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      setGetResume(getEmployeeSkills.data);
    }
  }, [authKey, userProfileId]);

  useEffect(() => {
    fetchAllResumeData();
  }, [fetchAllResumeData]);

  const handleActivityLog = async () => {
    const token = state?.auth?.auth?.authKey;
    let Payload = {
      jobSeekerId: state?.auth?.auth?.userProfileId,
      employerId: getParticularJob?.companyId,
      jobId: getParticularJob?.jobId,
      jobStatus: "Applied",
    };
    const response = await ActiveLogPost(Payload, token);

    if (response && response.statusCode === 200) {
      fetchActivityLog();
      fetchParticularJob();
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const ApplyJobPost = async () => {
    if (resumeData?.resumeId && coverLetterData?.userCoverLetterId) {
      const Payload = {
        userProfileId: state?.auth?.auth?.userProfileId,
        jobId: jobid[5],
        resumeId: resumeData.resumeId,
        userCoverLetterId: coverLetterData.userCoverLetterId,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await ApplyJobEmployee(Payload, token);
      if (response && response.statusCode === 200) {
        handleActivityLog();
        showToastSuccessMsg(response.message);
        setCoverLetterData(null);
        setResumeData(null);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else if (!resumeData?.resumeId) {
      showToastErrorMsg("Select Resume before apply job");
    } else if (!coverLetterData?.userCoverLetterId) {
      showToastErrorMsg("Select Cover Letter before apply job");
    }
  };

  const [getActivityMsg, setGetActivtyMsg] = useState([]);

  const fetchActivityLog = useCallback(async () => {
    if (!authKey || !userProfileId) return;
    const jobId = jobid[5];

    const getJobPost = await GetActivityLog(authKey, jobId, userProfileId);
    setGetActivtyMsg(getJobPost.data);
  }, [authKey, userProfileId, jobid]);

  useEffect(() => {
    fetchActivityLog();
  }, [fetchActivityLog]);

  return (
    <div className="dashboard-bg">
      <NavbarEmployee />
      <div className="main">
        <div className="candidate-details-section scroll-content-cde">
          <div className="candidate-profile-section ">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 px-1">
                  <div className="Jobs-details-skills   p-3">
                    <div className="d-flex align-items-center">
                      <div className="container">
                        <div className="row mx-1">
                          <h5 className="font-weight-bold mr-2">
                            {getParticularJob?.jobName}
                          </h5>
                          <div className="match d-flex align-items-center ">
                            <small className="color-green">
                              {getParticularJob?.profileMatch}% Match
                            </small>
                          </div>
                        </div>
                        <p className="mb-0 mx-1">
                          {getParticularJob?.companyName}
                        </p>
                        <h6>
                          <span className="material-symbols-rounded filled-icon icon-middle">
                            location_on
                          </span>

                          {getParticularJob?.locationId}
                        </h6>
                      </div>
                      <div className=" d-flex align-items-center justify-content-center mx-2">
                        {getParticularJob?.companyLogo === "" ? (
                          <h1 className="compworked-img font-weight-bold">
                            {GetInitials(getParticularJob?.companyName)}
                          </h1>
                        ) : (
                          <img
                            className="compworked-img"
                            src={getParticularJob?.companyLogo}
                            alt=""
                          />
                        )}
                        {/* <img
                          className="compworked-img"
                          src={getParticularJob?.companyLogo}
                          alt=""
                        /> */}
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center mx-3">
                      <div>
                        <small className="mx-2">
                          <span>
                            Posted date :&nbsp;
                            {moment(getParticularJob?.createdAt).format(
                              "MMM DD,yyyy"
                            )}
                          </span>
                        </small>
                        <small className="mx-2 dis-blk">
                          <span>
                            Job Applicants : {getParticularJob?.totalApplicants}
                          </span>
                        </small>
                      </div>
                      <div>
                        {/* <span className="material-symbols-rounded icon-middle mx-2">visibility_off</span>
												<span className="material-symbols-rounded icon-middle mx-2">bookmark</span> */}
                        {getParticularJob?.alreadyApplied === true ? (
                          <span className="btnapplied">APPLIED</span>
                        ) : (
                          <button
                            className="btn btn-light my-2 my-sm-0 mx-2 login-btn"
                            onClick={ApplyJobPost}
                          >
                            APPLY
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="Jobs-details-skills  my-2 p-3">
                    <div className="container">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <th className="py-2">Job Details</th>
                          </tr>
                          <tr>
                            <td className="w-50">Job Title</td>
                            <th>:</th>
                            <th>{getParticularJob?.jobName}</th>
                          </tr>
                          <tr>
                            <td className="w-50">Employment Type</td>
                            <th>:</th>
                            <th>
                              {getParticularJob?.employmentType?.map((e) => (
                                <p className="mb-0">{e.name}</p>
                              ))}
                            </th>
                          </tr>
                          <tr>
                            <td className="w-50">Job Type</td>
                            <th>:</th>
                            <th>
                              {getParticularJob?.workPlaceType?.map((e) => (
                                <p className="mb-0">{e.name}</p>
                              ))}
                            </th>
                          </tr>
                          <tr>
                            <td className="w-50">Location</td>
                            <th>:</th>
                            <th>{getParticularJob?.locationId}</th>
                          </tr>
                          <tr>
                            <td className="w-50">Salary Range</td>
                            <th>:</th>
                            <th>
                              ${getParticularJob?.salaryMin}-
                              {getParticularJob?.salaryMax}
                              {getParticularJob?.salaryRangeByValue}
                            </th>
                          </tr>
                          <tr>
                            <td className="w-50">Experience level</td>
                            <th>:</th>
                            <th>
                              {getParticularJob?.experienceLevel?.map((e) => (
                                <p className="mb-0">{e.name}</p>
                              ))}
                            </th>
                          </tr>
                          <tr>
                            <td className="w-25">Educational qualification</td>
                            <th>:</th>
                            <th>
                              {getParticularJob?.highestQualication?.name}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="Jobs-details-skills  my-2 p-3">
                    <div className="container">
                      <table className="w-100">
                        <tbody>
                          <div className="Jobs-details-skills3">
                            <p className="my-2 font-weight-bold">
                              Must have skills
                            </p>
                            {getParticularJob?.mustHaveSkill?.map((e) => (
                              <li>{e.name}</li>
                            ))}
                          </div>
                          <div className="Jobs-details-skills3">
                            <p className="my-2 font-weight-bold">
                              Nice to have skills
                            </p>
                            {getParticularJob?.niceTohaveSkill?.map((e) => (
                              <li>{e.name}</li>
                            ))}
                          </div>
                          <tr>
                            <th className="w-25 pl-0">Employer Benefits</th>
                          </tr>
                          <tr>
                            <td className="pl-0">
                              {getParticularJob?.employerBenefits?.map((e) => (
                                <li>{e.name}</li>
                              ))}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="Jobs-details-skills  my-2 p-3">
                    <div className="container">
                      <div className="jobdescription-section">
                        <h5 className="font-weight-bold my-3">
                          Job Description
                        </h5>
                        {getParticularJob?.jobDescription}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 px-1">
                  <div className="res-cover-section Jobs-details-skills  p-3">
                    <div className="edudetails-section">
                      <h6 className="text-center font-weight-bold mb-3">
                        Resume & Cover Letter
                      </h6>
                      <div className="row justify-content-center ">
                        <div className="col-lg-12 col-md-6 mb-2">
                          {getParticularJob?.resume?.fileName || resumeData ? (
                            <div className="edudetails-card p-0">
                              <div className="d-flex  ">
                                <div className="down-pdf">
                                  <h5 className="font-weight-bold m-auto">
                                    PDF
                                  </h5>
                                </div>
                                <div className="d-flex flex-column justify-content-center centered-content p-2">
                                  <p className="my-1 font-weight-bold">
                                    {getParticularJob?.resume?.fileName
                                      ? getParticularJob?.resume?.fileName
                                      : resumeData?.fileName}
                                  </p>

                                  <p className="my-1 ">
                                    Submitted on&nbsp;
                                    {getParticularJob?.resume?.createdAt
                                      ? moment(
                                          getParticularJob?.resume?.createdAt
                                        ).format("DD/MM/YYYY")
                                      : moment(resumeData.createdAt).format(
                                          "DD/MM/YYYY"
                                        )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="upload-btn-wrapper">
                              <button
                                className="btn upload-gallery"
                                onClick={() => {
                                  setResumeListModal(true);
                                }}

                                disabled={getParticularJob?.alreadyApplied === true}
                              >
                               
                                <span className="content"> Upload Resume</span>
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-12 col-md-6 mb-2">
                          {getParticularJob?.coverLetter?.title ||
                          coverLetterData ? (
                            <div className="edudetails-card p-0">
                              <div className="d-flex  ">
                                <div className="down-pdf">
                                  <h5 className="font-weight-bold m-auto">
                                    TXT
                                  </h5>
                                </div>
                                <div className="d-flex flex-column justify-content-center centered-content p-2">
                                  <p className="my-1 font-weight-bold">
                                    {getParticularJob?.coverLetter?.title
                                      ? getParticularJob?.coverLetter?.title
                                      : coverLetterData.title}
                                  </p>
                                  <p className="my-1 ">
                                    Submitted on&nbsp;
                                    {getParticularJob?.coverLetter?.createdAt
                                      ? moment(
                                          getParticularJob?.coverLetter
                                            ?.createdAt
                                        ).format("DD/MM/YYYY")
                                      : moment(
                                          coverLetterData.createdAt
                                        ).format("DD/MM/YYYY")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <button
                              className="btn upload-gallery"
                              onClick={() => {
                                setCoverLetterListModal(true);
                              }}
                              disabled={getParticularJob?.alreadyApplied === true}
                            >
                              <span className="content"> Add Cover Letter</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 className="font-weight-bold mt-4 mb-2">Activity Log</h5>
                  <div className="candiate-progressbar ">
                    <div
                      className="navigation_menu py-5 px-4"
                      id="navigation_menu"
                    >
                      <ul className="navigation_tabs" id="navigation_tabs">
                        <li
                          className={
                            getActivityMsg?.some(
                              (e) => e.jobStatus === "Applied"
                            )
                              ? "tab_inactive selected-resume-css"
                              : "tab_disabled selected-resume-css"
                          }
                        >
                          <a href="/#">Applied</a>
                          <small className="my-auto ml-2">
                            {moment(getActivityMsg?.createdAt).format(
                              "DD/MM/YYYY"
                            )}
                          </small>
                        </li>
                        <li
                          className={
                            getActivityMsg?.some(
                              (e) => e.jobStatus === "Waiting"
                            )
                              ? "tab_inactive "
                              : "tab_disabled "
                          }
                        >
                          <div className="selected-resume-css">
                            <a href="/#">Wait List</a>
                            <small className="my-auto ml-2">
                              {moment(getActivityMsg?.createdAt).format(
                                "DD/MM/YYYY"
                              )}
                            </small>
                          </div>

                          {getActivityMsg
                            ?.filter((e) => e.jobStatus === "Waiting")
                            .map((e) => (
                              <p>{e.message}</p>
                            ))}
                        </li>
                        <li
                          className={
                            getActivityMsg?.some(
                              (e) => e.jobStatus === "Inprogress"
                            )
                              ? "tab_inactive "
                              : "tab_disabled"
                          }
                        >
                          <div className="selected-resume-css">
                            <a href="/#">Inprogress</a>
                            <small className="my-auto ml-2">
                              {moment(getActivityMsg?.createdAt).format(
                                "DD/MM/YYYY"
                              )}
                            </small>
                          </div>
                          {getActivityMsg
                            ?.filter((e) => e.jobStatus === "Inprogress")
                            .map((e) => (
                              <p>{e.message}</p>
                            ))}
                        </li>
                        <li
                          className={
                            (getActivityMsg?.some(
                              (e) => e.jobStatus === "Selected"
                            )
                              ? "tab_inactive selected-resume-css"
                              : "tab_disabled selected-resume-css") ||
                            (getActivityMsg?.some(
                              (e) => e.jobStatus === "Rejected"
                            )
                              ? "tab_inactive_danger  selected-resume-css"
                              : "tab_disabled  selected-resume-css")
                          }
                        >
                          <a href="/#">
                            {getActivityMsg?.some(
                              (e) => e.jobStatus === "Selected"
                            )
                              ? "Selected"
                              : getActivityMsg?.some(
                                  (e) => e.jobStatus === "Rejected"
                                )
                              ? "Rejected"
                              : "Selected/Rejected"}
                          </a>
                          <small className="my-auto ml-2">
                            {moment(getActivityMsg?.createdAt).format(
                              "DD/MM/YYYY"
                            )}
                          </small>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className='col-lg-4'>
									<h5 className='font-weight-bold'>Similar Jobs</h5>
									<div className='row'>
										{jobsTypedataGrid.map((data, index) => {
											return (<div className='col-lg-12 jobs-padding col-md-6' key={index}>

												<div className='jobs-content'>
													<div className='d-flex'>
														<img className='compworked-img' src={Roboimg} alt='' />
														<div className="match d-flex align-items-center mx-2">
															<small className="color-green">{data.matchPercentage}</small>
														</div>
													</div>
													<span className="material-symbols-rounded jobs-eye-icon">visibility_off</span>
													<span className="material-symbols-rounded jobs-edit-icon">bookmark</span>
													<h5 className='font-weight-bold cut-text'>{data.jobName}</h5>
													<p className='mb-0'>{data.companyName}</p>
													<h6 className='my-0'><span className="material-symbols-rounded filled-icon icon-middle">
														location_on
													</span>{data.location}</h6>

													<table className='py-0'>
														<tbody>
															<tr  >
																<td>Experience </td>
																<td>:</td>
																<th>{data.experience} </th>
															</tr>
															<tr >
																<td>Job Type</td>
																<td>:</td>
																<th>{data.jobType}</th>
															</tr>
															<tr >
																<td className='text-nowrap'>Employment Type</td>
																<td>:</td>
																<th>{data.employeeType}</th>
															</tr>
															<tr >
																<td>Salary</td>
																<td>:</td>
																<th>{data.salary}</th>
															</tr>
														</tbody>
													</table>
													<div className='Jobs-details-skills2'>
														<p className='mb-1 font-weight-bold'>Primary skills</p>
														<li>XML</li>
														<li>XSLT</li>
														<li>XPATH</li>
														<li>Oxygen XML</li>
														<li>Talent Management</li>
													</div>
													<div className='d-flex justify-content-between mt-1'>
														<small><span>{data.postedDate}</span></small>
														<small><span className='text-color-blue'>{data.nearLocation}</span></small>
													</div>
												</div>

											</div>)
										})}

									</div>
								</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={RejectionModal}
        onClickOutside={() => {
          setRejectionModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Reason of Rejection
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-5">
                  <div className="google-input">
                    <label htmlFor="template-input" className="z-index-value">
                      Reason
                    </label>
                    <div className="dropdown-container">
                      <Select
                        options={optionList}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "6px",
                          zIndex: 3,
                          colors: {
                            ...theme.colors,
                            primary25: "#DFF1F1",
                            primary: "#00ABAD",
                          },
                        })}
                        styles={{
                          menu: (provided, state) => ({
                            ...provided,
                            zIndex: "5 !important",
                          }),
                        }}
                        placeholder="Choose your reason"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isMulti
                      />
                    </div>
                  </div>
                  <div className="modalAnswer mt-2">
                    <label for="exampleFormControlTextarea2">
                      Reason Details
                    </label>
                    <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button
                        type="button"
                        className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                      >
                        Rich Text Editor
                        <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                          lock
                        </span>
                      </button>
                    </div>

                    <div className="form-group">
                      <textarea
                        className="form-control rounded-0 modalDisapprove-textarea"
                        id="exampleFormControlTextarea2"
                        rows="7"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setRejectionModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn">
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={UpdateStatusModal}
        onClickOutside={() => {
          setUpdateStatusModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Update Status
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-5">
                  <div className="google-input"></div>
                  <div className="modalAnswer mt-2">
                    <label for="exampleFormControlTextarea2">
                      To : <b>Manokaran K</b>
                    </label>
                    <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button
                        type="button"
                        className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                      >
                        Rich Text Editor
                        <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                          lock
                        </span>
                      </button>
                    </div>

                    <div className="form-group">
                      <textarea
                        className="form-control rounded-0 modalDisapprove-textarea"
                        id="exampleFormControlTextarea2"
                        rows="7"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setUpdateStatusModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={SendMessageModal}
        onClickOutside={() => {
          setSendMessageModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Send Message
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-5">
                  <div className="google-input"></div>
                  <div className="modalAnswer mt-2">
                    <label for="exampleFormControlTextarea2">
                      To : <b>Manokaran K</b>
                    </label>
                    <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button
                        type="button"
                        className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                      >
                        Rich Text Editor
                        <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                          lock
                        </span>
                      </button>
                    </div>

                    <div className="form-group">
                      <textarea
                        className="form-control rounded-0 modalDisapprove-textarea"
                        id="exampleFormControlTextarea2"
                        rows="7"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setSendMessageModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        open={resumeListModal}
        onClickOutside={() => {
          setResumeListModal(false);
        }}
      >
        <div className="modal-content w-75  m-auto">
          <div className="modal-body px-3 py-5 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <div className="">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="col-lg-8 pb-3">
                    <div className="edudetails-section">
                      {getResume?.length === 0 ? (
                        //  <Link to="/ProfileEmployee">
                        <div
                          onClick={() =>
                            navigation(
                              "/ProfileEmployee?tab=Resume%20%26%20Cover%20Letter&subtab=Resumes"
                            )
                          }
                          className="text-center font-weight-bold"
                          style={{ color: "black" }}
                        >
                          Click here to upload Resume
                        </div>
                      ) : (
                        // </Link>
                        getResume?.map((e, i) => (
                          <div
                            key={i}
                            className="col-lg-12 col-md-12 px-1 my-2"
                            onClick={() => {
                              setResumeData(e);
                              setResumeListModal(false);
                            }}
                          >
                            <div className="edudetails-card">
                              <div className="d-flex justify-content-between">
                                <h6 className="font-weight-bold">
                                  {e.fileName}
                                </h6>
                              </div>
                              <div className="my-2">
                                <h6 className="mb-0 text-grey">
                                  Uploaded on{" "}
                                  {moment(e.createdAt).format("DD/MM/YYYY")}
                                </h6>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={coverLetterListModal}
        onClickOutside={() => {
          setCoverLetterListModal(false);
        }}
      >
        <div className="modal-content w-75  m-auto">
          <div className="modal-body px-3 py-5 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <div className="">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="col-lg-8 pb-3">
                    <div className="edudetails-section">
                      {getCoverLetter?.length === 0 ? (
                        //  <Link to="/ProfileEmployee">
                        <div
                          onClick={() =>
                            navigation(
                              "/ProfileEmployee?tab=Resume%20%26%20Cover%20Letter&subtab=Cover%20Letters"
                            )
                          }
                          className="text-center font-weight-bold"
                          style={{ color: "black", paddingTop: "0px" }}
                        >
                          Click here to upload Cover Letter
                        </div>
                      ) : (
                        // </Link>
                        getCoverLetter?.map((e, i) => (
                          <div
                            key={i}
                            className="col-lg-12 col-md-12 px-1 my-2"
                            onClick={() => {
                              setCoverLetterData(e);
                              setCoverLetterListModal(false);
                            }}
                          >
                            <div className="edudetails-card">
                              <div className="d-flex justify-content-between">
                                <h6 className="font-weight-bold">{e.title}</h6>
                              </div>
                              <p className="">{e.description}</p>
                              <div className="my-2">
                                <h6 className="mb-0 text-grey">
                                  Uploaded on
                                  {moment(e.createdAt).format("DD/MM/YYYY")}
                                </h6>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <ToastContainer />
    </div>
  );
}
