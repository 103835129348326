export const EmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// export const PhoneNumberRegex = /^[0-9].{11}$/;
export const NumberRegex = /^[0-9]/;
export const StringRegex = /^[A-Za-z ]+$/;
export const AlphaNumericRegex = /^[A-Za-z0-9 ]/;
export const CityRegex = /^[A-Za-z0-9,-. ]+$/;
export const QualificationRegex = /\b(([\w]+[.][\w]+)([.][\w])*)/;
export const URLregex =/((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%/&=?.]+\.[a-z]{2,4}\/?([^\s<>#%",{}\\|\\^[\]`]+)?)/gi;

var regex = new RegExp(URLregex);
export const NumericRangeRegex = /^[0-9-$ ]+$/;

export function PhoneNumberValidation(value) {
  if (value === undefined) {
    return { status: true, message: "" };
  }
  let number = value.toString();
  let tempNumber = number.replace("(", "");
  tempNumber = tempNumber.replace(")", "");
  tempNumber = tempNumber.replace("+", "");
  tempNumber = tempNumber.replace(" ", "");
  tempNumber = tempNumber.replace("-", "");
  tempNumber = tempNumber.replace(" ", "");
  if (tempNumber?.length <= 0) {
    return { status: true, message: "" };
  } else if (Array.from(tempNumber)[0] !== "1") {
    return { status: false, message: "must starts with 1" };
  } else if (tempNumber?.length !== 11) {
    return { status: false, message: "must be a 11 digits" };
  }
  return { status: true, message: "" };
}

export function PasswordValidation(value) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value?.length > 30) {
    return {
      status: false,
      message: "must allowed maximum 30 characters only",
    };
  }
  const password = [/[a-z]/, /[A-Z]/, /[0-9]/, /[.,:;'!@#$%^&*_+=|(){}[?\-\]/\\]/].every((pattern) => pattern.test(value));
  return !password
    ? { status: false, message: "is invalid" }
    : { status: true, message: "" };
}

const testArray = ["gmail.com", "yahoo.com", "hotmail.com"];
export function EmailCompanyValidation(value, value1) {
  if (!value?.trim()?.length) {
    return { status: false, message: "is required" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  } else if (testArray.includes(value.split("@").pop()) === true) {
    return { status: false, message: "should not be personal mail id" };
  } else if (value === value1) {
    return { status: false, message: "cannot be same" };
  }

  return !EmailRegex.test(value)
    ? { status: false, message: "is invalid" }
    : { status: true, message: "" };
}

export function EmailValidation(value) {
  if (!value?.trim()?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  }
  return !EmailRegex.test(value)
    ? { status: false, message: "is invalid" }
    : { status: true, message: "" };
}

export function NumberValidation(value) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  } else if (Number(value) === 0) {
    return { status: false, message: "cannot be Zero" };
  } else if (value < 0) {
    return { status: false, message: "cannot be Less than Zero" };
  }
  return !NumberRegex.test(value)
    ? { status: false, message: "is only a number" }
    : { status: true, message: "" };
}
export function ExperienceLevelValidation(value) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (Number(value) === 0) {
    return { status: false, message: "cannot be Zero" };
  }
  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}
export function NumberMaxValidation(value, value1) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  } else if (Number(value) < Number(value1)) {
    return { status: false, message: "is not lesser than Min Salary" };
  } else if (Number(value) === 0) {
    return { status: false, message: "cannot be Zero" };
  } else if (value < 0) {
    return { status: false, message: "cannot be Less than Zero" };
  }
  return !NumberRegex.test(value)
    ? { status: false, message: "is only a number" }
    : { status: true, message: "" };
}

export function SalaryRangeValidation(value, value1) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value === value1) {
    return { status: false, message: "is required" };
  } else if (Number(value) === 0) {
    return { status: false, message: "cannot be Zero" };
  }
  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}

export function CompanyNameValidation(value) {
  
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (Number(value) === 0) {
    return { status: false, message: "cannot be Zero" };
  }
  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}
export function CompanyNameChangeValidation(value, value1) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (Number(value) === 0) {
    return { status: false, message: "cannot be Zero" };
  } else if (value === value1) {
    return { status: false, message: "cannot be same" };
  }
  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}

export function NonEmptyValidation(value) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } 
  else if (value === null) {
    return { status: false, message: "cannot be empty" };
  } 
  else if (value === undefined) {
    return { status: false, message: "cannot be empty" };
  }
  else if (!value) {
    return { status: false, message: "is required" };
  }
  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}

export function AcceptTermsValidation(value) {
  if (value?.length===0) {
    return { status: false, message: "cannot be empty" };
  } else if (value === null) {
    return { status: false, message: "cannot be empty" };
  } else if (value === undefined) {
    return { status: false, message: "cannot be empty" };
  } else if (value === false) {
    return { status: false, message: "cannot be empty" };
  }

  return value < 0
    ? { status: false, message: "cannot be Less than Zero" }
    : { status: true, message: "" };
}

export function NonEmptyValidationForDate(value) {
  if (!value) {
    return { status: false, message: "cannot be empty" };
  } else if (value === null) {
    return { status: false, message: "cannot be empty" };
  } else if (value === undefined) {
    return { status: false, message: "cannot be empty" };
  }
  return value
    ? { status: true, message: "" }
    : { status: false, message: "cannot be empty" };
}

export function NonEmptyValidationForEndDate(value) {
  if (value === null) {
    return { status: false, message: "cannot be empty" };
  } else if (value === undefined) {
    return { status: false, message: "cannot be empty" };
  } else if (value === "") {
    return { status: true, message: "" };
  }
  return value
    ? { status: true, message: "" }
    : { status: false, message: "cannot be empty" };
}

export function StringValidation(value) {
  if (!value || !value?.length) {
    return { status: false, message: "is required" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid" };
  }
  else if (value === undefined) {
    return { status: false, message: "cannot be empty" };
  } 
  else if (value === "") {
    return { status: false, message: "cannot be empty" };
  }
  else if (value === 0) {
    return { status: false, message: "cannot be Zero" };
  } else if (value < 0) {
    return { status: false, message: "cannot be Less than Zero" };
  }
  return !StringRegex.test(value)
    ? { status: false, message: "is only Alphabets" }
    : { status: true, message: "" };
}

export function AlphaNumericValidation(value) {
  if (!value?.trim()?.length) {
    return { status: false, message: "is required" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid" };
  }

  return AlphaNumericRegex.test(value)
    ? { status: true, message: "" }
    : { status: false, message: "is only Alphabets & Numbers" };
}

export function URLValidation(value) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid." };
  } else if (value.match(regex)) {
    if (value.split(".")[0].length === 3) {
      return {
        status: true,
        message: "",
      };
    }
  }

  return {
    status: false,
    message: "is invalid",
  };
}

export function DateValidation(value) {
  const currentYear = new Date().getFullYear();
  if (!value?.trim()?.length) {
    return { status: false, message: "is required" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  } else if (value?.length <= 3) {
    return { status: false, message: "not a valid year" };
  }else if (!NumberRegex.test(value)) {
    return { status: false, message: "is only a number" };
  }
  return currentYear >= value
    ? { status: true, message: "" }
    : { status: false, message: "cannot be future date" };
}

export function DropdownArray(value) {
  if (!value || !value.length) {
    return { status: false, message: "is required" };
  }
  return { status: true, message: "" };
}

export function NumericRangeValidation(value) {
  if (!value?.trim()?.length) {
    return { status: false, message: "is required" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid" };
  }

  return NumericRangeRegex.test(value)
    ? {
        status: true,
        message: "",
      }
    : {
        status: false,
        message: "is only Numbers & Hyphens",
      };
}
export function NonEmptyObjectValidation(value) {
  if (!value) {
    return { status: false, message: "is required" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid" };
  }

  return { status: true, message: "" };
}

export function NonEmptyKeyValidation(value) {
  if (value === undefined) {
    return { status: false, message: "is required" };
  }
  return { status: true, message: "" };
}

export function QualificationValidation(value) {
  if (!value?.trim()?.length) {
    return { status: false, message: "is required" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid" };
  }

  return {
    status: true,
    message: "",
  };
}

export function CityValidation(value) {
  if (!value?.trim()?.length) {
    return { status: false, message: "is required" };
  } else if (!isNaN(value)) {
    return { status: false, message: "is invalid" };
  }

  return CityRegex.test(value)
    ? {
        status: true,
        message: "",
      }
    : {
        status: false,
        message: "is invalid",
      };
}
export function NoOfPositionNumberValidation(value) {
  if (!value?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value?.length > 3) {
    return { status: false, message: "cannot be more than 3-digits" };
  } else if (value?.includes(" ")) {
    return { status: false, message: "cannot be include space" };
  } else if (Number(value) < 1) {
    return { status: false, message: "cannot be Zero" };
  } else if (value < 0) {
    return { status: false, message: "cannot be Less than Zero" };
  } else if (Number(value) === "00") {
    return { status: false, message: "cannot be Zero" };
  } else if (Number(value) === "000") {
    return { status: false, message: "cannot be Zero" };
  }
  return !NumberRegex.test(value)
    ? { status: false, message: "is only a number" }
    : { status: true, message: "" };
}

export function ExpireDateValidation(value, value1) {
  if (!value1?.length) {
    return { status: false, message: "cannot be empty" };
  } else if (value1 === null) {
    return { status: false, message: "cannot be empty" };
  } else if (value1 === undefined) {
    return { status: false, message: "cannot be empty" };
  } else if (value1 === "") {
    return { status: true, message: "" };
  }
  return value >= value1
    ? { status: false, message: "must be future date" }
    : { status: true, message: "" };
}

export function PDFValidation(value) {
  return value === "pdf"
    ? { status: true, message: "" }
    : { status: false, message: "Accepts only pdf file" };
}
