/* eslint-disable array-callback-return */
import React, { useState, useCallback, useEffect } from "react";
import {
  CreateEmployeeSkillsPost,
  DeleteSkill,
  GetEmployeeSkillsPost,
} from "../../Api/Api";
import CustomModal from "../../CustomModal";
import { useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import Roboimg2 from "../../../images/roboimg-outerborder.png";
import { DropdownArray, NonEmptyObjectValidation } from "../../Store/validate";
import DropTarget from "./DropTarget";
const Skills = (props) => {
  const { showToastError, showToastSuccess, skills } = props;
  //state value
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const userProfileId = state?.auth?.auth?.userProfileId;

  const [allSkills, setAllSkills] = useState([]);
  const [skillData, setSkillData] = useState({});
  const [skillId, setSkillId] = useState("");
  const [skillValidation, setSkillValidation] = useState({});
  const [AddSkillsModal, setAddSkillsModal] = useState(false);
  const [skillDeleteModalStatus, setSkillDeleteModalStatus] = useState(false);
  //get all skills

  const fetchSkillsData = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const getEmployeeSkillPost = await GetEmployeeSkillsPost(authKey, userProfileId);
    if (getEmployeeSkillPost && getEmployeeSkillPost.statusCode === 200) {
      setAllSkills(getEmployeeSkillPost.data);
    }
  }, [authKey, userProfileId]);

  // skill post api

  const setSkillValue = (key, value) => {
    setSkillData({ ...skillData, [key]: value });
    if (skillValidation[key]) delete skillValidation[key];
  };
  const onChangeSkillValidationData = (key, value) => {
    setSkillValidation({ ...skillValidation, [key]: value });
  };
  const CheckValidation = () => {
    const Keys = [
      { key: "skillType", funcKey: 1 },
      { key: "proficiency", funcKey: 1 },
      { key: "skillId", funcKey: 2 },
    ];
    let values = {};
    Keys.map((element, index) => {
      let valid;
      switch (element.funcKey) {
        case 1:
          valid = NonEmptyObjectValidation(skillData[element.key]);
          break;
        case 2:
          valid = DropdownArray(skillData[element.key]);
          break;
        default:
          break;
      }
      values = { ...values, [element.key]: valid };
      if (index + 1 === Keys.length) {
        setSkillValidation(values);
        return valid;
      }
    });
    return values;
  };
  const handleSkillPost = async () => {
    const validationValues = CheckValidation();
    if (Object.values(validationValues).every((x) => x.status === true)) {
      const Payload = {
        userProfileId: state?.auth?.auth?.userProfileId,
        skillId: skillData?.skillId?.map((e) => e.masterDataId.toString()),
        skillType: skillData?.skillType,
        proficiency: skillData?.proficiency,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await CreateEmployeeSkillsPost(Payload, token);

      if (response && response.statusCode === 200) {
        showToastSuccess(response.message);
        setAddSkillsModal(false);
        fetchSkillsData();
        setSkillData({});
      } else {
        showToastError(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  //delete skill

  const handleDeleteSkill = async () => {
    try {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        userSkillId: skillId,
      };
      const response = await DeleteSkill(Payload, token);
      if (response && response.statusCode === 200) {
        setSkillDeleteModalStatus(false);
        showToastSuccess(response.message);
        fetchSkillsData();
      } else {
        showToastError(
          response.message || response.error.response.data.message
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleCreateSkills = async (e) => {
    const response = await props.handleCreateSkills(e);
    if (response && response.statusCode === 200) {
      if (skillData?.skillId?.length) {
        let data = skillData?.skillId;
        data.push(response.data);
        setSkillValue("skillId", data);
      } else {
        setSkillValue("skillId", [
          {
            masterDataId: response.data.masterDataId,
            name: response.data.name,
          },
        ]);
      }
    }
  };
  useEffect(() => {
    fetchSkillsData();
  }, [fetchSkillsData]);
  function startDrag(ev, skillId) {
    ev.dataTransfer.setData("drag-item", skillId);
  }
  return (
    <div>
      <div>
        <div className="container-fluid py-4">
          <div className="row justify-content-center d-flex align-items-center ">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-2 col-md-3 col-4 my-2 text-center">
                  <div className="upload-btn-wrapper mx-2">
                    <button
                      className="btn upload-gallery"
                      onClick={() => {
                        setAddSkillsModal(true);
                      }}
                    >
                      <h1>
                        <span className="material-symbols-rounded">add</span>
                      </h1>
                      <span className="content">Add Skills</span>
                    </button>
                  </div>
                </div>
                <div className="col-lg-10 scroll-content-design">
                  <div className="edudetails-section">
                    <div className="row  ">
                      <div className="col-lg-4 col-md-6 px-1 my-2">
                        <div className="skills-section">
                          <div className="primary-card">
                            <div className="primary-header">
                              <h6 className="text-center font-weight-bold mt-0 py-2">
                                Primary Skills
                              </h6>
                            </div>
                            <div className="primary-body px-4 py-2">
                              <div className="primary-body-content">
                                <p className="mb-1">Basic</p>
                                <DropTarget
                                  fetchSkillsData={fetchSkillsData}
                                  skillType={"Primary"}
                                  proficiency={"Basic"}
                                  showToastError={showToastError}
                                  showToastSuccess={showToastSuccess}
                                >
                                  {allSkills && allSkills?.length
                                    ? allSkills
                                        .filter(
                                          (e) =>
                                            e.skillType === "Primary" &&
                                            e.proficiency === "Basic"
                                        )
                                        ?.map((e) => (
                                          <small className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1">
                                            {e?.skillName?.name?.split(",")}
                                            <span
                                              className="material-symbols-rounded pl-2 icon-middle"
                                              onClick={() => {
                                                setSkillDeleteModalStatus(true);
                                                setSkillValidation({});
                                                setSkillId(e.userSkillId);
                                              }}
                                            >
                                              cancel
                                            </span>
                                          </small>
                                        ))
                                    : null}
                                </DropTarget>
                              </div>
                              <div className="primary-body-content">
                                <p className="mb-1">Intermediate</p>
                                <DropTarget
                                  fetchSkillsData={fetchSkillsData}
                                  skillType={"Primary"}
                                  proficiency={"Intermediate"}
                                  showToastError={showToastError}
                                  showToastSuccess={showToastSuccess}
                                >
                                  {allSkills && allSkills?.length
                                    ? allSkills
                                        .filter(
                                          (e) =>
                                            e.skillType === "Primary" &&
                                            e.proficiency === "Intermediate"
                                        )
                                        ?.map((e) => (
                                          <small className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1">
                                            {e?.skillName?.name?.split(",")}
                                            <span
                                              className="material-symbols-rounded pl-2 icon-middle"
                                              onClick={() => {
                                                setSkillDeleteModalStatus(true);
                                                setSkillId(e.userSkillId);
                                              }}
                                            >
                                              cancel
                                            </span>
                                          </small>
                                        ))
                                    : null}
                                </DropTarget>
                              </div>
                              <div className="primary-body-content">
                                <p className="mb-1">Expert</p>
                                <DropTarget
                                  fetchSkillsData={fetchSkillsData}
                                  skillType={"Primary"}
                                  proficiency={"Expert"}
                                  showToastError={showToastError}
                                  showToastSuccess={showToastSuccess}
                                >
                                  {allSkills && allSkills?.length
                                    ? allSkills
                                        .filter(
                                          (e) =>
                                            e.skillType === "Primary" &&
                                            e.proficiency === "Expert"
                                        )
                                        ?.map((e) => (
                                          <small className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1">
                                            {e?.skillName?.name?.split(",")}
                                            <span
                                              className="material-symbols-rounded pl-2 icon-middle"
                                              onClick={() => {
                                                setSkillDeleteModalStatus(true);
                                                setSkillId(e.userSkillId);
                                              }}
                                            >
                                              cancel
                                            </span>
                                          </small>
                                        ))
                                    : null}
                                </DropTarget>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 px-1 my-2">
                        <div className="skills-section">
                          <div className="primary-card">
                            <div className="primary-header">
                              <h6 className="text-center  font-weight-bold mt-0 py-2">
                                Secondary Skills
                              </h6>
                            </div>
                            <div className="primary-body px-4 py-2">
                              <div className="primary-body-content">
                                <p className="mb-1">Basic</p>
                                <DropTarget
                                  fetchSkillsData={fetchSkillsData}
                                  skillType={"Secondary"}
                                  proficiency={"Basic"}
                                  showToastError={showToastError}
                                  showToastSuccess={showToastSuccess}
                                >
                                  {allSkills && allSkills?.length
                                    ? allSkills
                                        .filter(
                                          (e) =>
                                            e.skillType === "Secondary" &&
                                            e.proficiency === "Basic"
                                        )
                                        ?.map((e) => (
                                          <small className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1">
                                            {e?.skillName?.name?.split(",")}
                                            <span
                                              className="material-symbols-rounded pl-2 icon-middle"
                                              onClick={() => {
                                                setSkillDeleteModalStatus(true);
                                                setSkillId(e.userSkillId);
                                              }}
                                            >
                                              cancel
                                            </span>
                                          </small>
                                        ))
                                    : null}
                                </DropTarget>
                              </div>
                              <div className="primary-body-content">
                                <p className="mb-1">Intermediate</p>
                                <DropTarget
                                  fetchSkillsData={fetchSkillsData}
                                  skillType={"Secondary"}
                                  proficiency={"Intermediate"}
                                  showToastError={showToastError}
                                  showToastSuccess={showToastSuccess}
                                >
                                  {allSkills && allSkills?.length
                                    ? allSkills
                                        .filter(
                                          (e) =>
                                            e.skillType === "Secondary" &&
                                            e.proficiency === "Intermediate"
                                        )
                                        ?.map((e) => (
                                          <small className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1">
                                            {e?.skillName?.name?.split(",")}
                                            <span
                                              className="material-symbols-rounded pl-2 icon-middle"
                                              onClick={() => {
                                                setSkillDeleteModalStatus(true);
                                                setSkillId(e.userSkillId);
                                              }}
                                            >
                                              cancel
                                            </span>
                                          </small>
                                        ))
                                    : null}
                                </DropTarget>
                              </div>
                              <div className="primary-body-content">
                                <p className="mb-1">Expert</p>
                                <DropTarget
                                  fetchSkillsData={fetchSkillsData}
                                  skillType={"Secondary"}
                                  proficiency={"Expert"}
                                  showToastError={showToastError}
                                  showToastSuccess={showToastSuccess}
                                >
                                  {allSkills && allSkills?.length
                                    ? allSkills
                                        .filter(
                                          (e) =>
                                            e.skillType === "Secondary" &&
                                            e.proficiency === "Expert"
                                        )
                                        ?.map((e) => (
                                          <small className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1">
                                            {e?.skillName?.name?.split(",")}
                                            <span
                                              className="material-symbols-rounded pl-2 icon-middle"
                                              onClick={() => {
                                                setSkillDeleteModalStatus(true);
                                                setSkillId(e.userSkillId);
                                              }}
                                            >
                                              cancel
                                            </span>
                                          </small>
                                        ))
                                    : null}
                                </DropTarget>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 px-1 my-2">
                        <div className="skills-section">
                          <div className="primary-card">
                            <div className="primary-header">
                              <h6 className="text-center  font-weight-bold mt-0 py-2">
                                Uncategorized Skills
                              </h6>
                            </div>
                            <div className="primary-body px-4 py-2">
                              <div className="primary-body-content2 ">
                                <div className="">
                                  {allSkills && allSkills?.length
                                    ? allSkills
                                        .filter(
                                          (e) => e.skillType === "unCategorized"
                                        )
                                        ?.map((e) => (
                                          <small
                                            className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1"
                                            draggable
                                            onDragStart={(ev) =>
                                              startDrag(ev, e.userSkillId)
                                            }
                                          >
                                            {e?.skillName?.name?.split(",")}
                                            <span
                                              className="material-symbols-rounded pl-2 icon-middle"
                                              onClick={() => {
                                                setSkillDeleteModalStatus(true);
                                                setSkillId(e.userSkillId);
                                              }}
                                            >
                                              cancel
                                            </span>
                                          </small>
                                        ))
                                    : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card my-1 d-block d-lg-none">
        <div className="card-header" id="headingSix">
          <h5 className="mb-0">
            <button
              className="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              Skills{" "}
              <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                expand_more
              </span>
            </button>
          </h5>
        </div>
        <div
          id="collapseSix"
          className="collapse"
          aria-labelledby="headingSix"
          data-parent="#accordion"
        >
          <div className="card-body p-0">
            <div>
              <div className="container-fluid ">
                <div className="row justify-content-center d-flex align-items-center ">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-2 col-md-3 col-12 my-2 text-center">
                        <div className="upload-btn-wrapper mx-2">
                          <button
                            className="btn upload-gallery"
                            onClick={() => {
                              setAddSkillsModal(true);
                            }}
                          >
                            <h5>
                              <span className="content">Add Skills</span>
                            </h5>
                            <h4>
                              <span className="material-symbols-rounded">
                                add
                              </span>
                            </h4>
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-10 scroll-content-design">
                        <div className="edudetails-section">
                          <div className="row  ">
                            <div className="col-lg-4 col-md-6 px-1 my-2">
                              <div className="skills-section">
                                <div className="primary-card">
                                  <div className="primary-header">
                                    <h6 className="text-center font-weight-bold mt-0 py-2">
                                      Primary Skills
                                    </h6>
                                  </div>
                                  <div className="primary-body px-4 py-2">
                                    <div className="primary-body-content">
                                      <p className="mb-1">Basic</p>
                                      <div className="">
                                        {allSkills && allSkills?.length
                                          ? allSkills
                                              .filter(
                                                (e) =>
                                                  e.skillType === "Primary" &&
                                                  e.proficiency === "Basic"
                                              )
                                              ?.map((e) => (
                                                <small className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1">
                                                  {e?.skillName?.name?.split(
                                                    ","
                                                  )}
                                                  <span
                                                    className="material-symbols-rounded pl-2 icon-middle"
                                                    onClick={() => {
                                                      setSkillDeleteModalStatus(
                                                        true
                                                      );
                                                      setSkillValidation({});
                                                      setSkillId(e.userSkillId);
                                                    }}
                                                  >
                                                    cancel
                                                  </span>
                                                </small>
                                              ))
                                          : null}
                                      </div>
                                    </div>
                                    <div className="primary-body-content">
                                      <p className="mb-1">Intermediate</p>
                                      <div className="">
                                        {allSkills && allSkills?.length
                                          ? allSkills
                                              .filter(
                                                (e) =>
                                                  e.skillType === "Primary" &&
                                                  e.proficiency ===
                                                    "Intermediate"
                                              )
                                              ?.map((e) => (
                                                <small className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1">
                                                  {e?.skillName?.name?.split(
                                                    ","
                                                  )}
                                                  <span
                                                    className="material-symbols-rounded pl-2 icon-middle"
                                                    onClick={() => {
                                                      setSkillDeleteModalStatus(
                                                        true
                                                      );
                                                      setSkillId(e.userSkillId);
                                                    }}
                                                  >
                                                    cancel
                                                  </span>
                                                </small>
                                              ))
                                          : null}
                                      </div>
                                    </div>
                                    <div className="primary-body-content">
                                      <p className="mb-1">Expert</p>
                                      <div className="">
                                        {allSkills && allSkills?.length
                                          ? allSkills
                                              .filter(
                                                (e) =>
                                                  e.skillType === "Primary" &&
                                                  e.proficiency === "Expert"
                                              )
                                              ?.map((e) => (
                                                <small className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1">
                                                  {e?.skillName?.name?.split(
                                                    ","
                                                  )}
                                                  <span
                                                    className="material-symbols-rounded pl-2 icon-middle"
                                                    onClick={() => {
                                                      setSkillDeleteModalStatus(
                                                        true
                                                      );
                                                      setSkillId(e.userSkillId);
                                                    }}
                                                  >
                                                    cancel
                                                  </span>
                                                </small>
                                              ))
                                          : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 px-1 my-2">
                              <div className="skills-section">
                                <div className="primary-card">
                                  <div className="primary-header">
                                    <h6 className="text-center  font-weight-bold mt-0 py-2">
                                      Secondary Skills
                                    </h6>
                                  </div>
                                  <div className="primary-body px-4 py-2">
                                    <div className="primary-body-content">
                                      <p className="mb-1">Basic</p>
                                      <div className="">
                                        {allSkills && allSkills?.length
                                          ? allSkills
                                              .filter(
                                                (e) =>
                                                  e.skillType === "Secondary" &&
                                                  e.proficiency === "Basic"
                                              )
                                              ?.map((e) => (
                                                <small className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1">
                                                  {e?.skillName?.name?.split(
                                                    ","
                                                  )}
                                                  <span
                                                    className="material-symbols-rounded pl-2 icon-middle"
                                                    onClick={() => {
                                                      setSkillDeleteModalStatus(
                                                        true
                                                      );
                                                      setSkillId(e.userSkillId);
                                                    }}
                                                  >
                                                    cancel
                                                  </span>
                                                </small>
                                              ))
                                          : null}
                                      </div>
                                    </div>
                                    <div className="primary-body-content">
                                      <p className="mb-1">Intermediate</p>
                                      <div className="">
                                        {allSkills && allSkills?.length
                                          ? allSkills
                                              .filter(
                                                (e) =>
                                                  e.skillType === "Secondary" &&
                                                  e.proficiency ===
                                                    "Intermediate"
                                              )
                                              ?.map((e) => (
                                                <small className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1">
                                                  {e?.skillName?.name?.split(
                                                    ","
                                                  )}
                                                  <span
                                                    className="material-symbols-rounded pl-2 icon-middle"
                                                    onClick={() => {
                                                      setSkillDeleteModalStatus(
                                                        true
                                                      );
                                                      setSkillId(e.userSkillId);
                                                    }}
                                                  >
                                                    cancel
                                                  </span>
                                                </small>
                                              ))
                                          : null}
                                      </div>
                                    </div>
                                    <div className="primary-body-content">
                                      <p className="mb-1">Expert</p>
                                      <div className="">
                                        {allSkills && allSkills?.length
                                          ? allSkills
                                              .filter(
                                                (e) =>
                                                  e.skillType === "Secondary" &&
                                                  e.proficiency === "Expert"
                                              )
                                              ?.map((e) => (
                                                <small className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1">
                                                  {e?.skillName?.name?.split(
                                                    ","
                                                  )}
                                                  <span
                                                    className="material-symbols-rounded pl-2 icon-middle"
                                                    onClick={() => {
                                                      setSkillDeleteModalStatus(
                                                        true
                                                      );
                                                      setSkillId(e.userSkillId);
                                                    }}
                                                  >
                                                    cancel
                                                  </span>
                                                </small>
                                              ))
                                          : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 px-1 my-2">
                              <div className="skills-section">
                                <div className="primary-card">
                                  <div className="primary-header">
                                    <h6 className="text-center font-weight-bold mt-0 py-2">
                                      Uncategorized Skills
                                    </h6>
                                  </div>
                                  <div className="primary-body px-4 py-2">
                                    <div className="primary-body-content2 ">
                                      <div className="">
                                        {allSkills && allSkills?.length
                                          ? allSkills
                                              .filter(
                                                (e) =>
                                                  e.skillType ===
                                                  "unCategorized"
                                              )
                                              ?.map((e) => (
                                                <small
                                                  className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1"
                                                  draggable
                                                >
                                                  {e?.skillName?.name?.split(
                                                    ","
                                                  )}
                                                  <span
                                                    className="material-symbols-rounded pl-2 icon-middle"
                                                    onClick={() => {
                                                      setSkillDeleteModalStatus(
                                                        true
                                                      );
                                                      setSkillId(e.userSkillId);
                                                    }}
                                                  >
                                                    cancel
                                                  </span>
                                                </small>
                                              ))
                                          : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={AddSkillsModal}
        onClickOutside={() => {
          setAddSkillsModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader py-3">
              <h5 className="text-center font-weight-bold my-0">Add Skills</h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content my-2 py-2">
                <div className="col-lg-6">
                  <div className="google-input">
                    <label htmlFor="template-input" className="z-index-value">
                      Skill*
                    </label>
                    <div className="dropdown-container">
                      <CreatableSelect
                        options={skills}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "6px",
                          zIndex: 3,
                          colors: {
                            ...theme.colors,
                            primary25: "#DFF1F1",
                            primary: "#00ABAD",
                          },
                        })}
                        onBlur={() => {
                          onChangeSkillValidationData(
                            "skillId",
                            DropdownArray(skillData.skillId)
                          );
                        }}
                        styles={{
                          menu: (provided, state) => ({
                            ...provided,
                            zIndex: "5 !important",
                          }),
                        }}
                        getOptionLabel={(option) => option.label || option.name}
                        getOptionValue={(option) =>
                          option.masterDataId || option.dataId
                        }
                        placeholder="Choose your skill"
                        value={skillData?.skillId}
                        onChange={(value) => setSkillValue("skillId", value)}
                        onCreateOption={handleCreateSkills}
                        isClearable
                        isMulti
                      />
                    </div>
                    {skillValidation?.skillId?.status === false ? (
                      <small className="text-danger">
                        Skill {skillValidation.skillId.message}
                      </small>
                    ) : null}
                  </div>
                  <div className="modalAnswer mt-3">
                    <label
                      htmlFor="template-input"
                      className="z-index-value employeeNavbar-labels"
                    >
                      Skill Type
                    </label>
                    <div>
                      <div className="row">
                        <div className="col-md-4">
                          <button
                            onClick={(e) =>
                              setSkillValue("skillType", "Primary")
                            }
                            className={`btn addLanguage-skills m-1 ${
                              skillData.skillType === "Primary" ? "active" : ""
                            }`}
                          >
                            Primary Skill
                          </button>
                        </div>
                        <div className="col-md-4 ">
                          <button
                            onClick={(e) =>
                              setSkillValue("skillType", "Secondary")
                            }
                            className={`btn addLanguage-skills m-1 ${
                              skillData.skillType === "Secondary"
                                ? "active"
                                : ""
                            }`}
                          >
                            Secondary Skill
                          </button>
                        </div>
                      </div>
                    </div>
                    {skillValidation?.skillType?.status === false ? (
                      <small className="text-danger">
                        Skill Type {skillValidation.skillType.message}
                      </small>
                    ) : null}
                  </div>
                  <div className="modalAnswer ml-1 mt-3 btn-group-toggle">
                    <label
                      htmlFor="template-input"
                      className="z-index-value employeeNavbar-labels"
                    >
                      Proficiency
                    </label>
                    <div className="form-check">
                      <input
                        autoComplete="off"
                        className="form-check-input"
                        checked={skillData.proficiency === "Basic"}
                        type="checkbox"
                        value="Basic"
                        id="gridCheck1"
                        onChange={(e) =>
                          setSkillValue("proficiency", e.target.value)
                        }
                      />
                      <label className="form-check-label" htmlFor="gridCheck1">
                        Basic
                      </label>
                    </div>
                    <div className="form-check mt-1">
                      <input
                        autoComplete="off"
                        className="form-check-input"
                        type="checkbox"
                        checked={skillData.proficiency === "Intermediate"}
                        value="Intermediate"
                        id="gridCheck1"
                        onChange={(e) =>
                          setSkillValue("proficiency", e.target.value)
                        }
                      />
                      <label className="form-check-label" htmlFor="gridCheck1">
                        Intermediate
                      </label>
                    </div>
                    <div className="form-check mt-1">
                      <input
                        autoComplete="off"
                        className="form-check-input"
                        type="checkbox"
                        checked={skillData.proficiency === "Expert"}
                        id="gridCheck1"
                        value="Expert"
                        onChange={(e) =>
                          setSkillValue("proficiency", e.target.value)
                        }
                      />
                      <label className="form-check-label" htmlFor="gridCheck1">
                        Expert
                      </label>
                    </div>
                    {skillValidation?.proficiency?.status === false ? (
                      <small className="text-danger">
                        Skill Proficiency {skillValidation.proficiency.message}
                      </small>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setAddSkillsModal(false);
                    setSkillValidation({});
                  }}
                >
                  Close
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                  onClick={handleSkillPost}
                >
                  ADD
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={skillDeleteModalStatus}
        onClickOutside={() => {
          setSkillDeleteModalStatus(false);
        }}
      >
        <div className="modal-content w-75 m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <h3 className="font-weight-bold text-center">
                    Are You Sure?
                  </h3>
                  <p className=" text-center">
                    Do you really want to delete this skill? this process cannot
                    be undone.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center border-0 mb-3">
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                  onClick={() => {
                    setSkillDeleteModalStatus(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ padding: "8px 24px" }}
                  className="btn btn-danger my-2 my-sm-0 mr-2 "
                  onClick={() => handleDeleteSkill()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default Skills;
