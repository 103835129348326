import './App.css';
import Routeslist from './routeslist';

import { FeatureFlagsContextProvider } from './context';
import coreContextValues from './context/core';

function App() {
  return (
    <div className="App">
      <FeatureFlagsContextProvider value={coreContextValues}>
        <Routeslist />
      </FeatureFlagsContextProvider>
    </div>
  );
}

export default App;
