import React from "react";
import { useState } from "react";

const Pagination = ({ clicksPerPage, totalPage, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPage / clicksPerPage); i++) {
    pageNumbers.push(i);
  }

  const [active ,setActive] = useState(1)

  return (
    <nav className="d-flex justify-content-end">
      <ul className="pagination">
        {pageNumbers?.map((number) => (
          <li key={number} className={number===active ? "page-item active" :"page-item "}>
            <button onClick={() => {
                paginate(number);
                setActive(number);
            }} className="page-link">
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
