import React, { useState } from "react";
import Navbar from "./navbar";
import Roboimg from "../images/roboimg.png";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { SendOtp, VerifyOtp } from "./Api/Api";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function EmployeOtp() {
  // Navigation
  const naviGation = useNavigate();
  //setOtp
  const [code, setOtp] = useState("");
  //Otp Submit
  const handleChange = (code) => {
    setOtp(code);
  };
  // state values
  const state = useSelector((state) => state);

  //Resent
  const Resent = async () => {
    setOtp(null);
    const Payload = {
      emailId: state.auth.registerData.email,
      verificationType: "Forgot Password",
    };
    const response = await SendOtp(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      //setSuccessModal(true);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      // setFailureModal(true);
    }
  };

  // final submit
  const Submit = async (data) => {
    data.preventDefault();
    const Payload = {
      emailId: state.auth.registerData.email,
      verificationType: "Forgot Password",
      otp: parseInt(code),
    };

    const response = await VerifyOtp(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      // setSuccessModal(true);
      naviGation("/ChangePassword");
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //    setFailureModal(true);
    }
  };
  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };
  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  return (
    <div>
      <Navbar />
      <div className="Banner d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="section1 section1-regEmp my-5 p-4">
                <img className="" src={Roboimg} alt="" />
                <h1>
                  {/* Hi {state.auth.registerData.fullname} ! */}
                  <br /> lets verify your account
                </h1>
                <p>
                  Please use the verification code sent to
                  <br /> {state?.auth?.registerData?.email} from
                  “quantum@eadjobs.us”,
                  <br /> check your inbox / junk / spam folders
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 m-auto">
              <div className="section2 my-5 pb-3">
                <h5 className="text-left font-weight-bold  mx-5">
                  Verify your email ID
                </h5>
                <p className="text-left  mx-5">
                  Enter the code we just sent to
                </p>
                <p className="text-left font-weight-bold  mx-5">
                  {state?.auth?.registerData?.email} &nbsp;
                  <span
                    onClick={() => {
                      naviGation({
                        pathname: "/ForgotPassword",
                      });
                    }}
                    className="fgtpwd"
                  >
                    Edit
                  </span>
                </p>
                <form className="my-4 mx-5" onSubmit={(e) => Submit(e)}>
                  <div className="form-container">
                    <div className="verification-code--inputs">
                      <OtpInput
                        className="otpInput"
                        value={code}
                        onChange={handleChange}
                        numInputs={6}
                        separator={<span style={{ width: "8px" }}></span>}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        inputStyle={{
                          border: "1px solid #CFD3DB",
                          borderRadius: "8px",
                          width: "54px",
                          height: "54px",
                          fontSize: "12px",
                          color: "#000",
                          fontWeight: "400",
                          caretColor: "blue",
                        }}
                        focusStyle={{
                          border: "1px solid black ",
                          outline: "none",
                        }}
                      />
                    </div>
                    {/* <p className="text-danger">{errors.otp?.message}</p> */}

                    <div className="top-12">
                      <span className="fgtpwd" onClick={() => Resent()}>
                        Resend
                      </span>
                    </div>
                    <div className="google-input-btm">
                      <button
                        type="submit"
                        className="btn btn-light my-2 my-sm-0 login-btn"
                        // onClick={() => naviGation("/Profile")}
                      >
                        Verify
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
