import React, { useEffect, useCallback, useState } from "react";
import NavbarEmployee from "./NavbarEmployee";
import CompBanner from "../../images/compbanner.png";
import Roboimg from "../../images/roboimg.png";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import StarRatings from "react-star-ratings";
import {
  CompanyDetailsEmployee,
  CreateAnswer,
  CreateEmployeeSkills,
  CreateLikeDislike,
  CreateQuestion,
  GetCompanyDetail,
  GetGallery,
  GetMasterDatasByType,
  // GetOverview,
  GetQADetails,
  GetReviewDetails,
  JobPostList,
} from "../Api/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomModal from "../CustomModal";
import { ToastContainer, toast } from "react-toastify";
import {
  StringValidation,
  AlphaNumericValidation,
  NonEmptyValidationForDate,
  NonEmptyValidation,
} from "../Store/validate";
import CreatableSelect from "react-select/creatable";
import Roboimg2 from "../../images/roboimg-outerborder.png";
import { GetInitials } from "../Store/Constants";

export default function CompaniesIndividualPage() {
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const userProfileId = state?.auth?.auth?.userProfileId;

  const [addansModal, setaddansModal] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [overviewData, setOverviewData] = useState({});
  // const [profilePic, setProfilePic] = useState("");
  const [sortOrder, setSortOrder] = useState("newest");
  const [sortOrderQA, setSortOrderQA] = useState("newest");
  const [reviewDetails, setReviewDetails] = useState([]);
  const [qaDetails, setQaDetails] = useState([]);
  const [showAnswerList, setShowAnswerList] = useState(false);
  const [answerSort, setAnswerSort] = useState("newest");
  const [sortPhoto, setSortPhoto] = useState("newest");
  const [questionID, setQuestionID] = useState("");
  const [validation, setValidation] = useState({});
  const [createAns, setCreateAns] = useState([]);
  const [jobsData, setJobsData] = useState([]);
  const [galleryPhoto, setGalleryPhoto] = useState([]);
  const [createQuestion, setCreateQuestion] = useState({});
  const [addQuestionModal, setAddQuestionModal] = useState(false);
  const [sortJob, setSortJob] = useState("newest");
  const locationSearch = useLocation();
  const { id } = useParams();
  const Navigate = useNavigate();
  const [externalLink, setExternalLink] = useState()

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };
  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

    // Overview
    const handleCompanyIndividual = useCallback(async () => {
      if (!authKey) return;
  
      const response = await GetCompanyDetail(authKey, id);
      if (response && response.statusCode === 200) {
        setOverviewData(response.data);
        let temp = "https://"+ response.data.companyUrl
        setExternalLink(temp);
      }
    }, [authKey, id]);
  
    // const handleProfilePic = async () => {
    //   const token = state?.auth?.auth?.authKey;
    //   const response = await GetOverview(id, token);
    //   if (response && response.statusCode === 200) {
    //     let newData = response.data;
    //     setProfilePic(newData.profileImage);
    //   }
    // };
  
    const handleAllCompany = useCallback(async () => {
      if (!authKey) return;
  
      const response = await CompanyDetailsEmployee(authKey);
      if (response && response.statusCode === 200) {
        let newData = response?.data?.companyListing;
        setListingData(newData);
      } else {
        setListingData([]);
      }
    }, [authKey]);

  const handleAllJob = useCallback(async () => {
    if (!authKey) return;

    const response = await JobPostList(authKey, id, sortJob);
    if (response && response.statusCode === 200) {
      setJobsData(response.data);
    } else {
      setJobsData([]);
    }
  }, [authKey, id, sortJob]);

  //Find all questions
  const handleAllQA = useCallback(async () => {
    if (!authKey) return;

    const response = await GetQADetails(id, authKey, sortOrderQA, answerSort);
    if (response && response.statusCode === 200) {
      setQaDetails(response.data);
    } else {
      setQaDetails([]);
    }
  }, [authKey, id, sortOrderQA, answerSort]);

  const handleallReview = useCallback(async () => {
    if (!authKey) return;

    const response = await GetReviewDetails(id, authKey, sortOrder);
    if (response && response.statusCode === 200) {
      setReviewDetails(response.data?.reviewDetails);
    } else {
      setReviewDetails([]);
    }
  }, [authKey, id, sortOrder]);

  useEffect(() => {
    handleAllCompany();
    handleCompanyIndividual();
    // handleProfilePic();
    handleallReview();
    handleAllQA();
    handleAllJob();
    const search = locationSearch.search;
    const tab = new URLSearchParams(search).get("tab");
    if (tab) setSelectedTab(tab);
    return () => { };
  }, [locationSearch, sortOrder, handleAllCompany, handleAllJob, handleAllQA, handleCompanyIndividual, handleallReview]);

  const [selectedTab, setSelectedTab] = React.useState("Overview");
  const Alltabs = ["Overview", "Reviews", "QA", "Jobs", "Images"];
  const navigation = useNavigate();

  let companyName = listingData
    ?.filter((e) => e.companyId === id)
    .map((e) => {
      return e.companyName;
    });

  // review
  //Find all

  let successReview = reviewDetails
    .filter((e) => e.status === "Approve")
    .map((e) => {
      return e;
    });
  //overall rating
  let overAllRating_str = successReview
    .filter((e) => e.overRating)
    .map((e) => {
      return e.overRating;
    });

  let overAllRating = overAllRating_str.map(Number);
  let total = overAllRating.reduce((acc, c) => acc + c, 0);
  let overAllRating_avg = total / overAllRating.length;
  let total_length = overAllRating.length;
  //5-star
  let fiveStar = overAllRating.filter((x) => x === 5).length;
  let fiveStarPercent = Math.floor((fiveStar * 100) / total_length);
  //4-star
  let fourStar = overAllRating.filter((x) => x === 4).length;
  let fourStarPercent = Math.floor((fourStar * 100) / total_length);
  //3-star
  let threeStar = overAllRating.filter((x) => x === 3).length;
  let threeStarPercent = Math.floor((threeStar * 100) / total_length);
  //2-star
  let twoStar = overAllRating.filter((x) => x === 2).length;
  let twoStarPercent = Math.floor((twoStar * 100) / total_length);
  //1-star
  let oneStar = overAllRating.filter((x) => x === 1).length;
  let oneStarPercent = Math.floor((oneStar * 100) / total_length);

  useEffect(() => {
    handleAllQA();
    const search = locationSearch.search;
    const tab = new URLSearchParams(search).get("tab");
    if (tab) setSelectedTab(tab);
    return () => { };
  }, [locationSearch, handleAllQA]);

  let answerData = qaDetails?.questionDetails
    ?.filter((e) => e.questionId === questionID)
    .map((e) => {
      return e.answerDetails;
    });
  let questionData = qaDetails?.questionDetails
    ?.filter((e) => e.questionId === questionID)
    .map((e) => {
      return e.question;
    });
  let createdData = qaDetails?.questionDetails
    ?.filter((e) => e.questionId === questionID)
    .map((e) => {
      return e.createdAt;
    });

  //create like and dislike
  const handleLikeDislike = async (ansId, type) => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      answerId: ansId,
      thumbsType: type,
      userProfileId: state?.auth?.auth?.userProfileId,
    };
    const response = await CreateLikeDislike(Payload, token);
    if (response && response.statusCode === 200) {
      handleAllQA();
    } else {
      showToastErrorMsg(response.message);
    }
  };

  //Create
  const ClearFields = () => {
    setCreateAns([]);
    setValidation({});
  };
  const PublishValidation = () => {
    let validate = {};
    validate.answer = NonEmptyValidationForDate(createAns?.answer);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      handleCreateAnswer();
    } else {
      console.log("Error");
    }
  };

  const setValue = (key, value) => {
    setCreateAns({ ...createAns, [key]: value });
    if (validation[key]) delete validation[key];
  };
  const setValidationValue = (key, value) => {
    setValidation({ ...validation, [key]: value });
  };
  const handleCreateAnswer = async () => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      questionId: questionID,
      answer: createAns.answer,
      answeredBy: state?.auth?.auth?.userProfileId,
    };
    const response = await CreateAnswer(Payload, token);
    if (response && response.statusCode === 200) {
      ClearFields();
      setaddansModal(false);
      showToastSuccessMsg(response.message);
      handleAllQA();
    } else {
      showToastErrorMsg(response.message);
    }
  };

  const setValueQuestion = (key, value) => {
    setCreateQuestion({ ...createQuestion, [key]: value });
    if (validation[key]) delete validation[key];
  };

  //get label question
  const [questionLabel, setQuestionLabel] = useState([]);
  const fetchQuestionLabel = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const Query = `authKey=${authKey}&masterDataType=question_label&status=Active&restrictionLevel=0&userId=${userProfileId}`;
    const response = await GetMasterDatasByType(Query);
    if (response && response.statusCode === 200) {
      let data1 = response.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          masterDataId: e.masterDataId,
        };
      });
      setQuestionLabel(mappingData);
    }
  }, [authKey, userProfileId]);

  useEffect(() => {
    fetchQuestionLabel();
  }, [fetchQuestionLabel]);

  const handleCreateQuestionLabel = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("label", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "question_label",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        restrictionLevel: 0,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchQuestionLabel();
        const data = {
          value: response.data.name,
          label: response.data.name,
          masterDataId: response.data.masterDataId,
        };
        if (createQuestion?.label?.length) {
          let setNewData = createQuestion?.label;
          setNewData.push(data);
          setValueQuestion("label", setNewData);
        } else {
          setValueQuestion("label", [data]);
        }
      }
    }
  };

  const ClearFieldsQuestion = () => {
    setCreateQuestion(null);
    setValidation({});
  };
  const QuestionValidation = () => {
    let validate = {};
    validate.question = NonEmptyValidationForDate(createQuestion?.question);
    validate.label = NonEmptyValidation(createQuestion?.label);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      handleQuestion();
    } else {
      console.log("Error");
    }
  };
  //create question
  const handleQuestion = async () => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      companyId: id,
      askedBy: state?.auth?.auth?.userProfileId,
      question: createQuestion.question,
      label:
        createQuestion?.label?.masterDataId ||
        createQuestion?.label?.map((e) => e.masterDataId.toString()),
    };
    const response = await CreateQuestion(Payload, token);
    if (response && response.statusCode === 200) {
      ClearFieldsQuestion();
      handleAllQA();
      setAddQuestionModal(false);
      showToastSuccessMsg(response.message);
    } else {
      showToastErrorMsg(response.message);
    }
  };

  //Find all jobs
  useEffect(() => {
    handleAllJob();
    const search = locationSearch.search;
    const tab = new URLSearchParams(search).get("tab");
    if (tab) setSelectedTab(tab);
    return () => { };
  }, [locationSearch, handleAllJob]);

  //Find all photos
  const handleAllPhotos = useCallback(async () => {
    if (!authKey) return;

    const response = await GetGallery(authKey, id, sortPhoto);
    if (response && response.statusCode === 200) {
      setGalleryPhoto(response.data);
    } else {
      setGalleryPhoto([]);
    }
  }, [authKey, id, sortPhoto]);

  useEffect(() => {
    handleAllPhotos();
    const search = locationSearch.search;
    const tab = new URLSearchParams(search).get("tab");
    if (tab) setSelectedTab(tab);
    return () => { };
  }, [locationSearch, handleAllPhotos]);

  let url = window.location.href;
  let route = url.split("/");

  return (
    <div className="dashboard-bg">
      <NavbarEmployee />
      <div className="main ce-section">
        <div className="ci-section  scroll-content">
          <div className="ci-banner-section">
            <img
              className="ci-bannerImage"
              src={overviewData?.bannerImage ? overviewData?.bannerImage : CompBanner}
              alt=""
            />
          </div>
          <div className="ci-banner-bottom pb-5">
            <div className="container">
              <div className="ci-logo-btn">
                <div className="ci-logoBG">
                  {/* <img
                    className="ci-bannerLogo"
                    src={overviewData.companyLogo}
                    alt=""
                  /> */}
                  {overviewData?.companyLogo ? (
                    <img
                      className="ci-bannerLogo"
                      src={overviewData?.companyLogo}
                      alt=""
                      hidden={overviewData?.companyLogo === ""}
                    />
                  ) : (
                    <h2 className="compworked-img ci-bannerLogo font-weight-bold">
                      {GetInitials(companyName[0])}
                    </h2>
                  )}
                </div>
                <div className="my-2 ci-btn">
                  <button
                    type="button"
                    className="btn btn-light mx-2  login-btn"
                    onClick={() => {
                      Navigate(`/CompaniesWAReview/${route[5]}`);
                    }}
                  >
                    Write a Review
                  </button>
                  {/* </Link> */}
                </div>
              </div>
              <div className="ci-comp-add">
                <h5 className="font-weight-bold">{companyName[0]}</h5>

                <div className="d-flex align-items-center mb-3">
                  <h5 className="my-0 font-weight-bold ">
                    {overAllRating_avg || 0}
                  </h5>
                  &nbsp;
                  <div className="rate-area ">
                    <StarRatings
                      rating={overAllRating_avg || 0}
                      starDimension="20px"
                      starSpacing="1px"
                      starRatedColor="#e75f6b"
                    />
                  </div>
                  {/* <h5 className="pl-2 font-weight-bold text-blue2">
                    <span className="material-symbols-rounded align-middle filled-icon">check_circle</span> <span className="mt-2">Claimed</span>
                  </h5> */}
                </div>
                <h6>{overviewData?.companyIndustryName}</h6>
              </div>
            </div>
          </div>
          <div className="ci-tab-section  mt-2 py-1">
            <div className="">
              <>
                <nav className="flex border-b tab-section companies-tab-section border-gray-300">
                  {Alltabs.map((tab, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          selectedTab === tab ? "tab active" : "tab inactive"
                        }
                        onClick={() => {
                          navigation({
                            pathname: `/CompaniesIndividualPage/${id}`,
                            search: `tab=${tab}`,
                          });
                        }}
                      >
                        <li className="tab-header">{tab}</li>
                      </div>
                    );
                  })}
                </nav>
                <div className="">
                  {selectedTab === "Overview" && (
                    <div className="Companies-Overview">
                      <div className="container py-4">
                        <h5 className="font-weight-bold pb-2 my-0">
                          About the Company
                        </h5>
                        <div className="row d-flex align-items-center justify-content-center ">
                          <div className="col-lg-3 col-md-4  my-3">
                            <div className="ce-card2 p-3">
                            {overviewData?.companyLogo ? (
                              <img
                                className="ce-ceo-img rounded"
                                src={overviewData.companyLogo}
                                alt=""
                                hidden={!overviewData.companyLogo}
                              />
                            ):  <h2 className="ce-ceo-img text-center font-weight-bold">
                      {GetInitials(companyName[0])}
                    </h2> }
                              {/* <h6 className="font-weight-bold text-center mt-3">
                                {overviewData.ceoName || ""}
                              </h6>
                              <h6 className="text-center mb-0">CEO</h6> */}
                            </div>
                          </div>
                          <div className="col-lg-9  col-md-8">
                            <div className="row">
                              <div className="col-lg-4 col-md-6  my-3">
                                <div className="ce-card2 d-flex flex-column  p-3">
                                  <h5 className="font-weight-bold mb-3 color-gray">
                                    Founded
                                  </h5>
                                  <h5 className="font-weight-bold">
                                    {overviewData.founded || ""}
                                  </h5>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6   my-3">
                                <div className="ce-card2 d-flex flex-column p-3">
                                  <h5 className="font-weight-bold mb-3 color-gray">
                                    Company size
                                  </h5>
                                  <h5 className="font-weight-bold">
                                    {overviewData.companySizeName || ""}
                                  </h5>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6   my-3">
                                <div className="ce-card2 d-flex flex-column p-3">
                                  <h5 className="font-weight-bold mb-3 color-gray">
                                    Revenue
                                  </h5>
                                  <h5 className="font-weight-bold">
                                    {overviewData.revenueName || ""}
                                  </h5>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6  my-3">
                                <div className="ce-card2 d-flex flex-column p-3">
                                  <h5 className="font-weight-bold mb-3 color-gray">
                                    Industry
                                  </h5>
                                  <h5 className="font-weight-bold">
                                    {overviewData.companyIndustryName || ""}
                                  </h5>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6   my-3 ">
                                <div className="ce-card2 d-flex flex-column p-3">
                                  <h5 className="font-weight-bold mb-3 color-gray">
                                    Headquarters
                                  </h5>
                                  <h5 className="font-weight-bold">
                                    {overviewData.headQuarterslocation || ""}
                                  </h5>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6   my-3">
                                <div className="ce-card2 d-flex flex-column p-3">
                                  <h5 className="font-weight-bold mb-3 color-gray">
                                    Website Link
                                  </h5>
                                  <h6 className="text-blue">
                                    <a href={externalLink} target="_blank" rel="noreferrer">
                                      {externalLink}
                                    </a>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedTab === "Reviews" && (
                    <div className="py-1 Companies-Reviews">
                      <div className="container-fluid py-4">
                        <div className="row d-flex justify-content-center align-items-center ">
                          <div className="col-lg-9 mb-auto">
                            <h5 className="font-weight-bold pb-4  my-0">
                              {companyName} Employee Reviews
                            </h5>
                          </div>
                        </div>

                        <div className="row d-flex justify-content-center align-items-center ">
                          <div className="col-lg-9 mb-auto">
                            <div className="reviews-heading reviews-heading-comp row">
                              <div className="col-lg-5 col-md-5 my-auto">
                                <h6 className="font-weight-bold">
                                  Overall rating
                                </h6>
                                <div className="rate-area2 ">
                                  <StarRatings
                                    rating={overAllRating_avg || 0}
                                    starDimension="30px"
                                    starSpacing="1px"
                                    starRatedColor="#e75f6b"
                                  />
                                </div>
                                <div>
                                  <p
                                    className="my-3"
                                    style={{ display: "inline-block" }}
                                  >
                                    Based on {successReview.length} reviews
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-7 col-md-7">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h5 className="d-flex font-weight-bold ml-2">
                                    <span className="align-middle"> 05</span>
                                    <span className="material-symbols-rounded filled-icon text-danger align-middle">
                                      star
                                    </span>
                                  </h5>
                                  <div className="progress  w-75">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow="25"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width:
                                          fiveStarPercent === 100
                                            ? "100%"
                                            : fiveStarPercent >= 80
                                              ? "75%"
                                              : fiveStarPercent >= 60
                                                ? "50%"
                                                : fiveStarPercent >= 40
                                                  ? "25%"
                                                  : fiveStarPercent >= 20
                                                    ? "15%"
                                                    : fiveStarPercent >= 10
                                                      ? "10%"
                                                      : fiveStarPercent >= 5
                                                        ? "5%"
                                                        : "0%",
                                        borderRadius: "20px",
                                      }}
                                    ></div>
                                  </div>
                                  <small className="ml-2">{fiveStar}</small>
                                </div>
                                <div className="d-flex  justify-content-between align-items-center">
                                  <h5 className="d-flex font-weight-bold ml-2">
                                    <span className="align-middle"> 04</span>
                                    <span className="material-symbols-rounded filled-icon text-danger align-middle">
                                      star
                                    </span>
                                  </h5>
                                  <div className="progress  w-75">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow="25"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width:
                                          fourStarPercent === 100
                                            ? "100%"
                                            : fourStarPercent >= 80
                                              ? "75%"
                                              : fourStarPercent >= 60
                                                ? "50%"
                                                : fourStarPercent >= 40
                                                  ? "25%"
                                                  : fourStarPercent >= 20
                                                    ? "15%"
                                                    : fourStarPercent >= 10
                                                      ? "10%"
                                                      : fourStarPercent >= 5
                                                        ? "5%"
                                                        : "0%",
                                        borderRadius: "20px",
                                      }}
                                    ></div>
                                  </div>
                                  <small className="ml-2">{fourStar}</small>
                                </div>
                                <div className="d-flex  justify-content-between align-items-center">
                                  <h5 className="d-flex font-weight-bold ml-2">
                                    <span className="align-middle"> 04</span>
                                    <span className="material-symbols-rounded filled-icon text-danger align-middle">
                                      star
                                    </span>
                                  </h5>

                                  <div className="progress w-75">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow="25"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width:
                                          threeStarPercent === 100
                                            ? "100%"
                                            : threeStarPercent >= 80
                                              ? "75%"
                                              : threeStarPercent >= 60
                                                ? "50%"
                                                : threeStarPercent >= 40
                                                  ? "25%"
                                                  : threeStarPercent >= 20
                                                    ? "15%"
                                                    : threeStarPercent >= 10
                                                      ? "10%"
                                                      : threeStarPercent >= 5
                                                        ? "5%"
                                                        : "0%",
                                        borderRadius: "20px",
                                      }}
                                    ></div>
                                  </div>
                                  <small className="ml-2"> {threeStar}</small>
                                </div>
                                <div className="d-flex  justify-content-between align-items-center">
                                  <h5 className="d-flex font-weight-bold ml-2">
                                    <span className="align-middle"> 02</span>
                                    <span className="material-symbols-rounded filled-icon text-danger align-middle">
                                      star
                                    </span>
                                  </h5>

                                  <div className="progress  w-75">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow="25"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width:
                                          twoStarPercent === 100
                                            ? "100%"
                                            : twoStarPercent >= 80
                                              ? "75%"
                                              : twoStarPercent >= 60
                                                ? "50%"
                                                : twoStarPercent >= 40
                                                  ? "25%"
                                                  : twoStarPercent >= 20
                                                    ? "15%"
                                                    : twoStarPercent >= 10
                                                      ? "10%"
                                                      : twoStarPercent >= 5
                                                        ? "5%"
                                                        : "0%",
                                        borderRadius: "20px",
                                      }}
                                    ></div>
                                  </div>
                                  <small className="ml-2">{twoStar}</small>
                                </div>
                                <div className="d-flex  justify-content-between align-items-center">
                                  <h5 className="d-flex font-weight-bold ml-2">
                                    <span className="align-middle">01</span>
                                    <span className="material-symbols-rounded filled-icon text-danger align-middle">
                                      star
                                    </span>
                                  </h5>
                                  <div className="progress  w-75">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow="25"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width:
                                          oneStarPercent === 100
                                            ? "100%"
                                            : oneStarPercent >= 80
                                              ? "75%"
                                              : oneStarPercent >= 60
                                                ? "50%"
                                                : oneStarPercent >= 40
                                                  ? "25%"
                                                  : oneStarPercent >= 20
                                                    ? "15%"
                                                    : oneStarPercent >= 10
                                                      ? "10%"
                                                      : oneStarPercent >= 5
                                                        ? "5%"
                                                        : "0%",
                                        borderRadius: "20px",
                                      }}
                                    ></div>
                                  </div>
                                  <small className="ml-2">{oneStar}</small>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-lg-3  mb-auto">
                            <div className="reviews-heading reviews-heading-comp py-3">
                              <h6 className="font-weight-bold">Want to know more about working here?</h6>
                              <p className="">
                                <small>Ask a question about working or interviewing at {companyName}. Our community is ready to answer</small>
                              </p>
                              <button type="button" className="btn btn-light mx-2  login-btn">
                                Ask a Question
                              </button>
                            </div>
                          </div> */}
                          <div className="col-lg-9 px-0">
                            <div className="faq-heading reviews-heading-comp d-flex justify-content-between align-items-center">
                              <h5 className="font-weight-bold">
                                {/* <span className="text-green">2</span> of <span className="text-green">4</span> review */}
                              </h5>
                              <div className="d-flex">
                                <p className="d-flex align-items-center mb-0 mr-2">
                                  Sort by
                                </p>
                                <div className="form-group mb-0">
                                  <select
                                    value={sortOrder}
                                    className="form-control font-weight-bold text-green"
                                    style={{ backgroundColor: "#e9ecef00" }}
                                    id="exampleFormControlSelect1"
                                    onChange={(e) =>
                                      setSortOrder(e.target.value)
                                    }
                                  >
                                    <option value="newest">NEWEST</option>
                                    <option value="oldest">OLDEST</option>
                                    <option value="ratingByLowest">
                                      LOWEST RATING
                                    </option>
                                    <option value="ratingByHighest">
                                      HIGHEST RATING
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          {successReview?.length < 1 ? (
                            <div className="col-lg-9 justify-content-center">
                              <div className="col-lg-12 mt-4">
                                <h6 className="text-center font-weight-bold my-2">
                                  <img
                                    style={{
                                      width: "85px",
                                      textShadow:
                                        "rgb(171 167 167 / 44%) 0px 3px 6px;",
                                    }}
                                    src={Roboimg2}
                                    alt=""
                                  />
                                </h6>
                                <br />
                                <div>
                                  <h6 className="text-center font-weight-bold my-2">
                                    No Reviews Available
                                  </h6>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              {successReview?.map((data, index) => {
                                return (
                                  <div className="col-lg-9 px-0" key={index}>
                                    <div className="faq-content reviews-heading-comp mt-2">
                                      <div className="row border-faq">
                                        <div className="pr-0 col-lg-2 d-flex justify-content-center">
                                          <img
                                            style={{
                                              width: "120px",
                                              height: "120px",
                                            }}
                                            src={Roboimg}
                                            alt=""
                                          />
                                        </div>
                                        <div className=" col-lg-10">
                                          <div className="faq-body">
                                            <h6 className="font-weight-bold mb-0">
                                              {data?.userProfile?.displayName
                                                ? data?.userProfile?.displayName
                                                : data?.companyProfile
                                                  ?.displayName}
                                            </h6>
                                            <small>
                                              {
                                                data?.userProfile
                                                  ?.primaryLocation?.name
                                              }
                                            </small>
                                            <div className="d-flex align-items-center mb-3">
                                              <h5 className="my-0 font-weight-bold ">
                                                {data.overRating}
                                              </h5>
                                              &nbsp;
                                              <div className="rate-area ">
                                                <StarRatings
                                                  rating={4}
                                                  value={data.overRating}
                                                  starDimension="20px"
                                                  starSpacing="1px"
                                                  starRatedColor="#e75f6b"
                                                />
                                              </div>
                                              <small className="pl-2">
                                                {moment(data?.createdAt).format(
                                                  "MM-DD-YYYY"
                                                )}
                                              </small>
                                            </div>
                                            <p className="dis-in-reviews">
                                              {data.yourReview}
                                            </p>
                                            <h6 className="font-weight-bold">
                                              Pros
                                            </h6>
                                            <p>{data.pros}</p>
                                            <h6 className="font-weight-bold">
                                              Cons
                                            </h6>
                                            <p>{data.cons}</p>
                                            {/* <div className="mb-3">
                                      <small>was this review helpfull?</small>
                                      <div className="d-flex">
                                        <button type="button" className="btn btn-light mr-2  postjob-btn">
                                          Yes
                                        </button>
                                        <button type="button" className="btn btn-light mx-2  postjob-btn">
                                          No
                                        </button>
                                      </div>
                                    </div> */}
                                            <div className="d-flex reviews-box-content">
                                              <img
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                }}
                                                src={Roboimg}
                                                alt=""
                                              />
                                              <div className="ml-2">
                                                <h6 className="font-weight-bold mb-0">
                                                  {
                                                    data?.companyProfile
                                                      ?.companyName
                                                  }
                                                </h6>
                                                <p className="">
                                                  {/* <small>Davis, ca</small> */}
                                                </p>
                                                <p>{data.answer}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedTab === "QA" && (
                    <div>
                      <div className="container-fluid pt-4">
                        <h5 className="font-weight-bold pb-4 my-0">
                          Questions and Answers about {companyName}
                        </h5>
                        {showAnswerList === false && (
                          <>
                            <div className="row">
                              <div className="col-lg-9 col-md-9">
                                <div className="row">
                                  <div className="col-lg-12 px-1 mb-2">
                                    <div className="faq-heading reviews-heading-comp py-4  align-items-center">
                                      <h6 className="font-weight-bold mb-3">
                                        {qaDetails?.questionDetails?.length}{" "}
                                        Questions about working at{" "}
                                        {companyName[0]}
                                      </h6>
                                      <div className="d-flex flex-sm-row flex-column justify-content-between">
                                        <div className="input-group flex-nowrap mr-4">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text material-symbols-rounded">
                                              search
                                            </span>
                                          </div>
                                          <input
                                            autoComplete="off"
                                            type="text"
                                            className="form-control"
                                            placeholder="Username"
                                            aria-label="Username"
                                            aria-describedby="addon-wrapping"
                                          />
                                        </div>
                                        <button className="btn btn-light my-2 my-sm-0  login-btn">
                                          Browse questions
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-12 px-1 mb-2">
                                    <div className="faq-heading reviews-heading-comp d-flex justify-content-between align-items-center">
                                      <div className="d-flex">
                                        <p className="d-flex align-items-center mb-0 mr-2">
                                          Sort by
                                        </p>
                                        <div className="form-group d-flex align-items-center mb-0">
                                          <select
                                            value={sortOrderQA}
                                            className="form-control font-weight-bold text-green"
                                            style={{
                                              backgroundColor: "#e9ecef00",
                                            }}
                                            id="exampleFormControlSelect1"
                                            onChange={(e) =>
                                              setSortOrderQA(e.target.value)
                                            }
                                          >
                                            <option value="newest">
                                              NEWEST
                                            </option>
                                            <option value="oldest">
                                              OLDEST
                                            </option>
                                          </select>
                                        </div>
                                        {/* <button type="button" className="btn btn-light mx-2  login-btn">
                                      Add an Answer
                                    </button> */}
                                      </div>
                                    </div>
                                  </div>
                                  {qaDetails?.questionDetails?.length < 1 ? (
                                    <div className="col-lg-12 justify-content-center">
                                      <h6 className="text-center font-weight-bold my-2">
                                        <img
                                          style={{
                                            width: "85px",
                                            textShadow:
                                              "rgb(171 167 167 / 44%) 0px 3px 6px;",
                                          }}
                                          src={Roboimg2}
                                          alt=""
                                        />
                                      </h6>
                                      <br />
                                      <div>
                                        <h6 className="text-center font-weight-bold my-2">
                                          No Q&As Available
                                        </h6>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {qaDetails?.questionDetails?.map(
                                        (data, index) => {
                                          return (
                                            <div
                                              className="col-lg-12 px-1 mb-2"
                                              key={index}
                                            >
                                              <div className="faq-content reviews-heading-comp">
                                                <div className="faq-body">
                                                  <h6 className="font-weight-bold">
                                                    {data.question}
                                                  </h6>
                                                  <p>
                                                    <small>
                                                      Created
                                                      {moment(
                                                        data.createdAt
                                                      ).format("LL")}
                                                    </small>
                                                  </p>
                                                  {data.answerDetails
                                                    ?.filter(
                                                      (item) =>
                                                        item.accpetedBy === true
                                                    )
                                                    .slice(0, 1)
                                                    .map((item, index) => {
                                                      return (
                                                        <div key={index}>
                                                          <p>{item.answer}</p>
                                                        </div>
                                                      );
                                                    })}
                                                  <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center">
                                                    <div className="d-flex">
                                                      <button
                                                        className="btn btn-light my-2 my-sm-0 mr-5 postjob-btn"
                                                        onClick={() => {
                                                          setQuestionID(
                                                            data.questionId
                                                          );
                                                          setaddansModal(true);
                                                        }}
                                                      >
                                                        Answer
                                                      </button>
                                                      <p
                                                        className="mb-0 d-flex   align-items-center text-green"
                                                        onClick={() =>
                                                          setShowAnswerList(
                                                            true
                                                          )
                                                        }
                                                      >
                                                        <a
                                                          href="/#"
                                                          className="text-green"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            setQuestionID(
                                                              data.questionId
                                                            );
                                                          }}
                                                        >
                                                          <u>See all answers</u>
                                                        </a>
                                                      </p>
                                                      {/* <p className="mb-0 d-flex   align-items-center">
                                          <a href="#top" className="text-green">
                                            <u>See all answers</u>
                                          </a>
                                        </p> */}
                                                    </div>
                                                    {data.answerDetails
                                                      ?.filter(
                                                        (item) =>
                                                          item.accpetedBy ===
                                                          true
                                                      )
                                                      .slice(0, 1)
                                                      .map((item, index) => {
                                                        return (
                                                          <div key={index}>
                                                            {item.accpetedBy ===
                                                              true ? (
                                                              <p className="font-weight-bold mb-0 faq-checkbox active">
                                                                <span className="material-symbols-rounded filled-icon align-middle">
                                                                  check_circle
                                                                </span>
                                                                Accepted by
                                                                employer
                                                              </p>
                                                            ) : null}
                                                          </div>
                                                        );
                                                      })}
                                                    {/* <p className="font-weight-bold mb-0 faq-checkbox active">
                                              <span className="material-symbols-rounded filled-icon align-middle">check_circle</span> Accepted answer
                                            </p> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                  <div className="col-lg-12 px-1 my-4">
                                    <div className="faq-content ">
                                      <div className="faq-body">
                                        <p className="my-3">
                                          Please note that all of this content
                                          is user-generated and its accuracy is
                                          not guaranteed by EADJobs.us or this
                                          company.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3   px-1">
                                <div className="reviews-heading reviews-heading-comp py-3">
                                  <h6 className="font-weight-bold">
                                    Can't find your question about{" "}
                                    {companyName[0]}?
                                  </h6>
                                  <button
                                    type="button"
                                    className="btn btn-light  my-2  login-btn"
                                    onClick={() => {
                                      setAddQuestionModal(true);
                                    }}
                                  >
                                    Ask a Question
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {showAnswerList === true && (
                        <div className="container-fluid pb-4">
                          <h5 className="font-weight-bold  my-0">
                            {questionData[0]}
                          </h5>
                          <p> Asked {moment(createdData[0]).format("LL")}</p>
                          <div className="row">
                            <div className="col-lg-9">
                              <div className="row">
                                <div className="col-lg-12 px-1 mb-2">
                                  <div className="faq-heading reviews-heading-comp d-flex justify-content-between align-items-center">
                                    {/* <h5 className="font-weight-bold">
                                    <span className="text-green">25</span> Answers
                                  </h5> */}
                                    <div className="d-flex">
                                      {/* <h5 className='font-weight-bold'><span className='text-green'>{answerData[0].length}</span> Answers</h5> */}
                                      <button
                                        className="btn btn-light m-2 my-sm-0 login-btn"
                                        onClick={() => {
                                          setShowAnswerList(false);
                                        }}
                                      >
                                        Back
                                      </button>
                                    </div>
                                    <div className="d-flex">
                                      <p className="d-flex align-items-center mb-0 mr-2">
                                        Sort by
                                      </p>
                                      <div className="form-group d-flex align-items-center mb-0">
                                        <select
                                          value={answerSort}
                                          className="form-control font-weight-bold text-green"
                                          style={{
                                            backgroundColor: "#e9ecef00",
                                          }}
                                          id="exampleFormControlSelect1"
                                          onChange={(e) =>
                                            setAnswerSort(e.target.value)
                                          }
                                        >
                                          <option value="newest">NEWEST</option>
                                          <option value="oldest">OLDEST</option>
                                        </select>
                                      </div>
                                      <button
                                        className="btn btn-light my-2 mx-2 my-sm-0  login-btn"
                                        onClick={() => {
                                          setQuestionID(questionID);
                                          setaddansModal(true);
                                        }}
                                      >
                                        Add an Answer
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {answerData[0]?.length > 1 &&
                                  answerData[0].map((item, index) => {
                                    return (
                                      <div
                                        className="col-lg-12 px-1 mb-2"
                                        key={index}
                                      >
                                        <div className="faq-content reviews-heading-comp">
                                          <div className="faq-body">
                                            <p>
                                              <small>
                                                Answered
                                                {moment(item.createdAt).format(
                                                  "LL"
                                                )}
                                                -
                                                {item.userProfile
                                                  ? item.userProfile
                                                    ?.displayName
                                                  : item.companyProfile
                                                    .displayName}
                                                {item.userProfile
                                                  ? item.userProfile
                                                    .primaryLocation?.name
                                                  : null}
                                              </small>
                                            </p>
                                            <p>{item.answer}</p>
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div className="d-flex">
                                                <p
                                                  className="mb-0  d-flex align-items-center"
                                                  onClick={() => {
                                                    handleLikeDislike(
                                                      item.answerId,
                                                      "Like"
                                                    );
                                                  }}
                                                >
                                                  <span
                                                    className={`material-symbols-rounded rounded-circle  faq-thumbs-up align-middle ${item.thumbsType === "Like"
                                                        ? "active"
                                                        : null
                                                      }`}
                                                  >
                                                    thumb_up
                                                  </span>
                                                </p>
                                                <span
                                                  className="badge  my-auto px-2"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {!item.likeCount
                                                    ? 0
                                                    : item.likeCount}
                                                </span>
                                                <p
                                                  className="mb-0  d-flex align-items-center"
                                                  onClick={() => {
                                                    handleLikeDislike(
                                                      item.answerId,
                                                      "DisLike"
                                                    );
                                                  }}
                                                >
                                                  <span
                                                    className={`material-symbols-rounded rounded-circle  faq-thumbs-down align-middle ${item.thumbsType ===
                                                        "DisLike"
                                                        ? "active"
                                                        : null
                                                      }`}
                                                  >
                                                    thumb_down
                                                  </span>
                                                </p>
                                                <span
                                                  className="badge my-auto px-2"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {!item.dislikeCount
                                                    ? 0
                                                    : item.dislikeCount}
                                                </span>
                                              </div>
                                              {item.accpetedBy === true ? (
                                                <p className="font-weight-bold mb-0 faq-checkbox active">
                                                  <span className="material-symbols-rounded filled-icon align-middle">
                                                    check_circle
                                                  </span>
                                                  Accepted by employer
                                                </p>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                <div className="col-lg-12 px-1 my-4">
                                  <div className="faq-content ">
                                    <div className="faq-body">
                                      <p className="my-3">
                                        Please note that all of this content is
                                        user-generated and its accuracy is not
                                        guaranteed by EADJobs.us or this
                                        company.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="col-lg-3   px-1">
                            <div className="reviews-heading reviews-heading-comp py-3">
                              <h6 className="font-weight-bold">Can't find your question about Cognizant Technology Solutions?</h6>
                              <button type="button" className="btn btn-light  my-2  login-btn">
                                Ask a Question
                              </button>
                            </div>
                          </div> */}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {selectedTab === "Jobs" && (
                    <div>
                      <div className="py-1 ">
                        <div className="container-fluid py-4">
                          <h5 className="font-weight-bold pb-4 my-0">
                            Find below Jobs that are available in
                            {companyName[0]}
                          </h5>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-lg-12 col-md-9 px-1 mb-2">
                                  <div className="faq-heading reviews-heading-comp d-flex justify-content-between align-items-center">
                                    <div className="d-flex">
                                      <p className="d-flex align-items-center mb-0 mr-2">
                                        Sort by
                                      </p>
                                      <div className="form-group d-flex align-items-center mb-0">
                                        <select
                                          className="form-control font-weight-bold text-green"
                                          style={{
                                            backgroundColor: "#e9ecef00",
                                          }}
                                          id="exampleFormControlSelect1"
                                          onChange={(e) =>
                                            setSortJob(e.target.value)
                                          }
                                        >
                                          <option value="newest">NEWEST</option>
                                          <option value="oldest">OLDEST</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="Jobssection3">
                                    <div className="row">
                                      {jobsData?.length < 1 ? (
                                        <div className="col-lg-12 justify-content-center">
                                          <h6 className="text-center font-weight-bold my-2">
                                            <img
                                              style={{
                                                width: "85px",
                                                textShadow:
                                                  "rgb(171 167 167 / 44%) 0px 3px 6px;",
                                              }}
                                              src={Roboimg2}
                                              alt=""
                                            />
                                          </h6>
                                          <br />
                                          <div>
                                            <h6 className="text-center font-weight-bold my-2">
                                              No Jobs Available
                                            </h6>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          {jobsData?.map((data, index) => {
                                            return (
                                              <div
                                                className="col-lg-3 jobs-padding col-md-6"
                                                key={index}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  navigation(
                                                    `/JobInfo/${data?.jobId}`
                                                  );
                                                }}
                                              >
                                                <div className="jobs-content">
                                                  <div className='scroll-jobs-employee scroll-content-design'>
                                                    <div className="d-flex">
                                                      <img
                                                        className="compworked-img"
                                                        src={
                                                          overviewData?.companyLogo
                                                        }
                                                        alt=""
                                                      />
                                                      <div className="match d-flex align-items-center mx-2">
                                                        <small className="color-green">
                                                          90% Match
                                                        </small>
                                                      </div>
                                                    </div>
                                                    {/* <span className="material-symbols-rounded jobs-eye-icon">
                                                      visibility_off
                                                    </span>
                                                    <span className="material-symbols-rounded jobs-edit-icon">
                                                      bookmark
                                                    </span> */}
                                                    <div className="redirect-link">
                                                      <h5 className="font-weight-bold cut-text">
                                                        {data?.jobTitle}
                                                      </h5>
                                                      <p className="mb-1">
                                                        {companyName[0]}
                                                      </p>
                                                      {data?.locationId?.map(
                                                        (item, index) => {
                                                          return (
                                                            <h6
                                                              className="my-2"
                                                              key={index}
                                                            >
                                                              <span className="material-symbols-rounded filled-icon icon-middle">
                                                                location_on
                                                              </span>
                                                              {item}
                                                            </h6>
                                                          );
                                                        }
                                                      )}

                                                      <table className="py-0">
                                                        <tbody>
                                                          <tr>
                                                            <td>Experience </td>
                                                            <td>:</td>
                                                            <th>
                                                              {data?.ExperienceLevel?.map(
                                                                (item, index) => {
                                                                  return (
                                                                    <p
                                                                      className="mb-0"
                                                                      key={index}
                                                                    >
                                                                      {item}
                                                                    </p>
                                                                  );
                                                                }
                                                              )}
                                                            </th>
                                                          </tr>
                                                          <tr>
                                                            <td>Job Type</td>
                                                            <td>:</td>

                                                            <th>
                                                              {data?.WorkPlaceType?.map(
                                                                (item, index) => {
                                                                  return (
                                                                    <p
                                                                      className="mb-0"
                                                                      key={index}
                                                                    >
                                                                      {item}
                                                                    </p>
                                                                  );
                                                                }
                                                              )}
                                                            </th>
                                                          </tr>
                                                          <tr>
                                                            <td className="text-nowrap">
                                                              Employment Type
                                                            </td>
                                                            <td>:</td>
                                                            <th>
                                                              {data?.EmploymentType?.map(
                                                                (item, index) => {
                                                                  return (
                                                                    <p
                                                                      className="mb-0"
                                                                      key={index}
                                                                    >
                                                                      {item}
                                                                    </p>
                                                                  );
                                                                }
                                                              )}
                                                            </th>
                                                          </tr>
                                                          <tr>
                                                            <td>Salary</td>
                                                            <td>:</td>
                                                            <th>
                                                              $ {data.salaryMin}-
                                                              {data.salaryMax}
                                                              {data.salaryRangeBy}
                                                            </th>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                      <div className="Jobs-details-skills2 mb-2">
                                                        <p className="mb-2 font-weight-bold">
                                                          Primary skills
                                                        </p>
                                                        {data?.MustHaveSkill?.map(
                                                          (item, index) => {
                                                            return (
                                                              <li key={index}>
                                                                {item}
                                                              </li>
                                                            );
                                                          }
                                                        )}
                                                        {data?.NiceTohaveSkill?.map(
                                                          (item, index) => {
                                                            return (
                                                              <li key={index}>
                                                                {item}
                                                              </li>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                      <div className="d-flex justify-content-between mt-1">
                                                        <small>
                                                          <span></span>
                                                        </small>
                                                        <small>
                                                          <span className="text-color-blue"></span>
                                                        </small>
                                                      </div>
                                                    </div>
                                                  </div>

                                                </div>
                                              </div>
                                            );
                                          })}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="col-lg-3  px-1">
                              <div className="reviews-heading reviews-heading-comp py-3">
                                <h6 className="font-weight-bold">Want to know more about working here?</h6>
                                <p className="">
                                  <small>Ask a question about working or interviewing at Cognizant Technology Solutions. Our community is ready to answer</small>
                                </p>
                                <button type="button" className="btn btn-light mx-2  login-btn">
                                  Ask a Question
                                </button>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedTab === "Images" && (
                    <div>
                      <div className="py-1 ">
                        <div className="container-fluid py-4">
                          <h5 className="font-weight-bold pb-4 my-0">
                            Glimpses of working at {companyName[0]}
                          </h5>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-lg-12 col-md-9 px-1 mb-2">
                                  <div className="faq-heading reviews-heading-comp d-flex justify-content-between align-items-center">
                                    <div className="d-flex">
                                      <p className="d-flex align-items-center mb-0 mr-2">
                                        Sort by
                                      </p>
                                      <div className="form-group d-flex align-items-center mb-0">
                                        <select
                                          value={sortPhoto}
                                          className="form-control font-weight-bold text-green"
                                          style={{
                                            backgroundColor: "#e9ecef00",
                                          }}
                                          id="exampleFormControlSelect1"
                                          onChange={(e) =>
                                            setSortPhoto(e.target.value)
                                          }
                                        >
                                          <option value="newest">NEWEST</option>
                                          <option value="oldest">OLDEST</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="gallery-section">
                                    <div className="row">
                                      {galleryPhoto?.length < 1 ? (
                                        <div className="col-lg-12 justify-content-center">
                                          <h6 className="text-center font-weight-bold my-2">
                                            <img
                                              style={{
                                                width: "85px",
                                                textShadow:
                                                  "rgb(171 167 167 / 44%) 0px 3px 6px;",
                                                height: "auto",
                                              }}
                                              src={Roboimg2}
                                              alt=""
                                            />
                                          </h6>
                                          <br />
                                          <div>
                                            <h6 className="text-center font-weight-bold my-2">
                                              No Images Available
                                            </h6>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          {galleryPhoto?.map((data, index) => {
                                            return (
                                              <div
                                                className="col-lg-3 col-md-4 px-1  mb-2"
                                                key={index}
                                              >
                                                <img
                                                  src={data.imageUrl}
                                                  className="h-100"
                                                  alt=""
                                                />
                                                {/* <span className="material-symbols-rounded  text-white rounded mt-2 del-icon">delete</span> */}
                                              </div>
                                            );
                                          })}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="col-lg-3  px-1">
                              <div className="reviews-heading reviews-heading-comp py-3">
                                <h6 className="font-weight-bold">Want to know more about working here?</h6>
                                <p className="">
                                  <small>Ask a question about working or interviewing at Cognizant Technology Solutions. Our community is ready to answer</small>
                                </p>
                                <button type="button" className="btn btn-light mx-2  login-btn">
                                  Ask a Question
                                </button>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={addansModal}
        onClickOutside={() => {
          setaddansModal(false);
          setValidation({});
          ClearFields();
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Add an Answer
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-10">
                  <div className="modalAnswer mt-2">
                    <div className="form-group">
                      <textarea
                        className="form-control rounded-25 "
                        id="exampleFormControlTextarea2"
                        rows="10"
                        onChange={(e) => {
                          setValue("answer", e.target.value);
                        }}
                        onBlur={(e) => {
                          setValidationValue(
                            "answer",
                            AlphaNumericValidation(e.target.value)
                          );
                        }}
                      ></textarea>
                      {validation?.answer?.status === false && (
                        <small className="text-danger">
                          {validation?.answer?.message
                            ? `Answer is ${validation?.answer?.message}`
                            : ""}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setaddansModal(false);
                    ClearFields();
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn"
                  onClick={() => {
                    PublishValidation();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </CustomModal>
      <CustomModal
        open={addQuestionModal}
        onClickOutside={() => {
          setAddQuestionModal(false);
          setValidation({});
          ClearFieldsQuestion();
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Add Question
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                {/* <h6 className="font-weight-bold  text-center cut-text2">Tips to get helpful answers</h6>
                <p>Check that your question hasn't already been asked</p>
                <p>Ask a direct question</p>
                <p>Check your spelling and grammar</p> */}
                <div className="col-lg-5">
                  {/* <div className="google-input">
										<input autoComplete="off" type="text" id="template-input" placeholder="Choose your reason" />
										<label htmlFor="template-input">
											Reason
										</label>
									</div> */}
                  <div className="google-input">
                    <label htmlFor="template-input" className="z-index-value">
                      Label
                    </label>
                    <div className="dropdown-container">
                      <CreatableSelect
                        options={questionLabel}
                        isMulti
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "6px",
                          zIndex: 3,
                          colors: {
                            ...theme.colors,
                            primary25: "#DFF1F1",
                            neutral20:
                              validation?.label?.status === false
                                ? "red"
                                : "#00ABAD", // border color
                            primary:
                              validation?.label?.status === false
                                ? "red"
                                : "#00ABAD", // border color when focus
                          },
                        })}
                        styles={{
                          menu: (provided, state) => ({
                            ...provided,
                            zIndex: "5 !important",
                          }),
                        }}
                        placeholder="Choose Job Title"
                        value={createQuestion?.label}
                        onChange={(newValue) => {
                          setValueQuestion("label", newValue);
                        }}
                        onCreateOption={handleCreateQuestionLabel}
                        isClearable
                      />

                      {validation?.label?.status === false && (
                        <small className="text-danger">
                          {validation?.label?.message
                            ? `Label ${validation?.label?.message}`
                            : ""}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="modalAnswer mt-2">
                    <label for="exampleFormControlTextarea2">Question</label>
                    {/* <div className="modalDisapprove-details d-flex  justify-content-end ">
                        <button type="button" className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1">
                          Rich Text Editor<span className="material-symbols-rounded modalDisapprove-lockicon mx-1">lock</span>
                        </button>
                      </div> */}
                    <div className="form-group">
                      <textarea
                        className="form-control rounded-0 modalDisapprove-textarea"
                        id="exampleFormControlTextarea2"
                        rows="7"
                        onChange={(e) => {
                          setValueQuestion("question", e.target.value);
                        }}
                        onBlur={(e) => {
                          setValidationValue(
                            "question",
                            AlphaNumericValidation(e.target.value)
                          );
                        }}
                      ></textarea>
                      {validation?.question?.status === false && (
                        <small className="text-danger">
                          {validation?.question?.message
                            ? `Question ${validation?.question?.message}`
                            : ""}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setAddQuestionModal(false);
                    setValidation({});
                    ClearFieldsQuestion();
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn"
                  onClick={() => {
                    QuestionValidation();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <ToastContainer />
    </div>
  );
}
