import React, { useEffect, useCallback, useState } from "react";
import NavbarEmployee from "./NavbarEmployee";
import StarRatings from "react-star-ratings";
import CompBanner from "../../images/compbanner.png";
import {
  CompanyDetailsEmployee,
  CreateEmployeeSkills,
  CreateReviewDetails,
  GetCompanyDetail,
  GetMasterDatasByType,
} from "../Api/Api";
import CreatableSelect from "react-select/creatable";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import {
  AlphaNumericValidation,
  StringValidation,
  NonEmptyValidationForDate,
  NonEmptyValidation,
  NonEmptyValidationForEndDate,
  NonEmptyObjectValidation,
} from "../Store/validate";
import { GetInitials } from "../Store/Constants";

export default function CompaniesWAReview() {
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const userProfileId = state?.auth?.auth?.userProfileId;

  const { id } = useParams();
  const Navigate = useNavigate();
  const [overAllStar, setOverAllStar] = useState(null);
  const [manageStar, setManageStar] = useState(null);
  const [jobCultureStar, setjobCultureStar] = useState(null);
  const [worklifeStar, setWorklifeStar] = useState(null);
  const [compensationStar, setCompensationStar] = useState(null);
  const [securityStar, setSecurityStar] = useState(null);
  const [createReview, setCreateReview] = useState([]);
  const [overviewData, setOverviewData] = useState({});
  const [listingData, setListingData] = useState([]);
  const [validation, setValidation] = useState({});

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };
  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  // Company detail
  const handleCompanyIndividual = useCallback(async () => {
    if (!authKey) return;

    const response = await GetCompanyDetail(authKey, id);
    if (response && response.statusCode === 200) {
      setOverviewData(response.data);
    }
  }, [authKey, id]);

  const handleAllCompany = useCallback(async () => {
    if (!authKey) return;

    const response = await CompanyDetailsEmployee(authKey);
    if (response && response.statusCode === 200) {
      let newData = response?.data?.companyListing;
      setListingData(newData);
    } else {
      setListingData([]);
    }
  }, [authKey]);

  useEffect(() => {
    handleAllCompany();
    handleCompanyIndividual();
    return () => {};
  }, [handleAllCompany, handleCompanyIndividual]);

  let companyName = listingData
    ?.filter((e) => e.companyId === id)
    .map((e) => {
      return e.companyName;
    });
  //get review reason
  const [jobReviewTitle, setJobReviewTitle] = useState([]);

  const fetchReviewTitle = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const Query = `authKey=${authKey}&masterDataType=job_title&status=Active&restrictionLevel=0&userId=${userProfileId}`;
    const response = await GetMasterDatasByType(Query);
    if (response && response.statusCode === 200) {
      let data1 = response.data;
      let mappingData = data1.map((e) => {
        return {
          name: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setJobReviewTitle(mappingData);
    }
  }, [authKey, userProfileId]);

  //get review reason
  const [jobReviewLocation, setJobReviewLocation] = useState([]);

  const fetchReviewLocation = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const Query = `authKey=${authKey}&masterDataType=job_location&status=Active&restrictionLevel=0&userId=${userProfileId}`;
    const response = await GetMasterDatasByType(Query);
    if (response && response.statusCode === 200) {
      let data1 = response.data;
      let mappingData = data1.map((e) => {
        return {
          name: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setJobReviewLocation(mappingData);
    }
  }, [authKey, userProfileId]);

  useEffect(() => {
    fetchReviewTitle();
    fetchReviewLocation();
  }, [fetchReviewTitle, fetchReviewLocation]);

  const handleCreateJobTitle = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("jobTitle", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "job_title",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        //  companyId: state?.auth?.auth?.userDetails?.companyProfileId,
        restrictionLevel: 0,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchReviewTitle();
        const data = {
          name: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        };
        setValue("jobTitle", data);
      }
    }
  };

  const handleCreateJobLocation = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("jobLocation", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "job_location",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        //  companyId: state?.auth?.auth?.userDetails?.companyProfileId,
        restrictionLevel: 0,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchReviewTitle();
        const data = {
          name: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        };
        setValue("jobLocation", data);
      }
    }
  };

  const setValue = (key, value) => {
    setCreateReview({ ...createReview, [key]: value });
    if (validation[key]) delete validation[key];
  };
  const setValidationValue = (key, value) => {
    setValidation({ ...validation, [key]: value });
  };

  const ClearFields = () => {
    setCreateReview(null);
    setValidation({});
  };
  const PublishValidation = () => {
    let validate = {};
    validate.reviewSummary = NonEmptyValidation(
      createReview?.reviewSummary
    );
    validate.yourReview = NonEmptyValidation(createReview?.yourReview);
    validate.pros = NonEmptyValidation(createReview?.pros);
    validate.cons = NonEmptyValidation(createReview?.cons);
    validate.jobTitle = NonEmptyObjectValidation(createReview?.jobTitle);
    validate.jobLocation = NonEmptyObjectValidation(createReview?.jobLocation);
    validate.startDate = NonEmptyValidationForDate(createReview?.startDate);
   
    validate.overRating = NonEmptyValidationForDate(createReview?.overRating);
    validate.management = NonEmptyValidationForDate(createReview?.management);
    validate.jobCulture = NonEmptyValidationForDate(createReview?.jobCulture);
    validate.jobWorklifeBalance = NonEmptyValidationForDate(
      createReview?.jobWorklifeBalance
    );
    validate.compensationbenefits = NonEmptyValidationForDate(
      createReview?.compensationbenefits
    );
    validate.jobSecurityadvancement = NonEmptyValidationForDate(
      createReview?.jobSecurityadvancement
    );
    if(endDateHide === false){
      validate.endDate = NonEmptyValidationForEndDate(createReview?.endDate);
    }
    setValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      handleReview();
    } else {
      console.log("Error");
    }
  };

  const [endDateHide, setEndDateHide] = useState(false);
  //create review
  const handleReview = async () => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      overRating: overAllStar,
      management: manageStar,
      jobCulture: jobCultureStar,
      jobWorklifeBalance: worklifeStar,
      compensationbenefits: compensationStar,
      jobSecurityadvancement: securityStar,
      reviewSummary: createReview.reviewSummary,
      yourReview: createReview.yourReview,
      pros: createReview.pros,
      cons: createReview.cons,
      jobTitle:
        createReview?.jobTitle?.dataId ||
        createReview?.jobTitle?.map((e) => e.dataId.toString()),
      jobLocation:
        createReview?.jobLocation?.dataId ||
        createReview?.jobLocation?.map((e) => e.dataId.toString()),
      startDate: createReview.startDate,
      endDate: endDateHide === true ? "" : createReview.endDate,
      userProfileId: state.auth.auth.userProfileId,
      companyId: id,
    };

    const response = await CreateReviewDetails(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      ClearFields();
      Navigate(`/CompaniesIndividualPage/${id}`);
    } else {
      showToastErrorMsg(response.message);
    }
  };
  return (
    <div>
      <div className="dashboard-bg">
        <NavbarEmployee />
        <div className="main ce-section">
          <div className="ci-section  scroll-content">
            <div className="ci-banner-section">
              <img
                className="ci-bannerImage"
                src={overviewData?.bannerImage ? overviewData?.bannerImage : CompBanner}
                alt=""
               
              />
            </div>
            <div className="ci-banner-bottom pb-5">
              <div className="container">
                <div className="ci-logo-btn">
                  <div className="ci-logoBG">
                  {overviewData?.companyLogo ? (
                    <img
                      className="ci-bannerLogo"
                      src={overviewData?.companyLogo}
                      alt=""
                      hidden={overviewData?.companyLogo === ""}
                    />
                  ) : (
                    <h2 className="compworked-img ci-bannerLogo font-weight-bold">
                      {GetInitials(companyName[0])}
                    </h2>
                  )}
                  </div>
                </div>
                <div className="">
                  <h6 className="font-weight-bold ">
                    Take a minute to review {companyName[0]}{" "}
                  </h6>
                  <p className="">
                    <small>
                      Your anonymous feedback will help fellow jobseekers
                    </small>
                  </p>
                  <p className="">
                    <li>
                      <small>
                        Company Reviews are NEVER attached to your job
                        applications
                      </small>
                    </li>
                    <li>
                      <small>
                        The Reviews ONLY incude star ratings, review text, job
                        title, location and review date
                      </small>
                    </li>
                  </p>
                </div>
              </div>
            </div>
            <div className="ci-tab-section2  mt-2 py-1">
              <div className="cwar-section py-4">
                <div className="container">
                  <h5 className="font-weight-bold text-center py-3">
                    How would u rate this company
                  </h5>
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <div className="row">
                        <div className="col-lg-4 col-md-6 ce-card-head">
                          <div className="ce-card text-center my-3 p-3">
                            <h6 className="font-weight-bold  text-center cut-text2">
                              Overall Rating
                              <span className="text-danger">*</span>
                            </h6>
                            <div className="rate-area pt-3 ">
                              <StarRatings
                                rating={overAllStar || 0}
                                starDimension="20px"
                                starSpacing="3px"
                                starRatedColor="#e75f6b"
                                changeRating={(e) => {
                                  setOverAllStar(e);
                                  setValue("overRating", e);
                                }}
                              />
                            </div>
                            <small className="text-danger">
                              {validation?.overRating?.message
                                ? `Overall Rating ${validation?.overRating?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ce-card-head">
                          <div className="ce-card text-center my-3 p-3">
                            <h6 className="font-weight-bold  text-center cut-text2">
                              Job Work / Life Balance
                              <span className="text-danger">*</span>
                            </h6>
                            <div className="rate-area  pt-3">
                              <StarRatings
                                rating={worklifeStar || 0}
                                starDimension="20px"
                                starSpacing="3px"
                                starRatedColor="#e75f6b"
                                changeRating={(e) => {
                                  setWorklifeStar(e);
                                  setValue("jobWorklifeBalance", e);
                                }}
                              />
                            </div>
                            <small className="text-danger">
                              {validation?.jobWorklifeBalance?.message
                                ? `This Rating ${validation?.jobWorklifeBalance?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ce-card-head">
                          <div className="ce-card text-center my-3 p-3">
                            <h6 className="font-weight-bold  text-center cut-text2">
                              Compensation/Benefits
                              <span className="text-danger">*</span>
                            </h6>
                            <div className="rate-area  pt-3">
                              <StarRatings
                                rating={compensationStar || 0}
                                starDimension="20px"
                                starSpacing="3px"
                                starRatedColor="#e75f6b"
                                changeRating={(e) => {
                                  setCompensationStar(e);
                                  setValue("compensationbenefits", e);
                                }}
                              />
                            </div>
                            <small className="text-danger">
                              {validation?.compensationbenefits?.message
                                ? `This Rating ${validation?.compensationbenefits?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ce-card-head">
                          <div className="ce-card text-center my-3 p-3">
                            <h6 className="font-weight-bold  text-center cut-text2">
                              Job Security / Advancement
                              <span className="text-danger">*</span>
                            </h6>
                            <div className="rate-area  pt-3">
                              <StarRatings
                                rating={securityStar || 0}
                                starDimension="20px"
                                starSpacing="3px"
                                starRatedColor="#e75f6b"
                                changeRating={(e) => {
                                  setSecurityStar(e);
                                  setValue("jobSecurityadvancement", e);
                                }}
                              />
                            </div>
                            <small className="text-danger">
                              {validation?.jobSecurityadvancement?.message
                                ? `This Rating ${validation?.jobSecurityadvancement?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ce-card-head">
                          <div className="ce-card text-center my-3 p-3">
                            <h6 className="font-weight-bold  text-center cut-text2">
                              Management<span className="text-danger">*</span>
                            </h6>
                            <div className="rate-area  pt-3">
                              <StarRatings
                                rating={manageStar || 0}
                                starDimension="20px"
                                starSpacing="3px"
                                starRatedColor="#e75f6b"
                                changeRating={(e) => {
                                  setManageStar(e);
                                  setValue("management", e);
                                }}
                              />
                            </div>
                            <small className="text-danger">
                              {validation?.management?.message
                                ? `This Rating ${validation?.management?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ce-card-head">
                          <div className="ce-card text-center my-3 p-3">
                            <h6 className="font-weight-bold  text-center cut-text2">
                              Job Culture<span className="text-danger">*</span>
                            </h6>
                            <div className="rate-area  pt-3">
                              <StarRatings
                                rating={jobCultureStar || 0}
                                starDimension="20px"
                                starSpacing="3px"
                                starRatedColor="#e75f6b"
                                changeRating={(e) => {
                                  setjobCultureStar(e);
                                  setValue("jobCulture", e);
                                }}
                              />
                            </div>
                            <small className="text-danger">
                              {validation?.jobCulture?.message
                                ? `This Rating ${validation?.jobCulture?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ci-tab-section2  mt-2 py-1">
              <div className="cwar-section py-4">
                <div className="container">
                  <h5 className="font-weight-bold text-center py-3">
                    The good and the bad. What stands out about working in this
                    company
                  </h5>
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-7 m-auto">
                      <div className="section2 pb-3">
                        {/* <form className="my-4"> */}
                        <div className="form-container">
                          <div className="google-input">
                            <input
                              autoComplete="off"
                              type="text"
                              id="fullname"
                              placeholder=""
                              onChange={(e) => {
                                setValue("reviewSummary", e.target.value);
                              }}
                              onBlur={(e) => {
                                setValidationValue(
                                  "reviewSummary",
                                  AlphaNumericValidation(e.target.value)
                                );
                              }}
                            />
                            <label htmlFor="fullname">
                              Review Summary
                              <span className="text-danger">*</span>
                            </label>
                            <small className="text-danger">
                              {validation?.reviewSummary?.message
                                ? `Review Summary ${validation?.reviewSummary?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="google-input">
                            <textarea
                              autoComplete="off"
                              type="text"
                              id="fullname"
                              placeholder=""
                              onChange={(e) => {
                                setValue("yourReview", e.target.value);
                              }}
                              onBlur={(e) => {
                                setValidationValue(
                                  "yourReview",
                                  AlphaNumericValidation(e.target.value)
                                );
                              }}
                            />
                            <label htmlFor="fullname">
                              Your Review<span className="text-danger">*</span>
                            </label>
                            <small className="text-danger">
                              {validation?.yourReview?.message
                                ? `Review ${validation?.yourReview?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="google-input">
                            <textarea
                              autoComplete="off"
                              type="text"
                              id="fullname"
                              placeholder=""
                              onChange={(e) => {
                                setValue("pros", e.target.value);
                              }}
                              onBlur={(e) => {
                                setValidationValue(
                                  "pros",
                                  AlphaNumericValidation(e.target.value)
                                );
                              }}
                            />
                            <label htmlFor="fullname">
                              Pros<span className="text-danger">*</span>
                            </label>
                            <small className="text-danger">
                              {validation?.pros?.message
                                ? `Pros ${validation?.pros?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="google-input">
                            <textarea
                              autoComplete="off"
                              type="text"
                              id="fullname"
                              placeholder=""
                              onChange={(e) => {
                                setValue("cons", e.target.value);
                              }}
                              onBlur={(e) => {
                                setValidationValue(
                                  "cons",
                                  AlphaNumericValidation(e.target.value)
                                );
                              }}
                            />
                            <label htmlFor="fullname">
                              Cons<span className="text-danger">*</span>
                            </label>
                            <small className="text-danger">
                              {validation?.cons?.message
                                ? `Cons ${validation?.cons?.message}`
                                : ""}
                            </small>
                          </div>
                          <h5 className="font-weight-bold py-4">
                            Tell us about you
                          </h5>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Job Title at {companyName[0]}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={jobReviewTitle}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      validation?.jobTitle?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      validation?.jobTitle?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                               
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose Job Title"
                                value={createReview?.jobTitle}
                                onChange={(e) => {
                                  setValue("jobTitle", e);
                                }}
                                onCreateOption={
                                  handleCreateJobTitle
                                }
                                isClearable
                              />
                              {console.log(createReview)}
                              {validation?.jobTitle?.status === false && (
                                <small className="text-danger">
                                  {validation?.jobTitle?.message
                                    ? `Job Title ${validation?.jobTitle?.message}`
                                    : ""}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Job location at {companyName[0]}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={jobReviewLocation}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    neutral20:
                                      validation?.jobLocation?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      validation?.jobLocation?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose Job Title"
                                value={createReview.jobLocation}
                                onChange={(e) => {
                                  setValue("jobLocation", e);
                                }}
                                onCreateOption={handleCreateJobLocation}
                                isClearable
                              />
                              {validation?.jobLocation?.status === false && (
                                <small className="text-danger">
                                  {validation?.jobLocation?.message
                                    ? `Job Location ${validation?.jobLocation?.message}`
                                    : ""}
                                </small>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-12 px-4 pb-4">
                            <div className="form-check mt-4">
                              <input
                                autoComplete="off"
                                className="form-check-input"
                                type="checkbox"
                                id="gridCheck1"
                                onChange={() => {
                                  if (endDateHide === false)
                                    setEndDateHide(true);
                                  else if (endDateHide === true)
                                    setEndDateHide(false);
                                }}
                              />

                              <label
                                className="form-check-label"
                                htmlFor="gridCheck1"
                              >
                                Currently working here
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="google-input">
                                <label
                                  htmlFor="template-input"
                                  className="z-index-value"
                                >
                                  Start Date
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  autoComplete="off"
                                  type="date"
                                  id="template-input"
                                  name="workingSince"
                                  onChange={(e) => {
                                    setValue(
                                      "startDate",
                                      moment(e.target.value).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setValidationValue(
                                      "startDate",
                                      NonEmptyValidationForDate(e.target.value)
                                    );
                                  }}
                                />
                                <small className="text-danger">
                                  {validation?.startDate?.message
                                    ? `Start Date ${validation?.startDate?.message}`
                                    : ""}
                                </small>
                              </div>
                            </div>
                            {endDateHide === false ? (
                              <div className="col-lg-6">
                                <div className="google-input">
                                  <label
                                    htmlFor="template-input"
                                    className="z-index-value"
                                  >
                                    End Date
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    autoComplete="off"
                                    type="date"
                                    id="template-input"
                                    name="endDate"
                                    onChange={(e) => {
                                      setValue(
                                        "endDate",
                                        moment(e.target.value).format(
                                          "YYYY-MM-DD"
                                        )
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setValidationValue(
                                        "endDate",
                                        NonEmptyValidationForDate(
                                          e.target.value
                                        )
                                      );
                                    }}
                                  />
                                  <small className="text-danger">
                                    {validation?.endDate?.message
                                      ? `End Date ${validation?.endDate?.message}`
                                      : ""}
                                  </small>
                                </div>
                                {/* <div className="google-input">
                                 <p className="text-center text-light w-100 bg-secondary py-2 mb-0 rounded">No Expiration</p>
                                </div> */}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="google-input">
                            <button
                              className="btn btn-light my-2 my-sm-0 login-btn"
                              onClick={() => {
                                PublishValidation();
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        {/* </form> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}
