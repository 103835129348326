import React, { useCallback, useState } from "react";
import NavbarDashboard from "./NavbarDashboard";
import CustomModal from "../CustomModal";
import Roboimg from "../../images/roboimg.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Roboimg2 from "../../images/roboimg-outerborder.png";
import {
  CreateEmployeeSkills,
  DeleteReviewDetails,
  GetMasterDatasByType,
  GetReviewDetails,
  UpdateReviewDetails,
} from "../Api/Api";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import CreatableSelect from "react-select/creatable";
import StarRatings from "react-star-ratings";
import { useLocation, useNavigate } from "react-router-dom";
import { NonEmptyValidation, StringValidation } from "../Store/validate";

export default function Reviews() {
  const [disapproveModal, setdisapproveModal] = useState(false);
  const [updateReview, setUpdateReview] = useState([]);
  const [reviewDetails, setReviewDetails] = useState([]);
  const [reviewId, setReviewId] = useState("");
  const [disapproveProfileId, setDisapproveProfileId] = useState("");
  // const [totalCount, setTotalCount] = useState();
  const [selectedSubTab, setSelectedSubTab] = useState("Waiting for Approval");
  const [validation, setValidation] = useState({});
  const [sortOrder, setSortOrder] = useState("newest");
  const state = useSelector((state) => state);
  const navigation = useNavigate();
  const AllSubtabs = ["Waiting for Approval", "Review"];
  const authKey = state?.auth?.auth?.authKey;
  const companyId = state?.auth?.auth?.userDetails?.companyId;

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const handleallReview = useCallback(async () => {
    if (!authKey || !companyId) return

    const response = await GetReviewDetails(companyId, authKey, sortOrder);
    if (response && response.statusCode === 200) {
      // setTotalCount(response.data?.totalCount);
      setReviewDetails(response.data?.reviewDetails);
    } else {
      setReviewDetails([]);
    }
  }, [sortOrder, authKey, companyId]);

  useEffect(() => {
    handleallReview();
    return () => { };
  }, [sortOrder, handleallReview]);

  //get review reason
  const [reasonData, setReasonData] = useState([]);
  const fetchReviewTitle = useCallback(async () => {
    const Query = `authKey=${authKey}&masterDataType=review_reason&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const response = await GetMasterDatasByType(Query);
    if (response && response.statusCode === 200) {
      let data1 = response.data;
      let mappingData = data1.map((e) => {
        return {
          name: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setReasonData(mappingData);
    }
  }, [authKey, companyId]);
  
  useEffect(() => {
    fetchReviewTitle();
  }, [fetchReviewTitle]);

  const handleCreateReviewTitle = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("reason", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "review_reason",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchReviewTitle();
        setUpdateReviewValue("reason", {
          name: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        });
      }
    }
  };

  //Find all
  let successReview = reviewDetails
    ?.filter((e) => e.status === "Approve")
    ?.map((e) => {
      return e;
    });

  let waitingReview = reviewDetails
    ?.filter((e) => e.status !== "Approve")
    ?.map((e) => {
      return e;
    });

  //overall rating
  let overAllRating_str = successReview
    ?.filter((e) => e.overRating)
    ?.map((e) => {
      return e.overRating;
    });

  let overAllRating = overAllRating_str?.map(Number);
  let total = overAllRating?.reduce((acc, c) => acc + c, 0);
  let overAllRating_avg = total / overAllRating?.length;
  //let avg =overAllRating_avg.toFixed(1);
  //let avg =Math.floor(overAllRating_avg);
  let total_length = overAllRating?.length;
  //5-star
  let fiveStar = overAllRating?.filter((x) => x === 5).length;
  let fiveStarPercent = Math?.floor((fiveStar * 100) / total_length);
  //4-star
  let fourStar = overAllRating?.filter((x) => x === 4).length;
  let fourStarPercent = Math?.floor((fourStar * 100) / total_length);
  //3-star
  let threeStar = overAllRating?.filter((x) => x === 3).length;
  let threeStarPercent = Math?.floor((threeStar * 100) / total_length);
  //2-star
  let twoStar = overAllRating?.filter((x) => x === 2).length;
  let twoStarPercent = Math?.floor((twoStar * 100) / total_length);
  //1-star
  let oneStar = overAllRating?.filter((x) => x === 1).length;
  let oneStarPercent = Math?.floor((oneStar * 100) / total_length);

  const locationSearch = useLocation();
  useEffect(() => {
    const search = locationSearch.search;
    const tab = new URLSearchParams(search).get("tab");
    if (tab) setSelectedSubTab(tab);
    return () => { };
  }, [locationSearch]);

  const setValidationValue = (key, value) => {
    setValidation({ ...validation, [key]: value });
  };

  const setUpdateReviewValue = (key, value) => {
    setUpdateReview({ ...updateReview, [key]: value });
    if (validation[key]) delete validation[key];
  };

  const handleUpdateReview = async (e, data, status_check) => {
    e.preventDefault();
    let validate = {};
    validate.answer = NonEmptyValidation(updateReview.answer);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const token = state?.auth?.auth?.authKey;
      let Payload = {
        userProfileId: data.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        answer: updateReview.answer,
        status: status_check,
      };

      const response = await UpdateReviewDetails(Payload, token);
      if (response && response.statusCode === 200) {
        setReviewDetails([]);
        handleallReview();
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(response.message);
      }
    } else {
      console.log("Error while updating review");
    }
  };

  const CheckValidationDisapprove = () => {
    let Status = true,
      validdateData = {};
    if (!updateReview?.reason || !updateReview?.reason?.dataId) {
      validdateData.reason = { status: false, message: "is Required" };
      Status = false;
    }
    if (!updateReview.reasonDetails || updateReview.reasonDetails === "") {
      validdateData.reasonDetails = { status: false, message: "is Required" };
      Status = false;
    }
    setValidation({ ...validation, ...validdateData });
    return Status;
  };

  const handleDisapproveReview = async (e) => {
    e.preventDefault();
    const token = state?.auth?.auth?.authKey;
    let Payload = {
      userProfileId: disapproveProfileId,
      companyId: state?.auth?.auth?.userDetails?.companyId,
      status: updateReview.status,
      reason:
        updateReview?.reason?.dataId ||
        updateReview?.reason?.map((e) => e.dataId.toString()),
      reasonDetails: updateReview.reasonDetails,
    };
    if (CheckValidationDisapprove()) {
      const response = await UpdateReviewDetails(Payload, token);
      if (response && response.statusCode === 200) {
        handleDeleteReview(reviewId);
      } else {
        showToastErrorMsg(response.message);
      }
    }
  };
  //Delete
  const handleDeleteReview = async (id) => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      reviewId: id,
    };
    const response = await DeleteReviewDetails(Payload, token);
    if (response && response.statusCode === 200) {
      setdisapproveModal(false);
      showToastSuccessMsg(response.message);
      handleallReview();
    } else {
      showToastErrorMsg(response.message);
    }
  };

  return (
    <div className="dashboard-bg">
      <NavbarDashboard />
      <div className="main">
        <div className="faq-section  scroll-content  py-0" style={{ backgroundColor: '#fff0' }}>
          <div className="container-fluid px-0">
            {/* <h2 className='d-flex justify-content-center text-green font-weight-bold'>Reviews</h2> */}
            <div>
              <>
                <nav className="flex border-b tab-section border-gray-300 bg-white" style={{ boxShadow: '0px 3px 6px #00000017' }}>
                  {AllSubtabs.map((tab, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          selectedSubTab === tab ? "tab active" : "tab inactive"
                        }
                        onClick={() => {
                          navigation({
                            pathname: "/Reviews",
                            search: `tab=${tab}`,
                          });
                        }}
                      >
                        <li className="tab-header">{tab}</li>
                      </div>
                    );
                  })}
                </nav>
                <div className="py-1">
                  {selectedSubTab === "Waiting for Approval" && (
                    <>
                      {state?.auth?.auth?.userDetails?.status === "Active" ||
                        state?.auth?.auth?.userDetails?.approved === true ? (
                        <div className="container-fluid px-0 mt-2">
                          <>
                            <div className="row">
                              <div className="col-lg-9">
                                {waitingReview?.length===0 ? 
                                
                                (
                                  <div className="modal-body p-3 ">
                                    <div
                                      className="modalHeader"
                                      style={{ backgroundColor: "#ffffff" }}
                                    >
                                      <h6 className="text-center font-weight-bold my-2">
                                        <img
                                          style={{
                                            width: "85px",
                                            textShadow:
                                              "rgb(171 167 167 / 44%) 0px 3px 6px;",
                                          }}
                                          src={Roboimg2}
                                          alt=""
                                        />
                                      </h6>
                                    </div>
                                    <form className="container">
                                      <div className="row d-flex align-items-center justify-content-center  mt-0">
                                        <div className="col-lg-8 pb-3">
                                          <p className=" text-center">
                                            No reviews found.
                                          </p>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                )
                                :waitingReview?.map((data, index) => {
                                  return (
                                    <div
                                      className="faq-content bg-white mt-2"
                                      key={index}
                                    >
                                      <div className="row">
                                        <div className="pr-0 col-lg-2 d-flex justify-content-center">
                                          <img
                                            style={{
                                              width: "120px",
                                              height: "120px",
                                            }}
                                            src={Roboimg}
                                            alt=""
                                          />
                                        </div>
                                        <div className=" col-lg-10">
                                          <div className="faq-body ">
                                            <h6 className="font-weight-bold">
                                              {data?.userProfile?.displayName}
                                            </h6>
                                            <small>
                                              {
                                                data?.userProfile
                                                  ?.primaryLocation?.name
                                              }
                                            </small>
                                            <div className="d-flex align-items-center mb-3">
                                              <div className="rate-area ">
                                                <ReactStars
                                                  count={5}
                                                  value={data?.overRating}
                                                  size={24}
                                                  activeColor="#e75f6b"
                                                  edit={false}
                                                />
                                                {/* <input autoComplete="off" type="radio" id="5-star" name="rating" value="5" /><label for="5-star" title="Amazing">5 stars</label>
                                       <input autoComplete="off" type="radio" id="4-star" name="rating" value="4" /><label for="4-star" title="Good">4 stars</label>
                                       <input autoComplete="off" type="radio" id="3-star" name="rating" value="3" /><label for="3-star" title="Average">3 stars</label>
                                       <input autoComplete="off" type="radio" id="2-star" name="rating" value="2" /><label for="2-star" title="Not Good">2 stars</label>
                                       <input autoComplete="off" type="radio" id="1-star" name="rating" value="1" /><label for="1-star" title="Bad">1 star</label> */}
                                              </div>
                                              <small className="pl-2">
                                                {moment(data?.createdAt).format(
                                                  "MM-DD-YYYY"
                                                )}
                                              </small>
                                              {/* 7/20/2022 */}
                                            </div>
                                            <p className="dis-in-reviews">
                                              {data?.yourReview}
                                            </p>
                                            <h6 className="font-weight-bold">
                                              Pros
                                            </h6>
                                            <p>{data.pros}</p>
                                            <h6 className="font-weight-bold">
                                              Cons
                                            </h6>
                                            <p>{data.cons}</p>
                                            <form
                                              onSubmit={(e) =>
                                                handleUpdateReview(
                                                  e,
                                                  data,
                                                  "none"
                                                )
                                              }
                                            >
                                              <h6 className="font-weight-bold">
                                                Reply to this review
                                              </h6>
                                              <div className="form-group">
                                                <textarea
                                                  className="form-control"
                                                  rows="3"
                                                  aria-invalid={Boolean(
                                                    validation?.answer
                                                      ?.status === false
                                                  )}
                                                  onChange={(e) => {
                                                    setUpdateReviewValue(
                                                      "answer",
                                                      e.target.value
                                                    );
                                                  }}
                                                  onBlur={(e) => {
                                                    setValidationValue(
                                                      "answer",
                                                      NonEmptyValidation(
                                                        e.target.value
                                                      )
                                                    );
                                                  }}
                                                >
                                                  {/* {updateReview.answer || data.answer} */}
                                                </textarea>
                                                <small className="text-danger">
                                                  {validation?.answer?.message
                                                    ? `Answer ${validation?.answer?.message}`
                                                    : ""}
                                                </small>
                                              </div>

                                              <button
                                                type="submit"
                                                className="btn btn-light my-2 my-sm-0 mr-1 login-btn"
                                              >
                                                Reply
                                              </button>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="faq-button d-flex justify-content-between align-items-center">
                                        <button
                                          className="btn btn-light my-2 my-sm-0 mr-1 postjob-btn"
                                          onClick={() => {
                                            setReviewId(data.reviewId);
                                            setDisapproveProfileId(
                                              data.userProfileId
                                            );
                                            setdisapproveModal(true);
                                            setUpdateReview({
                                              ...updateReview,
                                              status: "Disapprove",
                                            });
                                          }}
                                        >
                                          Disapprove
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-light my-2 my-sm-0 mr-1 login-btn"
                                          onClick={(e) => {
                                            handleUpdateReview(
                                              e,
                                              data,
                                              "Approve"
                                            );
                                          }}
                                        >
                                          Approve
                                        </button>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        </div>
                      ) : (
                        <div className="modal-body p-3 ">
                          <div
                            className="modalHeader"
                            style={{ backgroundColor: "#ffffff" }}
                          >
                            <h6 className="text-center font-weight-bold my-2">
                              <img
                                style={{
                                  width: "85px",
                                  textShadow:
                                    "rgb(171 167 167 / 44%) 0px 3px 6px;",
                                }}
                                src={Roboimg2}
                                alt=""
                              />
                            </h6>
                          </div>
                          <form className="container">
                            <div className="row d-flex align-items-center justify-content-center  mt-0">
                              <div className="col-lg-8 pb-3">
                                <p className=" text-center">
                                  Your account is still pending for approval.
                                </p>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </>
                  )}
                  {selectedSubTab === "Review" && (
                    <>
                      {state?.auth?.auth?.userDetails?.status === "Active" ||
                        state?.auth?.auth?.userDetails?.approved === true ? (
                        <div className="container-fluid px-0">
                          <div className="row">
                            <div className="col-lg-9">
                              <div className="faq-heading bg-white mt-2 d-flex justify-content-between align-items-center">
                                <h5 className="font-weight-bold">
                                  <span className="text-green">
                                    {successReview?.length}
                                  </span>{" "}
                                  of{" "}
                                  <span className="text-green">
                                    {reviewDetails?.length}
                                  </span>{" "}
                                  review
                                </h5>
                                <div className="d-flex">
                                  <p className="d-flex align-items-center mb-0 mr-2">
                                    Sort by{" "}
                                  </p>
                                  <div className="form-group mb-0">
                                    <select
                                      className="form-control font-weight-bold text-green"
                                      id="exampleFormControlSelect1"
                                      onChange={(e) =>
                                        setSortOrder(e.target.value)
                                      }
                                    >
                                      <option value="newest">NEWEST</option>
                                      <option value="oldest">OLDEST</option>
                                      <option value="ratingByLowest">
                                        LOWEST RATING
                                      </option>
                                      <option value="ratingByHighest">
                                        HIGHEST RATING
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div >
                                {successReview?.map((data, index) => {
                                  return (
                                    <div className="faq-content  bg-white mt-2">
                                      <div className="row border-faq" key={index}>
                                        <div className="pr-0 col-lg-2 d-flex justify-content-center">
                                          <img
                                            style={{
                                              width: "120px",
                                              height: "120px",
                                            }}
                                            src={Roboimg}
                                            alt=""
                                          />
                                        </div>
                                        <div className=" col-lg-10">
                                          <div className="faq-body">
                                            <h6 className="font-weight-bold">
                                              {data?.userProfile?.displayName}
                                            </h6>
                                            <small>
                                              {
                                                data?.userProfile?.primaryLocation
                                                  ?.name
                                              }
                                            </small>
                                            <div className="d-flex align-items-center mb-3">
                                              <div className="rate-area ">
                                                {/* <input autoComplete="off" type="radio" id="5-star" name="rating" value="5" /><label for="5-star" title="Amazing"></label>
                                      <input autoComplete="off" type="radio" id="4-star" name="rating" value="4" /><label for="4-star" title="Good">4 stars</label>
                                      <input autoComplete="off" type="radio" id="3-star" name="rating" value="3" /><label for="3-star" title="Average">3 stars</label>
                                      <input autoComplete="off" type="radio" id="2-star" name="rating" value="2" /><label for="2-star" title="Not Good">2 stars</label>
                                      <input autoComplete="off" type="radio" id="1-star" name="rating" value="1" /><label for="1-star" title="Bad">1 star</label> */}
                                                <StarRatings
                                                  rating={Number(
                                                    data?.overRating
                                                  )}
                                                  starDimension="20px"
                                                  starSpacing="1px"
                                                  starRatedColor="#e75f6b"
                                                />
                                              </div>
                                              <small className="pl-2">
                                                {moment(data?.createdAt).format(
                                                  "MM-DD-YYYY"
                                                )}
                                              </small>
                                              {/* 7/20/2022*/}
                                            </div>
                                            <p className="dis-in-reviews">
                                              {data?.yourReview}
                                            </p>
                                            <h6 className="font-weight-bold">
                                              Pros
                                            </h6>
                                            <p>{data.pros}</p>
                                            <h6 className="font-weight-bold">
                                              Cons
                                            </h6>
                                            <p>{data.cons}</p>
                                            <div className="d-flex reviews-box-content">
                                              <img
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                }}
                                                src={Roboimg}
                                                alt=""
                                              />
                                              <div className="ml-2">
                                                <h6 className="font-weight-bold">
                                                  {
                                                    data?.companyProfile
                                                      ?.companyName
                                                  }
                                                </h6>
                                                <p>
                                                  <small>
                                                    {
                                                      data?.companyProfile
                                                        ?.headQuarterslocation
                                                    }
                                                  </small>
                                                </p>
                                                <p>{data.answer}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  );
                                })}
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-5">
                              <div className="reviews-heading  bg-white mt-2">
                                <h6 className="font-weight-bold">
                                  Overall rating
                                </h6>
                                <div className="rate-area ">
                                  {/* <ReactStars count={5} value={avg} isHalf={true} size={24} activeColor="#ffd700" edit={false} /> */}
                                  <StarRatings
                                    rating={overAllRating_avg || 0}
                                    starDimension="20px"
                                    starSpacing="1px"
                                    starRatedColor="#e75f6b"
                                  />
                                  {/* <input autoComplete="off" type="radio" id="5-star" name="rating" value="5" /><label for="5-star" title="Amazing">5 stars</label>
                              <input autoComplete="off" type="radio" id="4-star" name="rating" value="4" /><label for="4-star" title="Good">4 stars</label>
                              <input autoComplete="off" type="radio" id="3-star" name="rating" value="3" /><label for="3-star" title="Average">3 stars</label>
                              <input autoComplete="off" type="radio" id="2-star" name="rating" value="2" /><label for="2-star" title="Not Good">2 stars</label>
                              <input autoComplete="off" type="radio" id="1-star" name="rating" value="1" /><label for="1-star" title="Bad">1 star</label> */}
                                </div>
                                <div>
                                  <p style={{ display: "inline-block" }}>
                                    Based on {reviewDetails?.length} reviews
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h5 className="font-weight-bold ml-2">
                                    5{" "}
                                    <span className="material-symbols-rounded filled-icon text-danger align-middle">
                                      star
                                    </span>
                                  </h5>
                                  <div className="progress  w-50">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow="25"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width:
                                          fiveStarPercent === 100
                                            ? "100%"
                                            : fiveStarPercent >= 80
                                              ? "75%"
                                              : fiveStarPercent >= 60
                                                ? "50%"
                                                : fiveStarPercent >= 40
                                                  ? "25%"
                                                  : fiveStarPercent >= 20
                                                    ? "15%"
                                                    : fiveStarPercent >= 10
                                                      ? "10%"
                                                      : fiveStarPercent >= 5
                                                        ? "5%"
                                                        : "0%",
                                        borderRadius: "20px",
                                      }}
                                    ></div>
                                  </div>
                                  <small className="ml-2"> {fiveStar}</small>
                                </div>
                                <div className="d-flex  justify-content-between align-items-center">
                                  <h5 className="font-weight-bold ml-2">
                                    4{" "}
                                    <span className="material-symbols-rounded filled-icon text-danger align-middle">
                                      star
                                    </span>{" "}
                                  </h5>

                                  <div className="progress  w-50">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow="25"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width:
                                          fourStarPercent === 100
                                            ? "100%"
                                            : fourStarPercent >= 80
                                              ? "75%"
                                              : fourStarPercent >= 60
                                                ? "50%"
                                                : fourStarPercent >= 40
                                                  ? "25%"
                                                  : fourStarPercent >= 20
                                                    ? "15%"
                                                    : fourStarPercent >= 10
                                                      ? "10%"
                                                      : fourStarPercent >= 5
                                                        ? "5%"
                                                        : "0%",
                                        borderRadius: "20px",
                                      }}
                                    ></div>
                                  </div>
                                  <small className="ml-2"> {fourStar}</small>
                                </div>
                                <div className="d-flex  justify-content-between align-items-center">
                                  <h5 className="font-weight-bold ml-2">
                                    3{" "}
                                    <span className="material-symbols-rounded filled-icon text-danger align-middle">
                                      star
                                    </span>{" "}
                                  </h5>

                                  <div className="progress  w-50">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow="25"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width:
                                          threeStarPercent === 100
                                            ? "100%"
                                            : threeStarPercent >= 80
                                              ? "75%"
                                              : threeStarPercent >= 60
                                                ? "50%"
                                                : threeStarPercent >= 40
                                                  ? "25%"
                                                  : threeStarPercent >= 20
                                                    ? "15%"
                                                    : threeStarPercent >= 10
                                                      ? "10%"
                                                      : threeStarPercent >= 5
                                                        ? "5%"
                                                        : "0%",
                                        borderRadius: "20px",
                                      }}
                                    ></div>
                                  </div>
                                  <small className="ml-2"> {threeStar}</small>
                                </div>
                                <div className="d-flex  justify-content-between align-items-center">
                                  <h5 className="font-weight-bold ml-2">
                                    2{" "}
                                    <span className="material-symbols-rounded filled-icon text-danger align-middle">
                                      star
                                    </span>{" "}
                                  </h5>

                                  <div className="progress  w-50">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow="25"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width:
                                          twoStarPercent === 100
                                            ? "100%"
                                            : twoStarPercent >= 80
                                              ? "75%"
                                              : twoStarPercent >= 60
                                                ? "50%"
                                                : twoStarPercent >= 40
                                                  ? "25%"
                                                  : twoStarPercent >= 20
                                                    ? "15%"
                                                    : twoStarPercent >= 10
                                                      ? "10%"
                                                      : twoStarPercent >= 5
                                                        ? "5%"
                                                        : "0%",
                                        borderRadius: "20px",
                                      }}
                                    ></div>
                                  </div>
                                  <small className="ml-2"> {twoStar}</small>
                                </div>
                                <div className="d-flex  justify-content-between align-items-center">
                                  <h5 className="font-weight-bold ml-2">
                                    1{" "}
                                    <span className="material-symbols-rounded filled-icon text-danger align-middle">
                                      star
                                    </span>
                                  </h5>

                                  <div className="progress  w-50">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      aria-valuenow="25"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{
                                        width:
                                          oneStarPercent === 100
                                            ? "100%"
                                            : oneStarPercent >= 80
                                              ? "75%"
                                              : oneStarPercent >= 60
                                                ? "50%"
                                                : oneStarPercent >= 40
                                                  ? "25%"
                                                  : oneStarPercent >= 20
                                                    ? "15%"
                                                    : oneStarPercent >= 10
                                                      ? "10%"
                                                      : oneStarPercent >= 5
                                                        ? "5%"
                                                        : "0%",
                                        borderRadius: "20px",
                                      }}
                                    ></div>
                                  </div>
                                  <small className="ml-2"> {oneStar}</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-body p-3 ">
                          <div
                            className="modalHeader"
                            style={{ backgroundColor: "#ffffff" }}
                          >
                            <h6 className="text-center font-weight-bold my-2">
                              <img
                                style={{
                                  width: "85px",
                                  textShadow:
                                    "rgb(171 167 167 / 44%) 0px 3px 6px;",
                                }}
                                src={Roboimg2}
                                alt=""
                              />
                            </h6>
                          </div>
                          <form className="container">
                            <div className="row d-flex align-items-center justify-content-center  mt-0">
                              <div className="col-lg-8 pb-3">
                                <p className=" text-center">
                                  Your account is still pending for approval.
                                </p>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={disapproveModal}
        onClickOutside={() => {
          setdisapproveModal(false);
          setValidation({});
          setUpdateReview({
            ...updateReview,
            ...{ reasonDetails: "", reason: [] },
          });
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Reason of Disapprove
              </h5>
            </div>
            <form
              onSubmit={(e) => {
                handleDisapproveReview(e);
              }}
            >
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                  <div className="col-lg-5">
                    {/* <div className="google-input">
										<input autoComplete="off" type="text" id="template-input" placeholder="Choose your reason" />
										<label htmlFor="template-input">
											Reason
										</label>
									</div> */}
                    <div className="google-input">
                      <label htmlFor="template-input" className="z-index-value">
                        Reason
                      </label>
                      <div className="dropdown-container">
                        <CreatableSelect
                          options={reasonData}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "6px",
                            zIndex: 3,
                            colors: {
                              ...theme.colors,
                              primary25: "#DFF1F1",
                              // primary: "#00ABAD",
                              neutral20:
                                validation?.reason?.status === false
                                  ? "red"
                                  : "#00ABAD", // border color
                              primary:
                                validation?.reason?.status === false
                                  ? "red"
                                  : "#00ABAD", // border color when focus
                            },
                          })}
                          styles={{
                            menu: (provided, state) => ({
                              ...provided,
                              zIndex: "5 !important",
                            }),
                          }}
                          placeholder="Choose Job Title"
                          value={{
                            value: updateReview?.reason?.name,
                            label: updateReview?.reason?.name,
                          }}
                          onChange={(e) => {
                            setUpdateReviewValue("reason", e);
                          }}
                          onCreateOption={(e) => {
                            handleCreateReviewTitle(e);
                          }}
                          isClearable
                        />
                        <small className="text-danger">
                          {validation?.reason?.message
                            ? `Reason ${validation?.reason?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="modalAnswer mt-2">
                      <label for="exampleFormControlTextarea2">Answer</label>
                      {/* <div className="modalDisapprove-details d-flex  justify-content-end ">
                        <button type="button" className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1">
                          Rich Text Editor<span className="material-symbols-rounded modalDisapprove-lockicon mx-1">lock</span>
                        </button>
                      </div> */}
                      <div className="form-group">
                        <textarea
                          className="form-control rounded-0 modalDisapprove-textarea"
                          id="exampleFormControlTextarea2"
                          rows="7"
                          aria-invalid={Boolean(
                            validation?.reasonDetails?.status === false
                          )}
                          onChange={(e) => {
                            setUpdateReviewValue(
                              "reasonDetails",
                              e.target.value
                            );
                          }}
                          onBlur={(e) => {
                            setValidationValue(
                              "reasonDetails",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                        ></textarea>

                        <small className="text-danger">
                          {validation?.reasonDetails?.message
                            ? `Reason details ${validation?.reasonDetails?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0">
                <div>
                  <button
                    className="btn btn-light otp-btn postjob-btn"
                    onClick={() => {
                      setdisapproveModal(false);
                      setValidation({});
                      setUpdateReview({
                        ...updateReview,
                        ...{ reasonDetails: "", reason: [] },
                      });
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                  <button
                    type="submit"
                    className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn"
                  // onClick={() => {
                  //   setDeleteModal(true);
                  // }}
                  >
                    Disapprove
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
