import React, { useEffect, useCallback, useState } from "react";
import NavbarEmployee from "../NavbarEmployee";
import { Link } from "react-router-dom";
import Select from "react-select";
import Roboimg from "../../../images/roboimg.png";
import "react-phone-input-2/lib/material.css";
import CustomModal from "../../CustomModal";
import { useSelector } from "react-redux";
import { CreateSavedJob, GetAllJobPost, VisibilityPost } from "../../Api/Api";
import { toast, ToastContainer } from "react-toastify";
import { GetInitials } from "../../Store/Constants";
import moment from "moment";
import Pagination from './Pagination'

export default function JobsHomeEmployee() {
  const [ShowTable, setShowTable] = useState(true);

  // React state to manage selected options
  const [selectedOptions, setSelectedOptions] = useState();

  // Array of all options
  const optionList = [
    { value: "Sentinel Technologies", label: "Sentinel Technologies" },
    { value: "Quantzi", label: "Quantzi" },
    { value: "Infosys", label: "Infosys" },
    { value: "IBM", label: "IBM" },
    { value: "Zoho", label: "Zoho" },
  ];

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }
  const [ClosingModal, setClosingModal] = useState(false);
  const [editJobModal, seteditJobModal] = useState(false);
  const [editJob2Modal, seteditJob2Modal] = useState(false);

  //state value
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const userProfileId = state?.auth?.auth?.userProfileId;

  // create job post api integration

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [listingJobs, setListingJobs] = useState([]);

  const handleListingJobPost = useCallback(async (value) => {
    if (!authKey || !userProfileId) return;

    const status = value;
    const response = await GetAllJobPost(authKey, userProfileId, status);
    if (response && response.statusCode === 200) {
      setListingJobs(response.data);
    } else {
    }
  }, [authKey, userProfileId]);

  useEffect(() => {
    handleListingJobPost("All");
  }, [handleListingJobPost]);

  // create saved job

  const handleCreateSavedJobs = async (value) => {
    const Payload = {
      jobId: value,
      userProfileId: state?.auth?.auth?.userProfileId,
    };

    const token = state?.auth?.auth?.authKey;
    const response = await CreateSavedJob(Payload, token);
    if (response && response.statusCode === 200) {
      handleListingJobPost("Saved");
      // handleListingJobPost("All");
      showToastSuccessMsg(response.message);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const handleVisiblityJobs = async (value) => {
    const Payload = {
      jobId: value,
      userProfileId: state?.auth?.auth?.userProfileId,
    };

    const token = state?.auth?.auth?.authKey;
    const response = await VisibilityPost(Payload, token);
    if (response && response.statusCode === 200) {
      handleListingJobPost("All");
      showToastSuccessMsg(response.message);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

 const [currentPage, setCurrentPage] = useState(1);
 const [clicksPerPage] = useState(8);

 const IndexOfLastPage = currentPage * clicksPerPage;
 const IndexOfFirstPage = IndexOfLastPage - clicksPerPage;
 const current = listingJobs?.jobDetails?.slice(IndexOfFirstPage, IndexOfLastPage)

 const paginate = pageNumber => setCurrentPage(pageNumber);




  return (
    <div className="dashboard-bg">
      <NavbarEmployee title={"Jobs"} />
      <div className="main">
     
        {/* <div className='Jobssection1 d-flex align-items-center py-1'>
					<div className='container-fluid'>
						<div className='row  align-items-center '>
							<div className='JHE-fixed-width bg-section d-flex justify-content-around'>
								<div className=''>
									<button className="btn btn-light my-2 my-sm-0 mr-1 postjob-btn " >Saved Searches (4)</button>
								</div>
								<div className=''>
									<button className="btn btn-light my-2 my-sm-0 mr-1 postjob-btn" >Saved Jobs (8)</button>
									<button className="btn btn-light my-2 my-sm-0 mr-1 postjob-btn " >Applied (10)</button>
									<button className="btn btn-light my-2 my-sm-0 mr-1 postjob-btn" >In Progress (28)</button>
									<button className="btn btn-light my-2 my-sm-0 mr-1 postjob-btn " >Selected (28)</button>
								</div>
								<div className=''>
									<button className="btn btn-light  my-2 my-sm-0 mr-1 rejected-btn postjob-btn">Rejected (28)</button>
								</div>
							</div>
							<div className='JHE-fixed-width2 my-auto'>
								<div className='d-flex justify-content-center'>
									<div className={`table-view ${ShowTable && "active"} `} onClick={() => {
										setShowTable(true);
									}}>
										<span className="material-symbols-rounded icon-middle">grid_view</span>
									</div>
									<div className={`grid-view ml-2 ${!ShowTable && "active"}`} onClick={() => {
										setShowTable(false);
									}}>
										<span className="material-symbols-rounded icon-middle">view_list</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}
        <div className="JHE-fixed-width d-flex align-items-center py-1">
          <div className="container-fluid">
            <div className=" align-items-center responsive-jobshomemployeee ">
              <div className="has-search2">
                <span className="material-symbols-rounded form-control-feedback2 align-middle pr-2">
                  tune
                </span>
              </div>
              <div className="">
                {/* <button
                  className="btn btn-light my-1 my-sm-0 mr-1 postjob-btn "
                  onClick={() => handleListingJobPost("savedSearch")}
                >
                  Saved Searches ({listingJobs?.savedSearch})
                </button> */}
              </div>
              <div className="d-flex">
                <button
                  className="btn btn-light my-1 my-sm-0 mr-1 postjob-btn"
                  onClick={() => handleListingJobPost("All")}
                >
                  All Jobs ({listingJobs?.alljobs})
                </button>
                <button
                  className="btn btn-light my-1 my-sm-0 mr-1 postjob-btn"
                  onClick={() => handleListingJobPost("Saved")}
                >
                  Saved Jobs ({listingJobs?.savedJobs})
                </button>
                <button
                  className="btn btn-light my-1 my-sm-0 mr-1 postjob-btn "
                  onClick={() => handleListingJobPost("Applied")}
                >
                  Applied ({listingJobs?.Applied})
                </button>
                <button
                  className="btn btn-light my-1 my-sm-0 mr-1 postjob-btn"
                  onClick={() => handleListingJobPost("Inprogress")}
                >
                  In Progress ({listingJobs?.Inprogress})
                </button>
                <button
                  className="btn btn-light my-1 my-sm-0 mr-1 postjob-btn "
                  onClick={() => handleListingJobPost("Selected")}
                >
                  Selected ({listingJobs?.Selected})
                </button>
                <button
                  className="btn btn-light my-1 my-sm-0 mr-1 postjob-btn "
                  onClick={() => handleListingJobPost("Hidden")}
                >
                  Hidden ({listingJobs?.hiddenJobs})
                </button>
              </div>
              <div className="">
                <button
                  className="btn btn-light  my-1 my-sm-0 mr-1 rejected-btn "
                  onClick={() => handleListingJobPost("Rejected")}
                >
                  Rejected ({listingJobs?.Rejected})
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="JHE-fixed-width2 align-items-center ">
          <div
            className={`table-view text-center ${ShowTable && "active"} `}
            onClick={() => {
              setShowTable(true);
            }}
          >
            <span className="material-symbols-rounded icon-middle">
              grid_view
            </span>
          </div>
        </div>
        <div className="JHE-fixed-width3  align-items-center ">
          <div
            className={`grid-view text-center  ${!ShowTable && "active"}`}
            onClick={() => {
              setShowTable(false);
            }}
          >
            <span className="material-symbols-rounded icon-middle">
              view_list
            </span>
          </div>
        </div>
        <div className="Jobssection2 scroll-content ">
          {ShowTable && (
            <div className="container-fluid">
              <h6 className="font-weight-bold my-3">
                {listingJobs?.totalJob} Jobs based on Your Desired Preferences
              </h6>
              <div className="row">
                {current?.length>0 ? (
                  current?.map((data, index) => {
                    return (
                      <div
                        className="col-lg-3 jobs-padding col-md-6"
                        key={index}
                      >
                        <div className="jobs-content">
                          <div className=' scroll-jobs-employee scroll-content-design'>
                            <div className="d-flex">
                              {!data?.companyLogo ? (
                                <h1 className="compworked-img font-weight-bold">
                                  {GetInitials(data?.companyName)}
                                </h1>
                              ) : (
                                <img
                                  className="compworked-img"
                                  src={data?.companyLogo}
                                  alt=""
                                  hidden={!data?.companyLogo}
                                />
                              )}
                              <div className="match d-flex align-items-center mx-2">
                                <small className="color-green">
                                  {data.profileMatch}% Match
                                </small>
                              </div>
                            </div>
                            <span
                              className="material-symbols-rounded jobs-eye-icon"
                              onClick={() => handleVisiblityJobs(data?.jobId)}
                            >
                              visibility_off
                            </span>
                            <span
                              className={
                                data?.saved === true
                                  ? `material-symbols-rounded jobs-edit-icon text-warning`
                                  : `material-symbols-rounded jobs-edit-icon`
                              }
                              onClick={() => {
                                handleCreateSavedJobs(data?.jobId);
                              }}
                            >
                              bookmark
                            </span>
                            <Link
                              className="redirect-link"
                              to={`/JobInfo/${data?.jobId}`}
                            >
                              <h5 className="font-weight-bold cut-text">
                                {data?.jobName}
                              </h5>
                              <p className="mb-1">{data?.companyName}</p>
                              <h6 className="my-2">
                                <span className="material-symbols-rounded filled-icon icon-middle">
                                  location_on
                                </span>
                                {data?.locationId?.toString()}
                              </h6>

                              <table className="py-0">
                                <tbody>
                                  <tr>
                                    <td>Experience </td>
                                    <td>:</td>
                                    <th>
                                      {data?.experienceLevel?.map((e) => (
                                        <p className="mb-0">{e.name} </p>
                                      ))}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td>Job Type</td>
                                    <td>:</td>
                                    <th>
                                      {data?.workPlaceType?.map((e) => (
                                        <p className="mb-0">{e.name} </p>
                                      ))}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td className="text-nowrap">
                                      Employment Type
                                    </td>
                                    <td>:</td>
                                    <th>
                                      {data?.employmentType?.map((e) => (
                                        <p className="mb-0">{e.name} </p>
                                      ))}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td>Salary</td>
                                    <td>:</td>
                                    <th>
                                      ${data.salaryMin}-{data.salaryMax}
                                      {data.salaryRangeByValue}
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="Jobs-details-skills2 mb-2">
                                <p className="mb-2 font-weight-bold">
                                  Primary skills
                                </p>
                                {data?.mustHaveSkill?.map((e) => (
                                  <li>{e.name} </li>
                                ))}
                              </div>

                            </Link>
                          </div>
                          <div className="static-date">
                            <div className="d-flex justify-content-between mt-1">
                              <small>
                                <span>
                                  Posted date :&nbsp;
                                  {moment(data?.createdAt).format(
                                    "MMM DD,yyyy"
                                  )}
                                </span>
                              </small>
                              <small>
                                <span className="text-color-blue">
                                  {data.nearLocation}
                                </span>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="col-12 mt-4">
                    <h6 className="text-center font-weight-bold my-2">
                      <img
                        style={{
                          width: "85px",
                          textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                        }}
                        src={Roboimg}
                        alt=""
                      />
                    </h6>
                    <br />
                    <div>
                      <h6 className="text-center font-weight-bold my-2">
                        There is no job available
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {!ShowTable && (
            <div className="container-fluid">
              <h6 className="font-weight-bold">
                {listingJobs?.totalJob} Jobs based on Your Desired Preferences
              </h6>
              <div className="row">
              {current ? (
                current?.map((data, index) => {
                  return (
                    <div className="col-lg-12" key={index}>
                      <div className="row table-profile mb-2">
                        <Link
                          className="col-lg-4  redirect-link "
                          to={`/JobInfo/${data?.jobId}`} style={{    position: 'relative'}}
                        >
                          <div className="row  align-items-center">
                            <div className="col-lg-4">
                              {data?.companyLogo === "" ? (
                                <h1 className="compworked-img font-weight-bold">
                                  {GetInitials(data?.companyName)}
                                </h1>
                              ) : (
                                <img
                                  className="compworked-img"
                                  src={data?.companyLogo}
                                  alt=""
                                />
                              )}
                            </div>

                            <div className="col-lg-8 pl-2" >
                              <div className="d-flex justify-content-between">
                                <h5 className="font-weight-bold cut-text">
                                  {data.jobName}
                                </h5>
                                <div className="match d-flex align-items-start mt-2 text-nowrap mx-2">
                                  <small className="color-green">
                                    {data.profileMatch}% Match
                                  </small>
                                </div>
                              </div>
                              <h6 className="mb-2">{data?.companyName}</h6>
                              <p className="mb-2 location-font">
                                <span className="material-symbols-rounded filled-icon icon-middle">
                                  location_on
                                </span>
                                {data?.locationId?.toString()}
                              </p>
                              

                            </div>
                          </div>
                          <div className="static-date">
                                <small>
                                  <span>
                                    Posted date :&nbsp;
                                    {moment(data?.createdAt).format(
                                      "MMM DD,yyyy"
                                    )}
                                  </span>
                                </small>
                              </div>
                        </Link>
                        <div className="col-lg-4">
                          <table className="">
                            <tbody>
                              <tr>
                                <td className="w-50">Experience </td>
                                <td>: </td>
                                <th>
                                  {data?.experienceLevel?.map((e) => (
                                    <p className="mb-0"> {e.name} </p>
                                  ))}
                                </th>
                              </tr>
                              <tr>
                                <td>Job Type</td>
                                <td>:</td>
                                <th>
                                  {data?.workPlaceType?.map((e) => (
                                    <p className="mb-0"> {e.name} </p>
                                  ))}
                                </th>
                              </tr>
                              <tr>
                                <td className="text-nowrap">Employment Type</td>
                                <td>:</td>
                                <th>
                                  {data?.employmentType?.map((e) => (
                                    <p className="mb-0"> {e.name} </p>
                                  ))}
                                </th>
                              </tr>
                              <tr>
                                <td>Salary</td>
                                <td>:</td>
                                <th>
                                  {data.salaryMin}-{data.salaryMax}
                                  {data.salaryRangeByValue}
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-lg-3">
                          <div className="Jobs-details-skills2">
                            <p className=" font-weight-bold">
                              Must to have skills
                            </p>
                            {data?.mustHaveSkill?.map((e) => (
                              <li> {e.name} </li>
                            ))}
                          </div>
                        </div>
                        <div className="col-lg-1 d-flex flex-column">
                          <div className="d-flex">
                            <span
                              className="material-symbols-rounded jobs-eye-icon icon-middle"
                              onClick={() => handleVisiblityJobs(data?.jobId)}
                            >
                              visibility_off
                            </span>
                            <span
                              className={
                                data?.saved === true
                                  ? `material-symbols-rounded jobs-edit-icon text-warning`
                                  : `material-symbols-rounded jobs-edit-icon`
                              }
                              onClick={() => {
                                handleCreateSavedJobs(data?.jobId);
                                handleListingJobPost("Saved");
                              }}
                            >
                              bookmark
                            </span>
                          </div>
                          <small className="mt-auto">
                            <span className="text-color-blue">
                              {data.nearLocation}
                            </span>
                          </small>
                        </div>
                      </div>
                    </div>
                  );
                }) ) : (
                  <div className="col-12 mt-4">
                    <h6 className="text-center font-weight-bold my-2">
                      <img
                        style={{
                          width: "85px",
                          textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                        }}
                        src={Roboimg}
                        alt=""
                      />
                    </h6>
                    <br />
                    <div>
                      <h6 className="text-center font-weight-bold my-2">
                        This is the right place to Create your Company's Jobs
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
           <Pagination
        clicksPerPage={clicksPerPage}
        totalPage={listingJobs?.jobDetails?.length}
        paginate={paginate}
      />
        </div>
        
      </div>
      
      <CustomModal
        open={ClosingModal}
        onClickOutside={() => {
          setClosingModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Reason of Closing
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-5">
                  <div className="google-input">
                    <label htmlFor="template-input" className="z-index-value">
                      Reason
                    </label>
                    <div className="dropdown-container">
                      <Select
                        options={optionList}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "6px",
                          zIndex: 3,
                          colors: {
                            ...theme.colors,
                            primary25: "#DFF1F1",
                            primary: "#00ABAD",
                          },
                        })}
                        styles={{
                          menu: (provided, state) => ({
                            ...provided,
                            zIndex: "5 !important",
                          }),
                        }}
                        placeholder="Choose your reason"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isMulti
                      />
                    </div>
                  </div>
                  <div className="modalAnswer mt-2">
                    <label for="exampleFormControlTextarea2">
                      Reason Details
                    </label>
                    <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button
                        type="button"
                        className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                      >
                        Rich Text Editor
                        <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                          lock
                        </span>
                      </button>
                    </div>

                    <div className="form-group">
                      <textarea
                        className="form-control rounded-0 modalDisapprove-textarea"
                        id="exampleFormControlTextarea2"
                        rows="7"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setClosingModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={editJobModal}
        onClickOutside={() => {
          seteditJobModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">Edit Job</h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content">
                <div className="col-lg-12">
                  <form>
                    <div className="form-container py-1">
                      <div className="row ">
                        <div className="col-lg-6 px-5 pb-4 border-right">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Job Title
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose job title"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Employment Type
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose employment type"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Work Place Type
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose job type"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Location
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose your job location"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Schedule
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Enter the salary range low to high"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 px-5 pb-4">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Experience level
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose experience level"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>

                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Must Have Skills
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose must have skills"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Nice To Have Skills
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose nice to have skills"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Headquarters Location
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Enter your Headquarters Location"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    seteditJobModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn"
                  onClick={() => {
                    seteditJob2Modal(true);
                  }}
                >
                  Continue
                </button>
                <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn">
                  Publish
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={editJob2Modal}
        onClickOutside={() => {
          seteditJob2Modal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">Post Job</h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-10">
                  <form>
                    <div className="form-container pt-4 py-1">
                      <div className="row ">
                        <div className="col-lg-6 px-5 pb-4 border-right">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Highest Qualification
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose your qualification"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                          <div className="google-input mt-5">
                            <input
                              autoComplete="off"
                              type="text"
                              id="template-input"
                              placeholder="Enter no of position"
                            />
                            <label htmlFor="template-input">
                              No of position
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 px-5 pb-4">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Employer Benefits
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1",
                                    primary: "#00ABAD",
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose employer benefits"
                                value={selectedOptions}
                                onChange={handleSelect}
                                isMulti
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modalAnswer col-lg-10 mt-2 pt-5 px-5 ml-2">
                  <label htmlFor="template-input" className="z-index-value">
                    Job Description
                  </label>
                  <div className="modalDisapprove-details d-flex  justify-content-end ">
                    <button
                      type="button"
                      className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                    >
                      Rich Text Editor
                      <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                        lock
                      </span>
                    </button>
                  </div>

                  <div className="form-group">
                    <textarea
                      className="form-control rounded-0 modalDisapprove-textarea"
                      id="exampleFormControlTextarea2"
                      rows="7"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    seteditJob2Modal(false);
                  }}
                >
                  Cancel
                </button>
                <button className="btn btn-light otp-btn postjob-btn mx-4">
                  Back
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn">
                  Disapprove
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <ToastContainer />
    </div>
  );
}
