import React from "react";

export default function Footer() {
  return (
    <div>
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-xs-6 col-md-3 col-lg-3">
              <h6>Job Seekers</h6>
              <ul className="footer-links">
                <li>
                  <a href="#top">Search Jobs</a>
                </li>
                <li>
                  <a href="#top">Job Seeker Reviews</a>
                </li>
                <li>
                  <a href="#top">Create Free Account</a>
                </li>
                <li>
                  <a href="#top">Job Seeker Support</a>
                </li>
              </ul>
            </div>

            <div className="col-xs-6 col-md-2 col-lg-2">
              <h6>Businesses</h6>
              <ul className="footer-links">
                <li>
                  <a href="#top">How it Works</a>
                </li>
                <li>
                  <a href="#top">Plans</a>
                </li>
                <li>
                  <a href="#top">Search Resumes</a>
                </li>
                <li>
                  <a href="#top">New Account Help</a>
                </li>
                <li>
                  <a href="#top">Employer Support</a>
                </li>
              </ul>
            </div>

            <div className="col-xs-6 col-md-2 col-lg-2">
              <h6>Company</h6>
              <ul className="footer-links">
                <li>
                  <a href="#top">About Us</a>
                </li>
                <li>
                  <a href="#top">Blog</a>
                </li>
                <li>
                  <a href="#top">Forum / Q&A</a>
                </li>
                <li>
                  <a href="#top">Knowledge Repo</a>
                </li>
                <li>
                  <a href="h">Contact</a>
                </li>
              </ul>
            </div>
            <div className="col-xs-6 col-md-2 col-lg-2">
              <h6>Call</h6>
              <ul className="footer-links">
                <li>
                  <a href="#top">+91 95974 64606</a>
                </li>
              </ul>
              <h6>Email</h6>
              <ul className="footer-links">
                <li>
                  <a href="#top">sales@eadjobs.us</a>
                </li>
              </ul>
            </div>
            <div className="col-xs-6 col-md-3 col-lg-3">
              <ul className="social-icons">
                <li>
                  <a className="facebook" href="#top">
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                </li>
                {/* <li><a className="twitter" href="#top"><i className="fa fa-twitter"></i></a></li> */}
                <li>
                  <a className="dribbble" href="#top">
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a className="linkedin" href="#top">
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <hr />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <p className="copyright-text text-center">
                Your privacy is our priority. Learn more: Do Not Sell My
                Personal Information{" "}
              </p>
              <p className="copyright-text text-center">
                COVID-19 Resources | Privacy Policy | California Privacy Notice
                | Terms of Use | Job Posting Rules | Attribution Notices |
                Corporate Responsibility
              </p>
              <p className="copyright-text text-center">
                © 2022 All Rights Reserved Worldwide{" "}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
