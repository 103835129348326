import React, { useState, useEffect } from "react";
import NavbarAdmin from "../NavbarAdmin";
import MUIDataTable from "mui-datatables";
import { GetAllJobUsersListByType } from "../../components/Api/Api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Admins = () => {
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const [adminsList, setAdminsList] = useState([]);
  const { authKey } = state?.auth?.auth || {};

  useEffect(() => {
    if (!!authKey) {
      const FetchAllAdminsList = async () => {
        const Query = `actorType=Admin&authKey=${authKey}`;
        const Response = await GetAllJobUsersListByType(Query);
        if (Response && Response.statusCode === 200) {
          setAdminsList(Response.data);
        }
      };
      FetchAllAdminsList();
    }
  
    return () => {};
  }, [authKey]);

  const columns = [
    {
      name: "userProfileId",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "emailId",
      label: "Email Id",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <div
                className="btn"
                onClick={() =>
                  navigate(`/Admin/Admins/EditAdmins/${tableMeta.rowData[0]}`)
                }
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    { name: "status", label: "Status" },
  ];
  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
  };
  return (
    <div className="dashboard-bg">
      <NavbarAdmin />
      <div className="main">
        <div
          className="chat-section scroll-content bg-white"
          style={{ boxShadow: "0px 3px 6px #00000017" }}
        >
          <div className="my-3 text-right">
            <button
              class="btn btn-light my-2 my-sm-0 mr-2 login-btn"
              onClick={() => navigate("/Admin/Admins/CreateAdmins")}
            >
              New Admin
            </button>
          </div>
          <MUIDataTable
            title={"Admins List"}
            data={adminsList}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};
export default Admins;
