import React from "react";
import PropTypes from "prop-types";
import { UpdateSkillDetails } from "../../Api/Api";
import { useSelector } from "react-redux";

const insideStyle = {
  //   backgroundColor: "#cccccc",
  //   opacity: 0.5,
};

const DropTarget = (props) => {
  const state = useSelector((state) => state);
  const [isOver, setIsOver] = React.useState(false);
  const dragOver = (ev) => {
    ev.preventDefault();
  };

  const drop = (ev) => {
    const droppedItem = ev.dataTransfer.getData("drag-item");

    if (droppedItem) {
      UpdateSkillDrop(droppedItem);
    }
    setIsOver(false);
  };
  const UpdateSkillDrop = async (id) => {
    const Authkey = state?.auth?.auth?.authKey;
    const Payload = {
      userSkillId: id,
      skillType: props?.skillType,
      proficiency: props?.proficiency,
    };
    const response = await UpdateSkillDetails(Payload, Authkey);
    if (response && response.statusCode === 200) {
      props.showToastSuccess(response.message);
      props.fetchSkillsData();
    } else {
      props.showToastError(
        response.message || response.error.response.data.message
      );
    }
  };
  const dragEnter = () => {
    setIsOver(true);
  };

  const dragLeave = () => setIsOver(false);

  return (
    <div
      onDragOver={dragOver}
      onDrop={drop}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      style={{ width: "100%", height: "100%", ...(isOver ? insideStyle : {}) }}
    >
      {props.children}
    </div>
  );
};

DropTarget.propTypes = {
  onItemDropped: PropTypes.func.isRequired,
  dropEffect: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default DropTarget;
