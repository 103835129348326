import React from "react";
import Navbar from "../navbar";
import Roboimg from "../../images/roboimg.png";
export default function Home() {
  return (
    <div>
      <Navbar />
      <div className="Banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="section1 my-5 p-4">
                <img className="mt-3" src={Roboimg} alt="" />
                <h1>
                  Welcome! I’m Quantum,
                  <br /> your personal recruiter.
                </h1>
                <p>
                  With a personal recruiter, you’ll get
                  <br /> matched to roles that are right for you.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 m-auto">
              <div className="section2 my-5 ">
                <h5 className="text-left font-weight-bold my-2 mx-4">
                  What brings you here today?
                </h5>
                <p className="text-center ">
                  I'm searching for a Job at this moment
                </p>
                <p className="text-center ">
                  I'm recruiting candidates right now
                </p>
                <p className="text-center ">I'm conducting mock interviews</p>
                <p className="text-center ">
                  I'm mentoring job seekers to be future ready
                </p>
                <p className="text-center ">I'm building better resumes</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
