import React, { useState } from "react";
import EADLogo from "../images/eadlogo2.png";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CustomModal from "./CustomModal";
import Roboimg from "../images/roboimg.png";
import Roboimg2 from "../images/roboimg-outerborder.png";
export default function Navbar() {
  const [deletePostModal, setdeletePostModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);
  // React state to manage selected options
  const [selectedOptions, setSelectedOptions] = useState();

  //Navigation
  const naviGation = useNavigate();

  // Array of all options
  const optionList = [
    { value: "Sentinel Technologies", label: "Sentinel Technologies" },
    { value: "Quantzi", label: "Quantzi" },
    { value: "Infosys", label: "Infosys" },
    { value: "IBM", label: "IBM" },
    { value: "Zoho", label: "Zoho" },
  ];

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }
  const [searchTray, setSearchTray] = useState(false);
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light py-3">
        <a className="navbar-brand" href="/">
          <img className="logo-img" src={EADLogo} alt="" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto ml-2">
            <li className="nav-item mx-2">
              <NavLink
                className="nav-link"
                to={"/RegisterEmployee"}
                activeclassname="active"
              >
                Job seeker
              </NavLink>
            </li>
            <li className="nav-item mx-2">
              <NavLink
                className="nav-link"
                to={"/RegisterEmployer"}
                activeclassname="active"
              >
                Employer
              </NavLink>
            </li>
            <li className="nav-item mx-2">
              <NavLink
                className="nav-link"
                to={"/ComingSoon"}
                activeclassname="active"
              >
                Companies
              </NavLink>
            </li>
            <li className="nav-item mx-2">
              <NavLink
                className="nav-link"
                to={"/ComingSoon"}
                activeclassname="active"
              >
                Forum
              </NavLink>
            </li>
            <li className="nav-item mx-2">
              <NavLink
                className="nav-link"
                to={"/BlogsHome"}
                activeclassname="active"
              >
                Blog
              </NavLink>
            </li>
          </ul>
          <div className="form-inline my-2 my-lg-0">
            <div className="form-group has-search mr-2">
              <span className="fa fa-search form-control-feedback"></span>
              <input
                autoComplete="off"
                type="text"
                className="form-control"
                placeholder="Search Jobs"
              />
              <button
                onClick={() => setSearchTray(!searchTray)}
                className="form-control-feedback2 btn btn-light"
              >
                <span className="tune-form-control material-symbols-rounded ">
                  tune
                </span>
              </button>
              {searchTray ? (
                <div id="searchTray">
                  <div className="">
                    <form className="my-4 mx-2">
                      <div className="form-container">
                        <div className="google-input">
                          <label
                            htmlFor="template-input"
                            className="z-index-value"
                          >
                            Job Title
                          </label>
                          <div className="dropdown-container">
                            <Select
                              options={optionList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: "6px",
                                colors: {
                                  ...theme.colors,
                                  primary25: "#DFF1F1",
                                  primary: "#00ABAD",
                                },
                              })}
                              styles={{
                                menu: (provided, state) => ({
                                  ...provided,
                                  zIndex: "5 !important",
                                }),
                              }}
                              placeholder="Choose your job role"
                              value={selectedOptions}
                              onChange={handleSelect}
                            />
                          </div>
                        </div>
                        <div className="google-input">
                          <label
                            htmlFor="template-input"
                            className="z-index-value"
                          >
                            Location
                          </label>
                          <div className="dropdown-container">
                            <Select
                              options={optionList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: "6px",
                                colors: {
                                  ...theme.colors,
                                  primary25: "#DFF1F1",
                                  primary: "#00ABAD",
                                },
                              })}
                              styles={{
                                menu: (provided, state) => ({
                                  ...provided,
                                  zIndex: "5 !important",
                                }),
                              }}
                              placeholder="Choose your job location"
                              value={selectedOptions}
                              onChange={handleSelect}
                            />
                          </div>
                        </div>
                        <div className="google-input">
                          <label
                            htmlFor="template-input"
                            className="z-index-value"
                          >
                            Employment Type
                          </label>
                          <div className="dropdown-container">
                            <Select
                              options={optionList}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: "6px",
                                colors: {
                                  ...theme.colors,
                                  primary25: "#DFF1F1",
                                  primary: "#00ABAD",
                                },
                              })}
                              styles={{
                                menu: (provided, state) => ({
                                  ...provided,
                                  zIndex: "5 !important",
                                }),
                              }}
                              placeholder="Choose your job location"
                              value={selectedOptions}
                              onChange={handleSelect}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="my-4 row mx-auto">
                        <button className="btn btn-light my-2 mx-1 my-sm-0 login-btn">
                          Search
                        </button>
                        <button className="btn btn-light my-2 mx-1 my-sm-0 postjob-btn">
                          Post a Job
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : null}
            </div>
            <button
              className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn"
              onClick={() => naviGation("/Login")}
            >
              Post a Job
            </button>
            {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn" onClick={() => { setFailureModal(true); }}>Post a Job</button> */}
            <button
              className="btn btn-light my-2 my-sm-0 login-btn"
              onClick={() => naviGation("/Login")}
            >
              Login
            </button>
          </div>
        </div>
      </nav>
      <CustomModal
        open={deletePostModal}
        onClickOutside={() => {
          setdeletePostModal(false);
        }}
      >
        <div className="modal-content w-75  m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <h3 className="font-weight-bold text-center">
                    Are You Sure?
                  </h3>
                  <p className=" text-center">
                    Do you really want to delete this Record? this process
                    cannot be undone.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center border-0 mb-3">
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                  onClick={() => {
                    setdeletePostModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ padding: "8px 24px" }}
                  className="btn btn-danger my-2 my-sm-0 mr-2 "
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={failureModal}
        onClickOutside={() => {
          setFailureModal(false);
        }}
      >
        <div className="modal-content w-50  m-auto">
          <div className="modal-body p-0 ">
            <div className="modalHeader bg-danger succ-failure">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                onClick={() => {
                  setFailureModal(false);
                }}
              >
                close
              </span>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  src={Roboimg}
                  alt=""
                />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 py-4">
                  <h3 className="font-weight-bold text-center">Sorry!</h3>
                  <p className="font-weight-bold text-center">
                    Your Details has not been Submited.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
