/* eslint-disable array-callback-return */
import React, { useEffect, useMemo, useCallback, useState, useRef } from "react";
import NavbarEmployee from "./NavbarEmployee";
import OtpInput from "react-otp-input";
import Select from "react-select";
import Roboimg from "../../images/roboimg.png";
import Roboimg2 from "../../images/roboimg-outerborder.png";
import "react-phone-input-2/lib/material.css";
import CustomModal from "../CustomModal";
import { GetInitials, PasswordRegex } from "../Store/Constants";
import moment from "moment";
import {
  ChangePassword,
  CreateCoverLetter,
  CreateEmployeeSkills,
  CreateKnownLanguage,
  CreateMasterDataByType,
  DeleteCoverLetter,
  DeleteKnownLanguage,
  DeleteResume,
  GetAllCoverLetter,
  GetAllResume,
  GetEmploymentDetailsPost,
  GetMasterDatasByType,
  getParticularLanguage,
  GetPersonalDetails,
  ReSendOtp,
  ResumePost,
  UpdateCoverLetter,
  UpdateLanguage,
  UpdatePersonDetails,
  UploadDocument,
  VerifyOtp,
} from "../Api/Api";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { updateSpecificUserDetailsKeyinState } from "../Store/auth/action";
import EducationalDetails from "./Profile/EducationalDetails";
import Skills from "./Profile/Skills";
import EmploymentDetails from "./Profile/EmploymentDetails";
import {
  DropdownArray,
  EmailValidation,
  NonEmptyObjectValidation,
  NonEmptyValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../Store/validate";
import Certification from "./Profile/Certification";
import PhoneInput from "react-phone-input-2";
import CreatableSelect from "react-select/creatable";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import CropEasy from "../Crop/CropEasy";

export default function ProfileEmployee() {
  //state value
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const userProfileId = state?.auth?.auth?.userProfileId;
  const userDetails = state?.auth?.auth?.userDetails;

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [emailVerifyModal, setEmailVerifyModal] = useState(false);
  const [AddCoverLetterModal, setAddCoverLetterModal] = useState(false);
  const [EditCoverLetterModal, setEditCoverLetterModal] = useState(false);
  const [deleteCoverModal, setDeleteCoverModal] = useState(false);
  const [deleteCoverId, setDeleteCoverId] = useState("");
  const [profileDetails, setProfileDetails] = useState({});
  const [profileDetailsValidation, setProfileDetailsValidation] = useState({});
  const [AddLanguageModal, setAddLanguageModal] = useState(false);
  const [EditLanguageModal, setEditLanguageModal] = useState(false);
  const [phoneNumberEdit, setPhoneNumberEdit] = useState(true);
  const [selectedTab, setSelectedTab] = useState("Personal Details");
  const [languageData, setLanguageData] = useState({});
  const [languageValidation, setLanguageValidation] = useState({});
  const [getResume, setGetResume] = useState([]);

  const Alltabs = [
    "Personal Details",
    "Employment Details",
    "Education Details",
    "Certifications",
    "Resume & Cover Letter",
    "Skills",
    "Change Password",
  ];
  const [selectedSubTab, setSelectedSubTab] = useState("Resumes");
  const AllSubtabs = ["Resumes", "Cover Letters"];
  const [language, setLanguage] = useState([]);
  const [location, setLocation] = useState([]);
  const ProfileRef = useRef();
  const MobileNumberRef = useRef();
  const ProfileTabRef = useRef();
  const EmailRef = useRef();
  const [emailEdit, setEmailEdit] = useState(true);
  const [languageDeleteModalStatus, setLanguageDeleteModalStatus] =
    useState(false);
  const [deleteLanguageData, setDeleteLanguageData] = useState("");
  const [currentRole, setCurrentRole] = useState("");
  const [getEmploymentDetails, setGetEmploymentDetails] = useState([]);
  const setBasicDetailsValidationValue = (key, value) => {
    setProfileDetailsValidation({ ...profileDetailsValidation, [key]: value });
  };

  //get city dropdown list
  const [cityData, setCity] = useState([]);

  const fetchData2 = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const Query = `authKey=${authKey}&masterDataType=city&status=Active&restrictionLevel=0&userId=${userProfileId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setCity(mappingData);
    }
    return getEmployeeSkills;
  }, [authKey, userProfileId]);

  const fetchAllEmploymentDetailsData = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const getEmployeeSkills = await GetEmploymentDetailsPost(authKey, userProfileId);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      setGetEmploymentDetails(getEmployeeSkills.data);
    } else {
      setGetEmploymentDetails([]);
    }
  }, [authKey, userProfileId]);

  const handleDeleteSelectedLanguage = async () => {
    try {
      let data = deleteLanguageData;
      data.language = profileDetails?.language.filter(
        (item) => item?.knownLanguageId !== data.knownLanguageId
      );
      const Authkey = state?.auth?.auth?.authKey;
      const Payload = {
        knownLanguageId: data.knownLanguageId,
      };
      const response = await DeleteKnownLanguage(Payload, Authkey);
      if (response && response.statusCode === 200) {
        handleProfileDetails("language", data.language);
        setLanguageDeleteModalStatus(false);
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } catch (e) {
      console.log(e, "Error while deleting the language");
    }
  };

  //Personal Details
  const [deleteGalleryModal, setDeleteGalleryModal] = useState(false);
  //Skill Api Integration
  //getting all skills

  const [skills, setSkills] = useState([]);
  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };
  // Success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const fetchData = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const Query = `authKey=${authKey}&masterDataType=skill&status=Active&restrictionLevel=0&userId=${userProfileId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          masterDataId: e.masterDataId,
        };
      });
      setSkills(mappingData);
    }
  }, [authKey, userProfileId]);

  const [particularLanguage, setParticularLanguage] = useState([])
  const [particularLanguageValidation, setParticularLanguageValidation] = useState([])
  
  const setParticularLanguageValue = (key, value) => {
    setParticularLanguage({
      ...particularLanguage,
      [key]: value,
    });
    if (particularLanguageValidation[key])
      delete particularLanguageValidation[key];
  };

  const fetchParticularLanguage = useCallback(async (value) => {
    const knownLanguageId = value;
    if (!authKey || !knownLanguageId) return;

    const getEmployeeSkills = await getParticularLanguage(authKey, knownLanguageId);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      setParticularLanguage(getEmployeeSkills.data);
    } else {
      setParticularLanguage([]);
    }
  }, [authKey]);

  const handleProfileData = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const response = await GetPersonalDetails(authKey, userProfileId);
    if (response && response.statusCode === 200) {
      setProfileDetails(response.data);
    }
  }, [authKey, userProfileId]);

  const fetchLanguageData = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const Query = `authKey=${authKey}&masterDataType=language&status=Active&restrictionLevel=0&userId=${userProfileId}`;
    const response = await GetMasterDatasByType(Query);
    if (response && response.statusCode === 200) {
      setLanguage(response.data);
    } else {
      setLanguage([]);
    }
  }, [authKey, userProfileId]);

  const fetchLocationData = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const Query = `authKey=${authKey}&masterDataType=location&status=Active&restrictionLevel=0&userId=${userProfileId}`;
    const response = await GetMasterDatasByType(Query);
    if (response && response.statusCode === 200) {
      setLocation(response.data);
    } else {
      setLocation([]);
    }
  }, [authKey, userProfileId]);

  const fetchAllCoverLetterData = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const getEmployeeSkills = await GetAllCoverLetter(authKey, userProfileId);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      setGetCoverLetter(getEmployeeSkills.data);
    } else {
      setGetCoverLetter([]);
    }
  }, [authKey, userProfileId]);

  const fetchAllResumeData = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const getEmployeeSkills = await GetAllResume(authKey, userProfileId);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      setGetResume(getEmployeeSkills.data);
    } else {
      setGetResume([]);
    }
  }, [authKey, userProfileId]);

  useEffect(() => {
    setProfileDetails(userDetails);
    fetchData();
    fetchData2();
    fetchLanguageData();
    fetchLocationData();
    handleProfileData();
    fetchAllCoverLetterData();
    fetchAllEmploymentDetailsData();
    fetchAllResumeData();
  }, [userDetails, fetchData, fetchData2, fetchLanguageData, fetchLocationData, handleProfileData, fetchAllCoverLetterData, fetchAllEmploymentDetailsData, fetchAllResumeData]);


  const [code, setOtp] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  const [validation, setValidation] = useState({});

  //Otp Submit
  const handleChange = (code) => {
    setOtp(code);
    setValidation({});
  };

  const Resent = async () => {
    setOtp(null);
    const Payload = {
      emailId: state.auth.auth.emailId,
      verificationType: "Account Creation",
    };

    const response = await ReSendOtp(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setMinutes(0);
      setSeconds(59);
      //setsuccessModal(true);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setFailureModal(true);
    }
  };

  // final submit
  const Submit = async (data) => {
    data.preventDefault();
    let validate = {};
    validate.code = NonEmptyValidation(code);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        emailId: state.auth.auth.emailId,
        verificationType: "Account Creation",
        otp: parseInt(code),
      };
      const response = await VerifyOtp(Payload);
      if (response && response.statusCode === 200) {
        navigation("/ProfileEmployee");
        setEmailVerifyModal(false);
        dispatch(updateSpecificUserDetailsKeyinState(response.data));
        showToastSuccessMsg(
          response.message || response.error.response.data.message
        );
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        // setFailureModal(true);
      }
    } else {
      console.log("Error while verify otp");
    }
  };

  //Employment details post api integration

  const handleCity = async (string) => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      masterDataType: "city",
      name: string,
      userId: state?.auth?.auth?.userProfileId,
      companyId: state?.auth?.auth?.userDetails?.companyId,
      restrictionLevel: 0,
    };
    const response = await CreateEmployeeSkills(Payload, token);
    if (response && response.statusCode === 200) {
      fetchData2();
    }
    return response;
  };

  // Get All Genders List

  const [gender, setGender] = useState([]);

  const fetchGenderDetails = useCallback(async () => {
    if (!authKey || !userProfileId) return;

    const Query = `authKey=${authKey}&masterDataType=gender&status=Active&restrictionLevel=0&userId=${userProfileId}`;
    const getGenderDetails = await GetMasterDatasByType(Query);
    if (getGenderDetails && getGenderDetails.statusCode === 200) {
      let data1 = getGenderDetails.data;

      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setGender(mappingData);
    }
  }, [authKey, userProfileId]);

  useEffect(() => {
    fetchGenderDetails();
  }, [fetchGenderDetails]);

  const onChangeLanguageDetails = (key, value) => {
    setLanguageData({ ...languageData, [key]: value });
    if (languageValidation[key]) delete languageValidation[key];
    return null;
  };
  const setLanguageValidationValue = (key, value) => {
    setLanguageValidation({ ...languageValidation, [key]: value });
  };

  const handleCreateLanguage = async (string,type) => {
    const valid = StringValidation(string);
    if (valid.status) {
      const Payload = {
        masterDataType: "language",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        restrictionLevel: 0,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await CreateMasterDataByType(Payload, token);
      if (response && response.statusCode === 200) {
        fetchLanguageData();
        if (type === "create") {
        onChangeLanguageDetails("languageId", {
          value: response.data.name,
            label: response.data.name,
            masterDataId: response.data.masterDataId,
        });
        }else{
          onChangeLanguageDetails("language", {
            value: response.data.name,
            label: response.data.name,
            masterDataId: response.data.masterDataId,
          });
          }
      }
    } else {
      setLanguageValidationValue("languageId", valid);
    }
  };
  const handleCreateLocation = async (key, string) => {
    const valid = StringValidation(string);
    if (valid.status) {
      const Payload = {
        masterDataType: "location",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        restrictionLevel: 0,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await CreateMasterDataByType(Payload, token);
      if (response && response.statusCode === 200) {
        fetchLocationData();
        if (key === "primaryLocation") {
          handleProfileDetails(key, response.data);
        } else {
          if (profileDetails[key] && profileDetails[key].length) {
            let data = profileDetails[key];
            data.push(response.data);
            handleProfileDetails(key, data);
          } else {
            handleProfileDetails(key, [response.data]);
          }
        }
      }
    } else {
      setBasicDetailsValidationValue(key, valid);
    }
  };
  const CheckLanguageValidation = () => {
    let values = {};

    values.languageId = NonEmptyObjectValidation(languageData.languageId);
    values.proficiency = NonEmptyObjectValidation(languageData.proficiency);

    if (languageData?.speak || languageData?.read || language?.write) {
      values.speak = { status: true, message: "" };
    } else {
      values.speak = { status: false, message: "is required" };
    }
    setLanguageValidation(values);
    return values;
  };

  const CreateKnownLanguageData = async () => {
    const validationValues = CheckLanguageValidation();
    if (Object.values(validationValues).every((x) => x.status === true)) {
      const Payload = {
        userProfileId: state?.auth?.auth?.userProfileId,
        languageId: languageData.languageId.masterDataId,
        speak: languageData.speak ? languageData.speak : false,
        read: languageData.read ? languageData.read : false,
        write: languageData.write ? languageData.write : false,
        proficiency: languageData.proficiency,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await CreateKnownLanguage(Payload, token);
      if (response && response.statusCode === 200) {
        setAddLanguageModal(false);
        setLanguageData({});
        fetchLanguageData();
        showToastSuccessMsg(response.message);
        if (profileDetails.language && profileDetails.language.length) {
          let data = profileDetails.language;
          data.push(response.data);
          handleProfileDetails("language", data);
        } else {
          handleProfileDetails("language", [response.data]);
        }
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };
  // certification post api

  //COVER LETTER

  //create post coverletter

  const [coverLetter, setCoverLetter] = useState({});

  const setCoverLetterValue = (key, value) => {
    setCoverLetter({ ...coverLetter, [key]: value });
    if (coverLetterValidation[key]) delete coverLetterValidation[key];
  };

  const [coverLetterValidation, setCoverLetterValidation] = useState({});

  const setCoverLetterValidationValue = (key, value) => {
    setCoverLetterValidation({ ...coverLetterValidation, [key]: value });
  };

  const CoverLetterValidation = () => {
    let validate = {};
    validate.title = StringValidation(coverLetter.title);
    validate.description = NonEmptyObjectValidation(coverLetter?.description);
    setCoverLetterValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      handleCoverLetter();
    } else {
      console.log("Error while creating cover letter");
    }
  };

  const handleCoverLetter = async () => {
    try {
      const Payload = {
        userProfileId: state?.auth?.auth?.userProfileId,
        description: coverLetter.description,
        title: coverLetter.title,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await CreateCoverLetter(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        //setSuccessModal(true);
        setAddCoverLetterModal(false);
        fetchAllCoverLetterData();
        setCoverLetter({});
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } catch (e) {
      showToastErrorMsg("Something Went Wrong");
    }
  };

  //get all cover letter

  const [getCoverLetter, setGetCoverLetter] = useState([]);
  const handleCreateSkills = async (string) => {
    const Payload = {
      masterDataType: "skill",
      name: string,
      userId: state?.auth?.auth?.userProfileId,
      restrictionLevel: 0,
    };
    const token = state?.auth?.auth?.authKey;
    const response = await CreateEmployeeSkills(Payload, token);
    if (response && response.statusCode === 200) {
      fetchData();
    }
    return response;
  };
  //delete cover letter
  const handleDeleteCoverLetter = async (value) => {
    try {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        userCoverLetterId: value,
      };
      const response = await DeleteCoverLetter(Payload, token);
      if (response && response.statusCode === 200) {
        setDeleteCoverModal(false);
        showToastSuccessMsg(response.message);
        //setSuccessModal(true);
        fetchAllCoverLetterData();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } catch (e) {
      showToastErrorMsg("Something Went Wrong");
    }
  };
  const [updateCoverLetter, setUpdateCoverLetter] = useState({});

  const setUpdateCoverLetterValue = (key, value) => {
    setUpdateCoverLetter({ ...updateCoverLetter, [key]: value });
    if (updateCoverLetterValidation[key])
      delete updateCoverLetterValidation[key];
  };

  const [updateCoverLetterValidation, setUpdateCoverLetterValidation] =
    useState({});

  const setUpdateCoverLetterValidationValue = (key, value) => {
    setUpdateCoverLetterValidation({
      ...updateCoverLetterValidation,
      [key]: value,
    });
  };

  const UpdateCoverLetterValidation = () => {
    let validate = {};
    validate.title = StringValidation(updateCoverLetter.title);
    validate.description = NonEmptyObjectValidation(
      updateCoverLetter?.description
    );
    setUpdateCoverLetterValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      handleUpdateCoverLetter();
    } else {
      console.log("Error while updating cover letter");
    }
  };

  const handleUpdateCoverLetter = async (value) => {
    try {
      const Payload = {
        userCoverLetterId: updateCoverLetter.userCoverLetterId,
        description: updateCoverLetter?.description,
        title: updateCoverLetter?.title,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await UpdateCoverLetter(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        // setSuccessModal(true);
        setEditCoverLetterModal(false);
        fetchAllCoverLetterData();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } catch (e) {
      showToastErrorMsg("Something Went Wrong");
      //setFailureModal(true);
    }
  };

  const locationSearch = useLocation();

  useEffect(() => {
    const search = locationSearch.search;
    const tab = new URLSearchParams(search).get("tab");
    const subtab = new URLSearchParams(search).get("subtab");

    setChangePasswordValidationData({});
    setLanguageValidation({});

    if (tab && !subtab) {
      setSelectedTab(tab);
    } else if (tab) {
      setSelectedTab(tab);
      setSelectedSubTab(subtab);
    }
    return () => {};
  }, [locationSearch]);

  //CHANGE PASSWORD
  const onSubmitChangePassword = async (e) => {
    try {
      if (CheckChangePasswordValidation()) {
        const token = state?.auth?.auth?.authKey;
        const Payload = {
          emailId: state.auth.auth.emailId,
          password: changePasswordData.password,
          oldPassword: changePasswordData.oldPassword,
        };
        const response = await ChangePassword(Payload, token);
        if (response && response.statusCode === 200) {
          e.target.reset();
          setChangePasswordData({});
          showToastSuccessMsg(response.message);
          // setSuccessModal(true);
        } else {
          showToastErrorMsg(
            response.message || response.error.response.data.message
          );
        }
      }
    } catch (e) {
      showToastErrorMsg("Something Went Wrong");
    }
  };

  // Profile Details
  const handleProfileDetails = (key, value) => {
    setProfileDetails({ ...profileDetails, [key]: value });
    if (profileDetailsValidation[key]) delete profileDetailsValidation[key];
    return null;
  };

  const basicValidationKeys = [
    {
      key: "fullName",
      rule: function (val) {
        return StringValidation(val);
      },
    },
    {
      key: "displayName",
      rule: function (val) {
        return StringValidation(val);
      },
    },
    {
      key: "emailId",
      rule: function (val) {
        return EmailValidation(val);
      },
    },
    // {
    //   key: "genderId",
    //   rule: function (val) {
    //     return NonEmptyObjectValidation(val);
    //   },
    // },
    {
      key: "mobileNumber",
      rule: function (val) {
        return PhoneNumberValidation(val);
      },
    },
    {
      key: "primaryLocation",
      rule: function (val) {
        return NonEmptyObjectValidation(val);
      },
    },
    {
      key: "secondaryLocation",
      rule: function (val) {
        return DropdownArray(val);
      },
    },
    {
      key: "language",
      rule: function (val) {
        return DropdownArray(val);
      },
    },
  ];

  const CheckingProfileDetails = () => {
    let values = {};

    basicValidationKeys.forEach(async (element, index) => {
      values = {
        ...values,
        [element.key]: element.rule(profileDetails[element.key]),
      };
      if (index + 1 === basicValidationKeys.length) {
        setProfileDetailsValidation(values);
      }
    });
    return values;
  };

  const onSubmitProfileDetails = async () => {
    let Payload;
    const validationValues = CheckingProfileDetails();
    if (Object.values(validationValues).every((x) => x.status === true)) {
      Payload = {
        userProfileId: state?.auth?.auth?.userProfileId,
        fullName: profileDetails.fullName,
        displayName: profileDetails.displayName,
        emailId: profileDetails.emailId,
        mobileNumber: profileDetails.mobileNumber,
        genderId: profileDetails.genderId.dataId,
        language: profileDetails.language.map((e) => e.knownLanguageId),
        primaryLocation: profileDetails.primaryLocation.masterDataId,
        secondaryLocation: profileDetails.secondaryLocation.map(
          (e) => e.masterDataId
        ),
      };
      const token = state?.auth?.auth?.authKey;
      const Response = await UpdatePersonDetails(Payload, token);
      if (Response && Response.statusCode === 200) {
        showToastSuccessMsg(Response.message);
        dispatch(updateSpecificUserDetailsKeyinState(Response.data));
      } else {
        showToastErrorMsg(
          Response.message || Response.error.response.data.message
        );
      }
    }
  };

  const onSubmitPhoneNumber = async () => {
    if (
      profileDetails.mobileNumber !== state.auth.auth.userDetails.mobileNumber
    ) {
      let valid = PhoneNumberValidation(profileDetails.mobileNumber);
      if (valid.status) {
        const Payload = {
          userProfileId: state?.auth?.auth?.userProfileId,
          mobileNumber: profileDetails.mobileNumber,
        };
        const token = state?.auth?.auth?.authKey;
        const Response = await UpdatePersonDetails(Payload, token);
        if (Response && Response.statusCode === 200) {
          showToastSuccessMsg(Response.message);
          dispatch(updateSpecificUserDetailsKeyinState(Response.data));
          setPhoneNumberEdit(!phoneNumberEdit);
        } else {
          showToastErrorMsg(
            Response.message || Response.error.response.data.message
          );
          //setFailureModal(true);
        }
      } else {
        setBasicDetailsValidationValue("mobileNumber", valid);
      }
    } else {
      setPhoneNumberEdit(!phoneNumberEdit);
    }
  };

  const onSubmitEmailId = async () => {
    if (profileDetails.emailId !== state.auth.auth.userDetails.emailId) {
      let valid = EmailValidation(profileDetails.emailId);
      if (valid.status) {
        const Payload = {
          userProfileId: state?.auth?.auth?.userProfileId,
          emailId: profileDetails.emailId,
        };
        const token = state?.auth?.auth?.authKey;
        const Response = await UpdatePersonDetails(Payload, token);
        if (Response && Response.statusCode === 200) {
          showToastSuccessMsg(Response.message);
          dispatch(updateSpecificUserDetailsKeyinState(Response.data));
          setEmailEdit(!emailEdit);
        } else {
          showToastErrorMsg(
            Response.message || Response.error.response.data.message
          );
          //setFailureModal(true);
        }
      } else {
        setBasicDetailsValidationValue("emailId", valid);
      }
    } else {
      setEmailEdit(!emailEdit);
    }
  };

  // const [pdfValidate, setPdfValidate] = useState({})

  // resume api integration
  const handleResume = async (name) => {
    let filename = name.split("/");
    // let extension = filename[4].split(".");
    // let validate = {};
    // validate.fileName = PDFValidation(extension[1]);
    // // setPdfValidate(validate)
    // if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        userProfileId: state?.auth?.auth?.userProfileId,
        fileUrl: name,
        fileName: filename[4],
      };
      const token = state?.auth?.auth?.authKey;
      const response = await ResumePost(Payload, token);

      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        fetchAllResumeData();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    // } else {
    //   showToastErrorMsg("Accepts only pdf file");
    // }
  };

  //resume upload
  const uploadResume = async (e) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("fileName", e.target.files[0].name);
    formData.append("path", "resume/");
    let response = await UploadDocument(formData);
    if (response && response.statusCode === 200) {
      // showToastSuccessMsg(response.message);
      handleResume(response.data);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const [resumeId, setResumeId] = useState("");

  //delete resume
  const handleDeleteResume = async (value) => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      resumeId: value,
    };

    const response = await DeleteResume(Payload, token);
    if (response && response.statusCode === 200) {
      setDeleteGalleryModal(false);
      showToastSuccessMsg(response.message);
      fetchAllResumeData();
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const [changePasswordData, setChangePasswordData] = useState({});

  const [changePasswordValidationData, setChangePasswordValidationData] =
    useState({});
  const CheckChangePasswordValidation = () => {
    let status = true,
      validation = {};

    if (
      !changePasswordData.oldPassword ||
      !changePasswordData?.oldPassword?.trim()?.length
    ) {
      status = false;
      validation.oldPassword = "Current Password is required";
    }

    if (
      !changePasswordData.password ||
      !changePasswordData?.password?.trim()?.length
    ) {
      status = false;
      validation.password = "New Password is required";
    } else if (!PasswordRegex.test(changePasswordData.password)) {
      status = false;
      validation.password = "Enter your Password with correct combinations";
    }
    if (
      !changePasswordData.confirmPassword ||
      !changePasswordData?.confirmPassword?.trim()?.length
    ) {
      status = false;
      validation.confirmPassword = "Confirm Password is required";
    } else if (
      changePasswordData.confirmPassword &&
      changePasswordData?.password &&
      changePasswordData.confirmPassword !== changePasswordData.password
    ) {
      status = false;
      validation.confirmPassword =
        "Confirm password is mismatch with New password";
    }
    setChangePasswordValidationData(validation);
    return status;
  };

  const onChangeChangePassword = (key, value) => {
    setChangePasswordData({ ...changePasswordData, [key]: value });
    if (changePasswordValidationData[key])
      delete changePasswordValidationData[key];
  };

  const handleKeyDownUrl = (event) => {
    if (event.key === "Enter") {
      onSubmitPhoneNumber();
    }
  };

  const handleKeyDownEmail = (event) => {
    if (event.key === "Enter") {
      onSubmitEmailId();
    }
  };

  const [fileName, setFileName] = useState("");
  const [field, setField] = useState("");
  const [photoURL, setPhotoURL] = useState(profileDetails?.profileImage);
  const [openCrop, setOpenCrop] = useState(false);
  const [setFile] = useState(null);


  //upload profile pic
  const uploadProfilePic = async (e,value) => {
    const file = e.target.files[0];
    if (file) {
      setField(value)
      setFile(file);
      setPhotoURL(URL.createObjectURL(file));
      setFileName(e.target.files[0].name);
      setOpenCrop(true);
    }
    // let formData = new FormData();
    // formData.append("file", e.target.files[0]);
    // formData.append("fileName", e.target.files[0].name);
    // formData.append("path", "resume/");
    // let response = await UploadDocument(formData);
    // if (response && response.statusCode === 200) {
    //   UpdateImage(response.data);
    // } else {
    //   showToastErrorMsg(
    //     response.message || response.error.response.data.message
    //   );
    // }
  };

  // const UpdateImage = async (val) => {
  //   const token = state?.auth?.auth?.authKey;
  //   const Payload = {
  //     userProfileId: state?.auth?.auth?.userProfileId,
  //     profileImage: val,
  //   };
  //   const response = await UpdatePersonDetails(Payload, token);
  //   if (response && response.statusCode === 200) {
  //     dispatch(updateSpecificUserDetailsKeyinState(response.data));
  //     handleProfileData();
  //     showToastSuccessMsg(response.message);
  //   } else {
  //     showToastErrorMsg(
  //       response.message || response.error.response.data.message
  //     );
  //   }
  // };
  const handleRemoveProfile = async () => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      userProfileId: state?.auth?.auth?.userProfileId,
      profileImage: "",
    };
    const response = await UpdatePersonDetails(Payload, token);
    if (response && response.statusCode === 200) {
      dispatch(updateSpecificUserDetailsKeyinState(response.data));
      handleProfileData();

    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const NeededKeysForPercentage = useMemo(() => [
    { key: "emailId", label: "Email Id" },
    { key: "genderId", label: "Gender" },
    { key: "fullName", label: "Full Name" },
    { key: "displayName", label: "Display Name" },
    { key: "mobileNumber", label: "Mobile Number" },
    { key: "profileImage", label: "Profile Image" },
    { key: "language", label: "Language" },
    { key: "primaryLocation", label: "Primary Location" },
    { key: "secondaryLocation", label: "Secondary Location" },
  ], []);

  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    if (state.auth.auth.userDetails) {
      const percent = NeededKeysForPercentage.filter((item) => {
        if (Array.isArray(state.auth.auth.userDetails[item.key])) {
          return Boolean(state.auth.auth.userDetails[item.key].length);
        } else {
          return !!state.auth.auth.userDetails[item.key];
        }
      });

      setPercentage(
        Math.round((percent.length / NeededKeysForPercentage.length) * 100)
      );
    }

    return () => {};
  }, [state.auth?.auth?.userDetails, NeededKeysForPercentage]);

  useEffect(() => {
    const currentlyWorking = getEmploymentDetails.filter(
      (item) => item.currentlyWorking === true
    );
    if (currentlyWorking.length) {
      setCurrentRole(currentlyWorking[0].jobTitle?.name);
    } else {
      const sortedArray = getEmploymentDetails.sort(
        (a, b) =>
          Date.parse(new Date(a.workingTo)) - Date.parse(new Date(b.workingTo))
      );
      if (sortedArray.length) {
        setCurrentRole(sortedArray[0].jobTitle?.name);
      }
    }

    return () => {};
  }, [getEmploymentDetails]);

  const uploadCompanyUrl = async (url,value) => {
    const token = state?.auth?.auth?.authKey;
    let Payload;

    if(value==="profileImage"){
    Payload = {
      userProfileId: state?.auth?.auth?.userProfileId,
      profileImage: url,
    };
    const response = await UpdatePersonDetails(Payload, token);
    if (response && response.statusCode === 200) {
      dispatch(updateSpecificUserDetailsKeyinState(response.data));
      handleProfileData();
      showToastSuccessMsg(response.message);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
    }
  };

  const CheckUpdateLanguageValidation = () => {
    let values = {};

    values.language = NonEmptyObjectValidation(particularLanguage.language);
    values.proficiency = NonEmptyObjectValidation(particularLanguage.proficiency);

    if (particularLanguage?.speak || particularLanguage?.read || particularLanguage?.write) {
      values.speak = { status: true, message: "" };
    } else {
      values.speak = { status: false, message: "is required" };
    }
    setParticularLanguageValidation(values);
    return values;
  };

  const UpdateKnownLanguageData = async (value) => {
    const validationValues = CheckUpdateLanguageValidation();
    if (Object.values(validationValues).every((x) => x.status === true)) {
      const Payload = {
        knownLanguageId: value,
        languageId: particularLanguage?.language.masterDataId,
        speak: particularLanguage.speak ? particularLanguage.speak : false,
        read: particularLanguage.read ? particularLanguage.read : false,
        write: particularLanguage.write ? particularLanguage.write : false,
        proficiency: particularLanguage.proficiency,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await UpdateLanguage(Payload, token);
      if (response && response.statusCode === 200) {
        setEditLanguageModal(false);
        setParticularLanguage({});
        handleProfileData();
        showToastSuccessMsg(response.message);
        if (particularLanguage && profileDetails.language.length) {
          let data = profileDetails.language;
          data.push(response.data);
          handleProfileDetails("language", data);
        } else {
          handleProfileDetails("language", [response.data]);
        }
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  return (
    <div className="dashboard-bg">
      <NavbarEmployee title={"Profile"} />
      {!openCrop ? (

      <div className="main">
        <div className="profilesection1 d-flex align-items-center py-1">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-md-12 d-flex justify-content-between">
                <div className="profilesection1-content">
                  <div className="position-relative m-auto d-flex align-items-center justify-content-center">
                    <div className="progress-profile ">
                      <CircularProgressbarWithChildren
                        value={percentage}
                        styles={{
                          position: "absolute",
                          width: "50%",
                          height: "100%",
                          marginTop: "-50%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="title timer"
                          data-from="0"
                          data-to={percentage}
                          data-speed="1800"
                        >
                          {!profileDetails?.profileImage
                            ? GetInitials(profileDetails?.fullName)
                            : null}
                          <img
                            className="PP-upload-img"
                            src={profileDetails?.profileImage}
                            alt=""
                            hidden={!profileDetails?.profileImage}
                          />
                        </span>
                      </CircularProgressbarWithChildren>
                    </div>
                    {!profileDetails?.profileImage ? (
                      <span
                        className="material-symbols-rounded photo-icon-css2 "
                        onClick={() => ProfileRef.current.click()}
                      >
                        add_a_photo
                        <input
                          autoComplete="off"
                          type="file"
                          name="myfile"
                          ref={ProfileRef}
                          hidden
                          onChange={(e) => {
                            uploadProfilePic(e,"profileImage");
                          }}
                        />
                      </span>
                    ) : (
                      <span
                        className="material-symbols-rounded text-danger photo-icon-css2 "
                        onClick={() => handleRemoveProfile()}
                      >
                        cancel
                      </span>
                    )}
                  </div>
                  <div className="profile-name-content mx-2 my-auto ">
                    <h6 className="font-weight-bold">
                      {state?.auth?.auth?.userDetails?.fullName}
                    </h6>
                    <p className="mb-0">{currentRole}</p>
                  </div>
                </div>
                <div className="res-percentage-content profile-name-content">
                  <h3 className="font-weight-bold text-danger">40%</h3>
                  <p className="mb-0">Profile Completed</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 my-auto">
                <div className="profilesection1-content res-psc custom-border ">
                  <div className="profile-name-content mx-2">
                    <ul className="mb-0 pl-0">
                      <li className="font-weight-bold mb-2">
                        <span className="material-symbols-rounded  icon-middle">
                          call
                        </span>

                        <input
                        maxLength={11}
                          value={profileDetails?.mobileNumber}
                          onChange={(e) => {
                            if (!phoneNumberEdit) {
                              handleProfileDetails(
                                "mobileNumber",
                                e.target.value
                              );
                            }
                          }}
                          onBlur={() => {
                            if (
                              typeof profileDetails?.mobileNumber !==
                              "undefined"
                            ) {
                              setBasicDetailsValidationValue(
                                "mobileNumber",
                                PhoneNumberValidation(
                                  profileDetails?.mobileNumber
                                )
                              );
                            } else {
                              return true;
                            }
                          }}
                          ref={MobileNumberRef}
                          onKeyDown={handleKeyDownUrl}
                          className={`EditableTextBox ${
                            !phoneNumberEdit ? "active" : ""
                          }`}
                          readOnly={phoneNumberEdit}
                        />
                        <span
                          className="material-icons edit-icon"
                          onClick={() => {
                            setPhoneNumberEdit(!phoneNumberEdit);
                            MobileNumberRef.current.focus();
                          }}
                        >
                          edit
                        </span>
                      </li>
                      <small className="text-danger">
                        {profileDetailsValidation?.mobileNumber?.message
                          ? `Phone number ${profileDetailsValidation?.mobileNumber?.message}`
                          : ""}
                      </small>

                      <li className="font-weight-bold">
                        <span className="material-symbols-rounded  icon-middle">
                          mail
                        </span>
                        <input
                          value={profileDetails?.emailId}
                          onChange={(e) => {
                            if (!emailEdit) {
                              handleProfileDetails("emailId", e.target.value);
                            }
                          }}
                          onBlur={() => {
                            setEmailEdit(!emailEdit);
                          }}
                          ref={EmailRef}
                          onKeyDown={handleKeyDownEmail}
                          className={`EditableTextBox ${
                            !emailEdit ? "active" : ""
                          }`}
                          readOnly={emailEdit}
                        />
                        <span
                          className="material-icons edit-icon"
                          onClick={() => {
                            setEmailEdit(!emailEdit);
                            EmailRef.current.focus();
                          }}
                        >
                          edit
                        </span>
                      </li>
                      {profileDetailsValidation?.emailId?.status === false ? (
                        <small className="text-danger">
                          Email Id {profileDetailsValidation?.emailId?.message}
                        </small>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6  my-auto">
                <div className="profilesection1-content res-psc custom-border2 ">
                  <div className="profile-name-content mx-2">
                    <ul className="mb-0 pl-0">
                      <li className="font-weight-bold">
                        <div className="pr-2">
                          <h6 className="font-weight-bold">
                            Location :&nbsp;
                            <span className="font-weight-bold">
                              {profileDetails?.primaryLocation
                                ? profileDetails.primaryLocation.name
                                : "Not Selected"}
                            </span>
                          </h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 my-auto">
                <div className="profilesection-content res-psc mx-3">
                  <div className="d-flex  justify-content-between">
                    <h6 className="font-weight-bold">Profile Completed</h6>
                    <h6
                      className="font-weight-bold progress-color"
                      style={{
                        color:
                          percentage <= 60
                            ? "#e75f6b"
                            : percentage <= 80
                            ? "orange"
                            : "green",
                      }}
                    >
                      {percentage}%
                    </h6>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{
                        width: `${percentage}%`,
                        backgroundColor:
                          percentage <= 60
                            ? "#e75f6b"
                            : percentage <= 80
                            ? "orange"
                            : "green",
                        borderRadius: "20px",
                      }}
                      aria-valuenow={percentage}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  {percentage !== 100 ? (
                    <div>
                      Fill &nbsp;
                      <span className="text-capitalize">
                        {NeededKeysForPercentage.filter((item) => {
                          if (Array.isArray(profileDetails[item.key])) {
                            if (!profileDetails[item.key].length) {
                              return item.label;
                            }
                          } else {
                            if (!profileDetails[item.key]) {
                              return item.label;
                            }
                          }
                        })
                          .map((o) => o.label)
                          .toString()}
                      </span>
                      &nbsp; to make 100%
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="profilesection2 py-1">
          <div className="">
            <>
              <nav className="flex border-b tab-section border-gray-300">
                {Alltabs.map((tab, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        selectedTab === tab ? "tab active" : "tab inactive"
                      }
                      onClick={() => {
                        if (tab === "Resume & Cover Letter") {
                          navigation({
                            pathname: "/ProfileEmployee",
                            search: `tab=Resume %26 Cover Letter&subtab=Resumes`,
                          });
                        } else {
                          navigation({
                            pathname: "/ProfileEmployee",
                            search: `tab=${tab}`,
                          });
                        }
                      }}
                    >
                      <li className="tab-header">{tab}</li>
                    </div>
                  );
                })}
              </nav>
              
              <div className="py-1">
                {selectedTab === "Personal Details" && (
                  <div className="scroll-content">
                    <div className="container">
                      <div className="row d-flex align-items-center justify-content-center ">
                        <div className="col-lg-10">
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              onSubmitProfileDetails();
                            }}
                          >
                            <div className="form-container py-1">
                              <div className="row justify-content-center">
                                <div className="col-lg-5 col-md-6  pb-4 border-right">
                                  <div className="google-input">
                                    <input
                                      autoComplete="off"
                                      onChange={(e) => {
                                        handleProfileDetails(
                                          "fullName",
                                          e.target.value
                                        );
                                      }}
                                      value={profileDetails.fullName || ""}
                                      type="text"
                                      id="fullName"
                                      placeholder="Enter your full name"
                                      aria-invalid={
                                        profileDetailsValidation?.fullName
                                          ?.status === false
                                      }
                                      onBlur={(e) => {
                                        setBasicDetailsValidationValue(
                                          "fullName",
                                          StringValidation(e.target.value)
                                        );
                                      }}
                                    />
                                    <label htmlFor="template-input">
                                      Full Name
                                    </label>
                                  </div>
                                  {profileDetailsValidation?.fullName
                                    ?.status === false ? (
                                    <small className="text-danger">
                                      Full Name
                                      {
                                        profileDetailsValidation?.fullName
                                          ?.message
                                      }
                                    </small>
                                  ) : null}
                                  <div className="google-input">
                                    <input
                                      autoComplete="off"
                                      type="text"
                                      id="displayName"
                                      placeholder="Enter your Display name"
                                      onChange={(e) => {
                                        handleProfileDetails(
                                          "displayName",
                                          e.target.value
                                        );
                                      }}
                                      value={profileDetails.displayName || ""}
                                      aria-invalid={
                                        profileDetailsValidation?.displayName
                                          ?.status === false
                                      }
                                      onBlur={(e) => {
                                        setBasicDetailsValidationValue(
                                          "displayName",
                                          StringValidation(e.target.value)
                                        );
                                      }}
                                    />
                                    <label htmlFor="template-input">
                                      Display Name
                                    </label>
                                  </div>
                                  {profileDetailsValidation?.displayName
                                    ?.status === false ? (
                                    <small className="text-danger">
                                      Display Name
                                      {
                                        profileDetailsValidation?.displayName
                                          ?.message
                                      }
                                    </small>
                                  ) : null}
                                  <div className="google-input">
                                    <input
                                      autoComplete="off"
                                      type="text"
                                      id="emailId"
                                      placeholder="Enter your email"
                                      onChange={(e) => {
                                        handleProfileDetails(
                                          "emailId",
                                          e.target.value
                                        );
                                      }}
                                      value={profileDetails.emailId || ""}
                                      aria-invalid={
                                        profileDetailsValidation?.emailId
                                          ?.status === false
                                      }
                                      onBlur={(e) => {
                                        setBasicDetailsValidationValue(
                                          "emailId",
                                          EmailValidation(e.target.value)
                                        );
                                      }}
                                    />
                                    {profileDetails?.isEmailIdVerified ===
                                    false ? (
                                      <span
                                        className="text-white bg-danger rounded edit-icon-input"
                                        onClick={() => {
                                          setEmailVerifyModal(true);
                                          Resent();
                                        }}
                                      >
                                        Verify
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    <label htmlFor="template-input">
                                      Email Id
                                    </label>
                                  </div>
                                  {profileDetailsValidation?.emailId?.status ===
                                  false ? (
                                    <small className="text-danger">
                                      Email Id
                                      {
                                        profileDetailsValidation?.emailId
                                          ?.message
                                      }
                                    </small>
                                  ) : null}

                                  <div className="google-input">
                                    <input
                                      autoComplete="off"
                                      type="text"
                                      id="template-input"
                                      onClick={() => {
                                        setAddLanguageModal(true);
                                      }}
                                      readOnly
                                      placeholder="Enter known languages"
                                    />
                                    <label htmlFor="template-input">
                                      Languages
                                    </label>
                                  </div>

                                  {profileDetailsValidation?.language
                                    ?.status === false ? (
                                    <small className="text-danger">
                                      Language
                                      {
                                        profileDetailsValidation?.language
                                          ?.message
                                      }
                                    </small>
                                  ) : null}
                                </div>
                                <div className="col-lg-5 col-md-6 pb-4">
                                  <div className="google-input">
                                    <label
                                      htmlFor="template-input"
                                      className="z-index-value"
                                    >
                                      Gender
                                    </label>
                                    <div className="dropdown-container">
                                      <Select
                                        options={gender}
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: "6px",
                                          zIndex: 3,
                                          // colors: {
                                          //   ...theme.colors,
                                          //   primary25: "#DFF1F1",
                                          //   neutral20:
                                          //     profileDetailsValidation?.genderId
                                          //       ?.status === false
                                          //       ? "red"
                                          //       : "#00ABAD", // border color
                                          //   primary:
                                          //     profileDetailsValidation?.genderId
                                          //       ?.status === false
                                          //       ? "red"
                                          //       : "#00ABAD", // border color when focus
                                          // },
                                        })}
                                        styles={{
                                          menu: (provided, state) => ({
                                            ...provided,
                                            zIndex: "5 !important",
                                          }),
                                        }}
                                        placeholder="Choose your gender"
                                        onChange={(e) => {
                                          handleProfileDetails("genderId", e);
                                        }}
                                        value={{
                                          value:
                                            profileDetails?.genderId?.value ||
                                            profileDetails?.genderId?.name,
                                          label:
                                            profileDetails?.genderId?.label ||
                                            profileDetails?.genderId?.name,
                                        }}
                                      />
                                    </div>
                                  </div>

                                  {/* {profileDetailsValidation?.genderId
                                    ?.status === false ? (
                                    <small className="text-danger">
                                      Gender
                                      {
                                        profileDetailsValidation?.genderId
                                          ?.message
                                      }
                                    </small>
                                  ) : null} */}
                                  <PhoneInput
                                    inputStyle={{
                                      width: "100%",
                                      height: "45px",
                                      onFocus: "red",
                                    }}
                                    onlyCountries={["us"]}
                                    // country={"us"}
                                    // countryCodeEditable={true}
                                    value={profileDetails?.mobileNumber?.toString()}
                                    onChange={(e) => {
                                      handleProfileDetails("mobileNumber", e);
                                    }}
                                    specialLabel="Phone Number"
                                    masks={{ us: ".........." }}
                                    onBlur={() => {
                                      if (
                                        typeof profileDetails?.mobileNumber !==
                                        "undefined"
                                      ) {
                                        setBasicDetailsValidationValue(
                                          "mobileNumber",
                                          PhoneNumberValidation(
                                            profileDetails?.mobileNumber
                                          )
                                        );
                                      } else {
                                        return true;
                                      }
                                    }}
                                  />
                                  <small className="text-danger">
                                    {profileDetailsValidation?.mobileNumber
                                      ?.message
                                      ? `Phone number ${profileDetailsValidation?.mobileNumber?.message}`
                                      : ""}
                                  </small>

                                  <div className="google-input">
                                    <label
                                      htmlFor="template-input"
                                      className="z-index-value"
                                    >
                                      Primary Location
                                    </label>
                                    <div className="dropdown-container">
                                      <CreatableSelect
                                        options={location}
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: "6px",
                                          zIndex: 3,
                                          colors: {
                                            ...theme.colors,
                                            primary25: "#DFF1F1",
                                            primary: "#00ABAD",
                                          },
                                        })}
                                        styles={{
                                          menu: (provided, state) => ({
                                            ...provided,
                                            zIndex: "5 !important",
                                          }),
                                        }}
                                        getOptionLabel={(option) =>
                                          option.name || option.label
                                        }
                                        getOptionValue={(option) =>
                                          option.masterDataId || option.dataId
                                        }
                                        placeholder="Choose your primary location"
                                        value={profileDetails?.primaryLocation}
                                        onCreateOption={(e) => {
                                          handleCreateLocation(
                                            "primaryLocation",
                                            e
                                          );
                                        }}
                                        onChange={(e) => {
                                          handleProfileDetails(
                                            "primaryLocation",
                                            e
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {profileDetailsValidation?.primaryLocation
                                    ?.status === false ? (
                                    <small className="text-danger">
                                      Primary Location
                                      {
                                        profileDetailsValidation
                                          ?.primaryLocation?.message
                                      }
                                    </small>
                                  ) : null}
                                  <div className="google-input">
                                    <label
                                      htmlFor="template-input"
                                      className="z-index-value"
                                    >
                                      Secondary Location
                                    </label>
                                    <div className="dropdown-container">
                                      <CreatableSelect
                                        isMulti
                                        options={location}
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: "6px",
                                          colors: {
                                            ...theme.colors,
                                            primary25: "#DFF1F1",
                                            primary: "#00ABAD",
                                          },
                                        })}
                                        styles={{
                                          menu: (provided, state) => ({
                                            ...provided,
                                            zIndex: "5 !important",
                                          }),
                                        }}
                                        getOptionLabel={(option) =>
                                          option.name || option.label
                                        }
                                        getOptionValue={(option) =>
                                          option.masterDataId || option.dataId
                                        }
                                        placeholder="Choose your secondary location"
                                        onChange={(e) => {
                                          handleProfileDetails(
                                            "secondaryLocation",
                                            e
                                          );
                                        }}
                                        value={
                                          profileDetails?.secondaryLocation
                                        }
                                        onCreateOption={(e) => {
                                          handleCreateLocation(
                                            "secondaryLocation",
                                            e
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  {profileDetailsValidation?.secondaryLocation
                                    ?.status === false ? (
                                    <small className="text-danger">
                                      Secondary Location
                                      {
                                        profileDetailsValidation
                                          ?.secondaryLocation?.message
                                      }
                                    </small>
                                  ) : null}
                                </div>

                                <div className="col-lg-12 text-center">
                                  <div className="selected-lang my-2">
                                    <table className="tablelanguage tableFixHead">
                                      <tr className="tabletr" style={{fontWeight: 'bolder',fontSize:"16.5px",fontFamily: "Atkinson"}}>
                                        <td>Language</td>
                                        <td>Basic</td>
                                        <td>Intermediate</td>
                                        <td>Expert</td>
                                        <td>Action</td>
                                      </tr>
                                      {profileDetails?.language &&
                                        profileDetails?.language?.length &&
                                        profileDetails?.language.map(
                                          (language, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>{language?.languageName}</td>
                                                <td>
                                                 
                                                  {language?.proficiency ===
                                                  "Basic" ? (
                                                    <span >
                                                      {language?.speak ? (
                                                        <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                          Speak
                                                        </small>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {language?.read ? (
                                                        <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                          Read
                                                        </small>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {language?.write ? (
                                                        <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                          Write
                                                        </small>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </td>
                                                <td>
                                                  {language?.proficiency ===
                                                  "Intermediate" ? (
                                                    <span>
                                                      {language?.speak ? (
                                                        <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                          Speak
                                                        </small>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {language?.read ? (
                                                        <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                          Read
                                                        </small>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {language?.write ? (
                                                        <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                          Write
                                                        </small>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </td>
                                                <td>
                                                  {language?.proficiency ===
                                                  "Expert" ? (
                                                    <span>
                                                      {language?.speak ? (
                                                        <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                          Speak
                                                        </small>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {language?.read ? (
                                                        <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                          Read
                                                        </small>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {language?.write ? (
                                                        <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                          Write
                                                        </small>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </td>
                                                <td>
                                                  
                                                    <div className="row">
                                                    <div className="col-6">
                                                    <div className="delete-content my-auto">
                                                    <span
                                                      className="material-symbols-rounded icon-middle"
                                                      onClick={() => {
                                                        setDeleteLanguageData({
                                                          knownLanguageId:
                                                            language?.knownLanguageId,
                                                          data: language,
                                                        });
                                                        setLanguageDeleteModalStatus(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      delete
                                                    </span>
                                                    </div>
                                                    </div>
                                                    <div className="col-6">
                                                    <div className="delete-content my-auto">
                                                    <span
                                                      className="material-symbols-rounded icon-middle"
                                                      onClick={() => {
                                                        setEditLanguageModal(true);
                                                        fetchParticularLanguage(language?.knownLanguageId)
                                                        
                                                      }}
                                                    >
                                                      edit
                                                    </span>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    
                                                
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </table>

                                    {/* <div>
                                      <small>Read, Speak, and Write</small>
                                      <div className="my-2">
                                        {profileDetails?.language &&
                                        profileDetails?.language?.length
                                          ? profileDetails?.language
                                              ?.filter(
                                                (e) =>
                                                  e?.read === true &&
                                                  e?.speak === true &&
                                                  e?.write === true
                                              )
                                              .map((languageDataValue, i) => {
                                                return (
                                                  <small
                                                    className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1 "
                                                    key={i}
                                                  >
                                                    {
                                                      languageDataValue?.languageName
                                                    }
                                                    <span
                                                      className="material-symbols-rounded pl-2 icon-middle"
                                                      onClick={() => {
                                                        setDeleteLanguageData({
                                                          knownLanguageId:
                                                            languageDataValue.knownLanguageId,
                                                          data: languageDataValue,
                                                        });
                                                        setLanguageDeleteModalStatus(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      cancel
                                                    </span>
                                                  </small>
                                                );
                                              })
                                          : null}
                                      </div>
                                    </div>
                                    <div>
                                      <small>Read and Write</small>
                                      <div className="my-2">
                                        {profileDetails?.language &&
                                        profileDetails?.language?.length
                                          ? profileDetails?.language
                                              ?.filter(
                                                (e) =>
                                                  e?.read === true &&
                                                  e?.speak === false &&
                                                  e?.write === true
                                              )
                                              .map((languageDataValue, i) => {
                                                return (
                                                  <small
                                                    className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1 "
                                                    key={i}
                                                  >
                                                    {
                                                      languageDataValue?.languageName
                                                    }
                                                    <span
                                                      className="material-symbols-rounded pl-2 icon-middle"
                                                      onClick={() => {
                                                        setDeleteLanguageData({
                                                          knownLanguageId:
                                                            languageDataValue.knownLanguageId,
                                                          data: languageDataValue,
                                                        });
                                                        setLanguageDeleteModalStatus(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      cancel
                                                    </span>
                                                  </small>
                                                );
                                              })
                                          : null}
                                      </div>
                                    </div>
                                    <div>
                                      <small>Speak Only</small>
                                      <div className="my-2">
                                        {profileDetails?.language &&
                                        profileDetails?.language?.length
                                          ? profileDetails?.language
                                              ?.filter(
                                                (e) =>
                                                  e?.read === false &&
                                                  e?.speak === true &&
                                                  e?.write === false
                                              )
                                              .map((languageDataValue, i) => {
                                                return (
                                                  <small
                                                    className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1 "
                                                    key={i}
                                                  >
                                                    {
                                                      languageDataValue?.languageName
                                                    }
                                                    <span
                                                      className="material-symbols-rounded pl-2 icon-middle"
                                                      onClick={() => {
                                                        setDeleteLanguageData({
                                                          knownLanguageId:
                                                            languageDataValue.knownLanguageId,
                                                          data: languageDataValue,
                                                        });
                                                        setLanguageDeleteModalStatus(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      cancel
                                                    </span>
                                                  </small>
                                                );
                                              })
                                          : null}
                                      </div>
                                    </div> */}
                                  </div>
                                  <div className="google-input">
                                    <button className="btn btn-light my-2 my-sm-0 login-btn">
                                      Update
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {selectedTab === "Employment Details" && (
                  <EmploymentDetails
                    showToastSuccess={showToastSuccessMsg}
                    showToastError={showToastErrorMsg}
                    cities={cityData}
                    skills={skills}
                    handleCreateSkills={handleCreateSkills}
                    handleCreateCity={handleCity}
                  />
                )}
                {selectedTab === "Education Details" && (
                  <EducationalDetails
                    showToastSuccess={showToastSuccessMsg}
                    showToastError={showToastErrorMsg}
                    cities={cityData}
                    skills={skills}
                    handleCreateSkills={handleCreateSkills}
                    handleCreateCity={handleCity}
                  />
                )}
                {selectedTab === "Certifications" && (
                  <Certification
                    state={state}
                    showToastSuccess={showToastSuccessMsg}
                    showToastError={showToastErrorMsg}
                    handleCreateSkills={handleCreateSkills}
                    skills={skills}
                  />
                )}
                {selectedTab === "Resume & Cover Letter" && (
                  <div>
                    <>
                      <nav className="flex border-b tab-section border-gray-300">
                        {AllSubtabs.map((tab, index) => {
                          return (
                            <div
                              key={index}
                              className={
                                selectedSubTab === tab
                                  ? "tab active"
                                  : "tab inactive"
                              }
                              onClick={() => {
                                navigation({
                                  pathname: "/ProfileEmployee",
                                  search: `tab=Resume %26 Cover Letter&subtab=${tab}`,
                                });
                              }}
                            >
                              <li className="tab-header">{tab}</li>
                            </div>
                          );
                        })}
                      </nav>
                      <div className="py-1">
                        {selectedSubTab === "Resumes" && (
                          <div className="scroll-content">
                            <div>
                              <div className="container py-4">
                                <div className="row justify-content-center d-flex align-items-center ">
                                  <div className="col-lg-12">
                                    <div className="row">
                                      <div className="col-lg-2 col-md-3 col-4 my-2 text-center">
                                        <div className="upload-btn-wrapper">
                                          <button className="btn upload-gallery">
                                            <h1>
                                              <span className="material-symbols-rounded">
                                                add
                                              </span>
                                            </h1>
                                            <span className="content">
                                              Upload Resume
                                            </span>
                                          </button>
                                          <input
                                            autoComplete="off"
                                            type="file"
                                            name="myfile"
                                            // accept=".pdf"
                                            onChange={(e) => uploadResume(e)}
                                          ></input>
                                        </div>
                                      </div>
                                      <div className="col-lg-10 ">
                                        <div className="edudetails-section">
                                          <div className="row  ">
                                            {getResume?.map((e, index) => (
                                              <div
                                                className="col-lg-6 col-md-6 px-1 my-2"
                                                key={index}
                                              >
                                                <div className="edudetails-card p-0">
                                                  <div className="d-flex justify-content-between">
                                                    <div className="down-pdf d-flex align-items-end flex-column">
                                                      <h4 className="font-weight-bold">
                                                        PDF
                                                      </h4>
                                                      <span className="material-symbols-rounded mx-auto  mt-auto">
                                                        download
                                                      </span>
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-center centered-content">
                                                      <span className="material-symbols-rounded hori-mor">
                                                        more_horiz
                                                      </span>
                                                      <p className="mb-1 mx-2 font-weight-bold">
                                                        {e.fileName}
                                                      </p>
                                                      <p className="mb-0 mx-2 ">
                                                        Uploaded on
                                                        {moment(
                                                          e.createdAt
                                                        ).format("DD/MM/YYYY")}
                                                      </p>
                                                    </div>
                                                    <div className="delete-content my-auto">
                                                      <span
                                                        className="material-symbols-rounded icon-middle "
                                                        onClick={() => {
                                                          setResumeId(
                                                            e.resumeId
                                                          );
                                                          setDeleteGalleryModal(
                                                            true
                                                          );
                                                        }}
                                                      >
                                                        delete
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {selectedSubTab === "Cover Letters" && (
                          <div className="scroll-content">
                            <div>
                              <div className="container py-4">
                                <div className="row justify-content-center d-flex align-items-center ">
                                  <div className="col-lg-12">
                                    <div className="row">
                                      <div className="col-lg-2 col-md-3 col-4 my-2 text-center">
                                        <div className="upload-btn-wrapper">
                                          <button
                                            className="btn upload-gallery"
                                            onClick={() => {
                                              setAddCoverLetterModal(true);
                                            }}
                                          >
                                            <h1>
                                              <span className="material-symbols-rounded">
                                                add
                                              </span>
                                            </h1>
                                            <span className="content">
                                              Add Cover Letter
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                      <div className="col-lg-10 ">
                                        <div className="edudetails-section">
                                          <div className="row  ">
                                            {getCoverLetter?.map((e, index) => (
                                              <div
                                                className="col-lg-6 col-md-6 px-1 my-2"
                                                key={index}
                                              >
                                                <div className="edudetails-card">
                                                  <div className="d-flex justify-content-between">
                                                    <h6 className="font-weight-bold">
                                                      {e.title}
                                                    </h6>
                                                    <div className="icon">
                                                      <span
                                                        className="material-icons icon-middle edit  m-1"
                                                        onClick={() => {
                                                          setEditCoverLetterModal(
                                                            true
                                                          );
                                                          setUpdateCoverLetter(
                                                            e
                                                          );
                                                        }}
                                                      >
                                                        edit
                                                      </span>
                                                      <span
                                                        className="material-symbols-rounded del icon-middle rounded m-1"
                                                        onClick={() => {
                                                          setDeleteCoverModal(
                                                            true
                                                          );
                                                          setDeleteCoverId(
                                                            e.userCoverLetterId
                                                          );
                                                        }}
                                                      >
                                                        delete
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <p className="">
                                                    {e.description}
                                                  </p>
                                                  <div className="my-2">
                                                    <h6 className="mb-0 ">
                                                      Uploaded on&nbsp;
                                                      {moment(
                                                        e.createdAt
                                                      ).format("DD/MM/YYYY")}
                                                    </h6>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                )}
                {selectedTab === "Skills" && (
                  <Skills
                    showToastSuccess={showToastSuccessMsg}
                    showToastError={showToastErrorMsg}
                    skills={skills}
                    handleCreateSkills={handleCreateSkills}
                  />
                )}
                {selectedTab === "Change Password" && (
                  <div>
                    <div className="container">
                      <div className="row d-flex  justify-content-center scroll-content">
                        <div className="col-lg-10">
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              onSubmitChangePassword(e);
                            }}
                          >
                            <div className="form-container">
                              <div className="row justify-content-center">
                                <div className="col-lg-6 px-5 pb-4">
                                  <div className="google-input">
                                    <input
                                      autoComplete="off"
                                      type="password"
                                      id="oldPassword"
                                      name="oldPassword"
                                      placeholder="Enter your Current Password"
                                      value={
                                        changePasswordData?.oldPassword || ""
                                      }
                                      onChange={(e) => {
                                        onChangeChangePassword(
                                          "oldPassword",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label htmlFor="oldPassword">
                                      Current Password
                                    </label>
                                  </div>
                                  <p className="text-danger">
                                    {changePasswordValidationData?.oldPassword}
                                  </p>
                                  <div className="google-input">
                                    <input
                                      autoComplete="off"
                                      type="password"
                                      id="password"
                                      name="password"
                                      placeholder="Enter your New Password"
                                      value={changePasswordData?.password || ""}
                                      onChange={(e) => {
                                        onChangeChangePassword(
                                          "password",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label htmlFor="password">
                                      New Password
                                    </label>
                                  </div>
                                  <p className="text-danger">
                                    {changePasswordValidationData?.password}
                                  </p>
                                  <div>
                                    {changePasswordData?.password ? (
                                      <div className="mt-3">
                                        <b>
                                          Create a password with the following
                                        </b>
                                        <br />

                                        {
                                          <p
                                            className={`${
                                              changePasswordData?.password.trim()
                                                .length < 8
                                                ? "text-danger"
                                                : "text-success"
                                            } d-flex`}
                                          >
                                            {changePasswordData?.password.trim()
                                              .length < 8 ? (
                                              <span className="material-symbols-rounded filled-icon close-icon">
                                                close
                                              </span>
                                            ) : (
                                              <span className="material-symbols-rounded filled-icon close-icon">
                                                check
                                              </span>
                                            )}
                                            At least 8 Characters
                                          </p>
                                        }

                                        {
                                          <p
                                            className={`${
                                              !/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                                changePasswordData?.password
                                              )
                                                ? "text-danger"
                                                : "text-success"
                                            } d-flex`}
                                          >
                                            {!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                              changePasswordData?.password
                                            ) ? (
                                              <span className="material-symbols-rounded filled-icon close-icon">
                                                close
                                              </span>
                                            ) : (
                                              <span className="material-symbols-rounded filled-icon close-icon">
                                                check
                                              </span>
                                            )}
                                            An Uppercase &amp; Lowercase
                                            character
                                          </p>
                                        }

                                        {
                                          <p
                                            className={`${
                                              !/^(?=.*[.,:;'!@#$%^&*_+=|(){}[?\-\]/\\]).*$/.test(
                                                changePasswordData?.password
                                              )
                                                ? "text-danger"
                                                : "text-success"
                                            } d-flex`}
                                          >
                                            {!/^(?=.*[.,:;'!@#$%^&*_+=|(){}[?\-\]/\\]).*$/.test(
                                              changePasswordData?.password
                                            ) ? (
                                              <span className="material-symbols-rounded filled-icon close-icon">
                                                close
                                              </span>
                                            ) : (
                                              <span className="material-symbols-rounded filled-icon close-icon">
                                                check
                                              </span>
                                            )}
                                            A special character
                                          </p>
                                        }

                                        {
                                          <p
                                            className={`${
                                              !/[0-9]/.test(
                                                changePasswordData?.password
                                              )
                                                ? "text-danger"
                                                : "text-success"
                                            } d-flex`}
                                          >
                                            {!/[0-9]/.test(
                                              changePasswordData?.password
                                            ) ? (
                                              <span className="material-symbols-rounded filled-icon close-icon">
                                                close
                                              </span>
                                            ) : (
                                              <span className="material-symbols-rounded filled-icon close-icon">
                                                check
                                              </span>
                                            )}
                                            A number
                                          </p>
                                        }
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="google-input">
                                    <input
                                      autoComplete="off"
                                      type="password"
                                      id="template-input"
                                      placeholder="Re-Enter your New Password"
                                      value={
                                        changePasswordData?.confirmPassword ||
                                        ""
                                      }
                                      onChange={(e) => {
                                        onChangeChangePassword(
                                          "confirmPassword",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label htmlFor="template-input">
                                      Re-Enter New Password
                                    </label>
                                  </div>
                                  <p className="text-danger">
                                    {
                                      changePasswordValidationData?.confirmPassword
                                    }
                                  </p>
                                </div>
                                <div className="col-lg-12 text-center">
                                  <div className="google-input">
                                    <button className="btn btn-light my-2 my-sm-0 login-btn">
                                      Change Password
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          </div>
        </div>
        <div className="res-profilesection2">
        <button
              onClick={()=>{
                ProfileTabRef.current.classList.toggle('show')
              }} >Test</button>
          <div id="accordion">
            <div className="card my-1">
              <div className="card-header" id="headingOne">
                <h5 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Personal Details
                    <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                      expand_more
                    </span>
                  </button>
                </h5>
              </div>

              <div
                id="collapseOne"
                className="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordion"
                ref={ProfileTabRef}
              >
                <div className="card-body p-0">
                  <div className="scroll-content">
                    <div className="container">
                    <div className="scroll-content">
                    <div className="container">
                      <div className="row d-flex align-items-center justify-content-center ">
                        <div className="col-lg-10">
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              onSubmitProfileDetails();
                            }}
                          >
                            <div className="form-container py-1">
                              <div className="row justify-content-center">
                                <div className="col-lg-5 col-md-6  pb-4 border-right">
                                  <div className="google-input">
                                    <input
                                      autoComplete="off"
                                      onChange={(e) => {
                                        handleProfileDetails(
                                          "fullName",
                                          e.target.value
                                        );
                                      }}
                                      value={profileDetails.fullName || ""}
                                      type="text"
                                      id="fullName"
                                      placeholder="Enter your full name"
                                      aria-invalid={
                                        profileDetailsValidation?.fullName
                                          ?.status === false
                                      }
                                      onBlur={(e) => {
                                        setBasicDetailsValidationValue(
                                          "fullName",
                                          StringValidation(e.target.value)
                                        );
                                      }}
                                    />
                                    <label htmlFor="template-input">
                                      Full Name
                                    </label>
                                  </div>
                                  {profileDetailsValidation?.fullName
                                    ?.status === false ? (
                                    <small className="text-danger">
                                      Full Name
                                      {
                                        profileDetailsValidation?.fullName
                                          ?.message
                                      }
                                    </small>
                                  ) : null}
                                  <div className="google-input">
                                    <input
                                      autoComplete="off"
                                      type="text"
                                      id="displayName"
                                      placeholder="Enter your Display name"
                                      onChange={(e) => {
                                        handleProfileDetails(
                                          "displayName",
                                          e.target.value
                                        );
                                      }}
                                      value={profileDetails.displayName || ""}
                                      aria-invalid={
                                        profileDetailsValidation?.displayName
                                          ?.status === false
                                      }
                                      onBlur={(e) => {
                                        setBasicDetailsValidationValue(
                                          "displayName",
                                          StringValidation(e.target.value)
                                        );
                                      }}
                                    />
                                    <label htmlFor="template-input">
                                      Display Name
                                    </label>
                                  </div>
                                  {profileDetailsValidation?.displayName
                                    ?.status === false ? (
                                    <small className="text-danger">
                                      Display Name
                                      {
                                        profileDetailsValidation?.displayName
                                          ?.message
                                      }
                                    </small>
                                  ) : null}
                                  <div className="google-input">
                                    <input
                                      autoComplete="off"
                                      type="text"
                                      id="emailId"
                                      placeholder="Enter your email"
                                      onChange={(e) => {
                                        handleProfileDetails(
                                          "emailId",
                                          e.target.value
                                        );
                                      }}
                                      value={profileDetails.emailId || ""}
                                      aria-invalid={
                                        profileDetailsValidation?.emailId
                                          ?.status === false
                                      }
                                      onBlur={(e) => {
                                        setBasicDetailsValidationValue(
                                          "emailId",
                                          EmailValidation(e.target.value)
                                        );
                                      }}
                                    />
                                    {profileDetails?.isEmailIdVerified ===
                                    false ? (
                                      <span
                                        className="text-white bg-danger rounded edit-icon-input"
                                        onClick={() => {
                                          setEmailVerifyModal(true);
                                          Resent();
                                        }}
                                      >
                                        Verify
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    <label htmlFor="template-input">
                                      Email Id
                                    </label>
                                  </div>
                                  {profileDetailsValidation?.emailId?.status ===
                                  false ? (
                                    <small className="text-danger">
                                      Email Id
                                      {
                                        profileDetailsValidation?.emailId
                                          ?.message
                                      }
                                    </small>
                                  ) : null}

                                  <div className="google-input">
                                    <input
                                      autoComplete="off"
                                      type="text"
                                      id="template-input"
                                      onClick={() => {
                                        setAddLanguageModal(true);
                                      }}
                                      readOnly
                                      placeholder="Enter known languages"
                                    />
                                    <label htmlFor="template-input">
                                      Languages
                                    </label>
                                  </div>

                                  {profileDetailsValidation?.language
                                    ?.status === false ? (
                                    <small className="text-danger">
                                      Language
                                      {
                                        profileDetailsValidation?.language
                                          ?.message
                                      }
                                    </small>
                                  ) : null}
                                  <div className="selected-lang my-2">
                                    <div>
                                      <small>Read, Speak, and Write</small>
                                      <div className="my-2">
                                        {profileDetails?.language &&
                                        profileDetails?.language?.length
                                          ? profileDetails?.language
                                              ?.filter(
                                                (e) =>
                                                  e?.read === true &&
                                                  e?.speak === true &&
                                                  e?.write === true
                                              )
                                              .map((languageDataValue, i) => {
                                                return (
                                                  <small
                                                    className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1 "
                                                    key={i}
                                                  >
                                                    {
                                                      languageDataValue?.languageName
                                                    }
                                                    <span
                                                      className="material-symbols-rounded pl-2 icon-middle"
                                                      onClick={() => {
                                                        setDeleteLanguageData({
                                                          knownLanguageId:
                                                            languageDataValue.knownLanguageId,
                                                          data: languageDataValue,
                                                        });
                                                        setLanguageDeleteModalStatus(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      cancel
                                                    </span>
                                                  </small>
                                                );
                                              })
                                          : null}
                                      </div>
                                    </div>
                                    <div>
                                      <small>Read and Write</small>
                                      <div className="my-2">
                                        {profileDetails?.language &&
                                        profileDetails?.language?.length
                                          ? profileDetails?.language
                                              ?.filter(
                                                (e) =>
                                                  e?.read === true &&
                                                  e?.speak === false &&
                                                  e?.write === true
                                              )
                                              .map((languageDataValue, i) => {
                                                return (
                                                  <small
                                                    className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1 "
                                                    key={i}
                                                  >
                                                    {
                                                      languageDataValue?.languageName
                                                    }
                                                    <span
                                                      className="material-symbols-rounded pl-2 icon-middle"
                                                      onClick={() => {
                                                        setDeleteLanguageData({
                                                          knownLanguageId:
                                                            languageDataValue.knownLanguageId,
                                                          data: languageDataValue,
                                                        });
                                                        setLanguageDeleteModalStatus(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      cancel
                                                    </span>
                                                  </small>
                                                );
                                              })
                                          : null}
                                      </div>
                                    </div>
                                    <div>
                                      <small>Speak Only</small>
                                      <div className="my-2">
                                        {profileDetails?.language &&
                                        profileDetails?.language?.length
                                          ? profileDetails?.language
                                              ?.filter(
                                                (e) =>
                                                  e?.read === false &&
                                                  e?.speak === true &&
                                                  e?.write === false
                                              )
                                              .map((languageDataValue, i) => {
                                                return (
                                                  <small
                                                    className="bg-color-green pl-3 pr-1 py-1 mx-1 my-1 "
                                                    key={i}
                                                  >
                                                    {
                                                      languageDataValue?.languageName
                                                    }
                                                    <span
                                                      className="material-symbols-rounded pl-2 icon-middle"
                                                      onClick={() => {
                                                        setDeleteLanguageData({
                                                          knownLanguageId:
                                                            languageDataValue.knownLanguageId,
                                                          data: languageDataValue,
                                                        });
                                                        setLanguageDeleteModalStatus(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      cancel
                                                    </span>
                                                  </small>
                                                );
                                              })
                                          : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-5 col-md-6 pb-4">
                                  <div className="google-input">
                                    <label
                                      htmlFor="template-input"
                                      className="z-index-value"
                                    >
                                      Gender
                                    </label>
                                    <div className="dropdown-container">
                                      <Select
                                        options={gender}
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: "6px",
                                          zIndex: 3,
                                          // colors: {
                                          //   ...theme.colors,
                                          //   primary25: "#DFF1F1",
                                          //   neutral20:
                                          //     profileDetailsValidation?.genderId
                                          //       ?.status === false
                                          //       ? "red"
                                          //       : "#00ABAD", // border color
                                          //   primary:
                                          //     profileDetailsValidation?.genderId
                                          //       ?.status === false
                                          //       ? "red"
                                          //       : "#00ABAD", // border color when focus
                                          // },
                                        })}
                                        styles={{
                                          menu: (provided, state) => ({
                                            ...provided,
                                            zIndex: "5 !important",
                                          }),
                                        }}
                                        placeholder="Choose your gender"
                                        onChange={(e) => {
                                          handleProfileDetails("genderId", e);
                                        }}
                                        value={{
                                          value:
                                            profileDetails?.genderId?.value ||
                                            profileDetails?.genderId?.name,
                                          label:
                                            profileDetails?.genderId?.label ||
                                            profileDetails?.genderId?.name,
                                        }}
                                      />
                                    </div>
                                  </div>

                                  {/* {profileDetailsValidation?.genderId
                                    ?.status === false ? (
                                    <small className="text-danger">
                                      Gender
                                      {
                                        profileDetailsValidation?.genderId
                                          ?.message
                                      }
                                    </small>
                                  ) : null} */}
                                  <PhoneInput
                                    inputStyle={{
                                      width: "100%",
                                      height: "45px",
                                      onFocus: "red",
                                    }}
                                    onlyCountries={["us"]}
                                    // country={"us"}
                                    // countryCodeEditable={true}
                                    value={profileDetails?.mobileNumber?.toString()}
                                    onChange={(e) => {
                                      handleProfileDetails("mobileNumber", e);
                                    }}
                                    specialLabel="Phone Number"
                                    masks={{ us: ".........." }}
                                    onBlur={() => {
                                      if (
                                        typeof profileDetails?.mobileNumber !==
                                        "undefined"
                                      ) {
                                        setBasicDetailsValidationValue(
                                          "mobileNumber",
                                          PhoneNumberValidation(
                                            profileDetails?.mobileNumber
                                          )
                                        );
                                      } else {
                                        return true;
                                      }
                                    }}
                                  />
                                  <small className="text-danger">
                                    {profileDetailsValidation?.mobileNumber
                                      ?.message
                                      ? `Phone number ${profileDetailsValidation?.mobileNumber?.message}`
                                      : ""}
                                  </small>

                                  <div className="google-input">
                                    <label
                                      htmlFor="template-input"
                                      className="z-index-value"
                                    >
                                      Primary Location
                                    </label>
                                    <div className="dropdown-container">
                                      <CreatableSelect
                                        options={location}
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: "6px",
                                          zIndex: 3,
                                          colors: {
                                            ...theme.colors,
                                            primary25: "#DFF1F1",
                                            primary: "#00ABAD",
                                          },
                                        })}
                                        styles={{
                                          menu: (provided, state) => ({
                                            ...provided,
                                            zIndex: "5 !important",
                                          }),
                                        }}
                                        getOptionLabel={(option) =>
                                          option.name || option.label
                                        }
                                        getOptionValue={(option) =>
                                          option.masterDataId || option.dataId
                                        }
                                        placeholder="Choose your primary location"
                                        value={profileDetails?.primaryLocation}
                                        onCreateOption={(e) => {
                                          handleCreateLocation(
                                            "primaryLocation",
                                            e
                                          );
                                        }}
                                        onChange={(e) => {
                                          handleProfileDetails(
                                            "primaryLocation",
                                            e
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {profileDetailsValidation?.primaryLocation
                                    ?.status === false ? (
                                    <small className="text-danger">
                                      Primary Location
                                      {
                                        profileDetailsValidation
                                          ?.primaryLocation?.message
                                      }
                                    </small>
                                  ) : null}
                                  <div className="google-input">
                                    <label
                                      htmlFor="template-input"
                                      className="z-index-value"
                                    >
                                      Secondary Location
                                    </label>
                                    <div className="dropdown-container">
                                      <CreatableSelect
                                        isMulti
                                        options={location}
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: "6px",
                                          colors: {
                                            ...theme.colors,
                                            primary25: "#DFF1F1",
                                            primary: "#00ABAD",
                                          },
                                        })}
                                        styles={{
                                          menu: (provided, state) => ({
                                            ...provided,
                                            zIndex: "5 !important",
                                          }),
                                        }}
                                        getOptionLabel={(option) =>
                                          option.name || option.label
                                        }
                                        getOptionValue={(option) =>
                                          option.masterDataId || option.dataId
                                        }
                                        placeholder="Choose your secondary location"
                                        onChange={(e) => {
                                          handleProfileDetails(
                                            "secondaryLocation",
                                            e
                                          );
                                        }}
                                        value={
                                          profileDetails?.secondaryLocation
                                        }
                                        onCreateOption={(e) => {
                                          handleCreateLocation(
                                            "secondaryLocation",
                                            e
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  {profileDetailsValidation?.secondaryLocation
                                    ?.status === false ? (
                                    <small className="text-danger">
                                      Secondary Location
                                      {
                                        profileDetailsValidation
                                          ?.secondaryLocation?.message
                                      }
                                    </small>
                                  ) : null}
                                </div>
                                <div className="col-lg-12 text-center">
                                  <div className="google-input">
                                    <button className="btn btn-light my-2 my-sm-0 login-btn">
                                      Update
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="card my-1">
              <div className="card-header" id="headingTwo">
                <h5 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Employment Details
                    <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                      expand_more
                    </span>
                  </button>
                </h5>
              </div>

              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordion"
              >
                <div className="card-body p-0">
                  <div className="scroll-content">
                    <div className="container">
                    <EmploymentDetails
                    showToastSuccess={showToastSuccessMsg}
                    showToastError={showToastErrorMsg}
                    cities={cityData}
                    skills={skills}
                    handleCreateSkills={handleCreateSkills}
                    handleCreateCity={handleCity}
                  />

                    </div>
                  </div>
                </div>
              </div>
            </div>
                                    
            <div className="card my-1">
              <div className="card-header" id="headingThree">
                <h5 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Education Details
                    <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                      expand_more
                    </span>
                  </button>
                </h5>
              </div>

              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordion"
              >
                <div className="card-body p-0">
                  <div className="scroll-content">
                    <div className="container">
                    <EducationalDetails
                    showToastSuccess={showToastSuccessMsg}
                    showToastError={showToastErrorMsg}
                    cities={cityData}
                    skills={skills}
                    handleCreateSkills={handleCreateSkills}
                    handleCreateCity={handleCity}
                  />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card my-1">
              <div className="card-header" id="headingFour">
                <h5 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Certifications
                    <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                      expand_more
                    </span>
                  </button>
                </h5>
              </div>

              <div
                id="collapseFour"
                className="collapse"
                aria-labelledby="headingFour"
                data-parent="#accordion"
              >
                <div className="card-body p-0">
                  <div className="scroll-content">
                    <div className="container">
                    <Certification
              state={state}
              showToastSuccess={showToastSuccessMsg}
              showToastError={showToastErrorMsg}
              skills={skills}
              handleCreateSkills={handleCreateSkills}
            />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="card my-1">
              <div className="card-header" id="headingFive">
                <h5 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Resume & Cover Letter
                    <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                      expand_more
                    </span>
                  </button>
                </h5>
              </div>
              <div
                id="collapseFive"
                className="collapse"
                aria-labelledby="headingFive"
                data-parent="#accordion"
              >
                <div className="card-body px-0 pt-0 pb-2">
                  <div id="accordion-1">
                    <div className="card bg-light my-1 mx-2">
                      <div className="card-header mb-0" id="headingFiveOne">
                        <h5 className="my-0">
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapseFiveOne"
                            aria-expanded="false"
                            aria-controls="collapseFiveOne"
                          >
                            Resumes
                            <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                              expand_more
                            </span>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseFiveOne"
                        className="collapse"
                        aria-labelledby="headingFiveOne"
                        data-parent="#accordion-1"
                      >
                        <div className="card-body p-0">
                        <div className="scroll-content">
                            <div>
                              <div className="container py-4">
                                <div className="row justify-content-center d-flex align-items-center ">
                                  <div className="col-lg-12">
                                    <div className="row">
                                      <div className="col-lg-2 col-md-3 col-4 my-2 text-center">
                                        <div className="upload-btn-wrapper">
                                          <button className="btn upload-gallery">
                                            <h1>
                                              <span className="material-symbols-rounded">
                                                add
                                              </span>
                                            </h1>
                                            <span className="content">
                                              Upload Resume
                                            </span>
                                          </button>
                                          <input
                                            autoComplete="off"
                                            type="file"
                                            name="myfile"
                                            // accept=".pdf"
                                            onChange={(e) => uploadResume(e)}
                                          ></input>
                                        </div>
                                      </div>
                                      <div className="col-lg-10 ">
                                        <div className="edudetails-section">
                                          <div className="row  ">
                                            {getResume?.map((e, index) => (
                                              <div
                                                className="col-lg-6 col-md-6 px-1 my-2"
                                                key={index}
                                              >
                                                <div className="edudetails-card p-0">
                                                  <div className="d-flex justify-content-between">
                                                    <div className="down-pdf d-flex align-items-end flex-column">
                                                      <h4 className="font-weight-bold">
                                                        PDF
                                                      </h4>
                                                      <span className="material-symbols-rounded mx-auto  mt-auto">
                                                        download
                                                      </span>
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-center centered-content">
                                                      <span className="material-symbols-rounded hori-mor">
                                                        more_horiz
                                                      </span>
                                                      <p className="mb-1 mx-2 font-weight-bold">
                                                        {e.fileName}
                                                      </p>
                                                      <p className="mb-0 mx-2 ">
                                                        Uploaded on
                                                        {moment(
                                                          e.createdAt
                                                        ).format("DD/MM/YYYY")}
                                                      </p>
                                                    </div>
                                                    <div className="delete-content my-auto">
                                                      <span
                                                        className="material-symbols-rounded icon-middle "
                                                        onClick={() => {
                                                          setResumeId(
                                                            e.resumeId
                                                          );
                                                          setDeleteGalleryModal(
                                                            true
                                                          );
                                                        }}
                                                      >
                                                        delete
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card bg-light my-1 mx-2">
                      <div className="card-header mb-0" id="headingFiveTwo">
                        <h5 className="my-0">
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapseFiveTwo"
                            aria-expanded="false"
                            aria-controls="collapseFiveTwo"
                          >
                            Cover Letters
                            <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                              expand_more
                            </span>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseFiveTwo"
                        className="collapse"
                        aria-labelledby="headingFiveTwo"
                        data-parent="#accordion-1"
                      >
                        <div className="card-body p-0">
                        <div className="scroll-content">
                            <div>
                              <div className="container py-4">
                                <div className="row justify-content-center d-flex align-items-center ">
                                  <div className="col-lg-12">
                                    <div className="row">
                                      <div className="col-lg-2 col-md-3 col-4 my-2 text-center">
                                        <div className="upload-btn-wrapper">
                                          <button
                                            className="btn upload-gallery"
                                            onClick={() => {
                                              setAddCoverLetterModal(true);
                                            }}
                                          >
                                            <h1>
                                              <span className="material-symbols-rounded">
                                                add
                                              </span>
                                            </h1>
                                            <span className="content">
                                              Add Cover Letter
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                      <div className="col-lg-10 ">
                                        <div className="edudetails-section">
                                          <div className="row  ">
                                            {getCoverLetter?.map((e, index) => (
                                              <div
                                                className="col-lg-6 col-md-6 px-1 my-2"
                                                key={index}
                                              >
                                                <div className="edudetails-card">
                                                  <div className="d-flex justify-content-between">
                                                    <h6 className="font-weight-bold">
                                                      {e.title}
                                                    </h6>
                                                    <div className="icon">
                                                      <span
                                                        className="material-icons icon-middle edit  m-1"
                                                        onClick={() => {
                                                          setEditCoverLetterModal(
                                                            true
                                                          );
                                                          setUpdateCoverLetter(
                                                            e
                                                          );
                                                        }}
                                                      >
                                                        edit
                                                      </span>
                                                      <span
                                                        className="material-symbols-rounded del icon-middle rounded m-1"
                                                        onClick={() => {
                                                          setDeleteCoverModal(
                                                            true
                                                          );
                                                          setDeleteCoverId(
                                                            e.userCoverLetterId
                                                          );
                                                        }}
                                                      >
                                                        delete
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <p className="">
                                                    {e.description}
                                                  </p>
                                                  <div className="my-2">
                                                    <h6 className="mb-0 ">
                                                      Uploaded on&nbsp;
                                                      {moment(
                                                        e.createdAt
                                                      ).format("DD/MM/YYYY")}
                                                    </h6>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card my-1">
              <div className="card-header" id="headingSix">
                <h5 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Skills
                    <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                      expand_more
                    </span>
                  </button>
                </h5>
              </div>

              <div
                id="collapseSix"
                className="collapse"
                aria-labelledby="headingSix"
                data-parent="#accordion"
              >
                <div className="card-body p-0">
                  <div className="scroll-content">
                    <div className="container">
                    <Skills
                    showToastSuccess={showToastSuccessMsg}
                    showToastError={showToastErrorMsg}
                    skills={skills}
                    handleCreateSkills={handleCreateSkills}
                  />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card my-1">
              <div className="card-header" id="headingSeven">
                <h5 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    Change Password
                    <span className="material-symbols-rounded font-weight-bold align-middle ml-auto">
                      expand_more
                    </span>
                  </button>
                </h5>
              </div>
              <div
                id="collapseSeven"
                className="collapse"
                aria-labelledby="headingSeven"
                data-parent="#accordion"
              >
                <div className="card-body p-0">
                  <div>
                    <div className="container">
                      <div className="row d-flex  justify-content-center scroll-content">
                        <div className="col-lg-10">
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              onSubmitChangePassword(e);
                            }}
                          >
                            <div className="form-container">
                              <div className="row justify-content-center">
                                <div className="col-lg-6 px-5 pb-4">
                                  <div className="google-input">
                                    <input
                                      autoComplete="off"
                                      type="password"
                                      id="oldPassword"
                                      name="oldPassword"
                                      placeholder="Enter your Current Password"
                                      value={
                                        changePasswordData?.oldPassword || ""
                                      }
                                      onChange={(e) => {
                                        onChangeChangePassword(
                                          "oldPassword",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label htmlFor="oldPassword">
                                      Current Password
                                    </label>
                                  </div>
                                  <p className="text-danger">
                                    {changePasswordValidationData?.oldPassword}
                                  </p>
                                  <div className="google-input">
                                    <input
                                      autoComplete="off"
                                      type="password"
                                      id="password"
                                      name="password"
                                      placeholder="Enter your New Password"
                                      value={changePasswordData?.password || ""}
                                      onChange={(e) => {
                                        onChangeChangePassword(
                                          "password",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label htmlFor="password">
                                      New Password
                                    </label>
                                  </div>
                                  <p className="text-danger">
                                    {changePasswordValidationData?.password}
                                  </p>
                                  <div>
                                    {changePasswordData?.password ? (
                                      <div className="mt-3">
                                        <b>
                                          Create a password with the following
                                        </b>
                                        <br />

                                        {
                                          <p
                                            className={`${
                                              changePasswordData?.password.trim()
                                                .length < 8
                                                ? "text-danger"
                                                : "text-success"
                                            } d-flex`}
                                          >
                                            {changePasswordData?.password.trim()
                                              .length < 8 ? (
                                              <span className="material-symbols-rounded filled-icon close-icon">
                                                close
                                              </span>
                                            ) : (
                                              <span className="material-symbols-rounded filled-icon close-icon">
                                                check
                                              </span>
                                            )}
                                            At least 8 Characters
                                          </p>
                                        }

                                        {
                                          <p
                                            className={`${
                                              !/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                                changePasswordData?.password
                                              )
                                                ? "text-danger"
                                                : "text-success"
                                            } d-flex`}
                                          >
                                            {!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                              changePasswordData?.password
                                            ) ? (
                                              <span className="material-symbols-rounded filled-icon close-icon">
                                                close
                                              </span>
                                            ) : (
                                              <span className="material-symbols-rounded filled-icon close-icon">
                                                check
                                              </span>
                                            )}
                                            An Uppercase &amp; Lowercase
                                            character
                                          </p>
                                        }

                                        {
                                          <p
                                            className={`${
                                              !/^(?=.*[.,:;'!@#$%^&*_+=|(){}[?\-\]/\\]).*$/.test(
                                                changePasswordData?.password
                                              )
                                                ? "text-danger"
                                                : "text-success"
                                            } d-flex`}
                                          >
                                            {!/^(?=.*[.,:;'!@#$%^&*_+=|(){}[?\-\]/\\]).*$/.test(
                                              changePasswordData?.password
                                            ) ? (
                                              <span className="material-symbols-rounded filled-icon close-icon">
                                                close
                                              </span>
                                            ) : (
                                              <span className="material-symbols-rounded filled-icon close-icon">
                                                check
                                              </span>
                                            )}
                                            A special character
                                          </p>
                                        }

                                        {
                                          <p
                                            className={`${
                                              !/[0-9]/.test(
                                                changePasswordData?.password
                                              )
                                                ? "text-danger"
                                                : "text-success"
                                            } d-flex`}
                                          >
                                            {!/[0-9]/.test(
                                              changePasswordData?.password
                                            ) ? (
                                              <span className="material-symbols-rounded filled-icon close-icon">
                                                close
                                              </span>
                                            ) : (
                                              <span className="material-symbols-rounded filled-icon close-icon">
                                                check
                                              </span>
                                            )}
                                            A number
                                          </p>
                                        }
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="google-input">
                                    <input
                                      autoComplete="off"
                                      type="password"
                                      id="template-input"
                                      placeholder="Re-Enter your New Password"
                                      value={
                                        changePasswordData?.confirmPassword ||
                                        ""
                                      }
                                      onChange={(e) => {
                                        onChangeChangePassword(
                                          "confirmPassword",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <label htmlFor="template-input">
                                      Re-Enter New Password
                                    </label>
                                  </div>
                                  <p className="text-danger">
                                    {
                                      changePasswordValidationData?.confirmPassword
                                    }
                                  </p>
                                </div>
                                <div className="col-lg-12 text-center">
                                  <div className="google-input">
                                    <button className="btn btn-light my-2 my-sm-0 login-btn">
                                      Change Password
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
) : (
  <CropEasy
    {...{ photoURL, uploadCompanyUrl,setOpenCrop, setPhotoURL, setFile, fileName, field }}
  />
)}
      <CustomModal
        open={AddCoverLetterModal}
        onClickOutside={() => {
          setAddCoverLetterModal(false);
          setCoverLetterValidation({});
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader py-3">
              <h5 className="text-center font-weight-bold my-0">
                Add Cover Letter
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content py-2 my-2">
                <div className="col-lg-12">
                  <form>
                    <div className="form-container  py-1">
                      <div className="row justify-content-center">
                        <div className="col-lg-6 px-4 pb-4 ">
                          <div className="google-input">
                            <input
                              autoComplete="off"
                              type="text"
                              id="template-input"
                              name="title"
                              aria-invalid={Boolean(
                                coverLetterValidation?.title?.status === false
                              )}
                              placeholder="Type your title"
                              onChange={(e) => {
                                setCoverLetterValue("title", e.target.value);
                              }}
                              onBlur={(e) => {
                                setCoverLetterValidationValue(
                                  "title",
                                  StringValidation(e.target.value)
                                );
                              }}
                            />
                            <label htmlFor="template-input">Title</label>
                          </div>
                          <small className="text-danger">
                            {coverLetterValidation?.title?.message
                              ? `Title ${coverLetterValidation?.title?.message}`
                              : ""}
                          </small>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modalAnswer col-lg-6 mt-2 pt-2 px-4">
                  <label htmlFor="template-input" className="z-index-value">
                    Description
                  </label>
                  <div className="modalDisapprove-details d-flex  justify-content-end ">
                    <button
                      type="button"
                      className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                    >
                      Rich Text Editor
                      <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                        lock
                      </span>
                    </button>
                  </div>

                  <div className="form-group">
                    <textarea
                      className="form-control  modalDisapprove-textarea"
                      id="exampleFormControlTextarea2"
                      rows="7"
                      aria-invalid={Boolean(
                        coverLetterValidation?.description?.status === false
                      )}
                      name="description"
                      onChange={(e) => {
                        setCoverLetterValue("description", e.target.value);
                      }}
                      onBlur={(e) => {
                        setCoverLetterValidationValue(
                          "description",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    ></textarea>
                  </div>
                  <small className="text-danger">
                    {coverLetterValidation?.description?.message
                      ? `Description ${coverLetterValidation?.description?.message}`
                      : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setAddCoverLetterModal(false);
                    setCoverLetterValidation({});
                  }}
                >
                  Back
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                  onClick={CoverLetterValidation}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={EditCoverLetterModal}
        onClickOutside={() => {
          setEditCoverLetterModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader py-3">
              <h5 className="text-center font-weight-bold my-0">
                Edit Cover Letter
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-12">
                  <form>
                    <div className="form-container  py-1">
                      <div className="row justify-content-center">
                        <div className="col-lg-6 px-4 pb-4 ">
                          <div className="google-input">
                            <input
                              autoComplete="off"
                              aria-invalid={Boolean(
                                updateCoverLetterValidation?.title?.status ===
                                  false
                              )}
                              type="text"
                              id="template-input"
                              name="title"
                              placeholder="Type your title"
                              defaultValue={updateCoverLetter.title}
                              onChange={(e) => {
                                setUpdateCoverLetterValue(
                                  "title",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setUpdateCoverLetterValidationValue(
                                  "title",
                                  StringValidation(e.target.value)
                                );
                              }}
                            />
                            <label htmlFor="template-input">Title</label>
                          </div>
                          <small className="text-danger">
                            {updateCoverLetterValidation?.title?.message
                              ? `Title ${updateCoverLetterValidation?.title?.message}`
                              : ""}
                          </small>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modalAnswer col-lg-6 mt-2 pt-2 px-4 ">
                  <label htmlFor="template-input" className="z-index-value">
                    Description
                  </label>
                  <div className="modalDisapprove-details d-flex  justify-content-end ">
                    <button
                      type="button"
                      className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                    >
                      Rich Text Editor
                      <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                        lock
                      </span>
                    </button>
                  </div>

                  <div className="form-group">
                    <textarea
                      className="form-control  modalDisapprove-textarea"
                      id="exampleFormControlTextarea2"
                      aria-invalid={Boolean(
                        updateCoverLetterValidation?.description?.status ===
                          false
                      )}
                      name="description"
                      rows="7"
                      defaultValue={updateCoverLetter.description}
                      onChange={(e) => {
                        setUpdateCoverLetterValue(
                          "description",
                          e.target.value
                        );
                      }}
                      onBlur={(e) => {
                        setUpdateCoverLetterValidationValue(
                          "description",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    ></textarea>
                  </div>
                  <small className="text-danger">
                    {updateCoverLetterValidation?.description?.message
                      ? `Description ${updateCoverLetterValidation?.description?.message}`
                      : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setEditCoverLetterModal(false);
                    setUpdateCoverLetterValidation({});
                  }}
                >
                  Back
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                  onClick={() => UpdateCoverLetterValidation()}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={deleteCoverModal}
        onClickOutside={() => {
          setDeleteCoverModal(false);
        }}
      >
        <div className="modal-content w-75  m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <h3 className="font-weight-bold text-center">
                    Are You Sure?
                  </h3>
                  <p className=" text-center">
                    Do you really want to delete Cover Letter ? this process
                    cannot be undone.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center border-0 mb-3">
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                  onClick={() => {
                    setDeleteCoverModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ padding: "8px 24px" }}
                  className="btn btn-danger my-2 my-sm-0 mr-2 "
                  onClick={() => handleDeleteCoverLetter(deleteCoverId)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={AddLanguageModal}
        onClickOutside={() => {
          setAddLanguageModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Add Language
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-0">
                <div className="col-lg-5">
                  <div className="google-input">
                    <label htmlFor="template-input" className="z-index-value">
                      Language*
                    </label>
                    <div className="dropdown-container">
                      <CreatableSelect
                        options={language}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "6px",
                          zIndex: 3,
                          colors: {
                            ...theme.colors,
                            primary25: "#DFF1F1",
                            neutral20:
                              languageValidation?.languageId?.status === false
                                ? "red"
                                : "#00ABAD", // border color
                            primary:
                              languageValidation?.languageId?.status === false
                                ? "red"
                                : "#00ABAD", // border color when focus
                          },
                        })}
                        getOptionLabel={(option) => option.name || option.label}
                        getOptionValue={(option) => option.masterDataId}
                        styles={{
                          menu: (provided, state) => ({
                            ...provided,
                            zIndex: "5 !important",
                          }),
                        }}
                        onBlur={() => {
                          setLanguageValidationValue(
                            "languageId",
                            NonEmptyObjectValidation(languageData?.languageId)
                          );
                        }}
                        onCreateOption={(e) => handleCreateLanguage(e,"create")}
                        placeholder="Choose your languages"
                        value={languageData?.languageId}
                        onChange={(e) =>
                          onChangeLanguageDetails("languageId", e)
                        }
                      />
                    </div>
                    {languageValidation?.languageId?.status === false ? (
                      <small className="text-danger">
                        Language {languageValidation?.languageId?.message}
                      </small>
                    ) : null}
                  </div>
                  <div className="modalAnswer mt-3">
                    <label
                      htmlFor="template-input"
                      className="z-index-value employeeNavbar-labels"
                    >
                      Communication skill
                    </label>
                    <div>
                      <label
                        className={`btn addLanguage-skills mr-2 ${
                          languageData?.speak ? "active" : ""
                        }`}
                        onClick={() =>
                          onChangeLanguageDetails("speak", !languageData.speak)
                        }
                      >
                        Speak
                      </label>
                      <label
                        className={`btn addLanguage-skills mr-2 ${
                          languageData?.read ? "active" : ""
                        }`}
                        onClick={() =>
                          onChangeLanguageDetails("read", !languageData.read)
                        }
                      >
                        Read
                      </label>
                      <label
                        className={`btn addLanguage-skills mr-2 ${
                          languageData?.write ? "active" : ""
                        }`}
                        onClick={() =>
                          onChangeLanguageDetails("write", !languageData.write)
                        }
                      >
                        Write
                      </label>
                    </div>
                    {languageValidation?.speak?.status === false ? (
                      <small className="text-danger">
                        Skill {languageValidation?.speak?.message}
                      </small>
                    ) : null}
                  </div>
                  <div className="modalAnswer ml-1 mt-5">
                    <label
                      htmlFor="template-input"
                      className="z-index-value employeeNavbar-labels"
                    >
                      Proficiency
                    </label>
                    <div
                      className="form-check"
                      onClick={() =>
                        onChangeLanguageDetails("proficiency", "Basic")
                      }
                    >
                      <input
                        autoComplete="off"
                        checked={languageData?.proficiency === "Basic"}
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                      />
                      <label className="form-check-label" htmlFor="gridCheck1">
                        Basic
                      </label>
                    </div>
                    <div
                      className="form-check mt-1"
                      onClick={() =>
                        onChangeLanguageDetails("proficiency", "Intermediate")
                      }
                    >
                      <input
                        autoComplete="off"
                        checked={languageData?.proficiency === "Intermediate"}
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                      />
                      <label className="form-check-label" htmlFor="gridCheck1">
                        Intermediate
                      </label>
                    </div>
                    <div
                      className="form-check mt-1"
                      onClick={() =>
                        onChangeLanguageDetails("proficiency", "Expert")
                      }
                    >
                      <input
                        autoComplete="off"
                        checked={languageData?.proficiency === "Expert"}
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                      />
                      <label className="form-check-label" htmlFor="gridCheck1">
                        Expert
                      </label>
                    </div>
                    {languageValidation?.proficiency?.status === false ? (
                      <small className="text-danger">
                        Proficiency {languageValidation?.proficiency?.message}
                      </small>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setAddLanguageModal(false);
                  }}
                >
                  Close
                </button>
              </div>
              <div>
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                  onClick={() => {
                    CreateKnownLanguageData();
                  }}
                >
                  ADD
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={deleteGalleryModal}
        onClickOutside={() => {
          setDeleteGalleryModal(false);
        }}
      >
        <div className="modal-content w-75  m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px;",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <h3 className="font-weight-bold text-center">
                    Are You Sure?
                  </h3>
                  <p className=" text-center">
                    Do you really want to delete this resume? this process
                    cannot be undone.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center border-0 mb-3">
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                  onClick={() => {
                    setDeleteGalleryModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ padding: "8px 24px" }}
                  className="btn btn-danger my-2 my-sm-0 mr-2 "
                  onClick={() => handleDeleteResume(resumeId)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={languageDeleteModalStatus}
        onClickOutside={() => {
          setLanguageDeleteModalStatus(false);
        }}
      >
        <div className="modal-content w-75 m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <h3 className="font-weight-bold text-center">
                    Are You Sure?
                  </h3>
                  <p className=" text-center">
                    Do you really want to delete this language? this process
                    cannot be undone.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center border-0 mb-3">
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                  onClick={() => {
                    setLanguageDeleteModalStatus(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ padding: "8px 24px" }}
                  className="btn btn-danger my-2 my-sm-0 mr-2 "
                  onClick={() => handleDeleteSelectedLanguage()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={emailVerifyModal}
        onClickOutside={() => {
          setEmailVerifyModal(false);
          setValidation({});
        }}
      >
        <div className="modal-content w-100  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                onClick={() => {
                  setEmailVerifyModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-6 col-md-6 modal-register-bg m-auto">
                  <div className="section1 ">
                    <img
                      className="mt-3"
                      style={{ width: "100px", height: "100px" }}
                      src={Roboimg}
                      alt=""
                    />
                    <h1>
                      Howdy! I’m Quantum, <br /> your personal recruiter who
                      never sleeps!
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6  m-auto">
                  <div
                    className="section2"
                    style={{ boxShadow: "0px 3px 6px #00000017" }}
                  >
                    <h5 className="text-center pt-0 font-weight-bold mx-4">
                      Verify your email ID
                    </h5>
                    <div className="mx-4 pb-pt-form">
                      <form className="my-4 mx-5" onSubmit={(e) => Submit(e)}>
                        <div className="form-container">
                          <div className="verification-code--inputs">
                            <OtpInput
                              className="otpInput"
                              value={code}
                              onChange={handleChange}
                              numInputs={6}
                              separator={<span style={{ width: "8px" }}></span>}
                              isInputNum={true}
                              shouldAutoFocus={true}
                              inputStyle={{
                                border: "1px solid #CFD3DB",
                                borderRadius: "8px",
                                width: "40px",
                                height: "40px",
                                fontSize: "12px",
                                color: "#000",
                                fontWeight: "400",
                                caretColor: "blue",
                              }}
                              focusStyle={{
                                border: "1px solid black ",
                                outline: "none",
                              }}
                            />
                          </div>
                          <small className="text-danger">
                            {validation?.code?.message
                              ? `OTP ${validation?.code?.message}`
                              : ""}
                          </small>
                          {/* <p className="text-danger">{errors.otp?.message}</p> */}
                          {seconds > 0 || minutes > 0 ? (
                            <div className="top-12">
                              Resend OTP in&nbsp;
                              <span className="text-green">
                                {minutes < 10 ? `0${minutes}` : minutes}:
                                {seconds < 10 ? `0${seconds}` : seconds}
                              </span>
                            </div>
                          ) : (
                            <div className="top-12">
                              Didn't receive code?&nbsp;
                              <span
                                className="fgtpwd"
                                style={{ cursor: "pointer" }}
                                onClick={() => Resent()}
                              >
                                Resend
                              </span>
                            </div>
                          )}

                          <div className="google-input-btm">
                            <button
                              type="submit"
                              className="btn btn-light my-2 my-sm-0 login-btn"
                              // onClick={() => naviGation("/Profile")}
                            >
                              Verify
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={EditLanguageModal}
        onClickOutside={() => {
          setEditLanguageModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Edit Language
              </h5>
            </div>
            
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-0">
                <div className="col-lg-5">
                  <div className="google-input">
                    <label htmlFor="template-input" className="z-index-value">
                      Language*
                    </label>
                    <div className="dropdown-container">
                      <CreatableSelect
                        options={language}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "6px",
                          zIndex: 3,
                          colors: {
                            ...theme.colors,
                            primary25: "#DFF1F1",
                            neutral20:
                            particularLanguageValidation?.language?.status === false
                                ? "red"
                                : "#00ABAD", // border color
                            primary:
                            particularLanguageValidation?.language?.status === false
                                ? "red"
                                : "#00ABAD", // border color when focus
                          },
                        })}
                        getOptionLabel={(option) => option.name || option.label}
                        getOptionValue={(option) => option.masterDataId}
                        styles={{
                          menu: (provided, state) => ({
                            ...provided,
                            zIndex: "5 !important",
                          }),
                        }}
                        onInputChange={() => {
                          if (
                            particularLanguageValidation[
                              "language"
                            ]
                          )
                            delete particularLanguageValidation[
                              "language"
                            ];
                        }}
                        onCreateOption={(e) => handleCreateLanguage(e,"update")}
                        placeholder="Choose your languages"
                        value={particularLanguage?.language}
                        onChange={(e) =>
                          setParticularLanguageValue("language", e)
                        }
                      />
                    </div>
                    {particularLanguageValidation?.language?.status === false ? (
                      <small className="text-danger">
                        Language {particularLanguageValidation?.language?.message}
                      </small>
                    ) : null}
                  </div>
                  <div className="modalAnswer mt-3">
                    <label
                      htmlFor="template-input"
                      className="z-index-value employeeNavbar-labels"
                    >
                      Communication skill
                    </label>
                    <div>
                      <label
                        className={`btn addLanguage-skills mr-2 ${
                          particularLanguage?.speak ? "active" : ""
                        }`}
                        onClick={() =>
                          setParticularLanguageValue("speak", !particularLanguage.speak)
                        }
                      >
                        Speak
                      </label>
                      <label
                        className={`btn addLanguage-skills mr-2 ${
                          particularLanguage?.read ? "active" : ""
                        }`}
                        onClick={() =>
                          setParticularLanguageValue("read", !particularLanguage.read)
                        }
                      >
                        Read
                      </label>
                      <label
                        className={`btn addLanguage-skills mr-2 ${
                          particularLanguage?.write ? "active" : ""
                        }`}
                        onClick={() =>
                          setParticularLanguageValue("write", !particularLanguage.write)
                        }
                      >
                        Write
                      </label>
                    </div>
                    {particularLanguageValidation?.speak?.status === false ? (
                      <small className="text-danger">
                        Skill {particularLanguageValidation?.speak?.message}
                      </small>
                    ) : null}
                  </div>
                  <div className="modalAnswer ml-1 mt-5">
                    <label
                      htmlFor="template-input"
                      className="z-index-value employeeNavbar-labels"
                    >
                      Proficiency
                    </label>
                    <div
                      className="form-check"
                      onClick={() =>
                        setParticularLanguageValue("proficiency", "Basic")
                      }
                    >
                      <input
                        autoComplete="off"
                        checked={particularLanguage?.proficiency === "Basic"}
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                      />
                      <label className="form-check-label" htmlFor="gridCheck1">
                        Basic
                      </label>
                    </div>
                    <div
                      className="form-check mt-1"
                      onClick={() =>
                        setParticularLanguageValue("proficiency", "Intermediate")
                      }
                    >
                      <input
                        autoComplete="off"
                        checked={particularLanguage?.proficiency === "Intermediate"}
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                      />
                      <label className="form-check-label" htmlFor="gridCheck1">
                        Intermediate
                      </label>
                    </div>
                    <div
                      className="form-check mt-1"
                      onClick={() =>
                        setParticularLanguageValue("proficiency", "Expert")
                      }
                    >
                      <input
                        autoComplete="off"
                        checked={particularLanguage?.proficiency === "Expert"}
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                      />
                      <label className="form-check-label" htmlFor="gridCheck1">
                        Expert
                      </label>
                    </div>
                    {particularLanguageValidation?.proficiency?.status === false ? (
                      <small className="text-danger">
                        Proficiency {particularLanguageValidation?.proficiency?.message}
                      </small>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setParticularLanguage({});
                    setEditLanguageModal(false);
          
                  }}
                >
                  Close
                </button>
              </div>
              <div>
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                  onClick={() => {
                    UpdateKnownLanguageData(particularLanguage?.knownLanguageId);
                  }}
                >
                  EDIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <ToastContainer />
    </div>
  );
}