
import React, { useState } from "react";
import NavbarAdmin from "../NavbarAdmin";
import Select from "react-select";
const options = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Others', label: 'Others' },
];
function EditJobs() {
    //get gender
    const [selectedOption, setSelectedOption] = useState(null);
    return (
        <div className="dashboard-bg">
            <NavbarAdmin />
            <div className="main">
                <div className="chat-section bg-white py-1" style={{ boxShadow: "0px 3px 6px #00000017" }}>
                    <div className="">
                        <>
                            <nav className="flex border-b tab-section border-gray-300">

                                <div className="tab active">
                                    <li className="tab-header">Edit Jobs</li>
                                </div>

                            </nav>
                        </>
                        <div className="">
                            <div className="container">
                                <div className="row d-flex align-items-center justify-content-center ">
                                    <div className="col-lg-10">
                                        <form>
                                            <div className="form-container py-4">
                                                <div className="row justify-content-center ">
                                                    <div className="col-lg-6 border-right  pb-4">
                                                        <div className="google-input">
                                                            <input autoComplete="off" type="text" id="template-input" placeholder="Enter your full name" />
                                                            <label htmlFor="template-input">Name</label>
                                                        </div>
                                                        <div className="google-input">
                                                            <input autoComplete="off" type="text" id="template-input" placeholder="Enter your email" />
                                                            <label htmlFor="template-input">Email Id</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6  pb-4">
                                                        <div className="google-input">
                                                            <input autoComplete="off" type="text" id="template-input" placeholder="Enter your Mobile Number" />
                                                            <label htmlFor="template-input">Mobile Number</label>
                                                        </div>
                                                        <div className="google-input">
                                                            <label
                                                                htmlFor="template-input"
                                                                className="z-index-value"
                                                            >
                                                                Gender
                                                            </label>

                                                            <div className="dropdown-container">
                                                                <Select
                                                                    options={options}
                                                                    defaultValue={selectedOption}
                                                                    onChange={setSelectedOption}
                                                                    theme={(theme) => ({
                                                                        ...theme,
                                                                        borderRadius: "6px",

                                                                        zIndex: 3,
                                                                        colors: {
                                                                            ...theme.colors,
                                                                            primary25: "#DFF1F1",
                                                                            neutral20: "#00ABAD", // border color
                                                                            primary: "#00ABAD", // border color when focus
                                                                        },
                                                                    })}
                                                                    placeholder="Select Gender"

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 text-center">
                                                        <div className="google-input">
                                                            <button type="button" className="btn btn-light my-2 my-sm-0 login-btn">
                                                                Update
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
export default EditJobs;