import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import CustomModal from "../CustomModal";
import { GetInitials } from "../Store/Constants";
import DashboardLogo from "../../images/dashboard-logo.png";
import { logOutSuccess } from "../Store/auth/action";
import { useDispatch, useSelector } from "react-redux";
import Roboimg2 from "../../images/roboimg-outerborder.png";
import CreatableSelect from "react-select/creatable";
import {
  CreateEmployeeSkills,
  CreateJobPost,
  GetMasterDatasByType,
  Logout,
} from "../Api/Api";
import { useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import EADLogo from "../../images/eadlogo2.png";
import "react-toastify/dist/ReactToastify.css";
//progress bar
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  StringValidation,
  NumberValidation,
  NonEmptyValidation,
  NumberMaxValidation,
  SalaryRangeValidation,
  NoOfPositionNumberValidation,
  NonEmptyObjectValidation,
} from "../Store/validate";

export default function NavbarDashboard(props) {
  const usePageLocation = useLocation();
  // React state to manage selected options
  const [selectedOptions, setSelectedOptions] = useState();

  // Array of all options
  const optionList = [
    { value: "Remote", label: "Remote" },
    { value: "Anywhere in US", label: "Anywhere in US" },
    { value: "Atlanta", label: "Atlanta" },
    { value: "California", label: "California" },
    { value: "Texas", label: "Texas" },
  ];

  //nav toggle
  const [Show, setShow] = React.useState(false);

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }
  //Navigation
  const naviGation = useNavigate();
  //state value
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const companyId = state?.auth?.auth?.userDetails?.companyId;
  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };
  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const dispatch = useDispatch();

  const handleSubmit = async (data) => {
    const token = state?.auth?.auth?.authKey;
    const response = await Logout(token);
    if (response && response.statusCode === 200) {
      dispatch(logOutSuccess(data));
      naviGation("/");
    }
  };

  const [postJobModal,  setpostJobModal] = useState(false);

  const [DisapproveModal, setDisapproveModal] = useState(false);
  const [PostJob2Modal, setPostJob2Modal] = useState(false);
  const [AddAnswerModal, setAddAnswerModal] = useState(false);
  const [ClosingModal, setClosingModal] = useState(false);
  const [RejectionModal, setRejectionModal] = useState(false);
  const [SendMessageModal, setSendMessageModal] = useState(false);
  const [UpdateStatusModal, setUpdateStatusModal] = useState(false);
  const [JobPostValidation, setJobPostValidation] = useState({});
  const [approveModal, setApproveModal] = useState(false);
  const ref = useRef(null);

  //////////////////////////////////////////////////////////////////////////////////////////////

  const [jobDetails, setJobDetails] = useState({});
  const setJobDetailsValue = (key, value) => {
    setJobDetails({ ...jobDetails, [key]: value });
    if (JobPostValidation[key]) delete JobPostValidation[key];
  };

  const setValidationValue = (key, value) => {
    setJobPostValidation({ ...JobPostValidation, [key]: value });
  };



  //get job title
  const [jobTitle, setJobTitle] = useState([]);

  const fetchJobTitle = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=job_title&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setJobTitle(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchJobTitle();
  }, [fetchJobTitle]);

  const handleCreateJobTitle = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("jobTitleId", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "job_title",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchJobTitle();
        setJobDetailsValue("jobTitleId", {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        });
      }
    }
  };

  //get employment type

  const [employmentType, setEmploymentType] = useState([]);

  const fetchData = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=employment&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setEmploymentType(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCreateEmploymentType = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("employmentTypeId", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "employment",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchData();

        const data = {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        };
        if (jobDetails?.employmentTypeId?.length) {
          let employmentTypeData = jobDetails?.employmentTypeId;

          employmentTypeData.push(data);
          setJobDetailsValue("employmentTypeId", employmentTypeData);
        } else {
          setJobDetailsValue("employmentTypeId", [data]);
        }
      }
    }
  };

  //get work place

  const [workPlace, setworkPlace] = useState([]);
  const fetchData1 = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=work_place&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setworkPlace(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData1();
  }, [fetchData1]);

  const handleCreateWorkPlace = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("workPlaceTypeId", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "work_place",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchData1();

        const data = {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        };
        if (jobDetails?.workPlaceTypeId?.length) {
          let workPlaceTypeData = jobDetails?.workPlaceTypeId;

          workPlaceTypeData.push(data);
          setJobDetailsValue("workPlaceTypeId", workPlaceTypeData);
        } else {
          setJobDetailsValue("workPlaceTypeId", [data]);
        }
      }
    }
  };

  //get schedule
  const [schedule, setSchedule] = useState([]);
  const fetchData2 = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=schedule&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setSchedule(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData2();
  }, [fetchData2]);

  const handleCreateSchedule = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("scheduleId", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "schedule",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchData2();

        const data = {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        };
        if (jobDetails?.scheduleId?.length) {
          let scheduleData = jobDetails?.scheduleId;

          scheduleData.push(data);
          setJobDetailsValue("scheduleId", scheduleData);
        } else {
          setJobDetailsValue("scheduleId", [data]);
        }
      }
    }
  };

  //get experience level
  const [experienceLevel, setExperienceLevel] = useState([]);
  
  const fetchData3 = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=experience_level&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setExperienceLevel(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData3();
  }, [fetchData3]);

  const handleCreateExperienceLevel = async (string) => {
    const valid = NonEmptyValidation(string);
    setValidationValue("experienceLevelId", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "experience_level",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchData3();

        const data = {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        };
        if (jobDetails?.experienceLevelId?.length) {
          let experienceLevelData = jobDetails?.experienceLevelId;
          experienceLevelData.push(data);
          setJobDetailsValue("experienceLevelId", experienceLevelData);
        } else {
          setJobDetailsValue("experienceLevelId", [data]);
        }
      }
    }
  };

  //get salary range

  const [salaryRage, setsalaryRage] = useState([]);
  const fetchSalaryRange = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=salary_range_by&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setsalaryRage(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchSalaryRange();
  }, [fetchSalaryRange]);

  //get must skill
  const [mustSkill, setMustSkill] = useState([]);
  const fetchData4 = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=must_have_skill&status=Active&restrictionLevel=1&companyId=${companyId}`;
    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setMustSkill(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData4();
  }, [fetchData4]);

  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    const NeededKeys = [
      "companyName",
      "officalEmail",
      "genderId",
      "fullName",
      "displayName",
      "phoneNumber",
      "profileImage",
    ];
    if (state?.auth?.auth?.userDetails) {
      const percent = NeededKeys.filter(
        (key) => state?.auth?.auth?.userDetails[key]
      );
      setPercentage(Math.round((percent.length / NeededKeys.length) * 100));
    }

    return () => {};
  }, [state?.auth?.auth?.userDetails]);

  const handleCreateMustSkill = async (string) => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      masterDataType: "must_have_skill",
      name: string,
      userId: state?.auth?.auth?.userProfileId,
      companyId: state?.auth?.auth?.userDetails?.companyId,
      restrictionLevel: 1,
    };
    const response = await CreateEmployeeSkills(Payload, token);
    if (response && response.statusCode === 200) {
      fetchData4();

      const data = {
        value: response.data.name,
        label: response.data.name,
        dataId: response.data.masterDataId,
      };
      if (jobDetails?.mustHaveSkillId?.length) {
        let mustHaveSkillData = jobDetails?.mustHaveSkillId;

        mustHaveSkillData.push(data);
        setJobDetailsValue("mustHaveSkillId", mustHaveSkillData);
      } else {
        setJobDetailsValue("mustHaveSkillId", [data]);
      }
    }
  };

  //get need skill
  const [needSkill, setNeedSkill] = useState([]);
  const fetchData5 = useCallback(async () => {
    if (!authKey || !companyId) return;
    const Query = `authKey=${authKey}&masterDataType=nice_have_skill&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setNeedSkill(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData5();
  }, [fetchData5]);

  const handleCreateNeedSkill = async (string) => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      masterDataType: "nice_have_skill",
      name: string,
      userId: state?.auth?.auth?.userProfileId,
      companyId: state?.auth?.auth?.userDetails?.companyId,
      restrictionLevel: 1,
    };
    const response = await CreateEmployeeSkills(Payload, token);
    if (response && response.statusCode === 200) {
      fetchData5();

      const data = {
        value: response.data.name,
        label: response.data.name,
        dataId: response.data.masterDataId,
      };
      if (jobDetails?.niceTohaveSkillId?.length) {
        let niceTohaveSkillData = jobDetails?.niceTohaveSkillId;

        niceTohaveSkillData.push(data);
        setJobDetailsValue("niceTohaveSkillId", niceTohaveSkillData);
      } else {
        setJobDetailsValue("niceTohaveSkillId", [data]);
      }
    }
  };

  //get education qualification
  const [educationalQualification, setEducationalQualification] = useState([]);

  const fetchData6 = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=educational&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setEducationalQualification(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData6();
  }, [fetchData6]);

  const handleCreateEducationalQualification = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("highestQualicationId", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "educational",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchData6();
        setJobDetailsValue("highestQualicationId", {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        });
      }
    }
  };

  //get employer beneffit
  const [employerBenefits, setEmployerBenefits] = useState([]);
  const fetchData7 = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=employer_benefits&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setEmployerBenefits(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchData7();
  }, [fetchData7]);

  const handleCreateEmployerBenefits = async (string) => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      masterDataType: "employer_benefits",
      name: string,
      userId: state?.auth?.auth?.userProfileId,
      companyId: state?.auth?.auth?.userDetails?.companyId,
      restrictionLevel: 1,
    };
    const response = await CreateEmployeeSkills(Payload, token);
    if (response && response.statusCode === 200) {
      fetchData7();

      const data = {
        value: response.data.name,
        label: response.data.name,
        dataId: response.data.masterDataId,
      };
      if (jobDetails?.employerBenefitsId?.length) {
        let employerBenefitsData = jobDetails?.employerBenefitsId;

        employerBenefitsData.push(data);
        setJobDetailsValue("employerBenefitsId", employerBenefitsData);
      } else {
        setJobDetailsValue("employerBenefitsId", [data]);
      }
    }
  };

  // create job post api integration

  const handleCreateJobPost = async () => {
    const Payload = {
      jobTitleId: jobDetails?.jobTitleId?.dataId,
      employmentTypeId:
        jobDetails?.employmentTypeId?.dataId ||
        jobDetails?.employmentTypeId?.map((e) => e.dataId.toString()),
        workPlaceTypeId:
        jobDetails?.workPlaceTypeId?.dataId ||
        jobDetails?.workPlaceTypeId?.map((e) => e.dataId.toString()),
        locationId: [jobDetails?.locationId],
        scheduleId:
        jobDetails?.scheduleId?.dataId ||
        jobDetails?.scheduleId?.map((e) => e.dataId.toString()),
        salaryRangeById: jobDetails?.salaryRangeById,
      salaryMin: jobDetails?.salaryMin,
      salaryMax: jobDetails?.salaryMax,
      experienceLevelId:
        jobDetails?.experienceLevelId?.dataId ||
        jobDetails?.experienceLevelId?.map((e) => e.dataId.toString()),
        mustHaveSkillId:
        jobDetails?.mustHaveSkillId?.dataId ||
        jobDetails?.mustHaveSkillId?.map((e) => e.dataId.toString()),
        niceTohaveSkillId:
        jobDetails?.niceTohaveSkillId?.dataId ||
        jobDetails?.niceTohaveSkillId?.map((e) => e.dataId.toString()),
        highestQualicationId: jobDetails?.highestQualicationId?.dataId,
      noOfPosition: jobDetails?.noOfPosition,
      companyId: state?.auth?.auth?.userDetails?.companyId,
      employerBenefitsId:
        jobDetails?.employerBenefitsId?.dataId ||
        jobDetails?.employerBenefitsId?.map((e) => e.dataId.toString()),
      jobDescription: jobDetails?.jobDescription,
    };
    const token = state?.auth?.auth?.authKey;
    console.log(Payload)
    const response = await CreateJobPost(Payload, token);
    if (response && response.statusCode === 200) {
      setpostJobModal(false);
      setPostJob2Modal(false);
      props.handleListingJobPost();
      showToastSuccessMsg(response.message);
      ClearFields();
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  //clear edit field

  const ClearFields = () => {
    setJobDetails(null);
    setJobPostValidation({});
  };


  const ContinueValidation = () => {
    let validate = {};
    validate.jobTitleId = NonEmptyObjectValidation(jobDetails?.jobTitleId?.dataId);
    validate.employmentTypeId = NonEmptyObjectValidation(jobDetails?.employmentTypeId);
    validate.workPlaceTypeId = NonEmptyObjectValidation(jobDetails?.workPlaceTypeId);
    validate.locationId = NonEmptyValidation(jobDetails?.locationId);
    validate.scheduleId = NonEmptyObjectValidation(jobDetails?.scheduleId);
    validate.experienceLevelId = NonEmptyObjectValidation(jobDetails?.experienceLevelId);
    validate.mustHaveSkillId = NonEmptyObjectValidation(jobDetails?.mustHaveSkillId);
    validate.niceTohaveSkillId = NonEmptyObjectValidation(jobDetails?.niceTohaveSkillId);
    validate.salaryMin = NumberValidation(jobDetails?.salaryMin);
    validate.salaryMax = NumberMaxValidation(
      jobDetails?.salaryMax,
      jobDetails?.salaryMin
    );
    validate.salaryRangeById = SalaryRangeValidation(
      jobDetails?.salaryRangeById,
      "Select Range Type"
    );
    
    setJobPostValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      setPostJob2Modal(true);
      setpostJobModal(false);
    } else {
      console.log("Error while clicking continue in post job modal");
    }
  };

  const BackValidation = () => {
    let validate = {};
    validate.highestQualicationId = NonEmptyObjectValidation(
      jobDetails?.highestQualicationId
    );
    validate.noOfPosition = NoOfPositionNumberValidation(jobDetails?.noOfPosition);
    validate.employerBenefitsId = NonEmptyObjectValidation(
      jobDetails?.employerBenefitsId
    );
    validate.jobDescription = NonEmptyValidation(jobDetails?.jobDescription);
    setJobPostValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      setPostJob2Modal(false);
      setpostJobModal(true);
    } else {
      console.log("Error while clicking back in post job modal");
    }
  };

  const PublishValidation = () => {
    let validate = {};
    validate.jobTitleId = NonEmptyObjectValidation(jobDetails?.jobTitleId);
    validate.employmentTypeId = NonEmptyObjectValidation(jobDetails?.employmentTypeId);
    validate.workPlaceTypeId = NonEmptyObjectValidation(jobDetails?.workPlaceTypeId);
    validate.locationId = NonEmptyValidation(jobDetails?.locationId);
    validate.scheduleId = NonEmptyObjectValidation(jobDetails?.scheduleId);
    validate.experienceLevelId = NonEmptyObjectValidation(jobDetails?.experienceLevelId);
    validate.mustHaveSkillId = NonEmptyObjectValidation(jobDetails?.mustHaveSkillId);
    validate.niceTohaveSkillId = NonEmptyObjectValidation(jobDetails?.niceTohaveSkillId);
    validate.salaryMin = NumberValidation(jobDetails?.salaryMin);
    validate.salaryMax = NumberMaxValidation(
      jobDetails?.salaryMax,
      jobDetails?.salaryMin
    );
    validate.salaryRangeById = SalaryRangeValidation(
      jobDetails?.salaryRangeById,
      "Select Range Type"
    );
    setJobPostValidation(validate);

    if (Object.values(validate).every((v) => v.status === true)) {
      setPostJob2Modal(true);
      setpostJobModal(false);
      let validate = {};
      validate.highestQualicationId = NonEmptyObjectValidation(
        jobDetails.highestQualicationId
      );
      validate.noOfPosition = NoOfPositionNumberValidation(jobDetails?.noOfPosition);
      validate.employerBenefitsId = NonEmptyObjectValidation(
        jobDetails?.employerBenefitsId
      );
      validate.jobDescription = NonEmptyValidation(jobDetails?.jobDescription);
      setJobPostValidation(validate);
      if (Object.values(validate).every((v) => v.status === true)) {
        handleCreateJobPost();
      }
    } else {
      console.log("Error while clicking publish in post job modal");
    }
  };

  const FinalPublishValidation = () => {
    let validate = {};
    validate.highestQualicationId = NonEmptyObjectValidation(
      jobDetails.highestQualicationId
    );
    validate.noOfPosition = NoOfPositionNumberValidation(jobDetails?.noOfPosition);
    validate.employerBenefitsId = NonEmptyObjectValidation(
      jobDetails?.employerBenefitsId
    );
    validate.jobDescription = NonEmptyValidation(jobDetails?.jobDescription);
    setJobPostValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      handleCreateJobPost();
    } else {
      console.log("Error while clicking final publish in post job modal");
    }
  };

  let host = window.location.href;
  let component = host?.split("/");


  return (
    <div>
      <div className="topbar">
        <div className="topbar-header">
          <div className="dashboard-logo d-flex align-items-center justify-content-center">
            <NavLink to={"/Profile?tab=Basic Details"}>
              <img className="logo-dashboard-img " src={DashboardLogo} alt="" />
            </NavLink>
          </div>
          <div className="dashboard-header d-flex align-items-center  ">
            <div className="d-flex justify-content-between center-d-flex-content">
              <div className="dashboard-header-left my-auto ">
                <h3>{props.title}</h3>
              </div>
              <div className="dashboard-header-center my-auto ">
                <form className="form-inline my-2 my-lg-0">
                  <div className="form-group has-search mr-2">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input
                      autoComplete="off"
                      type="text"
                      className="form-control dashboard-search"
                      placeholder="Search Candidates"
                    />
                    {/* <span className="fa-solid fa-sliders form-control-feedback2"></span> */}
                    <span className="material-symbols-rounded form-control-feedback2">
                      tune
                    </span>
                  </div>
                </form>
              </div>
              <div className="dashboard-header-right my-auto ">
                <div className="form-inline my-2 my-lg-0">
                  {usePageLocation?.pathname === "/JobsHome" ? (
                    <button
                      className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn"
                      onClick={() => {
                        if (
                          state?.auth?.auth?.userDetails?.status === "Active"
                        ) {
                          setpostJobModal(true);
                        } else {
                          setApproveModal(true);
                        }
                      }}
                    >
                      Post a Job
                    </button>
                  ) : null}
                  <span
                    className="material-icons mx-4"
                    style={{ fontSize: "30px" }}
                  >
                    notifications_none
                  </span>
                  <div class="dropdown " style={{cursor:"pointer",textDecoration:"none"}}>
                  <div className="progress-profile2"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  >
                    <CircularProgressbarWithChildren
                      value={percentage}
                      styles={{
                        position: "absolute",
                        width: "50%",
                        height: "100%",
                        marginTop: "-50%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="title timer"
                        data-from="0"
                        data-to={percentage}
                      >
                        {state?.auth?.auth?.userDetails?.profileImage
                          ? ""
                          : GetInitials(
                              state?.auth?.auth?.userDetails?.fullName
                            )}
                        <img
                          className="PP-upload-img2"
                          src={state?.auth?.auth?.userDetails?.profileImage}
                          alt=""
                        />
                      </span>
                    </CircularProgressbarWithChildren>
                    
                      {/* <div className="overlay"></div>
                    <div className="left"></div>
                    <div className="right"></div> */}
                    </div>
                    <div
                      class="dropdown-menu nav-logout-dropdown"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <NavLink to="/Profile" style={{cursor:"pointer",textDecoration:"none"}}>
                        <button class="dropdown-item" >Profile</button>
                      </NavLink>
                      <button class="dropdown-item" style={{cursor:"pointer",textDecoration:"none"}} onClick={handleSubmit}>
                        Logout
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="responsive-topbar">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container d-flex justify-content-between">
            <div className="dashboard-logo d-flex align-items-center justify-content-center">
              <NavLink to={"/Profile"}>
                {" "}
                <img className="logo-dashboard-img " src={EADLogo} alt="" />
              </NavLink>
            </div>
            <div className="d-flex">
              <div className="btn btn-default top-btn-desk">
                <span className="material-symbols-rounded menu-icon">
                  search
                </span>
              </div>
              <button
                onClick={() => {
                  setShow(true);
                }}
                className="btn btn-default top-btn-desk"
                id="menu-toggle"
              >
                <span className="material-symbols-rounded menu-icon">menu</span>
              </button>
            </div>
          </div>
        </nav>
      </div>
      <div className={Show ? "sidebar active" : "sidebar"}>
        <div className="d-flex justify-content-between">
          <NavLink className="res-logo-img" to={"/Profile"}>
            {" "}
            <img className="logo-dashboard-img " src={EADLogo} alt="" />
          </NavLink>
          <button
            onClick={() => {
              setShow(false);
            }}
            className="btn btn-default closemenu-toggle"
            id="menu-toggle"
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <ul>
          <NavLink to="/InsightsEmployer">
            <span className="material-symbols-rounded">insights</span>
            <li>Insights</li>
          </NavLink>
          <NavLink to="/JobsHome"
          className={component[4]==="JobsDetailsPage" || component[4]==="CandidateProfile" ? "active" : ""}
          >
            <span className="material-symbols-rounded">plagiarism</span>
            <li>Jobs</li>
          </NavLink>
          <NavLink to="/Chat">
            <span className="material-symbols-rounded">sms</span>
            <li>Chat</li>
          </NavLink>
          <NavLink to="/Profile?tab=Basic Details">
            <span className="material-symbols-rounded">account_circle</span>
            <li>Profile</li>
          </NavLink>

          <NavLink to="/Reviews">
            <span className="material-symbols-rounded">3p</span>
            <li>Reviews</li>
          </NavLink>
          <NavLink to="/QA">
            <span className="material-symbols-rounded">quiz</span>
            <li>QA</li>
          </NavLink>
          <NavLink to="/FAQ">
            <span className="material-symbols-rounded">question_mark</span>
            <li>FAQ</li>
          </NavLink>
          <NavLink to="/BlogsDashboard">
            <span className="material-symbols-rounded">description</span>
            <li>Blogs</li>
          </NavLink>
          <NavLink to="" id="logout-hover" onClick={handleSubmit}>
            <span className="material-symbols-rounded">logout</span>
            <li>Logout</li>
          </NavLink>
          {/* <NavLink to="/logout" activeclassname="active"  onClick={handleSubmit}>
            <span className="material-symbols-rounded">logout</span>
            <li>Logout</li>
          </NavLink> */}
        </ul>
      </div>
      <ToastContainer />
      <CustomModal
        open={postJobModal}
        onClickOutside={() => {
          setpostJobModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader py-3">
              <h5 className="text-center font-weight-bold my-0">Post Job</h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content">
                <div className="col-lg-12">
                  <form>
                    <div className="form-container py-4">
                      <div className="row ">
                        <div className="col-lg-6 px-5 pb-4 border-right">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Job Title
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={jobTitle}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  border:
                                    JobPostValidation?.jobTitleId?.status ===
                                    false
                                      ? "1px solid red !important"
                                      : "#DFF1F1",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1", // selected option background color
                                    neutral20:
                                      JobPostValidation?.jobTitleId?.status ===
                                      false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.jobTitleId?.status ===
                                      false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose Job Title"
                                value={
                                  jobDetails?.jobTitleId
                                    ? {
                                        value:
                                          jobDetails?.jobTitleId?.label ||
                                          jobDetails?.jobTitleId?.name,
                                        label:
                                          jobDetails?.jobTitleId?.label ||
                                          jobDetails?.jobTitleId?.name,
                                        dataId:
                                          jobDetails?.jobTitleId?.dataId ||
                                          jobDetails?.jobTitleId?.masterDataId,
                                      }
                                    : {}
                                }
                                onInputChange={()=>{
                                  if (JobPostValidation["jobTitleId"]) delete JobPostValidation["jobTitleId"]
                                }
                                }
                                onChange={(newValue) => {
                                  setJobDetailsValue("jobTitleId", newValue);
                                 
                                }}
                                
                                onCreateOption={(e)=>handleCreateJobTitle(e)}
                                isClearable
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.jobTitleId?.message
                                ? `Job title ${JobPostValidation?.jobTitleId?.message}`
                                : ""}
                            </small>
                          </div>

                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Employment Type
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={employmentType}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1", // selected option background color
                                    neutral20:
                                      JobPostValidation?.employmentTypeId
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.employmentTypeId
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose employment type"
                                value={
                                  jobDetails?.employmentTypeId
                                    ? jobDetails?.employmentTypeId?.map((v) => {
                                        return {
                                          value: v.name ? v.name : v.label,
                                          label: v.name ? v.name : v.label,
                                          dataId: v.masterDataId
                                            ? v.masterDataId
                                            : v.dataId,
                                        };
                                      })
                                    : []
                                }
                                onInputChange={()=>{
                                  if (JobPostValidation["employmentTypeId"]) delete JobPostValidation["employmentTypeId"]
                                }
                              }
                                onChange={(newValue) => {
                                  setJobDetailsValue(
                                    "employmentTypeId",
                                    newValue
                                  );
                                }}
                               
                                onCreateOption={handleCreateEmploymentType}
                                isClearable
                                isMulti
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.employmentTypeId?.message
                                ? `Employment type ${JobPostValidation?.employmentTypeId?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Work Place Type
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={workPlace}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1", // selected option background color
                                    neutral20:
                                      JobPostValidation?.workPlaceTypeId
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.workPlaceTypeId
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose Work Place type"
                                isClearable
                                isMulti
                                value={
                                  jobDetails?.workPlaceTypeId
                                    ? jobDetails?.workPlaceTypeId?.map((v) => {
                                        return {
                                          value: v.name ? v.name : v.label,
                                          label: v.name ? v.name : v.label,
                                          dataId: v.masterDataId
                                            ? v.masterDataId
                                            : v.dataId,
                                        };
                                      })
                                    : []
                                }
                                onInputChange={() => {
                                  if (JobPostValidation["workPlaceTypeId"])
                                    delete JobPostValidation["workPlaceTypeId"];
                                }}
                                onChange={(newValue) => {
                                  setJobDetailsValue("workPlaceTypeId", newValue);
                                }}
                                onCreateOption={(e) => handleCreateWorkPlace(e)}
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.workPlaceTypeId?.message
                                ? `Work type ${JobPostValidation?.workPlaceTypeId?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Location
                            </label>
                            <div className="dropdown-container">
                              <Select
                                options={optionList}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1", // selected option background color
                                    neutral20:
                                      JobPostValidation?.locationId?.status ===
                                      false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation.locationId?.status ===
                                      false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose your job location"
                                value={{
                                  value: jobDetails?.locationId,
                                  label: jobDetails?.locationId,
                                }}
                               
                                onChange={(e) => {
                                  setJobDetailsValue("locationId", e.value);
                                }}
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.locationId?.message
                                ? `Location ${JobPostValidation?.locationId?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Schedule
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={schedule}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1", // selected option background color
                                    neutral20:
                                      JobPostValidation?.scheduleId?.status ===
                                      false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.scheduleId?.status ===
                                      false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose Schedule"
                                isClearable
                                isMulti
                                value={
                                  jobDetails?.scheduleId
                                    ? jobDetails?.scheduleId?.map((v) => {
                                        return {
                                          value: v.name ? v.name : v.label,
                                          label: v.name ? v.name : v.label,
                                          dataId: v.masterDataId
                                            ? v.masterDataId
                                            : v.dataId,
                                        };
                                      })
                                    : []
                                }
                                onInputChange={()=>{
                                  if (JobPostValidation["scheduleId"]) delete JobPostValidation["scheduleId"]
                                }
                              }
                                onChange={(newValue) => {
                                  setJobDetailsValue("scheduleId", newValue);
                                }}
                               
                                onCreateOption={handleCreateSchedule}
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.scheduleId?.message
                                ? `Schedule ${JobPostValidation?.scheduleId?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-6 px-5 pb-4">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Experience level
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={experienceLevel}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1", // selected option background color
                                    neutral20:
                                      JobPostValidation?.experienceLevelId
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.experienceLevelId
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose experience level"
                                value={
                                  jobDetails?.experienceLevelId
                                    ? jobDetails?.experienceLevelId?.map((v) => {
                                        return {
                                          value: v.name ? v.name : v.label,
                                          label: v.name ? v.name : v.label,
                                          dataId: v.masterDataId
                                            ? v.masterDataId
                                            : v.dataId,
                                        };
                                      })
                                    : []
                                }
                                onInputChange={() => {
                                  if (JobPostValidation["experienceLevelId"])
                                    delete JobPostValidation["experienceLevelId"];
                                }}
                                onChange={(newValue) => {
                                  setJobDetailsValue(
                                    "experienceLevelId",
                                    newValue
                                  );
                                }}
                               
                                onCreateOption={handleCreateExperienceLevel}
                                isClearable
                                isMulti
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.experienceLevelId?.message
                                ? `Experience level ${JobPostValidation?.experienceLevelId?.message}`
                                : ""}
                            </small>
                          </div>

                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Must Have Skills
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={mustSkill}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1", // selected option background color
                                    neutral20:
                                      JobPostValidation?.mustHaveSkillId
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.mustHaveSkillId
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose must have skills"
                                isClearable
                                isMulti
                                value={
                                  jobDetails?.mustHaveSkillId
                                    ? jobDetails?.mustHaveSkillId?.map((v) => {
                                        return {
                                          value: v.name ? v.name : v.label,
                                          label: v.name ? v.name : v.label,
                                          dataId: v.masterDataId
                                            ? v.masterDataId
                                            : v.dataId,
                                        };
                                      })
                                    : []
                                }
                                onInputChange={()=>{
                                  if (JobPostValidation["mustHaveSkillId"]) delete JobPostValidation["mustHaveSkillId"]
                                }
                              }
                                onChange={(newValue) => {
                                  setJobDetailsValue("mustHaveSkillId", newValue);
                                }}
                               
                                onCreateOption={handleCreateMustSkill}
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.mustHaveSkillId?.message
                                ? `Must Skill ${JobPostValidation?.mustHaveSkillId?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Nice To Have Skills
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={needSkill}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1", // selected option background color
                                    neutral20:
                                      JobPostValidation?.niceTohaveSkillId
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.niceTohaveSkillId
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose nice to have skills"
                                isClearable
                                isMulti
                                value={
                                  jobDetails?.niceTohaveSkillId
                                    ? jobDetails?.niceTohaveSkillId?.map((v) => {
                                        return {
                                          value: v.name ? v.name : v.label,
                                          label: v.name ? v.name : v.label,
                                          dataId: v.masterDataId
                                            ? v.masterDataId
                                            : v.dataId,
                                        };
                                      })
                                    : []
                                }
                                onInputChange={()=>{
                                  if (JobPostValidation["niceTohaveSkillId"]) delete JobPostValidation["niceTohaveSkillId"]
                                }
                              }
                                onChange={(newValue) => {
                                  setJobDetailsValue(
                                    "niceTohaveSkillId",
                                    newValue
                                  );
                                }}
                               
                                onCreateOption={handleCreateNeedSkill}
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.niceTohaveSkillId?.message
                                ? `Nice skill ${JobPostValidation?.niceTohaveSkillId?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="google-input">
                            {/* <input autoComplete="off" type="text" id="template-input" placeholder="Type your question" />
                            <label htmlFor="template-input">
                            Salary Range
                            </label> */}
                            <label
                              htmlFor="template-input"
                              style={{ zIndex: "1" }}
                            >
                              Salary Range
                            </label>
                            <div className="input-group">
                              <div className="row">
                                <div className="col-4">
                                  <input
                                    autoComplete="off"
                                    type="number"
                                    placeholder="Min"
                                    className="form-control"
                                    aria-label="Text input with segmented dropdown button"
                                    name="salaryRange"
                                    aria-invalid={Boolean(
                                      JobPostValidation?.salaryMin?.status ===
                                        false
                                    )}
                                    value={jobDetails?.salaryMin}
                                    onChange={(e) =>
                                      setJobDetailsValue(
                                        "salaryMin",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      setValidationValue(
                                        "salaryMin",
                                        NumberValidation(e.target.value)
                                      );
                                    }}
                                  />
                                  <small className="text-danger">
                                    {JobPostValidation?.salaryMin?.message
                                      ? `Minimum salary ${JobPostValidation?.salaryMin?.message}`
                                      : ""}
                                  </small>
                                </div>
                                <div className="col-4">
                                  <input
                                    autoComplete="off"
                                    type="number"
                                    placeholder="Max"
                                    aria-invalid={Boolean(
                                      JobPostValidation?.salaryMax?.status ===
                                        false
                                    )}
                                    className="form-control"
                                    aria-label="Text input with segmented dropdown button"
                                    name="salaryRange"
                                    value={jobDetails?.salaryMax}
                                    onChange={(e) =>
                                      setJobDetailsValue(
                                        "salaryMax",
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      setValidationValue(
                                        "salaryMax",
                                        NumberValidation(e.target.value)
                                      );
                                    }}
                                  />
                                  <small className="text-danger">
                                    {JobPostValidation?.salaryMax?.message
                                      ? `Maximum salary ${JobPostValidation?.salaryMax?.message}`
                                      : ""}
                                  </small>
                                </div>
                                <div className="col-4">
                                  <div className="input-group-append">
                                    <select
                                      className="custom-select"
                                      style={{
                                        height: "45px",
                                        borderRadius: "0px 0.25rem 0.25rem 0px",
                                        border:`${JobPostValidation?.salaryRangeById
                                          ?.status === false ? "1.5px solid red" : ""}`
                                      }}
                                      aria-invalid={Boolean(
                                        JobPostValidation?.salaryRangeById
                                          ?.status === false
                                      )}
                                      id="inputGroupSelect01"
                                      value={jobDetails?.salaryRangeById}
                                      ref={ref}
                                      onChange={(e) => {
                                        setJobDetailsValue(
                                          "salaryRangeById",
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option>Select Range Type</option>
                                      {salaryRage?.map((e, index) => (
                                        <option
                                          key={index}
                                          defaultValue={e.masterDataId}
                                        >
                                          {e.value}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <small className="text-danger">
                                    {JobPostValidation?.salaryRangeById?.message
                                      ? `Salary range ${JobPostValidation?.salaryRangeById?.message}`
                                      : ""}
                                  </small>
                                </div>
                              </div>
                              {/* <input autoComplete="off" type="text" className="form-control" aria-label="Text input with segmented dropdown button" name="salaryRange" onChange={(e) => setJobDetailsValue("salaryRange", e.target.value)} /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setpostJobModal(false);
                    setJobPostValidation({});
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn"
                  onClick={() => {
                    ContinueValidation();
                  }}
                >
                  Continue
                </button>
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                  onClick={() => {
                    PublishValidation();
                  }}
                >
                  Publish
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        open={DisapproveModal}
        onClickOutside={() => {
          setDisapproveModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Reason of Disapprove
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-5">
                  <div className="google-input">
                    <label htmlFor="template-input" className="z-index-value">
                      Reason
                    </label>
                    <div className="dropdown-container">
                      <Select
                        options={optionList}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "6px",
                          zIndex: 3,
                          colors: {
                            ...theme.colors,
                            primary25: "#DFF1F1",
                            primary: "#00ABAD",
                          },
                        })}
                        styles={{
                          menu: (provided, state) => ({
                            ...provided,
                            zIndex: "5 !important",
                          }),
                        }}

                        placeholder="Choose your reason"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isMulti
                      />
                    </div>
                  </div>
                  <div className="modalAnswer mt-2">
                    <label for="exampleFormControlTextarea2">
                      Reason Details
                    </label>
                    <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button
                        type="button"
                        className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                      >
                        Rich Text Editor
                        <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                          lock
                        </span>
                      </button>
                    </div>

                    <div className="form-group">
                      <textarea
                        className="form-control rounded-0 modalDisapprove-textarea"
                        id="exampleFormControlTextarea2"
                        rows="7"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setDisapproveModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn">
                  Disapprove
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      {/* //////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <CustomModal
        open={PostJob2Modal}
        onClickOutside={() => {
          setPostJob2Modal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">Post Job</h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-10">
                  <form>
                    <div className="form-container pt-4 py-1">
                      <div className="row ">
                        <div className="col-lg-6 px-5 pb-4 border-right">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Highest Qualification
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={educationalQualification}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1", // selected option background color
                                    neutral20:
                                      JobPostValidation
                                        ?.highestQualicationId?.status ===
                                      false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation
                                        ?.highestQualicationId?.status ===
                                      false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose qualification"
                                isClearable
                                value={
                                  jobDetails?.highestQualicationId
                                    ? {
                                        value: jobDetails
                                          ?.highestQualicationId?.name
                                          ? jobDetails?.highestQualicationId
                                              ?.name
                                          : jobDetails?.highestQualicationId
                                              ?.label,
                                        label: jobDetails
                                          ?.highestQualicationId?.name
                                          ? jobDetails?.highestQualicationId
                                              ?.name
                                          : jobDetails?.highestQualicationId
                                              ?.label,
                                        dataId:
                                          jobDetails?.highestQualicationId
                                            ?.masterDataId,
                                      }
                                    : {}
                                }
                                l
                                onChange={(newValue) => {
                                  setJobDetailsValue(
                                    "highestQualicationId",
                                    newValue
                                  );
                                }}
                                onInputChange={() => {
                                  if (JobPostValidation["highestQualicationId"])
                                    delete JobPostValidation["highestQualicationId"];
                                }}
                                onCreateOption={
                                  handleCreateEducationalQualification
                                }
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.highestQualicationId
                                ?.message
                                ? `Qualification ${JobPostValidation?.highestQualicationId?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="google-input mt-5">
                            <input
                              autoComplete="off"
                              type="text"
                              id="template-input"
                              aria-invalid={Boolean(
                                JobPostValidation?.noOfPosition?.status ===
                                  false
                              )}
                              maxLength={3}
                              name="noOfPosition"
                              placeholder="Enter no of position"
                              value={jobDetails?.noOfPosition}
                              onChange={(e) =>
                                setJobDetailsValue(
                                  "noOfPosition",
                                  e.target.value
                                )
                              }
                              onBlur={(e) => {
                                setValidationValue(
                                  "noOfPosition",
                                  NoOfPositionNumberValidation(e.target.value)
                                );
                              }}
                            />
                            <label htmlFor="template-input">
                              No of position
                            </label>
                            <small className="text-danger">
                              {JobPostValidation?.noOfPosition?.message
                                ? `Number of Position ${JobPostValidation?.noOfPosition?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                        <div className="col-lg-6 px-5 pb-4">
                          <div className="google-input">
                            <label
                              htmlFor="template-input"
                              className="z-index-value"
                            >
                              Employer Benefits
                            </label>
                            <div className="dropdown-container">
                              <CreatableSelect
                                options={employerBenefits}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "6px",
                                  zIndex: 3,
                                  colors: {
                                    ...theme.colors,
                                    primary25: "#DFF1F1", // selected option background color
                                    neutral20:
                                      JobPostValidation?.employerBenefitsId
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color
                                    primary:
                                      JobPostValidation?.employerBenefitsId
                                        ?.status === false
                                        ? "red"
                                        : "#00ABAD", // border color when focus
                                  },
                                })}
                                styles={{
                                  menu: (provided, state) => ({
                                    ...provided,
                                    zIndex: "5 !important",
                                  }),
                                }}
                                placeholder="Choose employer benefits"
                                isClearable
                                isMulti
                                value={
                                  jobDetails?.employerBenefitsId
                                    ? jobDetails?.employerBenefitsId?.map((v) => {
                                        return {
                                          value: v.name ? v.name : v.label,
                                          label: v.name ? v.name : v.label,
                                          dataId: v.masterDataId
                                            ? v.masterDataId
                                            : v.dataId,
                                        };
                                      })
                                    : []
                                }
                                onInputChange={()=>{
                                  if (JobPostValidation["employerBenefitsId"]) delete JobPostValidation["employerBenefitsId"]
                                }
                              }
                                onChange={(newValue) => {
                                  setJobDetailsValue(
                                    "employerBenefitsId",
                                    newValue
                                  );
                                }}
                               
                                onCreateOption={handleCreateEmployerBenefits}
                              />
                            </div>
                            <small className="text-danger">
                              {JobPostValidation?.employerBenefitsId?.message
                                ? `Employer benifits ${JobPostValidation?.employerBenefitsId?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modalAnswer col-lg-10 mt-2 pt-5 px-5 ml-2">
                  <label htmlFor="template-input" className="z-index-value">
                    Job Description
                  </label>
                  <div className="modalDisapprove-details d-flex  justify-content-end ">
                    <button
                      type="button"
                      className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                    >
                      Rich Text Editor
                      <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                        lock
                      </span>
                    </button>
                  </div>

                  <div className="form-group">
                    <textarea
                      aria-invalid={Boolean(
                        JobPostValidation?.jobDescription?.status === false
                      )}
                      className="form-control rounded-0 modalDisapprove-textarea"
                      id="exampleFormControlTextarea2"
                      name="jobDescription"
                      rows="7"
                      value={jobDetails?.jobDescription}
                      onChange={(e) =>
                        setJobDetailsValue("jobDescription", e.target.value)
                      }
                      onBlur={(e) => {
                        setValidationValue(
                          "jobDescription",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    ></textarea>
                  </div>
                  <small className="text-danger">
                    {JobPostValidation?.jobDescription?.message
                      ? `Description ${JobPostValidation?.jobDescription?.message}`
                      : ""}
                  </small>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setPostJob2Modal(false);
                    setJobPostValidation({});
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-light otp-btn postjob-btn mx-4"
                  onClick={() => {
                    BackValidation();
                  }}
                >
                  Back
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                  onClick={() => {
                    FinalPublishValidation();
                  }}
                >
                  Publish
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        open={AddAnswerModal}
        onClickOutside={() => {
          setAddAnswerModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Add an Answer
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content ">
                <div className="col-lg-11">
                  <div className="modalAnswer">
                    <div className="form-group my-auto">
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="12"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn mx-3"
                  onClick={() => {
                    setAddAnswerModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn mx-3">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        open={ClosingModal}
        onClickOutside={() => {
          setClosingModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Reason of Closing
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-5">
                  <div className="google-input">
                    <label htmlFor="template-input" className="z-index-value">
                      Reason
                    </label>
                    <div className="dropdown-container">
                      <Select
                        options={optionList}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "6px",
                          zIndex: 3,
                          colors: {
                            ...theme.colors,
                            primary25: "#DFF1F1",
                            primary: "#00ABAD",
                          },
                        })}
                        styles={{
                          menu: (provided, state) => ({
                            ...provided,
                            zIndex: "5 !important",
                          }),
                        }}
                        placeholder="Choose your reason"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isMulti
                      />
                    </div>
                  </div>
                  <div className="modalAnswer mt-2">
                    <label for="exampleFormControlTextarea2">
                      Reason Details
                    </label>
                    <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button
                        type="button"
                        className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                      >
                        Rich Text Editor
                        <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                          lock
                        </span>
                      </button>
                    </div>

                    <div className="form-group">
                      <textarea
                        className="form-control rounded-0 modalDisapprove-textarea"
                        id="exampleFormControlTextarea2"
                        rows="7"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setClosingModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        open={RejectionModal}
        onClickOutside={() => {
          setRejectionModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Reason of Rejection
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-5">
                  <div className="google-input">
                    <label htmlFor="template-input" className="z-index-value">
                      Reason
                    </label>
                    <div className="dropdown-container">
                      <Select
                        options={optionList}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "6px",
                          zIndex: 3,
                          colors: {
                            ...theme.colors,
                            primary25: "#DFF1F1",
                            primary: "#00ABAD",
                          },
                        })}
                        styles={{
                          menu: (provided, state) => ({
                            ...provided,
                            zIndex: "5 !important",
                          }),
                        }}
                        placeholder="Choose your reason"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isMulti
                      />
                    </div>
                  </div>
                  <div className="modalAnswer mt-2">
                    <label for="exampleFormControlTextarea2">
                      Reason Details
                    </label>
                    <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button
                        type="button"
                        className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                      >
                        Rich Text Editor
                        <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                          lock
                        </span>
                      </button>
                    </div>

                    <div className="form-group">
                      <textarea
                        className="form-control rounded-0 modalDisapprove-textarea"
                        id="exampleFormControlTextarea2"
                        rows="7"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setRejectionModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn">
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        open={SendMessageModal}
        onClickOutside={() => {
          setSendMessageModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Send Message
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-5">
                  <div className="google-input"></div>
                  <div className="modalAnswer mt-2">
                    <label for="exampleFormControlTextarea2">
                      To : <b>Manokaran K</b>
                    </label>
                    <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button
                        type="button"
                        className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                      >
                        Rich Text Editor
                        <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                          lock
                        </span>
                      </button>
                    </div>

                    <div className="form-group">
                      <textarea
                        className="form-control rounded-0 modalDisapprove-textarea"
                        id="exampleFormControlTextarea2"
                        rows="7"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setSendMessageModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        open={UpdateStatusModal}
        onClickOutside={() => {
          setUpdateStatusModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Update Status
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                <div className="col-lg-5">
                  <div className="google-input"></div>
                  <div className="modalAnswer mt-2">
                    <label for="exampleFormControlTextarea2">
                      To : <b>Manokaran K</b>
                    </label>
                    <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button
                        type="button"
                        className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1"
                      >
                        Rich Text Editor
                        <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">
                          lock
                        </span>
                      </button>
                    </div>

                    <div className="form-group">
                      <textarea
                        className="form-control rounded-0 modalDisapprove-textarea"
                        id="exampleFormControlTextarea2"
                        rows="7"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setUpdateStatusModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div>
                {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                <button className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={approveModal}
        onClickOutside={() => {
          setApproveModal(false);
        }}
      >
        <div className="modal-content w-75  m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px;",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <p className=" text-center">
                    Your account still in pending for approval.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center border-0 mb-3">
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                  onClick={() => {
                    setApproveModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
