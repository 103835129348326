import { useContext, useEffect, useState } from 'react';
import { getFeatureFlags } from '../../components/Api/common/featureFlags';

import FeatureContext from '../../context';

const useFeatureToggle = () => {
  const featureContext = useContext(FeatureContext);

  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [loading, setloading] = useState(true);

  useEffect(() => {
    if (!loading) return

    getFeatureFlags().then((response) => {
      setResponse(response.data);
    }).catch((error) => {
      setError(error);
    }).finally(() => {
      setloading(false);
    });
  }, [loading]);


  if (!error && !loading) {
    return {
      ...featureContext,
      ...response
    }
  }

  return featureContext;
}

export default useFeatureToggle;