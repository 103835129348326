// let hostLocal = "http://192.168.1.8:";

// export const url = hostLocal + 7201;
// export const url_company_details = hostLocal + 7101;
// export const url_job_details = hostLocal + 7001;
// export const url_master_details = hostLocal + 8301;
// export const CHAT_BACKEND_URL = hostLocal + 7401;
// export const url_document_upload = hostLocal + 7801;

//PRODUCTION

let host = "https://alpha.eadjobs.us";

export const url = host;
export const url_company_details = host;
export const url_job_details = host;
export const url_master_details = host;
export const CHAT_BACKEND_URL = host;
export const url_document_upload = host;
