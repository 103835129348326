import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import DashboardLogo from "../images/dashboard-logo.png";
import { useDispatch, useSelector } from "react-redux";
// import { logOutSuccess } from '../Store/auth/action';
import { Logout } from "../components/Api/Api";
import { logOutSuccess } from "../components/Store/auth/action";
export default function NavbarAdmin(props) {


  //state value
  const state = useSelector((state) => state);

  //navigate

  const naviGation = useNavigate();

  //logout

  const dispatch = useDispatch();
  const handleSubmit = async (data) => {
    const token = state?.auth?.auth?.authKey;
    const response = await Logout(token);
    if (response && response.statusCode === 200) {
      dispatch(logOutSuccess(data));
      naviGation("/");
    }
  };
  return (
    <div>
      <div className="topbar">
        <div className="topbar-header">
          <div className="dashboard-logo d-flex align-items-center justify-content-center">
            <NavLink to={"/ProfileEmployee"}>
              <img className="logo-dashboard-img " src={DashboardLogo} alt="" />
            </NavLink>
          </div>
          <div className="dashboard-header d-flex align-items-center  ">
            <div className="d-flex mx-3 justify-content-between center-d-flex-content">
              <div className="dashboard-header-left my-auto ">
                <h3 className="font-weight-bold">{props.title}</h3>
              </div>
              <div className="dashboard-header-right my-auto ">
                <div className="form-inline my-2 my-lg-0">
                  <div className="progress-profile2 " onClick={() => naviGation('/Admin/Profile')}>
                    <span className="title timer" data-from="0" data-to="85" data-speed="1800">
                      CA
                    </span>
                    <div className="overlay"></div>
                    <div className="left"></div>
                    <div className="right"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar">
        <ul>
          <NavLink to="/Admin/JobSeekers">
            <span className="material-symbols-rounded">plagiarism</span>
            <li>Jobseekers</li>
          </NavLink>
          <NavLink to="/Admin/Companies">
            <span className="material-symbols-rounded">apartment</span>
            <li>Companies</li>
          </NavLink>
          <NavLink to="/Admin/Admins">
            <span className="material-symbols-rounded">manage_accounts</span>
            <li>Admins</li>
          </NavLink>
          <NavLink to="/Admin/MasterData">
            <span className="material-symbols-rounded">description</span>
            <li>Master Data</li>
          </NavLink>
          <NavLink to="/Admin/Jobs">
            <span className="material-symbols-rounded">contacts</span>
            <li>Jobs</li>
          </NavLink>
          <NavLink to="/Admin/Profile">
            <span className="material-symbols-rounded">account_circle</span>
            <li>Profile</li>
          </NavLink>
          
          <NavLink to="" style={{ backgroundColor: "white", color: "black" }} onClick={handleSubmit}>
            <span className="material-symbols-rounded">logout</span>
            <li>Logout</li>
          </NavLink>
        </ul>
      </div>
    </div>
  );
}
