import React, { useState, useCallback, useEffect } from "react";
import NavbarAdmin from "../NavbarAdmin";
import MUIDataTable from "mui-datatables";
import { GetAllJobUsersListByType } from "../../components/Api/Api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
function JobSeekers() {
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;

  const [jobseekersList, setJobSeekersList] = useState([]);

  const FetchAllJobSeekersList = useCallback(async () => {
    if (!authKey) return;

    const Query = `actorType=Employee&authKey=${authKey}`;
    const Response = await GetAllJobUsersListByType(Query);
    if (Response && Response.statusCode === 200) {
      setJobSeekersList(Response.data);
    }
  }, [authKey]);
  
  useEffect(() => {
    FetchAllJobSeekersList();
    return () => {};
  }, [FetchAllJobSeekersList]);

  const columns = [
    {
      name: "userProfileId",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "fullName",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <div
                className="btn"
                onClick={() =>
                  navigate(
                    `/Admin/JobSeekers/EditJobseekers/${tableMeta.rowData[0]}`
                  )
                }
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    { name: "emailId", label: "Email Id" },
    { name: "mobileNumber", label: "Mobile Number" },
    { name: "status", label: "Status" },
  ];
  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
  };
  return (
    <div className="dashboard-bg">
      <NavbarAdmin />
      <div className="main">
        <div className="chat-section scroll-content">
          <MUIDataTable
            title={"Jobseekers List"}
            data={jobseekersList}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>
  );
}

export default JobSeekers;
