import React, { useState, useEffect } from "react";
import Navbar from "./navbar";
import Roboimg from "../images/roboimg.png";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { ReSendotp, CreateUserAccount, VerifyOtp } from "./Api/Api";
import { loginSuccess } from "./Store/auth/action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NonEmptyValidation } from "./Store/validate";
export default function Verifyemployee() {
  // Navigation
  const naviGation = useNavigate();
  //setOtp
  const [code, setOtp] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  //Otp Submit
  const handleChange = (code) => {
    setOtp(code);
    setValidation({});
  };
  // state values
  const state = useSelector((state) => state);

  //Login User
  const dispatch = useDispatch();
  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };
  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //Resent
  const Resent = async () => {
    setOtp(null);
    const Payload = {
      emailId: state.auth.registerData.email,
      verificationType: "Account Creation",
    };

    const response = await ReSendotp(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setMinutes(0);
      setSeconds(59);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  // SKIP
  const Skip = async () => {
    const Payload = {
      emailId: state.auth.registerData.email,
      actorType: "Employee",
      password: state.auth.registerData.password,
      fullName: state.auth.registerData.fullname,
      displayName: state.auth.registerData.displayName,
      mobileNumber: state?.auth?.registerData?.phoneNumber
        ? state?.auth?.registerData?.phoneNumber
        : "",
      loginType: "Email",
    };

    const response = await CreateUserAccount(Payload);
    if (response && response.statusCode === 200) {
      dispatch(loginSuccess(response.data, response?.data?.authKey));
      naviGation({
        pathname: "/ProfileEmployee",
        search: `tab=Personal Details`,
      });
      showToastSuccessMsg(response.message);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  // login api integration

  const Login = async () => {
    const Payload = {
      emailId: state.auth.registerData.email,
      actorType: "Employee",
      password: state.auth.registerData.password,
      fullName: state.auth.registerData.fullname,
      displayName: state.auth.registerData.displayName,
      mobileNumber: state?.auth?.registerData?.phoneNumber
        ? state?.auth?.registerData?.phoneNumber
        : "",
      loginType: "Email",
    };
    const response = await CreateUserAccount(Payload);

    if (response && response.statusCode === 200) {
      dispatch(loginSuccess(response.data, response?.data?.token));
      showToastSuccessMsg(response.message);
      naviGation({
        pathname: "/ProfileEmployee",
        search: `tab=Personal Details`,
      });
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };
  const [validation, setValidation] = useState({});

  // final submit
  const Submit = async (data) => {
    data.preventDefault();
    let validate = {};
    validate.code = NonEmptyValidation(code);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        emailId: state.auth.registerData.email,
        verificationType: "Account Creation",
        otp: parseInt(code),
      };
      const response = await VerifyOtp(Payload);
      if (response && response.statusCode === 200) {
        Login();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      console.log("Error while verify otp");
    }
  };

  return (
    <div>
      <div>
        <Navbar />
        <div className="Banner d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-5">
                <div className="section1 section1-regEmp my-5 p-4">
                  <img className="" src={Roboimg} alt="" />
                  <h1>
                    Hi {state?.auth?.registerData?.fullname}!
                    <br /> lets verify your account
                  </h1>
                  <p>
                    Please use the verification code sent to
                    <br /> {state?.auth?.registerData?.email} from
                    “quantum@eadjobs.us”,
                    <br /> check your inbox / junk / spam folders
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-7 m-auto">
                <div className="section2 my-5 pb-3">
                  <h5 className="text-left font-weight-bold  mx-5">
                    Verify your email ID
                  </h5>
                  <p className="text-left  mx-5">
                    Enter the code we just sent to
                  </p>
                  <p className="text-left font-weight-bold  mx-5">
                    {state?.auth?.registerData?.email}&nbsp;
                    <span
                      className="fgtpwd"
                      style={{ cursor: "pointer" }}
                      onClick={() => naviGation("/RegisterEmployee")}
                    >
                      Edit
                    </span>
                  </p>
                  {/* <p className='text-left my-3 mx-5'>Or verify method with your phone number</p>
                              <h6 className='text-left my-3 mx-5'><span className='fgtpwd'>+1 (772) 934-9412</span></h6> */}
                  <form className="my-4 mx-5" onSubmit={(e) => Submit(e)}>
                    <div className="form-container">
                      <div className="verification-code--inputs">
                        <OtpInput
                          className="otpInput"
                          value={code}
                          onChange={handleChange}
                          numInputs={6}
                          separator={<span style={{ width: "8px" }}></span>}
                          isInputNum={true}
                          shouldAutoFocus={true}
                          inputStyle={{
                            border: "1px solid #CFD3DB",
                            borderRadius: "8px",
                            width: "40px",
                            height: "40px",
                            fontSize: "12px",
                            color: "#000",
                            fontWeight: "400",
                            caretColor: "blue",
                          }}
                          focusStyle={{
                            border: "1px solid black ",
                            outline: "none",
                          }}
                        />
                      </div>
                      <small className="text-danger">
                        {validation?.code?.message
                          ? `OTP ${validation?.code?.message}`
                          : ""}
                      </small>
                      {/* <p className="text-danger">{errors.otp?.message}</p> */}

                      {seconds > 0 || minutes > 0 ? (
                        <div className="top-12">
                          Resend OTP in&nbsp;
                          <span className="text-green">
                            {minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                          </span>
                        </div>
                      ) : (
                        <div className="top-12">
                          Didn't receive code?&nbsp;
                          <span
                            className="fgtpwd"
                            style={{ cursor: "pointer" }}
                            onClick={() => Resent()}
                          >
                            Resend
                          </span>
                        </div>
                      )}
                      <div className="google-input-btm">
                        <button
                          type="submit"
                          className="btn btn-light my-2 my-sm-0 login-btn"
                          // onClick={() => naviGation("/Profile")}
                        >
                          Verify
                        </button>
                        <span
                          className="btn btn-light my-2 my-sm-0 ml-2  postjob-btn"
                          onClick={() => Skip()}
                        >
                          Skip
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
