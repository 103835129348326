// import rootReducer from "./rootReducer";
import rootReducer from "./rootReduce"
import rootSaga from "./rootSaga";
import createSagaMiddleware from "redux-saga";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import persistStore from "redux-persist/es/persistStore";
const persistConfig = {
  key: "EAD",
  storage,
  whitelist: ["auth","device"]
  // blacklist: ["packages", "posts","notifications"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({ reducer: persistedReducer, middleware: [thunk, sagaMiddleware], devTools: process.env.NODE_ENV !== "production" });
const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export { store, persistor };
