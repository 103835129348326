import React from "react";
import Navbar from "./navbar";
import Roboimg from "../images/roboimg.png";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SendOtp } from "./Api/Api";
import { registerDetails } from "./Store/auth/action";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ForgotPassword() {
  // state
  // const state = useSelector((state) => state);
  // dispatch
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const {
    register,
    handleSubmit,
    // control,
    formState: { errors },
  } = useForm();

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };
  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };
  // handle submit
  const onSubmit = async (data) => {
    const Payload = {
      emailId: data.email,
      verificationType: "Forgot Password",
    };

    const response = await SendOtp(Payload);
    if (response && response.statusCode === 200) {
      dispatch(registerDetails(data));
      showToastSuccessMsg(response.message);
      //setSuccessModal(true);
      navigation("/EmployeOtp");
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      // setFailureModal(true);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="Banner d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-5">
              <div className="section1 section1-regEmp my-3 p-4">
                <img className="" src={Roboimg} alt="" />
                <h1>
                  Welcome! I'm Quantum,
                  <br />
                  your personal recruiter.
                </h1>
                <p>
                  With a personal recruiter, you'll get <br /> matched to roles
                  that are right for you.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-7 m-auto">
              <div className="section2 my-5 pb-3">
                <h5 className="text-left font-weight-bold  mx-5">
                  Forgot Password
                </h5>
                <h6 className="text-left  mx-5">
                  Already have an account?{" "}
                  <span
                    className="reg-text-clr"
                    onClick={() => navigation("/Login")}
                  >
                    Login
                  </span>
                </h6>
                <form className="my-4 mx-5" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-container">
                    <div className="google-input">
                      <input
                        autoComplete="off"
                        type="text"
                        id="emailId"
                        placeholder="Enter your email ID"
                        {...register("email", {
                          required: "Email is Required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please enter a valid email",
                          },
                        })}
                      />
                      <label htmlFor="template-input">
                        Registered Email ID
                      </label>
                    </div>
                    <p className="text-danger">{errors.email?.message}</p>
                    <div className="google-input-btm">
                      <button
                        type="submit"
                        className="btn btn-light my-2 my-sm-0 login-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
