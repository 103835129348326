import { actionTypes } from "./action";

export const initState = {
  isLoggedIn: false,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        ...{ auth: action.payload, isLoggedIn: true, registerData: {} },
      };
    case actionTypes.UPDATE_USER_DETAILS_KEY:
      return {
        ...state,
        ...{ auth: { ...state.auth, userDetails: { ...state.auth.userDetails, ...action.payload } } },
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        ...{ auth: {}, isLoggedIn: false, registerData: {} },
      };
    case actionTypes.REGISTRATION_DETAILS:
      return {
        ...state,
        ...{ registerData: action.payload },
      };
    case actionTypes.DELETE_DETAILS:
      return {
        ...{ registerData: {} },
      };

    default:
      return state;
  }
}
export default reducer;
