import React, { useState, useCallback, useEffect } from "react";
import {
  CreateEmployeeSkills,
  GetCompanyDetail,
  GetMasterDatasByType,
  UpdateCompanyDetail,
} from "../../components/Api/Api";
import {
  StringValidation,
  NumericRangeValidation,
  CompanyNameValidation,
  DateValidation,
  URLValidation,
} from "../../components/Store/validate";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreatableSelect from "react-select/creatable";
import { useSelector } from "react-redux";

const CompanyDetails = () => {
  const [CompanyDetailsValidation, setCompanyDetailsValidation] = useState({});
  let host = window.location.href;
  let url = host?.split("/");
  // state values
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };
  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //get industry

  const [companyIndustryData, setCompanyIndustryData] = useState([]);

  const fetchIndustry = useCallback(async () => {
    if (!authKey) return;

    const Query = `authKey=${authKey}&masterDataType=industry&status=Active&restrictionLevel=1&companyId=${url[7]}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setCompanyIndustryData(mappingData);
    }
  }, [authKey, url]);

  useEffect(() => {
    fetchIndustry();
  }, [fetchIndustry]);

  const setValidationValue = (key, value) => {
    setCompanyDetailsValidation({ ...CompanyDetailsValidation, [key]: value });
  };

  const handleCreateCompanyIndustry = async (string) => {
    const valid = CompanyNameValidation(string);
    setValidationValue("companyIndustryId", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "industry",
        name: string,
        companyId: url[7],
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);

      if (response && response.statusCode === 200) {
        fetchIndustry();
        setCompanyDetailsValue("companyIndustryId", {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        });
      }
    }
  };

  //get revenue

  const [revenueData, setRevenueData] = useState([]);

  const fetchDataRevenue = useCallback(async () => {
    if (!authKey) return;

    const Query = `authKey=${authKey}&masterDataType=company_revenue&status=Active&restrictionLevel=1&companyId=${url[7]}`;

    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setRevenueData(mappingData);
    }
  }, [authKey, url]);

  useEffect(() => {
    fetchDataRevenue();
  }, [fetchDataRevenue]);

  const handleCreateRevenue = async (string) => {
    const valid = NumericRangeValidation(string);
    setValidationValue("revenueId", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "company_revenue",
        name: string,
        companyId: url[7],
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchDataRevenue();
        setCompanyDetailsValue("revenueId", {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        });
      }
    }
  };

  //get company size

  const [companySize, setCompanySize] = useState([]);

  const fetchData1 = useCallback(async () => {
    if (!authKey) return;

    const Query = `authKey=${authKey}&masterDataType=company_size&status=Active&restrictionLevel=1&companyId=${url[7]}`;
    const getEmployeeSkills = await GetMasterDatasByType(Query);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setCompanySize(mappingData);
    }
  }, [authKey, url]);

  useEffect(() => {
    fetchData1();
  }, [fetchData1]);

  const handleCreateCompanySize = async (string) => {
    const valid = NumericRangeValidation(string);
    setValidationValue("companySizeId", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "company_size",
        name: string,
        companyId: url[7],
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchData1();
        setCompanyDetailsValue("companySizeId", {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        });
      }
    }
  };

  const [createdCompanyDetails, setCreatedCompanyDetails] = useState({});
  // get and update company details api integration

  const setCompanyDetailsValue = (key, value) => {
    setCreatedCompanyDetails({ ...createdCompanyDetails, [key]: value });
    if (CompanyDetailsValidation[key]) delete CompanyDetailsValidation[key];
  };

  const fetchCompanyDetails = useCallback(async () => {
    if (!authKey) return;

    const companyId = url[7];
    const getCompanyDetails = await GetCompanyDetail(authKey, companyId);
    if (getCompanyDetails && getCompanyDetails.statusCode === 200) {
      setCreatedCompanyDetails(getCompanyDetails.data);
    }
  }, [authKey, url]);

  useEffect(() => {
    fetchCompanyDetails();
  }, [fetchCompanyDetails]);

  const handleCompanyUpdate = async () => {
    const token = state?.auth?.auth?.authKey;
    const getCompanyDetails = await GetCompanyDetail(token, url[7]);
    let Payload = {
      ceoName: createdCompanyDetails.ceoName,
      companyLogo: createdCompanyDetails.companyLogo
        ? createdCompanyDetails.companyLogo
        : "",
      bannerImage: createdCompanyDetails.bannerImage
        ? createdCompanyDetails.bannerImage
        : "",
      founded: createdCompanyDetails.founded,
      revenueId:
        createdCompanyDetails?.revenueId?.dataId ||
        createdCompanyDetails?.revenueId,
      companyUrl: createdCompanyDetails.companyUrl,
      companyId: url[7],
      companySizeId:
        createdCompanyDetails?.companySizeId?.dataId ||
        createdCompanyDetails?.companySizeId,
      headQuarterslocation: createdCompanyDetails.headQuarterslocation,
      companyIndustryId:
        createdCompanyDetails?.companyIndustryId?.dataId ||
        createdCompanyDetails?.companyIndustryId,
    };
    if (getCompanyDetails && getCompanyDetails.statusCode === 200) {
      const response = await UpdateCompanyDetail(Payload, token);

      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        fetchCompanyDetails();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  return (
    <div>
      <div className="scroll-content">
        <div className="container">
          <div className="row d-flex  justify-content-center scroll-content">
            <div className="col-lg-10">
              <form>
                <div className="form-container py-1">
                  <div className="row ">
                    <div className="col-lg-6 px-5 pb-4 border-right">
                      <div className="google-input">
                        <input
                          autoComplete="off"
                          type="text"
                          id="ceoName"
                          aria-invalid={
                            CompanyDetailsValidation?.ceoName?.status === false
                          }
                          placeholder="Enter your CEO name"
                          onBlur={(e) => {
                            setValidationValue(
                              "ceoName",
                              StringValidation(e.target.value)
                            );
                          }}
                          value={createdCompanyDetails?.ceoName || ""}
                          onChange={(e) => {
                            setCompanyDetailsValue("ceoName", e.target.value);
                          }}
                        />
                        <label htmlFor="template-input">CEO Name</label>
                      </div>

                      <small className="text-danger">
                        {CompanyDetailsValidation?.ceoName?.message
                          ? `Ceo name ${CompanyDetailsValidation?.ceoName?.message}`
                          : ""}
                      </small>
                      <div className="google-input">
                        <input
                          autoComplete="off"
                          type="text"
                          id="founded"
                          maxLength={4}
                          aria-invalid={Boolean(
                            CompanyDetailsValidation?.founded?.status === false
                          )}
                          placeholder="Enter your Founded Year"
                          value={createdCompanyDetails?.founded || ""}
                          onChange={(e) => {
                            setCompanyDetailsValue("founded", e.target.value);
                          }}
                          onBlur={(e) => {
                            setValidationValue(
                              "founded",
                              DateValidation(e.target.value)
                            );
                          }}
                        />
                        <label htmlFor="template-input">Founded</label>
                      </div>
                      <small className="text-danger">
                        {CompanyDetailsValidation?.founded?.message
                          ? `Founded year ${CompanyDetailsValidation?.founded?.message}`
                          : ""}
                      </small>
                      <div className="google-input">
                        <input
                          autoComplete="off"
                          type="text"
                          id="companyUrl"
                          placeholder="Enter your website link"
                          aria-invalid={Boolean(
                            CompanyDetailsValidation?.companyUrl?.status ===
                              false
                          )}
                          value={createdCompanyDetails?.companyUrl || ""}
                          onChange={(e) => {
                            setCompanyDetailsValue(
                              "companyUrl",
                              e.target.value
                            );
                          }}
                          onBlur={(e) => {
                            setValidationValue(
                              "companyUrl",
                              URLValidation(e.target.value)
                            );
                          }}
                        />
                        <label htmlFor="template-input">Company URL</label>
                      </div>
                      <small className="text-danger">
                        {CompanyDetailsValidation?.companyUrl?.message
                          ? `Company url ${CompanyDetailsValidation?.companyUrl?.message}`
                          : ""}
                      </small>
                      <div className="google-input">
                        <label
                          htmlFor="companyIndustryId"
                          className="z-index-value"
                        >
                          Industry
                        </label>

                        <div className="dropdown-container">
                          <CreatableSelect
                            options={companyIndustryData}
                            id="companyIndustryId"
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: "6px",
                              zIndex: 3,
                              colors: {
                                ...theme.colors,
                                primary25: "#DFF1F1",
                                neutral20:
                                  CompanyDetailsValidation?.companyIndustryId
                                    ?.status === false
                                    ? "red"
                                    : "#00ABAD", // border color
                                primary:
                                  CompanyDetailsValidation?.companyIndustryId
                                    ?.status === false
                                    ? "red"
                                    : "#00ABAD", // border color when focus
                              },
                            })}
                            styles={{
                              menu: (provided, state) => ({
                                ...provided,
                                zIndex: "5 !important",
                              }),
                            }}
                            onInputChange={() => {
                              if (CompanyDetailsValidation["companyIndustryId"])
                                delete CompanyDetailsValidation[
                                  "companyIndustryId"
                                ];
                            }}
                            placeholder="Select your company's revenue"
                            value={{
                              value:
                                createdCompanyDetails?.companyIndustryId
                                  ?.value ||
                                createdCompanyDetails?.companyIndustryId ||
                                createdCompanyDetails?.companyIndustryId,
                              label:
                                createdCompanyDetails?.companyIndustryId
                                  ?.label ||
                                createdCompanyDetails?.companyIndustryName ||
                                createdCompanyDetails?.companyIndustryId,
                            }}
                            onChange={(newValue) => {
                              setCompanyDetailsValue(
                                "companyIndustryId",
                                newValue
                              );
                            }}
                            onCreateOption={(e) =>
                              handleCreateCompanyIndustry(e)
                            }
                            isClearable
                          />
                          <small className="text-danger">
                            {CompanyDetailsValidation?.companyIndustryId
                              ?.message
                              ? `Industry ${CompanyDetailsValidation?.companyIndustryId?.message}`
                              : ""}
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 px-5 pb-4">
                      <div className="google-input">
                        <label htmlFor="revenue" className="z-index-value">
                          Revenue
                        </label>

                        <div className="dropdown-container">
                          <CreatableSelect
                            options={revenueData}
                            id="revenueId"
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: "6px",
                              zIndex: 3,
                              colors: {
                                ...theme.colors,
                                primary25: "#DFF1F1",
                                neutral20:
                                  CompanyDetailsValidation?.revenueId
                                    ?.status === false
                                    ? "red"
                                    : "#00ABAD", // border color
                                primary:
                                  CompanyDetailsValidation?.revenueId
                                    ?.status === false
                                    ? "red"
                                    : "#00ABAD", // border color when focus
                              },
                            })}
                            styles={{
                              menu: (provided, state) => ({
                                ...provided,
                                zIndex: "5 !important",
                              }),
                            }}
                            onInputChange={() => {
                              if (CompanyDetailsValidation["revenueId"])
                                delete CompanyDetailsValidation["revenueId"];
                            }}
                            placeholder="Select your company's revenue"
                            value={{
                              value:
                                createdCompanyDetails?.revenueId?.value ||
                                createdCompanyDetails?.revenueId ||
                                createdCompanyDetails?.revenueId,
                              label:
                                createdCompanyDetails?.revenueId?.label ||
                                createdCompanyDetails?.revenueName ||
                                createdCompanyDetails?.revenueId,
                            }}
                            onChange={(newValue) => {
                              setCompanyDetailsValue("revenueId", newValue);
                              // setRevenueDataValue(newValue);
                            }}
                            onCreateOption={handleCreateRevenue}
                            isClearable
                          />
                          <small className="text-danger">
                            {CompanyDetailsValidation?.revenueId?.message
                              ? `Revenue ${CompanyDetailsValidation?.revenueId?.message}`
                              : ""}
                          </small>
                        </div>
                      </div>

                      <div className="google-input">
                        <label htmlFor="companySize" className="z-index-value">
                          Company Size
                        </label>
                        <div className="dropdown-container">
                          <CreatableSelect
                            options={companySize}
                            id="companySizeId"
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: "6px",
                              zIndex: 3,
                              colors: {
                                ...theme.colors,
                                primary25: "#DFF1F1", // selected option background color
                                neutral20:
                                  CompanyDetailsValidation?.companySizeId
                                    ?.status === false
                                    ? "red"
                                    : "#00ABAD", // border color
                                primary:
                                  CompanyDetailsValidation?.companySizeId
                                    ?.status === false
                                    ? "red"
                                    : "#00ABAD", // border color when focus
                              },
                            })}
                            styles={{
                              menu: (provided, state) => ({
                                ...provided,
                                zIndex: "5 !important",
                              }),
                            }}
                            onInputChange={() => {
                              if (CompanyDetailsValidation["companySizeId"])
                                delete CompanyDetailsValidation[
                                  "companySizeId"
                                ];
                            }}
                            placeholder="Enter your Company Size"
                            value={{
                              value:
                                createdCompanyDetails?.companySizeId?.value ||
                                createdCompanyDetails?.companySizeId ||
                                createdCompanyDetails?.companySizeId,
                              label:
                                createdCompanyDetails?.companySizeId?.label ||
                                createdCompanyDetails?.companySizeName ||
                                createdCompanyDetails?.companySizeId,
                            }}
                            onChange={(newValue) =>
                              setCompanyDetailsValue("companySizeId", newValue)
                            }
                            onCreateOption={handleCreateCompanySize}
                            isClearable
                          />
                          <small className="text-danger">
                            {CompanyDetailsValidation?.companySizeId?.message
                              ? `Company size ${CompanyDetailsValidation?.companySizeId?.message}`
                              : ""}
                          </small>
                        </div>
                      </div>
                      <div className="google-input">
                        <label
                          htmlFor="headQuarterslocation"
                          className="z-index-value"
                        >
                          Headquarters Location
                        </label>
                        <div className="google-input">
                          <input
                            autoComplete="off"
                            type="text"
                            aria-invalid={Boolean(
                              CompanyDetailsValidation?.headQuarterslocation
                                ?.status === false
                            )}
                            id="headQuarterslocation"
                            placeholder="Enter your Headquarters Location"
                            value={
                              createdCompanyDetails?.headQuarterslocation || ""
                            }
                            onChange={(e) => {
                              setCompanyDetailsValue(
                                "headQuarterslocation",
                                e.target.value
                              );
                            }}
                            onBlur={(e) => {
                              setValidationValue(
                                "headQuarterslocation",
                                StringValidation(e.target.value)
                              );
                            }}
                          />
                        </div>
                        <small className="text-danger">
                          {CompanyDetailsValidation?.headQuarterslocation
                            ?.message
                            ? `Headquarters location ${CompanyDetailsValidation?.headQuarterslocation?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-12 text-center">
                      <div className="google-input">
                        <button
                          type="button"
                          className="btn btn-light my-2 my-sm-0 login-btn"
                          onClick={() =>
                            handleCompanyUpdate()
                          }
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default CompanyDetails;
