import { actionTypes } from "./action";

export const initState = {
    deviceDetails : ""

};

function reducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.DEVICE_INFORMATION:
      return {
        ...state,
        ...{device: action.payload},
      };
    default:
      return state;
  }
}
export default reducer;