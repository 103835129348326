import React, { useState, useCallback } from "react";
import NavbarDashboard from "./NavbarDashboard";
import CustomModal from "../CustomModal";
import { useSelector } from "react-redux";
import {
  CreateEmployeeSkills,
  CreateFAQDetails,
  DeleteFAQDetails,
  GetFAQ,
  GetFAQDetails,
  GetLabelFaqFilter,
  GetMasterDatasByType,
  UpdateFAQDetails,
} from "../Api/Api";
import { useEffect } from "react";
import Roboimg2 from "../../images/roboimg-outerborder.png";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { ContentState, convertToRaw, EditorState } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CreatableSelect from "react-select/creatable";
import {
  AlphaNumericValidation,
  NonEmptyObjectValidation,
  NonEmptyValidation,
  StringValidation,
} from "../Store/validate";
// import draftToHtml from "draftjs-to-html";
// let htmlToDraft = null;
// if (typeof window === "object") {
//   htmlToDraft = require("html-to-draftjs").default;
// }

export default function FAQ() {
  const [addFaqModal, setaddFaqModal] = useState(false);
  const [editFaqModal, seteditFaqModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [faqId, setFaqId] = useState("");
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const companyId = state?.auth?.auth?.userDetails?.companyId;

  const [faqDetails, setFaqDetails] = useState([]);
  const [createFaq, setCreateFaq] = useState({});
  const [faqData, setFaqdata] = useState([]);
  const [validation, setValidation] = useState({});
  const [labelFilter, setLabelFilter] = useState("");

  // const refresh = (setFaqDetails) => {}
  // const [toggleIcon,setToggleIcon] = useState("Text Area");

  // data.filter((e) => e.text)

  // const handleEditorChange = (state) => {
  //   let isNotEmpty = state && state.getCurrentContent().hasText();
  //   if (isNotEmpty) {
  //     let html = draftToHtml(convertToRaw(state.getCurrentContent()));
  //     setValue("answer", html);
  // editorState = html;
  //   }
  // };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };
  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };
  const setValue = (key, value) => {
    setCreateFaq({ ...createFaq, [key]: value });
    if (validation[key]) delete validation[key];
  };
  const setValidationValue = (key, value) => {
    setValidation({ ...validation, [key]: value });
  };

  //Find all
  const handleallFAQ = useCallback(async () => {
    if (!authKey || !companyId) return;

    const response = await GetFAQDetails(companyId, authKey);
    if (response && response.statusCode === 200) {
      setFaqDetails(response.data);
    } else {
      setFaqDetails([]);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    handleallFAQ();
    return () => {};
  }, [handleallFAQ]);
  
  const setFaqdataValue = (key, value) => {
    setFaqdata({ ...faqData, [key]: value });
    if (validation[key]) delete validation[key];
  };

  //Find
  const handleFAQ = async (id) => {
    const token = state?.auth?.auth?.authKey;
    const response = await GetFAQ(id, token);
    if (response && response.statusCode === 200) {
      setFaqdata(response.data);
      // let cmsDescription = EditorState.createEmpty();
      // if(dummy.answer){
      //   let blocksFromHtml = htmlToDraft(dummy.answer);
      //   let { contentBlocks, entityMap } = blocksFromHtml;
      //   let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      //   cmsDescription = EditorState.createWithContent(contentState);
      // }
      // dummy.answer = cmsDescription;
    }
  };
  const [labelTitle, setLabelTitle] = useState([]);
  const fetchLabelTitle = useCallback(async () => {
    if (!authKey || !companyId) return;

    const Query = `authKey=${authKey}&masterDataType=label&status=Active&restrictionLevel=1&companyId=${companyId}`;

    const response = await GetMasterDatasByType(Query);
    if (response && response.statusCode === 200) {
      let data1 = response.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          masterDataId: e.masterDataId,
        };
      });
      setLabelTitle(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchLabelTitle();
  }, [fetchLabelTitle]);

  const handleCreateLabelTitle = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("label", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "label",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchLabelTitle();
        const data = {
          value: response.data.name,
          label: response.data.name,
          masterDataId: response.data.masterDataId,
        };
        if (createFaq?.label?.length) {
          let setNewData = createFaq?.label;
          setNewData.push(data);
          setValue("label", setNewData);
        } else {
          setValue("label", [data]);
        }
      }
    }
  };
  //Delete
  const handleDeleteFaq = async (id) => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      faqId: id,
    };
    const response = await DeleteFAQDetails(Payload, token);
    if (response && response.statusCode === 200) {
      setLabelFilter("");
      setDeleteModal(false);
      showToastSuccessMsg(response.message);
      handleallFAQ();
    } else {
      showToastErrorMsg(response.message);
    }
  };

  const handleCreateFaq = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.label = NonEmptyObjectValidation(createFaq?.label);
    validate.question = StringValidation(createFaq?.question);
    validate.answer = NonEmptyValidation(createFaq?.answer);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        companyId: state?.auth?.auth?.userDetails?.companyId,
        question: createFaq.question,
        answer: createFaq.answer,
        label:
          createFaq?.label?.masterDataId ||
          createFaq?.label?.map((e) => e.masterDataId.toString()),
      };
      const response = await CreateFAQDetails(Payload, token);
      if (response && response.statusCode === 200) {
        setLabelFilter("");
        setCreateFaq([]);
        setValidation({});
        setaddFaqModal(false);
        showToastSuccessMsg(response.message);
        handleallFAQ();
      } else {
        showToastErrorMsg(response.message);
      }
    } else {
      console.log("Error while add FAQ");
    }
  };

  //Update
 

  // const CheckValidationUpdate = () => {
  //   let Status = true,
  //     validdateData = {};
  //   if (!faqData.question) {
  //     validdateData.question = "Question is Required";
  //     Status = false;
  //   }
  //   if (!faqData?.label || !faqData?.label?.length) {
  //     validdateData.label = "Label is Required";
  //     Status = false;
  //   }
  //   if (!faqData.answer) {
  //     validdateData.answer = "Answer is Required";
  //     Status = false;
  //   } else if (faqData?.answer && faqData?.answer?.trim()?.length > 1000) {
  //     validdateData.answer = "Maximium 1000 characters allowed";
  //     Status = false;
  //   }
  //   setValidation({ ...validation, ...validdateData });
  //   return Status;
  // };
  const handleUpdateFaq = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.question = StringValidation(faqData.question);
    validate.label = NonEmptyObjectValidation(faqData.label);
    validate.answer = NonEmptyValidation(faqData.answer);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        faqId: faqData.faqId,
        question: faqData.question,
        answer: faqData.answer,
        label:
          faqData?.label?.masterDataId ||
          faqData?.label?.map((e) => e.masterDataId.toString()),
      };
      // Object.keys(validation).length === 3 &&

      const response = await UpdateFAQDetails(Payload, token);
      if (response && response.statusCode === 200) {
        setLabelFilter("");
        seteditFaqModal(false);
        showToastSuccessMsg(response.message);
        handleallFAQ();
      } else {
        showToastErrorMsg(response.message);
      }
    } else {
      console.log("Error while updating FAQ");
    }
  };
  const handleFilterData = async (id) => {
    const token = state?.auth?.auth?.authKey;
    const companyId = state?.auth?.auth?.userDetails?.companyId;
    const response = await GetLabelFaqFilter(id, token, 0, 25, companyId);
    if (response && response.statusCode === 200) {
      setFaqDetails(response.data);
    } else {
      showToastErrorMsg(response.message);
    }
  };

  const handleUpdateLabelTitle = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("label", valid);
    if (valid.status) {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      masterDataType: "label",
      name: string,
      userId: state?.auth?.auth?.userProfileId,
      companyId: state?.auth?.auth?.userDetails?.companyId,
      restrictionLevel: 1,
    };
    const response = await CreateEmployeeSkills(Payload, token);
    if (response && response.statusCode === 200) {
      fetchLabelTitle();
      const data = {
        value: response.data.name,
        label: response.data.name,
        masterDataId: response.data.masterDataId,
      };
      if (faqData?.label?.length) {
        let setNewData = faqData?.label;
        setNewData.push(data);
        setFaqdataValue("label", setNewData);
      } else {
        setFaqdataValue("label", [data]);
      }
    }
  }
  };

  return (
    <div className="dashboard-bg">
      <NavbarDashboard />
      <div className="main">
        <div className="faq-section  scroll-content  py-4">
          {state?.auth?.auth?.userDetails?.status === "Active" ||
          state?.auth?.auth?.userDetails?.approved === true ? (
            <div className="container">
              {/* <h2 className='d-flex justify-content-center text-green font-weight-bold'>FAQ</h2> */}
              <div className="row justify-content-center">
                <div className="col-lg-9">
                  <div className="faq-heading d-flex justify-content-between align-items-center">
                    <h5 className="font-weight-bold">
                      <span className="text-green"></span> FAQ
                    </h5>
                    <div className="d-flex">
                      {labelFilter.length > 0 ? (
                        <h6 className="my-auto mr-5">
                          <span
                            className="badge badge-info"
                            style={{ cursor: "pointer" }}
                          >
                            {labelFilter}
                          </span>
                          <span
                            className="material-symbols-rounded align-middle text-danger"
                            style={{ cursor: "pointer", fontSize: "15px" }}
                            onClick={() => {
                              setLabelFilter("");
                              handleallFAQ();
                            }}
                          >
                            cancel
                          </span>
                        </h6>
                      ) : null}
                      <button
                        className="btn btn-light my-2 my-sm-0 mr-1 login-btn"
                        onClick={() => {
                          setaddFaqModal(true);
                        }}
                      >
                        Add FAQ
                      </button>
                    </div>
                  </div>
                </div>
                {faqDetails?.faqDetails?.length < 1 ? (
                  <div className="col-lg-9 mt-4">
                    <h6 className="text-center font-weight-bold my-2">
                      <img
                        style={{
                          width: "85px",
                          textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px;",
                        }}
                        src={Roboimg2}
                        alt=""
                      />
                    </h6>
                    <br />
                    <div>
                      <h6 className="text-center font-weight-bold my-2">
                        This is the right place to Create your Company's FAQs
                      </h6>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {faqDetails?.faqDetails?.map((data, index) => {
                  return (
                    <div className="col-lg-9" key={index}>
                      <div className="faq-content">
                        <div className="faq-body mt-4">
                          <div className="d-flex justify-content-between">
                            <h6 className="font-weight-bold">
                              {data.question}
                            </h6>
                            <div className="d-flex">
                              {data?.label?.map((item, index) => {
                                return (
                                  <h6 className="mx-1" key={index}>
                                    <span
                                      className="badge badge-info px-2"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handleFilterData(item.masterDataId);
                                        setLabelFilter(item.name);
                                      }}
                                    >
                                      {item.name}
                                    </span>
                                  </h6>
                                );
                              })}
                            </div>
                          </div>
                          {/* What is the best part of working at Cognizant Technology Solutions? */}
                          <p>
                            <small>
                              Created {moment(data.createdAt).format("LL")}
                            </small>
                          </p>
                          {/* Created October 7, 2019 */}
                          <p>{data.answer}</p>
                          {/* Helping the customer’s with their issues. Working from home, but still feel part of the team. Everyone is very understanding and I learn something new every day! */}
                        </div>
                        <div className="faq-button d-flex justify-content-between align-items-center">
                          <button
                            className="btn btn-light my-2 my-sm-0 mr-1 postjob-btn"
                            onClick={() => {
                              setDeleteModal(true);
                              setFaqId(data.faqId);
                            }}
                          >
                            Delete
                          </button>
                          {/* {faqId} */}
                          <button
                            className="btn btn-light my-2 my-sm-0 mr-1 login-btn"
                            onClick={() => {
                              seteditFaqModal(true);
                              handleFAQ(data.faqId);
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="modal-body p-3 ">
              <div
                className="modalHeader"
                style={{ backgroundColor: "#ffffff" }}
              >
                <h6 className="text-center font-weight-bold my-2">
                  <img
                    style={{
                      width: "85px",
                      textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px;",
                    }}
                    src={Roboimg2}
                    alt=""
                  />
                </h6>
              </div>
              <form className="container">
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="col-lg-8 pb-3">
                    <p className=" text-center">
                      Your account is still pending for approval.
                    </p>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        open={addFaqModal}
        onClickOutside={() => {
          setaddFaqModal(false);
          setValidation({});
          setCreateFaq([]);
        }}
      >
        <div className="modal-content">
          <form>
            <div className="modal-body p-0 ">
              <div className="modalHeader">
                <h5 className="text-center font-weight-bold my-0">Add FAQ</h5>
              </div>
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                  <div className="col-lg-5">
                    <div className="google-input">
                      <input
                        autoComplete="off"
                        type="text"
                        id="template-input"
                        placeholder="Type your question"
                        aria-invalid={Boolean(
                          validation?.question?.status === false
                        )}
                        onChange={(e) => {
                          setValue("question", e.target.value);
                        }}
                        onBlur={(e) => {
                          setValidationValue(
                            "question",
                            AlphaNumericValidation(e.target.value)
                          );
                        }}
                        onInputChange={()=>{
                          if (validation["question"]) delete validation["question"]
                        }
                        }
                      />
                      <label htmlFor="template-input">Question</label>
                      <small className="text-danger">
                        {validation?.question?.message
                          ? `Question ${validation?.question?.message}`
                          : ""}
                      </small>
                    </div>
                    <div className="google-input">
                      <label htmlFor="template-input" className="z-index-value">
                        Related to
                      </label>
                      <div className="dropdown-container">
                        <CreatableSelect
                          options={labelTitle}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "6px",
                            zIndex: 3,
                            colors: {
                              ...theme.colors,
                              primary25: "#DFF1F1",

                              neutral20:
                                validation?.label?.status === false
                                  ? "red"
                                  : "#00ABAD", // border color
                              primary:
                                validation?.label?.status === false
                                  ? "red"
                                  : "#00ABAD", // border color when focus
                            },
                          })}
                          styles={{
                            menu: (provided, state) => ({
                              ...provided,
                              zIndex: "5 !important",
                            }),
                          }}
                          placeholder="Choose"
                          value={createFaq?.label}
                          onChange={(newValue) => {
                            setValue("label", newValue);
                          }}
                          onInputChange={()=>{
                            if (validation["label"]) delete validation["label"]
                          }
                          }
                          onCreateOption={handleCreateLabelTitle}
                          isClearable
                          isMulti
                        />
                      </div>
                      <small className="text-danger">
                        {validation?.label?.message
                          ? `Label ${validation?.label?.message}`
                          : ""}
                      </small>
                    </div>

                    <div className="modalAnswer mt-2">
                      <label for="exampleFormControlTextarea2">Answer</label>
                      {/* {toggleIcon === "Text Area" ?
                      <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button type="button" className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1" onClick={()=>setToggleIcon("Rich Text Editor")}>
                          Rich Text Editor
                          <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">lock</span> 
                      </button>
                      </div> : 
                      <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button type="button" className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1" onClick={()=>setToggleIcon("Text Area")}>
                          Text Area
                          {/* <span class="material-symbols-outlined">lock_open</span>                */}
                      {/* </button>
                      </div>} */}
                      {/* {toggleIcon === "Text Area" ? */}
                      <div className="form-group">
                        <textarea
                          className="form-control rounded-0 modalDisapprove-textarea"
                          id="exampleFormControlTextarea2"
                          rows="7"
                          aria-invalid={Boolean(
                            validation?.answer?.status === false
                          )}
                          onChange={(e) => {
                            setValue("answer", e.target.value);
                            // ValueValidation("answer");
                          }}
                          onBlur={(e) =>
                            setValidationValue(
                              "answer",
                              AlphaNumericValidation(e.target.value)
                            )
                          }
                        ></textarea>
                        <small className="text-danger">
                          {validation?.answer?.message
                            ? `Answer ${validation?.answer?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0">
                <div>
                  <button
                    className="btn btn-light otp-btn postjob-btn"
                    onClick={() => {
                      setaddFaqModal(false);
                      setValidation({});
                      setCreateFaq([]);
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                  <button
                    type="submit"
                    className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn"
                    onClick={(e) => handleCreateFaq(e)}
                  >
                    ADD
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <ToastContainer />
      </CustomModal>
      <CustomModal
        open={editFaqModal}
        onClickOutside={() => {
          seteditFaqModal(false);
          setValidation({});
        }}
      >
        <div className="modal-content">
          <form onSubmit={(e) => handleUpdateFaq(e)}>
            <div className="modal-body p-0 ">
              <div className="modalHeader">
                <h5 className="text-center font-weight-bold my-0">Edit FAQ</h5>
              </div>
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                  <div className="col-lg-5">
                    <div className="google-input">
                      <input
                        autoComplete="off"
                        type="text"
                        id="template-input"
                        placeholder="Type your question"
                        aria-invalid={Boolean(
                          validation?.question?.status === false
                        )}
                        value={faqData.question}
                        onChange={(e) => {
                          setFaqdataValue("question", e.target.value);
                        }}
                        onBlur={() => {
                          setValidationValue(
                            "question",
                            AlphaNumericValidation(faqData.question)
                          );
                        }}
                      />
                      <label htmlFor="template-input">Question</label>
                      <small className="text-danger">
                        {validation?.question?.message
                          ? `Question ${validation?.question?.message}`
                          : ""}
                      </small>
                    </div>
                    <div className="google-input">
                      <label htmlFor="template-input" className="z-index-value">
                        Related to
                      </label>
                      <div className="dropdown-container">
                        <CreatableSelect
                          options={labelTitle}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "6px",
                            zIndex: 3,
                            colors: {
                              ...theme.colors,
                              primary25: "#DFF1F1",
                              neutral20:
                                validation?.label?.status === false
                                  ? "red"
                                  : "#00ABAD", // border color
                              primary:
                                validation?.label?.status === false
                                  ? "red"
                                  : "#00ABAD", // border color when focus
                            },
                          })}
                          styles={{
                            menu: (provided, state) => ({
                              ...provided,
                              zIndex: "5 !important",
                            }),
                          }}
                          placeholder="Choose Job Title"
                          value={
                            faqData?.label
                              ? faqData?.label?.map((v) => {
                                  return {
                                    value: v.name ? v.name : v.label,
                                    label: v.name ? v.name : v.label,
                                    masterDataId: v.masterDataId
                                      ? v.masterDataId
                                      : v.masterDataId,
                                  };
                                })
                              : []
                          }
                          // value={{value:faqData?.label?.name||faqData?.label?.value,label:faqData?.label?.name||faqData?.label?.label}}
                          onChange={(e) => {
                            setFaqdataValue("label", e);
                          }}
                          onInputChange={()=>{
                            if (validation["label"]) delete validation["label"]
                          }
                          }
                          onCreateOption={handleUpdateLabelTitle}
                          isClearable
                          isMulti
                        />
                        <small className="text-danger">
                          {validation?.label?.message
                            ? `Label ${validation?.label?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="modalAnswer mt-2">
                      <label for="exampleFormControlTextarea2">Answer</label>
                      {/* {toggleIcon === "Text Area" ?
                      <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button type="button" className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1" onClick={()=>setToggleIcon("Rich Text Editor")}>
                          Rich Text Editor
                          <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">lock</span> 
                      </button>
                      </div> : 
                      <div className="modalDisapprove-details d-flex  justify-content-end ">
                      <button type="button" className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1" onClick={()=>setToggleIcon("Text Area")}>
                          Text Area
                       {/* <span class="material-symbols-outlined modalDisapprove-lockicon mx-1">lock_open</span>                */}
                      {/* </button>
                      </div>} */}
                      {/* {toggleIcon === "Text Area" ? */}
                      <div className="form-group">
                        <textarea
                          className="form-control rounded-0 modalDisapprove-textarea"
                          id="exampleFormControlTextarea2"
                          rows="7"
                          value={faqData.answer}
                          aria-invalid={Boolean(
                            validation?.answer?.status === false
                          )}
                          onChange={(e) => {
                            setFaqdataValue("answer", e.target.value);
                          }}
                          onBlur={() => {
                            setValidationValue(
                              "answer",
                              AlphaNumericValidation(faqData.answer)
                            );
                          }}
                        ></textarea>
                        <small className="text-danger">
                          {validation?.answer?.message
                            ? `Answer ${validation?.answer?.message}`
                            : ""}
                        </small>
                      </div>
                      {/* <div className="form-group">
                      //   <Editor editorState={faqData.answer}  handlePastedText={() => false} onEditorStateChange={(e)=>setFaqdata({ ...faqData, answer: e })} wrapperClassName="wrapper-class" editorClassName="editor-class" toolbarClassName="toolbar-class" />
                      //   {validation.answer && <small className="text-danger">{validation.answer}</small>}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0">
                <div>
                  <button
                    className="btn btn-light otp-btn postjob-btn"
                    onClick={() => {
                      seteditFaqModal(false);
                      setValidation({});
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn"
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <ToastContainer />
      </CustomModal>
      <CustomModal
        open={deleteModal}
        onClickOutside={() => {
          setDeleteModal(false);
        }}
      >
        <div className="modal-content w-75  m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px;",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <h3 className="font-weight-bold text-center">
                    Are You Sure?
                  </h3>
                  <p className=" text-center">
                    Do you really want to delete this? this process cannot be
                    undone.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center border-0 mb-3">
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                  onClick={() => {
                    setDeleteModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  style={{ padding: "8px 24px" }}
                  className="btn btn-danger my-2 my-sm-0 mr-2 "
                  onClick={() => handleDeleteFaq(faqId)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
