import React, { useEffect, useCallback, useState } from "react";
import Navbar from "./navbar";
import Roboimg from "../images/roboimg.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { CreateCompanyName, GetCompanyName, SendOtp } from "./Api/Api";
import { useDispatch, useSelector } from "react-redux";
import { registerDetails } from "./Store/auth/action";
import CreatableSelect from "react-select/creatable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  AcceptTermsValidation,
  CompanyNameValidation,
  EmailCompanyValidation,
  NonEmptyObjectValidation,
  PasswordValidation,
  PhoneNumberValidation,
  StringValidation,
} from "./Store/validate";

export default function RegisterEmployer() {
  //Navigation
  const naviGation = useNavigate();
  const [passwordStrength, setPasswordStrength] = useState("");
  // const [emailStrength, setEmailStrength] = useState("");
  const state = useSelector((state) => state);
  // const testArray = ["gmail.com", "yahoo.com", "hotmail.com"];

  const [registerDetail, setRegisterDetails] = useState({});

  const [registerValidation, setRegisterValidation] = useState({});

  const setRegisterDetailsValue = (key, value) => {
    setRegisterDetails({ ...registerDetail, [key]: value });
    if (registerValidation[key]) delete registerValidation[key];
  };

  const setRegisterValidationValue = (key, value) => {
    setRegisterValidation({ ...registerValidation, [key]: value });
  };

  const dispatch = useDispatch();
  // const location = useLocation();
  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //User Registration api call

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.companyName = NonEmptyObjectValidation(registerDetail?.companyName);
    validate.fullname = StringValidation(registerDetail?.fullname);
    validate.displayName = StringValidation(registerDetail?.displayName);
    validate.email = EmailCompanyValidation(registerDetail?.email);
    validate.phoneNumber = PhoneNumberValidation(registerDetail?.phoneNumber);
    validate.password = PasswordValidation(registerDetail?.password);
    validate.acceptTerms = AcceptTermsValidation(registerDetail?.acceptTerms);

    setRegisterValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        emailId: registerDetail.email,
        verificationType: "Account Creation",
      };

      const response = await SendOtp(Payload);
      if (response && response.statusCode === 200) {
        naviGation("/VerifyEmployer");
        dispatch(registerDetails(registerDetail));
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      console.log("Error while register as employer");
    }
  };

  const handleChange = useCallback((datas) => {
    const keys = [
      "fullname",
      "displayName",
      "email",
      "phoneNumber",
      "acceptTerms",
    ];
    let Data = {};
    if (
      state?.auth?.registerData &&
      Object.keys(state?.auth?.registerData).length
    ) {
      if (
        state?.auth?.registerData?.companyName?.dataId &&
        datas &&
        datas.length
      ) {
        const filteredValues = datas.filter(
          (cn) => cn.dataId === state?.auth?.registerData?.companyName?.dataId
        );
        if (filteredValues && filteredValues.length) {
          Data.companyName = filteredValues[0];
        }
      }
      keys.forEach((value) => {
        if (typeof state?.auth?.registerData[value] !== "undefined") {
          Data[value] = state?.auth?.registerData[value];
        }
      });

      setRegisterDetails(Data);
    }
  }, [state?.auth?.registerData]);

  //get company Name
  const [companyName, setCompanyName] = useState([]);

  const fetchCompanyName = useCallback(async () => {
    const getEmployeeSkills = await GetCompanyName();
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      const mappingData = data1.map((e) => {
        return {
          value: e.companyName,
          label: e.companyName,
          dataId: e.companyId,
        };
      });

      setCompanyName(mappingData);
      handleChange(mappingData);
    } else {
      handleChange([]);
    }
  }, [handleChange])

  useEffect(() => {
    fetchCompanyName();
  }, [fetchCompanyName]);

  const handleCreateCompanyName = async (string) => {
    const valid = CompanyNameValidation(string);
    setRegisterValidationValue("companyName", valid);
    if (valid.status) {
      const Payload = {
        companyName: string,
      };
      const response = await CreateCompanyName(Payload);
      if (response && response.statusCode === 200) {
        fetchCompanyName();
        const data = {
          value: response.data.companyName,
          label: response.data.companyName,
          dataId: response.data.companyId,
        };
        setRegisterDetails({ ...registerDetail, companyName: data });
      }
    } else {
      console.log("Error while register as employer");
    }
  };

  let url = window.location.href;
  let route = url.split("/");

  return (
    <div>
      <Navbar />
      <div className="Banner d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-5">
              <div className="section1 section1-regEmp my-4 p-4">
                <img className="mt-3" src={Roboimg} alt="" />
                <h1>
                  Hi There! Lets setup
                  <br /> your account to find
                  <br /> suitable candidates
                </h1>
                <p>
                  I’ll be sending a verification code to your
                  <br /> official email id from “quantum@eadjobs.us”,
                  <br /> please check your inbox / junk / spam folders
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-7 m-auto">
              <div className="section2 my-5 pb-3">
                <h5 className="text-left font-weight-bold mx-5">
                  Let's Create an employer account
                </h5>
                <h6 className="text-left  mx-5">
                  Already have an account?&nbsp;
                  <span
                    className="reg-text-clr"
                    style={{ cursor: "pointer" }}
                    onClick={() => naviGation("/Login")}
                  >
                    Login
                  </span>
                </h6>

                <form className="my-4 mx-5" onSubmit={(e) => handleSubmit(e)}>
                  <div className="form-container">
                    <div className="google-input">
                      <label htmlFor="template-input" className="z-index-value">
                        Company Name
                      </label>
                      <div className="dropdown-container">
                        <CreatableSelect
                          options={companyName}
                          required
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "6px",
                            zIndex: 3,
                            colors: {
                              ...theme.colors,
                              primary25: "#DFF1F1",
                              neutral20:
                                registerValidation?.companyName?.status ===
                                false
                                  ? "red"
                                  : "#00ABAD", // border color
                              primary:
                                registerValidation?.companyName?.status ===
                                false
                                  ? "red"
                                  : "#00ABAD", // border color when focus
                            },
                          })}
                          placeholder="Enter your company name"
                          isClearable
                          onInputChange={() => {
                            if (registerValidation["companyName"])
                              delete registerValidation["companyName"];
                          }}
                          onChange={(e) => {
                            setRegisterDetailsValue("companyName", e);
                          }}
                          value={registerDetail?.companyName}
                          onCreateOption={handleCreateCompanyName}
                        />
                      </div>
                    </div>
                    <small className="text-danger">
                      {registerValidation?.companyName?.message
                        ? `Company name ${registerValidation?.companyName?.message}`
                        : ""}
                    </small>

                    <div className="google-input">
                      <input
                        autoComplete="off"
                        type="text"
                        id="fullname"
                        placeholder="Enter your full name"
                        aria-invalid={Boolean(
                          registerValidation?.fullname?.status === false
                        )}
                        defaultValue={registerDetail?.fullname || ""}
                        onChange={(e) => {
                          setRegisterDetailsValue("fullname", e.target.value);
                          if (registerValidation["displayName"])
                              delete registerValidation["displayName"];
                        }}
                        onBlur={(e) => {
                          const str = e.target.value.split(" ");
                          setRegisterValidationValue(
                            "fullname",
                            StringValidation(e.target.value)
                          );
                          if (StringValidation(str[0]).status === true) {
                            setRegisterDetailsValue("displayName", str[0]);
                          }
                        }}
                      />
                      <label htmlFor="fullname">
                        Full name<span className="text-danger">*</span>
                      </label>
                    </div>
                    <small className="text-danger">
                      {registerValidation?.fullname?.message
                        ? `Full name ${registerValidation?.fullname?.message}`
                        : ""}
                    </small>
                    <div className="google-input">
                      <input
                        autoComplete="off"
                        type="text"
                        id="displayName"
                        placeholder="Enter your display name"
                        value={registerDetail?.displayName}
                        aria-invalid={Boolean(
                          registerValidation?.displayName?.status === false
                        )}
                        onChange={(e) => {
                          setRegisterDetailsValue(
                            "displayName",
                            e.target.value
                          );
                        }}
                        onBlur={(e) => {
                          setRegisterValidationValue(
                            "displayName",
                            StringValidation(e.target.value)
                          );
                        }}
                      />

                      <label htmlFor="displayName">
                        Display name<span className="text-danger">*</span>
                      </label>
                    </div>
                    <small className="text-danger">
                      {registerValidation?.displayName?.message
                        ? `Display name ${registerValidation?.displayName?.message}`
                        : ""}
                    </small>
                    <div className="google-input">
                      <input
                        type="text"
                        id="email"
                        placeholder="Enter your email"
                        aria-invalid={Boolean(
                          registerValidation?.email?.status === false
                        )}
                        defaultValue={registerDetail?.email || ""}
                        onChange={(e) => {
                          setRegisterDetailsValue("email", e.target.value);
                        }}
                        onBlur={(e) => {
                          setRegisterValidationValue(
                            "email",
                            EmailCompanyValidation(e.target.value)
                          );
                        }}
                        autoComplete="off"
                      />
                      <label htmlFor="email">
                        Official Email<span className="text-danger">*</span>
                      </label>
                      <p>
                        <small>
                          <span className="clr-blue">
                            Verification code will be sent to this email
                          </span>
                        </small>
                        <br />
                        <small className="text-danger">
                          {registerValidation?.email?.message
                            ? `Email ${registerValidation?.email?.message}`
                            : ""}
                        </small>
                      </p>
                    </div>
                    <PhoneInput
                      inputStyle={{
                        width: "100%",
                        height: "45px",
                        onFocus: "red",
                      }}
                      containerStyle={{
                        border: `${
                          registerValidation?.phoneNumber?.status === false
                            ? "1.5px solid red"
                            : ""
                        }`,
                        borderRadius: `${
                          registerValidation?.phoneNumber?.status === false
                            ? "5px"
                            : ""
                        }`,
                      }}
                      country={"US"}
                      id="phone-input"
                      onChange={(e) => {
                        setRegisterDetailsValue("phoneNumber", e);
                      }}
                      onBlur={(e) => {
                        setRegisterValidationValue(
                          "phoneNumber",
                          PhoneNumberValidation(e.target.value)
                        );
                      }}
                      value={registerDetail?.phoneNumber}
                    />
                    <small className="text-danger">
                      {registerValidation?.phoneNumber?.message
                        ? `Phone number ${registerValidation?.phoneNumber?.message}`
                        : ""}
                    </small>
                    <div className="google-input">
                      <input
                        autoComplete="off"
                        type="password"
                        id="password"
                        placeholder="Enter your password"
                        aria-invalid={Boolean(
                          registerValidation?.password?.status === false
                        )}
                        onChange={(e) => {
                          setRegisterDetailsValue("password", e.target.value);
                          setPasswordStrength(e.target.value);
                        }}
                        onBlur={(e) => {
                          setRegisterValidationValue(
                            "password",
                            PasswordValidation(e.target.value)
                          );
                        }}
                        defaultValue={registerDetail?.password || ""}
                      />
                      <label htmlFor="password">
                        Password<span className="text-danger">*</span>
                      </label>
                    </div>
                    <div>
                      {passwordStrength ? (
                        <div className="mt-3">
                          <b>Create a password with the following</b>
                          <br />
                          {
                            <p
                              className={`${
                                passwordStrength.trim().length < 8
                                  ? "text-danger"
                                  : "text-success"
                              } d-flex`}
                            >
                              {passwordStrength.trim().length < 8 ? (
                                <span className="material-symbols-rounded filled-icon close-icon">
                                  close
                                </span>
                              ) : (
                                <span className="material-symbols-rounded filled-icon close-icon">
                                  check
                                </span>
                              )}
                              At least 8 Characters
                            </p>
                          }
                          {
                            <p
                              className={`${
                                !/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                  passwordStrength
                                )
                                  ? "text-danger"
                                  : "text-success"
                              } d-flex`}
                            >
                              {!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                passwordStrength
                              ) ? (
                                <span className="material-symbols-rounded filled-icon close-icon">
                                  close
                                </span>
                              ) : (
                                <span className="material-symbols-rounded filled-icon close-icon">
                                  check
                                </span>
                              )}
                              An Uppercase &amp; Lowercase character
                            </p>
                          }
                          {
                            <p
                              className={`${
                                !/^(?=.*[.,:;'!@#$%^&*_+=|(){}[?\-\]/\\]).*$/.test(
                                  passwordStrength
                                )
                                  ? "text-danger"
                                  : "text-success"
                              } d-flex`}
                            >
                              {!/^(?=.*[.,:;'!@#$%^&*_+=|(){}[?\-\]/\\]).*$/.test(
                                passwordStrength
                              ) ? (
                                <span className="material-symbols-rounded filled-icon close-icon">
                                  close
                                </span>
                              ) : (
                                <span className="material-symbols-rounded filled-icon close-icon">
                                  check
                                </span>
                              )}
                              A special character
                            </p>
                          }
                          {
                            <p
                              className={`${
                                !/[0-9]/.test(passwordStrength)
                                  ? "text-danger"
                                  : "text-success"
                              } d-flex`}
                            >
                              {!/[0-9]/.test(passwordStrength) ? (
                                <span className="material-symbols-rounded filled-icon close-icon">
                                  close
                                </span>
                              ) : (
                                <span className="material-symbols-rounded filled-icon close-icon">
                                  check
                                </span>
                              )}
                              A number
                            </p>
                          }
                        </div>
                      ) : null}
                    </div>
                    <small className="text-danger">
                      {registerValidation?.password?.message
                        ? `Password ${registerValidation?.password?.message}`
                        : ""}
                    </small>
                    <div className="d-flex align-items-start top-12">
                      <div className="form-group form-check">
                        <input
                          name="acceptTerms"
                          type="checkbox"
                          id="acceptTerms"
                          checked={registerDetail?.acceptTerms === true}
                          onChange={(e) => {
                            setRegisterDetailsValue(
                              "acceptTerms",
                              e.target.checked
                            );
                          }}
                          // className="form-check-input"
                          className={`form-check-input ${
                            registerValidation?.acceptTerms ? "is-invalid" : ""
                          }`}
                        />
                        <label htmlFor="acceptTerms" className="mb-0">
                          <p className="ml-2 mb-0">
                            I agree to the
                            <span
                              className="clr-blue"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                dispatch(registerDetails(registerDetail));
                                naviGation(`/TermsAndConditions/${route[4]}`);
                              }}
                            >
                              &nbsp; Terms & Conditions &nbsp;
                            </span>
                            and
                            <span
                              className="clr-blue"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                dispatch(registerDetails(registerDetail));
                                naviGation(`/PrivacyPolicy/${route[4]}`);
                              }}
                            >
                              &nbsp; Privacy Policy
                            </span>
                          </p>
                        </label>
                        <div className="invalid-feedback">
                          {registerValidation?.acceptTerms?.message
                            ? `AcceptTerms ${registerValidation?.acceptTerms?.message}`
                            : ""}
                        </div>
                      </div>
                      <small className="text-danger">
                        {registerValidation?.checkbox?.message
                          ? `Display name ${registerValidation?.checkbox?.message}`
                          : ""}
                      </small>
                    </div>
                    <div className="google-input">
                      <button
                        type="submit"
                        className="btn btn-light my-2 my-sm-0 login-btn"
                        // onClick={handleSubmit}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
