import React, { useState } from "react";
import Navbar from "../navbar";
import { Link } from "react-router-dom";
import BlogsImg from "../../images/blogs.png";
import BlogsImg2 from "../../images/blogs2.png";
import RoboImg from "../../images/roboimg.png";

import Footer from "../footer";
import CustomModal from "../CustomModal";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
export default function BlogsDashboard() {
  const [writeBlogModal, setwriteBlogModal] = useState(false);
  return (
    <div className="">
      <Navbar />
      <div class="main ce-section BlogsHome">
        <div className="py-1 ">
          <div className="container py-4">
            <div className="row">
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-12 col-md-9 px-1 mb-2">
                    <div className="faq-heading reviews-heading-comp d-flex justify-content-between align-items-center">
                      <div className="container blogs-topic">
                        <OwlCarousel
                          className="owl-theme"
                          loop
                          autoplay={false}
                          items="7"
                          dots={false}
                          smartSpeed={1000}
                          nav={true}
                          margin={20}
                          autoWidth={true}
                          center={false}
                          autoplayTimeout={1800}
                          responsive={{
                            360: {
                              items: "1",
                            },
                            414: {
                              items: "1",
                            },
                            992: {
                              items: "3",
                            },
                            1200: {
                              items: "7",
                            },
                          }}
                        >
                          <div class="item text-center">
                            <h6 className="px-3">All</h6>
                          </div>
                          <div class="item text-center">
                            <h6 className="px-3">Gadgets</h6>
                          </div>
                          <div class="item text-center">
                            <h6 className="px-3">Creativity</h6>
                          </div>
                          <div class="item text-center">
                            <h6 className="px-3">Startup</h6>
                          </div>
                          <div class="item text-center">
                            <h6 className="px-3">Artificial Intelligence</h6>
                          </div>
                          <div class="item text-center">
                            <h6 className="px-3">Freelancing</h6>
                          </div>
                          <div class="item text-center">
                            <h6 className="px-3">Marketing</h6>
                          </div>
                          <div class="item text-center">
                            <h6 className="px-3">UX</h6>
                          </div>
                          <div class="item text-center">
                            <h6 className="px-3">Leadership</h6>
                          </div>
                          <div class="item text-center">
                            <h6 className="px-3">Economy</h6>
                          </div>
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="Jobssection3">
                      <div className="row">
                        <div
                          className="col-lg-4 jobs-padding col-md-6"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="jobs-content">
                            <div className="d-flex">
                              <img
                                className="w-100 h-auto"
                                src={BlogsImg}
                                alt=""
                              />
                            </div>
                            <div className="redirect-link">
                              <Link to="/BlogsIndividual">
                                <h5 className="font-weight-bold my-3">
                                  Now you can have a Googler review your UX
                                  portfolio
                                </h5>
                                <h6 className="mb-1 ">
                                  Readers of the UX Collective who are taking
                                  the Google UX Design Certificate can now enter
                                  for a chance to get portfolio feedback from a
                                  Googler.
                                </h6>
                                <div className="d-flex align-items-center mt-2">
                                  <img
                                    className="w-25 h-auto"
                                    src={RoboImg}
                                    alt=""
                                  />
                                  <div className="">
                                    <h6 className="font-weight-bold">
                                      Kristopher Saber
                                    </h6>
                                    <h6 className="">Jub 14, 2022</h6>
                                  </div>
                                </div>
                              </Link>
                              <div className="d-flex mt-3 justify-content-between">
                                <h6>
                                  <span className="bg-gray py-1 px-2">
                                    UX Design
                                  </span>
                                </h6>
                                <div className="d-flex justify-content-between align-items-center">
                                  <span className="material-symbols-rounded icon-size align-middle">
                                    {" "}
                                    share
                                  </span>
                                  <span className="material-symbols-rounded icon-size align-middle px-3">
                                    {" "}
                                    bookmark
                                  </span>
                                  <span className="material-symbols-rounded icon-size align-middle">
                                    {" "}
                                    more_vert
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4 jobs-padding col-md-6"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="jobs-content">
                            <div className="d-flex">
                              <img
                                className="w-100 h-auto"
                                src={BlogsImg2}
                                alt=""
                              />
                            </div>
                            <div className="redirect-link">
                              <h5 className="font-weight-bold my-3 ">
                                Now you can have a Googler review your UX
                                portfolio
                              </h5>
                              <h6 className="mb-1 ">
                                Readers of the UX Collective who are taking the
                                Google UX Design Certificate can now enter for a
                                chance to get portfolio feedback from a Googler.
                              </h6>
                              <div className="d-flex align-items-center mt-2">
                                <img
                                  className="w-25 h-auto"
                                  src={RoboImg}
                                  alt=""
                                />
                                <div className="">
                                  <h6 className="font-weight-bold">
                                    Kristopher Saber
                                  </h6>
                                  <h6 className="">Jub 14, 2022</h6>
                                </div>
                              </div>
                              <div className="d-flex mt-3 justify-content-between">
                                <h6>
                                  <span className="bg-gray py-1 px-2">
                                    UX Design
                                  </span>
                                </h6>
                                <div className="d-flex justify-content-between align-items-center">
                                  <span className="material-symbols-rounded icon-size align-middle">
                                    {" "}
                                    share
                                  </span>
                                  <span className="material-symbols-rounded icon-size align-middle px-3">
                                    {" "}
                                    bookmark
                                  </span>
                                  <span className="material-symbols-rounded icon-size align-middle">
                                    {" "}
                                    more_vert
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4 jobs-padding col-md-6"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="jobs-content">
                            <div className="d-flex">
                              <img
                                className="w-100 h-auto"
                                src={BlogsImg2}
                                alt=""
                              />
                            </div>
                            <div className="redirect-link">
                              <h5 className="font-weight-bold my-3 ">
                                Now you can have a Googler review your UX
                                portfolio
                              </h5>
                              <h6 className="mb-1 ">
                                Readers of the UX Collective who are taking the
                                Google UX Design Certificate can now enter for a
                                chance to get portfolio feedback from a Googler.
                              </h6>
                              <div className="d-flex align-items-center mt-2">
                                <img
                                  className="w-25 h-auto"
                                  src={RoboImg}
                                  alt=""
                                />
                                <div className="">
                                  <h6 className="font-weight-bold">
                                    Kristopher Saber
                                  </h6>
                                  <h6 className="">Jub 14, 2022</h6>
                                </div>
                              </div>
                              <div className="d-flex mt-3 justify-content-between">
                                <h6>
                                  <span className="bg-gray py-1 px-2">
                                    UX Design
                                  </span>
                                </h6>
                                <div className="d-flex justify-content-between align-items-center">
                                  <span className="material-symbols-rounded icon-size align-middle">
                                    {" "}
                                    share
                                  </span>
                                  <span className="material-symbols-rounded icon-size align-middle px-3">
                                    {" "}
                                    bookmark
                                  </span>
                                  <span className="material-symbols-rounded icon-size align-middle">
                                    {" "}
                                    more_vert
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4 jobs-padding col-md-6"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="jobs-content">
                            <div className="d-flex">
                              <img
                                className="w-100 h-auto"
                                src={BlogsImg2}
                                alt=""
                              />
                            </div>
                            <div className="redirect-link">
                              <h5 className="font-weight-bold my-3 ">
                                Now you can have a Googler review your UX
                                portfolio
                              </h5>
                              <h6 className="mb-1 ">
                                Readers of the UX Collective who are taking the
                                Google UX Design Certificate can now enter for a
                                chance to get portfolio feedback from a Googler.
                              </h6>
                              <div className="d-flex align-items-center mt-3">
                                <img
                                  className="w-25 h-auto"
                                  src={RoboImg}
                                  alt=""
                                />
                                <div className="">
                                  <h6 className="font-weight-bold">
                                    Kristopher Saber
                                  </h6>
                                  <h6 className="">Jub 14, 2022</h6>
                                </div>
                              </div>
                              <div className="d-flex mt-3 justify-content-between">
                                <h6>
                                  <span className="bg-gray py-1 px-2">
                                    UX Design
                                  </span>
                                </h6>
                                <div className="d-flex justify-content-between align-items-center">
                                  <span className="material-symbols-rounded icon-size align-middle">
                                    {" "}
                                    share
                                  </span>
                                  <span className="material-symbols-rounded icon-size align-middle px-3">
                                    {" "}
                                    bookmark
                                  </span>
                                  <span className="material-symbols-rounded icon-size align-middle">
                                    {" "}
                                    more_vert
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4 jobs-padding col-md-6"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="jobs-content">
                            <div className="d-flex">
                              <img
                                className="w-100 h-auto"
                                src={BlogsImg2}
                                alt=""
                              />
                            </div>
                            <div className="redirect-link">
                              <h5 className="font-weight-bold my-3 ">
                                Now you can have a Googler review your UX
                                portfolio
                              </h5>
                              <h6 className="mb-1 ">
                                Readers of the UX Collective who are taking the
                                Google UX Design Certificate can now enter for a
                                chance to get portfolio feedback from a Googler.
                              </h6>
                              <div className="d-flex align-items-center mt-3">
                                <img
                                  className="w-25 h-auto"
                                  src={RoboImg}
                                  alt=""
                                />
                                <div className="">
                                  <h6 className="font-weight-bold">
                                    Kristopher Saber
                                  </h6>
                                  <h6 className="">Jub 14, 2022</h6>
                                </div>
                              </div>
                              <div className="d-flex mt-3 justify-content-between">
                                <h6>
                                  <span className="bg-gray py-1 px-2">
                                    UX Design
                                  </span>
                                </h6>
                                <div className="d-flex justify-content-between align-items-center">
                                  <span className="material-symbols-rounded icon-size align-middle">
                                    {" "}
                                    share
                                  </span>
                                  <span className="material-symbols-rounded icon-size align-middle px-3">
                                    {" "}
                                    bookmark
                                  </span>
                                  <span className="material-symbols-rounded icon-size align-middle">
                                    {" "}
                                    more_vert
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4 jobs-padding col-md-6"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="jobs-content">
                            <div className="d-flex">
                              <img
                                className="w-100 h-auto"
                                src={BlogsImg2}
                                alt=""
                              />
                            </div>
                            <div className="redirect-link">
                              <h5 className="font-weight-bold my-3 ">
                                Now you can have a Googler review your UX
                                portfolio
                              </h5>
                              <h6 className="mb-1 ">
                                Readers of the UX Collective who are taking the
                                Google UX Design Certificate can now enter for a
                                chance to get portfolio feedback from a Googler.
                              </h6>
                              <div className="d-flex align-items-center mt-3">
                                <img
                                  className="w-25 h-auto"
                                  src={RoboImg}
                                  alt=""
                                />
                                <div className="">
                                  <h6 className="font-weight-bold">
                                    Kristopher Saber
                                  </h6>
                                  <h6 className="">Jub 14, 2022</h6>
                                </div>
                              </div>
                              <div className="d-flex mt-3 justify-content-between">
                                <h6>
                                  <span className="bg-gray py-1 px-2">
                                    UX Design
                                  </span>
                                </h6>
                                <div className="d-flex justify-content-between align-items-center">
                                  <span className="material-symbols-rounded icon-size align-middle">
                                    {" "}
                                    share
                                  </span>
                                  <span className="material-symbols-rounded icon-size align-middle px-3">
                                    {" "}
                                    bookmark
                                  </span>
                                  <span className="material-symbols-rounded icon-size align-middle">
                                    {" "}
                                    more_vert
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3  px-1">
                <div className="reviews-heading reviews-heading-comp mb-3 py-3">
                  <h6 className="font-weight-bold">Search Blogs</h6>
                  <div class="input-group search-bg mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text material-symbols-rounded">
                        Search
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search key word"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-light mx-2  login-btn"
                  >
                    Search
                  </button>
                </div>
                <div className="reviews-heading reviews-heading-comp my-3 py-3">
                  <h6 className="font-weight-bold">
                    Lorem Ipsum is simply dummy text of the
                  </h6>
                  <p className="">
                    <small>
                      Ask a question about working or interviewing at Cognizant
                      Technology Solutions. Our community is ready to answer
                    </small>
                  </p>
                  <button
                    type="button"
                    className="btn btn-light mx-2  login-btn"
                    onClick={() => {
                      setwriteBlogModal(true);
                    }}
                  >
                    Write Blog
                  </button>
                </div>
                <div className="reviews-heading reviews-heading-comp my-3 py-3">
                  <h6 className="font-weight-bold">Topics to follow</h6>
                  <div className="blogs-topics">
                    <li className=" py-1 px-2">Gadgets</li>
                    <li className=" py-1 px-2">Creativity</li>
                    <li className=" py-1 px-2">Startup</li>
                    <li className=" py-1 px-2">Artificial Intelligence</li>
                    <li className=" py-1 px-2">Freelancing</li>
                    <li className=" py-1 px-2">Marketing</li>
                    <li className=" py-1 px-2">Leadership</li>
                  </div>
                  <button
                    type="button"
                    className="btn btn-light mx-2  login-btn"
                  >
                    Load More Topics
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <CustomModal
        open={writeBlogModal}
        onClickOutside={() => {
          setwriteBlogModal(false);
        }}
      >
        <div className="modal-content w-75  m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                onClick={() => {
                  setwriteBlogModal(false);
                }}
              >
                close
              </span>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <h5 className="font-weight-bold text-center py-3">
                    You must be an EADJobs.us member to write blogs
                  </h5>
                  <div className="text-center">
                    <button className="btn btn-light login-btn my-2 my-sm-0 mx-auto ">
                      Sign Up for free
                    </button>
                  </div>
                  <p className=" text-center pt-4">
                    Already a member?{" "}
                    <span>
                      <a className="text-green" href="#top">
                        Sign In
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
