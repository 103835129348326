import React, { useState } from "react";
import Navbar from "./navbar";
import Roboimg from "../images/roboimg.png";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { UpdatePassword } from "./Api/Api";
import { logOutSuccess } from "./Store/auth/action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ChangePassword() {
  //navigation
  const naviGation = useNavigate();
  const [passwordStrength, setPasswordStrength] = useState("");
  //usestate
  const [checkPassword, setPassword] = useState("");
  // state
  const state = useSelector((state) => state);
  // useForm Details
  const {
    register,
    handleSubmit,
    // control,
    watch,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };
  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const onSubmit = async (data) => {
    if (data.NewPassword === data.password) {
      const Payload = {
        emailId: state.auth.registerData.email,
        password: data.NewPassword,
      };
      const response = await UpdatePassword(Payload);

      if (response && response.statusCode === 200) {
        dispatch(logOutSuccess(state.auth.isLoggedIn === false));
        showToastSuccessMsg(response.message);
        //setSuccessModal(true);
        naviGation("/Login");
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setFailureModal(true);
      }
    } else {
      setPassword("Enter-Same Password");
    }
  };

  return (
    <div>
      <Navbar />
      <div className="Banner d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-5">
              <div className="section1 section1-regEmp my-3 p-4">
                <img className="" src={Roboimg} alt="" />
                <h1>
                  Welcome! I'm Quantum,
                  <br />
                  your personal recruiter.
                </h1>
                <p>
                  With a personal recruiter, you'll get <br /> matched to roles
                  that are right for you.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-7 m-auto">
              <div className="section2 my-5 pb-3">
                <h5 className="text-left font-weight-bold  mx-5">
                  Change Your Password
                </h5>

                <form className="my-4 mx-5" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-container">
                    <div className="google-input">
                      <input
                        autoComplete="off"
                        type="password"
                        placeholder="Enter your New password"
                        {...register("password", {
                          required: "Password is Required",

                          validate: (value) => {
                            return [/[a-z]/, /[A-Z]/, /[0-9]/].every(
                              (pattern) => pattern.test(value)
                            );
                          },
                          onChange: (e) => {
                            setPasswordStrength(e.target.value);
                          },
                        })}
                      />
                      <label htmlFor="template-input">New Password</label>
                    </div>
                    <small className="text-danger">
                      {errors.password?.message}
                    </small>
                    {passwordStrength ? (
                      <div className="mt-3">
                        <b>Create a password with the following</b>
                        <br />
                        {
                          <p
                            className={`${
                              passwordStrength.trim().length < 8
                                ? "text-danger"
                                : "text-success"
                            } d-flex`}
                          >
                            {passwordStrength.trim().length < 8 ? (
                              <span className="material-symbols-rounded filled-icon close-icon">
                                close
                              </span>
                            ) : (
                              <span className="material-symbols-rounded filled-icon close-icon">
                                check
                              </span>
                            )}{" "}
                            At least 8 Characters
                          </p>
                        }
                        {
                          <p
                            className={`${
                              !/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                passwordStrength
                              )
                                ? "text-danger"
                                : "text-success"
                            } d-flex`}
                          >
                            {!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                              passwordStrength
                            ) ? (
                              <span className="material-symbols-rounded filled-icon close-icon">
                                close
                              </span>
                            ) : (
                              <span className="material-symbols-rounded filled-icon close-icon">
                                check
                              </span>
                            )}{" "}
                            An Uppercase &amp; Lowercase character
                          </p>
                        }
                        {
                          <p
                            className={`${
                              !/^(?=.*[.,:;'!@#$%^&*_+=|(){}[?\-\]/\\]).*$/.test(
                                passwordStrength
                              )
                                ? "text-danger"
                                : "text-success"
                            } d-flex`}
                          >
                            {!/^(?=.*[.,:;'!@#$%^&*_+=|(){}[?\-\]/\\]).*$/.test(
                              passwordStrength
                            ) ? (
                              <span className="material-symbols-rounded filled-icon close-icon">
                                close
                              </span>
                            ) : (
                              <span className="material-symbols-rounded filled-icon close-icon">
                                check
                              </span>
                            )}{" "}
                            A special character
                          </p>
                        }
                        {
                          <p
                            className={`${
                              !/[0-9]/.test(passwordStrength)
                                ? "text-danger"
                                : "text-success"
                            } d-flex`}
                          >
                            {!/[0-9]/.test(passwordStrength) ? (
                              <span className="material-symbols-rounded filled-icon close-icon">
                                close
                              </span>
                            ) : (
                              <span className="material-symbols-rounded filled-icon close-icon">
                                check
                              </span>
                            )}{" "}
                            A number
                          </p>
                        }
                      </div>
                    ) : null}
                    <div className="google-input">
                      <input
                        autoComplete="off"
                        type="password"
                        placeholder="Re-Enter New Password"
                        {...register("NewPassword", {
                          required: "Confirm Password is required",
                          validate: (value) => {
                            if (watch("password") !== value) {
                              return "Your passwords do no match";
                            }
                          },
                        })}
                      />
                      <label htmlFor="template-input">
                        Re-Enter New Password
                      </label>
                    </div>
                    <small className="text-danger">
                      {errors.NewPassword?.message || checkPassword}
                    </small>

                    <div className="google-input-btm">
                      <button
                        type="submit"
                        className="btn btn-light my-2 my-sm-0 login-btn"
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
