import React from "react";
import NavbarAdmin from "../../Admin/NavbarAdmin";
import Blogs from "./Blogs";
import Employee from "./Employee";
import FAQ from "./FAQ";
import Jobs from "./Jobs";
import QA from "./QA";
import CompanyDetails from "./CompanyDetails";

function EditCompanies() {
  const Alltabs = [
    "Company Details",
    "Jobs",
    "Employees",
    "Blogs",
    "FAQ",
    "QA",
  ];
  const [selectedTab, setSelectedTab] = React.useState("Company Details");

  return (
    <div className="dashboard-bg">
      <NavbarAdmin />
      <div className="main">
        <div
          className="chat-section bg-white py-1"
          style={{ boxShadow: "0px 3px 6px #00000017" }}
        >
          <div className="">
            <>
              <nav className="flex border-b tab-section border-gray-300">
                {Alltabs.map((tab, index) => {
                  return (
                    <div
                      className={
                        selectedTab === tab ? "tab active" : "tab inactive"
                      }
                      key={index}
                      onClick={() => {
                        setSelectedTab(tab);
                      }}
                    >
                      <li className="tab-header">{tab}</li>
                    </div>
                  );
                })}
              </nav>
            </>
            {selectedTab === "Company Details" && (
              <div className="">
                <CompanyDetails />
              </div>
            )}
            {selectedTab === "Blogs" && (
              <div className="">
                <Blogs />
              </div>
            )}
            {selectedTab === "FAQ" && (
              <div className="">
                <FAQ />
              </div>
            )}
            {selectedTab === "QA" && (
              <div className="">
                <QA />
              </div>
            )}
            {selectedTab === "Jobs" && (
              <div className="">
                <Jobs />
              </div>
            )}
            {selectedTab === "Employees" && (
              <div className="">
                <Employee />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCompanies;
