import React from "react";
import NavbarAdmin from "./NavbarAdmin";

const Dashboard = () => {
  return (
    <div className="dashboard-bg">
      <NavbarAdmin />
      <div className="main"></div>
    </div>
  );
};

export default Dashboard;
