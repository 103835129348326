/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import NavbarDashboard from "./NavbarDashboard";
import CustomModal from "../CustomModal";
import {
  CreateAnswer,
  CreateEmployeeSkills,
  CreateFAQDetails,
  CreateLikeDislike,
  GetLabel,
  GetLabelQuestionFilter,
  GetQADetails,
  UpdateAnswer,
} from "../Api/Api";
import { useSelector } from "react-redux";
import Roboimg2 from "../../images/roboimg-outerborder.png";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import { NonEmptyValidation, StringValidation } from "../Store/validate";

import Roboimg from "../../images/roboimg.png";
export default function QA() {
  const [addansModal, setaddansModal] = useState(false);
  const [qaDetails, setQaDetails] = useState([]);
  const [showAnswerList, setShowAnswerList] = useState(false);
  const [questionID, setQuestionID] = useState("");
  const [createAns, setCreateAns] = useState([]);
  const [validation, setValidation] = useState({});
  const [addFaqModal, setaddFaqModal] = useState(false);
  const [createFaq, setCreateFaq] = useState([]);
  const [answerFaq, setAnswerFaq] = useState([]);
  const [filteredResults, setFilteredResults] = useState(qaDetails?.questionDetails);
  const [questionFaq, setQuestionFaq] = useState("");
  // const [searchInput, setSearchInput] = useState("");
  const [ansInput, setAnsInput] = useState("");
  const [filteredAnswers, setFilteredAnswers] = useState([]);
  const [sortOrder, setSortOrder] = useState("newest");
  const [answerSort, setAnswerSort] = useState("newest");
  // const [questionInput, setQuestionInput] = useState("");
  const [answerInput, setAnswerInput] = useState("");
  const [labelFilter, setLabelFilter] = useState("");
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;
  const companyId = state?.auth?.auth?.userDetails?.companyId;

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };
  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //Find all
  const handleallQA = useCallback(async () => {
    if (!authKey || !companyId) return;

    const response = await GetQADetails(
      companyId,
      authKey,
      sortOrder,
      answerSort
    );
    if (response && response.statusCode === 200) {
      setQaDetails(response.data);
    } else {
      setQaDetails([]);
    }
  }, [companyId, authKey, answerSort, sortOrder]);

  useEffect(() => {
    handleallQA();
    return () => { };
  }, [sortOrder, answerSort, handleallQA]);

  // let emptyAnwer = qaDetails?.questionDetails?.map((e)=>e.answerDetails?.length)

  let answerData = qaDetails?.questionDetails
    ?.filter((e) => e.questionId === questionID)
    .map((e) => {
      return e.answerDetails;
    });
  console.log(filteredResults)
  console.log(answerData)


  //search filter question
  const [details, setDetails] = useState("");
  const searchItems = (e) => {
    // setSearchInput(searchValue);
    // if (searchValue && searchValue.trim().length > 0) {
    //   searchValue = searchValue.trim().toLowerCase();
    //   setFilteredResults(
    //     qaDetails?.questionDetails?.filter((item) => {
    //       if (item.question.toLowerCase().includes(searchValue)) {
    //         return item;
    //       }
    //     })
    //   );
    // } else {
    //   setFilteredResults(qaDetails);
    // }
    const keyword = e;
    if (keyword !== "") {
      const results = qaDetails?.questionDetails.filter((value) => {
        let name = value.question.toLowerCase().includes(keyword);
        return name
      });
      setFilteredResults(results);
    } else {
      setFilteredResults(qaDetails?.questionDetails);
    }

    setDetails(keyword);
  };
  useEffect(() => {
    return () => { };
  }, [ansInput]);
  //search filter Answer
  const searchAnswer = (searchValue) => {
    setAnsInput(searchValue);
    if (searchValue && searchValue.trim().length > 0) {
      searchValue = searchValue.trim().toLowerCase();
      setFilteredAnswers(
        answerData[0].filter((item) => {
          return (item.answer.toLowerCase().includes(searchValue));
        })
      );
    } else {
      setFilteredAnswers(answerData);
    }
  };

  const setValidationValue = (key, value) => {
    setValidation({ ...validation, [key]: value });
  };

  const setValue = (key, value) => {
    setCreateAns({ ...createAns, [key]: value });
    if (validation[key]) delete validation[key];
  };
  const handleCreateAnswer = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.answer = NonEmptyValidation(createAns.answer);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        questionId: questionID,
        answer: createAns.answer,
        answeredBy: state?.auth?.auth?.userProfileId,
      };

      const response = await CreateAnswer(Payload, token);
      if (response && response.statusCode === 200) {
        setaddansModal(false);
        showToastSuccessMsg(response.message);
        handleallQA();
        setCreateAns([]);
      } else {
        showToastErrorMsg(response.message);
      }
    } else {
      console.log("Error while creating Q&A answer");
    }
  };

  const handleUpdateQA = async (answerid, clicked) => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      answerId: answerid,
      questionId: questionID,
      //	answeredBy: state?.auth?.auth?.userProfileId,
      accpetedBy: clicked,
      //   likeCount: "0",
      //   dislikeCount: "0",
    };
    const response = await UpdateAnswer(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      handleallQA();
    } else {
      showToastErrorMsg(response.message);
    }
  };

  const setValueFAQ = (key, value) => {
    setCreateFaq({ ...createFaq, [key]: value });
    if (validation[key]) delete validation[key];
  };
  const [labelTitle, setLabelTitle] = useState([]);

  const fetchLabelTitle = useCallback(async () => {
    if (!authKey || !companyId) return;

    const response = await GetLabel(
      authKey,
      1,
      companyId
    );
    if (response && response.statusCode === 200) {
      let data1 = response.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.name,
          label: e.name,
          dataId: e.masterDataId,
        };
      });
      setLabelTitle(mappingData);
    }
  }, [authKey, companyId]);

  useEffect(() => {
    fetchLabelTitle();
  }, [fetchLabelTitle]);

  const handleCreateLabelTitle = async (string) => {
    const valid = StringValidation(string);
    setValidationValue("label", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        masterDataType: "label",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        companyId: state?.auth?.auth?.userDetails?.companyId,
        restrictionLevel: 1,
      };
      const response = await CreateEmployeeSkills(Payload, token);
      if (response && response.statusCode === 200) {
        fetchLabelTitle();
        const data = {
          value: response.data.name,
          label: response.data.name,
          dataId: response.data.masterDataId,
        };
        if (createFaq?.label?.length) {
          let setNewData = createFaq?.label;
          setNewData.push(data);
          setValueFAQ("label", setNewData);
        } else {
          setValueFAQ("label", [data]);
        }
      }
    } else {
      console.log("Error while creating label in Q&A page.");
    }
  };

  const handleCreateFaq = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.label = NonEmptyValidation(createFaq?.label);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const token = state?.auth?.auth?.authKey;
      const Payload = {
        companyId: state?.auth?.auth?.userDetails?.companyId,
        question: questionFaq,
        answer: answerFaq,
        label:
          createFaq?.label?.dataId ||
          createFaq?.label?.map((e) => e.dataId.toString()),
      };

      const response = await CreateFAQDetails(Payload, token);
      if (response && response.statusCode === 200) {
        setCreateFaq([]);
        setaddFaqModal(false);
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(response.message);
      }
    } else {
      console.log("Error while add FAQ in reviews page");
    }
  };

  //create like and dislike
  const handleLikeDislike = async (ansId, type) => {
    const token = state?.auth?.auth?.authKey;
    const Payload = {
      answerId: ansId,
      thumbsType: type,
      userProfileId: state?.auth?.auth?.userProfileId,
    };
    const response = await CreateLikeDislike(Payload, token);
    if (response && response.statusCode === 200) {
      handleallQA();
    } else {
      showToastErrorMsg(response.message);
    }
  };

  //label filter
  const handleFilterData = async (id) => {
    const token = state?.auth?.auth?.authKey;
    const companyId = state?.auth?.auth?.userDetails?.companyId;

    const response = await GetLabelQuestionFilter(id, token, 0, 25, companyId);
    if (response && response.statusCode === 200) {
      setQaDetails(response.data);
    } else {
      showToastErrorMsg(response.message);
    }
  };
  return (
    <>
      <div className="dashboard-bg">
        <NavbarDashboard />
        <div className="main">
          <div className="faq-section scroll-content py-4">
            <div className="container">
              {/* <h2 className='d-flex justify-content-center text-green font-weight-bold'>Q&A</h2> */}
              {showAnswerList === false && (
                <>
                  {state?.auth?.auth?.userDetails?.status === "Active" ||
                    state?.auth?.auth?.userDetails?.approved === true ? (
                    <div className="row justify-content-center">
                      <div className="col-lg-9">
                        <div className="faq-heading py-4  align-items-center">
                          <h6 className="font-weight-bold mb-3">
                            Questions about working at&nbsp;
                            {state?.auth?.auth?.userDetails?.companyName}
                          </h6>
                          <div className="d-flex flex-sm-row flex-column justify-content-between">
                            <div className="input-group flex-nowrap mr-4">
                              <div className="input-group-prepend">
                                <span className="input-group-text material-symbols-rounded">
                                  search
                                </span>
                              </div>
                              <input
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                placeholder="search"
                                aria-label="Username"
                                aria-describedby="addon-wrapping"
                                onChange={(e) => {
                                  searchItems(e.target.value)
                                  setDetails(e.target.value)
                                }}
                              />
                            </div>
                            <button
                              className="btn btn-light my-2 my-sm-0  login-btn"
                              // onClick={() => searchItems(questionInput)}
                              onClick={() => searchItems(details)}
                            >
                              Browse questions
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className='row justify-content-center'> */}
                      <div className="col-lg-9">
                        <div className="faq-heading d-flex flex-sm-row flex-column  justify-content-between align-items-center mt-4">
                          <h6 className="font-weight-bold">
                            Questions about working at&nbsp;
                            {state?.auth?.auth?.userDetails?.companyName}
                          </h6>
                          <div className="d-flex">
                            {labelFilter.length > 0 ? (
                              <h6 className="my-auto mr-5">
                                <span
                                  className="badge badge-info"
                                  style={{ cursor: "pointer" }}
                                >
                                  {labelFilter}
                                </span>
                                <span
                                  className="material-symbols-rounded align-middle text-danger"
                                  style={{ cursor: "pointer", fontSize: "15px" }}
                                  onClick={() => {
                                    setLabelFilter("");
                                    handleallQA();
                                  }}
                                >
                                  cancel
                                </span>
                              </h6>
                            ) : null}
                            <p className="d-flex align-items-center mb-0 mr-2">
                              Sort by
                            </p>
                            <div className="form-group mb-0">
                              <select
                                value={sortOrder}
                                className="form-control font-weight-bold text-green"
                                id="exampleFormControlSelect1"
                                onChange={(e) => setSortOrder(e.target.value)}
                              >
                                <option>newest</option>
                                <option>oldest</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      {filteredResults && filteredResults.length > 0 ? (
                        <>
                          {filteredResults?.map((data, index) => {
                            return (
                              <div className="col-lg-9" key={index}>
                                <div className="faq-content">
                                  <div className="faq-body mt-4">
                                    <h6 className="font-weight-bold">
                                      {data.question}
                                    </h6>
                                    <p>
                                      <small>
                                        Asked{" "}
                                        {moment(data.createdAt).format("LL")}
                                      </small>
                                    </p>
                                    {data.answerDetails
                                      ?.filter((item) => item.accpetedBy === true)
                                      .slice(0, 1)
                                      .map((item, index) => {
                                        return (
                                          <div key={index}>
                                            <p>{item.answer}</p>
                                          </div>
                                        );
                                      })}
                                    <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center">
                                      <div className="d-flex">
                                        <button
                                          className="btn btn-light my-2 my-sm-0 mr-5 postjob-btn"
                                          onClick={() => {
                                            setQuestionID(data.questionId);
                                            setaddansModal(true);
                                          }}
                                        >
                                          Answer
                                        </button>
                                        <p
                                          className="mb-0 d-flex   align-items-center text-green"
                                          onClick={() => {
                                            // if(qaDetails?.answerDetails.length>0){
                                            setShowAnswerList(true)
                                          }
                                            // }
                                          }
                                        >
                                          <a
                                            className="text-green"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              setQuestionID(data.questionId);
                                              setQuestionFaq(data.question);
                                            }}
                                          >
                                            <u>See all answers</u>

                                          </a>
                                        </p>
                                      </div>
                                      {data.answerDetails
                                        ?.filter(
                                          (item) => item.accpetedBy === true
                                        )
                                        .slice(0, 1)
                                        .map((item, index) => {
                                          return (
                                            <div key={index}>
                                              {item.accpetedBy === true ? (
                                                <p className="font-weight-bold mb-0 faq-checkbox active" style={{ cursor: "none" }}>
                                                  <span className="material-symbols-rounded filled-icon align-middle">
                                                    check_circle
                                                  </span>
                                                  Accepted answer
                                                </p>
                                              ) : null}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {qaDetails?.questionDetails?.map((data, index) => {
                            return (
                              <div className="col-lg-9" key={index}>
                                <div className="faq-content">
                                  <div className="faq-body mt-4">
                                    <div className="d-flex justify-content-between">
                                      <div>
                                        <h6 className="font-weight-bold">
                                          {data.question}
                                        </h6>
                                        <p>
                                          <small>
                                            Asked{" "}
                                            {moment(data.createdAt).format("LL")}
                                          </small>
                                        </p>
                                      </div>
                                      {data?.label?.map((item, index) => {
                                        return (
                                          <h6 className="mx-1" key={index}>
                                            <span
                                              className="badge badge-info px-2"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                handleFilterData(
                                                  item.masterDataId
                                                );
                                                setLabelFilter(item.name);
                                              }}
                                            >
                                              {item.name}
                                            </span>
                                          </h6>
                                        );
                                      })}
                                    </div>
                                    {data.answerDetails
                                      ?.filter((item) => item.accpetedBy === true)
                                      .slice(0, 1)
                                      .map((item, index) => {
                                        return (
                                          <div key={index}>
                                            <p>{item.answer}</p>
                                          </div>
                                        );
                                      })}
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="d-flex">
                                        <button
                                          className="btn btn-light my-2 my-sm-0 mr-5 postjob-btn"
                                          onClick={() => {
                                            setQuestionID(data.questionId);
                                            setaddansModal(true);
                                          }}
                                        >
                                          Answer
                                        </button>
                                        <p className="mb-0 d-flex   align-items-center text-green" onClick={() => setShowAnswerList(true)}>
                                          <a href="#" className="text-green" style={{ cursor: "pointer" }} onClick={(e) => {
                                            e.preventDefault();
                                            setQuestionID(data.questionId);
                                            setQuestionFaq(data.question);
                                          }}>
                                            <u>See all answers</u>
                                          </a>
                                        </p>
                                      </div>
                                      {data.answerDetails
                                        ?.filter(
                                          (item) => item.accpetedBy === true
                                        )
                                        .slice(0, 1)
                                        .map((item, index) => {
                                          return (
                                            <div key={index}>
                                              {item.accpetedBy === true ? (
                                                <p className="font-weight-bold mb-0 faq-checkbox active" style={{ cursor: "default" }}>
                                                  <span className="material-symbols-rounded filled-icon align-middle">
                                                    check_circle
                                                  </span>
                                                  Accepted answer
                                                </p>
                                              ) : null}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                      {/* </div> */}
                    </div>
                  ) : (
                    <div className="modal-body p-3 ">
                      <div
                        className="modalHeader"
                        style={{ backgroundColor: "#ffffff" }}
                      >
                        <h6 className="text-center font-weight-bold my-2">
                          <img
                            style={{
                              width: "85px",
                              textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px;",
                            }}
                            src={Roboimg2}
                            alt=""
                          />
                        </h6>
                      </div>
                      <form className="container">
                        <div className="row d-flex align-items-center justify-content-center  mt-0">
                          <div className="col-lg-8 pb-3">
                            <p className=" text-center">
                              Your account is still pending for approval.
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </>
              )}
              {showAnswerList === true && (
                <div className="row justify-content-center">
                  <div className="col-lg-9">
                    <div className="faq-heading py-4  align-items-center">
                      <div className="d-flex flex-sm-row flex-column justify-content-between">
                        <div className="input-group flex-nowrap mr-4">
                          <div className="input-group-prepend">
                            <span className="input-group-text material-symbols-rounded">
                              search
                            </span>
                          </div>
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            placeholder="search"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            onChange={(e) => setAnswerInput(e.target.value)}
                          />
                        </div>
                        <button
                          className="btn btn-light my-2 my-sm-0  login-btn"
                          onClick={() => searchAnswer(answerInput)}
                        >
                          Browse Answers
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="faq-heading d-flex justify-content-between align-items-center mt-2">
                      <div className="d-flex">
                        <h5 className="font-weight-bold">
                          <span className="text-green">
                            {answerData[0].length}
                          </span>{" "}
                          Answers
                        </h5>
                        <button
                          className="btn btn-light m-2 my-sm-0 login-btn"
                          onClick={() => {
                            setShowAnswerList(false);
                          }}
                        >
                          Back
                        </button>
                      </div>
                      <div className="d-flex">
                        <p className="d-flex align-items-center mb-0 mr-3">
                          Sort by
                        </p>
                        <div className="form-group mb-0 d-flex align-items-center  mr-3">
                          <select
                            value={answerSort}
                            className="form-control  font-weight-bold text-green"
                            id="exampleFormControlSelect1"
                            onChange={(e) => setAnswerSort(e.target.value)}
                          >
                            <option>newest</option>
                            <option>oldest</option>
                          </select>
                        </div>
                        <button
                          className="btn btn-light my-2 my-sm-0 login-btn"
                          onClick={() => {
                            setaddansModal(true);
                          }}
                        >
                          Add an Answer
                        </button>
                      </div>
                    </div>
                  </div>
                  {answerData?.length <= 0 ?
                    <div className="col-12 mt-4">
                      <h6 className="text-center font-weight-bold my-2">
                        <img
                          style={{
                            width: "85px",
                            textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                          }}
                          src={Roboimg}
                          alt=""
                        />
                      </h6>
                      <br />
                      <div>
                        <h6 className="text-center font-weight-bold my-2">
                          No answer found
                        </h6>
                      </div>
                    </div>
                    :

                    ansInput.length > 1 ? (
                      <>
                        {filteredAnswers.length > 0 &&
                          filteredAnswers?.map((item, index) => {
                            return (
                              <div className="col-lg-9" key={index}>
                                <div className="faq-content">
                                  <div className="faq-body mt-4">
                                    <h6>
                                      <span className="font-weight-bold pr-2">
                                        {item.userProfile
                                          ? item.userProfile?.displayName
                                          : item.companyProfile.displayName}
                                      </span>
                                      <small>
                                        Answered{" "}
                                        {moment(item.createdAt).format("LL")} -
                                        {item?.userProfile
                                          ? item?.userProfile?.role
                                          : item?.companyProfile?.companyName}
                                        - {item?.userProfile?.primaryLocation?.name}
                                      </small>
                                    </h6>
                                    <p>{item.answer}</p>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="d-flex">
                                        <p
                                          className="mb-0  d-flex align-items-center"
                                          onClick={() => {
                                            handleLikeDislike(
                                              item.answerId,
                                              "Like"
                                            );
                                          }}
                                        >
                                          <span className="material-symbols-rounded rounded-circle faq-thumbs-up align-middle">
                                            thumb_up
                                          </span>
                                        </p>
                                        <span
                                          className="badge  my-auto px-2"
                                          style={{ cursor: "pointer" }}
                                        >
                                          {!item.likeCount ? 0 : item.likeCount}
                                        </span>
                                        <p
                                          className="mb-0  d-flex align-items-center"
                                          onClick={() => {
                                            handleLikeDislike(
                                              item.answerId,
                                              "DisLike"
                                            );
                                          }}
                                        >
                                          <span className="material-symbols-rounded rounded-circle faq-thumbs-down align-middle">
                                            thumb_down
                                          </span>
                                        </p>
                                        <span
                                          className="badge my-auto px-2"
                                          style={{ cursor: "pointer" }}
                                        >
                                          {!item.dislikeCount
                                            ? 0
                                            : item.dislikeCount}
                                        </span>
                                        <button
                                          className="btn btn-light my-2 my-sm-0 mr-5 login-btn"
                                          onClick={() => {
                                            setaddFaqModal(true);
                                            setAnswerFaq(item.answer);
                                          }}
                                        >
                                          Add to FAQ
                                        </button>
                                      </div>
                                      {item.accpetedBy === false ? (
                                        <p
                                          className="font-weight-bold mb-0 faq-checkbox"
                                          onClick={() => {
                                            handleUpdateQA(item.answerId, true);
                                          }}
                                        >
                                          <span className="material-symbols-rounded filled-icon align-middle">
                                            check_circle
                                          </span>
                                          Accept the answer
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        {answerData[0].length > 0 &&
                          answerData[0].map((item, index) => {
                            return (
                              <div className="col-lg-9" key={index}>
                                <div className="faq-content">
                                  <div className="faq-body mt-4">
                                    <h6>
                                      <span className="font-weight-bold pr-2">
                                        {item.userProfile
                                          ? item.userProfile?.displayName
                                          : item.companyProfile.displayName}
                                      </span>
                                      <small>
                                        Answered{" "}
                                        {moment(item.createdAt).format("LL")} -
                                        {item?.userProfile
                                          ? item?.userProfile?.role
                                          : item?.companyProfile?.companyName}
                                        - {item?.userProfile?.primaryLocation?.name}
                                      </small>
                                    </h6>
                                    <p>{item.answer}</p>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="d-flex">
                                        <p
                                          className="mb-0  d-flex align-items-center"
                                          onClick={() => {
                                            handleLikeDislike(
                                              item.answerId,
                                              "Like"
                                            );
                                          }}
                                        >
                                          <span
                                            className={`material-symbols-rounded rounded-circle  faq-thumbs-up align-middle ${item.thumbsType === "Like"
                                                ? "active"
                                                : null
                                              }`}
                                          >
                                            thumb_up
                                          </span>
                                        </p>
                                        <span
                                          className="badge  my-auto px-2"
                                          style={{ cursor: "pointer" }}
                                        >
                                          {!item.likeCount ? 0 : item.likeCount}
                                        </span>
                                        <p
                                          className="mb-0  d-flex align-items-center"
                                          onClick={() => {
                                            handleLikeDislike(
                                              item.answerId,
                                              "DisLike"
                                            );
                                          }}
                                        >
                                          <span
                                            className={`material-symbols-rounded rounded-circle  faq-thumbs-down align-middle ${item.thumbsType === "DisLike"
                                                ? "active"
                                                : null
                                              }`}
                                          >
                                            thumb_down
                                          </span>
                                        </p>
                                        <span
                                          className="badge my-auto px-2"
                                          style={{ cursor: "pointer" }}
                                        >
                                          {!item.dislikeCount
                                            ? 0
                                            : item.dislikeCount}
                                        </span>
                                        <button
                                          className="btn btn-light my-2 my-sm-0 mr-5 login-btn"
                                          onClick={() => {
                                            setaddFaqModal(true);
                                            setAnswerFaq(item.answer);
                                          }}
                                        >
                                          Add to FAQ
                                        </button>
                                      </div>
                                      {item.accpetedBy === false ? (
                                        <p
                                          className="font-weight-bold mb-0 faq-checkbox"
                                          onClick={() => {
                                            handleUpdateQA(item.answerId, true);
                                          }}
                                        >
                                          <span className="material-symbols-rounded   filled-icon align-middle">
                                            check_circle
                                          </span>
                                          Accept the answer
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </>
                    )}



                </div>
              )}
            </div>
          </div>
        </div>
        <CustomModal
          open={addansModal}
          onClickOutside={() => {
            setaddansModal(false);
            setValidation({});
          }}
        >
          <div className="modal-content">
            <form onSubmit={(e) => handleCreateAnswer(e)}>
              <div className="modal-body p-0 ">
                <div className="modalHeader">
                  <h5 className="text-center font-weight-bold my-0">
                    Add an Answer
                  </h5>
                </div>
                <div className="container">
                  <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                    <div className="col-lg-10">
                      <div className="modalAnswer mt-2">
                        <div className="form-group">
                          <textarea
                            className="form-control rounded-25 "
                            id="exampleFormControlTextarea2"
                            rows="10"
                            aria-invalid={Boolean(
                              validation?.answer?.status === false
                            )}
                            onChange={(e) => {
                              setValue("answer", e.target.value);
                            }}
                            onBlur={(e) => {
                              setValidationValue(
                                "answer",
                                NonEmptyValidation(e.target.value)
                              );
                            }}
                          ></textarea>
                          <small className="text-danger">
                            {validation?.answer?.message
                              ? `Answer ${validation?.answer?.message}`
                              : ""}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0">
                  <div>
                    <button
                      className="btn btn-light otp-btn postjob-btn"
                      onClick={() => {
                        setaddansModal(false);
                        setValidation({});
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                    <button
                      type="submit"
                      className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <ToastContainer />
        </CustomModal>
        <CustomModal
          open={addFaqModal}
          onClickOutside={() => {
            setaddFaqModal(false);
            setValidation({});
          }}
        >
          <div className="modal-content">
            <form onSubmit={(e) => handleCreateFaq(e)}>
              <div className="modal-body p-0 ">
                <div className="modalHeader">
                  <h5 className="text-center font-weight-bold my-0">Add FAQ</h5>
                </div>
                <div className="container">
                  <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-3">
                    <div className="col-lg-5">
                      <div className="google-input">
                        <input
                          value={questionFaq}
                          autoComplete="off"
                          type="text"
                          id="template-input"
                          disabled
                          placeholder="Type your question"
                          onChange={(e) => {
                            setValueFAQ("question", questionFaq);
                          }}
                        />
                        <label htmlFor="template-input">Question</label>
                      </div>
                      <div className="google-input">
                        <label htmlFor="template-input" className="z-index-value">
                          Related to
                        </label>
                        <div className="dropdown-container">
                          <CreatableSelect
                            options={labelTitle}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: "6px",
                              zIndex: 3,
                              colors: {
                                ...theme.colors,
                                primary25: "#DFF1F1",
                                neutral20:
                                  validation?.label?.status === false
                                    ? "red"
                                    : "#00ABAD", // border color
                                primary:
                                  validation?.label?.status === false
                                    ? "red"
                                    : "#00ABAD", // border color when focus
                              },
                            })}
                            styles={{
                              menu: (provided, state) => ({
                                ...provided,
                                zIndex: "5 !important",
                              }),
                            }}
                            placeholder="Choose Job Title"
                            value={createFaq?.label}
                            onChange={(newValue) => {
                              setValueFAQ("label", newValue);
                            }}
                            onInputChange={() => {
                              if (validation["label"]) delete validation["label"];
                            }}
                            onCreateOption={handleCreateLabelTitle}
                            isClearable
                            isMulti
                          />
                        </div>
                        <small className="text-danger">
                          {validation?.label?.message
                            ? `Label ${validation?.label?.message}`
                            : ""}
                        </small>
                      </div>
                      <div className="modalAnswer mt-2">
                        <label for="exampleFormControlTextarea2">Answer</label>
                        <div className="form-group">
                          <textarea
                            value={answerFaq}
                            className="form-control rounded-0 modalDisapprove-textarea"
                            id="exampleFormControlTextarea2"
                            rows="7"
                            disabled
                            onChange={(e) => {
                              setValueFAQ("answer", answerFaq);
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0">
                  <div>
                    <button
                      className="btn btn-light otp-btn postjob-btn"
                      onClick={() => {
                        setaddFaqModal(false);
                        setValidation({});
                        setCreateFaq({ ...createFaq, label: null });
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    {/* <button className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn">Continue</button> */}
                    <button
                      type="submit"
                      className="btn btn-light my-2 my-sm-0 mr-2 login-btn modalClosing-btn"
                    >
                      ADD
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <ToastContainer />
        </CustomModal>
      </div>
    </>
  );
}
/* {
 <div className='col-lg-9'>
                  <div className='faq-content'>
                    <div className='faq-body mt-4'>
                      <h6 className='font-weight-bold'>What is the best part of working at Cognizant Technology Solutions?</h6>
                      <p><small>Created October 7, 2019</small></p>
                      <p>Helping the customer’s with their issues. Working from home, but still feel part of the team. Everyone is very understanding and I learn something new every day!</p>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex'>
                          <button className="btn btn-light my-2 my-sm-0 mr-5 postjob-btn">Answer</button>
                          <p className='mb-0 d-flex   align-items-center'><a href='' className='text-green'><u>See all answers</u></a></p>
                        </div>
                        <p className='font-weight-bold mb-0 faq-checkbox active'><span className="material-symbols-rounded filled-icon align-middle">check_circle</span> Accept the answer</p>
  
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-9'>
                  <div className='faq-content'>
                    <div className='faq-body mt-4'>
                      <h6 className='font-weight-bold'>What is the best part of working at Cognizant Technology Solutions?</h6>
                      <p><small>Created October 7, 2019</small></p>
                      <p>Helping the customer’s with their issues. Working from home, but still feel part of the team. Everyone is very understanding and I learn something new every day!</p>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex'>
                          <button className="btn btn-light my-2 my-sm-0 mr-5 postjob-btn">Answer</button>
                          <p className='mb-0 d-flex   align-items-center'><a href='' className='text-green'><u>See all answers</u></a></p>
                        </div>
                        <p className='font-weight-bold mb-0 faq-checkbox active'><span className="material-symbols-rounded filled-icon align-middle">check_circle</span> Accept the answer</p>
  
                      </div>
                    </div>
                  </div>
                </div>	 
} */

/* {
 <div className='col-lg-9'>
                  <div className='faq-content'>
                      <div className='faq-body mt-4'>
                        <h6><span className='font-weight-bold'>Antony C </span> <small> Answered June 26, 2022 - Process Executive - Voice - Amarillo, TX</small></h6>
                        <p>Helping the customer’s with their issues. Working from home, but still feel part of the team. Everyone is very understanding and I learn something new every day!</p>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className='d-flex'>
                          <p className='mb-0 mr-3 d-flex align-items-center'><span className="material-symbols-rounded  faq-thumbs-up align-middle">thumb_up</span></p>
                             <p className='mb-0 mr-3 d-flex align-items-center'><span className="material-symbols-rounded  faq-thumbs-down align-middle">thumb_down</span></p>
                            <button className="btn btn-light my-2 my-sm-0 mr-5 login-btn">Add to FAQ</button>
                          </div>
                          <p className='font-weight-bold mb-0 faq-checkbox'><span className="material-symbols-rounded  filled-icon align-middle">check_circle</span> Accept the answer</p>
  
                        </div>
                      </div>
                  </div>
                 </div>
                 <div className='col-lg-9'>
                    <div className='faq-content'>
                      <div className='faq-body mt-4'>
                        <h6><span className='font-weight-bold'>Antony C </span> <small> Answered June 26, 2022 - Process Executive - Voice - Amarillo, TX</small></h6>
                        <p>Helping the customer’s with their issues. Working from home, but still feel part of the team. Everyone is very understanding and I learn something new every day!</p>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className='d-flex'>
                                      <p className='mb-0 mr-3 d-flex align-items-center'><span className="material-symbols-rounded  faq-thumbs-up align-middle">thumb_up</span></p>
                             <p className='mb-0 mr-3 d-flex align-items-center'><span className="material-symbols-rounded  faq-thumbs-down align-middle">thumb_down</span></p>
                            <button className="btn btn-light my-2 my-sm-0 mr-5 login-btn">Add to FAQ</button>
                          </div>
                          <p className='font-weight-bold mb-0 faq-checkbox'><span className="material-symbols-rounded  filled-icon align-middle">check_circle</span> Accept the answer</p>
  
                        </div>
                      </div>
                    </div>
                 </div> 
} */

/*
  {toggleIcon === "Text Area" ?
  <div className="modalDisapprove-details d-flex  justify-content-end ">
  <button type="button" className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1" onClick={()=>setToggleIcon("Rich Text Editor")}>
     Rich Text Editor
     <span className="material-symbols-rounded modalDisapprove-lockicon mx-1">lock</span>
  </button>
  </div> :
  <div className="modalDisapprove-details d-flex  justify-content-end ">
  <button type="button" className="btn btn-secondary modalDisapprove-butn btn-sm mt-1 mb-1 mx-1" onClick={()=>setToggleIcon("Text Area")}>
     Text Area

  </button>
  </div>}
*/
