import React, { useEffect, useCallback, useState } from "react";
import PhoneInput from "react-phone-input-2";
import NavbarAdmin from "../../Admin/NavbarAdmin";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import {
  EmailValidation,
  NonEmptyObjectValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../../components/Store/validate";
import CustomModal from "../../components/CustomModal";
import { toast, ToastContainer } from "react-toastify";
import Roboimg2 from "../../images/roboimg-outerborder.png";
import {
  CreateKnownLanguage,
  CreateMasterDataByType,
  DeleteKnownLanguage,
  GetMasterDatasByType,
  getParticularLanguage,
  GetPersonalDetails,
  UpdateLanguage,
  UpdatePersonDetails,
} from "../../components/Api/Api";
import { useSelector } from "react-redux";

function EditJobseekers() {
  const state = useSelector((state) => state);
  const userDetails = state?.auth?.auth?.userDetails;
  const authKey = state?.auth?.auth?.authKey;

  const [profileDetails, setProfileDetails] = useState({});
  const [profileDetailsValidation, setProfileDetailsValidation] = useState({});
  const [AddLanguageModal, setAddLanguageModal] = useState(false);
  const [EditLanguageModal, setEditLanguageModal] = useState(false);
  const [languageData, setLanguageData] = useState({});
  const [languageValidation, setLanguageValidation] = useState({});
  const [language, setLanguage] = useState([]);
  const [location, setLocation] = useState([]);
  const [languageDeleteModalStatus, setLanguageDeleteModalStatus] =
    useState(false);
  const [deleteLanguageData, setDeleteLanguageData] = useState("");
  const setBasicDetailsValidationValue = (key, value) => {
    setProfileDetailsValidation({ ...profileDetailsValidation, [key]: value });
  };

  let host = window.location.href;
  let url = host?.split("/")
  console.log(url[7])

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };
  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const handleDeleteSelectedLanguage = async () => {
    try {
      let data = deleteLanguageData;
      data.language = profileDetails?.language.filter(
        (item) => item?.knownLanguageId !== data.knownLanguageId
      );
      const Authkey = state?.auth?.auth?.authKey;
      const Payload = {
        knownLanguageId: data.knownLanguageId,
      };
      const response = await DeleteKnownLanguage(Payload, Authkey);
      if (response && response.statusCode === 200) {
        handleProfileDetails("language", data.language);
        setLanguageDeleteModalStatus(false);
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } catch (e) {
      console.log(e, "Error while deleting the language");
    }
  };

  const [particularLanguage, setParticularLanguage] = useState([]);
  const [particularLanguageValidation] = useState([]);

  const setParticularLanguageValue = (key, value) => {
    setParticularLanguage({
      ...particularLanguage,
      [key]: value,
    });
    if (particularLanguageValidation[key])
      delete particularLanguageValidation[key];
  };

  async function fetchParticularLanguage(value) {
    const token = state?.auth?.auth?.authKey;
    const knownLanguageId = value;
    const getEmployeeSkills = await getParticularLanguage(
      token,
      knownLanguageId
    );
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      setParticularLanguage(getEmployeeSkills.data);
    } else {
      setParticularLanguage([]);
    }
  }

  const handleProfileData = useCallback(async () => {
    if (!authKey) return;

    const userId = url[7];

    const response = await GetPersonalDetails(authKey, userId);
    if (response && response.statusCode === 200) {
      setProfileDetails(response.data);
    }
  }, [authKey, url]);

  const fetchLanguageData = useCallback(async () => {
    if (!authKey) return;

    const Query = `authKey=${authKey}&masterDataType=language&status=Active&restrictionLevel=0&userId=${url[7]}`;
    const response = await GetMasterDatasByType(Query);
    if (response && response.statusCode === 200) {
      setLanguage(response.data);
    } else {
      setLanguage([]);
    }
  }, [authKey, url]);

  const fetchLocationData = useCallback(async () => {
    if (!authKey) return;

    const Query = `authKey=${authKey}&masterDataType=location&status=Active&restrictionLevel=0&userId=${url[7]}`;
    const response = await GetMasterDatasByType(Query);
    if (response && response.statusCode === 200) {
      setLocation(response.data);
    } else {
      setLocation([]);
    }
  }, [authKey, url]);

  useEffect(() => {
    setProfileDetails(userDetails);
    handleProfileData();
    fetchLanguageData();
    fetchLocationData();
  }, [userDetails, handleProfileData, fetchLanguageData, fetchLocationData]);


  const [gender, setGender] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const Query = `authKey=${authKey}&masterDataType=gender&status=Active&restrictionLevel=0&userId=${url[7]}`;
      const getGenderDetails = await GetMasterDatasByType(Query);
      if (getGenderDetails && getGenderDetails.statusCode === 200) {
        let data1 = getGenderDetails.data;

        let mappingData = data1.map((e) => {
          return {
            value: e.name,
            label: e.name,
            dataId: e.masterDataId,
          };
        });
        setGender(mappingData);
      }
    }
    fetchData();
  }, [authKey, url]);



  const onChangeLanguageDetails = (key, value) => {
    setLanguageData({ ...languageData, [key]: value });
    if (languageValidation[key]) delete languageValidation[key];
    return null;
  };
  const setLanguageValidationValue = (key, value) => {
    setLanguageValidation({ ...languageValidation, [key]: value });
  };

  const handleCreateLanguage = async (string, type) => {
    const valid = StringValidation(string);
    if (valid.status) {
      const Payload = {
        masterDataType: "language",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        restrictionLevel: 0,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await CreateMasterDataByType(Payload, token);
      if (response && response.statusCode === 200) {
        fetchLanguageData();
        if (type === "create") {
          onChangeLanguageDetails("languageId", {
            value: response.data.name,
            label: response.data.name,
            masterDataId: response.data.masterDataId,
          });
        } else {
          onChangeLanguageDetails("language", {
            value: response.data.name,
            label: response.data.name,
            masterDataId: response.data.masterDataId,
          });
        }
      }
    } else {
      setLanguageValidationValue("languageId", valid);
    }
  };

  const handleCreateLocation = async (key, string) => {
    const valid = StringValidation(string);
    if (valid.status) {
      const Payload = {
        masterDataType: "location",
        name: string,
        userId: state?.auth?.auth?.userProfileId,
        restrictionLevel: 0,
      };
      const token = state?.auth?.auth?.authKey;
      const response = await CreateMasterDataByType(Payload, token);
      if (response && response.statusCode === 200) {
        fetchLocationData();
        if (key === "primaryLocation") {
          handleProfileDetails(key, response.data);
        } else {
          if (profileDetails[key] && profileDetails[key].length) {
            let data = profileDetails[key];
            data.push(response.data);
            handleProfileDetails(key, data);
          } else {
            handleProfileDetails(key, [response.data]);
          }
        }
      }
    } else {
      setBasicDetailsValidationValue(key, valid);
    }
  };

  console.log(languageData)

  const CreateKnownLanguageData = async () => {
    const Payload = {
      userProfileId: url[7],
      languageId: languageData.languageId.masterDataId,
      speak: languageData.speak ? languageData.speak : false,
      read: languageData.read ? languageData.read : false,
      write: languageData.write ? languageData.write : false,
      proficiency: languageData.proficiency,
    };
    const token = state?.auth?.auth?.authKey;
    const response = await CreateKnownLanguage(Payload, token);
    if (response && response.statusCode === 200) {
      setAddLanguageModal(false);
      setLanguageData({});
      fetchLanguageData();
      showToastSuccessMsg(response.message);
      if (profileDetails.language && profileDetails.language.length) {
        let data = profileDetails.language;
        data.push(response.data);
        handleProfileDetails("language", data);
      } else {
        handleProfileDetails("language", [response.data]);
      }
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const handleProfileDetails = (key, value) => {
    setProfileDetails({ ...profileDetails, [key]: value });
    if (profileDetailsValidation[key]) delete profileDetailsValidation[key];
    return null;
  };

  const onSubmitProfileDetails = async () => {
    let Payload = {
      userProfileId: url[7],
      fullName: profileDetails.fullName,
      displayName: profileDetails.displayName,
      emailId: profileDetails.emailId,
      mobileNumber: profileDetails.mobileNumber,
      genderId: profileDetails.genderId.dataId,
      language: profileDetails.language.map((e) => e.knownLanguageId),
      primaryLocation: profileDetails.primaryLocation.masterDataId,
      secondaryLocation: profileDetails.secondaryLocation.map(
        (e) => e.masterDataId
      ),
    };
    const token = state?.auth?.auth?.authKey;
    const Response = await UpdatePersonDetails(Payload, token);
    if (Response && Response.statusCode === 200) {
      showToastSuccessMsg(Response.message);
    } else {
      showToastErrorMsg(
        Response.message || Response.error.response.data.message
      );
    }
  };

  const UpdateKnownLanguageData = async (value) => {
    const Payload = {
      knownLanguageId: value,
      languageId: particularLanguage?.language.masterDataId,
      speak: particularLanguage.speak ? particularLanguage.speak : false,
      read: particularLanguage.read ? particularLanguage.read : false,
      write: particularLanguage.write ? particularLanguage.write : false,
      proficiency: particularLanguage.proficiency,
    };
    const token = state?.auth?.auth?.authKey;
    const response = await UpdateLanguage(Payload, token);
    if (response && response.statusCode === 200) {
      setEditLanguageModal(false);
      setParticularLanguage({});
      handleProfileData();
      showToastSuccessMsg(response.message);
      if (particularLanguage && profileDetails.language.length) {
        let data = profileDetails.language;
        data.push(response.data);
        handleProfileDetails("language", data);
      } else {
        handleProfileDetails("language", [response.data]);
      }
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  return (
    <div className="dashboard-bg">
      <NavbarAdmin />

      <div className="main">
        <div
          className="chat-section bg-white py-1"
          style={{ boxShadow: "0px 3px 6px #00000017" }}
        >
          <div className="">
            <>
              <nav className="flex border-b tab-section border-gray-300">
                <div className="tab active">
                  <li className="tab-header">Edit Job Seekers</li>
                </div>
              </nav>
            </>
            <div className="">
              <div className="scroll-content">
                <div className="container">
                  <div className="row d-flex align-items-center justify-content-center ">
                    <div className="col-lg-10">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          onSubmitProfileDetails();
                        }}
                      >
                        <div className="form-container py-1">
                          <div className="row justify-content-center">
                            <div className="col-lg-5 col-md-6  pb-4 border-right">
                              <div className="google-input">
                                <input
                                  autoComplete="off"
                                  onChange={(e) => {
                                    handleProfileDetails(
                                      "fullName",
                                      e.target.value
                                    );
                                  }}
                                  value={profileDetails.fullName || ""}
                                  type="text"
                                  id="fullName"
                                  placeholder="Enter your full name"
                                  aria-invalid={
                                    profileDetailsValidation?.fullName
                                      ?.status === false
                                  }
                                  onBlur={(e) => {
                                    setBasicDetailsValidationValue(
                                      "fullName",
                                      StringValidation(e.target.value)
                                    );
                                  }}
                                />
                                <label htmlFor="template-input">
                                  Full Name
                                </label>
                              </div>
                              {profileDetailsValidation?.fullName?.status ===
                              false ? (
                                <small className="text-danger">
                                  Full Name
                                  {profileDetailsValidation?.fullName?.message}
                                </small>
                              ) : null}
                              <div className="google-input">
                                <input
                                  autoComplete="off"
                                  type="text"
                                  id="displayName"
                                  placeholder="Enter your Display name"
                                  onChange={(e) => {
                                    handleProfileDetails(
                                      "displayName",
                                      e.target.value
                                    );
                                  }}
                                  value={profileDetails.displayName || ""}
                                  aria-invalid={
                                    profileDetailsValidation?.displayName
                                      ?.status === false
                                  }
                                  onBlur={(e) => {
                                    setBasicDetailsValidationValue(
                                      "displayName",
                                      StringValidation(e.target.value)
                                    );
                                  }}
                                />
                                <label htmlFor="template-input">
                                  Display Name
                                </label>
                              </div>
                              {profileDetailsValidation?.displayName?.status ===
                              false ? (
                                <small className="text-danger">
                                  Display Name
                                  {
                                    profileDetailsValidation?.displayName
                                      ?.message
                                  }
                                </small>
                              ) : null}
                              <div className="google-input">
                                <input
                                  autoComplete="off"
                                  type="text"
                                  id="emailId"
                                  placeholder="Enter your email"
                                  onChange={(e) => {
                                    handleProfileDetails(
                                      "emailId",
                                      e.target.value
                                    );
                                  }}
                                  value={profileDetails.emailId || ""}
                                  aria-invalid={
                                    profileDetailsValidation?.emailId
                                      ?.status === false
                                  }
                                  onBlur={(e) => {
                                    setBasicDetailsValidationValue(
                                      "emailId",
                                      EmailValidation(e.target.value)
                                    );
                                  }}
                                />
                                <label htmlFor="template-input">Email Id</label>
                              </div>
                              {profileDetailsValidation?.emailId?.status ===
                              false ? (
                                <small className="text-danger">
                                  Email Id
                                  {profileDetailsValidation?.emailId?.message}
                                </small>
                              ) : null}

                              <div className="google-input">
                                <input
                                  autoComplete="off"
                                  type="text"
                                  id="template-input"
                                  onClick={() => {
                                    setAddLanguageModal(true);
                                  }}
                                  readOnly
                                  placeholder="Enter known languages"
                                />
                                <label htmlFor="template-input">
                                  Languages
                                </label>
                              </div>

                              {profileDetailsValidation?.language?.status ===
                              false ? (
                                <small className="text-danger">
                                  Language
                                  {profileDetailsValidation?.language?.message}
                                </small>
                              ) : null}
                            </div>
                            <div className="col-lg-5 col-md-6 pb-4">
                              <div className="google-input">
                                <label
                                  htmlFor="template-input"
                                  className="z-index-value"
                                >
                                  Gender
                                </label>
                                <div className="dropdown-container">
                                  <Select
                                    options={gender}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: "6px",
                                      zIndex: 3,
                                      // colors: {
                                      //   ...theme.colors,
                                      //   primary25: "#DFF1F1",
                                      //   neutral20:
                                      //     profileDetailsValidation?.genderId
                                      //       ?.status === false
                                      //       ? "red"
                                      //       : "#00ABAD", // border color
                                      //   primary:
                                      //     profileDetailsValidation?.genderId
                                      //       ?.status === false
                                      //       ? "red"
                                      //       : "#00ABAD", // border color when focus
                                      // },
                                    })}
                                    styles={{
                                      menu: (provided, state) => ({
                                        ...provided,
                                        zIndex: "5 !important",
                                      }),
                                    }}
                                    placeholder="Choose your gender"
                                    onChange={(e) => {
                                      handleProfileDetails("genderId", e);
                                    }}
                                    value={{
                                      value:
                                        profileDetails?.genderId?.value ||
                                        profileDetails?.genderId?.name,
                                      label:
                                        profileDetails?.genderId?.label ||
                                        profileDetails?.genderId?.name,
                                    }}
                                  />
                                </div>
                              </div>

                              {/* {profileDetailsValidation?.genderId
                                    ?.status === false ? (
                                    <small className="text-danger">
                                      Gender
                                      {
                                        profileDetailsValidation?.genderId
                                          ?.message
                                      }
                                    </small>
                                  ) : null} */}
                              <PhoneInput
                                inputStyle={{
                                  width: "100%",
                                  height: "45px",
                                  onFocus: "red",
                                }}
                                onlyCountries={["us"]}
                                // country={"us"}
                                // countryCodeEditable={true}
                                value={profileDetails?.mobileNumber?.toString()}
                                onChange={(e) => {
                                  handleProfileDetails("mobileNumber", e);
                                }}
                                specialLabel="Phone Number"
                                masks={{ us: ".........." }}
                                onBlur={() => {
                                  if (
                                    typeof profileDetails?.mobileNumber !==
                                    "undefined"
                                  ) {
                                    setBasicDetailsValidationValue(
                                      "mobileNumber",
                                      PhoneNumberValidation(
                                        profileDetails?.mobileNumber
                                      )
                                    );
                                  } else {
                                    return true;
                                  }
                                }}
                              />
                              <small className="text-danger">
                                {profileDetailsValidation?.mobileNumber?.message
                                  ? `Phone number ${profileDetailsValidation?.mobileNumber?.message}`
                                  : ""}
                              </small>

                              <div className="google-input">
                                <label
                                  htmlFor="template-input"
                                  className="z-index-value"
                                >
                                  Primary Location
                                </label>
                                <div className="dropdown-container">
                                  <CreatableSelect
                                    options={location}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: "6px",
                                      zIndex: 3,
                                      colors: {
                                        ...theme.colors,
                                        primary25: "#DFF1F1",
                                        primary: "#00ABAD",
                                      },
                                    })}
                                    styles={{
                                      menu: (provided, state) => ({
                                        ...provided,
                                        zIndex: "5 !important",
                                      }),
                                    }}
                                    getOptionLabel={(option) =>
                                      option.name || option.label
                                    }
                                    getOptionValue={(option) =>
                                      option.masterDataId || option.dataId
                                    }
                                    placeholder="Choose your primary location"
                                    value={profileDetails?.primaryLocation}
                                    onCreateOption={(e) => {
                                      handleCreateLocation(
                                        "primaryLocation",
                                        e
                                      );
                                    }}
                                    onChange={(e) => {
                                      handleProfileDetails(
                                        "primaryLocation",
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              {profileDetailsValidation?.primaryLocation
                                ?.status === false ? (
                                <small className="text-danger">
                                  Primary Location
                                  {
                                    profileDetailsValidation?.primaryLocation
                                      ?.message
                                  }
                                </small>
                              ) : null}
                              <div className="google-input">
                                <label
                                  htmlFor="template-input"
                                  className="z-index-value"
                                >
                                  Secondary Location
                                </label>
                                <div className="dropdown-container">
                                  <CreatableSelect
                                    isMulti
                                    options={location}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: "6px",
                                      colors: {
                                        ...theme.colors,
                                        primary25: "#DFF1F1",
                                        primary: "#00ABAD",
                                      },
                                    })}
                                    styles={{
                                      menu: (provided, state) => ({
                                        ...provided,
                                        zIndex: "5 !important",
                                      }),
                                    }}
                                    getOptionLabel={(option) =>
                                      option.name || option.label
                                    }
                                    getOptionValue={(option) =>
                                      option.masterDataId || option.dataId
                                    }
                                    placeholder="Choose your secondary location"
                                    onChange={(e) => {
                                      handleProfileDetails(
                                        "secondaryLocation",
                                        e
                                      );
                                    }}
                                    value={profileDetails?.secondaryLocation}
                                    onCreateOption={(e) => {
                                      handleCreateLocation(
                                        "secondaryLocation",
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              {profileDetailsValidation?.secondaryLocation
                                ?.status === false ? (
                                <small className="text-danger">
                                  Secondary Location
                                  {
                                    profileDetailsValidation?.secondaryLocation
                                      ?.message
                                  }
                                </small>
                              ) : null}
                            </div>

                            <div className="col-lg-12 text-center">
                              <div className="selected-lang my-2">
                                {profileDetails?.language?.length === 0 ? (
                                  ""
                                ) : (
                                  <table className="tablelanguage tableFixHead">
                                    <tr
                                      className="tabletr"
                                      style={{
                                        fontWeight: "bolder",
                                        fontSize: "16.5px",
                                        fontFamily: "Atkinson",
                                      }}
                                    >
                                      <td>Language</td>
                                      <td>Basic</td>
                                      <td>Intermediate</td>
                                      <td>Expert</td>
                                      <td>Action</td>
                                    </tr>
                                    {console.log(profileDetails)}
                                    {profileDetails?.language &&
                                      profileDetails?.language?.length &&
                                      profileDetails?.language.map(
                                        (language, index) => {
                                          return (
                                            <tr key={index}>
                                              <td>{language?.languageName}</td>
                                              <td>
                                                {language?.proficiency ===
                                                "Basic" ? (
                                                  <span>
                                                    {language?.speak ? (
                                                      <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                        Speak
                                                      </small>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {language?.read ? (
                                                      <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                        Read
                                                      </small>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {language?.write ? (
                                                      <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                        Write
                                                      </small>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                              </td>
                                              <td>
                                                {language?.proficiency ===
                                                "Intermediate" ? (
                                                  <span>
                                                    {language?.speak ? (
                                                      <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                        Speak
                                                      </small>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {language?.read ? (
                                                      <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                        Read
                                                      </small>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {language?.write ? (
                                                      <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                        Write
                                                      </small>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                              </td>
                                              <td>
                                                {language?.proficiency ===
                                                "Expert" ? (
                                                  <span>
                                                    {language?.speak ? (
                                                      <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                        Speak
                                                      </small>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {language?.read ? (
                                                      <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                        Read
                                                      </small>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {language?.write ? (
                                                      <small className="bg-color-green px-3 py-1 mx-1 my-1">
                                                        Write
                                                      </small>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                ) : (
                                                  ""
                                                )}
                                              </td>
                                              <td>
                                                <div className="row">
                                                  <div className="col-6">
                                                    <div className="delete-content my-auto">
                                                      <span
                                                        className="material-symbols-rounded icon-middle"
                                                        onClick={() => {
                                                          setDeleteLanguageData(
                                                            {
                                                              knownLanguageId:
                                                                language?.knownLanguageId,
                                                              data: language,
                                                            }
                                                          );
                                                          setLanguageDeleteModalStatus(
                                                            true
                                                          );
                                                        }}
                                                      >
                                                        delete
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="col-6">
                                                    <div className="delete-content my-auto">
                                                      <span
                                                        className="material-symbols-rounded icon-middle"
                                                        onClick={() => {
                                                          setEditLanguageModal(
                                                            true
                                                          );
                                                          fetchParticularLanguage(
                                                            language?.knownLanguageId
                                                          );
                                                        }}
                                                      >
                                                        edit
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                  </table>
                                )}{" "}
                              </div>
                              <div className="google-input">
                                <button className="btn btn-light my-2 my-sm-0 login-btn">
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={AddLanguageModal}
        onClickOutside={() => {
          setAddLanguageModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Add Language
              </h5>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-0">
                <div className="col-lg-5">
                  <div className="google-input">
                    <label htmlFor="template-input" className="z-index-value">
                      Language*
                    </label>
                    <div className="dropdown-container">
                      <CreatableSelect
                        options={language}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "6px",
                          zIndex: 3,
                          colors: {
                            ...theme.colors,
                            primary25: "#DFF1F1",
                            neutral20:
                              languageValidation?.languageId?.status === false
                                ? "red"
                                : "#00ABAD", // border color
                            primary:
                              languageValidation?.languageId?.status === false
                                ? "red"
                                : "#00ABAD", // border color when focus
                          },
                        })}
                        getOptionLabel={(option) => option.name || option.label}
                        getOptionValue={(option) => option.masterDataId}
                        styles={{
                          menu: (provided, state) => ({
                            ...provided,
                            zIndex: "5 !important",
                          }),
                        }}
                        onBlur={() => {
                          setLanguageValidationValue(
                            "languageId",
                            NonEmptyObjectValidation(languageData?.languageId)
                          );
                        }}
                        onCreateOption={(e) =>
                          handleCreateLanguage(e, "create")
                        }
                        placeholder="Choose your languages"
                        value={languageData?.languageId}
                        onChange={(e) =>
                          onChangeLanguageDetails("languageId", e)
                        }
                      />
                    </div>
                    {languageValidation?.languageId?.status === false ? (
                      <small className="text-danger">
                        Language {languageValidation?.languageId?.message}
                      </small>
                    ) : null}
                  </div>
                  <div className="modalAnswer mt-3">
                    <label
                      htmlFor="template-input"
                      className="z-index-value employeeNavbar-labels"
                    >
                      Communication skill
                    </label>
                    <div>
                      <label
                        className={`btn addLanguage-skills mr-2 ${
                          languageData?.speak ? "active" : ""
                        }`}
                        onClick={() =>
                          onChangeLanguageDetails("speak", !languageData.speak)
                        }
                      >
                        Speak
                      </label>
                      <label
                        className={`btn addLanguage-skills mr-2 ${
                          languageData?.read ? "active" : ""
                        }`}
                        onClick={() =>
                          onChangeLanguageDetails("read", !languageData.read)
                        }
                      >
                        Read
                      </label>
                      <label
                        className={`btn addLanguage-skills mr-2 ${
                          languageData?.write ? "active" : ""
                        }`}
                        onClick={() =>
                          onChangeLanguageDetails("write", !languageData.write)
                        }
                      >
                        Write
                      </label>
                    </div>
                    {languageValidation?.speak?.status === false ? (
                      <small className="text-danger">
                        Skill {languageValidation?.speak?.message}
                      </small>
                    ) : null}
                  </div>
                  <div className="modalAnswer ml-1 mt-5">
                    <label
                      htmlFor="template-input"
                      className="z-index-value employeeNavbar-labels"
                    >
                      Proficiency
                    </label>
                    <div
                      className="form-check"
                      onClick={() =>
                        onChangeLanguageDetails("proficiency", "Basic")
                      }
                    >
                      <input
                        autoComplete="off"
                        checked={languageData?.proficiency === "Basic"}
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                      />
                      <label className="form-check-label" htmlFor="gridCheck1">
                        Basic
                      </label>
                    </div>
                    <div
                      className="form-check mt-1"
                      onClick={() =>
                        onChangeLanguageDetails("proficiency", "Intermediate")
                      }
                    >
                      <input
                        autoComplete="off"
                        checked={languageData?.proficiency === "Intermediate"}
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                      />
                      <label className="form-check-label" htmlFor="gridCheck1">
                        Intermediate
                      </label>
                    </div>
                    <div
                      className="form-check mt-1"
                      onClick={() =>
                        onChangeLanguageDetails("proficiency", "Expert")
                      }
                    >
                      <input
                        autoComplete="off"
                        checked={languageData?.proficiency === "Expert"}
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                      />
                      <label className="form-check-label" htmlFor="gridCheck1">
                        Expert
                      </label>
                    </div>
                    {languageValidation?.proficiency?.status === false ? (
                      <small className="text-danger">
                        Proficiency {languageValidation?.proficiency?.message}
                      </small>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setAddLanguageModal(false);
                  }}
                >
                  Close
                </button>
              </div>
              <div>
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                  onClick={() => {
                    CreateKnownLanguageData();
                  }}
                >
                  ADD
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={languageDeleteModalStatus}
        onClickOutside={() => {
          setLanguageDeleteModalStatus(false);
        }}
      >
        <div className="modal-content w-75 m-auto">
          <div className="modal-body p-3 ">
            <div className="modalHeader" style={{ backgroundColor: "#ffffff" }}>
              <h6 className="text-center font-weight-bold my-2">
                <img
                  style={{
                    width: "85px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  src={Roboimg2}
                  alt=""
                />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 pb-3">
                  <h3 className="font-weight-bold text-center">
                    Are You Sure?
                  </h3>
                  <p className=" text-center">
                    Do you really want to delete this language? this process
                    cannot be undone.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center border-0 mb-3">
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 postjob-btn "
                  onClick={() => {
                    setLanguageDeleteModalStatus(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ padding: "8px 24px" }}
                  className="btn btn-danger my-2 my-sm-0 mr-2 "
                  onClick={() => handleDeleteSelectedLanguage()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={EditLanguageModal}
        onClickOutside={() => {
          setEditLanguageModal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body p-0 ">
            <div className="modalHeader">
              <h5 className="text-center font-weight-bold my-0">
                Edit Language
              </h5>
            </div>

            <div className="container">
              <div className="row d-flex align-items-center justify-content-center modal-scroll-content mt-0">
                <div className="col-lg-5">
                  <div className="google-input">
                    <label htmlFor="template-input" className="z-index-value">
                      Language*
                    </label>
                    <div className="dropdown-container">
                      <CreatableSelect
                        options={language}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "6px",
                          zIndex: 3,
                          colors: {
                            ...theme.colors,
                            primary25: "#DFF1F1",
                            neutral20:
                              particularLanguageValidation?.language?.status ===
                              false
                                ? "red"
                                : "#00ABAD", // border color
                            primary:
                              particularLanguageValidation?.language?.status ===
                              false
                                ? "red"
                                : "#00ABAD", // border color when focus
                          },
                        })}
                        getOptionLabel={(option) => option.name || option.label}
                        getOptionValue={(option) => option.masterDataId}
                        styles={{
                          menu: (provided, state) => ({
                            ...provided,
                            zIndex: "5 !important",
                          }),
                        }}
                        onInputChange={() => {
                          if (particularLanguageValidation["language"])
                            delete particularLanguageValidation["language"];
                        }}
                        onCreateOption={(e) =>
                          handleCreateLanguage(e, "update")
                        }
                        placeholder="Choose your languages"
                        value={particularLanguage?.language}
                        onChange={(e) =>
                          setParticularLanguageValue("language", e)
                        }
                      />
                    </div>
                    {particularLanguageValidation?.language?.status ===
                    false ? (
                      <small className="text-danger">
                        Language{" "}
                        {particularLanguageValidation?.language?.message}
                      </small>
                    ) : null}
                  </div>
                  <div className="modalAnswer mt-3">
                    <label
                      htmlFor="template-input"
                      className="z-index-value employeeNavbar-labels"
                    >
                      Communication skill
                    </label>
                    <div>
                      <label
                        className={`btn addLanguage-skills mr-2 ${
                          particularLanguage?.speak ? "active" : ""
                        }`}
                        onClick={() =>
                          setParticularLanguageValue(
                            "speak",
                            !particularLanguage.speak
                          )
                        }
                      >
                        Speak
                      </label>
                      <label
                        className={`btn addLanguage-skills mr-2 ${
                          particularLanguage?.read ? "active" : ""
                        }`}
                        onClick={() =>
                          setParticularLanguageValue(
                            "read",
                            !particularLanguage.read
                          )
                        }
                      >
                        Read
                      </label>
                      <label
                        className={`btn addLanguage-skills mr-2 ${
                          particularLanguage?.write ? "active" : ""
                        }`}
                        onClick={() =>
                          setParticularLanguageValue(
                            "write",
                            !particularLanguage.write
                          )
                        }
                      >
                        Write
                      </label>
                    </div>
                    {particularLanguageValidation?.speak?.status === false ? (
                      <small className="text-danger">
                        Skill {particularLanguageValidation?.speak?.message}
                      </small>
                    ) : null}
                  </div>
                  <div className="modalAnswer ml-1 mt-5">
                    <label
                      htmlFor="template-input"
                      className="z-index-value employeeNavbar-labels"
                    >
                      Proficiency
                    </label>
                    <div
                      className="form-check"
                      onClick={() =>
                        setParticularLanguageValue("proficiency", "Basic")
                      }
                    >
                      <input
                        autoComplete="off"
                        checked={particularLanguage?.proficiency === "Basic"}
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                      />
                      <label className="form-check-label" htmlFor="gridCheck1">
                        Basic
                      </label>
                    </div>
                    <div
                      className="form-check mt-1"
                      onClick={() =>
                        setParticularLanguageValue(
                          "proficiency",
                          "Intermediate"
                        )
                      }
                    >
                      <input
                        autoComplete="off"
                        checked={
                          particularLanguage?.proficiency === "Intermediate"
                        }
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                      />
                      <label className="form-check-label" htmlFor="gridCheck1">
                        Intermediate
                      </label>
                    </div>
                    <div
                      className="form-check mt-1"
                      onClick={() =>
                        setParticularLanguageValue("proficiency", "Expert")
                      }
                    >
                      <input
                        autoComplete="off"
                        checked={particularLanguage?.proficiency === "Expert"}
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck1"
                      />
                      <label className="form-check-label" htmlFor="gridCheck1">
                        Expert
                      </label>
                    </div>
                    {particularLanguageValidation?.proficiency?.status ===
                    false ? (
                      <small className="text-danger">
                        Proficiency{" "}
                        {particularLanguageValidation?.proficiency?.message}
                      </small>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  className="btn btn-light otp-btn postjob-btn"
                  onClick={() => {
                    setParticularLanguage({});
                    setEditLanguageModal(false);
                  }}
                >
                  Close
                </button>
              </div>
              <div>
                <button
                  className="btn btn-light my-2 my-sm-0 mr-2 login-btn"
                  onClick={() => {
                    UpdateKnownLanguageData(
                      particularLanguage?.knownLanguageId
                    );
                  }}
                >
                  EDIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <ToastContainer />
    </div>
  );
}

export default EditJobseekers;
