import React, { useEffect, useCallback, useState } from "react";
import NavbarEmployee from "../NavbarEmployee";
import io from "socket.io-client";
import { CHAT_BACKEND_URL } from "../../Api/BaseUrl";
import moment from "moment";
import { GetListOfChatRooms, GetListOfMessages } from "../../Api/Api";
import { useSelector } from "react-redux";
import ChatImg from "../../../images/chatImg.png";
import NavbarDashboard from "../../Employer/NavbarDashboard";
const socket = io(CHAT_BACKEND_URL, { transports: ["websocket"] });

export default function ChatEmployee() {
  // redux data
  const state = useSelector((state) => state);
  const authKey = state?.auth?.auth?.authKey;

  // state data
  const [chatRooms, setChatRooms] = useState([]);
  const [selectedChatRoom, setselectedChatRoom] = useState({});
  const [messages, setMessages] = useState({});

  const userId =
    state?.auth?.auth?.actorType === "Employer"
      ? state?.auth?.auth?.userProfileId
      : state?.auth?.auth?.userDetails?.userProfileId;

  const GroupingTheChat = (data) => {
    let Chats = {};
    data.forEach((item, ind) => {
      const SelectedDate = moment(item.date).format("DD-MMM-YYYY");
      if (!Chats[SelectedDate]) {
        Chats[SelectedDate] = [item];
      } else {
        Chats[SelectedDate] = [...Chats[SelectedDate], item];
      }
      if (data.length === ind + 1) {
        setMessages(Chats);
      }
    });
  };

  const fetchdata = useCallback(async () => {
    if (!authKey) return;

    const Response = await GetListOfMessages(selectedChatRoom.id);
    if (Response && Response.statusCode === 200) {
      GroupingTheChat(Response.data);
    }
  }, [authKey, selectedChatRoom]);

  const [text, setText] = useState("");
  const sendData = () => {
    if (text !== "") {
      socket.emit("chat", text, userId, selectedChatRoom.id);
      setText("");
    }
  };

  const FetchAllChatRooms = useCallback(async () => {
    if (!authKey) return;

    const Query = `userId=${userId}&authKey=${authKey}`;
    const Response = await GetListOfChatRooms(Query);
    if (Response && Response.statusCode === 200) {
      setChatRooms(Response.data);
    }
  }, [authKey, userId]);
  

  useEffect(() => {
    FetchAllChatRooms();

    socket.on("message", () => {
      if (Object.keys(selectedChatRoom).length) {
        fetchdata();
      }
    });
  }, [selectedChatRoom, FetchAllChatRooms, fetchdata]);
  const messagesEndRef = React.useRef(null);

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, [messages]);

  return (
    <div className="dashboard-bg">
      {state?.auth?.auth?.actorType === "Employer" ? (
        <NavbarDashboard title={"Chats"} />
      ) : (
        <NavbarEmployee title={"Chats"} />
      )}
      <div className="main">
        <div className="chat-section ">
          <div className="candidate-profile-section ">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4 px-0">
                  <div className="chat-text">
                    <div className="chat-search d-flex justify-content-between px-4 py-1">
                      <h6 className="chat font-weight-bold">CHAT</h6>
                      <div className="icon my-auto">
                        <span className="material-icons icon-middle">
                          search
                        </span>
                      </div>
                    </div>
                    <div className="all-chat-section scroll-content">
                      {chatRooms.map((data, index) => {
                        return (
                          <div
                            className="all-chat d-flex justify-content-between px-4 py-2"
                            key={index}
                          >
                            <div
                              className="individual-chat"
                              onClick={() => {
                                setselectedChatRoom(data);
                                socket.emit("joinRoom", {
                                  roomname: data.id,
                                  userId: data.userId,
                                });
                              }}
                            >
                              {/* cut-text */}
                              <p className="mb-0">
                                {data.displayName.split(",")[0]}
                              </p>
                              <p className="font-weight-bold mb-0">
                                {data.displayName.split(",")[1]}
                              </p>
                              <p className="mb-0 cut-text">
                                {data?.lastMessage?.message}
                              </p>
                            </div>
                            <div className="chat-date">
                              <p>
                                <small>
                                  {moment(data?.lastMessage?.date).format(
                                    "MMM DD"
                                  )}
                                </small>
                              </p>
                              <div className="icon my-auto">
                                <span className="material-symbols-rounded icon-middle">
                                  star
                                </span>
                                {/* <span className="material-symbols-rounded icon-middle filled-icon">star</span> */}
                                <span className="material-icons icon-middle">
                                  more_vert
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 px-0">
                  {!Object.keys(selectedChatRoom).length ? (
                    <div className="chat-open text-center m-auto">
                      <div className="my-4 chat-open-img ">
                        <img className="chat-img" src={ChatImg} alt="" />
                        <h6 className="font-weight-bold text-center">
                          Select the conversation
                        </h6>
                      </div>
                    </div>
                  ) : (
                    <div className="chat-open">
                      <div className="chat-search d-flex justify-content-between px-4 py-1">
                        <div className="chat-open-text">
                          <h6 className="chat">
                            {selectedChatRoom.displayName.split(",")[0]}
                          </h6>
                          <h6 className="font-weight-bold">
                            {selectedChatRoom.displayName.split(",")[1]}
                          </h6>
                        </div>
                        <div className="chat-open-text my-auto">
                          <h6>APPLIED:</h6>
                          <h6 className="font-weight-bold">AUG 03, 2022</h6>
                        </div>
                      </div>
                      <div className="chat-open-section scroll-content pb-5">
                        {Object.keys(messages).map((ke, index1) => {
                          return (
                            <div key={index1}>
                              <h6 className="text-center  my-4">
                                <span className="py-2 px-4">{ke}</span>
                              </h6>
                              {messages[ke].map((data, index) => {
                                if (data.userId !== userId) {
                                  return (
                                    <div
                                      className="message-left px-4 my-2"
                                      key={index}
                                    >
                                      <div className="text-left chat-left">
                                        <p className="mb-0">{data.message}</p>
                                      </div>
                                      <small className="d-flex justify-content-end">
                                        {moment(data.date).format("hh:mm a")}
                                      </small>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      className="message-right px-4 my-2"
                                      key={index}
                                    >
                                      <div className="text-left chat-right">
                                        <p className="mb-0">{data.message}</p>
                                      </div>
                                      <small>
                                        {moment(data.date).format("hh:mm a")}
                                      </small>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          );
                        })}
                        <div ref={messagesEndRef} />
                        <div className="chat-send px-4">
                          <input
                            autoComplete="off"
                            className="form-control"
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                sendData();
                              }
                            }}
                            placeholder="Write your message"
                          />
                          <button
                            className="send-btn"
                            onClick={() => {
                              sendData();
                            }}
                          >
                            <span className="material-icons icon-middle">
                              send
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
