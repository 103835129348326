import React, { useState, useEffect } from "react";
import NavbarAdmin from "./NavbarAdmin";
import { ChangePassword} from "../components/Api/Api";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import CustomModal from "../components/CustomModal";
import Roboimg from "../images/roboimg.png";
function AdminProfile() {
  const state = useSelector((state) => state);
  const Alltabs = ["Personal Details", "Change Password"];
  const [selectedTab, setSelectedTab] = React.useState("Personal Details");
  const [successMsg, setSuccessMsg] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [successModal, setSuccessModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);
  const UpdatAdminDetails = async (e) => {
    e.preventDefault();
    // const Query = `actorType=Employee&authKey=${state?.auth?.auth?.authKey}`;
    // const Response = await GetAllJobUsersListByType(Query);
    // if (Response && Response.statusCode === 200) {
    //   setJobSeekersList(Response.data);
    // }
  };
  const {
    register,
    handleSubmit,
    // control,
    watch,
    formState: { errors },
  } = useForm();
  //CHANGE PASSWORD

  const onSubmit = async (data) => {
    const Payload = {
      emailId: state.auth.auth.emailId,
      password: data.password,
      oldPassword: data.oldPassword,
    };
    const token = state?.auth?.auth?.authKey;
    const response = await ChangePassword(Payload, token);

    if (response && response.statusCode === 200) {
      setSuccessMsg(response.message);
      setSuccessModal(true);
    } else {
      setErrorMsg(response.message || response.error.response.data.message);
      setFailureModal(true);
    }
  };

  useEffect(() => {
    return () => {};
  }, []);
  return (
    <div className="dashboard-bg">
      <NavbarAdmin />
      <div className="main">
        <div className="chat-section bg-white py-1" style={{boxShadow:"0px 3px 6px #00000017"}}>
          <div className="">
            <>
              <nav className="flex border-b tab-section border-gray-300">
                {Alltabs.map((tab, index) => {
                  return (
                    <div
                      key={index}
                      className={selectedTab === tab ? "tab active" : "tab inactive"}
                      onClick={() => {
                        setSelectedTab(tab);
                      }}
                    >
                      <li className="tab-header">{tab}</li>
                    </div>
                  );
                })}
              </nav>
            </>
            {selectedTab === "Personal Details" && (
              <div className="">
                <div className="container">
                  <div className="row d-flex align-items-center justify-content-center ">
                    <div className="col-lg-10">
                      <form
                        onSubmit={(e) => {
                          UpdatAdminDetails(e);
                        }}
                      >
                        <div className="form-container py-1">
                          <div className="row ">
                            <div className="col-lg-6 px-5 pb-4 border-right">
                              <div className="google-input">
                                <input autoComplete="off" type="text" id="template-input" placeholder="Enter your full name" />
                                <label htmlFor="template-input">Full name</label>
                              </div>
                              <div className="google-input">
                                <input autoComplete="off" type="text" id="template-input" placeholder="Enter your Display name" />
                                <label htmlFor="template-input">Display name</label>
                              </div>
                              <div className="google-input">
                                <input autoComplete="off" type="text" id="template-input" placeholder="Enter your email" />
                                <label htmlFor="template-input">Email Id</label>
                              </div>
                            </div>
                            <div className="col-lg-6 px-5 pb-4">
                              <div className="google-input">
                                <input autoComplete="off" type="text" id="template-input" placeholder="Enter your Mobile Number" />
                                <label htmlFor="template-input">Mobile Number</label>
                              </div>
                            </div>
                            <div className="col-lg-12 text-center">
                              <div className="google-input">
                                <button type="button" className="btn btn-light my-2 my-sm-0 login-btn">
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "Change Password" && (
              <div>
                <div className="container">
                  <div className="row d-flex  justify-content-center ">
                    <div className="col-lg-10">
                      <form onClick={handleSubmit(onSubmit)}>
                        <div className="form-container">
                          <div className="row justify-content-center">
                            <div className="col-lg-6 px-5 pb-4">
                              <div className="google-input">
                                <input
                                  autoComplete="off"
                                  type="text"
                                  id="oldPassword"
                                  name="oldPassword"
                                  placeholder="Enter your Current Password"
                                  {...register("oldPassword", {
                                    required: "current password is Required",
                                    maxLength: {
                                      value: 15,
                                      message: "must be max 15 chars",
                                    },
                                    validate: (value) => {
                                      return [/[a-z]/, /[A-Z]/, /\d/].every((pattern) => pattern.test(value)) || "cannot special chars, only lower, upper, number";
                                    },
                                  })}
                                />
                                <label htmlFor="template-input">Current Password</label>
                              </div>
                              <p className="text-danger">{errors.oldPassword?.message}</p>
                              <div className="google-input">
                                <input
                                  autoComplete="off"
                                  type="text"
                                  id="password"
                                  name="password"
                                  placeholder="Enter your New Password"
                                  {...register("password", {
                                    required: "password is Required",
                                    maxLength: {
                                      value: 15,
                                      message: "must be max 15 chars",
                                    },
                                    validate: (value) => {
                                      return [/[a-z]/, /[A-Z]/, /\d/].every((pattern) => pattern.test(value)) || "cannot special chars, only lower, upper, number";
                                    },
                                  })}
                                />
                                <label htmlFor="template-input">New Password</label>
                              </div>
                              <p className="text-danger">{errors.password?.message}</p>
                              <div className="google-input">
                                <input
                                  autoComplete="off"
                                  type="text"
                                  id="template-input"
                                  placeholder="Re-Enter your New Password"
                                  {...register("confirm_password", {
                                    required: true,
                                    validate: (value) => {
                                      if (watch("password") !== value) {
                                        return "Your passwords do no match";
                                      }
                                    },
                                  })}
                                />
                                <label htmlFor="template-input">Re-Enter New Password</label>
                              </div>
                              <p className="text-danger">{errors.confirm_password?.message}</p>
                            </div>
                            <div className="col-lg-12 text-center">
                              <div className="google-input">
                                <button type="button" className="btn btn-light my-2 my-sm-0 login-btn">
                                  Change Password
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <CustomModal
        open={successModal}
        onClickOutside={() => {
          setSuccessModal(false);
        }}
      >
        <div className="modal-content w-50  m-auto">
          <div className="modal-body p-0 ">
            <div className="modalHeader succ-failure">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                onClick={() => {
                  setSuccessModal(false);
                }}
              >
                close
              </span>
              <h6 className="text-center font-weight-bold my-2">
                <img style={{ width: "85px", textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }} src={Roboimg} alt="" />
                {/* <span className="material-symbols-rounded filled-icon text-green" style={{fontSize:'65px',textShadow: 'rgb(171 167 167 / 44%) 0px 3px 6px'}}>check_circle</span> */}
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 py-4">{successMsg ? <p className="font-weight-bold text-center">{successMsg}</p> : ""}</div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={failureModal}
        onClickOutside={() => {
          setFailureModal(false);
        }}
      >
        <div className="modal-content w-50  m-auto">
          <div className="modal-body p-0 ">
            <div className="modalHeader succ-failure">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}
                onClick={() => {
                  setFailureModal(false);
                }}
              >
                close
              </span>
              <h6 className="text-center font-weight-bold my-2">
                <img style={{ width: "85px", textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }} src={Roboimg} alt="" />
              </h6>
            </div>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="col-lg-8 py-4">
                  <h3 className="font-weight-bold text-center">Sorry!</h3>
                  <p className="font-weight-bold text-center">{errorMsg}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}

export default AdminProfile;
